import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { getShiftTypeByRoleId } from 'AC/shifts/shifts'
import { getTableYScroll } from 'utils/scrollUtils'
import { fetchRoles } from 'AC/doers/doerRoles'

import { getColumns, getRows } from './utils'


type Props = {
  roles: APITYPES.Role[]
  shiftTypesByRole: APITYPES.ShiftTypesByRolesId[]
  isLoading: boolean
}

export const ShiftTypesByRoleList = (props: Props) => {
  const { isLoading, shiftTypesByRole, roles } = props
  const [newFilteredValue, setFilteredValue] = useState<string[]>([roles[0]?.id])
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(dispatch(action(Actions.SET_SHIFT_TYPES_BY_ROLE_FILTER, roles[0].id)))
    }
  }, [])

  useEffect(() => {
    if (!newFilteredValue) {
      dispatch(getShiftTypeByRoleId(roles[0].id))
    }  else if (newFilteredValue[0] === 'all') {
      dispatch(fetchRoles())
    } else {
      dispatch(getShiftTypeByRoleId(newFilteredValue[0]))
    }
  }, [dispatch, newFilteredValue, roles])

  const onChange = (_: any, filters: any) => {
    const { type } = filters

    if (!type) {
      dispatch(getShiftTypeByRoleId(roles[0].id))
    } else {
      setFilteredValue([type[0]])
      dispatch(getShiftTypeByRoleId(type[0]))
    }
  }

  return (
    <Table
      sticky
      loading={isLoading}
      columns={getColumns(roles, newFilteredValue ?? [roles[0].id]) as any}
      dataSource={getRows(roles, newFilteredValue ?? [roles[0].id], shiftTypesByRole)}
      pagination={false}
      size='small'
      scroll={{ y: getTableYScroll(250.5) }}
      onChange={onChange}
    />
  )
}
