// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--contact--KU05P{padding:5px 10px;margin-top:10px;border:1px solid #85878f;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.styles-module--addBtn--ydmfe{margin-top:10px}.styles-module--addBlock--17rFr{padding:10px;margin-top:10px;border:1px solid #85878f}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/Employees/Contacts/styles.module.sass"],"names":[],"mappings":"AAEA,+BACI,gBAAA,CACA,eAAA,CACA,wBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAEJ,8BACI,eAAA,CAEJ,gCACI,YAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":["@import 'vars'\n    \n.contact\n    padding: 5px 10px\n    margin-top: 10px\n    border: 1px solid $black300\n    display: flex\n    align-items: center\n    justify-content: space-between\n\n.addBtn\n    margin-top: 10px\n\n.addBlock\n    padding: 10px\n    margin-top: 10px\n    border: 1px solid $black300"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "styles-module--contact--KU05P",
	"addBtn": "styles-module--addBtn--ydmfe",
	"addBlock": "styles-module--addBlock--17rFr"
};
export default ___CSS_LOADER_EXPORT___;
