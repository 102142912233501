import React from 'react';
import { Tag } from 'antd';
import { APITYPES } from 'types/apitypes';

export type EditModalType = { isOpen: boolean, fleetCar: APITYPES.FleetCar | null }

export const getColumns = () => {
  return [
    {
      title: 'Гос. номер',
      dataIndex: 'grz',
      key: 'grz',
      align: 'center',
      render: (grz: string) => <Tag>{grz}</Tag>
    },
    {
      title: 'Марка',
      dataIndex: 'car_brand_name',
      key: 'car_brand_id',
    },
    {
      title: 'Модель',
      dataIndex: 'car_model_name',
      key: 'car_model_id',
    },
    {
      title: 'Оператор',
      dataIndex: 'fleet_name',
      key: 'fleet_name',
    },


  ]
}

export const getRows = (fleets: APITYPES.FleetCar[]) => {
  return fleets.map((fleet) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'model') {
        row[column.dataIndex] = `${fleet.car_brand_name} ${fleet.car_model_name}`
      } else {
        //@ts-ignore
        row[column.dataIndex] = fleet[column.dataIndex]
      }
    })
    row.fleetCar = fleet
    row.key = fleet.id

    return row
  })
}

/**
 * If the URL parameter null_model is set to true, return true, otherwise return false
 * @returns A function that returns true if the null_model parameter is set to true in the URL, and
 * false otherwise.
 */
export const checkNullModels = () => {
  // Get the current URL
  const currentUrl = new URL(window.location.href)

  // Get the search params from the URL
  const searchParams = new URLSearchParams(currentUrl.search)

  // Check if the null_models parameter is set to true
  if (searchParams.get('null_model') === 'true') {
    // If null_models is set to true, return true
    return true
  }
  
  // If null_models is not set or is set to a different value, return false
  return false
}
