import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import ContractorType = APITYPES.ContractorType


export const getContractors = (filter?: {
  limit?: number
  offset?: number
  search?: string
  type?: ContractorType
}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getContractors', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetContractors.URL, {
        params: {
          type: filter?.type ?? getState().billingReducer.contractorsFilter.type,
          search: filter?.search ?? getState().billingReducer.contractorsFilter.search,
          offset: filter?.offset ?? getState().billingReducer.contractors.length,
          limit: filter?.limit ?? 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_CONTRACTORS, resp))

        dispatch(changeRequestState('getContractors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getContractors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getPartnerContractors = (filter?: {
  search?: string
  limit?: number
  offset?: number
}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getPartnerContractors', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetContractors.URL, {
        params: {
          type: ContractorType.partner,
          search: filter?.search || undefined,
          offset: filter?.offset || 0,
          limit: filter?.limit || 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_PARTNER_CONTRACTORS, resp))

        dispatch(changeRequestState('getPartnerContractors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getPartnerContractors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getDoerContractors = (filter?: {
  search?: string
  limit?: number
  offset?: number
}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerContractors', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetContractors.URL, {
        params: {
          type: ContractorType.doer,
          search: filter?.search || undefined,
          offset: filter?.offset ?? 0,
          limit: filter?.limit || 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_CONTRACTORS, resp))

        dispatch(changeRequestState('getDoerContractors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerContractors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}