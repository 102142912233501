import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Collapse } from 'antd'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchRoles } from 'AC/doers/doerRoles'
import { fetchShiftTypes } from 'AC/shifts/shifts'
import { ShiftTypeForm } from './components/ShiftTypeForm'
import { UModal } from 'shared/ui/u-modal'
import { ShiftTypesList } from './components/ShiftTypesList'
import { ShiftTypesByRoleList } from './components/ShiftTypesByRoleList'
import { ShiftTypeByRoleForm } from './components/ShiftTypeByRoleForm'
import { Loader } from 'components/Loader'

import styles from './styles.module.sass'


enum PANEL_KEYS {
  SHIFT_TYPE = 'SHIFT_TYPE',
  SHIFT_TYPE_BY_ROLE = 'SHIFT_TYPE_BY_ROLE',
}

const { Panel } = Collapse

const selector = (state: State) => {
  const { shiftTypes, shiftTypesByRoleId, shiftTypesByRoleFilter  } = state.shiftsReducer
  const { roles } = state.doersReducer


  const isShiftTypesLoading = state.requestsReducer.fetchShiftTypes === StatusRequest.LOADING
  const isAddShiftTypeLoading = state.requestsReducer.addShiftType === StatusRequest.LOADING
  const addShiftByRoleLoading = state.requestsReducer.addShiftTypeByRole === StatusRequest.LOADING
  const isShiftsByAllRolesLoading = state.requestsReducer.fetchShiftTypesByAllRoles === StatusRequest.LOADING
  const isShiftTypesByRoleLoading = state.requestsReducer.fetchShiftTypesByRole === StatusRequest.LOADING
  const isRolesFetching = state.requestsReducer.fetchRoles === StatusRequest.LOADING


  const isShiftTypesTableLoading = isAddShiftTypeLoading || isShiftTypesLoading

  const isShiftsByRoleTableLoading =
      addShiftByRoleLoading
      || isShiftsByAllRolesLoading
      || isShiftTypesByRoleLoading
      || isRolesFetching

  return {
    shiftTypes,
    isShiftTypesTableLoading,
    isShiftsByRoleTableLoading,
    shiftTypesByRoleId,
    shiftTypesByRoleFilter,
    roles,
  }
}

export const ShiftSettings = () => {
  const dispatch = useDispatch()
  const {
    shiftTypes,
    isShiftTypesTableLoading,
    isShiftsByRoleTableLoading,
    shiftTypesByRoleId,
    roles = [],
  } = useSelector(selector)

  const [accordion, setAccordion] = useState<PANEL_KEYS | PANEL_KEYS[] | undefined>(undefined)
  const [isShiftTypeFormOpen, setIShiftTypeFormOpen] = useState(false)
  const [isShiftTypeByRoleFormOpen, setIShiftTypeByRoleFormOpen] = useState(false)

  const onChange = (key: string | string[]) => setAccordion(key as PANEL_KEYS | PANEL_KEYS[] | undefined)

  const closeShiftTypeFormModal = () => setIShiftTypeFormOpen(false)
  const closeShiftTypeByRoleFormModal = () => setIShiftTypeByRoleFormOpen(false)

  useEffect(() => {
    dispatch(fetchRoles())
    dispatch(fetchShiftTypes())
  }, [])


  if (!roles) {return <Loader />}
  
  return (
    <div className={styles.container}>
      <Collapse
        accordion
        defaultActiveKey={accordion}
        activeKey={accordion}
        onChange={onChange}
        className={styles.collapse}
      >
        <Panel
          className={styles.collapsePanel}
          header='Типы смен'
          key={PANEL_KEYS.SHIFT_TYPE}
          extra={
            accordion === PANEL_KEYS.SHIFT_TYPE && (
              <Button
                size='small'
                className={styles.addBtn}
                onClick={
                  (e) => {
                    e.stopPropagation()
                    setIShiftTypeFormOpen(true)
                  }
                }
              >
                Добавить
              </Button>
            )
          }
        >
          <ShiftTypesList
            shiftTypes={shiftTypes}
            isLoading={isShiftTypesTableLoading}
          />
        </Panel>

        <Panel
          className={styles.collapsePanel}
          header='Типы смен по ролям'
          key={PANEL_KEYS.SHIFT_TYPE_BY_ROLE}
          extra={
            accordion === PANEL_KEYS.SHIFT_TYPE_BY_ROLE && (
              <Button
                size='small'
                className={styles.addBtn}
                onClick={
                  (e) => {
                    e.stopPropagation()
                    setIShiftTypeByRoleFormOpen(true)
                  }
                }
              >
                Добавить
              </Button>
            )

          }
        >
          <ShiftTypesByRoleList
            roles={roles || []}
            shiftTypesByRole={shiftTypesByRoleId}
            isLoading={isShiftsByRoleTableLoading}
          />
        </Panel>
      </Collapse>
      <UModal
        destroyOnClose
        footer={false}
        title='Создание типа смены'
        open={isShiftTypeFormOpen}
        onCancel={closeShiftTypeFormModal}
      >
        <ShiftTypeForm onClose={closeShiftTypeFormModal} />
      </UModal>
      <UModal
        destroyOnClose
        footer={false}
        title='Создание типа смены в зависимости от роли'
        open={isShiftTypeByRoleFormOpen}
        onCancel={closeShiftTypeByRoleFormModal}
      >
        <ShiftTypeByRoleForm onClose={closeShiftTypeByRoleFormModal} />
      </UModal>
    </div>
  )
}
