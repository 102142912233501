import React from 'react'
import styles from './styles.module.sass'
import { APITYPES } from 'types/apitypes'

type Transferable = {
  key: string
  title: string
  description?: string
  disabled?: boolean
}

export const transferTitles = [
  <div className={styles.listTitle} key='roles'>Все сервисы</div>,
  <div className={styles.listTitle} key='doer_roles'>Сервисы продукта</div>,
]

export const listStyle = {
  width: '30%',
  height: `calc(100vh - 316px)`,
}

export const getSourceColumn = (data: APITYPES.AllServices.Get.Resp): Transferable[] => {
  const result: Transferable[] = []

  data.forEach((service) => {
    result.push({
      key: service.id,
      title: service.name,
    })
  })

  return result
}

export const getTargetKeys = (data: APITYPES.ProductService[]) => {
  return data.map((option) => option.id)
}