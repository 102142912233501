import { useEffect, useMemo, useState } from 'react'
import { pickBy } from 'lodash'

import { APITYPES } from '../../../../../types/apitypes'
import { api } from '../../../../../index'


export const useGetLocations = ({ city_id, type, search }: APITYPES.Shifts.ShiftLocations.GET.Params) => {
  const [locations, setLocations] = useState<APITYPES.Location[]>([])

  const filter = useMemo(() => pickBy({
    city_id: Number(city_id),
    type,
    search,
  }), [city_id, type, search])

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await api.get(APITYPES.Shifts.ShiftLocations.GET.URL, { params: { ...filter } })

        setLocations(resp)
      } catch (e) {
        console.log(e)
      }
    }

    getData()
  }, [filter])

  return locations
}