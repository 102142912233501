import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

import styles from '../MatchingExecutors/styles.module.sass'
import { State } from '../../../../../../../../stores/rootReducer'
import { UnmatchedPlacementsTable } from './components/UnmatchedPlacementsTable'


interface Props {
  onSubmit: () => void
}

const selector = (state: State) => ({ unmatchedPlacements: state.yandexLavkaReducer.unmatchedPlacements })


export const MatchingPlacements: FC<Props> = ({ onSubmit }) => {
  const { unmatchedPlacements } = useSelector(selector)

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedPlacementsTable unmatchedPlacements={unmatchedPlacements || []} />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!unmatchedPlacements?.every((item) => item)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
