import React from 'react'

import { UModal } from 'shared/ui/u-modal'

import { CancelInternshipForm } from './cancel-internship-form'


type Props = {
  internshipId: string
  onClose: () => void
}

export const CancelInternshipModal = (props: Props) => {
  const { internshipId, onClose } = props

  return (
    <UModal
      title='Отмена стажировки'
      open={!!internshipId}
      footer={false}
      onCancel={onClose}
      destroyOnClose
    >
      <CancelInternshipForm
        internshipId={internshipId}
        onClose={onClose}
      />
    </UModal>
  )
}
