// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addBtn--72IbR{position:fixed;bottom:64px;right:3.6%}.styles-module--newsFeed--ELeGd{display:-webkit-box;display:-ms-flexbox;display:flex;max-width:100%;gap:28px;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.styles-module--intersectionRef--OBmh-{height:16px}.styles-module--loaderWrapper--WTtUp{height:300px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/News/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,cAAA,CACA,WAAA,CACA,UAAA,CAEF,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,cAAA,CACA,QAAA,CACA,kBAAA,CAAA,cAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAEF,uCACE,WAAA,CAEF,qCACE,YAAA","sourcesContent":[".addBtn\n  position: fixed\n  bottom: 64px\n  right: 3.6%\n\n.newsFeed\n  display: flex\n  max-width: 100%\n  gap: 28px\n  flex-wrap: wrap\n  justify-content: center\n\n.intersectionRef\n  height: 16px\n\n.loaderWrapper\n  height: 300px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtn": "styles-module--addBtn--72IbR",
	"newsFeed": "styles-module--newsFeed--ELeGd",
	"intersectionRef": "styles-module--intersectionRef--OBmh-",
	"loaderWrapper": "styles-module--loaderWrapper--WTtUp"
};
export default ___CSS_LOADER_EXPORT___;
