import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Select, Space } from 'antd';

import { ROUTES } from 'config/routes';
import { State } from 'stores/rootReducer';
import { StatusRequest } from 'stores/requestsReducer';
import { action, Actions } from 'actions';
import { fetchAllCarBrands, fetchAllCarClasses } from 'AC/directory/directory';
import { Loader } from 'components/Loader';
import { getUrlParam } from 'utils/urlUtils';

import styles from './styles.module.sass';
import { CarModel } from './CarModels/CarModel';
import { CarModels } from './CarModels';


const selector = (state: State) => {
  const {carBrands} = state.directoryReducer;
  const isBrandsLoading =
    state.requestsReducer.fetchAllCarBrands === StatusRequest.LOADING;
  const isModelsLoading =
    state.requestsReducer.fetchAllBrandModels === StatusRequest.LOADING;

  return {
    carBrands,
    isBrandsLoading,
    isModelsLoading,
  };
};


export const Cars = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {carBrands, isBrandsLoading, isModelsLoading} = useSelector(selector);

  const brand_id = getUrlParam(pathname, 1);

  useEffect(() => {
    batch(() => {
      dispatch(fetchAllCarBrands());
      dispatch(fetchAllCarClasses());
    });
  }, []);

  useEffect(() => {
    ROUTES.DIRECTORY.CARS.BRAND.ID.createPath(brand_id)
    if (!brand_id && carBrands) {
      setTab(carBrands[0].id);
    }
  }, [carBrands]);

  const setTab = (brand_id: string) => {
    dispatch(action(Actions.CLEAR_BRAND_MODELS, undefined));
    history.push(ROUTES.DIRECTORY.CARS.BRAND.ID.createPath(brand_id));
  };


  if (!carBrands || isBrandsLoading) {
    return <Loader/>;
  }


  return (
    <div className={styles.main}>

      <Row justify="space-between">
        <Col span={6}>
          <Space direction="vertical" size={10} style={{width: '100%'}}>
            <Row justify="space-between">
              <Col span={4}>Марки: </Col>
              <Col span={19}>
                <Select
                  showSearch
                  style={{width: '100%'}}
                  value={+brand_id}
                  placeholder="Выберите марку"
                  optionFilterProp="children"
                  fieldNames={{label: 'name', value: 'id'}}
                  filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    ROUTES.DIRECTORY.CARS.BRAND.ID.createPath(e.toString())
                    setTab(e.toString())
                  }}
                  options={carBrands}
                />
              </Col>
            </Row>
            <CarModels/>
          </Space>
        </Col>
        <Col span={17}>
          <CarModel/>
        </Col>
      </Row>
    </div>
  );
};
