import { useEffect, useRef, useState } from 'react';
import { Form, InputRef } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'stores/rootReducer';
import { editOperation } from 'AC/shifts/operations';
import { errorNotification } from 'components/modals/OperationNotification';
import { GeneralType } from '../../utils';
import { personal, taxi } from '../utils';

const selector = (state: State) => ({
  selectedOperation: state.operationsReducer.currentOperation,
  parent: state.operationsReducer.parentOperation,
})

export const useEditForm = (general: GeneralType) => {
  const dispatch = useDispatch()
  const {selectedOperation, parent} = useSelector(selector)


  const [editGrzMode, setEditGrzMode] = useState<boolean>(false)
  const grzRef = useRef<InputRef>(null)

  useEffect(() => {
    if (editGrzMode)
      setEditGrzMode(false)
  }, [general])

  const [form] = Form.useForm()

  const onGrzChange = () => {
    form.submit()
  }

  const onGrzFinish = (values: any) => {
    if(!parent || (parent.grz === values.grz)) {
      setEditGrzMode(false)
      return
    }

    if(personal.test(values.grz) || taxi.test(values.grz)) {
      dispatch(editOperation(parent.id, {grz: values.grz}, 'grz'))
    } else {
      form.resetFields()
      errorNotification('Неверный формат')
    }
    setEditGrzMode(false)
  }

  const onGrzEditClick = () => {
    setEditGrzMode(true)
    setTimeout(() => {
      if(grzRef.current) {
        grzRef.current.focus({cursor: 'end'})
      }
    }, 0)
  }

  return {
    editGrzMode,
    onGrzChange,
    onGrzFinish,
    onGrzEditClick,
    grzRef,
    editForm: form
  }
};
