import { action, Actions, ThAction } from '../../actions'
import { showError } from '../../components/modals/ErrorModal'
import { APITYPES } from '../../types/apitypes'
import { successNotification } from '../../components/modals/OperationNotification'


export const getPlacements = (locationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const response = await api.get(
        APITYPES.YandexLavka.Placements.GET.URL,
        { params: { location_id: locationId } }
      )

      dispatch(action(Actions.SET_YANDEX_LAVKA_PLACEMENTS, response))
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const createPlacement = (locationId: string, name: string, onSuccess: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const response = await api.post(
        APITYPES.YandexLavka.Placements.POST.URL,
        {
          location_id: locationId,
          name: name,
        }
      )

      dispatch(action(Actions.ADD_YANDEX_LAVKA_PLACEMENTS, response))
      onSuccess()
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const deletePlacement = (placementId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.delete(APITYPES.YandexLavka.Placements.DELETE.URL, { replaceUrl: { id: placementId } })

      if (resp) {
        dispatch(action(Actions.DELETE_YANDEX_LAVKA_PLACEMENT, { id: placementId }))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}


export const updateUnmatchedPlacement = (placementId: string, locationId:  string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.put(
        APITYPES.YandexLavka.UnmatchedPlacements.PUT.URL, { location_id: locationId }, { replaceUrl: { id: placementId } }
      )

      if (resp) {
        dispatch(action(Actions.UPDATE_YANDEX_LAVKA_PLACEMENTS_MATCHED, { id: placementId }))
        successNotification('Успешно связано!')
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteUnmatchedPlacement = (placementId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.delete(APITYPES.YandexLavka.UnmatchedPlacements.PUT.URL, { replaceUrl: { id: placementId } })

      if (resp) {
        dispatch(action(Actions.DELETE_YANDEX_LAVKA_UNMATCHED_PLACEMENT, { id: placementId }))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}