import React, { useState } from 'react'
import { Card, Button } from 'antd'
import cn from 'classnames'

import { APITYPES } from 'types/apitypes'
import { getNameByNum } from '../utils'

import { Interval } from './Interval'
import { AddModal } from './AddModal'
import styles from './styles.module.sass'


type Props = {
  scheduleId: string
  className?: string
  dayNum: number | undefined
  intervals: APITYPES.ScheduleInterval[]
}

export const Day = (props: Props) => {
  const { intervals, className, scheduleId, dayNum } = props

  const [isAddModal, setIsAddModal] = useState(false)

  return (
    <Card
      className={cn(styles.root, className)}
      title={getNameByNum(dayNum)}
    >
      {
        intervals.map((interval)=>(
          <Interval
            key={interval.id}
            interval={interval}
            scheduleId={scheduleId}
          />
        ))
      }
      <Button
        className={styles.addButton}
        onClick={()=>setIsAddModal(true)}
        type='primary'
      >
        Добавить
      </Button>
      <AddModal
        scheduleId={scheduleId}
        isVisible={isAddModal}
        onCancel={()=>setIsAddModal(false)}
        dayNum={dayNum}
      />
    </Card>
  )
}