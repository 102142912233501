import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const fetchUser = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchUser', StatusRequest.LOADING))
      
    try {
      const resp = await api.get(APITYPES.Users.GetUser.URL, { replaceUrl: { id } })
    
      if (resp) {
        dispatch(action(Actions.SET_USER, resp))
    
        dispatch(changeRequestState('fetchUser', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchUser', StatusRequest.ERROR))
  
      throw(e)
    }
  }
}