import { Reducer } from 'redux'

import { Action, Actions } from 'actions'


export type AuthState = {
  isUserSignedIn: boolean
}

export const initialAuthState = { isUserSignedIn: false }

export const authReducer: Reducer<AuthState, Action> = (state = initialAuthState, a): AuthState => {
  switch (a.type) {
    case Actions.SET_IS_USER_SIGNED_IN:
      const newState = {
        ...state,
        isUserSignedIn: a.data,
      }

      return { ...newState }
  }
  
  return state
}
