import { APITYPES } from 'types/apitypes'


export const getColumns = (
  roles?: APITYPES.Role[],
  filteredValue?: string[]
) => {
  return [
    {
      title: 'Название роли',
      dataIndex: 'role_name',
      key: 'type',
      fixed: 'left',
      filters: roles
        ? roles.map((role) => ({
          text: role.name,
          value: role.id,
        }))
        : null,
      filterMultiple: false,
      filteredValue: filteredValue,
    },
    {
      title: 'Тип смены',
      dataIndex: 'type',
      key: 'type',
    },
  ]
}

export const getRows = (
  roles: APITYPES.Role[],
  filteredValue: string[],
  RoleShiftTypes: APITYPES.ShiftTypesByRolesId[]
) => {
  const rows: any[] = []

  const role = roles.find(
    (role: APITYPES.Role) => role.id === filteredValue[0]
  )

  if (RoleShiftTypes) {
    RoleShiftTypes.map((shift: APITYPES.ShiftTypesByRolesId) => {
      const row: any = {}

      getColumns().map((column: any) => {
        if (column.dataIndex === 'role_name') {
          //@ts-ignore
          row[column.dataIndex] = role.name
        } else {
          //@ts-ignore
          row[column.dataIndex] = shift.name
        }
      })

      row.key = shift.id + shift.name

      rows.push(row)
    })
  }

  return rows
}
