import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { openNotification } from 'components/modals/OperationNotification'
import { changeRequestState } from '../request'
import { fetchShiftDoers } from '../shifts/shifts';

export const addDoerToShift = (body: APITYPES.Shifts.AddDoerToShift.Req): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('addDoerToShift', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Shifts.AddDoerToShift.URL, body)

      if (resp) {
        const shiftDoersFilter = getState().shiftsReducer.shiftDoersFilter

        dispatch(fetchShiftDoers(body.shift_id, shiftDoersFilter))

        openNotification('Исполнитель добавлен в смену')
        dispatch(changeRequestState('addDoerToShift', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerToShift', StatusRequest.ERROR))

      showError(JSON.stringify(e.response.data))

      throw(e)
    }
  }
}

