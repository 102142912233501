import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Drawer, Form, Select } from 'antd'
import { useHistory } from 'react-router'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { getEntries } from 'utils/enumUtils'

const contractorTypes = getEntries(APITYPES.ContractorTypeTrans)

type Props = {
  isOpen: boolean
  onClose: () => void
}
const selector = (state: State) => ({filter: state.billingReducer.contractorsFilter})

export const Filters = ({isOpen, onClose}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {filter} = useSelector(selector)

  /** handlers */
  const onFilterChange = (partial: Partial<APITYPES.ContractorFilter>) => {
    dispatch(action(Actions.SET_CONTRACTORS_FILTER, {...filter, ...partial}))
  }

  const resetFilters = () => {
    dispatch(action(Actions.SET_CONTRACTORS_FILTER, {}))
    history.push(ROUTES.BILLING.CONTRACTORS.path)
  }

  return (
    <Drawer
      title="Фильтры"
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout="vertical">
        <Form.Item label="Тип">
          <Select
            allowClear
            placeholder="Выберите тип"
            value={filter?.type}
            onChange={(value) => onFilterChange({type: value})}
          >
            {
              contractorTypes.map(type =>
                <Select.Option key={type[0]} value={type[0]}>{type[1] as string}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
