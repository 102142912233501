import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, InputNumber, Tooltip } from 'antd'
import { CopyOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import momentTimezone from 'moment-timezone'

import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'
import { copyToClipboard } from 'utils/copyUtils'
import { errorNotification } from 'components/modals/OperationNotification'
import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { fetchEmployerById, updateShiftById } from 'AC/shifts/shifts'
import { action, Actions } from '../../../../../../../../actions'

import { getGeneralDataArr, getOffersDataMap, getRolesDataArr } from './utils'
import styles from './styles.modules.sass'


type Props = {
  shift: APITYPES.Shift
}

const selector = (state: State) => ({
  shiftOffers: state.shiftsReducer.shiftOffers,
  employer: state.shiftsReducer.employer,
})

export const Info = (props: Props) => {
  const { shift } = props
  const { shiftOffers, employer } = useSelector(selector)
  const dispatch = useDispatch()
  const dataArr = useMemo(() => getGeneralDataArr(shift, employer), [shift, employer])
  const rolesArr = useMemo(() => getRolesDataArr(shift.roles), [shift])
  const offersDataMap = useMemo(() => getOffersDataMap(shiftOffers), [shiftOffers])

  const onBlur = (e: any, role_id: string) => {
    if (!e.target.value) {return}
    const splitValue = e.target.value.split('/')

    if (!splitValue[1] || isNaN(+splitValue[1].trim())) {
      errorNotification('Неверный формат')
      
      return
    }
    const newPlaces = +splitValue[1].trim()
    const theRole = shift.roles.find((r) => r.id === role_id)

    if (theRole && theRole.places === newPlaces) {return}

    const { roles, isOk } = getNewRoles(role_id, newPlaces)

    if (isOk) {
      dispatch(updateShiftById(shift.id, { roles }))
    }
  }

  // helper
  const getNewRoles = (role_id: string, newPlaces: number) => {
    let isOk = true

    const roles = [...shift.roles.map((role) => {
      if (role.id === role_id) {
        if (newPlaces < 0 || newPlaces < role.booked_places) {
          errorNotification('Значение не может быть меньше 0 или кол-ва зарезервированных мест')
          isOk = false
          
          return {
            id: role.id,
            places: role.places,
          }
        }
        role.places = newPlaces
          
        return {
          id: role.id,
          places: newPlaces,
        }
      }
      
      return {
        id: role.id,
        places: role.places,
      }
    })]

    
    return {
      roles,
      isOk,
    }
  }

  const onInputFormat = (value: number | undefined, field: any) => {
    return `${field.booked} / ${value}`
  }

  const onInputParse = (input: string | undefined, filedPlaces: number) => {
    if (!input) {return 0}
    if (!input.split('/')[1]) {return filedPlaces}
    
    return +input.split('/')[1]
  }

  const handleClipboardClick = () => {
    copyToClipboard(shift.id, 'ID смены успешно скопирована!')
  }

  const timezone = useGetTimezoneFromLocalstorage()

  const formatDate = (date: string) => {
    const times = date
      .split('-')
      .map((t) => t.trim())
    
    const [startShift, endShift] = times

    const [start, end] = formatDateTime({
      start: startShift,
      end: endShift,
      targetTimezone: timezone,
    })
    
    return `${start} - ${end}`
  }

  useEffect(() => {
    if (shift.employer_id) {
      dispatch(fetchEmployerById({ id: shift.employer_id }))
    } else {
      dispatch(action(Actions.SET_EMPLOYER, null))
    }
  }, [shift])

  useEffect(() => {
    if (timezone) {
      momentTimezone.tz.setDefault(timezone)
    }
  }, [timezone])

  return (
    <div>
      <div
        className={styles.field}
        style={{ cursor: 'pointer' }}
        onClick={handleClipboardClick}
      >
        <div className={styles.title}>ID смены</div>
        <div className={styles.value}>{ shift?.id }</div>
        <CopyOutlined />
      </div>
      <Divider className={styles.divider}>Общая информация</Divider>
      <div>
        {
          dataArr.map((field) => (
            <div
              className={styles.field}
              key={field.label}
              style={{ flexWrap: field.label === 'График:' ? 'wrap' : 'nowrap' }}
            >
              <div className={styles.fieldWrap}>
                <div className={styles.title}>{ field.label }</div>
                <div className={styles.value}>
                  {
                    field.label === 'График:' && typeof field.value === 'string'
                      ? field.value
                      : field.value
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <Divider className={styles.divider}>Роли</Divider>
      <div className={styles.segmentContainer}>
        {
          rolesArr.map((field) => (
            <div
              className={styles.field}
              key={field.label}
            >
              <div className={styles.title_role}>{ field.label }</div>
              <InputNumber
                size='small'
                defaultValue={field.places}
                className={styles.fieldInput}
                formatter={(value) => onInputFormat(value, field)}
                parser={(input: string | undefined) => onInputParse(input, field.places)}
                onBlur={(e) => onBlur(e, field.id)}
              />
              <Tooltip overlay='Перейти к офферу'>
                {
                  offersDataMap[field.id] && (
                    <Link
                      to={ROUTES.DOERS.OFFERS.createPath(offersDataMap[field.id])}
                      target='_blank'
                    >
                      <DoubleRightOutlined className={styles.linkIcon} />
                    </Link>
                  )
                }
              </Tooltip>
            </div>
          ))
        }
      </div>

    </div>
  )
}