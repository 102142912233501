import React from 'react'
import { DatePicker } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { dateFormat } from 'utils/dateUtils'


type Filter = {
  state?: string[]
  begin?: string
  end?: string
};

export const getColumns = (filter?: Filter) => {
  const filterDates =
    Boolean(filter?.begin) && Boolean(filter?.end)
      ? [moment(filter?.begin), moment(filter?.end)]
      : undefined

  return [
    {
      title: 'Компенсация',
      dataIndex: 'name',
      key: 'name',
      width: 270,
    },
    {
      title: 'Сумма',
      dataIndex: 'value',
      key: 'value',
      width: 130,
      align: 'center',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      defaultFilteredValue: filterDates ?? undefined,
      //for filter when needed
      // filterDropdown: ({ clearFilters, confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
      //   const onDatePick = (dates: [moment.Moment, moment.Moment]) => {
      //     if (!dates) {
      //       if (clearFilters) {
      //         clearFilters()
      //       }
      //       confirm({ closeDropdown: true })
              
      //       return
      //     }
      //     //@ts-ignore
      //     setSelectedKeys([dates[0], dates[1]])
      //     confirm({ closeDropdown: true })
      //   }
          
      //   return (
      //   //@ts-ignore
      //     <DatePicker.RangePicker
      //       onChange={onDatePick}
      //       format='DD.MM.YYYY'
      //       value={selectedKeys}
      //     />
      //   )
      // },
    },
  ]
}

export const getRows = (
  compensations: APITYPES.Carwashes.GetCompensations.Resp
) => {
  const data = compensations.map((compensation) => {
    const row: any = {}

    getColumns().forEach((column) => {
      if (column.dataIndex === 'date') {
        // @ts-ignore
        row[column.dataIndex] = moment(compensation[column.dataIndex]).format(
          dateFormat
        )
      } else {
        // @ts-ignore
        row[column.dataIndex] = compensation[column.dataIndex]
      }
    })
    row.key = compensation.id

    return row
  })

  return data
}
