import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

import styles from '../MatchingExecutors/styles.module.sass'
import { State } from '../../../../../../../../stores/rootReducer'
import { UnmatchedPlacementsTable } from './components/UnmatchedPlacementsTable'


interface Props {
  onSubmit: () => void
}

const selector = (state: State) => ({ report: state.urentReducer.uploadedReport })


export const MatchingPlacements: FC<Props> = ({ onSubmit }) => {
  const { report } = useSelector(selector)

  useEffect(() => {
    if (report?.unmatched_placements && report?.unmatched_placements.length === 0) {
      onSubmit()
    }
  }, [report])

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedPlacementsTable unmatchedPlacements={report?.unmatched_placements || []} />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!report?.unmatched_placements?.every((item) => item.isMatched)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
