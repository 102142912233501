// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--sfch-{border:1px solid #ebedf0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/CustomerTariffs/CustomerTariff/TariffHeader/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,wBAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--sfch-"
};
export default ___CSS_LOADER_EXPORT___;
