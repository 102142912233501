// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-internship-form-module--footer--AVS5O{margin-top:1rem}.create-internship-form-module--roleContainer--SZA7m{max-height:168px;overflow:auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:.5rem;margin-bottom:1rem}.create-internship-form-module--roleRow--maV0c{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:1rem;padding-right:1rem}.create-internship-form-module--formItem--ecNh3{margin:0}.create-internship-form-module--flexGrow--ARoRB{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/internships/components/create-internship-form/create-internship-form.module.sass"],"names":[],"mappings":"AAAA,8CACE,eAAA,CAEF,qDACE,gBAAA,CACA,aAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,SAAA,CACA,kBAAA,CAEF,+CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CACA,kBAAA,CAGF,gDACE,QAAA,CAEF,gDACE,kBAAA,CAAA,mBAAA,CAAA,WAAA","sourcesContent":[".footer\n  margin-top: 1rem\n\n.roleContainer\n  max-height: 168px\n  overflow: auto\n  display: flex\n  flex-direction: column\n  gap: 0.5rem\n  margin-bottom: 1rem\n\n.roleRow\n  display: flex\n  align-items: center\n  gap: 1rem\n  padding-right: 1rem\n\n\n.formItem\n  margin: 0\n\n.flexGrow\n  flex-grow: 1\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "create-internship-form-module--footer--AVS5O",
	"roleContainer": "create-internship-form-module--roleContainer--SZA7m",
	"roleRow": "create-internship-form-module--roleRow--maV0c",
	"formItem": "create-internship-form-module--formItem--ecNh3",
	"flexGrow": "create-internship-form-module--flexGrow--ARoRB"
};
export default ___CSS_LOADER_EXPORT___;
