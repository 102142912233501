import React from 'react'
import { Button, Input, Popconfirm } from 'antd'

import { APITYPES } from 'types/apitypes'


export const getColumns = (
  tariff?: APITYPES.DoerTariff,
  onShiftPriceDelete?: (shiftCostId: string) => void,
  onConditionsChange?: (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => void,
  onShiftPriceChange?: (
    shiftCostId: string,
    shiftPrice: APITYPES.DoerTariffCost,
    partial: Partial<APITYPES.DoerTariff.ShiftPrices.PUT.Req>
  ) => void
) => {
  return [
    {
      title: 'По умолчанию',
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={1}
          cond={tariff?.condition_1}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_1',
      key: 'cost_1',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost_1'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={2}
          cond={tariff?.condition_2}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_2',
      key: 'cost_2',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost_2'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={3}
          cond={tariff?.condition_3}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_3',
      key: 'cost_3',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost_3'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={4}
          cond={tariff?.condition_4}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_4',
      key: 'cost_4',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost_4'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={5}
          cond={tariff?.condition_5}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_5',
      key: 'cost_5',
      align: 'center',
      render: (shiftPrice: APITYPES.DoerTariffCost) => (
        <RowInput
          shiftPrice={shiftPrice}
          keyValue='cost_5'
          onChange={onShiftPriceChange}
        />
      ),
    },
    {
      title: 'Действие',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 110,
      render: (shiftCostId: string) => (
        <Popconfirm
          title='Вы уверены, что хотите удалить задачу?'
          onConfirm={onShiftPriceDelete ? () => onShiftPriceDelete(shiftCostId) : undefined}
          okText='Да'
          cancelText='Нет'
          placement='bottomRight'
        >
          <Button type='link'>Удалить</Button>
        </Popconfirm>
      ),
    },
  ]
}

export const getRows = (shiftPrices: APITYPES.DoerTariffCost[]) => {
  return shiftPrices.map((shiftPrice) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'actions') {
        row[column.dataIndex] = shiftPrice.id
      } else if (column.dataIndex.includes('cost')) {
        //@ts-ignore
        row[column.dataIndex] = shiftPrice
      } else {
        //@ts-ignore
        row[column.dataIndex] = shiftPrice[column.dataIndex]
      }
    })
    row.key = shiftPrice.id

    return row
  })
}

type RowInputProps = {
  shiftPrice: APITYPES.DoerTariffCost
  keyValue: keyof APITYPES.DoerTariff.ShiftPrices.PUT.Req
  onChange?: (
    shiftCostId: string,
    shiftPrice: APITYPES.DoerTariffCost,
    partial: Partial<APITYPES.DoerTariff.ShiftPrices.PUT.Req>
  ) => void
};
const RowInput = ({ shiftPrice, keyValue, onChange }: RowInputProps) => {
  return (
    <Input
      type='number'
      size='small'
      defaultValue={shiftPrice[keyValue]}
      onBlur={
        onChange
          ? (e) => {
            const val =
                e.target?.value && e.target?.value !== '0'
                  ? e.target.value
                  : undefined

            onChange(shiftPrice.id, shiftPrice, { [keyValue]: val })
          }
          : undefined
      }
    />
  )
}

type HeaderInputProps = {
  num: number
  cond?: number | undefined
  onChange?: (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => void
};
const stl = { width: '70%' }
const HeaderInput = ({ cond, num, onChange }: HeaderInputProps) => {
  return (
    <>
      { 'Усл. ' }
      { num }
      { ' ' }
      <Input
        size='small'
        type='number'
        defaultValue={cond}
        style={stl}
        onBlur={
          onChange
            ? (e) => {
              const val = e.target?.value ? +e.target.value : null

              onChange({ [`condition_${num}`]: val })
            }
            : undefined
        }
      />
    </>
  )
}