import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type BillingState = {
  bills: APITYPES.Bill[]
  billsFilter: APITYPES.BillsFilter
  contractorsFilter: APITYPES.ContractorFilter
  bill: APITYPES.Bill | null
  billsHasMore: boolean
  contractors: APITYPES.Contractor[]
  contractor: APITYPES.Contractor | null
  records: APITYPES.ContractorRecord[]
  contractorsHasMore: boolean

  // contractors data with query type=partner
  partnerContractors: APITYPES.Contractor[]
  // contractors data with query type=doer
  doerContractors: APITYPES.Contractor[]

  billsDoers: APITYPES.Bill[]
  billsDoersFilter: APITYPES.BillsFilter
  billsDoersHasMore: boolean

  allDocuments: APITYPES.Billing.GetBillDownloadUrl.Resp

  rentData: APITYPES.Rent[] | null

  doerRecords: APITYPES.DoerRecords[]
  recordsHasMore: boolean

  billCalculations: APITYPES.DoerTariff.GetBillCalculations.Resp
  billCalculationsHasMore: boolean
  savedBillCalculation: APITYPES.DoerTariff.GetBillCalculations.Bill | null

  doerRecordsPeriod: APITYPES.Billing.GetDoerRecordsPeriod.Resp
  periodHasMore: boolean
};

export const initialBillingState: BillingState = {
  bills: [],
  billsFilter: {},
  contractorsFilter: {},
  bill: null,
  billsHasMore: true,
  contractors: [],
  contractor: null,
  records: [],
  contractorsHasMore: true,
  rentData: null,

  partnerContractors: [],
  doerContractors: [],

  billsDoers: [],
  billsDoersFilter: {},
  billsDoersHasMore: true,

  allDocuments: {},
  doerRecords: [],
  recordsHasMore: true,

  billCalculations: [],
  billCalculationsHasMore: true,
  savedBillCalculation:null,

  doerRecordsPeriod: [],
  periodHasMore: true,
}

export const billingReducer: Reducer<BillingState, Action> = (state = initialBillingState, a): BillingState => {
  switch (a.type) {
    case Actions.SET_RENT_DATA: {
      return {
        ...state,
        rentData: a.data,
      }
    }

    case Actions.SET_DOER_CONTRACTORS: {
      return {
        ...state,
        doerContractors: a.data,
      }
    }

    case Actions.SET_PARTNER_CONTRACTORS: {
      return {
        ...state,
        partnerContractors: a.data,
      }
    }


    case Actions.SET_BILLS:
      return {
        ...state,
        bills: a.data.offset === 0 ? a.data.bills : [...state.bills, ...a.data.bills],
        billsHasMore: !!a.data.bills.length,
      }

    case Actions.SET_BILLS_DOERS:
      return {
        ...state,
        billsDoers: a.data.offset === 0 ? a.data.billsDoers : [...state.billsDoers, ...a.data.billsDoers],
        billsDoersHasMore: !!a.data.billsDoers.length,
      }

    case Actions.CLEAN_BILLS:
      return {
        ...state,
        bills: [],
        billsHasMore: true,
      }

    case Actions.SET_BILLS_FILTER:
      return {
        ...state,
        billsFilter: a.data,
      }

    case Actions.CLEAN_BILLS_DOERS:
      return {
        ...state,
        billsDoers: [],
        billsDoersHasMore: true,
      }
    case Actions.CLEAN_EXPORT_BILLS:
      return {
        ...state,
        billsDoers: [],
        billsDoersHasMore: true,
      }

    case Actions.SET_BILLS_FILTER_DOERS:
      return {
        ...state,
        billsDoersFilter: a.data,
      }
    case Actions.SET_EXPORT_BILLS:
      return { ...state }
    case Actions.SET_CONTRACTORS_FILTER:
      return {
        ...state,
        contractorsFilter: a.data,
      }

    case Actions.SET_BILL:
      return {
        ...state,
        bill: a.data,
      }

    case Actions.SET_CONTRACTORS:
      return {
        ...state,
        contractors: [...state.contractors, ...a.data],
        contractorsHasMore: !!a.data.length,
      }

    case Actions.SET_CONTRACTOR:
      return {
        ...state,
        contractor: a.data,
      }

    case Actions.SET_CONTRACTOR_RECORDS:
      return {
        ...state,
        records: a.data,
      }

    case Actions.CLEAN_CONTRACTORS:
      return {
        ...state,
        contractors: [],
        contractorsHasMore: true,
      }

    case Actions.SET_ALL_DOCUMENTS:
      return {
        ...state,
        allDocuments: a.data,
      }
    case Actions.CLEAN_ALL_DOCUMENTS:
      return {
        ...state,
        allDocuments: {},
      }
    case Actions.CLEAN_DOER_RECORDS:
      return {
        ...state,
        doerRecords: [],
        recordsHasMore: true,
      }
    case Actions.SET_SAVED_BILL_CALCULATION:
      return {
        ...state,
        savedBillCalculation: a.data.savedBillCalculation,
      }
    case Actions.SET_BILL_CALCULATIONS:
      return {
        ...state,
        billCalculations: a.data.offset === 0 ? a.data.billCalculations
          : [...state.billCalculations, ...a.data.billCalculations],
        billCalculationsHasMore: !!a.data.billCalculations.length,
      }
    case Actions.CLEAN_BILL_CALCULATIONS:
      return {
        ...state,
        billCalculations: [],
        billCalculationsHasMore: true,
      }
    case Actions.SET_DOER_RECORDS_PERIOD:
      return {
        ...state,
        doerRecordsPeriod:
          a.data.offset === 0
            ? a.data.doerRecordsPeriod
            : [...state.doerRecordsPeriod, ...a.data.doerRecordsPeriod],
        periodHasMore: !!a.data.doerRecordsPeriod.length,
      }
    case Actions.CLEAR_DOER_RECORDS_PERIOD:
      return {
        ...state,
        doerRecordsPeriod:[],
        periodHasMore: true,
      }
  }

  return state
}
