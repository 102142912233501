import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { fetchEmployers, fetchLocations, fetchShiftTypes } from 'AC/shifts/shifts'
import { fetchRoles } from 'AC/doers/doerRoles'


const selector = (state: State) => ({
  locations: state.shiftsReducer.locations,
  shiftTypes: state.shiftsReducer.shiftTypes,
  roles: state.doersReducer.roles,
  employers: state.shiftsReducer.employers,
})

export const useShiftsInit = () => {
  const {shiftTypes, locations, roles, employers} = useSelector(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!shiftTypes.length) dispatch(fetchShiftTypes())
    if (!locations.length) dispatch(fetchLocations())
    if (!roles || !roles.length) dispatch(fetchRoles())
    if (!employers.length) dispatch(fetchEmployers())
  }, [])

  return { shiftTypes, locations, roles, employers }
}