import React from 'react'
import { Table } from 'antd'
import { useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'

import { getColumns, getRows } from './utils'


const selector = (state: State) => ({
  operations: state.statisticsReducer.operations,
  isLoading:
    state.requestsReducer.addOperationsStatistics === StatusRequest.LOADING,
})

export const StatisticsTable = () => {
  const { operations, isLoading } = useSelector(selector)

  return (
    <Table
      sticky
      size='large'
      loading={isLoading}
      columns={getColumns() as any}
      //@ts-ignore
      dataSource={getRows(operations)}
      pagination={false}
      scroll={{ y: getTableYScroll(500) }}
      bordered
      style={{ cursor: 'pointer' }}
    />
  )
}
