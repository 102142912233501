// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--omkVI{margin-top:12px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Rent/styles.module.sass"],"names":[],"mappings":"AAAA,6BACE,eAAA","sourcesContent":[".table\n  margin-top: 12px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--omkVI"
};
export default ___CSS_LOADER_EXPORT___;
