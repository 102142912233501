import React from 'react'

import { ROUTES } from 'config/routes'
import { NestedSwitchRoutes } from 'components/NestedSwitch'

import { Service } from './Service'


export const routes: NestedSwitchRoutes = [{
  path: ROUTES.DIRECTORY.SERVICES.ID.createPath(),
  component: <Service />,
}]