import React, { useMemo } from 'react'
import { Modal, Form, DatePicker, Checkbox } from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { FormFooter } from 'components/FormFooter'
import { createBill } from 'AC/billing/createBill'
import { useGetBillsDoers } from 'App/hooks'
import { ContractorSelector } from '../ContractorSelector'
import { APITYPES } from '../../../../../types/apitypes'

import ContractorType = APITYPES.ContractorType


type Props = {
  contractor_type: ContractorType
  isVisible: boolean
  onCancel: () => void
}

type ALL = 'all'
const { useForm } = Form
const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD.MM.YYYY'
const { RangePicker } = DatePicker

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const ALL: ALL = 'all'

export const CreateCard = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const { isVisible, onCancel, contractor_type: contractorType } = props
  const getBillsDoers = useGetBillsDoers()

  const isPartner = useMemo(() => contractorType === 'partner', [contractorType])
  const isDoer = useMemo(() => contractorType === 'doer', [contractorType])

  const onFinish = async (values: any) => {
    const data: any = {
      start: values.period[0].format(dateFormat),
      end: values.period[1].format(dateFormat),
      created: values.created.format(dateFormat),
      skip_disconnected: values.skip_disconnected,
      skip_zero: values.skip_zero,
    }

    if (values.contractor_ids !== ALL) {
      data.contractor_ids = [values.contractor_ids]
    } else {
      data.contractor_type = props.contractor_type
    }

    dispatch(createBill(data))
    onCancelClick()
    getBillsDoers()
  }

  const onCancelClick = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title='Формирование счета'
      visible={isVisible}
      onCancel={onCancelClick}
      width={740}
      footer={false}
    >
      <Form
        name='bills_form'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={
          {
            'skip_disconnected': isPartner,
            'skip_zero': isDoer,
          }
        }
      >
        <Form.Item
          name='contractor_ids'
          label='Контрагенты'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <ContractorSelector
            labelInValue={false}
            contractorType={props.contractor_type}
            extraOption={
              {
                label: 'Для всех контрагентов',
                value: ALL,
              }
            }
          />
        </Form.Item>
        <Form.Item
          label='Период'
          name='period'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          extra='Будут автоматически добавлены операции контрагента за период'
        >
          <RangePicker
            format={viewDateFormat}
          />
        </Form.Item>
        <Form.Item
          label='Создан'
          name='created'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          initialValue={moment()}
        >
          <DatePicker format={viewDateFormat} />
        </Form.Item>

        <Form.Item
          label='Исключить откл. контрагентов'
          name='skip_disconnected'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label='Исключить пустые счета'
          name='skip_zero'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
        
        <FormFooter
          onCancel={onCancelClick}
          position='right'
        />
      </Form>
    </Modal>
  )
}