import React from 'react'
import { useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'


const selector = (state: State) => ({ partners: state.partnersReducer.allPartners })

type Props = {
  value: APITYPES.Carwashes.Get.Resp[number]
}

export const Partner = ({ value }: Props) => {
  const { partners } = useSelector(selector)

  const partner = partners.find((item) => item.id === value.partner_id)
  
  return (
    <div>
      { partner?.legal_name ?? 'Партнера нет' }
    </div>
  )
}