import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Drawer, Form, Input, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { getFleetsCars } from 'AC/fleets/fleetsCars'
import { getUrlSearchParams } from 'utils/urlUtils'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { isObjectEmpty } from 'utils/filterUtils'

import { checkNullModels } from './utils'


const { Option } = Select

type Props = {
  onClose: () => void
  isOpen: boolean
  isIntersecting?: boolean
}

const selector = (state: State) => ({
  filter: state.fleetsReducer.fleetsCarsFilter,
  fleets: state.fleetsReducer.fleets,

})

export const Filters = ({ onClose, isOpen, isIntersecting }: Props) => {
  const [checked, setChecked] = useState(() => checkNullModels())

  const history = useHistory()
  const dispatch = useDispatch()
  const { filter, fleets } = useSelector(selector)
  const urlParams = getUrlSearchParams()

  const [grz, setGrz] = useDebouncedSearch({
    defaultValue: urlParams?.grz || filter?.grz,
    skipFirstRun: true
  }, () => onChangeFilers({ grz }))

  useEffect(() => {
    if (!isObjectEmpty(urlParams)) {
      dispatch(action(Actions.SET_FLEET_CAR_FILTER, { ...urlParams }))
      dispatch(getFleetsCars(urlParams))
    } else {
      dispatch(action(Actions.CLEAR_FLEETS_CARS, []))
      history.push(ROUTES.FLEETS.CARS.PARAMS.createPath({ ...filter }))
      dispatch(getFleetsCars(filter))
    }
  }, [])

  useEffect(() => {
    if (isIntersecting) {
      dispatch(getFleetsCars(filter))
    }
  }, [dispatch, filter, isIntersecting])


  const onChangeFilers = (partial: Partial<APITYPES.FleetCarFilter>) => {
    dispatch(action(Actions.CLEAR_FLEETS_CARS, []))

    const newFilter = {
      ...filter,
      ...partial,
    }

    history.push(ROUTES.FLEETS.CARS.PARAMS.createPath(newFilter))

    dispatch(action(Actions.SET_FLEET_CAR_FILTER, newFilter))
    dispatch(getFleetsCars(newFilter))
  }
  
  const onCheckboxFilers = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked

    setChecked(isChecked)
    dispatch(action(Actions.CLEAR_FLEETS_CARS, []))

    const newFilter = {
      ...filter,
      null_model: String(isChecked),
    }

    history.push(ROUTES.FLEETS.CARS.PARAMS.createPath(newFilter))

    dispatch(action(Actions.SET_FLEET_CAR_FILTER, newFilter))
    dispatch(getFleetsCars(newFilter))
  }

  const onResetFilers = () => {
    dispatch(action(Actions.CLEAR_FLEETS_CARS, []))
    dispatch(action(Actions.SET_FLEET_CAR_FILTER, {}))

    setGrz(undefined)

    history.push(ROUTES.FLEETS.CARS.path)
    dispatch(getFleetsCars())
    setChecked(false)
  }

  return (
    <Drawer
      title="Фильтры"
      placement="right"
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout="vertical">
        <Form.Item label="Оператор">
          <Select
            allowClear
            placeholder="Поиск по операторам"
            value={filter?.fleet_id}
            onChange={(value) => onChangeFilers({ fleet_id: value ?? undefined })}
          >
            {fleets?.map(fleet => (
              <Option key={fleet.id} value={fleet.id}>{fleet.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Гос. номер">
          <Input
            value={grz}
            onChange={(e) => setGrz(e.target.value)}
            placeholder="Поиск по гос. номеру"
          />
        </Form.Item>
        <Form.Item name="null_model">
          <Checkbox checked={checked} onChange={onCheckboxFilers}>Нет модели</Checkbox>
        </Form.Item>
        <Button onClick={onResetFilers}>
          Сбросить фильтры
        </Button>
      </Form>
    </Drawer>
  );
};
