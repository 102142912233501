import React from 'react'
import { Form, Modal, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { deleteClientTypeService, updateService } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'
import { DeleteButton } from 'components/DeleteButton'
import { showSureConfirm } from 'components/modals/SureModal'


type Props = {
  isVisible: boolean
  onCancel: () => void
  service: APITYPES.Services.Get.Resp[number] | null
}

const { useForm } = Form

export const EditServiceForm = (props: Props) => {
  const { service, isVisible, onCancel } = props
  const { id } = useParams<{
    id: string
  }>()
  const [form] = useForm()
  const dispatch = useDispatch()

  const onFinish = async (values: { position: number }) => {
    dispatch(updateService(id, service!.id, values))

    onCancel()
  }

  const onDelete = () => {
    showSureConfirm(()=>{ dispatch(deleteClientTypeService(id, service!.id))}, ()=>{})
    onCancel()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Редактировать'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <Form
        name='car_class'
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='position'
          initialValue={service?.position}
          label='Позиция'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Сохранить'
          leftElement={
            <DeleteButton
              onClick={onDelete}
            />
          }
        />
      </Form>
    </Modal>
  )
}