import React, { useState } from 'react'
import { Table } from 'antd'

import { getTableYScroll } from 'utils/scrollUtils'
import { useShiftsContext, useShiftTabsContext } from '../../context'
import { useShiftsInit } from '../../hooks/useShiftsInit'

import { getRows, getColumns, getRolesColumns, getRolesRows } from './utils'
import styles from './styles.module.sass'


export const ShiftsTable = () => {
  const { shifts, isShiftsLoading } = useShiftsContext()
  const { tabActions } = useShiftTabsContext()
  const { employers } = useShiftsInit()

  const [expanded, setExpanded] = useState<string[]>([])
  const onExpand = (_: boolean, record: any) => {
    if (expanded.includes(record.key)) {
      setExpanded((prev) => prev.filter((id) => id !== record.key))
    } else {
      setExpanded((prev) => [...prev, record.key])
    }
  }

  return (
    <Table
      sticky
      bordered
      size='small'
      pagination={false}
      className={styles.table}
      rowClassName={(record) => tabActions.isActiveTab(record.key) && styles.active}
      loading={isShiftsLoading}
      columns={getColumns(onExpand, employers) as any}
      dataSource={getRows(shifts || [])}
      onExpand={onExpand}
      expandedRowKeys={expanded}
      onRow={
        (record: any) => {
          return { onClick: () => tabActions.handleAddShiftTab(record.shift) }
        }
      }
      expandable={
        {
          showExpandColumn: false,
          expandedRowRender: (record) => (
            <Table
              size='small'
              bordered={false}
              pagination={false}
              showHeader={false}
              className={styles.innerTable}
              columns={getRolesColumns() as any}
              dataSource={getRolesRows(record.roles)}
            />
          ),
        }
      }
      scroll={{ y: getTableYScroll(170) }}
    />
  )
}
