import React from 'react'
import classNames from 'classnames'

import { Loader as LayoutSpinner } from 'components/Loader'

import styles from './styles.module.sass'


const SCROLL_DISTANCE_TO_BOTTOM = 200

export function scrollDistanceToBottom(el: Element) {
  const { scrollTop, clientHeight, scrollHeight } = el

  return scrollHeight - scrollTop - clientHeight
}

type OwnProps = {
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
  className?: string
  distanceToBottom?: number
  isReactive?: boolean
}

export const InfiniteScroll: React.FC<OwnProps> = (props) => {
  const {
    children,
    isLoading,
    hasMore,
    distanceToBottom = SCROLL_DISTANCE_TO_BOTTOM,
    isReactive = true,
  } = props

  const scrollableDivRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const scrollableDiv = scrollableDivRef.current

    if (!scrollableDiv || isLoading || !hasMore) {
      return
    }

    if (scrollableDiv.clientHeight >= scrollableDiv.scrollHeight && isReactive) {
      props.loadMore()
    }
  })

  const onScroll = () => {
    const scrollableDiv = scrollableDivRef.current

    if (!scrollableDiv || isLoading || !hasMore) {
      return
    }

    if (scrollDistanceToBottom(scrollableDiv) < distanceToBottom) {
      props.loadMore()
    }
  }

  return (
    <div
      ref={scrollableDivRef}
      className={classNames(styles.container, isLoading ? styles.whileLoading : props.className)}
      onScroll={onScroll}
    >
      { children }
      { isLoading && <LayoutSpinner /> }
    </div>
  )
}