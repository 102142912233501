import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Form, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { addNews } from 'AC/news/news'
import { FormFooter } from 'components/FormFooter'


const selector = (state: State) => ({ roles: state.doersReducer.roles })

const formLayout = { labelCol: { span: 6 } }

type Props = {
  onClose: () => void
}

export const AddForm = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { roles } = useSelector(selector)
  const { onClose } = props

  const onFinish = (values: any) => {
    dispatch(addNews({
      image: values.image,
      body: values.body,
      title: values.title,
      publication_date: new Date().toDateString(),
      role_ids: values.role_ids,
    }))

    onClose()
    form.resetFields()
  }

  return (
    <Form
      form={form}
      {...formLayout}
      onFinish={onFinish}
    >
      <Form.Item
        label='Заголовок'
        name='title'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Контент'
        name='body'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <TextArea autoSize={
          {
            minRows: 4,
            maxRows: 8,
          }
        }
        />
      </Form.Item>

      <Form.Item
        label='Дата'
        name='publication_date'
        initialValue={moment()}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <DatePicker
          format='LLL'
          showTime={
            {
              showHour: true,
              showMinute: true,
            }
          }
          style={{ width: '50%' }}
        />
      </Form.Item>

      <Form.Item
        label='Изображение'
        name='image'
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Роли'
        name='role_ids'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Select mode='multiple'>
          {
            roles && roles.map((role) => (
              <Select.Option
                value={role.id}
                key={role.id}
              >
                { role.name ? role.name : '' }
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>

      <FormFooter onCancel={onClose} />
    </Form>
  )
}
