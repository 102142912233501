import React, { useCallback } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { deleteLocationChildren } from 'AC/shifts/locations'
import { CustomEmpty } from 'components/CustomEmpty'
import { getColumns, getRows } from './utils'

type Props = {
  locationId: string | null
}

const selector = (state: State) => ({
  children: state.shiftsReducer.locationChildren,
  isLoading: state.requestsReducer.fetchLocationChildren === StatusRequest.LOADING,
  isProcessing: state.requestsReducer.addLocationChildren === StatusRequest.LOADING
    || state.requestsReducer.deleteLocationChildren === StatusRequest.LOADING,
})

export const ChildrenLocations = ({locationId}: Props) => {
  const dispatch = useDispatch()
  const {children, isLoading, isProcessing} = useSelector(selector)

  const onDelete = useCallback((childrenId: string) => {
    if (!locationId) return
    dispatch(deleteLocationChildren(locationId, {children_id: childrenId}))
  }, [locationId])

  return (
    <>
      {
        children?.length || isLoading || isProcessing
          ? <Table
            sticky
            size="small"
            loading={isLoading || isProcessing}
            columns={getColumns(onDelete) as any}
            dataSource={getRows(children || [])}
            pagination={false}
            scroll={{y: 281}}
          />
          : <CustomEmpty
            text='Нет дочерних элементов'
            style={{height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
          />
      }

    </>
  );
};
