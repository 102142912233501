import { APITYPES } from 'types/apitypes'
import { Reducer } from 'redux'
import { Action, Actions } from '../actions'


export type CustomerTariffsState = {
  customerTariffs: APITYPES.CustomerTariff[]
  customerTariff: APITYPES.CustomerTariff | null
  customerTariffProducts: APITYPES.CustomerTariffProduct[]
  customerTariffLocations: APITYPES.CustomerTariffLocation[]
  customerTariffsFilter: APITYPES.CustomerTariffFilter
}

export const initialCustomerTariffsState = {
  customerTariffs: [],
  customerTariff: null,
  customerTariffProducts: [],
  customerTariffLocations: [],
  customerTariffsFilter: {},
}

export const customerTariffsReducer: Reducer<CustomerTariffsState, Action> = (state = initialCustomerTariffsState, a): CustomerTariffsState => {
  switch (a.type) {
    case Actions.SET_CUSTOMER_TARIFF: {
      return {
        ...state,
        customerTariff: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS: {
      return {
        ...state,
        customerTariffs: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_LOCATIONS: {
      return {
        ...state,
        customerTariffLocations: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_PRODUCTS: {
      return {
        ...state,
        customerTariffProducts: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_FILTER: {
      return {
        ...state,
        customerTariffsFilter: a.data
      }
    }
  }

  return state
}