import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { deleteCustomerTariffLocations, fetchCustomerTariffLocations } from 'AC/products/tariffs'
import { getTableYScroll } from 'utils/scrollUtils'
import { getColumns, getRows } from './utils'

type Props = {
  tariffId: string
}

const selector = (state: State) => ({
  customerTariffLocations: state.customerTariffsReducer.customerTariffLocations,
  isLoading: state.requestsReducer.fetchCustomerTariffLocations === StatusRequest.LOADING
})

export const TariffLocations = ({tariffId}: Props) => {
  const dispatch = useDispatch()
  const {customerTariffLocations, isLoading} = useSelector(selector)

  useEffect(() => {
    dispatch(fetchCustomerTariffLocations(tariffId))
    return () => {
      dispatch(action(Actions.SET_CUSTOMER_TARIFFS_LOCATIONS, []))
    }
  }, [])

  const onDelete = (locationId: string) => {
    dispatch(deleteCustomerTariffLocations(tariffId, locationId))
  }

  return (
    <Table
      sticky
      bordered
      size='small'
      pagination={false}
      loading={isLoading}
      style={{cursor: 'pointer'}}
      columns={getColumns(onDelete) as any}
      dataSource={getRows(customerTariffLocations)}
      scroll={{y: getTableYScroll(364)}}
    />
  );
};
