import { APITYPES } from 'types/apitypes'
import { getGroupTag } from '../../../utils'


export const getColumns = () => {
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 550,
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
      align: 'center',
      render: (group: string) => getGroupTag(group),
    },
  ]
}

export const getRows = (cars: APITYPES.CarClass[]) => {
  const data = cars.map((car) => {
    const row: any = {}

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = car[column.dataIndex]
      
      return
    })
    row.key = car.id
    row.car = car

    return row
  })

  return data
}