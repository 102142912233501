import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import { fetchTables } from './carwashes'


export const copyTariff = (carwashId: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('copyTariff', StatusRequest.LOADING))
      
    try {
      const id = getState().carwashesReducer.carwash?.id

      if (!id) {return}

      const resp = await api.post(APITYPES.CopyTariff.URL, {
        source_carwash_id: carwashId,
        target_carwash_id: id,
      })
    
      if (resp) {
        dispatch(fetchTables(id))
        dispatch(changeRequestState('copyTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('copyTariff', StatusRequest.ERROR))

      showError(e.response.data)
  
      throw(e)
    }
  }
}