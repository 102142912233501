import { useEffect, useRef, useState } from 'react'
import { useDebounce } from '../debounce'

type InitProps = {
  delay?: number
  defaultValue?: string
  disableOnEmpty?: boolean
  skipFirstRun?: boolean
}

type DebounceTriggerFnType = (args?: any) => void

export const useDebouncedSearch = (
  {
    delay = 500,
    defaultValue = undefined,
    disableOnEmpty = false,
    skipFirstRun = false
  }: InitProps, triggerCb?: DebounceTriggerFnType) => {
  const [search, setSearch] = useState<string | undefined>(defaultValue)
  const debouncedSearch = useDebounce(search, delay)
  const isFirstSkipped = useRef(skipFirstRun)

  useEffect(() => {
    if (!triggerCb) return

    if(isFirstSkipped.current) {
      isFirstSkipped.current = false
      return;
    }

    if (disableOnEmpty) {
      if(Boolean(debouncedSearch)) triggerCb()
    } else {
      triggerCb()
    }
  }, [debouncedSearch])

  return [search, setSearch, debouncedSearch]
}