import { pickBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { api } from 'index'
import { APITYPES } from 'types/apitypes'


export const useGetRoles = ({ cityId, roleId }: {cityId?: string | number, roleId?: string }) => {
  const [roles, setRoles] = useState<APITYPES.Role[]>([])

  const filter = useMemo(() => pickBy({
    city_id: Number(cityId),
    role_id: roleId,
  }), [cityId, roleId])

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await api.get(APITYPES.Doers.Roles.GET.URL, { params: { ...filter } })

        setRoles(resp)
      } catch (e) {
        console.log(e)
      }
    }

    getData()
  }, [filter])

  return roles
}

export const useGetPenalties = ({ cityId, roleId }: {cityId: string | number, roleId: string }) => {
  const [penalties, setPenalties] = useState<APITYPES.Performers.GetPenalties.Resp[]>([])

  const filter = useMemo(() => pickBy({
    city_id: Number(cityId),
    role_id: roleId,
  }), [cityId, roleId])

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await api.get(APITYPES.Performers.GetPenalties.URL, { params: { ...filter } })

        setPenalties(resp)
      } catch (e) {
        console.log(e)
      }
    }

    getData()
  }, [filter])

  return penalties
}