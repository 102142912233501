import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { getCityName } from '../../../../utils/enumUtils'


export type EditModalType = {
  isOpen: boolean
  fleetService: APITYPES.FleetService | null
};

export type SubTasks = {
  id: string
  title: string
  description: string
  required: boolean
  exclusive: null
  question: null
  grz_required: boolean
  serial: number
  action: string
  subtasks_min: number
  min_optional_elements: number
  elements: []
};

export const getColumns = () => {
  return [
    {
      title: 'Оператор',
      dataIndex: 'fleet_id',
      key: 'fleet_id',
    },
    {
      title: 'Город',
      dataIndex: 'city_id',
      key: 'city_id',
    },
    {
      title: 'Класс авто',
      dataIndex: 'car_class_id',
      key: 'car_class_id',
    },
    {
      title: 'Задача',
      dataIndex: 'task_title',
      key: 'task_id',
    },
    {
      title: 'Услуга',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Начало',
      dataIndex: 'begin',
      key: 'begin',
    },
    {
      title: 'Конец',
      dataIndex: 'end',
      key: 'end',
    },
  ]
}

export const getRows = (
  services: APITYPES.FleetService[],
  fleets: APITYPES.Fleet[]
) => {
  const dateFormat = 'DD.MM.YYYY'

  return services.map((service) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'begin' || column.dataIndex === 'end') {
        //@ts-ignore
        row[column.dataIndex] = service[column.dataIndex]
          ? moment(service[column.dataIndex]).format(dateFormat)
          : undefined
      } else if (column.dataIndex === 'fleet_id') {
        row[column.dataIndex] = //@ts-ignore
          fleets.find((fleet: APITYPES.Fleet) => fleet.id === service[column.dataIndex])?.name
      } else if (column.dataIndex === 'city_id') {
        row[column.dataIndex] = getCityName(service[column.dataIndex])
      } else if (column.dataIndex === 'car_class_id') {
        row[column.dataIndex] = service.car_class_id ? 'Базовый' : ''
      } else {
        //@ts-ignore
        row[column.dataIndex] = service[column.dataIndex]
      }
    })
    row.key = service.id
    row.service = service

    return row
  })
}

export function getAllSubtask(tasks: any) {
  const allSubtasks: any = []

  function traverseSubtasks(subtasks: any) {
    if (!subtasks) {
      return
    }

    for (const subtask of subtasks) {
      allSubtasks.push(subtask)
      traverseSubtasks(subtask.subtasks)
    }
  }

  traverseSubtasks(tasks)

  return allSubtasks
}
