import React from 'react'
import { Modal, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'

import { offPartner } from 'AC/partners/partners'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  id: string
  onCancel: () => void
}

export const Off = (props: Props) => {
  const { isVisible, onCancel, id } = props

  const dispatch = useDispatch()

  const onFinish = (values: { reason: string }) => {
    dispatch(offPartner(id, values.reason))

    onCancel()
  }

  return (
    <Modal
      title='Отключение партнера'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='off'
        onFinish={onFinish}
      >
        <Form.Item
          name='reason'
          label='Причина отключения'
          initialValue=''
        >
          <Input />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Отключить'
        />
      </Form>
    </Modal>
  )
}