import React from 'react'
import { Form, Input, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { addProductGroup } from 'AC/products/products'
import { required } from 'utils/formUtils'
import { FormFooter } from 'components/FormFooter'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AddModal = ({isOpen, onClose}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    dispatch(addProductGroup(values))
    onClose()
  }


  return (
    <Modal
      title="Создание группы продуктов"
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      afterClose={form.resetFields}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item label='Название' name='name' rules={required}>
          <Input placeholder='Введите название' />
        </Form.Item>

        <FormFooter onCancel={onClose} />
      </Form>
    </Modal>
  );
};
