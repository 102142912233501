// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addButton--PoDrg{width:100%;margin:0 0 4px 0}.styles-module--tabPane--keBK2{width:200px;white-space:break-spaces;text-align:left}.styles-module--tabs--s-dqa{height:calc(100vh - 93.5px)}.styles-module--tabs--s-dqa>div:first-child>div:first-child{padding:0 8px;width:100%}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/Services/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,UAAA,CACA,gBAAA,CAEJ,+BACI,WAAA,CACA,wBAAA,CACA,eAAA,CAEJ,4BACI,2BAAA,CACA,4DACI,aAAA,CACA,UAAA","sourcesContent":[".addButton\n    width: 100%\n    margin: 0 0 4px 0\n\n.tabPane\n    width: 200px\n    white-space: break-spaces\n    text-align: left\n\n.tabs\n    height: calc(100vh - 93.5px)\n    & > div:first-child > div:first-child\n        padding: 0 8px\n        width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "styles-module--addButton--PoDrg",
	"tabPane": "styles-module--tabPane--keBK2",
	"tabs": "styles-module--tabs--s-dqa"
};
export default ___CSS_LOADER_EXPORT___;
