import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { getTableYScroll } from 'utils/scrollUtils'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'
import { action, Actions } from 'actions'
import { useQueryParams } from 'utils/hooks/useQueryParams'

import { AddModal } from './AddModal'
import { EditModal } from './EditModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'
import { Filters } from './Filters'


type EditModalType = {
  isOpen: boolean
  role: APITYPES.Role | null
}


const selector = (state: State) => {
  const { roles, rolesFilter: filter } = state.doersReducer

  const isFetchRolesLoading = state.requestsReducer.fetchRoles === StatusRequest.LOADING
  const isRoleUpdating = state.requestsReducer.updateRole === StatusRequest.LOADING
  const isRolesTableLoading = isFetchRolesLoading || isRoleUpdating

  return {
    roles,
    filter,
    isRolesTableLoading,
  }
}

export const Roles = () => {
  const queryParams = useQueryParams()
  const dispatch = useDispatch()
  const { isRolesTableLoading, roles, filter } = useSelector(selector)


  const [addModal, setAddModal] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [editModal, setEditModal] = useState<EditModalType>({
    isOpen: false,
    role: null,
  })
  const closeEditModal = () => setEditModal({
    isOpen: false,
    role: null,
  })
  const openEditModal = (role: APITYPES.Role) => setEditModal({
    isOpen: true,
    role: role,
  })
  const toggleAddModal = () => setAddModal(!addModal)
  const toggleFilter = () => setIsFilterOpen((prev) => !prev)

  useEffect(() => {
    if (!isObjectEmpty(queryParams)) {
      dispatch(action(Actions.SET_ROLES_FILTER, { ...queryParams }))
    }
  }, [])

  return (
    <>
      <div>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            className={styles.addBtn}
            onClick={toggleAddModal}
          >
            Добавить роль
          </Button>

          <Button
            size='small'
            onClick={toggleFilter}
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          />
        </div>

        <Table
          sticky
          loading={isRolesTableLoading}
          columns={getColumns() as any}
          dataSource={getRows(roles || [])}
          pagination={false}
          className={styles.table}
          bordered
          scroll={{ y: getTableYScroll(193) }}
          onRow={(record: any) => ({ onClick: () => openEditModal(record.role) })}
        />
      </div>

      <AddModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />
      <EditModal
        isOpen={editModal.isOpen}
        onClose={closeEditModal}
        role={editModal.role}
      />

      <Filters
        isOpen={isFilterOpen}
        onClose={toggleFilter}
      />
    </>
  )
}
