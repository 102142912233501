import React, { FC } from 'react'

import { UModal } from 'shared/ui/u-modal'
import { APITYPES } from 'types'

import { EditInternshipShiftsForm } from './edit-internship-shifts-form'


type EditCountInternshipModalProps = {
  shifts: APITYPES.Internships.InternshipsShift[] | null
  onClose: () => void
}

export const EditInternshipShiftsModal: FC<EditCountInternshipModalProps> = ({ shifts, onClose }) => {
  return (
    <UModal
      title='Изменение количества мест'
      open={!!shifts}
      footer={false}
      onCancel={onClose}
      destroyOnClose
    >
      <EditInternshipShiftsForm
        shifts={shifts}
        onClose={onClose}
      />
    </UModal>
  )
}
