import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { isToday, eachDayOfInterval } from 'date-fns'
import { Calendar } from 'antd'

import { State } from 'stores/rootReducer'
import { createSchedule, getSchedule } from 'AC/carwashes/schedules'
import { Loader } from 'components/Loader'
import { action, Actions } from 'actions'

import { Period } from './Period'
import styles from './styles.module.sass'
import { isDayBelongsToInterval, isEdited, isWeekend, getInnerCustomSchedule } from './utils'


const selector = (state: State) => {
  const { schedule, schedules, scheduleIntervalsMap } = state.carwashesReducer

  return {
    schedule,
    schedules,
    scheduleIntervalsMap,
  }
}

export const WorkingTime = () => {
  const { schedules, schedule, scheduleIntervalsMap } = useSelector(selector)

  const [selectedPeriod, setSelectedPeriod] = useState<{
    start: Date | null
    end: Date | null
  }>({
    start: null,
    end: null,
  })

  const dispatch = useDispatch()

  useEffect(()=>{
    const periodSchedule = schedules?.find((item)=>{
      if (!selectedPeriod.start) {return}

      if (!item.active_from || !item.active_to) {return false}

      return isDayBelongsToInterval(selectedPeriod.start, {
        start: item.active_from,
        end: item.active_to,
      }) || selectedPeriod.end && isDayBelongsToInterval(selectedPeriod.end, {
        start: item.active_from,
        end: item.active_to,
      })
    })

    dispatch(getSchedule(periodSchedule))

    if (!selectedPeriod.end || !selectedPeriod.start) { return }

    const innerCustomSchedule = getInnerCustomSchedule(schedules, {
      start: selectedPeriod.start,
      end: selectedPeriod.end,
    })

    if (innerCustomSchedule) {
      setSelectedPeriod((value)=>({
        start: value.end,
        end: null,
      }))
    }
  }, [dispatch, schedules, selectedPeriod, schedule])

  useEffect(()=>{
    if (schedule && schedule.active_from && schedule.active_to) {
      setSelectedPeriod(()=>({
        start: new Date(schedule.active_from),
        end: new Date(schedule.active_to),
      }))
    }
  }, [schedule])

  if (!schedules) {
    return <Loader />
  }

  const days = selectedPeriod.start ? eachDayOfInterval({
    start: selectedPeriod.start,
    end: selectedPeriod.end ?? selectedPeriod.start,
  }) : []

  const onCreateSchedule = () => {
    if (!selectedPeriod.start) {return}

    dispatch(createSchedule({
      description: '',
      active_from: selectedPeriod.start,
      active_to:selectedPeriod.end ? selectedPeriod.end :
        selectedPeriod.start,
    }))
  }

  const onReset = () => {
    setSelectedPeriod({
      start: null,
      end: null,
    })
  }

  const onSelect = (date: any) => {
    const formatDate = new Date(date)

    if (selectedPeriod.end) {
      setSelectedPeriod({
        start: formatDate,
        end: null,
      })

      dispatch(action(Actions.SET_SCHEDULE, null))

      return
    }

    if (selectedPeriod.start){
      if (selectedPeriod.start > formatDate) {
        setSelectedPeriod({
          start: formatDate,
          end: selectedPeriod.start,
        })

        return
      }

      setSelectedPeriod({
        start:selectedPeriod.start,
        end: formatDate,
      })

      return
    }

    setSelectedPeriod({
      start: formatDate,
      end: null,
    })
  }

  return (
    <div className={styles.root}>
      <Period
        onReset={onReset}
        onCreateSchedule={onCreateSchedule}
        days={days}
        selectedPeriod={selectedPeriod}
        schedule={schedule}
        schedules={schedules}
        scheduleIntervalsMap={scheduleIntervalsMap}
      />
      <div className={cn('site-calendar-demo-card', styles.calendarBlock)}>
        <Calendar
          fullscreen={false}
          dateFullCellRender={
            (day: any)=>{
              const date = new Date(day)

              return (
                <div
                  className={
                    cn(
                      styles.cell,
                      isEdited(date, schedules) && styles.yellow,
                      isWeekend(date, schedules) && styles.red,
                      selectedPeriod.start && isDayBelongsToInterval(date, {
                        start: selectedPeriod.start,
                        end: selectedPeriod.end ?? selectedPeriod.start,
                      }) && styles.selected,
                      isToday(date) && styles.today,

                    )
                  }
                >
                  { day.format('DD') }
                  {
                    isToday(date) && (
                      <div className={styles.todayCircle} />
                    )
                  }
                </div>
              )
            }
          }
          onSelect={onSelect}
        />
      </div>
    </div>
  )
}