import React from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { APITYPES } from 'types/apitypes'
import { createService } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'

const { ServiceProvider } = APITYPES


const { useForm } = Form
const { TextArea } = Input

type Props = {
  isVisible: boolean
  onCancel: () => void
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export const CreateCard = (props: Props) => {
  const { isVisible, onCancel } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = useForm()
  const providers = Object.entries(ServiceProvider)

  const onFinish = async (values: APITYPES.AllServices.Post.Req) => {
    dispatch(createService(values, history))

    onCancel()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Создание'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      afterClose={afterClose}
    >
      <Form
        name='client_types'
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='name'
          label='Название услуги'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='description'
          label='Описание услуги'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name='provider'
          label='Провайдер'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select>
            {
              providers.map(provider => (
                <Select.Option value={provider[0]} key={provider[0]}>{provider[1]}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <FormFooter onCancel={onCancel} />
      </Form>
    </Modal>
  )
}