import { compact } from 'lodash'

import { APITYPES } from 'types/apitypes'
import { getEnumKeys } from 'utils/enumUtils'


export const getLocationsOptions = (locations: APITYPES.Location[] | null) => {
  if (!locations) {
    return []
  }
  const cities = getEnumKeys(APITYPES.Cities)

  return locations.map((location) => ({
    city_id: location.city_id,
    label: `${cities[location.city_id - 1]}, ${location.name}`,
    location: location,
    value: location.id,
  }))
}

export const getEmployerOptions = (employers: APITYPES.Employer[] | null) => {
  if (!employers?.length) {
    return []
  }

  return employers.map((employer) => ({
    label: employer.name,
    value: employer.id,
  }))
}

export const getTypesOptions = (
  cityIds: Array<number | null> | null,
  shiftTypes: APITYPES.ShiftType[] | null,
  roleCityId?: number | undefined
) => {
  let resultCityIds: number[] = []

  if (!shiftTypes) {
    return []
  }
  const shiftTypesArr = shiftTypes.map(({ city_id, name, id, begin, end , roles }) => ({
    city_id,
    label: (begin && end) ? `${name} (${formatTime(begin)} - ${formatTime(end)})` : name,
    roles,
    value: id,
  }))

  if (cityIds) {
    resultCityIds = compact(cityIds)
  }

  if (!resultCityIds.length && roleCityId) {
    resultCityIds.push(roleCityId)
  }

  if (!resultCityIds.length) {
    return shiftTypesArr
  }

  return shiftTypesArr.filter(
    (type) => resultCityIds.includes(type.city_id)
  )
}

export const formatTime = (time: number) => {
  const [hours, minutes] = time.toString().split(':')

  return `${hours}:${minutes}`
}
