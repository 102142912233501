import React, { FC, useEffect, useState } from 'react'
import { Modal, Steps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { StepProps } from 'antd/lib/steps'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { MatchingUnmatchedExecutors } from './steps/MatchingExecutors/MatchingExecutors'
import { getYandexLavkaReportById } from '../../../../../../AC/yandexLavka/reports'
import { MatchingPlacements } from './steps/MatchingPlacements/MatchingPlacements'
import { FinalStep } from './steps/FinalStep/FinalStep'

import styles from './styles.module.sass'


enum STEPS {
  MATCHING_EXECUTORS = 0,
  MATCHING_LOCATIONS = 1,
  PROCESSING = 2,
}


interface Props {
  reportId: string
  onCancel: () => void
}

interface Step {
  title: string
  component: React.ReactNode
}


const selector = (state: State) => ({
  unmatchedExecutors: state.yandexLavkaReducer.unmatchedExecutors,
  unmatchedPlacements: state.yandexLavkaReducer.unmatchedPlacements,
  isLoading: state.requestsReducer.getYandexLavkaReportById === StatusRequest.LOADING,
})


export const MatchingModal: FC<Props> = ({
  reportId,
  onCancel,
}) => {
  const dispatch = useDispatch()
  const {
    unmatchedExecutors,
    unmatchedPlacements,
    isLoading,
  } = useSelector(selector)

  const [current, setCurrent] = useState<STEPS>(STEPS.MATCHING_EXECUTORS)
  const changeStep = (step: STEPS) => {
    setCurrent(step)
  }

  const stepsMap: Record<STEPS, Step> = {
    [STEPS.MATCHING_EXECUTORS]: {
      title: 'Связка исполнителей',
      component: <MatchingUnmatchedExecutors onSubmit={() => changeStep(STEPS.MATCHING_LOCATIONS)} />,
    },
    [STEPS.MATCHING_LOCATIONS]: {
      title: 'Связка локаций',
      component: <MatchingPlacements onSubmit={() => changeStep(STEPS.PROCESSING)} />,
    },
    [STEPS.PROCESSING]: {
      title: 'Обработка',
      component: <FinalStep
        onFinish={onCancel}
        reportId={reportId}
      />,
    },
  }

  const steps: StepProps[] = Object.values(stepsMap).map(({ title }) => ({ title }))

  useEffect(() => {
    if (!reportId) {
      return
    }
    dispatch(getYandexLavkaReportById(reportId))
  }, [reportId])

  useEffect(() => {
    if (!unmatchedExecutors || !unmatchedPlacements || isLoading) {
      return
    }

    if (unmatchedExecutors.length === 0 && unmatchedPlacements.length === 0) {
      changeStep(STEPS.PROCESSING)
    }

    if (unmatchedExecutors.length === 0 && unmatchedPlacements.length) {
      changeStep(STEPS.MATCHING_LOCATIONS)
    }
  }, [isLoading])

  return (
    <Modal
      title='Связка данных'
      open={!!reportId}
      onCancel={onCancel}
      destroyOnClose
      width={1200}
      footer={false}
    >

      {
        isLoading
          ? (
            <div className={styles.loaderWrapper}>
              <Loader
                circleIcon
                noPadding
                size='large'
              />
            </div>
          )
          : (
            <>
              <Steps
                current={current}
                items={steps}
              />
              { stepsMap[current].component }
            </>
          )
      }

    </Modal>
  )
}
