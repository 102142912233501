// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--marginLeft--INQuG{margin-left:0px}.styles-module--modal--\\+vVma{min-width:600px !important}.styles-module--innButton--cy55v{margin-left:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/Card/CreateCard/styles.module.sass"],"names":[],"mappings":"AAAA,kCACI,eAAA,CAEJ,8BACI,0BAAA,CAEJ,iCACI,gBAAA","sourcesContent":[".marginLeft\n    margin-left: 0px\n\n.modal\n    min-width: 600px!important\n\n.innButton\n    margin-left: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginLeft": "styles-module--marginLeft--INQuG",
	"modal": "styles-module--modal--+vVma",
	"innButton": "styles-module--innButton--cy55v"
};
export default ___CSS_LOADER_EXPORT___;
