import React from 'react'
import { Card } from 'antd'
import { EditOutlined, PictureOutlined, SettingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { navigate } from 'utils/tabelUtils'
import { NewsModalType } from '../utils'

import styles from './styles.module.sass'


const { Meta } = Card

type Props = {
  news: APITYPES.News
  newsHandler: (type: NewsModalType, news?: APITYPES.News) => void
}

export const NewsCard = (props: Props) => {
  const { news, newsHandler } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const openSingleNews = (e: React.MouseEvent) => {
    dispatch(action(Actions.SET_CURRENT_NEWS, news))
    navigate(ROUTES.DOERS.NEWS.PARAMS.createPath(news.id), history)(e)
  }

  const isImage = !!news.image

  return (
    <Card
      className={styles.card}
      cover={
        <div
          className={styles.cover}
          onClick={openSingleNews}
        >
          {
            isImage
              ? (
                <img
                  alt={news.title}
                  src={news.image}
                />
              )
              : (
                <div className={styles.noImage}>
                  <PictureOutlined />
                  <span>No Image</span>
                </div>
              )
          }

          <div
            className={styles.pub_date}
            onClick={(e) => e.stopPropagation()}
          >
            { moment(news.publication_date).format('LLL') }
          </div>
        </div>
      }
      actions={
        [
          <SettingOutlined
            key='setting'
            onClick={() => newsHandler(NewsModalType.MANAGE_NEWS, news)}
          />,
          <EditOutlined
            key='edit'
            onClick={() => newsHandler(NewsModalType.EDIT_NEWS, news)}
          />,
        ]
      }
    >
      <Meta title={<div className={styles.title}>{ news.title }</div>} />
      <div className={styles.body}>
        { news.body }
      </div>
    </Card>
  )
}
