import React from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Form, Input, Select, DatePicker } from 'antd'
import moment from 'moment-timezone'

import { APITYPES } from 'types/apitypes'
import { addNewCompensation } from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'
import { backDateFormat, dateFormat } from 'utils/dateUtils'
import { required } from 'utils/formUtils'


type Props = {
  carwash_id: string
  isVisible: boolean
  onCancel: () => void
  compensationTypes: APITYPES.Compensation[]
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const { useForm } = Form

export const NewCompensationModal = (props: Props) => {
  const { isVisible, onCancel, carwash_id, compensationTypes } = props
  const dispatch = useDispatch()
  const [form] = useForm()

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      date: values.date
        ? moment(values.date).format(backDateFormat)
        : moment().format(backDateFormat),
    }

    dispatch(addNewCompensation({ carwash_id }, body))
    onCancelClick()
  }

  const onCancelClick = () => {
    form.resetFields()
    onCancel()
  }

  const handleSelect = (id: string) => {
    const newValue = compensationTypes.find((type) => type.id === id)?.value

    if (newValue) {
      form.setFieldsValue({
        compensation_id: id,
        value: newValue,
      })
    } else {
      form.setFieldsValue({
        compensation_id: id,
        value: '',
      })
    }
  }

  return (
    <Modal
      title='Добавить компенсацию'
      open={isVisible}
      onCancel={onCancelClick}
      width={700}
      footer={false}
    >
      <Form
        {...formItemLayout}
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          name='compensation_id'
          label='Компенсация'
          rules={required}
        >
          <Select
            showSearch
            onChange={(e) => handleSelect(e)}
          >
            {
              compensationTypes.map((type) => (
                <Select.Option
                  key={type.id}
                  value={type.id}
                >
                  { type.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Сумма:'
          name='value'
          rules={required}
        >
          <Input
            type='number'
          />
        </Form.Item>

        <Form.Item
          name='date'
          label='Дата'
          rules={required}
          initialValue={moment()}
        >
          <DatePicker
            format={dateFormat}
          />
        </Form.Item>
        <Form.Item
          name='description'
          label='Описание'
          initialValue=''
        >
          <Input.TextArea
            maxLength={250}
            autoSize={
              {
                minRows: 3,
                maxRows: 6,
              }
            }
          />
        </Form.Item>
        <FormFooter
          onCancel={onCancelClick}
          okText='Добавить'
        />
      </Form>
    </Modal>
  )
}
