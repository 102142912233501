import { useEffect, useState } from 'react'
import momentTimezone from 'moment-timezone'


export const useGetTimezoneFromLocalstorage = () => {
  const [timezone, setTimezone] = useState(localStorage.getItem('timezone') || momentTimezone.tz.guess())

  useEffect(() => {
    const handleStorageChange = () => {
      setTimezone(localStorage.getItem('timezone') ?? momentTimezone.tz.guess())
    }

    window.addEventListener('storage', handleStorageChange, false)

    return () => {
      window.removeEventListener('storage', handleStorageChange, false)
    }
  }, [])

  return timezone
}