import { APITYPES } from 'types/apitypes'

import RESERVE_STATE = APITYPES.Reserves.RESERVE_STATE
import STATE_TYPE = APITYPES.Internships.STATE_TYPE


export type ColorType = 'gray' | 'red' | 'green' | 'blue' | 'orange'
export type StatusType = {
  label: string
  color: ColorType
}

export const mapReserveStateOptions: Record<RESERVE_STATE, string> = {
  [RESERVE_STATE.BOOKED]: 'Записан',
  [RESERVE_STATE.CANCELED]: 'Отменён',
  [RESERVE_STATE.CONFIRMED]: 'Подтверждён',
}

export const mapReserveStateToColor: Record<RESERVE_STATE, ColorType> = {
  [RESERVE_STATE.BOOKED]: 'gray',
  [RESERVE_STATE.CANCELED]: 'red',
  [RESERVE_STATE.CONFIRMED]: 'blue',
}

export const mapStateToStatus: Record<STATE_TYPE, StatusType> = {
  [STATE_TYPE.CANCELED]: {
    label: 'Отменён',
    color: 'red',
  },
  [STATE_TYPE.CONFIRMED]: {
    label: 'Подтверждён',
    color: 'blue',
  },
  [STATE_TYPE.STARTED]: {
    label: 'Явился на стажировку',
    color: 'green',
  },
  [STATE_TYPE.ENDED]: {
    label: 'Завершил стажировку',
    color: 'orange',
  },
  [STATE_TYPE.FAILED]: {
    label: 'Не прошёл стажировку',
    color: 'red',
  },
}


export const stateOptions = [
  {
    label: mapStateToStatus[STATE_TYPE.CANCELED].label,
    value: STATE_TYPE.CANCELED,
  },
  {
    label: mapStateToStatus[STATE_TYPE.CONFIRMED].label,
    value: STATE_TYPE.CONFIRMED,
  },
  {
    label: mapStateToStatus[STATE_TYPE.STARTED].label,
    value: STATE_TYPE.STARTED,
  },
  {
    label: mapStateToStatus[STATE_TYPE.ENDED].label,
    value: STATE_TYPE.ENDED,
  },
  {
    label: mapStateToStatus[STATE_TYPE.FAILED].label,
    value: STATE_TYPE.FAILED,
  },
]