import React from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { addLocationChildren } from 'AC/shifts/locations'
import { FormFooter } from 'components/FormFooter'

const Locations = APITYPES.ShiftLocationsTypes;

type Props = {
  locationId: string | null
  onClose: () => void
  afterAdd: () => void
  disabled: boolean
}

const selector = (state: State) => {
  const {allLocations, locationChildren} = state.shiftsReducer
  let filteredLocations = [...allLocations]
  if (locationChildren) {
    filteredLocations = filteredLocations.filter(loc => locationChildren.every(l => loc.id !== l.id))
  }

  return {
    locations: filteredLocations,
  }
}

export const AddChildrenForm = ({onClose, locationId, afterAdd, disabled}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {locations} = useSelector(selector)

  const onFinish = (values: any) => {
    if (!locationId || disabled) return
    dispatch(addLocationChildren(locationId, values))
    afterAdd()
    form.resetFields()
  }

  return (
    <Form form={form} onFinish={onFinish} style={{padding: 24}}>
      <Form.Item label="Локации" name="children_id" rules={[{required: true, message: 'Поле должно быть заполнено'}]}>
        <Select showSearch filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            locations?.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {/*@ts-ignore*/}
                {`${location.name} (${Locations[location.type]})`}
              </Select.Option>
            ))
          }
        </Select>

      </Form.Item>
      <FormFooter onCancel={onClose} okText="Добавить" confirmDisabled={disabled}/>
    </Form>
  );
};
