import React, { useEffect } from 'react'
import { PageHeader } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { deleteService } from 'AC/directory/directory'
import { DeleteButton } from 'components/DeleteButton'
import { showSureConfirm } from 'components/modals/SureModal'

import { ServiceInfoEdit } from './ServiceInfoEdit'
import styles from './styles.module.sass'


const selector = (state: State) => {
  const { service } = state.directoryReducer
  const { services } = state.directoryReducer

  return {
    service,
    services,
  }
}

export const Service = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { service, services } = useSelector(selector)

  useEffect(()=>{
    if (services) {
      const currentService = services.find((service) => service.id === id)

      dispatch(action(Actions.SET_DIRECTORY_SERVICE, currentService))
    }
  }, [id])

  const onDelete = async () => {
    showSureConfirm(async ()=>{
      await dispatch(deleteService(id, history))
    }, ()=>{})
  }

  if (service?.id !== id) {return null}

  return (
    <>
      <PageHeader
        className={styles.title}
        title={service?.name}
        extra={
          <DeleteButton
            onClick={onDelete}
            className={styles.btn}
          />
        }
      />

      <div className={styles.serviceForm}>
        {
          service && (
            <ServiceInfoEdit
              service={service}
            />
          )
        }
      </div>
    </>
  )
}