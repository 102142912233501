import { notification } from 'antd';

import { action, Actions, ThAction } from 'actions'
import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer';
import { changeRequestState } from '../request';
import { showError } from 'components/modals/ErrorModal';

const openNotification = (msg: string) => {
  notification.info({
    message: msg,
    placement: 'bottomRight',
  });
};

export const fetchRentByPeriod = (body: APITYPES.Rent.POST.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchRentByPeriod', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Rent.POST.URL, body)

      if (resp) {
        dispatch(action(Actions.SET_RENT_DATA, resp))

        dispatch(changeRequestState('fetchRentByPeriod', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchRentByPeriod', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const sendToBilling = (body: APITYPES.Rent.POST.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('sendToBilling', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Rent.SendToBilling.URL, body)

      if (resp) {
        dispatch(changeRequestState('sendToBilling', StatusRequest.LOADED))
        openNotification('Выполнено')
      }
    } catch (e: any) {
      dispatch(changeRequestState('sendToBilling', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}