import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type ProductsState = {
  product: APITYPES.Product | null
  products: APITYPES.Product[]
  productsFilter: APITYPES.ProductsFilter

  allOptions: APITYPES.Product[] | []

  productGroups: APITYPES.ProductGroup[]

  customerTariffs: APITYPES.CustomerTariff[]
  customerTariffProducts: APITYPES.CustomerTariffProduct[]
  customerTariffLocations: APITYPES.CustomerTariffLocation[]
  customerTariffsFilter: APITYPES.CustomerTariffFilter
}

export const initialProductsState = {
  product: null,
  products: [],
  productsFilter: {},
  allOptions: [],
  productGroups: [],

  customerTariffs: [],
  customerTariffProducts: [],
  customerTariffLocations: [],
  customerTariffsFilter: {},
}

export const productsReducer: Reducer<ProductsState, Action> = (state = initialProductsState, a): ProductsState => {
  switch (a.type) {
    case Actions.SET_PRODUCT_GROUPS: {
      return {
        ...state,
        productGroups: a.data
      }
    }

    case Actions.SET_ALL_PRODUCT_OPTIONS: {
      return {
        ...state,
        allOptions: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS: {
      return {
        ...state,
        customerTariffs: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_LOCATIONS: {
      return {
        ...state,
        customerTariffLocations: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_PRODUCTS: {
      return {
        ...state,
        customerTariffProducts: a.data
      }
    }

    case Actions.SET_CUSTOMER_TARIFFS_FILTER: {
      return {
        ...state,
        customerTariffsFilter: a.data
      }
    }

    case Actions.SET_PRODUCT: {
      return {
        ...state,
        product: a.data
      }
    }

    case Actions.SET_PRODUCTS: {
      return {
        ...state,
        products: a.data
      }
    }

    case Actions.SET_PRODUCTS_FILTER: {
      return {
        ...state,
        productsFilter: a.data
      }
    }
  }

  return state
}
