import React, { FC } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal/Modal'

import { UModalSize, uModalSizeMap } from './u-modal.types'


interface UModalProps extends ModalProps {
  size?: UModalSize
}
export const UModal: FC<UModalProps> = ({ size = 's', children, ...props }) => {
  return (
    <Modal
      width={uModalSizeMap[size]}
      {...props}
    >
      { children }
    </Modal>
  )
}
