// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--loaderWrapper--uoBJX{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:300px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Urent/components/MatchingModal/styles.module.sass"],"names":[],"mappings":"AACA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA","sourcesContent":["\n.loaderWrapper\n  display: flex\n  align-items: center\n  height: 300px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": "styles-module--loaderWrapper--uoBJX"
};
export default ___CSS_LOADER_EXPORT___;
