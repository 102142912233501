import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { State } from 'stores/rootReducer'
import { fetchProduct } from 'AC/products/products'
import { action, Actions } from 'actions'
import { Loader } from 'components/Loader'

import { ProductCard } from './ProductCard'
import { ProductHeader } from './ProductHeader'
import { ProductServicesOptions } from './ProductServicesOptions'
import styles from './styles.module.sass'


const selector = (state: State) => ({product: state.productsReducer.product})

export const Product = () => {
  const {productId} = useParams<{ productId: string }>()
  const {product} = useSelector(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProduct(productId))
    return () => {
      dispatch(action(Actions.SET_PRODUCT, null))
    }
  }, [])

  if (!product) return <Loader/>

  return (
    <div>
      <ProductHeader product={product}/>

      <div className={styles.content}>
        <Tabs defaultActiveKey='info'>
          <Tabs.TabPane tab='Информация' key='info'>
            <ProductCard product={product} />
          </Tabs.TabPane>

          <Tabs.TabPane tab='Опции/сервисы' key='options_services'>
            <ProductServicesOptions product={product} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
