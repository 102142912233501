// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--main--ApZKa{height:calc(100vh - 89px)}.styles-module--table--wN34I{cursor:pointer}.styles-module--addBtn--fMiwh{margin-bottom:16px}.styles-module--toolbar--rc96P{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Locations/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,yBAAA,CAEF,6BACE,cAAA,CAEF,8BACE,kBAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".main\n  height: calc(100vh - 89px)\n\n.table\n  cursor: pointer\n\n.addBtn\n  margin-bottom: 16px\n\n.toolbar\n  display: flex\n  justify-content: space-between\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles-module--main--ApZKa",
	"table": "styles-module--table--wN34I",
	"addBtn": "styles-module--addBtn--fMiwh",
	"toolbar": "styles-module--toolbar--rc96P"
};
export default ___CSS_LOADER_EXPORT___;
