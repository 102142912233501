import React, { useState } from 'react'
import { Tag, Form, Select } from 'antd'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { APITYPES } from 'types/apitypes'
import { changeCar } from 'AC/users/changeCar'
import { State } from 'stores/rootReducer'

import { ChangeCar as Modal } from './ChangeCar'
import styles from './styles.module.sass'

type ComponentProps = { car: APITYPES.ExpandedUser['cars'][number], userId: string }


export const formatCarDate = (date: string) => {
  return format(new Date(date), 'yyyy-MM-dd hh:mm:ss')
}

const selector = (state: State) => ({clientTypes: state.directoryReducer.clientTypes})

const columns = [
  {
    title: 'Номер',
    dataIndex: 'number',
    key: 'number',
    width: '20%',
    fixed: 'left',
    render: (value: ComponentProps) => <Number car={value.car} userId={value.userId}/>,
  },
  {
    title: 'Автомобиль',
    dataIndex: 'name',
    key: 'name',
    render: (value: ComponentProps) => <Name car={value.car} userId={value.userId}/>,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (car: APITYPES.ExpandedUser['cars'][number]) => {
      return (
        <>
          {
            car.deleted ? (
              <Tag color="red">
                {'Удален ' + formatCarDate(car.deleted)}
              </Tag>
            ) : car.confirmed ? (
              <Tag color="green">{'Подтвержден ' + formatCarDate(car.confirmed)}</Tag>
            ) : (
              <Tag color="red">Не подтвержден</Tag>
            )
          }
        </>
      )
    },
  },
  {
    title: 'Тип клиента',
    dataIndex: 'clientType',
    key: 'clientType',
    width: '20%',
    render: (value: ComponentProps) => <ClientType car={value.car} userId={value.userId}/>,
  },
]

export const getColumns = () => columns

export const getRows = (cars: APITYPES.ExpandedUser['cars'], userId: string) => {
  const data = cars.map((car) => {
    const row: any = {}

    row.name = {
      car: car,
      userId: userId,
    }

    row.number = {
      car: car,
      userId: userId,
    }

    row.clientType = {
      car: car,
      userId: userId,
    }

    row.status = car
    row.key = car.car_id

    return row
  })

  return data
}

const Name = (props: ComponentProps) => {
  const {car, userId} = props
  const [isChangeCarModal, setIsChangeCarModal] = useState(false)

  return (
    <>
      {
        isChangeCarModal && (
          <Modal
            car={car}
            userId={userId}
            isVisible={isChangeCarModal}
            onCancel={() => setIsChangeCarModal(false)}
          />
        )
      }
      <Tag
        className={styles.clickArea}
        onClick={() => setIsChangeCarModal(true)}
      >
        {`${car.car_brand_name} ${car.car_model_name}`}
      </Tag>
    </>
  )
}
const ClientType = (props: ComponentProps) => {
  const {car, userId} = props
  const dispatch = useDispatch()
  const {clientTypes} = useSelector(selector)

  const [form] = useForm()

  const onSend = (values: any) => {
    dispatch(changeCar(userId, car.car_id,
      {client_type_code: values.client_type_code}))
  }

  const onClientTypeChange = () => {
    onSend(form.getFieldsValue())
  }

  return (
    <Form
      name="car_form"
      form={form}
      onFinish={onSend}
    >
      <Form.Item
        name="client_type_code"
        initialValue={car.client_type_code}
        className={styles.field}
      >
        <Select
          showSearch
          onChange={onClientTypeChange}
        >
          {
            clientTypes?.map((item) => {
              return (
                <Select.Option
                  key={item.id}
                  value={item.code}
                >
                  {item.name}
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
    </Form>
  )
}
const Number = (props: ComponentProps) => {
  const {car, userId} = props
  const [isChangeCarModal, setIsChangeCarModal] = useState(false)

  return (
    <>
      {
        isChangeCarModal && (
          <Modal
            car={car}
            userId={userId}
            isVisible={isChangeCarModal}
            onCancel={() => setIsChangeCarModal(false)}
          />
        )
      }
      <Tag
        className={styles.clickArea}
        onClick={() => setIsChangeCarModal(true)}
      >
        {car.grz}
      </Tag>
    </>
  )
}