// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--gGvL9{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;gap:10px;padding:20px;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.styles-module--datePicker--BmMaN{width:100%;text-align:left}.styles-module--checkBoxContainer--9V1XX{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:5px;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start;width:100%;overflow:scroll;overflow-x:hidden;max-height:600px}.styles-module--checkBox--0Rp45{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;text-align:start}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftsPage/components/CommitModal/GetStatistics/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,QAAA,CACA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGF,kCACE,UAAA,CACA,eAAA,CAGF,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,OAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CAEF,gCACE,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,gBAAA","sourcesContent":[".container \n  display: flex\n  justify-content: center\n  gap: 10px\n  padding: 20px\n  flex-direction: column\n\n\n.datePicker \n  width: 100%\n  text-align: left\n\n\n.checkBoxContainer \n  display: flex\n  flex-direction: column\n  gap: 5px\n  align-items: baseline\n  justify-content: start\n  width: 100%\n  overflow: scroll\n  overflow-x: hidden\n  max-height: 600px\n\n.checkBox \n  width: fit-content\n  text-align: start\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--gGvL9",
	"datePicker": "styles-module--datePicker--BmMaN",
	"checkBoxContainer": "styles-module--checkBoxContainer--9V1XX",
	"checkBox": "styles-module--checkBox--0Rp45"
};
export default ___CSS_LOADER_EXPORT___;
