import { APITYPES } from "types/apitypes";

export const getColumns = () => {
  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
  ];
};

export const getRows = (carClasses: APITYPES.CarClasses.Get.Resp) => {
  const data = carClasses.map((carClass) => {
    const row: any = {};

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = carClass[column.dataIndex];
      row.key = carClass.id;

      return;
    });

    row.carClass = carClass;

    return row;
  });

  return data;
};
