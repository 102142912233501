import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Table, Select } from 'antd'
import { useHistory } from 'react-router'

import { fetchPartners, FetchPartnersParamsType } from 'AC/partners/partners'
import { syncPartners as syncPartnersRequest } from 'AC/partners/syncPartners'
import { State } from 'stores/rootReducer'
import { Loader } from 'components/Loader'
import { CreateCard } from './Card/CreateCard'
import { StatusRequest } from 'stores/requestsReducer'
import { action, Actions } from 'actions'
import { useDebounce } from 'utils/debounce'
import { ROUTES } from 'config/routes'
import { InfiniteTable } from 'components/InfiniteTable'
import { navigate } from 'utils/tabelUtils'
import { exportPartnersToDocument } from '../../../../AC/partners/exportPartnersToDocument'
import { useQueryParams } from '../../../../utils/hooks/useQueryParams'

import styles from './styles.module.sass'
import { getColumns, getRows } from './utils'


const { Search } = Input
const { Option } = Select


const selector = (state: State) => {
  const { partners, hasMore, search } = state.partnersReducer
  const { fetchPartners, syncPartners, exportPartners } = state.requestsReducer

  return {
    partners,
    hasMore,
    isLoading: fetchPartners === StatusRequest.LOADING,
    isSyncLoading: syncPartners === StatusRequest.LOADING,
    isExportLoading: exportPartners === StatusRequest.LOADING,
    search,
  }
}

const partnerStates = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Новый',
    value: 'new',
  },
  {
    label: 'Активный',
    value: 'active',
  },
  {
    label: 'Отключен',
    value: 'disconnected',
  },
]


export const Registry = () => {
  const queryParams = useQueryParams()

  const [state, setState] = useState<string>(queryParams?.state || partnerStates[0].value)
  const [search, setSearch] = useState<string>(queryParams?.search || '')
  const [isCreate, setIsCreate] = useState(false)

  const {
    partners,
    hasMore,
    isLoading,
    isSyncLoading,
    isExportLoading,
  } = useSelector(selector)

  const dispatch = useDispatch()
  const history = useHistory()
  const debouncedSearchTerm = useDebounce(search, 600)


  const loadMore = () => {
    dispatch(fetchPartners(getSearchParam()))
  }

  const onSearch = () => {
    const term = search.trim()

    if (!term.length) {
      return
    }

    history.push(ROUTES.PARTNERS.SEARCH.createPath({
      ...queryParams,
      search: term,
    }))

    dispatch(action(Actions.CLEAN_PARTNERS, {}))
    dispatch(fetchPartners({ search: term }))
  }

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()

    setSearch(value)
  }

  const syncPartners = () => {
    dispatch(syncPartnersRequest())
  }

  const onExportPartners = () => {
    dispatch(exportPartnersToDocument(search, state))
  }

  const getSearchParam = () => {
    const params: FetchPartnersParamsType = {}

    if (search.trim()) {
      params.search = search
    }

    if (state) {
      params.state = state
    }

    return params
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    dispatch(action(Actions.CLEAN_PARTNERS, {}))
    dispatch(fetchPartners(getSearchParam()))
    history.push(ROUTES.PARTNERS.SEARCH.createPath({
      search,
      state,
    }))
  }, [debouncedSearchTerm, state])

  if (!partners) {
    return <Loader />
  }

  return (
    <div className={styles.main}>
      <div className={styles.searchHeader}>
        <Search
          value={search}
          onChange={onSearchChange}
          placeholder='Поиск партнеров'
          onSearch={onSearch}
          enterButton
          allowClear
        />
        <div className={styles.searchHeader}>
          <span>Статус:</span>
          <Select
            className={styles.searchHeaderSelect}
            showSearch
            placeholder='Выберите статус'
            defaultValue={state}
            value={state}
            onChange={(value) => setState(value)}
          >
            {
              partnerStates?.map((s) =>(
                <Option
                  key={s.value}
                  value={s.value}
                >
                  { s.label }
                </Option>
              ))
            }
          </Select>
        </div>
      </div>
      <div>
        <Button
          type='primary'
          onClick={() => setIsCreate(true)}
          className={styles.addButton}
        >
          Добавить партнера
        </Button>
        <Button
          type='primary'
          onClick={syncPartners}
          className={styles.addButton}
          disabled={isSyncLoading}
        >
          Обновить реестр
        </Button>
        <Button
          type='primary'
          onClick={onExportPartners}
          className={styles.addButton}
          disabled={isExportLoading}
        >
          Экспорт в таблицу
        </Button>
      </div>
      <CreateCard
        isVisible={isCreate}
        onCancel={() => setIsCreate(false)}
      />
      <InfiniteTable
        columns={getColumns()}
        isLoading={isLoading}
        hasMore={hasMore}
        className={styles.tableContainer}
        loadMore={loadMore}
        isReactive={false}
      >
        <Table
          showHeader={false}
          columns={getColumns() as any}
          className={styles.table}
          dataSource={getRows(partners)}
          bordered
          size='large'
          scroll={{ x: '100%' }}
          pagination={false}
          onRow={
            (record: any) => ({ onClick: navigate(ROUTES.PARTNERS.ID.createPath(record.key), history) })
          }
        />
      </InfiniteTable>
    </div>
  )
}