import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Layout } from 'components/Layout'
import { LocalStorageKeys, getLocalStorageItem } from 'utils/localStorageUtils'

import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'


export const App: React.FC = () => {
  const isUserSignedIn = getLocalStorageItem(LocalStorageKeys.TOKEN)

  return (
    <BrowserRouter>
      { isUserSignedIn ? <Layout><PrivateRoutes /></Layout> : <PublicRoutes /> }
    </BrowserRouter>
  )
}
