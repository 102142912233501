import { Button, Modal, Select, Upload } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import * as XLSX from 'xlsx'

import { dateFormat } from 'utils/dateUtils'
import { State } from 'stores/rootReducer'
import { getDoerRecordsPeriod, importDoerRecords } from '../../../../../../AC/billing/doerRecords'
import { openNotification } from '../../../../../../components/modals/OperationNotification'

import styles from './styles.module.sass'


type Props = {
  isOpen: boolean
  onCancel: () => void
};

const selector = (state: State) => ({
  period: state.billingReducer.doerRecordsPeriod,
  hasMore: state.billingReducer.periodHasMore,
  filter: state.doerRecordsReducer.filtersMap[state.doerRecordsReducer.activeTabId],
})

export const ImportModal = ({ isOpen, onCancel }: Props) => {
  const dispatch = useDispatch()
  const { period, filter, hasMore } = useSelector(selector)

  const [dates, setDates] = useState<string[] | []>([])
  const [datesDisabled, setDatesDisabled] = useState(false)
  const [table, setTable] = useState<any>(null)
  const [file, setFile] = useState<any>(null)


  const lastRowRef = useRef(null)

  const props = {
    beforeUpload: (file: any) => {
      file.arrayBuffer().then((data: any) =>{
        const workbook = XLSX.read(data)
        const worksheet = workbook.Sheets['import']

        if (!worksheet) {
          openNotification('Отсутствует лист "import" в файле')

          return
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          rawNumbers: false,
          blankrows: false,
        })

        setTable(jsonData)
        setFile(file)
      })

      return false
    },
  }

  const onUpload = () => {
    dispatch(importDoerRecords(file, dates, onCancel))
  }

  const onSelectChange = (dates: any) => {
    if (dates) {
      const [billing_begin, billing_end] = dates.split('/')

      setDates([billing_begin, billing_end])
    }
  }

  const afterClose = () => {
    setDates([])
    setTable(null)
    setFile(null)
  }
  const modalWidth = table ? (table[0].length > 3 ? 1300 : 900) : 480

  useEffect(() => {
    if (filter?.period?.value) {
      onSelectChange(filter?.period?.value)
      setDatesDisabled(true)
    }
  }, [])

  const handleScroll = useCallback((event) => {
    const { target } = event
    const atBottom = target.scrollTop + target.offsetHeight === target.scrollHeight

    if (atBottom && hasMore) {
      dispatch(getDoerRecordsPeriod())
    }
  }, [hasMore])


  return (
    <Modal
      title='Импортировать записи'
      open={isOpen}
      onCancel={onCancel}
      afterClose={afterClose}
      destroyOnClose
      width={modalWidth}
      footer
    >
      <div className={styles.wrapper}>
        <div className={styles.uploadFields}>
          <Select
            placeholder='Выберите период расчета'
            allowClear
            onChange={onSelectChange}
            value={dates.length ? `${dates[0]}/${dates[1]}` : null}
            disabled={datesDisabled}
            onPopupScroll={handleScroll}
          >
            {
              period.map((item, index) => (
                <Select.Option
                  key={`${item.billing_begin}+${item.billing_end}`}
                  value={`${item.billing_begin}/${item.billing_end}`}
                  ref={index === period?.length - 1 ? lastRowRef : undefined}
                >
                  {
                    `c ${moment(item.billing_begin).format(dateFormat)} по 
                    ${moment(item.billing_end).format(dateFormat)}`
                  }
                </Select.Option>
              ))
            }
          </Select>

          <Upload
            {...props}
            accept='.xlsx'
            maxCount={1}
            showUploadList={false}
          >
            <Button
              type='primary'
              disabled={!dates?.length}
            >
              <UploadOutlined />
              Загрузить
            </Button>
          </Upload>

        </div>


        {
          table && (
            <div  style={
              {
                width: modalWidth - 40,
                maxHeight: 400,
                overflow: 'auto',
              }
            }
            >
              <table
                className={styles.previewTable}

              >
                <thead>
                  <tr>
                    <th>№</th>
                    {
                      Object.keys(table[0]).map((item: string) => (
                        <th key={item}>{ item }</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    table.map((value: string, index: number) => (
                      <tr key={index}>
                        <td>{ index + 1 }</td>
                        {
                          Object.values(value).map((item: string) => (
                            <td key={item}>
                              { item }
                            </td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>
      <div className={styles.uploadBtn}>
        {
          table && (
            <Button
              onClick={onUpload}
              type='primary'
            >
              Импортировать
            </Button>
          )
        }
      </div>
    </Modal>
  )
}
