import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, MenuProps, Popconfirm } from 'antd'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'

import {
  connectYandexProExecutorAndDoer,
  deleteYandexProExecutor,
} from 'AC/yandexpro/reports'


/**
 * Очень странная логика в компоненте, возможно даже неправильная.
 * @deprecated
 */
export const ConnectButton = ({ record, report, setNewReport }: any) => {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [loadings, setLoadings] = useState([])

  useEffect(() => {
    setIsDisabled(!record.doer_name)
    setIsConnected(false)
  }, [record.doer_name])

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings: any) => {
      const newLoadings: any = [...prevLoadings]

      newLoadings[index] = true

      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings: any) => {
        const newLoadings: any = [...prevLoadings]

        newLoadings[index] = false
        setIsConnected(true)
        setIsDisabled(true)

        return newLoadings
      })
    }, 2000)
  }

  const handleImport = (record: any) => {
    dispatch(
      connectYandexProExecutorAndDoer(record.yandex_executor_id, { doer_user_id: record.doer_user_id })
    )
  }
  const handleDelete = () => {
    const newReport = report.filter(
      (item: any) => item.yandex_executor_id !== record.yandex_executor_id
    )

    setNewReport(newReport)
    dispatch(deleteYandexProExecutor(record.yandex_executor_id))
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Popconfirm
          placement='top'
          title='Вы действительно хотите удалить исполнителя?'
          onConfirm={handleDelete}
          okText='Да'
          okType='danger'
          cancelText='Нет'
        >
          Удалить
        </Popconfirm>
      ),
      key: 'Удалить',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ]

  const menuProps = { items }

  return (
    <div
      style={
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent:'center',
        }
      }
    >
      <Button
        type='primary'
        loading={loadings[1]}
        onClick={
          () => {
            enterLoading(1)
            handleImport(record)
          }
        }
        disabled={isDisabled}
        style={
          {
            width:'fit-content',
            padding: '0 4px',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }
        }
      >
        { !isConnected ? 'Связать' : 'Связано' }
      </Button>
      <Dropdown
        menu={menuProps}
        trigger={['click']}
        placement='bottomRight'
      >
        <Button
          type='primary'
          style={
            {
              width:'fit-content',
              padding: '0 4px',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }
          }
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
    </div>
  )
}
