import { Reducer } from 'redux'

import { Action, Actions } from 'actions'


export enum StatusRequest {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
  NOT_STARTED = 'NOT_STARTED',
}

export type RequestsState = {
  login: StatusRequest
  cancelReserve: StatusRequest
  fetchPartners: StatusRequest
  exportPartners: StatusRequest
  exportCarwashes: StatusRequest
  fetchPartnerByInn: StatusRequest
  fetchPartner: StatusRequest
  updatePartner: StatusRequest
  offPartner: StatusRequest
  activatePartner: StatusRequest
  createPartner: StatusRequest
  fetchCarwashes: StatusRequest
  fetchCarwash: StatusRequest
  updateCarwash: StatusRequest
  offCarwash: StatusRequest
  activateCarwash: StatusRequest
  createCarwash: StatusRequest
  fetchTariffs: StatusRequest
  createTariff: StatusRequest
  updateTariff: StatusRequest
  deleteTariff: StatusRequest
  fetchClientTypes: StatusRequest
  fetchCarClasses: StatusRequest
  fetchAllCarClasses: StatusRequest
  fetchServices: StatusRequest
  updateEmployee: StatusRequest
  deleteEmployee: StatusRequest
  createEmployee: StatusRequest
  createClientType: StatusRequest
  createService: StatusRequest
  addService: StatusRequest
  addCarClass: StatusRequest
  fetchBrands: StatusRequest
  fetchModels: StatusRequest
  fetchCarClassCars: StatusRequest
  createCarClass: StatusRequest
  addCarClassCar: StatusRequest
  deleteCarClassCar: StatusRequest
  fetchCarwashPhotos: StatusRequest
  addCarwashPhoto: StatusRequest
  updateCarClass: StatusRequest
  updateService: StatusRequest
  deleteClientTypeService: StatusRequest
  deleteClientTypeCarClass: StatusRequest
  deactivateCarwash: StatusRequest
  onCarwash: StatusRequest
  updateAllServices: StatusRequest
  deleteService: StatusRequest
  fetchCarwashesStatistics: StatusRequest
  fetchPartnersStatistics: StatusRequest
  addOperationsStatistics: StatusRequest
  updateClientTypes: StatusRequest
  deleteClientType: StatusRequest
  fetchClientType: StatusRequest
  replacePartner: StatusRequest
  fetchAllService: StatusRequest
  fetchUsers: StatusRequest
  fetchUser: StatusRequest
  changeCar: StatusRequest
  syncPartners: StatusRequest
  switchClientType: StatusRequest
  copyTariff: StatusRequest
  getDocuments: StatusRequest
  createDocument: StatusRequest
  updateDocument: StatusRequest
  deleteDocument: StatusRequest
  deleteFile: StatusRequest
  getSchedules: StatusRequest
  getSchedule: StatusRequest
  createSchedule: StatusRequest
  updateSchedule: StatusRequest
  deleteSchedule: StatusRequest
  createInterval: StatusRequest
  updateInterval: StatusRequest
  deleteInterval: StatusRequest
  getDoers: StatusRequest
  addDoer: StatusRequest
  updateDoer: StatusRequest
  blockDoer: StatusRequest
  confirmDoer: StatusRequest
  getBills: StatusRequest
  getBill: StatusRequest
  getExportBills: StatusRequest
  getExportBillsPnl: StatusRequest
  getExportBillsConsole: StatusRequest
  postImportBillsItems: StatusRequest
  postInvoicesForPayment: StatusRequest
  getContractors: StatusRequest
  getPartnerContractors: StatusRequest
  getDoerContractors: StatusRequest
  getContractor: StatusRequest
  createBill: StatusRequest
  updateBill: StatusRequest
  cancelBill: StatusRequest
  negotiationBill: StatusRequest
  acceptBill: StatusRequest
  updateBillItem: StatusRequest
  deleteBillItem: StatusRequest
  addBillItem: StatusRequest
  getContractorRecords: StatusRequest
  getDoerRecords: StatusRequest
  getDoerRecordsPeriod: StatusRequest
  exportAggregatedDoerRecords: StatusRequest
  exportConsoleDoerRecords: StatusRequest
  declineBill: StatusRequest
  fetchPenalties: StatusRequest
  fetchPenaltyTypes: StatusRequest
  addNewPenalty: StatusRequest
  updatePenaltyStatus: StatusRequest
  updateCompensationStatus: StatusRequest
  fetchCompensations: StatusRequest
  fetchCompensationTypes: StatusRequest
  addNewCompensation: StatusRequest
  fetchAllServices: StatusRequest
  fetchAllCarBrands: StatusRequest
  fetchAllBrandModels: StatusRequest
  fetchCarModelInfo: StatusRequest
  fetchCarModelCarClasses: StatusRequest
  fetchShiftTypes: StatusRequest
  addShiftType: StatusRequest
  fetchShifts: StatusRequest
  addShift: StatusRequest
  getShiftById: StatusRequest
  updateShiftById: StatusRequest
  fetchShiftTypesByRole: StatusRequest
  addShiftTypeByRole: StatusRequest
  getShiftTypeByRoleId: StatusRequest
  fetchShiftTypesByAllRoles: StatusRequest
  fetchLocations: StatusRequest
  fetchEmployers: StatusRequest
  fetchEmployerById: StatusRequest
  addShiftLocation: StatusRequest
  editShiftLocation: StatusRequest
  addDoerShiftToLocation: StatusRequest
  deleteDoerShiftToLocation: StatusRequest
  commitShifts: StatusRequest
  fetchRoles: StatusRequest
  fetchRoleGroups: StatusRequest
  addRole: StatusRequest
  fetchDoerRoles: StatusRequest
  addDoerRole: StatusRequest
  deleteDoerRole: StatusRequest
  fetchNews: StatusRequest
  addNews: StatusRequest
  getSingleNews: StatusRequest
  deleteSingleNews: StatusRequest
  updateSingleNews: StatusRequest
  deleteNewsRole: StatusRequest
  updateRole: StatusRequest
  fetchUserSubscriptions: StatusRequest
  fetchUserOrders: StatusRequest
  fetchRolesOfEachDoer: StatusRequest
  fetchShiftDoers: StatusRequest
  doerClearWallet: StatusRequest
  createBillFileUrl: StatusRequest
  getBillFileUrl: StatusRequest
  deleteShift: StatusRequest
  importShift: StatusRequest
  changeDoerShiftStatus: StatusRequest
  fetchOffers: StatusRequest
  fetchOfferReserves: StatusRequest
  setShiftDoer: StatusRequest
  fetchOfferById: StatusRequest
  fetchOperations: StatusRequest
  fetchOperation: StatusRequest
  editOperation: StatusRequest
  deleteOperation: StatusRequest
  changeOperationState: StatusRequest
  fetchOperationTasks: StatusRequest
  createSuboperation: StatusRequest
  changeReserveOffer: StatusRequest
  fetchReserveOffers: StatusRequest
  fetchOperationLocations: StatusRequest
  addDoerToShift: StatusRequest
  getDoerByName: StatusRequest
  cancelShift: StatusRequest
  fetchShiftOffers: StatusRequest
  paidBill: StatusRequest
  payingBill: StatusRequest
  createOperationOrders: StatusRequest
  closeOperationOrders: StatusRequest
  getBillCalculations: StatusRequest
  postBillCalculations: StatusRequest
  fetchBillSyncState: StatusRequest
  fetchBillSyncStateV2: StatusRequest
  fetchOperationFiles: StatusRequest
  fetchTasks: StatusRequest
  changeDoerShift: StatusRequest
  fetchShiftsForRedirect: StatusRequest
  fetchRentByPeriod: StatusRequest
  sendToBilling: StatusRequest
  getDoerById: StatusRequest
  fetchLocationChildren: StatusRequest
  addLocationChildren: StatusRequest
  deleteLocationChildren: StatusRequest
  fetchStaticLocations: StatusRequest
  getLocationPhotos: StatusRequest
  deleteLocationPhoto: StatusRequest
  addLocationPhoto: StatusRequest
  putLocationPhoto: StatusRequest
  getDoerShifts: StatusRequest
  getDoerReserves: StatusRequest
  fetchProducts: StatusRequest
  addProduct: StatusRequest
  editProduct: StatusRequest
  addOptionToProduct: StatusRequest
  addServiceToProduct: StatusRequest
  deleteOptionFromProduct: StatusRequest
  deleteServiceFromProduct: StatusRequest
  fetchProduct: StatusRequest
  fetchCustomerTariffs: StatusRequest
  createCustomerTariff: StatusRequest
  editCustomerTariff: StatusRequest
  fetchCustomerTariffProducts: StatusRequest
  addCustomerTariffProducts: StatusRequest
  editCustomerTariffProducts: StatusRequest
  deleteCustomerTariffProducts: StatusRequest
  fetchCustomerTariffLocations: StatusRequest
  addCustomerTariffLocations: StatusRequest
  deleteCustomerTariffLocations: StatusRequest
  fetchAllOptions: StatusRequest
  fetchProductGroups: StatusRequest
  addProductGroup: StatusRequest
  editProductGroup: StatusRequest
  deleteProductGroup: StatusRequest
  fetchCustomerTariffById: StatusRequest
  fetchDoerTariffs: StatusRequest
  fetchDoerTariffsGroups: StatusRequest
  getMotivationStatistics: StatusRequest
  fetchDoerTariffById: StatusRequest
  addDoerTariff: StatusRequest
  editDoerTariff: StatusRequest
  deleteDoerTariff: StatusRequest
  addDoerTariffTask: StatusRequest
  editDoerTariffTask: StatusRequest
  deleteDoerTariffTask: StatusRequest
  fetchDoerTariffShiftTypes: StatusRequest
  addDoerTariffShiftTypes: StatusRequest
  editDoerTariffShiftTypes: StatusRequest
  deleteDoerTariffShiftTypes: StatusRequest
  addDoerTariffShiftPrices: StatusRequest
  editDoerTariffShiftPrices: StatusRequest
  deleteDoerTariffShiftPrices: StatusRequest
  fetchDoerTariffFunctions: StatusRequest
  getBillsDoers: StatusRequest
  getFleets: StatusRequest
  getFleetsCars: StatusRequest
  getFleetCarById: StatusRequest
  createFleetCar: StatusRequest
  changeFleetCar: StatusRequest
  deleteFleetCar: StatusRequest
  getFleetsServices: StatusRequest
  createFleetService: StatusRequest
  changeFleetService: StatusRequest
  deleteFleetService: StatusRequest
  getMailing: StatusRequest
  addMailing: StatusRequest
  getDoerMotivations: StatusRequest
  getDoerPenalty: StatusRequest
  getDoerCompensation: StatusRequest
  getPenalties: StatusRequest
  addDoerPenalties: StatusRequest
  addDoerCompensations: StatusRequest
  importMotivations: StatusRequest
  applyPenalty: StatusRequest
  cancelPenalty: StatusRequest
  getYandexProReports: StatusRequest
  getYandexProReport: StatusRequest
  addYandexProReports: StatusRequest
  deployYandexProReport: StatusRequest
  connectYandexProExecutorAndDoer: StatusRequest
  getYandexPROExecutors: StatusRequest
  updateYandexProExecutorAndDoer: StatusRequest
  deleteYandexProExecutor: StatusRequest
  getMonitoringAllocations: StatusRequest
  updateMonitoringAllocations: StatusRequest
  updatedMonitoringAllocationsData: StatusRequest
  getDoerVacancies: StatusRequest
  addVacancy: StatusRequest
  getVacancy: StatusRequest
  updateVacancy: StatusRequest
  openVacancyById: StatusRequest
  cancelVacancyById: StatusRequest
  getYandexLavkaReports: StatusRequest
  getYandexLavkaReportById: StatusRequest
  connectLavkaUnmatchedExecutors: StatusRequest
  deployYandexLavkaReport: StatusRequest
  getUrentReports: StatusRequest
  getUrentReportById: StatusRequest
  connectUrentUnmatchedExecutors: StatusRequest
  deployUrentReport: StatusRequest
  fetchInternships: StatusRequest
  cancelInternship: StatusRequest
  editInternshipCount: StatusRequest
  fetchRentalObjectTypes: StatusRequest
  fetchRentalObjectType: StatusRequest
};

export const defStateRequests: RequestsState = {
  login: StatusRequest.NOT_STARTED,
  deleteFleetCar: StatusRequest.NOT_STARTED,
  changeFleetCar: StatusRequest.NOT_STARTED,
  createFleetCar: StatusRequest.NOT_STARTED,
  getFleetCarById: StatusRequest.NOT_STARTED,
  getFleetsCars: StatusRequest.NOT_STARTED,
  getFleets: StatusRequest.NOT_STARTED,
  getFleetsServices: StatusRequest.NOT_STARTED,
  createFleetService: StatusRequest.NOT_STARTED,
  changeFleetService: StatusRequest.NOT_STARTED,
  deleteFleetService: StatusRequest.NOT_STARTED,
  getBillsDoers: StatusRequest.NOT_STARTED,
  getExportBills: StatusRequest.NOT_STARTED,
  getExportBillsPnl: StatusRequest.NOT_STARTED,
  getExportBillsConsole: StatusRequest.NOT_STARTED,
  postImportBillsItems: StatusRequest.NOT_STARTED,
  postInvoicesForPayment: StatusRequest.NOT_STARTED,
  fetchDoerTariffFunctions: StatusRequest.NOT_STARTED,
  deleteDoerTariffTask: StatusRequest.NOT_STARTED,
  editDoerTariffTask: StatusRequest.NOT_STARTED,
  addDoerTariffTask: StatusRequest.NOT_STARTED,
  fetchDoerTariffShiftTypes: StatusRequest.NOT_STARTED,
  addDoerTariffShiftTypes: StatusRequest.NOT_STARTED,
  editDoerTariffShiftTypes: StatusRequest.NOT_STARTED,
  deleteDoerTariffShiftTypes: StatusRequest.NOT_STARTED,
  addDoerTariffShiftPrices: StatusRequest.NOT_STARTED,
  editDoerTariffShiftPrices: StatusRequest.NOT_STARTED,
  deleteDoerTariffShiftPrices: StatusRequest.NOT_STARTED,
  deleteDoerTariff: StatusRequest.NOT_STARTED,
  editDoerTariff: StatusRequest.NOT_STARTED,
  addDoerTariff: StatusRequest.NOT_STARTED,
  fetchDoerTariffById: StatusRequest.NOT_STARTED,
  fetchDoerTariffs: StatusRequest.NOT_STARTED,
  fetchDoerTariffsGroups: StatusRequest.NOT_STARTED,
  getMotivationStatistics: StatusRequest.NOT_STARTED,
  fetchCustomerTariffById: StatusRequest.NOT_STARTED,
  deleteProductGroup: StatusRequest.NOT_STARTED,
  editProductGroup: StatusRequest.NOT_STARTED,
  addProductGroup: StatusRequest.NOT_STARTED,
  fetchProductGroups: StatusRequest.NOT_STARTED,
  fetchAllOptions: StatusRequest.NOT_STARTED,
  deleteCustomerTariffLocations: StatusRequest.NOT_STARTED,
  addCustomerTariffLocations: StatusRequest.NOT_STARTED,
  fetchCustomerTariffLocations: StatusRequest.NOT_STARTED,
  deleteCustomerTariffProducts: StatusRequest.NOT_STARTED,
  editCustomerTariffProducts: StatusRequest.NOT_STARTED,
  addCustomerTariffProducts: StatusRequest.NOT_STARTED,
  fetchCustomerTariffProducts: StatusRequest.NOT_STARTED,
  editCustomerTariff: StatusRequest.NOT_STARTED,
  createCustomerTariff: StatusRequest.NOT_STARTED,
  fetchCustomerTariffs: StatusRequest.NOT_STARTED,
  fetchProduct: StatusRequest.NOT_STARTED,
  deleteServiceFromProduct: StatusRequest.NOT_STARTED,
  deleteOptionFromProduct: StatusRequest.NOT_STARTED,
  addServiceToProduct: StatusRequest.NOT_STARTED,
  addOptionToProduct: StatusRequest.NOT_STARTED,
  fetchProducts: StatusRequest.NOT_STARTED,
  addProduct: StatusRequest.NOT_STARTED,
  editProduct: StatusRequest.NOT_STARTED,
  cancelReserve: StatusRequest.NOT_STARTED,
  fetchOperationFiles: StatusRequest.NOT_STARTED,
  createBillFileUrl: StatusRequest.NOT_STARTED,
  getBillFileUrl: StatusRequest.NOT_STARTED,
  fetchRolesOfEachDoer: StatusRequest.NOT_STARTED,
  fetchPartners: StatusRequest.NOT_STARTED,
  exportPartners:  StatusRequest.NOT_STARTED,
  exportCarwashes:  StatusRequest.NOT_STARTED,
  fetchPartner: StatusRequest.NOT_STARTED,
  fetchPartnerByInn: StatusRequest.NOT_STARTED,
  updatePartner: StatusRequest.NOT_STARTED,
  offPartner: StatusRequest.NOT_STARTED,
  activatePartner: StatusRequest.NOT_STARTED,
  createPartner: StatusRequest.NOT_STARTED,
  fetchCarwashes: StatusRequest.NOT_STARTED,
  fetchCarwash: StatusRequest.NOT_STARTED,
  updateCarwash: StatusRequest.NOT_STARTED,
  offCarwash: StatusRequest.NOT_STARTED,
  activateCarwash: StatusRequest.NOT_STARTED,
  createCarwash: StatusRequest.NOT_STARTED,
  fetchTariffs: StatusRequest.NOT_STARTED,
  createTariff: StatusRequest.NOT_STARTED,
  updateTariff: StatusRequest.NOT_STARTED,
  deleteTariff: StatusRequest.NOT_STARTED,
  fetchClientTypes: StatusRequest.NOT_STARTED,
  fetchCarClasses: StatusRequest.NOT_STARTED,
  fetchAllCarClasses: StatusRequest.NOT_STARTED,
  fetchServices: StatusRequest.NOT_STARTED,
  updateEmployee: StatusRequest.NOT_STARTED,
  deleteEmployee: StatusRequest.NOT_STARTED,
  createEmployee: StatusRequest.NOT_STARTED,
  createClientType: StatusRequest.NOT_STARTED,
  createService: StatusRequest.NOT_STARTED,
  addService: StatusRequest.NOT_STARTED,
  addCarClass: StatusRequest.NOT_STARTED,
  fetchBrands: StatusRequest.NOT_STARTED,
  fetchModels: StatusRequest.NOT_STARTED,
  fetchCarClassCars: StatusRequest.NOT_STARTED,
  createCarClass: StatusRequest.NOT_STARTED,
  addCarClassCar: StatusRequest.NOT_STARTED,
  deleteCarClassCar: StatusRequest.NOT_STARTED,
  fetchCarwashPhotos: StatusRequest.NOT_STARTED,
  addCarwashPhoto: StatusRequest.NOT_STARTED,
  updateCarClass: StatusRequest.NOT_STARTED,
  updateService: StatusRequest.NOT_STARTED,
  deleteClientTypeService: StatusRequest.NOT_STARTED,
  deleteClientTypeCarClass: StatusRequest.NOT_STARTED,
  deactivateCarwash: StatusRequest.NOT_STARTED,
  onCarwash: StatusRequest.NOT_STARTED,
  updateAllServices: StatusRequest.NOT_STARTED,
  deleteService: StatusRequest.NOT_STARTED,
  fetchCarwashesStatistics: StatusRequest.NOT_STARTED,
  fetchPartnersStatistics: StatusRequest.NOT_STARTED,
  addOperationsStatistics: StatusRequest.NOT_STARTED,
  updateClientTypes: StatusRequest.NOT_STARTED,
  deleteClientType: StatusRequest.NOT_STARTED,
  fetchClientType: StatusRequest.NOT_STARTED,
  replacePartner: StatusRequest.NOT_STARTED,
  fetchAllService: StatusRequest.NOT_STARTED,
  fetchUsers: StatusRequest.NOT_STARTED,
  fetchUser: StatusRequest.NOT_STARTED,
  changeCar: StatusRequest.NOT_STARTED,
  syncPartners: StatusRequest.NOT_STARTED,
  switchClientType: StatusRequest.NOT_STARTED,
  copyTariff: StatusRequest.NOT_STARTED,
  getDocuments: StatusRequest.NOT_STARTED,
  createDocument: StatusRequest.NOT_STARTED,
  updateDocument: StatusRequest.NOT_STARTED,
  deleteDocument: StatusRequest.NOT_STARTED,
  deleteFile: StatusRequest.NOT_STARTED,
  getSchedules: StatusRequest.NOT_STARTED,
  getSchedule: StatusRequest.NOT_STARTED,
  createSchedule: StatusRequest.NOT_STARTED,
  updateSchedule: StatusRequest.NOT_STARTED,
  deleteSchedule: StatusRequest.NOT_STARTED,
  createInterval: StatusRequest.NOT_STARTED,
  updateInterval: StatusRequest.NOT_STARTED,
  deleteInterval: StatusRequest.NOT_STARTED,
  getDoers: StatusRequest.NOT_STARTED,
  addDoer: StatusRequest.NOT_STARTED,
  updateDoer: StatusRequest.NOT_STARTED,
  blockDoer: StatusRequest.NOT_STARTED,
  confirmDoer: StatusRequest.NOT_STARTED,
  getBills: StatusRequest.NOT_STARTED,
  getBill: StatusRequest.NOT_STARTED,
  getContractors: StatusRequest.NOT_STARTED,
  getPartnerContractors: StatusRequest.NOT_STARTED,
  getDoerContractors: StatusRequest.NOT_STARTED,
  getContractor: StatusRequest.NOT_STARTED,
  createBill: StatusRequest.NOT_STARTED,
  updateBill: StatusRequest.NOT_STARTED,
  cancelBill: StatusRequest.NOT_STARTED,
  negotiationBill: StatusRequest.NOT_STARTED,
  acceptBill: StatusRequest.NOT_STARTED,
  updateBillItem: StatusRequest.NOT_STARTED,
  deleteBillItem: StatusRequest.NOT_STARTED,
  addBillItem: StatusRequest.NOT_STARTED,
  getContractorRecords: StatusRequest.NOT_STARTED,
  getDoerRecords: StatusRequest.NOT_STARTED,
  getDoerRecordsPeriod: StatusRequest.NOT_STARTED,
  exportAggregatedDoerRecords: StatusRequest.NOT_STARTED,
  exportConsoleDoerRecords: StatusRequest.NOT_STARTED,
  declineBill: StatusRequest.NOT_STARTED,
  fetchPenalties: StatusRequest.NOT_STARTED,
  fetchPenaltyTypes: StatusRequest.NOT_STARTED,
  addNewPenalty: StatusRequest.NOT_STARTED,
  updatePenaltyStatus: StatusRequest.NOT_STARTED,
  updateCompensationStatus: StatusRequest.NOT_STARTED,
  fetchCompensations: StatusRequest.NOT_STARTED,
  fetchCompensationTypes: StatusRequest.NOT_STARTED,
  addNewCompensation: StatusRequest.NOT_STARTED,
  fetchAllServices: StatusRequest.NOT_STARTED,
  fetchAllCarBrands: StatusRequest.NOT_STARTED,
  fetchAllBrandModels: StatusRequest.NOT_STARTED,
  fetchCarModelInfo: StatusRequest.NOT_STARTED,
  fetchCarModelCarClasses: StatusRequest.NOT_STARTED,
  fetchShiftTypes: StatusRequest.NOT_STARTED,
  addShiftType: StatusRequest.NOT_STARTED,
  fetchShifts: StatusRequest.NOT_STARTED,
  addShift: StatusRequest.NOT_STARTED,
  getShiftById: StatusRequest.NOT_STARTED,
  updateShiftById: StatusRequest.NOT_STARTED,
  fetchShiftTypesByRole: StatusRequest.NOT_STARTED,
  addShiftTypeByRole: StatusRequest.NOT_STARTED,
  getShiftTypeByRoleId: StatusRequest.NOT_STARTED,
  fetchLocations: StatusRequest.NOT_STARTED,
  fetchEmployers: StatusRequest.NOT_STARTED,
  fetchEmployerById: StatusRequest.NOT_STARTED,
  addShiftLocation: StatusRequest.NOT_STARTED,
  editShiftLocation: StatusRequest.NOT_STARTED,
  addDoerShiftToLocation: StatusRequest.NOT_STARTED,
  deleteDoerShiftToLocation: StatusRequest.NOT_STARTED,
  commitShifts: StatusRequest.NOT_STARTED,
  fetchRoles: StatusRequest.NOT_STARTED,
  fetchRoleGroups: StatusRequest.NOT_STARTED,
  addRole: StatusRequest.NOT_STARTED,
  fetchDoerRoles: StatusRequest.NOT_STARTED,
  addDoerRole: StatusRequest.NOT_STARTED,
  deleteDoerRole: StatusRequest.NOT_STARTED,
  fetchShiftTypesByAllRoles: StatusRequest.NOT_STARTED,
  fetchNews: StatusRequest.NOT_STARTED,
  addNews: StatusRequest.NOT_STARTED,
  getSingleNews: StatusRequest.NOT_STARTED,
  deleteSingleNews: StatusRequest.NOT_STARTED,
  updateSingleNews: StatusRequest.NOT_STARTED,
  deleteNewsRole: StatusRequest.NOT_STARTED,
  updateRole: StatusRequest.NOT_STARTED,
  fetchUserSubscriptions: StatusRequest.NOT_STARTED,
  fetchUserOrders: StatusRequest.NOT_STARTED,
  fetchShiftDoers: StatusRequest.NOT_STARTED,
  doerClearWallet: StatusRequest.NOT_STARTED,
  deleteShift: StatusRequest.NOT_STARTED,
  importShift: StatusRequest.NOT_STARTED,
  changeDoerShiftStatus: StatusRequest.NOT_STARTED,
  fetchOffers: StatusRequest.NOT_STARTED,
  fetchOfferReserves: StatusRequest.NOT_STARTED,
  setShiftDoer: StatusRequest.NOT_STARTED,
  fetchOfferById: StatusRequest.NOT_STARTED,
  fetchOperations: StatusRequest.NOT_STARTED,
  fetchOperation: StatusRequest.NOT_STARTED,
  editOperation: StatusRequest.NOT_STARTED,
  deleteOperation: StatusRequest.NOT_STARTED,
  changeOperationState: StatusRequest.NOT_STARTED,
  fetchOperationTasks: StatusRequest.NOT_STARTED,
  createSuboperation: StatusRequest.NOT_STARTED,
  changeReserveOffer: StatusRequest.NOT_STARTED,
  fetchReserveOffers: StatusRequest.NOT_STARTED,
  fetchOperationLocations: StatusRequest.NOT_STARTED,
  addDoerToShift: StatusRequest.NOT_STARTED,
  getDoerByName: StatusRequest.NOT_STARTED,
  cancelShift: StatusRequest.NOT_STARTED,
  fetchShiftOffers: StatusRequest.NOT_STARTED,
  paidBill: StatusRequest.NOT_STARTED,
  payingBill: StatusRequest.NOT_STARTED,
  createOperationOrders: StatusRequest.NOT_STARTED,
  closeOperationOrders: StatusRequest.NOT_STARTED,
  getBillCalculations: StatusRequest.NOT_STARTED,
  postBillCalculations: StatusRequest.NOT_STARTED,
  fetchBillSyncState: StatusRequest.NOT_STARTED,
  fetchBillSyncStateV2: StatusRequest.NOT_STARTED,
  fetchTasks: StatusRequest.NOT_STARTED,
  changeDoerShift: StatusRequest.NOT_STARTED,
  fetchShiftsForRedirect: StatusRequest.NOT_STARTED,
  fetchRentByPeriod: StatusRequest.NOT_STARTED,
  sendToBilling: StatusRequest.NOT_STARTED,
  getDoerById: StatusRequest.NOT_STARTED,
  fetchLocationChildren: StatusRequest.NOT_STARTED,
  addLocationChildren: StatusRequest.NOT_STARTED,
  deleteLocationChildren: StatusRequest.NOT_STARTED,
  fetchStaticLocations: StatusRequest.NOT_STARTED,
  getLocationPhotos: StatusRequest.NOT_STARTED,
  deleteLocationPhoto: StatusRequest.NOT_STARTED,
  addLocationPhoto: StatusRequest.NOT_STARTED,
  putLocationPhoto: StatusRequest.NOT_STARTED,
  getDoerShifts: StatusRequest.NOT_STARTED,
  getDoerReserves: StatusRequest.NOT_STARTED,
  getMailing: StatusRequest.NOT_STARTED,
  addMailing: StatusRequest.NOT_STARTED,
  getDoerMotivations: StatusRequest.NOT_STARTED,
  getDoerPenalty: StatusRequest.NOT_STARTED,
  getDoerCompensation: StatusRequest.NOT_STARTED,
  getPenalties: StatusRequest.NOT_STARTED,
  addDoerPenalties: StatusRequest.NOT_STARTED,
  addDoerCompensations: StatusRequest.NOT_STARTED,
  importMotivations: StatusRequest.NOT_STARTED,
  applyPenalty: StatusRequest.NOT_STARTED,
  cancelPenalty: StatusRequest.NOT_STARTED,
  getYandexProReports: StatusRequest.NOT_STARTED,
  getYandexProReport: StatusRequest.NOT_STARTED,
  addYandexProReports: StatusRequest.NOT_STARTED,
  deployYandexProReport: StatusRequest.NOT_STARTED,
  connectYandexProExecutorAndDoer: StatusRequest.NOT_STARTED,
  updateYandexProExecutorAndDoer: StatusRequest.NOT_STARTED,
  getYandexPROExecutors: StatusRequest.NOT_STARTED,
  deleteYandexProExecutor: StatusRequest.NOT_STARTED,
  getMonitoringAllocations: StatusRequest.NOT_STARTED,
  updateMonitoringAllocations: StatusRequest.NOT_STARTED,
  updatedMonitoringAllocationsData: StatusRequest.NOT_STARTED,
  getDoerVacancies: StatusRequest.NOT_STARTED,
  addVacancy: StatusRequest.NOT_STARTED,
  getVacancy: StatusRequest.NOT_STARTED,
  updateVacancy: StatusRequest.NOT_STARTED,
  openVacancyById: StatusRequest.NOT_STARTED,
  cancelVacancyById: StatusRequest.NOT_STARTED,
  getYandexLavkaReports: StatusRequest.NOT_STARTED,
  getYandexLavkaReportById: StatusRequest.NOT_STARTED,
  connectLavkaUnmatchedExecutors: StatusRequest.NOT_STARTED,
  deployYandexLavkaReport: StatusRequest.NOT_STARTED,
  getUrentReports: StatusRequest.NOT_STARTED,
  getUrentReportById: StatusRequest.NOT_STARTED,
  connectUrentUnmatchedExecutors: StatusRequest.NOT_STARTED,
  deployUrentReport: StatusRequest.NOT_STARTED,
  fetchInternships: StatusRequest.NOT_STARTED,
  cancelInternship: StatusRequest.NOT_STARTED,
  editInternshipCount: StatusRequest.NOT_STARTED,
  fetchRentalObjectTypes: StatusRequest.NOT_STARTED,
  fetchRentalObjectType: StatusRequest.NOT_STARTED,
}

export const requestsReducer: Reducer<RequestsState, Action> = (
  state = defStateRequests,
  a
): RequestsState => {
  switch (a.type) {
    case Actions.CHANGE_REQUEST_STATUS:
      const newState = {
        ...state,
        [a.data.requestName]: a.data.status,
      }

      return { ...newState }
  }

  return state
}
