import React, { useEffect } from 'react'
import { Button, Drawer, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import qs from 'qs'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { fetchProducts } from 'AC/products/products'
import { getEntries } from 'utils/enumUtils'
import { isObjectEmpty } from 'utils/filterUtils'
import { getUrlSearchParams } from 'utils/urlUtils'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const productTypeOptions = getEntries(APITYPES.ProductTypeTrans)

const selector = (state: State) => ({filter: state.productsReducer.productsFilter})

export const Filters = ({isOpen, onClose}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = getUrlSearchParams()
  const {filter} = useSelector(selector)

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)
      dispatch(action(Actions.SET_PRODUCTS_FILTER, {...parsed}))
      dispatch(fetchProducts(parsed))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.PRODUCTS.PARAMS.createPath(filter))
      dispatch(fetchProducts(filter))
    } else {
      dispatch(fetchProducts())
    }
  }, [])

  const onFilterChange = (partial: Partial<APITYPES.ProductsFilter>) => {
    const newFilter = {...filter, ...partial}
    dispatch(action(Actions.SET_PRODUCTS_FILTER, newFilter))
    history.push(ROUTES.PRODUCTS.PARAMS.createPath(newFilter))
    dispatch(fetchProducts(newFilter))
  }

  const resetFilters = () => {
    dispatch(action(Actions.SET_PRODUCTS_FILTER, {}))
    history.push(ROUTES.PRODUCTS.path)
    dispatch(fetchProducts())
  }

  return (
    <Drawer
      title="Фильтры"
      onClose={onClose}
      visible={isOpen}
    >
      <Form layout="vertical">
        <Form.Item label="Тип">
          <Select
            allowClear
            value={filter?.type}
            placeholder="Выберите тип продукта"
            onChange={(value) => onFilterChange({type: value})}
          >
            {productTypeOptions.map(type => (
              <Select.Option key={type[0]} value={type[0]}>{type[1] as string}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
