import React from 'react'
import { Form, Input, Modal } from 'antd'
import { useDispatch } from 'react-redux'

import { updateRole } from 'AC/doers/doerRoles'
import { FormFooter } from 'components/FormFooter'
import { APITYPES } from 'types/apitypes'


type Props = {
  isOpen: boolean
  onClose: () => void
  role: APITYPES.Role | null
}

export const EditModal = (props: Props) => {
  const { isOpen, onClose, role } = props
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    if(!role) return
    
    dispatch(updateRole(role.id, { name: values.name, city_id: role.city_id }))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      destroyOnClose
      afterClose={afterClose}
      footer={false}
      title='Редактирование роли'
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label='Название'
          name='name'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          initialValue={role?.name}
        >
          <Input />
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Изменить'
        />
      </Form>
    </Modal>
  )
}
