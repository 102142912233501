// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--bP6Bd{border:0}.styles-module--field--lqYq\\+{display:-webkit-box;display:-ms-flexbox;display:flex;padding-left:8px;width:100%}.styles-module--title--fKi6o{font-weight:700;font-size:13.5px;margin-right:8px}.styles-module--value--3s-r-{font-size:13.5px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Motivations/PenaltyTab/PenaltyRecords/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,QAAA,CAEF,8BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,gBAAA,CACA,UAAA,CAEF,6BACE,eAAA,CACA,gBAAA,CACA,gBAAA,CAEF,6BACE,gBAAA","sourcesContent":[".card\n  border: 0\n\n.field\n  display: flex\n  padding-left: 8px\n  width: 100%\n\n.title\n  font-weight: 700\n  font-size: 13.5px\n  margin-right: 8px\n\n.value\n  font-size: 13.5px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--bP6Bd",
	"field": "styles-module--field--lqYq+",
	"title": "styles-module--title--fKi6o",
	"value": "styles-module--value--3s-r-"
};
export default ___CSS_LOADER_EXPORT___;
