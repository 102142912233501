import React from 'react'
import { Tag } from 'antd'
import { useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { useShiftsFilterContext } from '../../../context'
import { getCityName, getLocationName, getRoleName, getShiftTypeName } from './utils'

const selector = (state: State) => ({
  locations: state.shiftsReducer.locations,
  shiftTypes: state.shiftsReducer.shiftTypes,
  roles: state.doersReducer.roles,
})

export const FilterTags = () => {
  const {shiftTypes, locations, roles} = useSelector(selector)

  const {
    shiftsFilter,
    onCityFilter,
    onShiftTypeFilter,
    onLocationChange,
    onRolesChange,
  } = useShiftsFilterContext()

  return (
    <>
      <Tag closable onClose={() => onCityFilter()} visible={Boolean(shiftsFilter.city_id)}>
        {getCityName(shiftsFilter.city_id)}
      </Tag>

      <Tag closable onClose={() => onShiftTypeFilter()} visible={Boolean(shiftsFilter.shift_type_id)}>
        {getShiftTypeName(shiftsFilter.shift_type_id, shiftTypes)}
      </Tag>

      <Tag closable onClose={() => onLocationChange()} visible={Boolean(shiftsFilter.location_id)}>
        {getLocationName(shiftsFilter.location_id, locations)}
      </Tag>

      <Tag closable onClose={() => onRolesChange()} visible={Boolean(shiftsFilter.role_id)}>
        {getRoleName(shiftsFilter.role_id, roles || [])}
      </Tag>
    </>
  );
};
