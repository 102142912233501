import React from 'react'
import { Tabs } from 'antd'

import { APITYPES } from 'types/apitypes'
import { useShiftTabsContext } from '../context'
import { formattedDate } from './ShiftTab/utils'

import { ShiftTab } from './ShiftTab'
import { ShiftsTable } from './ShiftsTable'
import { ExtraTabActions } from './ExtraTabActions'
import styles from './styles.module.sass'


const { TabPane } = Tabs

export const Shifts = () => {
  const { tabActions, activeTab, shiftTabs, DEFAULT_TAB_KEY } = useShiftTabsContext()

  
  return (
    <Tabs
      className={styles.tabs}
      type='editable-card'
      hideAdd
      onEdit={tabActions.handleCloseTab}
      activeKey={activeTab}
      onTabClick={tabActions.onTabClick}
      destroyInactiveTabPane
      tabBarStyle={{ gap: 12 }}
      tabBarExtraContent={
        {
          right: <ExtraTabActions.Right />,
          left: <ExtraTabActions.Left />,
        }
      }
    >
      <TabPane
        tab='Смены'
        key={DEFAULT_TAB_KEY}
        closeIcon={<></>}
        forceRender
      >
        <ShiftsTable />
      </TabPane>

      {
        shiftTabs.map((tab: APITYPES.Shift) => (
          <TabPane
            key={tab.id}
            tab={`${tab.shift_type}, ${formattedDate(tab.date)}`}
          >
            <ShiftTab shift={tab} />
          </TabPane>
        ))
      }
    </Tabs>
  )
}
