import React, { useEffect, useState } from 'react'
import { Button, Tabs } from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, NavLink } from 'react-router-dom'

import { fetchAllCarClasses, fetchAllServices, fetchClientTypes } from 'AC/directory/directory'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { ROUTES } from 'config/routes'
import { NestedSwitch } from 'components/NestedSwitch'
import { getUrlParam } from 'utils/urlUtils'

import { CreateCard } from './CreateCard'
import styles from './styles.module.sass'
import { routes } from './utils'


const { TabPane } = Tabs

const selector = (state: State) => {
  const isClientTypeLoading = state.requestsReducer.fetchClientTypes === StatusRequest.LOADING
  const isCarClassesLoading = state.requestsReducer.fetchAllCarClasses === StatusRequest.LOADING
  const isServicesLoading = state.requestsReducer.fetchAllServices === StatusRequest.LOADING
  const isClientTypeCarsLoading = state.requestsReducer.fetchCarClasses === StatusRequest.LOADING
  const isClientTypeServicesLoading = state.requestsReducer.fetchServices === StatusRequest.LOADING

  const isClientDataLoading = isCarClassesLoading
    || isServicesLoading
    || isClientTypeLoading
    || isClientTypeCarsLoading
    || isClientTypeServicesLoading

  return {
    clientTypes: state.directoryReducer.clientTypes,
    isClientDataLoading,
  }
}

export const ClientTypes = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const { clientTypes, isClientDataLoading } = useSelector(selector)
  const id = getUrlParam(pathname, 1)

  useEffect(() => {
    batch(() => {
      dispatch(fetchClientTypes())
      dispatch(fetchAllCarClasses())
      dispatch(fetchAllServices())
    })
  }, [dispatch])

  useEffect(() => {
    if (!id && clientTypes) {
      setTab(id ?? clientTypes[0].id)
    }
  }, [id, isClientDataLoading])

  const [isCreating, setIsCreating] = useState(false)

  const setTab = (id: string) => {
    history.push(ROUTES.DIRECTORY.CLIENT_TYPES.ID.createPath(id))
  }

  if (!clientTypes) {
    return <Loader />
  }

  return (
    <>
      <Tabs
        activeKey={id ? id : clientTypes[0].id}
        tabPosition='left'
        className={styles.tabs}
        destroyInactiveTabPane
        tabBarExtraContent={
          {
            left: (
              <Button
                type='primary'
                onClick={() => setIsCreating(true)}
                className={styles.addButton}
              >
                Добавить тип клиента
              </Button>),
          }
        }
      >
        {
          clientTypes?.map((type) => (
            <TabPane
              tab={
                <NavLink to={ROUTES.DIRECTORY.CLIENT_TYPES.ID.createPath(type.id)}>
                  <div className={styles.tabPane}>
                    { type.name }
                  </div>
                </NavLink>
              }
              key={type.id}
              forceRender={false}
              disabled={isClientDataLoading}
            >
              <NestedSwitch routes={routes} />
            </TabPane>
          ))
        }
      </Tabs>

      <CreateCard
        isVisible={isCreating}
        onCancel={() => setIsCreating(false)}
      />
    </>
  )
}