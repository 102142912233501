import { APITYPES } from 'types/apitypes'


export const getTreeDataFromParent = (parent: APITYPES.Operation | null) => {
  if (!parent) { return [] }

  return [{
    title: parent.title,
    key: parent.id,
    selectable: true,
    operation: parent,
    children: parent.operations ? getTreeData(parent.operations) : undefined,
  }]
}

// @ts-ignore
export const getTreeData = (operations: APITYPES.Operation[]) => {
  return operations.map((operation) => ({
    title: operation.title,
    key: operation.id,
    selectable: true,
    operation: operation,
    children: operation.operations ? getTreeData(operation.operations) : undefined,
  }),
  )
}

export const getTreeDataCustom = (operations: any) => {
  // eslint-disable-next-line arrow-parens
  if (operations.length > 0) {
    return operations.map((operation: { title: any, id: any, subtasks: any }) => ({
      title: operation.title,
      key: operation.id,
      selectable: true,
      operation: operation,
      children: operation.subtasks ? getTreeDataCustom(operation.subtasks) : undefined,
    }),
    )
  }
}

export const getTreeCustom = (parent: any) => {
  if (!parent) { return [] }

  return [{
    title: parent.title,
    key: parent.id,
    selectable: true,
    operation: parent,
    children: parent.subtasks ? getTreeDataCustom(parent.subtasks) : undefined,
  }]
}

export const addCustomFields = (subTasks: any, grz: string, doer_shift_id: string): any => {
  if (!subTasks) { return [] }

  return subTasks.map((el: { subtasks: any }) => {
    return ({
      ...el,
      subtasks: el.subtasks ? addCustomFields(el.subtasks, grz, doer_shift_id) : undefined,
      grz,
      doer_shift_id,
    })
  })
}

export const addCustomFieldsMain = (subTasks: any, grz: string, doer_shift_id: string): any => {
  const a = addCustomFields(subTasks, grz, doer_shift_id)

  return a
}

export function deepSearchChildren(node: { subtasks: any; id: any }, selectedIds: string | any[]) {
  const children = (node.subtasks || [])
    .map((child: any) => deepSearchChildren(child, selectedIds))
    .filter((child: null) => child !== null);
  if (children.length > 0 || selectedIds?.includes(node.id)) {
    return {
      ...node,
      subtasks: children,
    };
  }
  return null;
}


export const getAllKeys = (tasks: any) => {
  const b: any[] = []
  const findItemNested = (arr: any) => {
    for (const i of arr) {
      if (i.key) {
        b.push(i.key)
      }
      if (i.children) {
        findItemNested(i.children);
      }
    }
  };
  const res = findItemNested([...tasks])

  return b

}