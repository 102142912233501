import { errorNotification, successNotification } from '../components/modals/OperationNotification'


const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')

  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus();textArea.select()
  try {
    document.execCommand('copy')
  } catch (err){
    errorNotification('Не удалось скопировать текст')
  }
  document.body.removeChild(textArea)
}

/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
 */
export const copyToClipboard = (content: string, successText = 'Успешно скопировано!') => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content)
  } else {
    unsecuredCopyToClipboard(content)
  }

  successNotification(successText)
}