import React from 'react'
import { Tag } from 'antd'

import { APITYPES } from 'types/apitypes'
import { dateFormat, getFormattedDate } from 'utils/dateUtils'
import { vacancyPriorities } from '../../../../../utils/enumUtils'

import VacancyStateTrans = APITYPES.VacancyStateTrans


const priorityColors = ['red', 'orange', 'yellow', 'green']

const getDemandColor = (efficiency: number) => {
  if (efficiency < 0.5) {return 'red'}

  if (efficiency < 1 && efficiency >= 0.5) {return 'orange'}

  return ''
}

export const vacancyStateColors: Record<keyof typeof VacancyStateTrans, string> = {
  open: 'green',
  canceled: 'gray',
  closed: 'blue',
}

export const getColumns = () => ([
  {
    title: 'Приоритет',
    dataIndex: 'priority',
    key: 'priority',
    align: 'center',
    width: 105,
    map: (vacancy: APITYPES.VacancyWithDoersCount) => (
      <Tag
        style={{ margin: 0 }}
        color={priorityColors[vacancy.priority - 1]}
        key={vacancy.id}
      >
        { vacancyPriorities[vacancy.priority] }
      </Tag>
    ),
  },
  {
    title: 'Город',
    dataIndex: 'city_name',
    key: 'city_name',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => vacancy.city.name,
  },
  {
    title: 'Роль',
    dataIndex: 'role_name',
    key: 'role_name',
    align: 'center',
    width: '20%',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => vacancy.role?.name,
  },
  {
    title: 'График',
    dataIndex: 'schedule',
    key: 'schedule',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => `${vacancy.begin} - ${vacancy.end}`,
  },
  {
    title: 'Локация',
    dataIndex: 'location',
    key: 'location',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => vacancy.location?.name || '-',
  },
  {
    title: 'Создана',
    dataIndex: 'created',
    key: 'created',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => getFormattedDate(vacancy['created'], dateFormat),
  },
  {
    title: 'Закрыта',
    dataIndex: 'ended',
    key: 'ended',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => vacancy['ended'] ? getFormattedDate(vacancy['ended'], dateFormat) : '-',
  },
  {
    title: 'Срок выполнения',
    dataIndex: 'target_date',
    key: 'target_date',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => getFormattedDate(vacancy.target_date, dateFormat),
  },
  {
    title: 'Потребность',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    map: ({ doers_count, value, efficiency, id }: APITYPES.VacancyWithDoersCount) => (
      <Tag
        style={{ margin: 0 }}
        color={getDemandColor(efficiency)}
        key={id}
      >
        { doers_count }
        { ' / ' }
        { value }
      </Tag>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'state',
    key: 'state',
    align: 'center',
    map: (vacancy: APITYPES.VacancyWithDoersCount) =>  (
      <Tag
        style={{ margin: 0 }}
        color={vacancyStateColors[vacancy.state]}
        key={vacancy.id}
      >
        { VacancyStateTrans[vacancy.state] }
      </Tag>
    ),
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    align: 'center',
    width: '20%',
    map: (vacancy: APITYPES.VacancyWithDoersCount) => vacancy.comment || '-',
  },
])

export const getRows = (vacancies: APITYPES.VacancyWithDoersCount[]) => {
  return vacancies.map((vacancy) => {
    const row: any = {}

    getColumns().forEach((column) => {
      row[column.key] = column?.map
        ? column.map(vacancy)
        : vacancy[column.key as keyof APITYPES.VacancyWithDoersCount]
    })
    row.key = vacancy.id

    return row
  })
}
