import React from 'react'
import { Tag, Tooltip } from 'antd'
import moment from 'moment'
import { APITYPES } from 'types/apitypes'

import styles from './styles.module.sass'


export const getColumns = () => ([
  {
    title: 'Имя',
    dataIndex: 'legal_name',
    key: 'legal_name',
    width: '20%',
  },
  {
    title: 'ИНН',
    dataIndex: 'inn',
    key: 'inn',
    width: 140,
    align: 'center',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    width: 140,
    align: 'center',
  },
  {
    title: 'Адрес',
    dataIndex: 'legal_address',
    key: 'legal_address',
    render: (address: string | undefined) => (
      <Tooltip overlay={address} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <div className={styles.address}>{address ? address : '-'}</div>
      </Tooltip>
    )
  },
  {
    title: 'Отключен',
    dataIndex: 'disconnected',
    key: 'disconnected',
    width: 160,
    align: 'center',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: 140,
    align: 'center',
    render: (type: APITYPES.ContractorType) => <Tag>{APITYPES.ContractorTypeTrans[type]}</Tag>
  },
])

export const getRows = (contractors: APITYPES.Contractor[]) => {
  const data = contractors.map((contractor) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'legal_address') {
        row[column.dataIndex] = contractor?.legal_address ? contractor?.legal_address : undefined
      } else if (column.dataIndex === 'inn') {
        row[column.dataIndex] = contractor?.inn ? contractor?.inn : '-'
      } else if (column.dataIndex === 'phone') {
        row[column.dataIndex] = contractor?.phone ? contractor?.phone : '-'
      } else if (column.dataIndex === 'disconnected') {
        row[column.dataIndex] = contractor?.disconnected ? moment(contractor?.disconnected).format('L') : '-'
      }
      else {
        //@ts-ignore
        row[column.dataIndex] = contractor[column.dataIndex]
      }

      return
    })

    row.key = contractor.id

    return row
  })

  return data
}