import React from 'react'
import { Tag } from 'antd'
import { BillStateTranslation } from '../../Filters/utils'
import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'


export const getColumns = () => [
  {
    title: 'Статус',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '15%',
    align: 'center',
    //@ts-ignore
    render: (name: string) => <Tag>{ BillStateTranslation[name] }</Tag>,
  },
  {
    title: 'Имя',
    dataIndex: 'actor',
    key: 'actor',
    width: '30%',
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
    width: '20%',
    align: 'center',
  },
]

export const getRows = (history: APITYPES.BillHistory[]) => {
  return history.map((item, index) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'created') {
        row[column.dataIndex] = getFormattedDate(item[column.dataIndex])
      } else {
        //@ts-ignore
        row[column.dataIndex] = item[column.dataIndex]
      }

      return
    })
    row.key = index

    return row
  })
}