import React from 'react'
import { APITYPES } from 'types/apitypes'
import styles from './styles.module.sass'

export const listStyle = {
  width: '30%',
  height: `calc(100vh - 308px)`,
}

export const transferTitles = [
  <div className={styles.listTitle} key='roles'>Все роли</div>,
  <div className={styles.listTitle} key='doer_roles'>Роли исполнителя</div>,
]

type Transferable = {
  key: string
  title: string
  description?: string
  disabled?: boolean
}

export const getSourceColumn = (data: APITYPES.Role[]): Transferable[] => {
  const result: Transferable[] = []

  data.forEach((role) => {
    result.push({
      key: role.id,
      title: role.name,
    })
  })

  return result
}

export const getTargetKeys = (data: APITYPES.DoerRole[]) => {
  return data.map((role) => role.role_id)
}