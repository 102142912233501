import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ROUTES } from 'config/routes'

import { SignIn } from './SignIn'


export const PublicRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.SIGN_IN.path}
      >
        <SignIn />
      </Route>
      <Redirect to={ROUTES.SIGN_IN.path} />
    </Switch>
  )
}
