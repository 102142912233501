import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type DoersState = {
  doers: APITYPES.Doer[] | null
  doersFilter: APITYPES.DoersFilterType
  roles: APITYPES.Role[] | null
  currentDoerRoles?: APITYPES.Doers.DoerRole.GET.Resp
  hasMore: boolean

  doer: APITYPES.Doers.GetById.Resp | null
  doerShifts: APITYPES.DoerShift[]
  hasMoreDoerShifts: boolean

  doerReserves: APITYPES.DoerReserve[]
  hasMoreDoerReserves: boolean

  searchedDoers: APITYPES.Doers.Get.Resp
  searchedDoer: APITYPES.Doers.GetById.Resp | null

  roleGroups: APITYPES.RoleGroups[] | null
  rolesFilter: APITYPES.RolesFilter
}

export const initialUsersState = {
  doers: null,
  doersFilter: {
    name: undefined,
    phone_number: undefined,
    state: undefined,
    role_id: undefined,
  },
  hasMore: true,

  doer: null,
  doerShifts: [],
  hasMoreDoerShifts: true,

  doerReserves: [],
  hasMoreDoerReserves: true,
  roles: null,

  searchedDoers: [],
  searchedDoer: null,

  roleGroups: [],
  rolesFilter: {},
}

export const doersReducer: Reducer<DoersState, Action> = (
  state = initialUsersState,
  a
): DoersState => {
  switch (a.type) {
    case Actions.CLEAR_DOER_SHIFTS: {
      return {
        ...state,
        doerShifts: [],
        hasMoreDoerShifts: true,
      }
    }

    case Actions.CLEAR_DOER_RESERVES: {
      return {
        ...state,
        doerReserves: [],
        hasMoreDoerReserves: true,
      }
    }

    case Actions.SET_DOER_RESERVES: {
      return {
        ...state,
        doerReserves: [...state.doerReserves, ...a.data.doerReserves],
        hasMoreDoerReserves: a.data.hasMoreDoerReserves,
      }
    }

    case Actions.SET_DOER_SHIFTS: {
      return {
        ...state,
        doerShifts: [...state.doerShifts, ...a.data.doerShifts],
        hasMoreDoerShifts: a.data.hasMoreDoerShifts,
      }
    }

    case Actions.SET_DOER: {
      return {
        ...state,
        doer: a.data,
      }
    }

    case Actions.SET_SEARCHED_DOER: {
      return {
        ...state,
        doer: a.data,
      }
    }

    case Actions.SET_SEARCHED_DOERS: {
      return {
        ...state,
        searchedDoers: a.data,
      }
    }

    case Actions.SET_CURRENT_DOER_ROLES:
      return {
        ...state,
        currentDoerRoles: a.data,
      }

    case Actions.SET_ROLES:
      return {
        ...state,
        roles: a.data,
      }

    case Actions.CLEAN_ROLES:
      return {
        ...state,
        roles: null,
      }

    case Actions.SET_ROLE_GROUPS:
      return {
        ...state,
        roleGroups: a.data,
      }

    case Actions.CLEAN_DOERS:
      return {
        ...state,
        doers: [],
      }

    case Actions.SET_DOERS:
      return {
        ...state,
        doers: state.doers ? [...state.doers, ...a.data.doers] : a.data.doers,
        hasMore: a.data.hasMore,
      }

    case Actions.SET_DOERS_FILTER:
      return {
        ...state,
        doersFilter: a.data,
      }

    case Actions.UPDATE_DOER:
      return {
        ...state,
        doers: state.doers?.map((item) =>
          item.id === a.data.id ? a.data : item
        ) ?? null,
      }

    case Actions.SET_ROLES_FILTER:
      return {
        ...state,
        rolesFilter: a.data,
      }
  }

  return state
}
