import React from 'react'
import { Button } from 'antd'
import { APITYPES } from 'types/apitypes'
import { getEnumKeys } from 'utils/enumUtils'


const { ShiftLocationsTypes } = APITYPES
export const cities = getEnumKeys(APITYPES.Cities)

export const getColumns = (onDelete?: (locationId: string) => void) => ([
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'Тип локации',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 140,
    align: 'center',
    render: (locationId: string) => {
      return <Button type='link' onClick={onDelete ? () => onDelete(locationId) : undefined}>Удалить</Button>
    }
  },
])

export const getRows = (locations: APITYPES.Shifts.ShiftLocations.GET.Resp) => {
  return locations.map((location) => {
    const row: any = {}

    getColumns().map((column) => {
      if(column.dataIndex === 'actions') {
        row[column.dataIndex] = location.id
      } else if (column.dataIndex === 'name') {
        row[column.dataIndex] = location.name
      } else {
        //@ts-ignore
        row[column.dataIndex] = ShiftLocationsTypes[location[column.dataIndex]]
      }
    })
    row.key = location.id
    row.location = location

    return row
  })
}