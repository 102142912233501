import {
  action,
  Actions,
  ThAction,
} from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { api } from '../../index'
import { openNotification } from '../../components/modals/OperationNotification'


export const getMailingList = (params?: APITYPES.Mailing.GetList.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getMailing', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Mailing.GetList.URL, {
        params: {
          ...params,
          offset: getState().mailingReducer.mailingList?.length ?? 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_MAILING, {
          mailing: resp,
          hasMoreMailing: Boolean(resp.length),
        }))

        dispatch(changeRequestState('getMailing', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getMailing', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const addMailing = (body: APITYPES.Mailing.Post.Req, onFinish?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addMailing', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Mailing.Post.URL, body)

      if (resp) {
        onFinish?.()
        dispatch(action(Actions.CLEAN_MAILS, {}))
        dispatch(getMailingList())
        dispatch(changeRequestState('addMailing', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addMailing', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getMailing = async (id: string) => {
  try {
    return await api.get(APITYPES.Mailing.Get.URL, { replaceUrl: { id } }) as APITYPES.Mailing.Get.Resp
  } catch (e: any) {
    showError(e.response.data.description)
    throw(e)
  }
}

export const exportRecipients = async (id: string) => {
  const data = await api.post(APITYPES.Mailing.RecipientsExport.URL, {}, {
    replaceUrl: { id },
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', `recipients-${id}.xlsx`)
  document.body.appendChild(link)
  link.click()
  openNotification('Файл загружен')
}