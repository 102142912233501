import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, Modal } from 'antd'
import { APITYPES } from 'types/apitypes'
import { required } from 'utils/formUtils'
import { FormFooter } from 'components/FormFooter'
import { deleteProductGroup, editProductGroup } from 'AC/products/products'

type Props = {
  isOpen: boolean
  group: APITYPES.ProductGroup | null
  onClose: () => void
}

export const EditModal = ({isOpen, onClose, group}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    if(!group?.id) return
    dispatch(editProductGroup(group.id, values))
    onClose()
  }

  const onDelete = () => {
    if(!group?.id) return
    dispatch(deleteProductGroup(group.id))
    onClose()
  }

  return (
    <Modal
      title="Редактирование группы продуктов"
      visible={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      destroyOnClose
      footer={false}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item label='Название' name='name' rules={required} initialValue={group?.name}>
          <Input placeholder='Введите название' />
        </Form.Item>

        <FormFooter onCancel={onClose} okText='Изменить' leftElement={
          <Button danger type='primary' onClick={onDelete}>Удалить</Button>
        } />
      </Form>
    </Modal>
  );
};
