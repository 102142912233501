import React from 'react'
import { Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { cancelInternship } from 'AC/internships/internships'
import { required } from 'utils/formUtils'
import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'


type Props = {
  internshipId: string
  onClose: () => void
}

type FormValues = {
  comment: string
}

const selector = (state: State) => {
  return { isLoading: state.requestsReducer.cancelInternship === StatusRequest.LOADING }
}

export const CancelInternshipForm = (props: Props) => {
  const { internshipId, onClose } = props
  const [form] = Form.useForm<FormValues>()
  const { isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const onFinish = (values: FormValues) => {
    dispatch(cancelInternship(internshipId, values.comment, onClose))
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label='Комментарий'
        name='comment'
        rules={required}
      >
        <Input.TextArea autoSize={
          {
            minRows: 4,
            maxRows: 6,
          }
        }
        />
      </Form.Item>
      <FormFooter
        isLoading={isLoading}
        onCancel={onClose}
        okText='Отменить стажировку'
      />
    </Form>
  )
}
