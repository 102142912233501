import { useQuery } from '@tanstack/react-query'

import { useDebounce } from '../../../utils/debounce'
import { APITYPES } from '../../../types'
import { api } from '../../../index'


export const usePartnersSearch = (partnersQuery: string | undefined, domain?:  APITYPES.RentalObjectTypeDomain) => {
  const debouncedPartnersQuery = useDebounce(partnersQuery, 500)

  const partnersQueryParams: APITYPES.Partners.Get.Req = {
    domain: domain,
    limit: 10,
    search: debouncedPartnersQuery,
  }
  const {
    isLoading,
    data,
  } = useQuery<APITYPES.Partners.Get.Resp>(
    ['fetchPartners', JSON.stringify(partnersQueryParams)],
    () => api.get(APITYPES.Partners.Get.URL, { params: partnersQueryParams }),
  )

  return [
    isLoading,
    data,
  ] as const
}