import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { fetchDoersFromInternship } from 'AC/internships/internships'
import { action, Actions } from 'actions'
import { AddDoerModal } from '../../../Doers/Doer/AddDoerModal'


const selector = (state: State) => {
  return {
    hasInDoers: state.internshipsReducer.hasInDoers,
    filters: state.internshipsReducer.filters,
  }
}

export const NotFoundResult = () => {
  const { hasInDoers, filters } = useSelector(selector)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const hasNoInDoersMessage = {
    emptyText: (
      <span>
        <p>
          Исполнителя нет в базе
        </p>
        <Button onClick={() => setIsModalOpen(true)}>Добавить исполнителя</Button>
      </span>
    ),
  }

  const hasInDoersMessage = {
    emptyText: (
      <span>
        <p>
          Исполнитель не найден
        </p>
      </span>
    ),
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }


  useEffect(() => {
    if (filters?.search) {
      dispatch(action(Actions.SET_INTERNSHIPS_DOER_SEARCH, true))
      dispatch(fetchDoersFromInternship(filters.search))
    }
  }, [])


  return (
    <>
      <Table locale={(hasInDoers || !filters?.search) ? hasInDoersMessage : hasNoInDoersMessage} />
      <AddDoerModal
        shortForm
        isOpen={isModalOpen}
        search={filters?.search || ''}
        onSuccess={closeModal}
        onClose={closeModal}
      />
    </>
  )
}