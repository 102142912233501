import React, { useEffect, useMemo, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { getUrlSearchParams } from 'utils/urlUtils'
import { DebounceSelect, fetchUserList, SelectValue } from 'components/DebounceSelectDoer'
import { State } from 'stores/rootReducer'
import { getDoerMotivations, getPenalties } from 'AC/doers/penalties'
import { getInitDatesState, getInitDoer } from 'App/PrivateRoutes/Billing/Bills/Filters/utils'
import { APITYPES } from 'types/apitypes'
import { backDateFormat, dateFormat } from 'utils/dateUtils'
import { states, types } from './utils/filterConstants'
import { getCitiesOptions } from '../../../../utils/enumUtils'
import { getDoers } from '../../../../AC/doers/getDoers'

import { useGetRoles } from './hooks'


type Props = {
  isOpen: boolean
  onClose: () => void
}

type M = moment.Moment


const selector = (state: State) => {
  const { penalties } = state.motivationsReducer

  return {
    penalties,
    doers: state.doersReducer.doers,
    filter: state.motivationsReducer.doerMotivationsFilter,
  }
}

const cities = getCitiesOptions()

export const Filters = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = getUrlSearchParams()
  const { doers, filter, penalties } = useSelector(selector)
  const roles = useGetRoles({})

  const [dates, setDates] =
    useState<M[] | undefined>(getInitDatesState(params?.begin ?? filter?.begin, params?.end ?? filter?.end))

  const userValue = useMemo(() => doers?.map(({ id, name, phone_number }) => ({
    label: `${name} (${phone_number})`,
    value: id,
  })) ?? [], [doers])


  const [user, setUser] = useState<SelectValue | undefined | null>()

  useEffect(() => {
    const selectedUser = getInitDoer(filter?.doer_user_id ?? params?.doer_user_id, userValue)

    if (selectedUser) {
      setUser(selectedUser)
    }
  }, [userValue.length])

  const onDateChange = (dates: any) => {
    if (!dates) {
      setDates(undefined)
      onFilterChange({
        begin: undefined,
        end: undefined,
      })
    } else {
      setDates(dates)
      const [dates_begin, dates_end] = dates

      onFilterChange({
        begin: dates_begin.format(backDateFormat),
        end: dates_end.format(backDateFormat),
      })
    }
  }

  useEffect(() => {
    dispatch(action(Actions.SET_PERFORMACE_PENALTIES, []))
    dispatch(action(Actions.CLEAN_DOERS, []))
    dispatch(getDoers())
  }, [dispatch])

  useEffect(() => {
    const { city_id, role_id } = filter

    if (city_id && role_id) {
      dispatch(getPenalties({
        city_id,
        role_id,
      }))
    }
  }, [filter?.role_id, filter?.city_id])

  const onFilterChange = (partial: Partial<APITYPES.Performers.GetDoerMotivations.Req>) => {
    history.push(ROUTES.DOERS.MOTIVATIONS.PARAMS.createPath({
      ...filter,
      ...partial,
    }))
    dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
    dispatch(action(Actions.SET_DOER_MOTIVATIONS_FILTER, {
      ...filter,
      ...partial,
    }))
    dispatch(getDoerMotivations({
      ...filter,
      ...partial,
    }))
  }

  const onFilterReset = () => {
    dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
    dispatch(action(Actions.SET_DOER_MOTIVATIONS_FILTER, {}))
    history.push(ROUTES.DOERS.MOTIVATIONS.path)
    dispatch(getDoerMotivations())
    dispatch(getDoers())
    setUser(null)
  }

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Период'>
          <DatePicker.RangePicker
            format={dateFormat}
            //@ts-ignore
            value={dates}
            onChange={onDateChange}
          />
        </Form.Item>

        <Form.Item
          label='Исполнитель'
        >
          <DebounceSelect
            allowClear
            value={user}
            placeholder='Выберите исполнителя'
            fetchOptions={fetchUserList}
            defaultOptions={userValue}
            onChange={
              (newValue) => {
                setUser(newValue as SelectValue)
                onFilterChange({ doer_user_id: (newValue as SelectValue)?.value })
              }
            }
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label='Тип'>
          <Select
            placeholder='Выберите тип'
            allowClear
            value={filter?.type}
            onChange={(value) => onFilterChange({ type: value })}
          >
            {
              types.map((type) => (
                <Select.Option
                  key={type.value}
                  value={type.value}
                >
                  { type.text }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Статус'>
          <Select
            placeholder='Выберите статус'
            allowClear
            value={filter?.state}
            onChange={(value) => onFilterChange({ state: value })}
          >
            {
              states?.map((status) => (
                <Select.Option
                  key={status.value}
                  value={status.value}
                >
                  { status.text }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Город'>
          <Select
            placeholder='Выберите город'
            value={filter?.city_id}
            allowClear
            onClear={
              () => {
                // role_penalty_id should only be selected if role_id & city_id are picked
                if ('role_penalty_id' in filter) {
                  filter.role_penalty_id = undefined
                }
              }
            }
            onChange={(value) => onFilterChange({ city_id: value })}
          >
            {
              cities.map((city) => (
                <Select.Option
                  key={city.value}
                  value={city.value}
                >
                  { city.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Роль'>
          <Select
            placeholder='Выберите роль'
            value={filter?.role_id}
            allowClear
            onClear={
              () => {
                // Penalty(role_penalty_id) should only be selected if Role(role_id) & City(city_id) are picked
                if ('role_penalty_id' in filter) {
                  filter.role_penalty_id = undefined
                }
              }
            }
            onChange={
              (value) => {
                onFilterChange({ role_id: value })
              }
            }
          >
            {
              roles.map((role) => (
                <Select.Option
                  value={role.id}
                  key={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Штраф (выберите город и роль)'>
          <Select
            disabled={!(filter?.role_id && filter?.city_id)}
            placeholder='Выберите штраф'
            allowClear
            value={filter?.role_penalty_id}
            onChange={(value) => onFilterChange({ role_penalty_id: value })}
          >
            {
              penalties?.map((penalty) => (
                <Select.Option
                  key={penalty.id}
                  value={penalty.id}
                >
                  { penalty.title }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Button onClick={onFilterReset}>Сбросить фильтры</Button>
      </Form>
    </Drawer>
  )
}
