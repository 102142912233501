import React, { useCallback, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { editProduct } from 'AC/products/products'
import { getTableYScroll } from 'utils/scrollUtils'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'

import { Filters } from './Filters'
import { AddProductModal } from './AddProductModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  products: state.productsReducer.products,
  productGroups: state.productsReducer.productGroups,
  isLoading: state.requestsReducer.fetchProducts === StatusRequest.LOADING
    || state.requestsReducer.addProduct === StatusRequest.LOADING,
  filter: state.productsReducer.productsFilter,
})

export const Products = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { products, isLoading, filter } = useSelector(selector)

  const [filterOpen, setFilterOpen] = useState(false)
  const toggleFilter = useCallback(() => setFilterOpen((prev) => !prev),[])

  const [addOpen, setAddOpen] = useState(false)
  const toggleAddModal = () => setAddOpen((prev) => !prev)

  const activeChange = useCallback(async (e: any, id: string, value: boolean) => {
    e.stopPropagation()
    dispatch(editProduct(id, { active: value }, true))
  }, [dispatch])

  return (
    <>
      <div className={styles.toolbar}>
        <Button
          onClick={toggleAddModal}
          type='primary'
        >
          Добавить продукт
        </Button>

        <Button
          size='small'
          onClick={toggleFilter}
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
        />
      </div>

      <Table
        sticky
        bordered
        size='small'
        pagination={false}
        loading={isLoading}
        style={{ cursor: 'pointer' }}
        columns={getColumns(activeChange) as any}
        dataSource={getRows(products || [])}
        scroll={{ y: getTableYScroll(164) }}
        onRow={
          (record) => ({ onClick: () => history.push(ROUTES.PRODUCTS.ID.createPath(record.key)) })
        }
      />

      <Filters
        isOpen={filterOpen}
        onClose={toggleFilter}
      />
      <AddProductModal
        isOpen={addOpen}
        onClose={toggleAddModal}
      />
    </>
  )
}
