// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--marginLeft--tNm-e{margin-left:0px}.styles-module--modal--irKZj{min-width:700px !important}.styles-module--buttonOff--31Lav{margin-left:20px}.styles-module--buttonOn--uDH81{margin-left:20px}.styles-module--buttonsBlock--\\+n9XR{display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module--addButton--3iKpU{margin-left:20px}.styles-module--titleBlock--DuixC{border:1px solid #ebedf0}.styles-module--tagBlok--STrwE{margin-left:20px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--reason--SzAE1{height:22px;margin-left:20px}.styles-module--title--zVv\\+t{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/Card/Partner/styles.module.sass"],"names":[],"mappings":"AAAA,kCACI,eAAA,CAEJ,6BACI,0BAAA,CAEJ,iCACI,gBAAA,CAEJ,gCACI,gBAAA,CAEJ,qCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEJ,iCACI,gBAAA,CAEJ,kCACI,wBAAA,CAEJ,+BACI,gBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,8BACI,WAAA,CACA,gBAAA,CAEJ,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".marginLeft\n    margin-left: 0px\n\n.modal\n    min-width: 700px!important\n\n.buttonOff\n    margin-left: 20px\n\n.buttonOn\n    margin-left: 20px\n\n.buttonsBlock\n    display: flex\n\n.addButton\n    margin-left: 20px\n\n.titleBlock\n    border: 1px solid rgb(235, 237, 240)\n\n.tagBlok\n    margin-left: 20px\n    display: flex\n    align-items: center\n\n.reason\n    height: 22px\n    margin-left: 20px\n\n.title\n    display: flex\n    align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginLeft": "styles-module--marginLeft--tNm-e",
	"modal": "styles-module--modal--irKZj",
	"buttonOff": "styles-module--buttonOff--31Lav",
	"buttonOn": "styles-module--buttonOn--uDH81",
	"buttonsBlock": "styles-module--buttonsBlock--+n9XR",
	"addButton": "styles-module--addButton--3iKpU",
	"titleBlock": "styles-module--titleBlock--DuixC",
	"tagBlok": "styles-module--tagBlok--STrwE",
	"reason": "styles-module--reason--SzAE1",
	"title": "styles-module--title--zVv+t"
};
export default ___CSS_LOADER_EXPORT___;
