import React, { useState } from 'react'
import { Steps, Button, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Moment } from 'moment'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { StatisticsTable } from '../StatisticsTable'
import { GetStatistics } from '../GetStatistics'
import { сommitShifts } from 'AC/shifts/shifts'
import { addOperationsStatistics } from 'AC/statistics'

import { exportToExcel, formatDates } from './utils'
import styles from './styles.module.sass'


type Props = {
  onClose: () => void
};

type DateRange = [Moment, Moment];

export const Wizard = ({ onClose }: Props) => {
  const [current, setCurrent] = useState<number>(0)
  const { operations, isCommitShiftLoading } = useSelector((state: State) => ({
    operations: state.statisticsReducer.operations,
    isLoading: state.requestsReducer.addOperationsStatistics === StatusRequest.LOADING,
    isCommitShiftLoading: state.requestsReducer.commitShifts === StatusRequest.LOADING,
  }))
  const [dates, setDates] = useState<DateRange | undefined>(undefined)
  const [rolesId, setRolesId] = useState<string[]>([])
  const dispatch = useDispatch()

  const steps = [
    {
      title: 'Получить статистику',
      content: (
        <GetStatistics
          dates={dates}
          setDates={setDates}
          setRolesId={setRolesId}
        />
      ),
    },
    {
      title: 'Фиксировать смены',
      content: <StatisticsTable />,
    },
  ]

  const dispatchAddOperationsStatistics = (dates: Moment[]) => {
    const formattedDates = formatDates(dates)

    if (formattedDates && rolesId) {
      const [formattedBegin, formattedEnd] = formattedDates
      const body = {
        begin: formattedBegin,
        end: formattedEnd,
        role_ids: rolesId,
      }

      dispatch(addOperationsStatistics(body))
    }
  }

  const next = () => {
    if (dates && rolesId) {
      setCurrent(current + 1)
      dispatchAddOperationsStatistics(dates)
    }
  }

  const onError = () => {
    setCurrent(0)
  }

  const downloadFile = async () => {
    if (dates) {
      exportToExcel(operations, dates, 'Смены за ')
    }
  }

  const onFinish = async () => {
    if (operations && dates) {
      const formattedDates = formatDates(dates)

      if (formattedDates) {
        const [formattedBegin, formattedEnd] = formattedDates
        const body = {
          begin: formattedBegin,
          end: formattedEnd,
          role_ids: rolesId,
        }

        await dispatch(сommitShifts(body, onClose, onError))
      }
    }
  }

  return (
    <>
      <Steps
        current={current}
        items={steps}
      />
      <div className={styles.steps_content}>{ steps[current].content }</div>
      <div className={styles.steps_action}>
        {
          current < steps.length - 1 && (
            <Button
              disabled={!rolesId.length || !dates}
              type='primary'
              onClick={next}
            >
              Далее
            </Button>
          )
        }
        {
          current === steps.length - 1 && (
            <Space>
              <Button
                type='primary'
                onClick={downloadFile}
              >
                Скачать
              </Button>
              <Button
                type='primary'
                onClick={onFinish}
                loading={isCommitShiftLoading}
              >
                Фиксировать
              </Button>
            </Space>
          )
        }
      </div>
    </>
  )
}
