import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from 'antd'

import { State } from 'stores/rootReducer'
import { deployYandexLavkaReportById } from 'AC/yandexLavka/reports'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'

import styles from './styles.module.sass'


interface FinalStepProps {
  reportId: string
  onFinish: () => void
}

const selector = (state: State) => ({ isLoading:  state.requestsReducer.deployYandexLavkaReport === StatusRequest.LOADING })

export const FinalStep: FC<FinalStepProps> = ({ onFinish, reportId }) => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(selector)

  const onClickDeploy = () => {
    dispatch(deployYandexLavkaReportById(reportId, onFinish))
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        {
          isLoading ? <Loader /> : (
            <>
              <Typography.Text >
                Все данные успешно загружены
              </Typography.Text>
              <Typography.Paragraph >
                Пожалуйста, нажмите на кнопку
                <strong>"Обработать"</strong>
                для завершения процесса
              </Typography.Paragraph>
            </>
          )
        }

      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onClickDeploy}
          type='primary'
        >
          Обработать
        </Button>
      </div>
    </div>
  )
}
