import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'
import { useHistory } from 'react-router'
import qs from 'qs'

import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { getBillsDoers } from 'AC/billing/getBills'
import { ROUTES } from 'config/routes'
import { isObjectEmpty } from 'utils/filterUtils'
import { navigate } from 'utils/tabelUtils'
import { Loader } from 'components/Loader'
import { InfiniteTable } from 'components/InfiniteTable'
import { CreateCard } from '../Bills/CreatedCard'
import { BillsImportCard } from '../Bills/BillsImport'
import { FilterIcon } from 'components/FilterIcon'
import { APITYPES } from '../../../../types/apitypes'

import { Filters } from './Filters'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'
import { ExportBills } from './Export'
import { filterEmptyValues } from '../../../../utils/urlUtils'
import ContractorType = APITYPES.ContractorType


const selector = (state: State) => {
  const { billsDoers, billsDoersHasMore, billsDoersFilter } = state.billingReducer
  const { getBillsDoers, createBill } = state.requestsReducer

  return {
    bills: billsDoers,
    filter: billsDoersFilter,
    billsHasMore: billsDoersHasMore,
    isLoading: getBillsDoers === StatusRequest.LOADING,
    isAddingLoading: createBill === StatusRequest.LOADING,
  }
}

export const BillingDoers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const { bills, billsHasMore: hasMore, isLoading, filter, isAddingLoading } = useSelector(selector)

  /** modals state */
  const [isCreate, setIsCreate] = useState(false)
  const [isImportBills, setIsImportBills] = useState(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [exportBillsOpen, setExportBillsOpen] = useState<boolean>(false)
  const toggleExportBills = () => setExportBillsOpen((prev) => !prev)
  const toggleFilter = () => setFilterOpen((prev) => !prev)

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = filterEmptyValues(qs.parse(params))

      dispatch(action(Actions.SET_BILLS_FILTER_DOERS, {
        ...parsed,
        state: parsed?.state ? (parsed.state as string).split(',') : undefined,
      }))

      return
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.BILLING.DOERS.PARAMS.createPath(filter))
    }
  }, [dispatch])

  const loadMore = () => {
    if (isLoading) {return}
    const parsed = filterEmptyValues(qs.parse(params))

    dispatch(getBillsDoers({
      ...parsed,
      state: parsed?.state ? (parsed.state as string).split(',') : undefined,
    }, ContractorType.doer))
  }

  if (!bills) {return <Loader />}

  return (
    <div className={styles.main}>
      <div className={styles.toolbar}>
        <Button
          type='primary'
          loading={isAddingLoading}
          onClick={() => setIsCreate(true)}
        >
          Сформировать счет
        </Button>

        <div className={styles.buttonsContainer}>
          <Button
            type='primary'
            loading={isAddingLoading}
            onClick={toggleExportBills}
          >
            Экспорт счетов
          </Button>
          <Button
            type='primary'
            loading={isAddingLoading}
            onClick={() => setIsImportBills(true)}
          >
            Импорт позиций
          </Button>

          <Button
            onClick={toggleFilter}
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          />
        </div>
      </div>

      <InfiniteTable
        columns={getColumns()}
        isLoading={isLoading}
        hasMore={hasMore}
        className={styles.tableContainer}
        loadMore={loadMore}
      >
        <Table
          showHeader={false}
          columns={getColumns() as any}
          className={styles.table}
          dataSource={getRows(bills)}
          bordered
          size='large'
          scroll={{ x: '100%' }}
          pagination={false}
          onRow={(record: any) => ({ onClick: navigate(ROUTES.BILLING.DOERS.ID.createPath(record.key), history) })}
        />
      </InfiniteTable>

      <CreateCard
        contractor_type={ContractorType.doer}
        isVisible={isCreate}
        onCancel={() => setIsCreate(false)}
      />

      <BillsImportCard
        isVisible={isImportBills}
        onCancel={() => setIsImportBills(false)}
      />

      <ExportBills
        isVisible={exportBillsOpen}
        onCancel={toggleExportBills}
      />
      <Filters
        isOpen={filterOpen}
        onClose={toggleFilter}
      />
    </div>
  )
}