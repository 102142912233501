import React from 'react'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { updateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { getRentalObjectDynamicFields } from '../../utils'

import styles from './styles.module.sass'

import RentalObjectType = APITYPES.RentalObjectType


type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
  rentalObjectType: RentalObjectType
}

export const Properties = (props: Props) => {
  const dispatch = useDispatch()

  const {
    carwash,
    rentalObjectType,
  } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onFinish = async (values: any) => {
    dispatch(updateCarwash({ dynamic_data: values }, carwash.id))
  }

  const [form] = useForm()

  const onBlur = () => {
    onFinish(form.getFieldsValue())
  }

  return (
    <Form
      className={styles.form}
      name='time_related_controls'
      {...formItemLayout}
      onFinish={onFinish}
      onBlur={onBlur}
      form={form}
      initialValues={carwash.dynamic_data}
    >
      { getRentalObjectDynamicFields(rentalObjectType) }
    </Form>
  )
}