import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { exportDoerRecords } from 'AC/billing/doerRecords'
import { APITYPES } from 'types/apitypes'
import { State } from '../../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../../stores/requestsReducer'


type Props = {
  filter: APITYPES.Billing.GetDoerRecords.Req
}

const selector = (state: State) => ({
  isAggregatedLoading: state.requestsReducer.exportAggregatedDoerRecords === StatusRequest.LOADING,
  isConsoleLoading: state.requestsReducer.exportConsoleDoerRecords === StatusRequest.LOADING,
})

export const ExportButtons = ({ filter }: Props) => {
  const dispatch = useDispatch()
  const { isAggregatedLoading, isConsoleLoading } = useSelector(selector)

  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)

  const onAggregatedExport = () => {
    dispatch(exportDoerRecords(filter, {
      option: 'aggr',
      onFinish: onClose,
    }))
  }

  const onConsoleExport = () => {
    dispatch(exportDoerRecords(filter, { onFinish: onClose }))
  }

  return (
    <>
      <Button
        type='primary'
        size='small'
        onClick={() => setIsOpen(true)}
      >
        Экспорт
      </Button>

      <Modal
        title='Экспорт'
        open={isOpen}
        destroyOnClose
        footer={false}
        width={400}
        onCancel={onClose}
      >
        <Col>
          <Row gutter={18}>
            <Button
              type='primary'
              size='middle'
              onClick={onAggregatedExport}
              block
              style={{ marginBottom: 12 }}
              loading={isAggregatedLoading}
            >
              Агрегированный
            </Button>
          </Row>

          <Row gutter={18}>
            <Button
              type='primary'
              size='middle'
              onClick={onConsoleExport}
              block
              loading={isConsoleLoading}
            >
              Детальный
            </Button>
          </Row>
        </Col>
      </Modal>
    </>
  )
}
