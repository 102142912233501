// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--toolbar--qrMGF{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:12px}.styles-module--col--NQzlV{height:100%;gap:14px;display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module--addBtn--QeX3Q{margin-bottom:16px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Motivations/Toolbar/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAEF,2BACE,WAAA,CACA,QAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEF,8BACE,kBAAA","sourcesContent":[".toolbar\n  display: flex\n  width: 100%\n  justify-content: space-between\n  align-items: center\n  margin-bottom: 12px\n\n.col\n  height: 100%\n  gap: 14px\n  display: flex\n\n.addBtn\n  margin-bottom: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "styles-module--toolbar--qrMGF",
	"col": "styles-module--col--NQzlV",
	"addBtn": "styles-module--addBtn--QeX3Q"
};
export default ___CSS_LOADER_EXPORT___;
