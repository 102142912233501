import { action, Actions, ThAction } from 'actions'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const changeCar = (user_id: string, car_id: string, values: APITYPES.Users.ChangeCar.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('changeCar', StatusRequest.LOADING))
      
    try {
      const resp = await api.put(APITYPES.Users.ChangeCar.URL, values, {
        replaceUrl: {
          user_id,
          car_id,
        },
      })
    
      if (resp) {
        dispatch(action(Actions.SET_USER, resp))

        openNotification('Сохранено')
    
        dispatch(changeRequestState('changeCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeCar', StatusRequest.ERROR))
  
      throw(e)
    }
  }
}