import { columns } from './columns'


type GroupTitle = {
  groupTitle: string
  length: number
}

type GroupTotal = {
  title: string
  total: number
}

// TODO: needs refactoring
export const getGroupRows = (groupTitles: GroupTitle[], groupTotal: GroupTotal[]) => {
  const data = groupTitles.map((title) => {
    const row: any = {}

    columns.map((column) => {
      if (title.groupTitle === 'others' && column.key === 'name') {
        row[column.dataIndex] = 'Прочее'
      } else if (column.key === 'name') {
        //@ts-ignore
        row[column.dataIndex] = title.groupTitle
      } else if (column.key === 'value') {
        row[column.dataIndex] = groupTotal.find((item) => item.title === title.groupTitle)?.total
      } else if (column.key === 'amount') {
        row[column.dataIndex] = title.length
      }
      
      return
    })

    row.key = title.groupTitle

    return row
  })

  return data
}