import React from 'react'
import { APITYPES } from 'types/apitypes'
import styles from './styles.module.sass'

const Locations = APITYPES.ShiftLocationsTypes

type Props = {
  locationType: APITYPES.ShiftLocationsTypesOptions | null
}

export const OnlyForDistrict = ({locationType}: Props) => {
  if(!locationType) return null

  return (
    <div className={styles.container}>
      {`Локации типа "${Locations[locationType]}" не могут иметь дочерние элементы`}
    </div>
  );
};
