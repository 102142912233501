// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--tabs--Q3Cty{margin-top:20px}.styles-module--titleBlock--1klcS{border:1px solid #ebedf0;position:sticky;top:65px;z-index:10;background:#fff}.styles-module--main--oVvJj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex:1 1 100%;flex:1 1 100%}.styles-module--title--esjUu{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--tag--mL3r7{margin-left:20px}.styles-module--positions--KFwrR{padding:40px 0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/Bill/styles.module.sass"],"names":[],"mappings":"AAAA,4BACI,eAAA,CAEJ,kCACI,wBAAA,CACA,eAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CAEJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,kBAAA,CAAA,iBAAA,CAAA,aAAA,CAEJ,6BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,2BACI,gBAAA,CAEJ,iCACI,cAAA","sourcesContent":[".tabs\n    margin-top: 20px\n\n.titleBlock\n    border: 1px solid rgb(235, 237, 240)\n    position: sticky\n    top: 65px\n    z-index: 10\n    background: white\n\n.main\n    display: flex\n    flex-direction: column\n    flex: 1 1 100%\n\n.title\n    display: flex\n    align-items: center\n\n.tag\n    margin-left: 20px\n\n.positions\n    padding: 40px 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "styles-module--tabs--Q3Cty",
	"titleBlock": "styles-module--titleBlock--1klcS",
	"main": "styles-module--main--oVvJj",
	"title": "styles-module--title--esjUu",
	"tag": "styles-module--tag--mL3r7",
	"positions": "styles-module--positions--KFwrR"
};
export default ___CSS_LOADER_EXPORT___;
