import React from 'react'
import { APITYPES } from 'types/apitypes'

import { ProductOptions } from './ProductOptions'
import { ProductServices } from './ProductServices'
import styles from './styles.module.sass'

const {ProductTypeEnum} = APITYPES

type Props = {
  product: APITYPES.Product
}

export const ProductServicesOptions = ({product}: Props) => {
  return (
    <div className={styles.container}>
      <ProductOptions productId={product.id} options={product.options} disabled={product.type === ProductTypeEnum.option}/>
      <ProductServices productId={product.id} services={product.services} />
    </div>
  );
};
