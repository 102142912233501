import { Action, Actions } from '../actions'
import { APITYPES } from '../types/apitypes'

import Dictionary = APITYPES.Dictionary.Dictionary


export type DictionaryState = {
  signers: Dictionary[]
}

const initialState: DictionaryState = { signers: [] }

export const dictionaryReducer = (state = initialState, action: Action): DictionaryState => {
  switch (action.type) {
    case Actions.SET_SIGNER_DICTIONARY: {
      return {
        ...state,
        signers: action.data,
      }
    }
    default: return state
  }
}