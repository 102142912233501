import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getDoerShifts } from 'AC/doers/getDoers'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { getTableYScroll } from 'utils/scrollUtils'
import { getColumns, getRows } from './utils'

const backDate = 'YYYY-MM-DD'

type Props = {
  doer: APITYPES.Doer
}

const selector = (state: State) => ({
  doerShifts: state.doersReducer.doerShifts,
  hasMoreShifts: state.doersReducer.hasMoreDoerShifts,
  isLoading: state.requestsReducer.getDoerShifts === StatusRequest.LOADING,
})

export const Shifts = ({doer}: Props) => {
  const dispatch = useDispatch()
  const {doerShifts, isLoading, hasMoreShifts} = useSelector(selector)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  /** filter state */
  const [filter, setFilter] = useState<APITYPES.Doers.GetShifts.Params>({
    date_from: undefined,
    date_to: undefined,
    state: undefined,
  })

  useEffect(() => {
    if (!isLoading && hasMoreShifts && entry?.isIntersecting) {
      dispatch(getDoerShifts(doer.id, filter))
    }
  }, [entry?.isIntersecting])

  useEffect(() => {
    dispatch(action(Actions.CLEAR_DOER_SHIFTS, {}))
    dispatch(getDoerShifts(doer.id, filter))
  }, [filter])

  const onTableChange = (_: any, tableFilters: any) => {
    setFilter({
      state: tableFilters?.state ? tableFilters.state : undefined,
      date_from: tableFilters?.dates ? tableFilters.dates[0].format(backDate) : undefined,
      date_to: tableFilters?.dates ? tableFilters.dates[1].format(backDate) : undefined,
    })
  }

  return (
    <Table
      sticky
      bordered
      size='small'
      loading={isLoading}
      columns={getColumns() as any}
      dataSource={getRows(doerShifts)}
      pagination={false}
      scroll={{y: getTableYScroll(265)}}
      onChange={onTableChange}
      onRow={(_, index) => ({
        ref: index === doerShifts.length - 1 ? lastRowRef : undefined,
        onClick: undefined,
      })}
    />
  );
};
