// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--title--ctB--{border:1px solid #ebedf0}.styles-module--btn--XQ2wn{margin-left:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/ClientTypes/ClientType/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,wBAAA,CAEJ,2BACI,gBAAA","sourcesContent":[".title\n    border: 1px solid rgb(235, 237, 240)\n\n.btn\n    margin-left: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles-module--title--ctB--",
	"btn": "styles-module--btn--XQ2wn"
};
export default ___CSS_LOADER_EXPORT___;
