import { Button, Input, Select } from 'antd'
import React, { useState } from 'react'

import { DeleteButton } from 'components/DeleteButton'
import { ContactsType } from '../utils'

import styles from './styles.module.sass'


type Props = {
  phones: string[]
  emails: string[]
  setPhones: (value: string[] | ((value: string[])=>string[])) => void
  setEmails: (value: string[] | ((value: string[])=>string[])) => void
}

export const Contacts = (props: Props) => {
  const { phones, emails, setPhones, setEmails } = props

  const [newPhone, setNewPhone] = useState('')

  const [newEmail, setNewEmail] = useState('')

  const [contactType, setContactType] = useState(ContactsType.PHONES)
  
  return (
    <>
      <Select
        value={contactType}
        style={{ width: 120 }}
        onChange={(value)=>setContactType(value)}
      >
        <Select.Option value={ContactsType.PHONES}>Телефон</Select.Option>
        <Select.Option value={ContactsType.EMAILS}>Почта</Select.Option>
      </Select>
      {
        contactType === ContactsType.PHONES && (
          <div>
            <div className={styles.addBlock}>
              <Input
                value={newPhone}
                onChange={(e: any)=>setNewPhone(e.target.value)}
                type='number'
              />
              <Button
                className={styles.addBtn}
                onClick={
                  ()=>{
                    if (newPhone.trim().length) {
                      setPhones((value)=>[newPhone, ...value])
                      setNewPhone('')
                    }
                  }
                }
                disabled={!newPhone}
              >
                Добавить телефон
              </Button>
            </div>
            {
              phones.map((item)=>{
                return (
                  <div
                    className={styles.contact}
                    key={item}
                  >
                    { item }
                    <DeleteButton
                      onClick={
                        ()=>{
                          setPhones((value)=>value.filter((p)=>p !== item))
                        }
                      }
                    />
                  </div>
                )
              })
            }
          </div>
        )
      }
      {
        contactType === ContactsType.EMAILS && (
          <div>
            <div className={styles.addBlock}>
              <Input
                value={newEmail}
                onChange={(e: any)=>setNewEmail(e.target.value)}
              />
              <Button
                className={styles.addBtn}
                onClick={
                  ()=>{
                    if (newEmail.trim().length) {
                      setEmails((value)=>[newEmail, ...value])
                      setNewEmail('')
                    }
                  }
                }
                disabled={!newEmail}
              >
                Добавить почту
              </Button>
            </div>
            {
              emails.map((item)=>{
                return (
                  <div
                    className={styles.contact}
                    key={item}
                  >
                    { item }
                    <DeleteButton
                      onClick={
                        ()=>{
                          setEmails((value)=>value.filter((p)=>p !== item))
                        }
                      }
                    />
                  </div>
                )
              })
            }
          </div>
        )
      }
    </>
  )
}