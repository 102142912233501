import React, { useEffect, useState } from 'react'
import { Form, Input, PageHeader, Space } from 'antd'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { EditOutlined } from '@ant-design/icons'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchOperation } from 'AC/shifts/operations'
import { Loader } from 'components/Loader'
import { GeneralBlock } from './OperationCard/CardContent'
import { getCardData } from './OperationCard/utils'
import { useEditForm } from './OperationCard/CardContent/hooks/useEditForm'
import { ROUTES } from '../../../../../config/routes'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'

import styles from './styles.module.sass'
import { OperationCard } from './OperationCard'
import { OperationsTree } from './OperationsTree'


const selector = (state: State) => ({
  tasks: state.operationsReducer.operationTasks,
  parent: state.operationsReducer.parentOperation,
  isLoading: state.requestsReducer.fetchOperation === StatusRequest.LOADING,
})

export const Operation = () => {
  const dispatch = useDispatch()
  const { parent,  isLoading } = useSelector(selector)
  const { operationId } = useParams<{ operationId: string }>()
  const history = useHistory()
  const timezone = useGetTimezoneFromLocalstorage()

  // fetch parent operation
  useEffect(() => {
    dispatch(action(Actions.SET_OPERATION_FILES, []))

    if (!operationId) {return}
    dispatch(fetchOperation(operationId, 'parent'))

    return () => {
      batch(() => {
        dispatch(action(Actions.CLEAR_OPERATION, {}))
        dispatch(action(Actions.CLEAR_OPERATION_TASKS, undefined))
      })
    }
  }, [])

  const [generalProp, setGeneralProp] = useState<any>()
  const {
    grzRef,
    editForm,
    editGrzMode,
    onGrzChange,
    onGrzFinish,
    onGrzEditClick,
  } = useEditForm(generalProp)

  useEffect(() => {
    if (parent && parent.shift !== undefined) {
      setGeneralProp(getCardData(parent).general)
    }
  }, [parent, parent?.grz])

  const date = moment(parent?.time).format('DD.MM.YYYY')
  const time = moment(parent?.time).format('HH:mm:ss Z')
        
  const shift = formatDateTime({
    start: time,
    targetTimezone: (parent?.timezone ? parent.timezone : timezone)
  })
  
  return (
    <div>
      <PageHeader
        className={styles.header}
        onBack={history.length > 1 ? history.goBack : () => history.replace(ROUTES.DOERS.OPERATIONS.path)}
        title={
          !isLoading && (
            <span style={{ fontWeight: 400 }}>
              <Space size={8}>
                { parent?.title }
                { ' ' }
                {
                  !editGrzMode
                    ? (
                      <div style={{ display: 'inline-block' }}>
                        <Space size={4}>

                          <span>
                            {
                              generalProp?.grz
                                ? (
                                  <span style={
                                    {
                                      fontSize: '20px',
                                      fontWeight: 400,
                                      textAlign: 'center',
                                    }
                                  }
                                  >
                                    { generalProp?.grz }
                                  </span>
                                ) :
                                '-'
                            }
                          </span>
                          <EditOutlined
                            className={styles.icon}
                            onClick={onGrzEditClick}
                            style={{ fontSize: '17px' }}
                          />
                        </Space>
                      </div>
                    ) : (
                      <Form
                        form={editForm}
                        onFinish={onGrzFinish}
                      >
                        <Form.Item
                          noStyle
                          name='grz'
                          className={styles.input}
                          initialValue={generalProp?.grz}
                        >
                          <Input
                            ref={grzRef}
                            className={styles.input}
                            onBlur={onGrzChange}
                          />
                        </Form.Item>
                      </Form>
                    )
                }
              </Space>
            </span>
          )
        }
        subTitle={parent && (parent?.time ?
            <span style={
              { textAlign: 'center',
                color: "black"}
            }
            >{date} {shift}</span> : null)}
        extra={
          isLoading ? (
            <div className={styles.loadWrapper}>
              <Loader
                noPadding
                circleIcon
              />
            </div>
          ) : (
            <Space size={40}>

              {
                parent ? (
                  <span style={
                    {
                      fontSize: '20px',
                      textAlign: 'center',
                    }
                  }
                  >
                    { parent.doer_name }
                  </span>
                ) : null
              }
            </Space>
          )
        }
      >

        {
          parent && (parent.shift === undefined
            ? null : (
              <GeneralBlock
                general={getCardData(parent).general}
                shift={getCardData(parent).shift}
              />
            ))
        }

      </PageHeader>
      <div className={styles.content}>

        <OperationsTree />
        <OperationCard />
      </div>
    </div>
  )
}
