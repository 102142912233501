import React from 'react'
import { Modal, Form, Input, DatePicker, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { createDocuments } from 'AC/partners/createDocument'

import styles from './styles.module.sass'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

const { useForm } = Form

const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD-MM-YYYY'


export const CreateDocument = (props: Props) => {
  const { isVisible, onCancel } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const [ form ] = useForm()

  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    dispatch(createDocuments({
      ...values,
      date: values.date ? values.date.format(dateFormat) : undefined,
    }))

    onCancel()
  }

  return (
    <Modal
      className={styles.modal}
      title='Создание документа'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='edit_document'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='type'
          label='Тип договора'
          initialValue='Договор аренды оборудования'
        >
          <Select showSearch>
            <Select.Option value='Договор аренды оборудования'>Договор аренды оборудования</Select.Option>
            <Select.Option value='Договор возмездного оказания услуг'>
              Договор возмездного оказания услуг
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='number'
          label='№ договора'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Дата заключения договора'
          name='date'
        >
          <DatePicker format={viewDateFormat} />
        </Form.Item>
        <Form.Item
          name='link'
          label='Ссылка на договор'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='payment_type'
          label='Порядок оплаты'
          initialValue='Постоплата'
        >
          <Select showSearch>
            <Select.Option value='Постоплата'>Постоплата</Select.Option>
            <Select.Option value='Предоплата'>Предоплата</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='act_frequency'
          label='Периодичность актирования'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='payment_delay_period'
          label='Период отсрочки платежа'
        >
          <Input />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          position='right'
        />
      </Form>
    </Modal>
  )
}