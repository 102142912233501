import React, { FC, useEffect, useState } from 'react'
import { Button, Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getTableYScroll } from 'utils/scrollUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { useDebounce } from 'utils/debounce'
import { action, Actions } from 'actions'
import { getDoers } from 'AC/doers/getDoers'
import { DoerStateTranslation } from '../../../../../../Doers/utils'
import { connectUrentUnmatchedExecutors } from 'AC/urent/reports'


type Executor = APITYPES.Urent.UnmatchedExecutor;

const selector = (state: State) => ({
  doers: state.doersReducer.doers ,
  filter: state.doersReducer.doersFilter,
})


interface Props {
  unmatchedExecutors: Executor[]
}

export const UnmatchedExecutorsTable: FC<Props> = ({ unmatchedExecutors }) => {
  const {  doers, filter } = useSelector(selector)
  const [executors, setExecutors] = useState<Executor[]>(unmatchedExecutors)
  const dispatch = useDispatch()
  const [debState, setDebState] = useState<string>(filter.name ?? '')
  const debouncedName = useDebounce(debState, 500)


  const handleSelectChange = (name: string, executor: Executor) => {
    const doer = doers?.find((doer: APITYPES.Doer) => doer.name === name)

    const updatedRecord: Executor = {
      ...executor,
      doer_name: doer?.name ?? '',
      doer_phone: doer?.phone_number ?? '',
      doer_user_id: doer?.id ?? '',
    }
    const updatedReport = executors.map((item: Executor) => {
      return item.urent_executor_id === updatedRecord.urent_executor_id
        ? updatedRecord
        : item
    })

    setExecutors(updatedReport)
  }


  const onSearchChange = (newFilter: APITYPES.DoersFilterType) => {
    dispatch(action(Actions.CLEAN_DOERS, {}))
    dispatch(
      action(Actions.SET_DOERS_FILTER, {
        ...filter,
        ...newFilter,
      })
    )
    dispatch(getDoers())
  }


  useEffect(() => {
    onSearchChange({ name: debouncedName ?? undefined })
  }, [debouncedName])

  useEffect(() => {
    setExecutors(unmatchedExecutors)
  }, [unmatchedExecutors])


  const expandedRowRender = (executor: APITYPES.Urent.UnmatchedExecutor) => {
    return (
      <Select
        disabled={executor.isMatched}
        showSearch
        allowClear
        defaultValue={executor.doer_name ? executor.doer_name : ''}
        style={{ width: '100%' }}
        onChange={
          (name) => {
            handleSelectChange(name, executor)
          }
        }
        onSearch={setDebState}
        onClear={() => handleSelectChange('', executor)}
      >
        {
          doers?.map((doer: APITYPES.Doer) => (
            <Select.Option
              key={doer.id}
              value={doer.name}
            >
              { /*@ts-ignore*/ }
              { `${doer.name}, ${doer.phone_number}, ${DoerStateTranslation[doer.state.name]}` }
              { `${doer.roles ? ', ' + doer.roles.map((r) => r.name).join(' ') : ''}` }
            </Select.Option>
          ))
        }
      </Select>
    )
  }


  return (
    <Table
      bordered
      sticky
      pagination={false}
      size='large'
      style={{ cursor: 'pointer' }}
      scroll={{ y: getTableYScroll(500) }}
      columns={
        [
          {
            title: 'Из Urent',
            children: [
              {
                title: 'ФИО',
                dataIndex: 'urent_executor_name',
                key: 'urent_executor_id',
                align: 'left',
              },
              {
                title: 'Яндекс Лавка ID',
                dataIndex: 'urent_executor_id',
                key: 'urent_executor_id',
                align: 'left',
                width: '20%',
              },
            ],
          },
          {
            title: 'Из Базы Данных',
            children: [
              {
                title: 'ФИО',
                dataIndex: 'doer_name',
                key: 'doer_name',
                align: 'left',
                width: '20%',
              },
              {
                title: 'Телефон',
                dataIndex: 'doer_phone',
                key: 'doer_phone',
                align: 'left',
              },
            ],
          },
          {
            title: 'Связать данные',
            dataIndex: 'связать',
            key: 'x',
            align: 'center',
            render: (_: any, executor: Executor) => {
              return (
                <Button
                  disabled={!executor.doer_user_id || executor.isMatched}
                  type='primary'
                  key={executor.urent_executor_id}
                  onClick={
                    () => {
                      if (executor.doer_user_id) {
                        dispatch(connectUrentUnmatchedExecutors(executor.urent_executor_id, executor.doer_user_id))
                      }
                    }
                  }
                >
                  { executor.isMatched ? 'Связано' : 'Связать' }
                </Button>
              )
            },
          },
        ]
      }
      dataSource={
        executors.map((item) => ({
          ...item,
          key: item.urent_executor_id,
        }))
      }
      expandable={{ expandedRowRender }}
    />
  )
}
