import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { fetchRoles } from 'AC/doers/doerRoles'
import { fetchDoerTariffFunctions, fetchDoerTariffs } from 'AC/doers/doerTariffs'
import { isObjectEmpty } from 'utils/filterUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { getEnumOptions } from 'utils/enumUtils'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { fetchEmployers } from '../../../../../AC/shifts/shifts'

import { getInitDatesState } from './utils'


const { Option } = Select
const cities = getEnumOptions(APITYPES.Cities)

type Props = {
  isOpen: boolean
  onClose: () => void
}
type M = moment.Moment
const selector = (state: State) => ({
  filter: state.doerTariffsReducer.doerTariffsFilter,
  roles: state.doersReducer.roles,
  employers: state.shiftsReducer.employers,
  functions: state.doerTariffsReducer.doerTariffFunctions,
  tariffGroups: state.doerTariffsReducer.doerTariffsGroups,
})

const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'

const filterFunctionTypes = [
  {
    label: 'Операция',
    value: 'task',
  },
  {
    label: 'Смена',
    value: 'shift_type',
  },
]

export const Filters = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    filter,
    roles,
    employers,
    functions,
    tariffGroups,
  } = useSelector(selector)
  const params = getUrlSearchParams()
  const [dates, setDates] =
  useState<M[] | undefined>(getInitDatesState(params?.begin ?? filter?.begin, params?.end ?? filter?.end))

  const [search, setSearch] = useDebouncedSearch(
    {
      defaultValue: params?.search ? params.search : filter?.search,
      skipFirstRun: true,
    },
    () => onFilterChange({ search }),
  )

  useEffect(() => {
    if (!roles) {dispatch(fetchRoles())}
    if (!functions.length) {dispatch(fetchDoerTariffFunctions())}
    if (!employers.length) {dispatch(fetchEmployers())}
  }, [])

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)

      dispatch(action(Actions.SET_DOER_TARIFFS_FILTER, parsed))
      dispatch(fetchDoerTariffs(parsed))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.DOERS.TARIFFS.PARAMS.createPath(filter))
      dispatch(fetchDoerTariffs(filter))
    } else {
      dispatch(fetchDoerTariffs())
    }
  }, [])

  const onFilterChange = (partial: Partial<APITYPES.DoerTariffFilter>) => {
    const newFilter = {
      ...filter,
      ...partial,
    }

    history.push(ROUTES.DOERS.TARIFFS.PARAMS.createPath(newFilter))
    dispatch(action(Actions.SET_DOER_TARIFFS_FILTER, newFilter))
    dispatch(fetchDoerTariffs(newFilter))
  }

  const onFilterReset = () => {
    dispatch(action(Actions.SET_DOER_TARIFFS_FILTER, {}))
    history.push(ROUTES.DOERS.TARIFFS.path)
    if (search && dates || search || dates) {
      setDates(undefined)
      setSearch(undefined)
    } else {
      dispatch(fetchDoerTariffs())
    }
  }

  const onDateChange = (dates: any) => {
    if (!dates) {
      setDates(undefined)
      onFilterChange({
        begin: undefined,
        end: undefined,
      })
    } else {
      setDates(dates)
      const [dates_begin, dates_end] = dates

      onFilterChange({
        begin: dates_begin.format(backFormat),
        end: dates_end.format(backFormat),
      })
    }
  }

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Период'>
          <DatePicker.RangePicker
            format={dateFormat}
            //@ts-ignore
            value={dates}
            onChange={onDateChange}
          />
        </Form.Item>
        <Form.Item label='Название'>
          <Input
            value={search}
            placeholder='Введите название'
            defaultValue={search}
            onChange={
              (e) =>
                setSearch(e.target.value ? e.target.value : undefined)
            }
          />
        </Form.Item>

        <Form.Item label='Город'>
          <Select
            allowClear
            placeholder='Выберите город'
            value={filter?.city_id ? +filter.city_id : undefined}
            onChange={(value) => onFilterChange({ city_id: value })}
          >
            {
              cities.map((city) => (
                <Option
                  key={city.value}
                  value={city.value}
                >
                  { city.label }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Заказчик'>
          <Select
            placeholder='Выберите заказчика'
            allowClear
            showSearch
            onChange={(value) => onFilterChange({ employer_id: value })}
            value={filter?.employer_id}
            filterOption={
              (input, option: any) =>
                (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
            }
          >
            {
              employers.map((employer) => (
                <Select.Option
                  key={employer.id}
                  value={employer.id}
                >
                  { employer.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Роль'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите роль'
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={filter?.role_id}
            onChange={(value) => onFilterChange({ role_id: value })}
          >
            {
              roles?.map((role) =>
                (
                  <Option
                    key={role.id}
                    value={role.id}
                  >
                    { role.name }
                  </Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Метрика'>
          <Select
            allowClear
            placeholder='Выберите метрику'
            value={filter?.function_name}
            onChange={(value) => onFilterChange({ function_name: value })}
          >
            {
              functions?.map((f) =>
                (
                  <Option
                    key={f.name}
                    value={f.name}
                  >
                    { f.title }
                  </Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Тип'>
          <Select
            allowClear
            placeholder='Выберите тип'
            value={filter?.function_type}
            onChange={(value) => onFilterChange({ function_type: value })}
          >
            {
              filterFunctionTypes?.map((t) =>
                (
                  <Option
                    key={t.value}
                    value={t.value}
                  >
                    { t.label }
                  </Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Группа'>
          <Select
            allowClear
            placeholder='Выберите группу'
            value={filter?.group_id}
            onChange={(value) => onFilterChange({ group_id: value })}
          >
            {
              tariffGroups?.map((g) =>
                (
                  <Option
                    key={g.id}
                    value={g.id}
                  >
                    { g.name }
                  </Option>
                ))
            }
          </Select>
        </Form.Item>


        <Button onClick={onFilterReset}>Сбросить фильтры</Button>
      </Form>
    </Drawer>
  )
}
