import { APITYPES } from '../../types/apitypes'
import { action, Actions, ThAction } from '../../actions'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'


export const getMonitoringAllocations = (filter: APITYPES.DoerMonitoring.GetAllocations.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getMonitoringAllocations', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerMonitoring.GetAllocations.URL, { params: { ...filter } })

      if (resp) {
        dispatch(action(Actions.CLEAN_DOER_MONITORING_ALLOCATIONS, null))
        dispatch(action(Actions.SET_DOER_MONITORING_ALLOCATIONS, { doerMonitoringAllocations: resp }))
        dispatch(changeRequestState('getMonitoringAllocations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getMonitoringAllocations', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const updateMonitoringAllocations = (filter: APITYPES.DoerMonitoring.UpdateAllocations.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateMonitoringAllocations', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerMonitoring.UpdateAllocations.URL, { params: { ...filter } })

      if (resp) {
        dispatch(changeRequestState('updatedMonitoringAllocationsData', StatusRequest.LOADING))
        dispatch(action(Actions.CLEAN_DOER_MONITORING_ALLOCATIONS, null))
        dispatch(action(Actions.SET_DOER_MONITORING_ALLOCATIONS, { doerMonitoringAllocations: resp }))
        dispatch(changeRequestState('updatedMonitoringAllocationsData', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateMonitoringAllocations', StatusRequest.ERROR))

      throw(e)
    } finally {
      dispatch(changeRequestState('updateMonitoringAllocations', StatusRequest.LOADED))
    }
  }
}