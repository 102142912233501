import React from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch, useSelector, } from 'react-redux'
import { State } from 'stores/rootReducer'
import { createSuboperation } from 'AC/shifts/operations'
import { FormFooter } from 'components/FormFooter'
import { formItemStyle, stateOptions } from './utils'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  tasks: state.operationsReducer.operationTasks,
  current: state.operationsReducer.currentOperation,
})

const formLayout = {
  labelCol: {span: 5},
}

export const CreateOperationModal = (props: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {tasks, current} = useSelector(selector)
  const {onClose, isOpen} = props

  const onFinish = (values: any) => {
    if(!current) return

    dispatch(createSuboperation(current.id, values))
    
    onClose()
  }
  const afterClose = () => form.resetFields()

  return (
    <Modal
      title="Создание операции"
      visible={isOpen}
      footer={false}
      afterClose={afterClose}
      onCancel={onClose}
    >
      <Form form={form} onFinish={onFinish} {...formLayout} initialValues = {{state: stateOptions[1][0]}}>
        <div style={formItemStyle}>
          <Form.Item
            label="Операция"
            name="task_id"
            rules={[{required: true, message: 'Поле должно быть заполнено'}]}
          >
            <Select>
              {tasks.map(task => (
                <Select.Option key={task.id} value={task.id}>{task.title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={formItemStyle}>
          <Form.Item
            label="Статус"
            name="state"
            rules={[{required: true, message: 'Поле должно быть заполнено'}]}
          >
            <Select> 
              {stateOptions.map(state => (
                <Select.Option key={state[0]} value={state[0]}>{state[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <FormFooter onCancel={onClose} okText="Создать"/>
      </Form>
    </Modal>
  );
};
