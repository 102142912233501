import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { dateFormat, dateTimeFormat } from 'utils/dateUtils'


export const getColumns = () => {
  return [
    {
      title: 'Дата отчета',
      dataIndex: 'report_date',
      key: 'report_date',
    },
    {
      title: 'Загружен',
      dataIndex: 'created',
      key: 'created',
      render: (date: string) => moment(date).format(dateTimeFormat),
    },
    {
      title: 'Обработан',
      dataIndex: 'deployed',
      key: 'deployed',
      render: (date: string) => date ?  moment(date).format(dateTimeFormat) : '',
    },
  ]
}

export const getRows = (reports: APITYPES.YandexPro.Reports.GET.Resp) => {
  return reports.map((report) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'report_date') {
        row[column.dataIndex] = moment(report.report_date).format(dateFormat)
      } else {
        //@ts-ignore
        row[column.dataIndex] = report[column.dataIndex]
      }
    })
    row.key = `${report.id}_${report.report_date}`
    row.id = report.id

    return row
  })
}