import { Form, Input, Modal, Select, Tabs } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { createBillItem } from 'AC/billing/createBillItem'
import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { BILL_ITEM_GROUPS, DOERS_BILL_ITEM_GROUPS } from '../../../../config'

import styles from './styles.module.sass'


const { TabPane } = Tabs

type Props = {
  isVisible: boolean
  onCancel: () => void
}

const selector = (state: State) => ({ records: state.billingReducer.records })

export const CreateModal = (props: Props) => {
  const { pathname } = useLocation()
  
  const billItemGroup = pathname.includes('/bills/') ? BILL_ITEM_GROUPS : DOERS_BILL_ITEM_GROUPS

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const { records } = useSelector(selector)

  const { isVisible, onCancel } = props

  const dispatch = useDispatch()

  const onFinish = async (values: {
    name: string
    description: string
    value: string
    group: string
  }) => {
    dispatch(createBillItem({
      ...values,
      value: parseFloat(values.value),
      group: ! values.group.includes('Прочее') ? values.group : undefined,
    }))

    onCancel()
  }

  const onFinishByRecord = async (values: {
    record_id: string
  }) => {
    dispatch(createBillItem(values))

    onCancel()
  }

  return (
    <Modal
      title='Создание позиции счета'
      open={isVisible}
      onCancel={onCancel}
      footer={false}
      className={styles.modal}
    >
      <Tabs
        defaultActiveKey='1'
      >
        <TabPane
          tab='Вручную'
          key='1'
        >
          <Form
            name='updating_bill_item_form'
            {...formItemLayout}
            onFinish={onFinish}
          >
            <Form.Item
              name='name'
              label='Название'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='description'
              label='Описание'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='value'
              label='Сумма'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input type='number' />
            </Form.Item>
            <Form.Item
              name='group'
              label='Тип'
              initialValue={billItemGroup[0]}
            >
              <Select showSearch>
                {
                  billItemGroup.map((item, i)=>{
                    return (
                      <Select.Option
                        key={i}
                        value={item}
                      >
                        { item }
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <FormFooter
              onCancel={onCancel}
              position='right'
            />
          </Form>
        </TabPane>
        <TabPane
          tab='На основе записи'
          key='2'
        >
          <Form
            name='updating_bill_item_form2'
            {...formItemLayout}
            onFinish={onFinishByRecord}
          >
            <Form.Item
              name='record_id'
              label='Запись'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Select>
                {
                  records.map((item)=>{
                    return (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                      >
                        { item.item_name }
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <FormFooter
              onCancel={onCancel}
              position='right'
            />
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  )
}