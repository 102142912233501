import { RcFile } from 'antd/es/upload'

import { action, Actions, ThAction } from '../../actions'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'
import { APITYPES } from '../../types/apitypes'
import { showError } from '../../components/modals/ErrorModal'
import { successNotification } from '../../components/modals/OperationNotification'
import { apiV1 } from '../../network/http'
import { showInfo } from '../../components/modals/InfoModal'


export const getUrentReports = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getUrentReports', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Urent.Reports.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_URENT_REPORTS, resp))

        dispatch(changeRequestState('getUrentReports', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getUrentReports', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const addReportFile = (file: RcFile): ThAction => {
  return async (dispatch) => {
    try {
      const response = await apiV1.post(
        '/v1/urent/reports',
        file,
        {
          headers: {
            'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        dispatch(action(Actions.SET_URENT_REPORT, response.data))
        successNotification('Файл успешно загружен')
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const getUrentReportById = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getUrentReportById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Urent.Reports.GetById.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_URENT_REPORT, resp))

        dispatch(changeRequestState('getUrentReportById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getUrentReportById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const connectUrentUnmatchedExecutors = (executorId: string, doerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('connectUrentUnmatchedExecutors', StatusRequest.LOADING))

    try {
      const resp = await api.put(
        APITYPES.Urent.UnmatchedExecutors.PUT.URL, { doer_user_id: doerId }, { replaceUrl: { id: executorId } }
      )

      if (resp) {
        dispatch(action(Actions.UPDATE_URENT_UNMATCHED_EXECUTOR, { id: executorId }))

        dispatch(changeRequestState('connectUrentUnmatchedExecutors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('connectUrentUnmatchedExecutors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deployUrentReportById = (id: string, onSuccess?: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deployUrentReport', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Urent.DeployReport.URL,{}, { replaceUrl: { id } })

      if (resp) {
        onSuccess && onSuccess()
        showInfo('Отчет обработан')
        dispatch(changeRequestState('deployUrentReport', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deployUrentReport', StatusRequest.ERROR))

      showError(e?.response?.data || e?.message || 'Ошибка')

      throw(e)
    }
  }
}