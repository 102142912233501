import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'

export const getFleets = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getFleets', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Fleets.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_FLEETS, resp))

        dispatch(changeRequestState('getFleets', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getFleets', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}