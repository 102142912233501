// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--editBtn--CUnpN{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border:none}.styles-module--editBtn--CUnpN:hover,.styles-module--editBtn--CUnpN:focus,.styles-module--editBtn--CUnpN:active{border:none !important}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Locations/EditLocationModal/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,kBAAA,CACA,WAAA,CAEA,gHACE,sBAAA","sourcesContent":[".editBtn\n  width: fit-content\n  height: fit-content\n  border: none\n\n  &:hover, &:focus, &:active\n    border: none !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editBtn": "styles-module--editBtn--CUnpN"
};
export default ___CSS_LOADER_EXPORT___;
