import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'antd'

import { State } from 'stores/rootReducer'
import { getTableYScroll } from 'utils/scrollUtils'

import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({ history: state.carwashesReducer.carwash?.history })

export const History = () => {
  const { history = [] } = useSelector(selector)

  return (
    <div className={styles.tableContainer}>
      <Table
        sticky
        columns={getColumns() as any}
        dataSource={getRows(history)}
        bordered
        size='large'
        pagination={false}
        scroll={{ y: getTableYScroll(281) }}
      />
    </div>
  )
}