// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--modal--XXNNp{min-width:700px !important}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/Card/Partner/CreateDocument/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,0BAAA","sourcesContent":[".modal\n    min-width: 700px!important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles-module--modal--XXNNp"
};
export default ___CSS_LOADER_EXPORT___;
