import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { getDoerById } from 'AC/doers/getDoers'
import { fetchRoles } from 'AC/doers/doerRoles'
import { Loader } from 'components/Loader'

import { Header } from './Header'
import { Information } from './Information'
import { Shifts } from './Shifts'
import { Reserves } from './Reserves'
import YandexPro from './YandexPro'
import { Urent } from './Urent'


const { TabPane } = Tabs

const selector = (state: State) => ({
  doer: state.doersReducer.doer,
  roles: state.doersReducer.roles,
})

export const Doer = () => {
  const { doerId } = useParams<{ doerId: string }>()
  const dispatch = useDispatch()
  const { doer, roles } = useSelector(selector)

  useEffect(() => {
    if (!roles) {dispatch(fetchRoles())}
    dispatch(getDoerById(doerId))

    return () => {
      dispatch(action(Actions.SET_DOER, null))
      dispatch(action(Actions.CLEAR_DOER_SHIFTS, {}))
      dispatch(action(Actions.CLEAR_DOER_RESERVES, {}))
      dispatch(action(Actions.CLEAR_YANDEXPRO_EXECUTOR, {}))
    }
  }, [])

  if (!doer) {return <Loader />}

  return (
    <div>
      <Header doer={doer} />

      <Tabs defaultActiveKey='info'>
        <TabPane
          tab='Информация'
          key='info'
          forceRender
        >
          <Information doer={doer} />
        </TabPane>

        <TabPane
          tab='Смены'
          key='shifts'
        >
          <Shifts doer={doer} />
        </TabPane>

        <TabPane
          tab='Резервы'
          key='reserves'
        >
          <Reserves doer={doer} />
        </TabPane>
        <TabPane
          tab='Яндекс.Про'
          key='YandexPro'
        >
          <YandexPro doer={doer}  />
        </TabPane>
        <TabPane
          tab='Urent'
          key='urent'
        >
          <Urent doer={doer}  />
        </TabPane>
      </Tabs>
    </div>
  )
}
