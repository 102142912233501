import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const getContractorRecords = (params: APITYPES.Billing.GetContractorRecords.Req = {}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getContractorRecords', StatusRequest.LOADING))

    const { bill } = getState().billingReducer

    if (!bill) { return }

    const { contractor_id } = bill

    try {
      const resp = await api.get(APITYPES.Billing.GetContractorRecords.URL, {
        params,
        replaceUrl: { id: contractor_id },
      })
      
      if (resp) {
        dispatch(action(Actions.SET_CONTRACTOR_RECORDS, resp))
        dispatch(changeRequestState('getContractorRecords', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getContractorRecords', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}