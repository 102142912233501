import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const switchClientType = (value: boolean, carwash_id: string, client_type_id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('switchClientType', StatusRequest.LOADING))
      
    try {
      const resp = value ? await api.put(APITYPES.CarwashClientTypes.Connect.URL, {}, {
        replaceUrl: {
          carwash_id,
          client_type_id,
        },
      }) :
        await api.put(APITYPES.CarwashClientTypes.Disconnect.URL, {}, {
          replaceUrl: {
            carwash_id,
            client_type_id,
          },
        })
    
      if (resp) {
        dispatch(action(Actions.UPDATE_CLIENT_TYPES, {
          id: client_type_id,
          value,
        }))
    
        dispatch(changeRequestState('switchClientType', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('switchClientType', StatusRequest.ERROR))

      showError(e.response.data)
  
      throw(e)
    }
  }
}