// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--SCkj3{display:-webkit-box;display:-ms-flexbox;display:flex;gap:12px;height:calc(100vh - 254px)}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/Product/ProductServicesOptions/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CACA,0BAAA","sourcesContent":[".container\n  display: flex\n  gap: 12px\n  height: calc(100vh - 254px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--SCkj3"
};
export default ___CSS_LOADER_EXPORT___;
