import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getDoerReserves } from 'AC/doers/getDoers'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { getTableYScroll } from 'utils/scrollUtils'

import { getColumns, getRows } from './utils'


const backDate = 'YYYY-MM-DD'

type Props = {
  doer: APITYPES.Doer
}

const selector = (state: State) => ({
  doerReserves: state.doersReducer.doerReserves,
  hasMoreDoerReserves: state.doersReducer.hasMoreDoerReserves,
  isLoading: state.requestsReducer.getDoerReserves === StatusRequest.LOADING,
})

export const Reserves = ({ doer }: Props) => {
  const dispatch = useDispatch()
  const { doerReserves, isLoading, hasMoreDoerReserves } = useSelector(selector)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  /** filter state */
  const [filter, setFilter] = useState<APITYPES.Doers.GetReserves.Params>({
    date_from: undefined,
    date_to: undefined,
    state: undefined,
  })

  useEffect(() => {
    if (!isLoading && hasMoreDoerReserves && entry?.isIntersecting) {
      dispatch(getDoerReserves(doer.id, filter))
    }
  }, [entry?.isIntersecting])

  useEffect(() => {
    dispatch(action(Actions.CLEAR_DOER_RESERVES, {}))
    dispatch(getDoerReserves(doer.id, filter))
  }, [filter])


  /** helpers */
  const toOffer = (id: string) => window.open(ROUTES.DOERS.OFFERS.createPath(id))

  const onTableChange = (_: any, tableFilters: any) => {
    setFilter({
      state: tableFilters?.state ? tableFilters.state : undefined,
      date_from: tableFilters?.schedule ? tableFilters.schedule[0].format(backDate) : undefined,
      date_to: tableFilters?.schedule ? tableFilters.schedule[1].format(backDate) : undefined,
    })
  }

  return (
    <Table
      sticky
      bordered
      size='small'
      loading={isLoading}
      columns={getColumns() as any}
      dataSource={getRows(doerReserves || [])}
      pagination={false}
      style={{ cursor: 'pointer' }}
      onChange={onTableChange}
      onRow={
        (record: any, index) => ({
          ref: index === doerReserves.length - 1 ? lastRowRef : undefined,
          onClick: () => toOffer(record.offer_id),
        })
      }
      scroll={{ y: getTableYScroll(265) }}
    />
  )
}
