import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { updateAllServices } from 'AC/directory/directory'

import { isFormChanged, normalizeFormTypings, isFormValid } from './utils'

const { ServiceProvider } = APITYPES


const { TextArea } = Input
const { useForm } = Form

type Props = {
  service: APITYPES.AllServices.GetService.Resp
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: {
      span: 24,
      offset: 0,
    },
  },
}

export const ServiceInfoEdit = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const { service } = props
  const [formValues, setFormValues] = useState<APITYPES.AllServices.Put.Req>(normalizeFormTypings(form))
  const providers = Object.entries(ServiceProvider)

  useEffect(() => {
    const values = normalizeFormTypings(form)

    setFormValues(values)
  }, [])

  const onFinish = async (values: APITYPES.AllServices.Put.Req) => {
    dispatch(updateAllServices({
      ...values,
      id: service.id,
    }))
  }

  const onBlur = () => {
    const errors = isFormValid(form)
    const values = normalizeFormTypings(form)
    const isChanged = isFormChanged(values, formValues)

    if (!errors && isChanged) {
      setFormValues(values)
      form.submit()
    }
  }

  return (
    <Form
      name='service'
      onFinish={onFinish}
      onBlur={onBlur}
      labelAlign='left'
      form={form}
      {...formItemLayout}
    >
      <Form.Item
        name='name'
        initialValue={service.name}
        label='Название'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input />
      </Form.Item>

      <Form.Item
        name='description'
        initialValue={service.description}
        label='Описание'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <TextArea autoSize={
          {
            minRows: 4,
            maxRows: 6,
          }
        }
        />
      </Form.Item>

      <Form.Item
        name='provider'
        initialValue={service.provider}
        label='Провайдер'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Select onSelect={onBlur} >
          {
            providers.map(provider => (
              <Select.Option value={provider[0]} key={provider[0]}>{provider[1]}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    </Form>
  )
}