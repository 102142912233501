// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--U7DAv{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:500;font-size:17px;width:100%;height:280px;color:#adafb5}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Locations/EditLocationModal/OnlyForDistrict/styles.module.sass","webpack://./src/styles/vars.sass"],"names":[],"mappings":"AAEA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,UAAA,CACA,YAAA,CACA,aCUS","sourcesContent":["@import \"src/styles/vars\"\n\n.container\n  display: flex\n  justify-content: center\n  align-items: center\n  font-weight: 500\n  font-size: 17px\n  width: 100%\n  height: 280px\n  color: $black200\n","$fontFamilyDefault: 'Roboto', -apple-system, BlinkMacSystemFont, \"Segoe UI\", sans-serif\n\n$white: #FCFCFD\n\n$blue: #0066FF\n$active-tab: #1890FFFF\n$grayish: #FBFBFB\n\n$red: #F6768E\n$green: #52C41A\n\n$yellow: #ffc600\n$orange: #FFA500\n\n$blackMain: #333745\n$black900: #161616\n$black700: #1F2129\n$black500: #333745\n$black400: #5C5F6A\n$black300: #85878F\n$black200: #ADAFB5\n\n$gray100: #F2F2F2\n$gray200: #989898\n$gray300: #828282\n$gray500: #676767\n$gray700: #464646\n$lightGray: #AAA"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--U7DAv"
};
export default ___CSS_LOADER_EXPORT___;
