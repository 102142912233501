import React from 'react'

import { APITYPES } from 'types/apitypes'
import { Partner } from '../Carwashes/Partner'
import { Status } from '../Carwashes/Status'

import CarwashLocation = APITYPES.CarwashLocation


export const getColumns = () => {
  return (
    [
      {
        title: 'Адрес',
        dataIndex: 'location',
        fixed: 'left',
        width: 174,
        render: (value: Partial<CarwashLocation> | undefined) => value?.address,
      },
      {
        title: 'Регион',
        dataIndex: 'location',
        width: 152,
        render: (value: Partial<CarwashLocation> | undefined) => value?.region,
      },
      {
        title: 'Статус',
        dataIndex: 'state',
        key: 'state',
        width: 150,
        render: (value: APITYPES.Carwash) => (
          <Status
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'Партнер',
        dataIndex: 'partner',
        key: 'partner_id',
        width: 174,
        render: (value: APITYPES.Carwashes.Get.Resp[number]) => (
          <Partner
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'Способ владения',
        dataIndex: 'own_type',
        key: 'own_type',
        width: 150,
      },
    ]
  )
}

export const getRows = (carwashes: APITYPES.Carwashes.Get.Resp) => {
  return carwashes.map((carwash) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state' || column.dataIndex === 'partner') {
        return
      }
      //@ts-ignore
      row[column.dataIndex] = carwash[column.dataIndex]
      row.key = carwash.id

      return
    })

    row.partner = carwash
    row.state = carwash
    row.client_type = carwash

    return row
  })
}