import moment from 'moment'

import { APITYPES } from 'types/apitypes'


const Cities = Object.values(APITYPES.Cities)

const getScheduleTime = (schedule: string) => {
  return moment(schedule, 'HH:mm:ss').format('HH:mm')
}

export const getGeneralDataArr = (shift: APITYPES.Shift, employer: APITYPES.Employer | null) => {
  const list = [
    {
      label: 'Город:',
      value: Cities[shift.location.city_id - 1],
    },
    {
      label: 'Локация:',
      value: shift.location.name,
    },
    {
      label: 'Дата:',
      value: moment(shift.date).format('LL'),
    },
    {
      label: 'График:',
      value: `${ getScheduleTime(shift.begin) } - ${ getScheduleTime(shift.end) }`,
    },
  ]

  if (employer?.name) {
    list.unshift({
      label: 'Заказчик:',
      value: employer.name,
    })
  }

  if (shift.time) {
    list.push({
      label: 'Публикация:',
      value: moment(shift.time).format('LLL'),
    })
  }

  return list
}

export const getRolesDataArr = (roles: APITYPES.ShiftRole[]) => {
  return roles.map((role) => ({
    id: role.id,
    label: `${role.name}:`,
    booked: role.booked_places,
    places: role.places,
  }))
}
export const getOffersDataMap = (offers: APITYPES.ShiftOffer[]) => {
  const dataMap: Record<string, string> = {}

  offers.forEach((item) => {
    dataMap[item.role_id] = item.offer_id
  })

  return dataMap
}