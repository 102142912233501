import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchUserOrders } from 'AC/users/userOrders'
import { getTableYScroll } from 'utils/scrollUtils'
import { getColumns, getRows, getServiceColumns, getServiceRows } from './utils'

type Props = {
  user: APITYPES.ExpandedUser | null
}

const selector = (state: State) => ({
  orders: state.usersReducer.orders,
  isLoading: state.requestsReducer.fetchUserOrders === StatusRequest.LOADING
})

export const Orders = (props: Props) => {
  const { user } = props
  const { orders, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    if(user && user.cars) {
      dispatch(fetchUserOrders(user.id))
    }

    return () => {
      dispatch(action(Actions.SET_USER_ORDERS, null))
    }
  }, [])

  const onTableChange = (_: any, filters: any) => {
    if(user) {
      dispatch(action(Actions.SET_USER_ORDERS, null))
      dispatch(fetchUserOrders(user.id, {
        state_name: filters?.state_name ? filters?.state_name[0] : undefined
      }))
    }
  }

  return (
    <Table
      columns={getColumns() as any}
      dataSource={getRows(orders || [], user!.cars)}
      bordered
      pagination={false}
      loading={isLoading}
      expandable={{
        rowExpandable: (record) => !!record.services,
        expandedRowRender: record => (
          <Table
            columns={getServiceColumns() as any}
            dataSource={getServiceRows(record.services || [])}
            pagination={false}
            size='small'
          />
        ),
      }}
      onChange={onTableChange}
      scroll={{y: getTableYScroll(280)}}
    />
  )
}