import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Actions, action } from 'actions'


const FilterDrawerContext = React.createContext<any>({})

export const FilterDrawerProvider = ({ children }: {
  children: React.ReactNode
}) => {
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const toggleFilterDrawer = useCallback(
    () => setFilterDrawer((prev) => !prev),
    []
  )
  const dispatch = useDispatch()

  const [addShiftModal, setAddShiftModal] = useState<boolean>(false)
  const [commitModal, setCommitModal] = useState<boolean>(false)

  const toggleAddShiftModal = useCallback(
    () => setAddShiftModal((prev) => !prev),
    []
  )
  const toggleCommitModal = useCallback(() => {
    setCommitModal((prev) => !prev)
    dispatch(action(Actions.CLEAR_OPERATIONS_STATISTICS, []))
  }, [dispatch])

  return (
    <FilterDrawerContext.Provider
      value={
        {
          filterDrawer,
          toggleFilterDrawer,

          addShiftModal,
          toggleAddShiftModal,

          commitModal,
          toggleCommitModal,
        }
      }
    >
      { children }
    </FilterDrawerContext.Provider>
  )
}

export const useFilterDrawerContext = () => {
  const context = useContext(FilterDrawerContext)

  if (!context) {
    throw new Error('FilterDrawerContext is not reachable.')
  }

  return context
}
