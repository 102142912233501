import React, { useEffect } from 'react'
import { Button, Drawer, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { useGetLocations } from '../hooks/useGetLocations'
import { action, Actions } from '../../../../../actions'
import { APITYPES } from '../../../../../types/apitypes'
import { State } from '../../../../../stores/rootReducer'
import { getVacancyStates, getPrioritiesOptions, getCitiesOptions } from '../../../../../utils/enumUtils'
import { isObjectEmpty } from '../../../../../utils/filterUtils'
import { ROUTES } from '../../../../../config/routes'
import { useQueryParams } from '../../../../../utils/hooks/useQueryParams'
import { getDoerVacancies } from '../../../../../AC/doers/doerVacancies'
import { useGetRoles } from '../../Motivations/hooks'
import { fetchRoleGroup } from '../../../../../AC/doers/doerRoles'
import { removeDuplicateObjects } from '../../../../../utils/arrayUtils'

import Cities = APITYPES.Cities


type Props = {
  isOpen: boolean
  onClose: () => void
}

const priorityOptions = getPrioritiesOptions()
const stateOptions = getVacancyStates()
const cities =  getCitiesOptions()

const selector = (state: State) => ({
  filter: state.doerVacanciesReducer.vacanciesFilter,
  roleGroups: state.doersReducer.roleGroups,
})

export const Filter = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const { filter, roleGroups } = useSelector(selector)
  const locations = useGetLocations({})
  const roles = useGetRoles({})
  const queryParams = useQueryParams()
  const history = useHistory()

  const onFilterChange = (key: keyof APITYPES.DoerVacancies.Get.Query, value: string | undefined | number) => {
    history.push(ROUTES.DOERS.VACANCIES.PARAMS.createPath({
      ...filter,
      [key]: value,
    }))
    dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
    dispatch(action(Actions.SET_DOER_VACANCIES_FILTER, {
      ...filter,
      [key]: value,
    }))
    dispatch(getDoerVacancies({
      ...filter,
      [key]: value,
    }))
  }

  const resetFilters = () => {
    if (isObjectEmpty(queryParams)) {
      return
    }
    
    dispatch(action(Actions.SET_DOER_VACANCIES_FILTER, {}))
    dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
    history.push(ROUTES.DOERS.VACANCIES.path)
    dispatch(getDoerVacancies())
  }

  useEffect(() => {
    if (roleGroups?.length === 0) {
      dispatch(fetchRoleGroup())
    }
  }, [dispatch, roleGroups])

  return (
    <Drawer
      title='Фильтры'
      onClose={onClose}
      open={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Роли'>
          <Select
            placeholder='Выберите роль'
            allowClear
            showSearch
            onChange={(value) => onFilterChange('role_id', value)}
            value={filter?.role_id}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              roles && roles.map((role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Город'>
          <Select
            allowClear
            placeholder='Выберите город'
            value={filter?.city_id ? +filter.city_id : undefined}
            onChange={(value) => onFilterChange('city_id', value)}
          >
            {
              cities.map((city) => (
                <Select.Option
                  key={city.value}
                  value={city.value}
                >
                  { city.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Локации'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите локацию'
            value={filter?.location_id}
            onChange={(value) => onFilterChange('location_id', value)}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              locations.map(({ id, city_id, name }) => (
                <Select.Option
                  key={id}
                  value={id}
                >
                  { (city_id ? Cities[city_id] + ', ' : '') + name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Приоритет'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите приоритет'
            value={filter?.priority}
            onChange={(value) => onFilterChange('priority', value)}
          >
            {
              priorityOptions.map((item) => (
                <Select.Option
                  key={item.value}
                  value={item.value}
                >
                  { item.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Состояние'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите состояние'
            value={filter?.state}
            onChange={(value) => onFilterChange('state', value)}
          >
            {
              stateOptions.map((item) => (
                <Select.Option
                  key={item.value}
                  value={item.value}
                >
                  { item.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Группа'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите группу'
            value={filter.group_id}
            onChange={(value) => onFilterChange('group_id', value)}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              roleGroups?.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  { item.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Направление'>
          <Select
            allowClear
            showSearch
            placeholder='Выберите направление'
            value={filter.department}
            onChange={(value) => onFilterChange('department', value)}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              removeDuplicateObjects(roleGroups, (item) => item.department)?.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.department}
                >
                  { item.department }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}