// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--error--oL0pP{border:1px solid red}.styles-module--input--hsgby{text-align:right}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/TariffInput/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,oBAAA,CAEJ,6BACI,gBAAA","sourcesContent":[".error\n    border: 1px solid red\n\n.input\n    text-align: right"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "styles-module--error--oL0pP",
	"input": "styles-module--input--hsgby"
};
export default ___CSS_LOADER_EXPORT___;
