import React, { useEffect } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { cancelReserve, changeReserveOffer, fetchReserveOffers } from 'AC/shifts/offers'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isOpen: boolean
  onClose: () => void
  reserve: APITYPES.OfferReserve | null
}

const selector = (state: State) => ({
  reserveOffers: state.offersReducer.reserveOffers,
  isResOffersLoading: state.requestsReducer.fetchReserveOffers === StatusRequest.LOADING,
  offer: state.offersReducer.offer,
})

export const ChangeReserveModal = (props: Props) => {
  const { isOpen, onClose, reserve } = props
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { offer, isResOffersLoading, reserveOffers } = useSelector(selector)

  useEffect(() => {
    if (!reserve || !offer) {return}
    dispatch(fetchReserveOffers(offer.role.id, offer.date))

    return () => {dispatch(action(Actions.SET_RESERVE_OFFERS, []))}
  }, [reserve])

  const onFinish = (values: any) => {
    if (!reserve) {return}

    dispatch(changeReserveOffer(values, reserve.id))

    onClose()
  }

  const onCancelReserve = () => {
    if (!reserve || !offer) {return}

    dispatch(cancelReserve(reserve.id, offer.id))
    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Смена оффера исполнителя'
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      afterClose={afterClose}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label='Оффер'
          name='offer_id'
          rules={[{required: true, message: 'Поле должно быть заполнено'}]}
        >
          <Select
            loading={isResOffersLoading}
            disabled={isResOffersLoading}
          >
            {
              reserveOffers.map((o) => {
                if (offer?.id !== o.id) {
                  return (
                    <Select.Option
                      key={o.id}
                      value={o.id}
                    >
                      { `${APITYPES.Cities[o.location.city_id]}, ${o.location.name}` }
                    </Select.Option>
                  )
                }

                return null
              })
            }
          </Select>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Изменить'
          hideCancelButton
          leftElement={
            <Button
              danger
              type='primary'
              onClick={onCancelReserve}
            >
              Отменить
            </Button>
          }
        />
      </Form>
    </Modal>
  )
}
