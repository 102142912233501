import React, { useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
// import { getSingleNews } from 'AC/news/news'
import { NewsModalType } from '../utils'
import { NewsModal } from '../NewsModal'
import { mock_news } from '../mock_data'
import { ROUTES } from 'config/routes'

import styles from './styles.module.sass'


const selector = (state: State) => ({ news: state.newsReducer.currentNews })

type NewsModalState = {
  isOpen: boolean
  type: NewsModalType | null
}

export const SingleNews = () => {
  const { news } = useSelector(selector)
  const history = useHistory()
  const { id } = useParams<{id: string}>()
  const dispatch = useDispatch()
  const [newsModal, setNewsModal] = useState<NewsModalState>({
    isOpen: false,
    type: null,
  })

  useEffect(() => {
    if (!news) {
      const curNews = mock_news.find((n) => n.id === id)

      dispatch(action(Actions.SET_CURRENT_NEWS, curNews))
      // dispatch(getSingleNews(id))
    }
  }, [])


  const onBackClick = () => {
    history.push(ROUTES.DOERS.NEWS.path)
  }

  const openNewsModal = (type: NewsModalType, modalNews?: APITYPES.News) => {
    if (type !== NewsModalType.ADD_NEWS && modalNews) {
      dispatch(action(Actions.SET_CURRENT_NEWS, modalNews))
    }
    setNewsModal({
      isOpen: true,
      type: type,
    })
  }

  const closeNewsModal = () => setNewsModal({
    isOpen: false,
    type: null,
  })

  const menu = (
    <Menu>
      <Menu.Item
        key='edit'
        onClick={() => openNewsModal(NewsModalType.EDIT_NEWS)}
      >
        Редактировать
      </Menu.Item>
      <Menu.Item
        key='manage'
        onClick={() => openNewsModal(NewsModalType.MANAGE_NEWS)}
      >
        Настройки
      </Menu.Item>
    </Menu>
  )

  if (!news) {return null}

  const isImage = !!news.image

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          {
            isImage ? (
              <img
                alt={news.title}
                src={news.image}
              />
            ) : null
          }

          <div className={styles.controls}>
            <ArrowLeftOutlined
              className={styles.icon}
              onClick={onBackClick}
            />

            <Dropdown overlay={menu}>
              <MoreOutlined className={styles.icon} />
            </Dropdown>
          </div>

          <div className={styles.titleBlock}>
            <div className={styles.title}>{ news.title }</div>
            <div className={styles.date}>{ moment(news.publication_date).format('LLL') }</div>
          </div>

        </div>

        <div className={styles.body}>
          { news.body }
        </div>
      </div>

      <NewsModal
        isOpen={newsModal.isOpen}
        type={newsModal.type}
        onClose={closeNewsModal}
      />
    </>
  )
}