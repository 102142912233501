import React, { useEffect, useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Divider, InputNumber, PageHeader, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { copyToClipboard } from '../../../../../utils/copyUtils'
import { State } from '../../../../../stores/rootReducer'
import { getVacancy, updateVacancy } from '../../../../../AC/doers/doerVacancies'
import { StatusRequest } from '../../../../../stores/requestsReducer'
import { Loader } from '../../../../../components/Loader'
import { APITYPES } from '../../../../../types/apitypes'
import { vacancyStateColors } from '../utils/utils'

import styles from './styles.modules.sass'
import { getColumns, getColumnsForDoers, getRows, getRowsForDoers } from './utils'
import { ChangeStatusModal } from './ChangeStatusModal'

import VacancyStateTrans = APITYPES.VacancyStateTrans


const selector = (state: State) => {
  const { doerVacancy } = state.doerVacanciesReducer
  const isLoading = state.requestsReducer.getVacancy === StatusRequest.LOADING
  const isUpdateLoading = state.requestsReducer.updateVacancy === StatusRequest.LOADING

  return {
    doerVacancy,
    isLoading,
    isUpdateLoading,
  }
}

export const Vacancy = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { vacancyId } = useParams<{vacancyId: string}>()
  const { doerVacancy, isLoading, isUpdateLoading } = useSelector(selector)

  const [openStatus, setOpenStatus] = useState(false)
  const [demandValue, setDemandValue] = useState(doerVacancy?.value)

  useEffect(() => {
    dispatch(getVacancy(vacancyId))
  }, [])

  if (isLoading) {
    return <Loader />
  }

  const onDemandChange = (value: number | null) => {
    setDemandValue(value as number)
  }

  const onDemandValueSave = () => {
    if (demandValue) {
      dispatch(updateVacancy({
        vacancy_id: vacancyId,
        value: demandValue,
      }))
    }
  }

  return (
    <>
      <PageHeader
        className={styles.header}
        title={doerVacancy?.city?.name}
        extra={moment(doerVacancy?.created).format('DD.MM.YYYY')}
        onBack={history.goBack}
      />
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.infoContainer}>
            <div
              className={styles.field}
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipboard(doerVacancy?.id, 'ID успешно скопирована')}
            >
              <div className={styles.title}>ID</div>
              <div className={styles.value}>{ doerVacancy?.id }</div>
              <CopyOutlined className={styles.copyIcon} />
            </div>
            <Divider className={styles.divider}>Общая информация</Divider>
            <div
              className={styles.field}
            >
              <div className={styles.fieldWrap}>
                <div className={styles.title}>Город</div>
                <div className={styles.value}>{ doerVacancy?.city?.name }</div>
              </div>
            </div>
            <div
              className={styles.field}
            >
              <div className={styles.fieldWrap}>
                <div className={styles.title}>Статус</div>
                <Tag
                  color={vacancyStateColors[doerVacancy?.state]}
                  onClick={
                    () => {
                      if (doerVacancy?.state === 'open') {
                        setOpenStatus(true)
                      }
                    }
                  }
                  style={
                    {
                      padding: '0 20px',
                      cursor: 'pointer',
                    }
                  }
                >
                  { VacancyStateTrans[doerVacancy?.state] }
                </Tag>
              </div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div
              className={styles.field}
            >
              <div className={styles.fieldWrap}>
                <div className={styles.title}>Потребность</div>
                <InputNumber
                  className={styles.value}
                  defaultValue={doerVacancy.value}
                  max={doerVacancy?.value}
                  min={0}
                  onChange={onDemandChange}
                  style={{ maxWidth: 150 }}
                />
                <Button
                  loading={isUpdateLoading}
                  type='primary'
                  style={{ marginLeft: 12 }}
                  onClick={onDemandValueSave}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          sticky
          size='small'
          columns={getColumnsForDoers() as any}
          dataSource={getRowsForDoers(doerVacancy.doers || [])}
          bordered
          pagination={false}
          scroll={{ x: '100%' }}
        />
        <Table
          sticky
          size='small'
          columns={getColumns() as any}
          dataSource={getRows(doerVacancy?.doers_count || [])}
          bordered
          pagination={false}
          scroll={{ x: '100%' }}
        />
        <ChangeStatusModal
          open={openStatus}
          onCancel={() => setOpenStatus(false)}
          initialState={doerVacancy?.state}
        />
      </div>
    </>
  )
}