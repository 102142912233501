import React, { FC, useState } from 'react'
import { Button, Select } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

import cls from './editable-select.module.sass'


type OptionType = {
  label: string
  value: string
}
export type EditableSpanPropsType = {
  selectedOption: OptionType
  options: OptionType[]
  selectClassName?: string
  showEdit?: boolean
  onEdit: (newValue: string) => void
}

export const EditableSelect: FC<EditableSpanPropsType> = (props) => {
  const {
    selectedOption,
    options,
    showEdit = false,
    selectClassName,
    onEdit,
  } = props

  const [selectedValue, setSelectedValue] = useState(selectedOption)
  const [editMode, setEditMode] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const onCancel = () => {
    setEditMode(false)
  }

  const onSave = () => {
    onEdit(selectedValue.value)
    setEditMode(false)
  }

  return (
    <div
      className={cls.wrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {
        editMode ? (
          <div className={cls.actions}>
            <Select
              value={selectedValue.value}
              onChange={
                (value, option: any) => { // any due to options type is not expandable in SelectProps
                  setSelectedValue({
                    label: option.label,
                    value,
                  })
                }
              }
              className={selectClassName}
            >
              {
                options.map(({
                  label,
                  value,
                }) => (
                  <Select.Option
                    label={label}
                    key={value}
                    value={value}
                  >
                    { label }
                  </Select.Option>
                ))
              }
            </Select>
            <Button
              disabled={selectedValue.value === selectedOption.value}
              shape='circle'
              onClick={onSave}
              icon={<CheckOutlined />}
            />
            <Button
              shape='circle'
              onClick={onCancel}
              icon={<CloseOutlined />}
            />
          </div>
        )
          : (
            <span>
              { selectedOption.label }

              {
                (isHovered || showEdit) && (
                  <Button
                    type='text'
                    onClick={() => setEditMode(true)}
                    icon={<EditOutlined />}
                  />
                )
              }
            </span>
          )
      }
    </div>
  )
}