export const BILL_ITEM_GROUPS = [
  'Прочее',
  'Залив незамерзающей жидкости',
  'Покупка незамерзающей жидкости',
  'Химчистка',
  'Аренда',
  'Штраф',
  'Очень грязный автомобиль',
  'Поощрение',
  'Мойка автомобиля',
  'Самомойка',
  'Неучтенные автомобили',
  'Ремонт оборудования',
]

export const DOERS_BILL_ITEM_GROUPS = [
  'Перегонщики (мойки каршеринга, самомой)',
  'Перегонщики (мойки каршеринга, снабжение)',
  'Прочие (исполнители каршеринг, бонусы)',
  'Штрафы (дисциплинарные)',
  'Штрафы (качество)',
  'Штрафы (ПДД)',
  'Штрафы (ДТП)',
  'Перегонщики (заправки каршеринга)',
  'Прочие (исполнители каршеринг)',
  'Контроль качества (мойки каршеринга)',
  'Шиномонтажники (шиномонтаж каршеринга)',
]