import React from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Form, Input, Select } from 'antd'

import { APITYPES } from 'types/apitypes'
import { addNewPenalty } from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'


type Props = {
  carwash_id: string
  isVisible: boolean
  onCancel: () => void
  penaltyTypes: APITYPES.Penalty[]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}
const { useForm } = Form

export const NewPenaltyModal = (props: Props) => {
  const { isVisible, onCancel, carwash_id, penaltyTypes } = props
  const dispatch = useDispatch()
  const [form] = useForm()

  const onSubmit = (values: any) => {
    dispatch(addNewPenalty({ carwash_id }, values))

    onCancelClick()
  }

  const onCancelClick = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title='Выставление штрафа'
      visible={isVisible}
      onCancel={onCancelClick}
      width={700}
      footer={false}
    >
      <Form
        {...formItemLayout}
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          name='penalty_id'
          label='Список штрафов'
          rules={
            [{ required: true }]
          }
        >
          <Select showSearch>
            {
              penaltyTypes.map((type) => (
                <Select.Option
                  key={type.id}
                  value={type.id}
                >
                  { type.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='comment'
          label='Коментарий'
          initialValue=''
        >
          <Input.TextArea
            maxLength={250}
            autoSize={
              {
                minRows: 3,
                maxRows: 6,
              }
            }
          />
        </Form.Item>
        <FormFooter
          onCancel={onCancelClick}
          okText='Выставить'
        />
      </Form>
    </Modal>
  )
}