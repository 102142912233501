// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--knDNs{margin-bottom:20px;width:calc(100% - 10px)}.styles-module--addButton--YliQj{margin-top:20px}.styles-module--addButton--YliQj+.styles-module--addButton--YliQj{margin-left:20px}.styles-module--main--0wm1a{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:calc(100vh - 90px)}.styles-module--tableContainer--d8OOx{margin-top:20px;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;overflow:hidden}.styles-module--legalNameLink--\\+504b{width:100%;height:100%}.styles-module--table--RpGLb{cursor:pointer}.styles-module--searchHeader--lJa3s{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:1rem}.styles-module--searchHeader--lJa3s .styles-module--searchHeaderSelect--p8055{min-width:7.5rem}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/styles.module.sass"],"names":[],"mappings":"AAAA,4BACI,kBAAA,CACA,uBAAA,CAEJ,iCAII,eAAA,CAHA,kEACI,gBAAA,CAIR,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,yBAAA,CAEJ,sCACI,eAAA,CACA,kBAAA,CAAA,iBAAA,CAAA,aAAA,CACA,eAAA,CAEJ,sCACI,UAAA,CACA,WAAA,CAEJ,6BACI,cAAA,CAEJ,oCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CACA,8EACI,gBAAA","sourcesContent":[".card\n    margin-bottom: 20px\n    width: calc(100% - 10px)\n\n.addButton\n    & + .addButton\n        margin-left: 20px\n\n    margin-top: 20px\n\n.main\n    display: flex\n    flex-direction: column\n    height: calc(100vh - 90px)\n\n.tableContainer\n    margin-top: 20px\n    flex: 1 1 auto\n    overflow: hidden\n\n.legalNameLink\n    width: 100%\n    height: 100%\n\n.table\n    cursor: pointer\n\n.searchHeader\n    display: flex\n    align-items: center\n    gap: 1rem\n    .searchHeaderSelect\n        min-width: 7.5rem\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--knDNs",
	"addButton": "styles-module--addButton--YliQj",
	"main": "styles-module--main--0wm1a",
	"tableContainer": "styles-module--tableContainer--d8OOx",
	"legalNameLink": "styles-module--legalNameLink--+504b",
	"table": "styles-module--table--RpGLb",
	"searchHeader": "styles-module--searchHeader--lJa3s",
	"searchHeaderSelect": "styles-module--searchHeaderSelect--p8055"
};
export default ___CSS_LOADER_EXPORT___;
