import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'

const ShiftsContext = createContext<any>({})

const selector = (state: State) => {
  const {shifts} = state.shiftsReducer

  const isShiftLoading = state.requestsReducer.fetchShifts === StatusRequest.LOADING
  const isAddShiftLoading = state.requestsReducer.addShift === StatusRequest.LOADING
  const isShiftsLoading = isShiftLoading || isAddShiftLoading

  return {
    shifts,
    isShiftsLoading,
  }
}

export const ShiftsContextProvider = ({children}: { children: React.ReactNode }) => {
  const {shifts, isShiftsLoading} = useSelector(selector)

  return (
    <ShiftsContext.Provider value={{shifts, isShiftsLoading}}>
      {children}
    </ShiftsContext.Provider>
  )
}

export const useShiftsContext = () => {
  const context = useContext(ShiftsContext);

  if (!context) {
    throw new Error('ShiftsContext is not reachable.')
  }

  return context;
}

