import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { fetchAllBrandModels } from 'AC/directory/directory'
import { changeFleetCar, deleteFleetCar } from 'AC/fleets/fleetsCars'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'


const { Option } = Select

type Props = {
  onClose: () => void
  isOpen: boolean
  fleetCar: APITYPES.FleetCar | null
  setEditModal: any
}

const formLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  fleets: state.fleetsReducer.fleets,
  allBrands: state.directoryReducer.carBrands,
  brandModels: state.directoryReducer.carBrandModels,
  isModelsLoading: state.requestsReducer.fetchAllBrandModels === StatusRequest.LOADING,
})

export const EditFleetCarModal = ({ onClose, isOpen, fleetCar, setEditModal }: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { allBrands, brandModels, isModelsLoading, fleets } = useSelector(selector)
  const [selectedBrandId, setBrandId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!selectedBrandId) {return}
    dispatch(fetchAllBrandModels(selectedBrandId))
  }, [selectedBrandId])


  useEffect(() => {
    if (fleetCar) {
      if (fleetCar?.car_brand_id) {dispatch(fetchAllBrandModels(fleetCar.car_brand_id + ''))}
    }
  }, [fleetCar])

  const onFinish = (values: any) => {
    if (!fleetCar) {return}

    if (fleetCar.fleet_id === values.fleet_id) {
      dispatch(changeFleetCar(fleetCar.id, { car_model_id: values.car_model_id }))
    } else if (fleetCar.fleet_id !== values.fleet_id) {
      dispatch(changeFleetCar(fleetCar.id, {
        fleet_id: values.fleet_id,
        car_model_id: values.car_model_id,
      }))
    }

    onClose()
  }

  const onDelete = () => {
    if (!fleetCar) {return}
    dispatch(deleteFleetCar(fleetCar.id))
    onClose()
  }
  const onValueChange = () => {
    form.setFieldValue('car_model_id', '')
  }

  return (
    <Modal
      title='Изменение автомобиля'
      visible={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      footer={false}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          label='Оператор'
          name='fleet_id'
          initialValue={fleetCar?.fleet_id}
        >
          <Select showSearch>
            {
              fleets?.map((brand) => (
                <Option
                  key={brand.id}
                  value={brand.id}
                >
                  { brand.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          label='Марка'
          name='car_brand_id'
          initialValue={fleetCar?.car_brand_id}
        >
          <Select
            onChange={
              (e) => {
                dispatch(fetchAllBrandModels(e + ''))
                onValueChange()
              }
            }
            showSearch
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              allBrands?.map((brand) => (
                <Option
                  key={brand.id}
                  value={brand.id}
                >
                  { brand.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Модель'
          name='car_model_id'
          initialValue={fleetCar?.car_model_id}
          rules={required}
        >
          <Select
            showSearch
            disabled={isModelsLoading}
            loading={isModelsLoading}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              brandModels?.map((model) => (
                <Option
                  key={model.id}
                  value={model.id}
                >
                  { model.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <FormFooter
          okText='Изменить'
          onCancel={onClose}
          leftElement={
            <Button
              danger
              type='primary'
              onClick={onDelete}
            >
              Удалить
            </Button>
          }
        />
      </Form>
    </Modal>
  )
}
