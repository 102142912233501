import React, { useEffect, useState } from 'react'
import { Button, Tabs, PageHeader, Tag } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchPartner } from 'AC/partners/partners'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { Status } from '../../Status'
import { CreateCard } from 'App/PrivateRoutes/Partners/Carwashes/Card/CreateCard'

import { Off } from './Off'
import { PartnerInfo } from './PartnerInfo'
import styles from './styles.module.sass'


const { TabPane } = Tabs

const selector = (state: State) => {
  const { partner } = state.partnersReducer
  const { fetchPartner } = state.requestsReducer

  return {
    partner,
    isLoading: fetchPartner === StatusRequest.LOADING,
  }
}

export const Partner = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()

  const [isCreate, setIsCreate] = useState(false)

  useEffect(()=>{
    dispatch(fetchPartner(id))
  }, [id, dispatch])
      
  const { partner } = useSelector(selector)

  const [ isOffModal, setIsOffModal ] = useState(false)

  if (!partner) {
    return <Loader />
  }

  const onCreateCarwash = () => {
    dispatch(action(Actions.SET_PARTNER_ID_FOR_CREATING, partner.id))
    dispatch(action(Actions.SET_IS_CARWASH_CREATING, true))
    setIsCreate(true)
  }

  return (
    <div className={styles.main}>
      <PageHeader
        className={styles.titleBlock}
        onBack={() => history.push(ROUTES.PARTNERS.path)}
        title={
          <div className={styles.title}>
            { partner.legal_name }
            <div className={styles.tagBlok}>
              <Status
                value={partner}
              />
              {
                Boolean(partner.state.reason?.length) && (
                  <Tag
                    color='blue'
                    className={styles.reason}
                  >
                    { 'причина: ' + partner.state.reason }
                  </Tag>
                )
              }
            </div>
          </div>
        }
        extra={
          (
            <div className={styles.buttonsBlock}>
              <div className={styles.addButton}>
                <Button
                  type='primary'
                  onClick={onCreateCarwash}
                >
                  Добавить объект
                </Button>
              </div>
            </div>
          )
        }
      />
      <Tabs
        defaultActiveKey='1'
      >
        <TabPane
          tab='Информация'
          key='1'
        >
          <PartnerInfo partner={partner} />
          <Off
            onCancel={()=>setIsOffModal(false)}
            isVisible={isOffModal}
            id={id}
          />
        </TabPane>
      </Tabs>
      <CreateCard
        isVisible={isCreate}
        onCancel={() => setIsCreate(false)}
      />
    </div>
  )
}