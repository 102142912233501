import React, { useState } from 'react'
import { Dropdown, Menu, Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { APITYPES } from 'types/apitypes'
import { ChangePenaltyStatusModal } from '../ChangeStatusModal'

import { getAllowedStatusArray, getPenaltyStatusColor, getPenaltyStatusText, isDropdownDisabled } from './utils'
import styles from './styles.module.sass'


export type Props = {
  penalty: APITYPES.CarwashPenalty
  onCallback?: (comment: string, status: string) => void
}

export const Status = (props: Props) => {
  const { penalty, onCallback, penalty: { state } } = props

  const [changeStatusModalState, setChangeStatusModalState] = useState<{
    isOpen: boolean
    newStatus: string | null
  }>({
    isOpen: false,
    newStatus: null,
  })

  const onTagClick = (newStatus: string) => {
    setChangeStatusModalState({
      isOpen: true,
      newStatus,
    })
  }

  const getMenu = (status: string) => {
    const allowedItems = getAllowedStatusArray(status)
    
    return (
      <Menu className={styles.menu}>
        {
          allowedItems.map((item) => (
            <Menu.Item
              key={item}
              onClick={() => onTagClick(item)}
            >
              <Tag
                color={getPenaltyStatusColor(item)}
                className={styles.tag}
              >
                { getPenaltyStatusText(item) }
              </Tag>
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  return (
    <>
      <div className={styles.container}>
        <Dropdown
          overlay={getMenu(state.name)}
          trigger={['click']}
          disabled={isDropdownDisabled(state.name)}
        >
          <a
            className='ant-dropdown-link'
            onClick={(e) => e.preventDefault()}
          >
            <Tag
              color={getPenaltyStatusColor(state.name)}
            >
              { getPenaltyStatusText(state.name) }
            </Tag>
            { '  ' }
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
      <ChangePenaltyStatusModal
        modalTitle='Вы точно хотите изменить статус штрафа?'
        newStatus={changeStatusModalState.newStatus}
        carwashPenalty={penalty}
        isVisible={changeStatusModalState.isOpen}
        onCallback={onCallback}
        onCancel={
          () => setChangeStatusModalState({
            isOpen: false,
            newStatus: null,
          })
        }
      />
    </>
  )
}
