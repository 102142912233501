import React from 'react'
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popover,
  Select,
} from 'antd'

import { APITYPES } from 'types/apitypes'
import { MomentType } from '../../Doers/ShiftsPage/components/AddShiftModal'
import {
  backDateFormat,
  dateFormat,
} from '../../../../utils/dateUtils'

import { Partner } from './Partner'
import { Status } from './Status'

import CarwashLocation = APITYPES.CarwashLocation
import RentalObjectType = APITYPES.RentalObjectType


export const getColumns = () => {
  return (
    [
      {
        title: 'Адрес',
        dataIndex: 'location',
        fixed: 'left',
        width: 174,
        render: (value: Partial<CarwashLocation> | undefined) => value?.address,
      },
      {
        title: 'Регион',
        dataIndex: 'location',
        width: 152,
        render: (value: Partial<CarwashLocation> | undefined) => value?.region,
      },
      {
        title: 'Статус',
        dataIndex: 'state',
        key: 'state',
        width: 150,
        render: (value: APITYPES.Carwash) => (
          <Status
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'Партнер',
        dataIndex: 'partner',
        key: 'partner_id',
        width: 174,
        render: (value: APITYPES.Carwashes.Get.Resp[number]) => (
          <Partner
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'График работы',
        dataIndex: 'working_time',
        key: 'working_time',
        width: 150,
      },
      {
        title: 'Способ владения',
        dataIndex: 'own_type',
        key: 'own_type',
        width: 150,
      },
      {
        title: 'Формат сотрудничества',
        dataIndex: 'cooperation_type',
        key: 'cooperation_type',
        width: 150,
      },
      {
        title: 'Направления работы',
        dataIndex: 'client_type',
        key: 'client_type',
        width: 150,
        render: (value: APITYPES.Carwashes.Get.Resp[number]) => {
          const content = (
            <div>
              { value.client_types?.map((item, i) => (<p key={i}>{ item }</p>)) }
            </div>
          )

          return (
            <Popover
              content={content}
              title={false}
              trigger='click'
            >
              <Button
                type='link'
                disabled={!value.client_types?.length}
                onClick={(e) => e.stopPropagation()}
              >
                Показать
              </Button>
            </Popover>
          )
        },
      },
    ]
  )
}

export const getRows = (carwashes: APITYPES.Carwashes.Get.Resp) => {
  return carwashes.map((carwash) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state' || column.dataIndex === 'partner') {
        return
      }
      //@ts-ignore
      row[column.dataIndex] = carwash[column.dataIndex]
      row.key = carwash.id

      return
    })

    row.partner = carwash
    row.state = carwash
    row.client_type = carwash

    return row
  })
}

export const getRentalObjectDynamicFields = (rentalObjectType: RentalObjectType, wrapperName?: string) =>
  rentalObjectType.fields.map(({
    type,
    required,
    name,
    label,
    values,
    default: defaultValue,
  }) => {
    let inputElement

    switch (type) {
      case 'string':
        inputElement = <Input />
        break
      case 'boolean':
        inputElement = <Checkbox>{ label }</Checkbox>
        break
      case 'integer':
        inputElement = (
          <InputNumber
            type='number'
            controls={false}
          />
        )
        break
      case 'float':
        inputElement = (
          <InputNumber
            type='number'
            precision={2}
            controls={false}
          />
        )
        break
      case 'enum':
        inputElement = (
          <Select>
            {
              values?.map((value) => (
                <Select.Option
                  key={value}
                  value={value}
                >
                  { value }
                </Select.Option>
              ))
            }
          </Select>
        )
        break
      case 'date':
        inputElement = <DatePicker format={dateFormat} />
        break
      default: return null
    }

    return (
      <Form.Item
        key={name}
        rules={[{ required }]}
        name={wrapperName ? [wrapperName, name] : name}
        label={
          type !== 'boolean'
            ? label
            : undefined
        }
        initialValue={defaultValue ?? undefined}
        valuePropName={
          type === 'boolean'
            ? 'checked'
            : undefined
        }
        getValueProps={
          type === 'date'
            ? (value) => value
            : undefined
        }
        normalize={
          type === 'date'
            ? (value: MomentType) => value.format(backDateFormat)
            : undefined
        }
        wrapperCol={
          type === 'boolean' ? {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          } : undefined
        }
      >
        { inputElement }
      </Form.Item>
    )
  })