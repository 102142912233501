import React, { useState } from 'react'
import { Button, Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { getTableYScroll } from 'utils/scrollUtils'

import { AddServiceForm } from './AddServiceForm'
import { EditServiceForm } from './EditServiceForm'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


type Props = {
  services: APITYPES.Services.Get.Resp
  allServices: APITYPES.AllServices.Get.Resp
  isLoading?: boolean
}

export const Services = (props: Props) => {
  const { services, allServices, isLoading = false } = props

  const [isAddServiceModal, setAddServiceModal] = useState(false)
  const toggleAddServiceModal = () => setAddServiceModal(!isAddServiceModal)

  const [isEditServiceModal, setEditServiceModal] = useState<{
    isOpen: boolean
    service: APITYPES.Services.Get.Resp[number] | null
  }>({
    isOpen: false,
    service: null,
  })

  const openEditServiceModal = (service: APITYPES.Services.Get.Resp[number]) => setEditServiceModal({
    isOpen: true,
    service: service,
  })
  const closeEditServiceModal = () => setEditServiceModal({
    isOpen: false,
    service: null,
  })
  
  return (
    <>
      <AddServiceForm
        isVisible={isAddServiceModal}
        onCancel={toggleAddServiceModal}
        services={services}
        allServices={allServices}
      />
      <Button
        type='primary'
        className={styles.addButton}
        onClick={toggleAddServiceModal}
      >
        Привязать услугу
      </Button>

      <Table
        sticky
        columns={getColumns() as any}
        dataSource={getRows(services)}
        bordered
        size='middle'
        pagination={false}
        loading={isLoading}
        className={styles.table}
        scroll={{ y: getTableYScroll(321.5) }}
        onRow={(record: any) => ({ onClick: () => openEditServiceModal(record.service) })}
      />

      <EditServiceForm
        isVisible={isEditServiceModal.isOpen}
        onCancel={closeEditServiceModal}
        service={isEditServiceModal.service}
      />
    </>
  )
}