import React from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router'
import { ROUTES } from 'config/routes'


export const Error = () => {
  const history = useHistory()

  const toMain = () => history.push(ROUTES.ROOT.path)

  return (
    <Result
      status="403"
      title="403"
      subTitle="Извините, у вас нет доступа к этой странице."
      extra={<Button type="primary" onClick={toMain}>На главную</Button>}
    />
  );
};
