import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Divider, Form, Image as AntdImage, Select, Space, Tooltip } from 'antd'
import { DownloadOutlined, EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { CardDataType, GeneralType, ShiftType } from '../utils'
import { Loader } from 'components/Loader'
import { CustomEmpty } from 'components/CustomEmpty'
import { getVideoFileFromUrl } from 'utils/fileUtils'
import { useLocationForm } from './hooks/useLocationForm'
import { formatDateTime } from 'utils/formatDateTime'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'

import styles from './styles.module.sass'
import { ReportsBlock } from './components'


const LocTypes = APITYPES.ShiftLocationsTypes
const { Cities } = APITYPES

type Props = {
  cardData: CardDataType
}

export const CardContent = (props: Props) => {
  const { cardData } = props

  return (
    <div className={styles.content}>
      <PhotosBlock />
      <ReportsBlock reports={cardData.reports} />
    </div>
  )
}


export const GeneralBlock = (props: { general: GeneralType, shift: ShiftType }) => {
  const { general, shift } = props
  const timezone = useGetTimezoneFromLocalstorage()

  const shiftBeginDate = moment(shift.begin).format('DD.MM.YYYY')
  const shiftBeginTime = moment(shift.begin).format('HH:mm:ss Z')

  const shiftEndDate = moment(shift.end).format('DD.MM.YYYY')
  const shiftEndTime = moment(shift.end).format('HH:mm:ss Z')

  const [start, end] = formatDateTime({
    start: shiftBeginTime,
    end: shiftEndTime,
    targetTimezone: (shift.timezone ? shift.timezone : timezone),
  })

  const operationLocation = useMemo(() => {
    const city = Cities[general.location.city_id]
    const type = LocTypes[general.location.type as APITYPES.ShiftLocationsTypesOptions]
    const { name } = general.location

    return `${city}, ${type}, ${name}`
  }, [general.location])

  const {
    options,
    locationForm,
    editLocationMode,
    onLocationSearch,
    isOptionsLoading,
    onLocationSelect,
    onLocationEditClick,
    onLocationSelectBlur,
  } = useLocationForm()

  return (
    <div className={styles.blockContainer}>
      <div className={styles.block}>
        <div className={styles.left}>
          <span>Смена: </span>
          <span>Локация: </span>
        </div>

        <div className={styles.right}>
          <span>
            { shift?.shift_type_name }
            { ' ' }
            (
            { `${shiftBeginDate}, ${start} - ${shiftEndDate}, ${end}` }
            )
          </span>
          <span>
            <Space size={20}>
              {
                !editLocationMode ? (
                  <div>
                    <span>
                      { operationLocation }
                    </span>
                    <EditOutlined
                      className={styles.selectIcon}
                      onClick={onLocationEditClick}
                    />
                  </div>
                ) : (
                  <Form form={locationForm}>
                    <Form.Item
                      noStyle
                      name='location_id'
                      className={styles.select}
                      initialValue={editLocationMode ? null : general.location.name}
                    >
                      <Select
                        open
                        autoFocus
                        showSearch
                        defaultOpen
                        size='small'
                        filterOption={false}
                        autoClearSearchValue
                        className={styles.select}
                        placeholder='Начните вводить адрес...'
                        loading={isOptionsLoading}
                        onSelect={onLocationSelect}
                        onSearch={onLocationSearch}
                        onBlur={onLocationSelectBlur}
                      >
                        {
                          options?.map((option) => (
                            <Select.Option
                              key={option.id}
                              value={option.id}
                              newLocation={option}
                            >
                              { `${APITYPES.Cities[option.city_id]}, ${option.name}` }
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Form>
                )
              }
            </Space>


          </span>
        </div>
      </div>
    </div>
  )
}

const photoBlockSelector = (state: State) => ({
  photos: state.operationsReducer.operationFiles,
  isPhotosLoading: state.requestsReducer.fetchOperationTasks === StatusRequest.LOADING,
})
const PhotosBlock = () => {
  const { photos, isPhotosLoading } = useSelector(photoBlockSelector)

  return (
    <div className={styles.blockContainer}>
      <Divider className={styles.divider}><span className={styles.title}>Фото/видео отчет</span></Divider>

      <div className={styles.block}>
        {
          isPhotosLoading
            ? <Loader className={styles.loader} />
            : photos.length === 0
              ? (
                <CustomEmpty
                  text='Нет фото/видео'
                  className={styles.empty}
                />
              )
              : (
                <div className={styles.photosWrapper}>
                  <AntdImage.PreviewGroup>
                    {
                      photos.map((item) => (
                        <ImageOrVideo
                          item={item}
                          key={item.file_id}
                        />
                      ))
                    }
                  </AntdImage.PreviewGroup>
                </div>
              )
        }
      </div>
    </div>
  )
}


type ImageOrVideoProps = {
  item: APITYPES.OperationFile
}

type VideoFileState = {
  data: File | null
  type: string | null
  stream: any
}
const ImageOrVideo = ({ item }: ImageOrVideoProps) => {
  const SRC_URL = `/v1/files/${item.file_id}`
  const [file, setFile] = useState<VideoFileState>({
    data: null,
    type: null,
    stream: null,
  })

  useEffect(() => {
    getVideoFileFromUrl(SRC_URL, item.file_id)
      .then(({ file, stream }) => setFile({
        data: file,
        type: file.type,
        stream,
      }))
  }, [])

  /** video */
  const videoRef = useRef<HTMLVideoElement>(null)
  const toggleFullScreen = () => {
    if (!videoRef.current) {return}
    videoRef.current.requestFullscreen()
  }

  const isVideo = useMemo(() => file?.type && ['image/mp4', 'video/mp4'].includes(file?.type), [file?.type])
  const isPhoto = useMemo(() => file?.type && ['image/jpg', 'image/png', 'image/jpeg'].includes(file?.type), [file?.type])

  return (
    <Tooltip
      mouseLeaveDelay={0}
      mouseEnterDelay={0}
      overlay={item.task_element_name}
      key={item.file_id}
    >
      <div className={styles.photo}>
        { !file?.type && <Loader circleIcon /> }

        {
          isPhoto && (
            <div className={styles.videoWrapper}>
              <AntdImage
                src={file.stream}
                width={85}
                height={85}
              />
              <a
                id={item.file_id}
                href={file.stream}
                download={`${file.data?.name}`}
                className={styles.videoLink}
              >
                <DownloadOutlined />
              </a>
            </div>
          )
        }

        {
          isVideo && (
            <div className={styles.videoWrapper}>
              <video
                ref={videoRef}
                src={file.stream}
                width={85}
                height={85}
                onClick={toggleFullScreen}
                controlsList='nodownload'
              />
              <a
                id={item.file_id}
                href={file.stream}
                download={`${file.data?.name}`}
                className={styles.videoLink}
              >
                <DownloadOutlined />

              </a>
            </div>
          )
        }
      </div>
    </Tooltip>
  )
}
