import React from 'react'
import moment from 'moment-timezone'

import { getEnumKeys } from 'utils/enumUtils'
import { APITYPES } from 'types/apitypes'
import { backDateFormat, dateFormat } from '../../../../utils/dateUtils'

import { DeleteRecordButton } from './DeleteButton'


export enum RecordState {
  not_billed = 'not_billed',
  accepted = 'accepted',
  paid = 'paid',
}

export enum RecordStateTranslation {
  not_billed = 'Вне счета',
  accepted = 'Согласовано',
  paid = 'Оплачено',
}

export const RecordStateOptions = getEnumKeys(RecordState)

const formatDate = (date: string) => {
  return moment(date).format(dateFormat)
}

export const getColumns = (records?: any, setRecords? : any ) => (
  [
    {
      title: 'Период расчета',
      dataIndex: 'period',
      map: (item: any) => {
        return  `${formatDate(item.billing_begin)}\n${formatDate(item.billing_end)}`
      },
      width: 120 as const,
    },
    {
      title: 'Дата',
      dataIndex: 'billing_date',
      key: 'billing_date',
    },
    {
      title: 'ФИО',
      dataIndex: 'contractor_name',
      key: 'contractor_name',
    },
    {
      title: 'Город',
      dataIndex: 'city_name',
      key: 'city_name',
    },
    {
      title: 'Группа ролей',
      dataIndex: 'role_group_name',
      key: 'role_group_name',
    },
    {
      title: 'Роль',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Услуга',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Город',
      dataIndex: 'city_name',
      key: 'city_name',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Сумма',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Статус',
      dataIndex: 'bill_state_name',
      key: 'bill_state_name',
      map: (item: any) => {
        return RecordStateTranslation[item.bill_state_name as keyof typeof RecordState]
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center' as const,
      width: 70 as const,
      render: (_: any, record:any) => {
        return (
          <DeleteRecordButton
            doerId={record.doerId}
            records={records}
            setRecords={setRecords}
          />
        )
      },
    },
  ])

export const getRows = (doerRecords: APITYPES.DoerRecords[]) => {
  const data = doerRecords.map((record) => {
    const row: any = {}

    getColumns().forEach((column) => {
      if (column.map) {
        row[column.dataIndex] = column.map(record)

        return
      }
      //@ts-ignore
      row[column.dataIndex] = record[column.dataIndex]
    })
    row.key = record.id
    row.doerId = record.id

    return row
  })

  return data
}

export const mapFilterToQuery = (values: any) => {
  const formValues: any = Object.entries(values).reduce((acc, [key, value]: any) => {
    return {
      ...acc,
      [key]: value.value,
    }
  }, {})


  let body = {
    type: undefined,
    role_id: undefined,
    billing_begin: undefined,
    billing_end: undefined,
    employer_name: undefined,
    begin: undefined,
    end: undefined,
    signer: undefined,
    state: formValues.state,
    department_name: formValues.department_name,
    role_group_name: formValues.role_group_name,
    contractor_id: formValues.contractor_id,
    city_id: formValues.city_id,
  }

  if (formValues.dates && formValues.period) {
    const [begin, end] = formValues.dates
    const [billing_begin, billing_end] = formValues.period.split('/')

    body = {
      ...body,
      begin: begin.format(backDateFormat),
      end: end.format(backDateFormat),
      billing_begin: billing_begin,
      billing_end: billing_end,
    }
  } else if (formValues.dates) {
    const [begin, end] = formValues.dates

    body = {
      ...body,
      begin: begin.format(backDateFormat),
      end: end.format(backDateFormat),
    }
  } else if (formValues.period) {
    const [billing_begin, billing_end] = formValues.period.split('/')

    body = {
      ...body,
      billing_begin: billing_begin,
      billing_end: billing_end,
    }
  }

  if (formValues.role_id) {
    body = {
      ...body,
      role_id: formValues.role_id,
    }
  }

  if (formValues.type) {
    body = {
      ...body,
      type: formValues.type,
    }
  }

  if (formValues.employer_name) {
    body = {
      ...body,
      employer_name: formValues.employer_name,
    }
  }

  if (formValues.signer) {
    body = {
      ...body,
      signer: formValues.signer,
    }
  }

  return body
}