// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--DHWk2{cursor:pointer}.styles-module--toolbar--hhq\\+9{margin-top:-8px;margin-bottom:12px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/styles.module.sass"],"names":[],"mappings":"AAAA,6BACE,cAAA,CAEF,gCACE,eAAA,CACA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".table\n  cursor: pointer\n\n.toolbar\n  margin-top: -8px\n  margin-bottom: 12px\n  display: flex\n  justify-content: space-between\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--DHWk2",
	"toolbar": "styles-module--toolbar--hhq+9"
};
export default ___CSS_LOADER_EXPORT___;
