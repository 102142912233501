import React, { FC } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { UploadFiles } from 'shared/ui/UploadFiles'
import { State } from 'stores/rootReducer'
import { addReportFile } from 'AC/urent/reports'

import styles from './styles.module.sass'


interface Props {
  onSubmit: () => void
  reportStep?: () => void
  reportId?: string
}
const select = (state: State) => state.urentReducer.uploadedReport

export const UploadOperationReportFile: FC<Props> = ({ onSubmit, reportId, reportStep }) => {
  const report = useSelector(select)
  const dispatch = useDispatch()


  return (
    <div className={styles.wrapper}>
      <section className={styles.uploading}>
        <UploadFiles
          title='Загрузите файл'
          acceptingFiles='.xlsx'
          onFileUpload={(file) => dispatch(addReportFile(file))}
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!report}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
