import React from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { addService } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
  services: APITYPES.Services.Get.Resp
  allServices: APITYPES.AllServices.Get.Resp
}

const { useForm } = Form

export const AddServiceForm = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const { services, allServices, isVisible, onCancel } = props
  const [form] = useForm()

  const dispatch = useDispatch()

  const onFinish = async (values: { service: string }) => {
    const service = allServices.find((item)=>item.id === values.service)

    if (!service) {return}

    dispatch(addService(service, id))

    onCancel()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Привязать'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      afterClose={afterClose}
    >
      <Form
        name='services'
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='service'
          label='Услуга'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select
            showSearch
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              allServices.map((service)=>{
                if (!services.map((item)=>item.id).find((id)=> id === service.id)) {
                  return (
                    <Select.Option
                      key={service.id}
                      value={service.id}
                    >
                      { service.name }
                    </Select.Option>
                  )
                }

                return
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Привязать услугу'
        />
      </Form>
    </Modal>
  )
}