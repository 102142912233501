import React from 'react'
import { Modal, Form, Input, DatePicker, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import { updateDocument } from 'AC/partners/updateDocument'

import styles from './styles.module.sass'


type Props = {
  documentId: string
  isVisible: boolean
  onCancel: () => void
}

const { useForm } = Form

const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD-MM-YYYY'

const selector = (state: State) => {
  const { partner } = state.partnersReducer

  return { partner }
}

export const EditDocument = (props: Props) => {
  const { partner } = useSelector(selector)

  const { isVisible, onCancel, documentId } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const documnetObj = partner?.documents?.find((item)=>item.id === documentId)

  const [ form ] = useForm()

  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    dispatch(updateDocument({
      ...values,
      date: values.date ? values.date.format(dateFormat) : undefined,
    }, documentId))

    onCancel()
  }

  return (
    <Modal
      className={styles.modal}
      title='Редактирование документа'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='edit_document'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='type'
          label='Тип договора'
          initialValue={documnetObj?.type}
        >
          <Select showSearch>
            <Select.Option value='Договор аренды оборудования'>Договор аренды оборудования</Select.Option>
            <Select.Option value='Договор возмездного оказания услуг'>
              Договор возмездного оказания услуг
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='number'
          label='№ договора'
          initialValue={documnetObj?.number}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Дата заключения договора'
          name='date'
          initialValue={documnetObj?.date ? moment(documnetObj?.date, dateFormat) : undefined}
        >
          <DatePicker format={viewDateFormat} />
        </Form.Item>
        <Form.Item
          name='link'
          label='Ссылка на договор'
          initialValue={documnetObj?.link}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='payment_type'
          label='Порядок оплаты'
          initialValue={documnetObj?.payment_type}
        >
          <Select showSearch>
            <Select.Option value='Постоплата'>Постоплата</Select.Option>
            <Select.Option value='Предоплата'>Предоплата</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='act_frequency'
          label='Периодичность актирования'
          initialValue={documnetObj?.act_frequency}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='payment_delay_period'
          label='Период отсрочки платежа'
          initialValue={documnetObj?.payment_delay_period}
        >
          <Input />
        </Form.Item>
        <FormFooter
          okText='Сохранить'
          onCancel={onCancel}
          position='right'
        />
      </Form>
    </Modal>
  )
}