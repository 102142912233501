import React, { useEffect, useState } from 'react'
import { Card, Transfer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchAllServices } from 'AC/directory/directory'
import { addServiceToProduct, deleteServiceFromProduct } from 'AC/products/products'
import { Loader } from 'components/Loader'

import { getSourceColumn, getTargetKeys, listStyle, transferTitles } from './utils'
import styles from './styles.module.sass'

type Props = {
  productId: string
  services: APITYPES.ProductService[] | null
}

const selector = (state: State) => ({
  allServices: state.directoryReducer.services,
  isLoading: state.requestsReducer.fetchAllServices === StatusRequest.LOADING
})

export const ProductServices = ({productId, services}: Props) => {
  const dispatch = useDispatch()
  const {allServices, isLoading} = useSelector(selector)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!allServices) dispatch(fetchAllServices())
  }, [])

  const onChange = async (_: string[], direction: string, selectedServices: string[]) => {
    setLoading(true)
    switch (direction) {
      case 'right':
        await addServices(selectedServices)
        break
      case 'left':
        await deleteServices(selectedServices)
        break
    }
  }

  const addServices = async (addServices: string[]) => {
    dispatch(addServiceToProduct(productId, addServices, () => setLoading(false)))
  }

  const deleteServices = async (deleteServices: string[]) => {
    dispatch(deleteServiceFromProduct(productId, deleteServices, () => setLoading(false)))
  }

  return (
    <Card className={styles.container} title="Сервисы" size="small">
      <Transfer
        showSearch
        className={styles.transfer}
        titles={transferTitles}
        showSelectAll={false}
        selectAllLabels={[<></>, <></>]}
        listStyle={listStyle}
        onChange={onChange}
        dataSource={getSourceColumn(allServices || [])}
        targetKeys={getTargetKeys(services || [])}
        render={(item) => item.title}
        filterOption={(inputValue, item) => item?.title?.toLowerCase().includes(inputValue)}
      >
        {() => (loading || isLoading) && <div className={styles.loader}><Loader/></div>}
      </Transfer>
    </Card>
  );
};
