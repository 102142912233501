import React, { CSSProperties } from 'react'
import { Empty } from 'antd'
import cn from 'classnames'

import styles from './styles.module.sass'

type Props = {
  className?: string
  text?: string
  image?: React.ReactNode
  style?: CSSProperties
}

export const CustomEmpty = (props: Props) => {
  const {
    className,
    image = Empty.PRESENTED_IMAGE_SIMPLE,
    text = 'No data',
    style
  } = props

  const emptyStyle = cn(styles.empty, className)

  return <Empty
    className={emptyStyle}
    image={image}
    description={text}
    style={style}
  />

}
