import { useQuery } from '@tanstack/react-query'
import {
  Col,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import React from 'react'

import { getMailing } from '../../../../../../AC/doers/mailing'


type Props = {
  id: string
}

export const MailingDetails = ({ id }: Props) => {
  const { isLoading, data } = useQuery(['getMailing', id], () => getMailing(id))
  const { filters, recipients } = data ?? {}

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Row>
      <Col span={8}>
        <Space direction='vertical'>
          <Typography.Text>
            Статистика:
          </Typography.Text>
          <Typography.Text>
            { `Отправлено: ${recipients?.sent}` }
          </Typography.Text>
          <Typography.Text>
            { `Ожидает отправки: ${recipients?.awaiting_sending}` }
          </Typography.Text>
          <Typography.Text>
            { `Отменено: ${recipients?.canceled}` }
          </Typography.Text>
        </Space>
      </Col>
      <Col span={16}>
        <Space direction='vertical'>
          <Typography.Text >
            Фильтры:
          </Typography.Text>
          {
            filters?.map((filter, index) => (
              <Typography.Text key={index}>
                { `${filter.role} ${filter.city}` }
              </Typography.Text>
            ))
          }
        </Space>
      </Col>
    </Row>
  )
}
