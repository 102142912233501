// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".internships-filter-module--wrapper--VEgrL{margin:1.5rem 0;overflow:auto}.internships-filter-module--mainSection--pbZd6{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem;max-width:450px}.internships-filter-module--expandedSection--FH9Zf{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem;margin-top:1rem}.internships-filter-module--hidden--ixcLb{display:none}.internships-filter-module--formItem--oUARS{margin:0}.internships-filter-module--select--weAOg{min-width:180px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/internships/components/internships-filter/internships-filter.module.sass"],"names":[],"mappings":"AAAA,2CACE,eAAA,CACA,aAAA,CAEF,+CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CACA,eAAA,CAGF,mDAEE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CACA,eAAA,CAEF,0CACE,YAAA,CAEF,4CACE,QAAA,CAEF,0CACE,eAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA","sourcesContent":[".wrapper\n  margin: 1.5rem 0\n  overflow: auto\n\n.mainSection\n  display: flex\n  gap: .5rem\n  max-width: 450px\n\n\n.expandedSection\n\n  display: flex\n  gap: .5rem\n  margin-top: 1rem\n\n.hidden\n  display: none\n\n.formItem\n  margin: 0\n\n.select\n  min-width: 180px\n  width: fit-content"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "internships-filter-module--wrapper--VEgrL",
	"mainSection": "internships-filter-module--mainSection--pbZd6",
	"expandedSection": "internships-filter-module--expandedSection--FH9Zf",
	"hidden": "internships-filter-module--hidden--ixcLb",
	"formItem": "internships-filter-module--formItem--oUARS",
	"select": "internships-filter-module--select--weAOg"
};
export default ___CSS_LOADER_EXPORT___;
