import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components/Loader'
import { deployYandexProReportById } from 'AC/yandexpro/reports'
import { State } from 'stores/rootReducer'


interface FinalStepProps {
  onFinish: () => void
}
export const FinalStep: FC<FinalStepProps> = ({ onFinish }) => {
  const dispatch = useDispatch()
  const report = useSelector((state: State) => state.yandexProReducer.report)

  useEffect(() => {
    if (report) {
      dispatch(deployYandexProReportById(report.id, onFinish, onFinish))
    }
  }, [])

  return (
    <div>
      <Loader />
    </div>
  )
}
