import React from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { blockDoer } from 'AC/doers/blockDoer'
import { confirmDoer } from 'AC/doers/confirmDoer'
import { showSureConfirm } from 'components/modals/SureModal'
import { Loader } from 'components/Loader'
import styles from '../styles.module.sass'

type Props = {
  doer: APITYPES.Doer
}

const selector = (state: State) => {
  const isFetchingDoerById = state.requestsReducer.getDoerById === StatusRequest.LOADING
  const isBlockDoerLoading = state.requestsReducer.blockDoer === StatusRequest.LOADING
  const isConfirmDoerLoading = state.requestsReducer.confirmDoer === StatusRequest.LOADING

  const isLoading = isBlockDoerLoading || isConfirmDoerLoading || isFetchingDoerById

  return {isLoading}
}

export const ExtraActions = ({doer}: Props) => {
  const dispatch = useDispatch()
  const {isLoading} = useSelector(selector)


  const onBlock = () => {
    showSureConfirm(() => {
      dispatch(blockDoer(doer.id))
    }, () => {
    })
  }

  const onConfirm = () => {
    showSureConfirm(() => {
        dispatch(confirmDoer(doer.id))
      },
      () => {
      })
  }

  if(isLoading) return <div className={styles.loaderWrapper}><Loader circleIcon noPadding size='large' /></div>

  return (
    <div className={styles.extra}>
      {
        doer.state.name !== 'blocked' && (
          <Button
            type="primary"
            onClick={onBlock}
            danger
          >
            Заблокировать
          </Button>
        )
      }
      {
        doer.state.name !== 'confirmed' && (
          <Button
            type="primary"
            onClick={onConfirm}
          >
            Подтвердить
          </Button>
        )
      }
    </div>
  )
}

