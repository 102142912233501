// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--photoBlock--5BSQF{padding:16px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Locations/EditLocationModal/Photos/styles.module.sass"],"names":[],"mappings":"AAAA,kCACE,YAAA","sourcesContent":[".photoBlock\n  padding: 16px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photoBlock": "styles-module--photoBlock--5BSQF"
};
export default ___CSS_LOADER_EXPORT___;
