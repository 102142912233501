const { searchParams } = new URL(location.href)

export const QUERY_TICKET_ID = searchParams.get('ticket_id') || ''
export const QUERY_SENTRY = searchParams.get('sentry') === 'test'
export const QUERY_PROMO = searchParams.get('promo') || ''
export const QUERY_CLIENT_TYPE = searchParams.get('utm_ct') || '1'

export const getUrlParam = (pathname: string, splitIndex: number): string => {
  const param = pathname.split('/').filter((el) => el)[splitIndex]

  return param
}

export const getUrlSearchParams = () => {
  return Object.fromEntries(new URL(window.location.href).searchParams.entries())
}

export const getStateByParams = (params: any) => {
  return {
    ...params,
    state: params.state?.length ? params.state?.split(',') : undefined,
  }
}

export const mapToQueryString = (obj: Record<string, any>): string =>{
  if (!obj || Object.keys(obj).length === 0) {
    return ''
  }

  const queryParams: string[] = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      if (value !== null && value !== undefined && value !== '') {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          })
        } else {
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      }
    }
  }


  return queryParams.join('&')
}

//TODO:need to check usage with V2 and remove if V2 is ok
export const filterEmptyValues = <T = Record<string, any>>(filter: T): Partial<T> => {
  return Object.entries(filter)
    .filter(([, v]) => v !== undefined && v !== '')
    .reduce((acc, [k, v]) => ({
      ...acc,
      [k]: v,
    }), {})
}

export const filterEmptyValuesV2 = <T = Record<string, any>>(filter: T): Partial<T> => {
  return Object.entries(filter)
    .filter(([, v]) => v !== undefined && v !== '' && v !== null)
    .reduce((acc, [k, v]) => ({
      ...acc,
      [k]: v,
    }), {})
}