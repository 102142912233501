import React, { useEffect, useMemo, useState } from 'react'
import { Form, Input, Modal, Select, Tree } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { fetchOperations } from 'AC/shifts/operations'
import { FormFooter } from 'components/FormFooter'
import { getDoers } from 'AC/doers/getDoers'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { apiV1 } from 'network/http'
import { addCustomFieldsMain, deepSearchChildren, getAllKeys, getTreeCustom } from '../Operation/OperationsTree/utils'
import { errorNotification, openNotification } from 'components/modals/OperationNotification'
import { DebounceSelect, fetchUserList, SelectValue } from 'components/DebounceSelectDoer'


type Props = {
  isOpen: boolean
  onClose: () => void
};

const formLayout = { labelCol: { span: 3 } }

const selector = (state: State) => ({
  doersStore: state.doersReducer,
  isLoading: state.requestsReducer.fetchOperation === StatusRequest.LOADING,
  isTasksLoading: state.requestsReducer.fetchOperationTasks === StatusRequest.LOADING,
})

interface IReqParams {
  taskId: string
  grz: string
  shiftId: string
  subTasks: []
}

export const OperationNewModal = (props: Props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const store = useSelector(selector)
  const [form] = Form.useForm()
  const getDoerShift = async (id: string) => {
    const res =
      await apiV1
        .get(`/v1/doers/${id}/shifts`, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })

    setCurrentShifts(res.data)
  }

  const [doer, setDoer] = useState<SelectValue | null>(null)
  const [tasks, setTasks] = useState<any>([])
  const [taskTree, setTaskTree] = useState<any>([])
  const [subTasks, setSubTasks] = useState<any>([])
  const [treeKeys, setTreeKeys] = useState<any>([])
  const [mainData, setMainData] = useState<any>()
  const [reqParams, setReqParams] = useState<IReqParams>({
    taskId: '',
    grz: '',
    shiftId: '',
    subTasks: [],
  })
  const [selectedOps, setSelectedOps] = useState<any>()
  const [checkedKeys, setCheckedKeys] = useState<Array<string>>([])

  const doerOptions =
    useMemo(() => store.doersStore.doers?.map(({ id, name, phone_number }) =>
      ({
        label: `${name} (${phone_number})`,
        value: id,
      })) ?? [], [store.doersStore.doers])

  const getTasks = async (id: string) => {
    try {
      const res = await apiV1.get('/v1/tasks', {
        params: { role_id: id },
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') },
      })

      setTasks(res.data)
    } catch (error) {
      console.log(error)
    }
  }
  const getTaskTree = async (id: string) => {
    try {
      const res =
        await apiV1.get(`/v1/tasks/${id}`, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })

      setSubTasks(res.data.subtasks)
      setMainData(res.data)
      setTaskTree(getTreeCustom(res.data))
    } catch (error) {
      console.log(error)
    }
  }

  const addOperation = async () => {
    const addedFieldNames =
      addCustomFieldsMain(deepSearchChildren(mainData, selectedOps.checked)?.subtasks, reqParams.grz, reqParams.shiftId)

    const body = {
      id: reqParams.taskId,
      doer_shift_id: reqParams.shiftId,
      subtasks: addedFieldNames,
    }

    if (reqParams.grz.length) {
      // @ts-ignore
      body.grz = reqParams.grz
    }

    try {
      const res = await apiV1
        .post('/v1/operations', body, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })

      if (res) {
        onClose()
        openNotification('Операция создана')
        dispatch(fetchOperations())
      }
    } catch (error) {
      errorNotification('Произошла ошибка')
    }
  }

  const [currentShifts, setCurrentShifts] = useState([])
  const [shift, setShift] = useState('')

  const onFinish = () => {
    addOperation()
  }

  useEffect(() => {
    dispatch(getDoers())
  }, [dispatch])

  useEffect(() => {
    setTreeKeys(getAllKeys(taskTree))
  }, [taskTree])

  useEffect(() => {
    if (doer?.value) {
      getDoerShift(doer.value)
    }
  }, [doer])

  useEffect(() => {
    if (shift.length > 0) {
      const result: any = currentShifts.find(({ id }) => id === shift)

      if (result !== undefined) {
        getTasks(result.role.id)
      }
    }
  }, [shift])

  const afterClose = () => {
    form.resetFields()
    setTaskTree([])
    setCheckedKeys([])
    setSelectedOps({})
  }

  return (
    <Modal
      title='Создание операции'
      visible={isOpen}
      onCancel={onClose}
      afterClose={afterClose}
      footer={false}
      width={800}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          name='name'
          label='Исполнитель'
        >
          <DebounceSelect
            placeholder='Выберите исполнителя'
            fetchOptions={fetchUserList}
            style={{ width: '100%' }}
            value={doer}
            defaultOptions={doerOptions}

            onChange={
              (newValue) => {
                setDoer(newValue as SelectValue)
              }
            }
          />
        </Form.Item>
        <Form.Item
          name='grz'
          label='Гос.номер'
        >
          <Input
            placeholder='Введите гос.номер'
            onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
            onChange={
              (e) => setReqParams((reqParams) => ({
                ...reqParams,
                grz: e.target.value,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          name='shift'
          label='Смена'
        >
          <Select
            showSearch
            placeholder='Выберите смену'
            disabled={currentShifts.length > 0 ? false : true}
            onChange={
              (e) => {
                setShift(e)
                setReqParams({
                  ...reqParams,
                  shiftId: e,
                })
              }
            }
            optionFilterProp='children'
          >
            {
              currentShifts && currentShifts.map((el: {
                id: string
                shift_type: any
                location: any
                date: any
              }) => (
                <Select.Option
                  value={el.id}
                  key={el.id}
                >
                  { el.date }
                  { ' ' }
                  { el.shift_type.type }
                  { ' ' }
                  { el.location.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='task'
          label='Задача'
        >
          <Select
            showSearch
            placeholder='Выберите задачу'
            disabled={currentShifts.length > 0 ? false : true}
            fieldNames={
              {
                label: 'title',
                value: 'id',
              }
            }
            onChange={
              (e: string) => {
                getTaskTree(e)

                setReqParams((reqParams) => ({
                  ...reqParams,
                  taskId: e,
                }))
              }
            }
            options={tasks ? tasks : []}
          />
        </Form.Item>
        {
          taskTree.length > 0
            ? (
              <Tree
                treeData={taskTree}
                checkable
                defaultExpandAll
                checkStrictly
                checkedKeys={checkedKeys}
                onCheck={
                  (e, info) => {
                    setSelectedOps(e)
                    // TODO: need refactoring
                    const { pos } = info.node
                    const { checked } = info

                    const positions = pos.split('-').map(Number)

                    positions.shift()

                    const titles: Array<string> = []
                    const removedKeys: Array<string> = []

                    // @ts-ignore
                    const recursionSelectRemoveKeys = (postions: any, taskTree: any, index = 0) => {
                      const postionfirstElement = positions?.[index]

                      if (Array.isArray(taskTree)) {
                        return recursionSelectRemoveKeys(positions, taskTree?.[postionfirstElement], index + 1)
                      }

                      if (postions?.length === index) {
                        const keys: Array<string> = []

                        const selectKeys = (taskTree: any) => {
                          keys.push(taskTree.key)

                          if (Array.isArray(taskTree.children)) {
                            return taskTree.children.map((task: any) => selectKeys(task))
                          }

                          return
                        }

                        if (Array.isArray(taskTree.children)) {
                          selectKeys(taskTree)
                          removedKeys.push(...keys)
                        } else {
                          removedKeys.push(taskTree?.key)
                        }

                        return
                      }

                      const task = taskTree?.children?.[postionfirstElement] ?? taskTree?.[postionfirstElement]

                      return recursionSelectRemoveKeys(positions, task, index + 1)
                    }

                    // @ts-ignore
                    const recursionSelectKeys = (postions, taskTree, index = 0) => {
                      const postionfirstElement = positions?.[index]

                      if (Array.isArray(taskTree)) {
                        return recursionSelectKeys(positions, taskTree?.[postionfirstElement], index + 1)
                      }

                      if (postions?.length === index) {
                        titles.push(taskTree?.key)

                        return
                      }

                      const task = taskTree?.children?.[postionfirstElement] ?? taskTree?.[postionfirstElement]

                      titles.push(taskTree?.key)

                      return recursionSelectKeys(positions, task, index + 1)
                    }

                    if (!checked) {
                      recursionSelectRemoveKeys(positions, taskTree)
                    } else {
                      recursionSelectKeys(positions, taskTree)
                    }

                    const isEqual =
                      checkedKeys.length === titles.length && checkedKeys.every((key) => titles.includes(key))

                    const r = isEqual ? titles.slice(0, -1) : titles

                    setCheckedKeys([...new Set([...r, ...checkedKeys].filter((key) => !removedKeys.includes(key)))])
                  }
                }

              />
            ) : null
        }


        <FormFooter
          onCancel={onClose}
          okText='Создать'
        />
      </Form>
    </Modal>
  )
}
