import React from 'react'
import { Spin } from 'antd'
import cn from 'classnames'


import styles from './styles.module.sass'
import { LoadingOutlined } from '@ant-design/icons';


type Props = {
  size?: 'default' | 'small' | 'large'
  tip?: string
  noPadding?: boolean
  className?: string
  circleIcon?: boolean
}

export const Loader = (props: Props) => {
  const { size = 'default', tip, className, noPadding, circleIcon = false} = props

  return (
    <div className={cn(styles.container, noPadding && styles.noPadding, className)}>
      <Spin
        size={size}
        tip={tip}
        indicator={circleIcon ? <LoadingOutlined /> : undefined}
      />

    </div>
  )
}