import { APITYPES } from 'types/apitypes'


export const getChangeAction = (type: APITYPES.OperationStateNoTrans) => {
  switch (type) {
    case APITYPES.OperationStateNoTrans.inprogress: {
      return 'progress'
    }
    case APITYPES.OperationStateNoTrans.done: {
      return 'do'
    }
    case APITYPES.OperationStateNoTrans.canceled: {
      return 'cancel'
    }
    case APITYPES.OperationStateNoTrans.failed: {
      return 'fail'
    }
    default: return
  }
}

// номерной знак машины
export const personal = /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{1}[0-9]{3}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}[0-9]{2,3}$/
export const taxi = /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}[0-9]{3}[0-9]{2,3}$/
