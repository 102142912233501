import React from 'react'
import { Button, Col, Row, Space, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { useShiftTabsContext, useFilterDrawerContext } from '../../context'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'
import { State } from 'stores/rootReducer'

import { FilterTags } from './FilterTags'


export const ExtraTabActions = () => {
  return null
}

const Left = () => {
  const { toggleAddShiftModal } = useFilterDrawerContext()

  return (
    <Tooltip
      overlay='Создать смену'
      placement='bottom'
      mouseLeaveDelay={0}
    >
      <Button
        icon={<PlusOutlined />}
        onClick={toggleAddShiftModal}
        size='small'
        type='primary'
      />
    </Tooltip>
  )
}

const selector = (state: State) => {
  return state.shiftsReducer.shiftsFilter
}

const Right = () => {
  const { activeTab, DEFAULT_TAB_KEY } = useShiftTabsContext()
  const { toggleFilterDrawer, toggleCommitModal } = useFilterDrawerContext()

  const filter = useSelector(selector)

  return (
    <Row
      align='middle'
      justify='space-evenly'
    >
      <Space>
        <Col>
          <Button
            size='small'
            type='primary'
            onClick={toggleCommitModal}
          >
            Фиксировать смены
          </Button>
        </Col>
        <Col>
          {
            activeTab === DEFAULT_TAB_KEY && (
              <>
                <FilterTags />
                <Button
                  size='small'
                  icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
                  onClick={toggleFilterDrawer}
                />
              </>
            )
          }
        </Col>
      </Space>
    </Row>
  )
}

ExtraTabActions.Left = Left
ExtraTabActions.Right = Right
