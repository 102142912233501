import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { apiV1 } from '../../network/http'


// get both penalties and compensations
export const getDoerMotivations = (filter?: APITYPES.Performers.GetDoerMotivations.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerMotivations', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Performers.GetDoerMotivations.URL, {
        params: {
          ...filter,
          offset: getState().motivationsReducer.doerMotivations?.length ?? 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_MOTIVATIONS, {
          doerMotivations: resp,
          hasMore: !(resp.length < 20),
        }))

        dispatch(changeRequestState('getDoerMotivations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerMotivations', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getDoerPenalty = (id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerPenalty', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Performers.GetDoerPenalty.URL, { replaceUrl: { penalty_id: id } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_PENALTY, resp))

        dispatch(changeRequestState('getDoerPenalty', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerPenalty', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getDoerCompensation = (id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerCompensation', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Performers.GetDoerCompensation.URL, { replaceUrl: { compensation_id: id } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_COMPENSATION, resp))

        dispatch(changeRequestState('getDoerCompensation', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerCompensation', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getPenalties = (params?: APITYPES.Performers.GetPenalties.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getPenalties', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Performers.GetPenalties.URL, {
        params: {
          role_id: params?.role_id,
          city_id: params?.city_id,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_PERFORMACE_PENALTIES, resp))

        dispatch(changeRequestState('getPenalties', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getPenalties', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addDoerPenalties = (body: APITYPES.Performers.PostPenalty.Req, onSuccess?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerPenalties', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Performers.PostPenalty.URL, body)

      if (resp) {
        onSuccess?.()
        dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
        dispatch(getDoerMotivations())
        dispatch(changeRequestState('addDoerPenalties', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerPenalties', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updatePenaltyStatus = (
  params: APITYPES.Performers.UpdatePenaltyStatus.Params,
  data: APITYPES.Performers.UpdatePenaltyStatus.Req,
  onSuccess?: () => ThAction,
): ThAction => {
  return async(dispatch, getState, { api }) => {
    dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.LOADING))
    const { penalty_id, new_status } = params

    try {
      const resp = await api.post(APITYPES.Performers.UpdatePenaltyStatus.URL, data,
        {
          replaceUrl: {
            penalty_id,
            new_status,
          },
        })

      if (resp) {
        if (onSuccess) {dispatch(onSuccess())}
        dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
        dispatch(getDoerMotivations(getState().motivationsReducer.doerMotivationsFilter))

        dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.LOADED))
        openNotification('Статус штрафа изменен.')
      }
    } catch (e) {
      dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.ERROR))
      showError(e.response.data)
    }
  }
}

export const addDoerCompensations = (body: APITYPES.Performers.PostCompensation.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerCompensations', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Performers.PostCompensation.URL, body)

      if (resp) {
        dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
        dispatch(getDoerMotivations())
        dispatch(changeRequestState('addDoerCompensations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerCompensations', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateCompensationStatus = (
  params: APITYPES.Performers.UpdateCompensationStatus.Params,
  data: APITYPES.Performers.UpdateCompensationStatus.Req,
  onSuccess?: () => ThAction,
): ThAction => {
  return async(dispatch, getState, { api }) => {
    dispatch(changeRequestState('updateCompensationStatus', StatusRequest.LOADING))
    const { compensation_id, new_status } = params

    try {
      const resp = await api.post(APITYPES.Performers.UpdateCompensationStatus.URL, data,
        {
          replaceUrl: {
            compensation_id,
            new_status,
          },
        })

      if (resp) {
        if (onSuccess) {dispatch(onSuccess())}
        dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
        dispatch(getDoerMotivations(getState().motivationsReducer.doerMotivationsFilter))

        dispatch(changeRequestState('updateCompensationStatus', StatusRequest.LOADED))
        openNotification('Статус компенсаций изменен.')
      }
    } catch (e) {
      dispatch(changeRequestState('updateCompensationStatus', StatusRequest.ERROR))
      showError(e.response.data)
    }
  }
}

export const importMotivations = (file: any , onClose: () => void): ThAction => {
  return async (dispatch) => {
    dispatch(changeRequestState('importMotivations', StatusRequest.LOADING))

    try {
      const response = await apiV1.post(
        '/v1/performers/import_doers_motivations',
        file,
        {
          headers: {
            'Content-Type': 'application/xlsx',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        dispatch(changeRequestState('importMotivations', StatusRequest.LOADED))
        openNotification('Импортировано')
        dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
        dispatch(getDoerMotivations())
        onClose()
      }
    } catch (e: any) {
      dispatch(changeRequestState('importMotivations', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}