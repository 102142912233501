/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { successNotification } from '../../components/modals/OperationNotification'

import { getDoers } from './getDoers'


export const addDoer = (body: APITYPES.Doers.POST.Req, onSuccess?: (doer: APITYPES.Doer) => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addDoer', StatusRequest.LOADING))
    try {
      const resp = await api.post(APITYPES.Doers.POST.URL, body)

      if (resp) {
        onSuccess && onSuccess(resp)
        successNotification('Исполнитель успешно добавлен')
        dispatch(getDoers())

        dispatch(changeRequestState('addDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoer', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}
