import React from "react";
import { DatePicker, Form, Modal } from "antd";
import { useDispatch } from "react-redux";
import {
  createOperationOrders,
  fetchBillSyncState,
} from "AC/shifts/operations";
import { FormFooter } from "components/FormFooter";

const dateFormat = "DD.MM.YYYY";
const backFormat = "YYYY-MM-DD";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const OperationOrdersModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const [begin, end] = values.dates;

    dispatch(
      createOperationOrders({
        begin: begin.format(backFormat),
        end: end.format(backFormat),
      })
    );

    onClose();
  };

  const afterClose = () => form.resetFields();

  return (
    <Modal
      title="Создание заказов для операций"
      visible={isOpen}
      onCancel={onClose}
      afterClose={afterClose}
      footer={false}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="dates"
          label="Период"
          rules={[{ required: true, message: "Поле должно быть заполнено" }]}
        >
          <DatePicker.RangePicker format={dateFormat} />
        </Form.Item>
        <FormFooter onCancel={onClose} okText="Создать" />
      </Form>
    </Modal>
  );
};
