export const columns = ([
  {
    title: 'Исполнитель',
    dataIndex: 'doer_name',
    key: 'doer_name',
    align: 'left',
    width: 280,
  },
  {
    title: 'Роль',
    dataIndex: 'role_name',
    key: 'role_name',
    align: 'center',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    fixed: 'center',
  },
  {
    title: 'Сумма',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    width: 80,
  },
  {
    title: 'Город',
    dataIndex: 'city_id',
    key: 'city_id',
    align: 'center',
  },
  {
    title: 'Время',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
  },
])

export const getColumns = () => columns