import React from 'react'

import { NestedSwitchRoutes } from 'components/NestedSwitch'
import { ROUTES } from 'config/routes'

import { CarClass } from './CarClass'


export const routes: NestedSwitchRoutes = [{
  path: ROUTES.DIRECTORY.CAR_CLASSES.ID.createPath(),
  component: <CarClass />,
}]