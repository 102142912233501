import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import pickBy from 'lodash/pickBy'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import {
  deleteDoerTariffShiftTypes,
  editDoerTariff,
  editDoerTariffShiftTypes,
} from 'AC/doers/doerTariffs'
import { getTableYScroll } from 'utils/scrollUtils'
import { errorNotification } from 'components/modals/OperationNotification'
import { apiV1 } from 'network/http'

import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'
import { AddTariffShiftModal } from './AddTariffShiftModal'


type Props = {
  tariff: APITYPES.DoerTariff.GetById.Resp
};

const selector = (state: State) => ({
  loading:
    state.requestsReducer.fetchDoerTariffShiftTypes === StatusRequest.LOADING ||
    state.requestsReducer.deleteDoerTariffShiftTypes ===
      StatusRequest.LOADING ||
    state.requestsReducer.addDoerTariffShiftTypes === StatusRequest.LOADING,
})

export const TariffShiftTable = ({ tariff }: Props) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(selector)

  const [shiftTypesRoles,setShiftTypesRoles] = useState([])


  const [addTariff, setAddTariff] = useState(false)
  const onShiftDelete = (shift_type_id: string) => {
    dispatch(deleteDoerTariffShiftTypes(tariff.id, shift_type_id))
  }

  useEffect(() => {
    const getAllRoles = async (cityId: number, roleId: string) => {
      try {
        const res =
          // eslint-disable-next-line max-len
          await apiV1.get(`/v1/shift_types?/?city_id=${cityId}&role_id=${roleId}`, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })
  
  
        setShiftTypesRoles(res.data)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    getAllRoles(tariff.city_id, tariff.role_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addTariff])
  
  
  const onTariffChange = (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => {
    const key = Object.keys(partial)[0] as keyof APITYPES.DoerTariff.PUT.Req

    if (partial[key] === 0) {
      errorNotification('Число должно быть больше 0')

      return
    }

    //@ts-ignore
    if (partial[key] === +tariff?.[key]) {
      return
    }
    //@ts-ignore
    if (partial[key] === null && isNaN(+tariff?.[key])) {
      return
    }

    const newBody = {
      ...tariff,
      ...partial,
      id: undefined,
      tasks: undefined,
      shift_types: undefined,
    }

    dispatch(editDoerTariff(tariff.id, newBody))
  }

  const onShiftTypeChange = (
    shift_type_id: string,
    shift_type: Partial<APITYPES.DoerTariffShiftType>,
    partial: Partial<APITYPES.DoerTariff.ShiftTypes.PUT.Req>
  ) => {
    const key = Object.keys(
      partial
    )[0] as keyof APITYPES.DoerTariff.ShiftTypes.PUT.Req

    if ((shift_type?.[key] as string) === (partial?.[key] as string)) {
      return
    }

    const newBody = {
      ...shift_type,
      ...partial,
      shift_type_id: undefined,
      doer_tariff_id: undefined,
    }

    // TODO: need refactoring
    if ('shift_type_name' in newBody) {
      delete newBody['shift_type_name']
    }

    if ('id' in newBody) {
      // @ts-ignore
      delete newBody['id']
    }

    if (!newBody.cost && !newBody.cost_1) {
      errorNotification(
        'Общая цена или цена по первому условию должны быть заполнены'
      )

      return
    }

    dispatch(editDoerTariffShiftTypes(shift_type.id ?? '', pickBy(newBody)))
  }

  return (
    <>
      <Button
        size='small'
        type='primary'
        className={styles.addBtn}
        onClick={() => setAddTariff(!addTariff)}
      >
        Добавить тип смены
      </Button>

      <Table
        sticky
        bordered
        loading={loading}
        columns={
          getColumns(tariff, onShiftDelete, onTariffChange, onShiftTypeChange) as any
        }
        dataSource={getRows(tariff.shift_types ? tariff.shift_types : [])}
        pagination={false}
        scroll={
          {
            y: getTableYScroll(296),
            scrollToFirstRowOnChange: false,
          }
        }
      />

      <AddTariffShiftModal
        isOpen={addTariff}
        onClose={() => setAddTariff(!addTariff)}
        shiftTypes={shiftTypesRoles}
      />
    </>
  )
}
