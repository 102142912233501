// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--S6lK\\+{width:50%}.styles-module--transfer--nrzA6>:nth-child(1)>:first-child>:first-child,.styles-module--transfer--nrzA6>:nth-child(3)>:first-child>:first-child{display:none}.styles-module--listTitle--wyyKn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start}.styles-module--loader--px15b{position:absolute;top:0;bottom:0;right:0;left:0;background-color:#fff;opacity:.33}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/Product/ProductServicesOptions/ProductServices/styles.module.sass"],"names":[],"mappings":"AAAA,kCACE,SAAA,CAGA,gJAEE,YAAA,CAEJ,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,qBAAA,CAEF,8BACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,OAAA,CACA,MAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":[".container\n  width: 50%\n\n.transfer\n  & > :nth-child(1) > :first-child > :first-child,\n  & > :nth-child(3) > :first-child > :first-child\n    display: none\n\n.listTitle\n  display: flex\n  justify-content: start\n\n.loader\n  position: absolute\n  top: 0\n  bottom: 0\n  right: 0\n  left: 0\n  background-color: #fff\n  opacity: 0.33"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--S6lK+",
	"transfer": "styles-module--transfer--nrzA6",
	"listTitle": "styles-module--listTitle--wyyKn",
	"loader": "styles-module--loader--px15b"
};
export default ___CSS_LOADER_EXPORT___;
