import React, { useState } from 'react'
import { Button, Card, Col, Row, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { DoerRecordsFilterTab } from 'stores/doerRecordsReducer'

import styles from './styles.module.sass'
import { BillCalculations } from './BillCalculations'
import { Filter } from './Filters'
import { RecordTable } from './RecordTable'
import { CalcDoersWizard } from './CalcDoersWizard'

import TabPane = Tabs.TabPane;


const selector = (state: State) => ({
  activeTabId: state.doerRecordsReducer.activeTabId,
  filterTabs: state.doerRecordsReducer.filterTabs,
})

export const DoerRecordsPage = () => {
  const { activeTabId, filterTabs } = useSelector(selector)
  const dispatch = useDispatch()
  const [calcModal, setCalcModal] = useState(false)
  const toggleCalcModal = () => setCalcModal((prev) => !prev)

  const selectActiveTab = (id: string) => {
    dispatch(action(Actions.SET_ACTIVE_DOER_RECORDS_TAB, { id }))
  }

  const handleCloseTab = (id: any) => {
    const tabIndex = filterTabs.findIndex((tab: DoerRecordsFilterTab) => tab.id === id)

    if (tabIndex === -1) {
      return
    }

    dispatch(action(Actions.DELETE_DOER_RECORD_FILTER_TAB, { id }))
    selectActiveTab(filterTabs[tabIndex - 1]?.id || 'table')
  }


  return (
    <div>
      <Tabs
        className={styles.tabs}
        type='editable-card'
        hideAdd
        onEdit={handleCloseTab}
        activeKey={activeTabId}
        onTabClick={selectActiveTab}
        destroyInactiveTabPane
        tabBarStyle={{ gap: 12 }}
      >
        <TabPane
          tab='Расчеты'
          key='table'
          closeIcon={<></>}
          forceRender
        >
          <Row gutter={16}>
            <Col span={18}>
              <Card
                title={
                  <div className={styles.toolbar}>
                    <div>
                      <Button
                        type='primary'
                        onClick={toggleCalcModal}
                      >
                        Новый
                      </Button>
                    </div>
                  </div>
                }
              >
                <Card.Grid
                  className={styles.list}
                  style={{ width: '100%' }}
                  hoverable={false}
                >
                  <BillCalculations />
                </Card.Grid>
              </Card>
            </Col>
            <Col span={6}>
              <Filter />
            </Col>
          </Row>
        </TabPane>


        {
          filterTabs.map((tab: DoerRecordsFilterTab) => (
            <TabPane
              key={tab.id}
              tab={tab.name}
            >
              <Row
                gutter={16}
              >
                <Col span={18}>
                  <RecordTable />
                </Col>
                <Col span={6}>
                  <Filter />
                </Col>
              </Row>
            </TabPane>
          ))
        }
      </Tabs>

      <CalcDoersWizard
        isOpen={calcModal}
        onClose={toggleCalcModal}
      />
    </div>
  )
}
