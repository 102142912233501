import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import { getDoerById } from './getDoers'


export const updateDoer = (doerId: string, value: APITYPES.Doers.Put.Req, onSuccess?: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateDoer', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Doers.Put.URL, value, { replaceUrl: { id: doerId } })

      if (resp) {
        dispatch(getDoerById(doerId))

        onSuccess && onSuccess()
        dispatch(changeRequestState('updateDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateDoer', StatusRequest.ERROR))

      showError(e?.response?.data || 'Ошибка при обновлении исполнителя')

      throw(e)
    }
  }
}