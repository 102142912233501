import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Tabs } from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, NavLink } from 'react-router-dom'

import { fetchAllCarClasses } from 'AC/directory/directory'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { ROUTES } from 'config/routes'
import { Loader } from 'components/Loader'
import { NestedSwitch } from 'components/NestedSwitch'
import { getUrlParam } from 'utils/urlUtils'
import { fetchBrands } from 'AC/cars'
import { getGroupTag } from '../utils'

import { routes } from './utils'
import { CreateCard } from './CreateCard'
import styles from './styles.module.sass'


const { TabPane } = Tabs

const selector = (state: State) => {
  const { carClasses } = state.directoryReducer
  const isCarClassLoading =
    state.requestsReducer.fetchAllCarClasses === StatusRequest.LOADING
  const isBrandsLoading =
    state.requestsReducer.fetchBrands === StatusRequest.LOADING
  const isCarClassCarsLoading =
    state.requestsReducer.fetchCarClassCars === StatusRequest.LOADING

  const isLoading =
    isCarClassLoading || isBrandsLoading || isCarClassCarsLoading

  return {
    carClasses,
    isLoading,
  }
}

export const CarClasses = () => {
  const { carClasses, isLoading } = useSelector(selector)
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const id = getUrlParam(pathname, 1)
  const [isCreating, setIsCreating] = useState(false)
  const openCreateModal = () => setIsCreating(true)
  const closeCreateModal = () => setIsCreating(false)

  useEffect(() => {
    batch(() => {
      dispatch(fetchAllCarClasses())
      dispatch(fetchBrands())
    })
  }, [dispatch])

  useEffect(() => {
    if (!id && carClasses) {
      setTab(id ?? carClasses[0].id)
    }
  }, [id, isLoading])

  const setTab = (id: string) => {
    history.push(ROUTES.DIRECTORY.CAR_CLASSES.ID.createPath(id))
  }

  if (!carClasses) {
    return <Loader />
  }

  return (
    <div className={styles.main}>
      <Tabs
        activeKey={id ? id : carClasses[0].id}
        tabPosition='left'
        className={styles.tabs}
        destroyInactiveTabPane
        tabBarExtraContent={
          {
            left: (
              <Button
                type='primary'
                onClick={openCreateModal}
                className={styles.addButton}
              >
                Добавить класс автомобиля
              </Button>
            ),
          }
        }
      >
        {
          carClasses?.map((carClass) => (
            <TabPane
              tab={
                <NavLink
                  to={ROUTES.DIRECTORY.CAR_CLASSES.ID.createPath(carClass.id)}
                >
                  <div
                    className={styles.tabPane}
                    style={
                      {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }
                    }
                  >
                    { carClass.name }

                    <div>{ getGroupTag(carClass?.group) }</div>
                  </div>
                </NavLink>
              }
              key={carClass.id}
              forceRender={false}
              disabled={isLoading}
            >
              <NestedSwitch routes={routes} />
            </TabPane>
          ))
        }
      </Tabs>

      <CreateCard
        isVisible={isCreating}
        onCancel={closeCreateModal}
      />
    </div>
  )
}
