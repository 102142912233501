// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--carsBlock--fOCXY{-webkit-box-flex:1;-ms-flex:1 1 100%;flex:1 1 100%}.styles-module--field--oVHgF{margin-bottom:0}.styles-module--clickArea--xx\\+--{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Users/User/UserInfo/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,kBAAA,CAAA,iBAAA,CAAA,aAAA,CAEJ,6BACI,eAAA,CAEJ,kCACI,cAAA","sourcesContent":[".carsBlock\n    flex: 1 1 100%\n\n.field\n    margin-bottom: 0\n\n.clickArea\n    cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carsBlock": "styles-module--carsBlock--fOCXY",
	"field": "styles-module--field--oVHgF",
	"clickArea": "styles-module--clickArea--xx+--"
};
export default ___CSS_LOADER_EXPORT___;
