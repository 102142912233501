import { Tag } from 'antd'
import React from 'react'

import { APITYPES } from '../../../../../../types/apitypes'
import { BillStateTranslation } from '../../../../Billing/BillingDoers/Filters/utils'

import BillState = APITYPES.BillState


enum BillStateColor {
  draft = 'yellow',
  negotiation = 'yellow',
  accepted = 'yellow',
  canceled = 'red',
  declined = 'red',
  paid = 'green',
  paying = 'green',
}

export const getColumns = () => ([
  {
    title: 'Сумма',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    width: 130,
  },
  {
    title: 'Период расчета',
    dataIndex: 'period',
    key: 'period',
    align: 'center',
    map: ({ billing_begin, billing_end }: APITYPES.DoerPenaltyRecord) => `${billing_begin} - ${billing_end}`,
  },
  {
    title: 'Статус',
    dataIndex: 'bill_state_name',
    key: 'bill_state_name',
    align: 'center',
    width: 120,
    map: ({ bill_state_name, id }: APITYPES.DoerPenaltyRecord) => (
      bill_state_name ? (
        <Tag
          style={{ margin: 0 }}
          color={BillStateColor[bill_state_name as BillState]}
          key={id}
        >
          { BillStateTranslation[bill_state_name as BillState] }
        </Tag>
      ) : '-'
    ),
  },
  {
    title: 'Группа ролей',
    dataIndex: 'role_group_name',
    key: 'role_group_name',
    align: 'center',
    map: ({ role_group_name }: APITYPES.DoerPenaltyRecord) => role_group_name || '-',
  },
  {
    title: 'Департамент',
    dataIndex: 'role_department_name',
    key: 'role_department_name',
    align: 'center',
    width: 150,
  },
])

export const getRows = (records: APITYPES.DoerPenaltyRecord[]) => {
  return records.map((record) => {
    const row: any = {}

    getColumns().forEach((column) => {
      row[column.key] = column?.map
        ? column.map(record)
        : record[column.key as keyof APITYPES.DoerPenaltyRecord]
    })
    row.key = record.id

    return row
  })
}

export const getTotalPenaltyValue = (records: APITYPES.DoerPenaltyRecord[]) => {
  let sum = 0

  records.forEach((item) => {
    if (item.bill_state_name === 'canceled' || item.bill_state_name === 'declined') {return}

    if (Number.isNaN(Number(item.value))) {return}

    sum += Math.abs(item.value)
  })

  return sum
}