import React from 'react'
import { PageHeader, Tag } from 'antd'
import { useHistory } from 'react-router'
import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { ExtraActions } from './ExtraActions'
import { DoerStateTranslation, getColorTagByState } from '../../utils'
import styles from './styles.module.sass'

type Props = {
  doer: APITYPES.Doer
}

export const Header = ({doer}: Props) => {
  const history = useHistory()

  return (
    <PageHeader
      title={doer.name}
      onBack={() => history.push(ROUTES.DOERS.DOERS.path)}
      className={styles.header}
      //@ts-ignore
      subTitle={<Tag color={getColorTagByState(doer.state.name)}>{DoerStateTranslation[doer.state.name]}</Tag>}
      extra={<ExtraActions doer={doer}/>}
    />
  );
};
