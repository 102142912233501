// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--toolbar--f4Zma{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:-8px;margin-bottom:16px}.styles-module--table--ChVCX{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/DoerTariffs/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CACA,kBAAA,CAEF,6BACE,cAAA","sourcesContent":[".toolbar\n  display: flex\n  justify-content: space-between\n  align-items: center\n  margin-top: -8px\n  margin-bottom: 16px\n\n.table\n  cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "styles-module--toolbar--f4Zma",
	"table": "styles-module--table--ChVCX"
};
export default ___CSS_LOADER_EXPORT___;
