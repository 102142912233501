import React from 'react'
import { Row, Typography } from 'antd'

import { APITYPES } from 'types/apitypes'


export enum TabKeys {
  LOCATIONS = 'locations',
  ADD = 'add',
  YANDEX = 'yandex',
  YANDEX_LAVKA = 'yandex-lavke',
  URENT = 'urent',
  PHOTOS = 'photos'
}

export enum EditType {
  EDIT_LOCATION = 'edit_location',
  TABS = 'tabs'
}

export const getEditDescription = (location: APITYPES.Location | null) => (
  <>
    <Row
      align='bottom'
    >
      {
        location?.latitude && location.longitude
          ? (
            <Typography.Text style={
              {
                fontSize: 12,
                color: '#949494',
              }
            }
            >
              Координаты:
              { ' ' }
              { location.latitude }
              /
              { location.longitude }
              .
            </Typography.Text>
          )
          : null
      }
    </Row>
    <Row>
      {
        location?.description
          ? (
            <Typography.Text style={
              {
                fontSize: 12,
                color: '#949494',
              }
            }
            >
              { location.description }
              .
            </Typography.Text>
          ) : null
      }
    </Row>
  </>
)
