import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const cancelBill = (comment: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    const id = getState().billingReducer.bill?.id

    if (!id) {
      return
    }

    dispatch(changeRequestState('cancelBill', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Billing.CancelBillById.URL, { comment }, { replaceUrl: { id } })
      
      if (resp) {
        dispatch(action(Actions.SET_BILL, resp))

        openNotification('Счет отменен')

        dispatch(changeRequestState('cancelBill', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('cancelBill', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}