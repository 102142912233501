import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type StatisticsState = {
  carwashes?: APITYPES.Statistics.Carwashes.Resp
  partners?: APITYPES.Statistics.Partners.Resp
  operations?: APITYPES.Statistics.Operations.POST.Resp | []
}

export const initialStatisticsState = {}

export const statisticsReducer: Reducer<StatisticsState, Action> =
(state = initialStatisticsState, a): StatisticsState => {
  switch (a.type) {
    case Actions.SET_CARWASHES_STATISTICS:
      return {
        ...state,
        carwashes: a.data,
      }

    case Actions.SET_PARTNERS_STATISTICS:
      return {
        ...state,
        partners: a.data,
      }
    case Actions.SET_OPERATIONS_STATISTICS:
      return {
        ...state,
        operations: a.data,
      }
    case Actions.CLEAR_OPERATIONS_STATISTICS:
      return {
        ...state,
        operations: [],
      }
  }

  return state
}
