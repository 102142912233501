import React, { FC, useEffect, useState } from 'react'
import { Steps } from 'antd'
import { StepProps } from 'antd/lib/steps'
import { useDispatch } from 'react-redux'

import { UploadOperationReportFile } from '../steps/UploadOperationReportFile/UploadOperationReportFile'
import { Loader } from 'components/Loader'
import { MatchingExecutors } from '../steps/MatchingExecutors/MatchingExecutors'
import { UploadShiftFile } from '../steps/UploadShiftFile/UploadShiftFile'
import { MatchingLocationsStep } from '../steps/MatchingLocations'
import { deployYandexProReportById } from '../../../../../../AC/yandexpro/reports'
import { FinalStep } from '../steps/FinalStep/FinalStep'
import { actionEmpty, Actions } from '../../../../../../actions'


enum STEPS {
  UPLOAD_OPERATION_FILE = 0,
  MATCHING_EXECUTORS = 1,
  UPLOAD_SHIFT_FILE = 2,
  MATCHING_LOCATIONS = 3,
  PROCESSING = 4,
}


interface Props {
  reportId?: string
  onFinish: () => void
}

interface Step {
  title: string
  component: React.ReactNode
}

export const MatchingSteps: FC<Props> = ({ reportId, onFinish }) => {
  const [current, setCurrent] = useState<STEPS>(STEPS.UPLOAD_OPERATION_FILE)
  const dispatch = useDispatch()
  const changeStep = (step: STEPS) => {
    setCurrent(step)
  }

  const uploadReport = () => {
    return (
      <UploadOperationReportFile
        onSubmit={() => changeStep(STEPS.MATCHING_EXECUTORS)}
        reportStep={() => changeStep(STEPS.UPLOAD_SHIFT_FILE)}
        reportId={reportId}
      />
    )
  }

  const stepsMap: Record<STEPS,  Step> = {
    [STEPS.UPLOAD_OPERATION_FILE]: {
      title: 'Раздел операций',
      component: uploadReport(),
    },
    [STEPS.MATCHING_EXECUTORS]: {
      title: 'Связка исполнителей',
      component: <MatchingExecutors onSubmit={() => changeStep(STEPS.UPLOAD_SHIFT_FILE)} />,
    },
    [STEPS.UPLOAD_SHIFT_FILE]: {
      title: 'Раздел смен',
      component: <UploadShiftFile  onSubmit={() => changeStep(STEPS.MATCHING_LOCATIONS)} />,
    },
    [STEPS.MATCHING_LOCATIONS]: {
      title: 'Связка локаций',
      component: <MatchingLocationsStep  onSubmit={() => changeStep(STEPS.PROCESSING)} />,
    },
    [STEPS.PROCESSING]: {
      title: 'Обработка',
      component: <FinalStep onFinish={onFinish} />,
    },
  }

  const steps: StepProps[] = Object.values(stepsMap).map(({ title }) => ({ title }))

  useEffect(() => {
    return () => {
      dispatch(actionEmpty(Actions.CLEAR_YANDEXPRO_REPORT))
      dispatch(actionEmpty(Actions.RESET_YANDEX_PLACEMENTS))
    }
  }, [])

  return (
    <>
      <Steps
        current={current}
        items={steps}
      />

      { stepsMap[current].component }
    </>
  )
}
