import React from 'react'
import { Col, Form, Modal, Row, InputNumber } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { addDoerTariffShiftPrices } from 'AC/doers/doerTariffs'
import {
  requiredNoMsg,
  strToNumber,
} from 'utils/formUtils'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isOpen: boolean
  onClose: () => void
};

const formLayout = { labelCol: { span: 5 } }


export const AddTariffShiftPriceModal = ({ isOpen, onClose }: Props) => {
  const { doerTariffId } = useParams<{ doerTariffId: string }>()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const watchedCost = Form.useWatch('cost', form)
  const watchedCost1 = Form.useWatch('cost_1', form)
  const orValidation = watchedCost || watchedCost1 ? 'success' : 'error'

  const onFinish = (values: any) => {
    dispatch(addDoerTariffShiftPrices(doerTariffId, values))
    onClose()
  }


  return (
    <Modal
      title='Добавление стоимости смены'
      open={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      footer={false}
      destroyOnClose
      width={600}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 22 }}
      >
        <Form.Item
          label='По умолчанию'
          name='cost'
          initialValue={null}
          normalize={strToNumber}
          rules={watchedCost1 ? undefined : requiredNoMsg}
          validateStatus={orValidation}
        >
          <InputNumber
            controls={false}
            min={1}
            style={{ width: 65 }}
          />
        </Form.Item>

        <Form.Item
          label='Условия'
          style={{ display: 'flex' }}
        >
          <Row justify='space-between'>
            <Col span={4}>
              <Form.Item
                name='cost_1'
                initialValue={null}
                normalize={strToNumber}
                rules={watchedCost ? undefined : requiredNoMsg}
                validateStatus={orValidation}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='1'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_2'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='2'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_3'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='3'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_4'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='4'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_5'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='5'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Добавить'
        />
      </Form>
    </Modal>
  )
}
