// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--main--S1h02{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.styles-module--newPenaltyButton--9LZbP{margin-bottom:20px;max-width:180px}.styles-module--tableContainer--OrPzi{max-height:calc(100vh - 277px);overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/Penalties/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEF,wCACE,kBAAA,CACA,eAAA,CAEF,sCACE,8BAAA,CACA,iBAAA","sourcesContent":[".main\n  display: flex\n  flex-direction: column\n\n.newPenaltyButton\n  margin-bottom: 20px\n  max-width: 180px\n\n.tableContainer\n  max-height: calc(100vh - 277px)\n  overflow-x: hidden\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles-module--main--S1h02",
	"newPenaltyButton": "styles-module--newPenaltyButton--9LZbP",
	"tableContainer": "styles-module--tableContainer--OrPzi"
};
export default ___CSS_LOADER_EXPORT___;
