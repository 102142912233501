import React from 'react'
import { Descriptions, Input, PageHeader } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { APITYPES } from 'types/apitypes'
import { editCustomerTariff } from 'AC/products/tariffs'
import { dateFormat, getFormattedDate } from 'utils/dateUtils'
import styles from './styles.module.sass'

type Props = {
  tariff: APITYPES.CustomerTariff
}

export const TariffHeader = ({tariff}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onTariffChange = (partial: APITYPES.Tariffs.PUT.Req, key: keyof APITYPES.Tariffs.PUT.Req) => {
    if(partial[key] === tariff[key]) return
    dispatch(editCustomerTariff(tariff.id, partial))
  }

  return (
    <>
      <PageHeader
        title={tariff.name}
        subTitle={getFormattedDate(tariff.created, dateFormat)}
        onBack={history.goBack}
        className={styles.header}
      >
        <Descriptions size="small" column={2}>
          <Descriptions.Item label="Название">
            <Input
              size='small'
              style={{width: '50%'}}
              defaultValue={tariff.name}
              onBlur={(e) => onTariffChange({name: e.target.value}, 'name')}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Тип клиента">{tariff.client_type.name}</Descriptions.Item>

          <Descriptions.Item label="Примеры">
            <Input
              size='small'
              style={{width: '50%'}}
              defaultValue={tariff.examples ? tariff.examples : ''}
              onBlur={(e) => onTariffChange({examples: e.target.value}, 'examples')}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Класс автомобиля">{tariff.car_class.name}</Descriptions.Item>
        </Descriptions>
      </PageHeader>
    </>
  );
};
