import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type CarsState = {
  brands: APITYPES.Cars.GetBrands.Resp | []
  models: APITYPES.Cars.GetModels.Resp | []
  brand?: APITYPES.Cars.GetBrands.Resp[number]
}

export const initialCarsState = {
  brands: [],
  models: [],
}

export const carsReducer: Reducer<CarsState, Action> = (state = initialCarsState, a): CarsState => {
  switch (a.type) {
    case Actions.SET_BRAND:
      return {
        ...state,
        brand: a.data,
      }

    case Actions.SET_BRANDS:
      return {
        ...state,
        brands: a.data,
      }

    case Actions.SET_MODELS:
      return {
        ...state,
        models: a.data,
      }
  }
  
  return state
}
