import React, { useEffect, useState } from 'react'
import { Button, Tabs } from 'antd'
import { useHistory, useLocation, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'


import { fetchAllServices } from 'AC/directory/directory'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { ROUTES } from 'config/routes'
import { Loader } from 'components/Loader'
import { NestedSwitch } from 'components/NestedSwitch'
import { getUrlParam } from 'utils/urlUtils'

import { CreateCard } from './CreateCard'
import { routes } from './utils'
import styles from './styles.module.sass'


const { TabPane } = Tabs

const selector = (state: State) => {
  const { services } = state.directoryReducer
  const isLoading = state.requestsReducer.fetchServices === StatusRequest.LOADING

  return {
    services,
    isLoading,
  }
}

export const Services = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { services, isLoading } = useSelector(selector)
  const { pathname } = useLocation()
  const id = getUrlParam(pathname, 1)
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    dispatch(fetchAllServices())
  }, [dispatch])

  useEffect(() => {
    if (!id && services) {
      setTab(id ?? services[0].id)
    }
  }, [id, isLoading, services])

  const setTab = (id: string) => {
    history.push(ROUTES.DIRECTORY.SERVICES.ID.createPath(id))
  }

  if (!services) {
    return <Loader />
  }

  return (
    <>
      <Tabs
        activeKey={id ? id : services[0].id}
        tabPosition='left'
        className={styles.tabs}
        destroyInactiveTabPane
        tabBarExtraContent={
          {
            left: (
              <Button
                type='primary'
                onClick={() => setIsCreating(true)}
                className={styles.addButton}
              >
                Добавить услугу
              </Button>),
          }
        }
      >
        {
          services?.map((service) => (
            <TabPane
              tab={
                <NavLink to={ROUTES.DIRECTORY.SERVICES.ID.createPath(service.id)}>
                  <div className={styles.tabPane}>
                    { service.name }
                  </div>
                </NavLink>
              }
              key={service.id}
              forceRender={false}
              disabled={isLoading}
            >
              <NestedSwitch routes={routes} />
            </TabPane>
          ))
        }
      </Tabs>

      <CreateCard
        isVisible={isCreating}
        onCancel={() => setIsCreating(false)}
      />
    </>
  )
}