import { APITYPES } from '../../types/apitypes'
import { action, Actions, ThAction } from '../../actions'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'
import { showError } from '../../components/modals/ErrorModal'


export const getMotivationStatistics = (filter: APITYPES.DoerTariff.MotivationStatistics.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getMotivationStatistics', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerTariff.MotivationStatistics.URL,
        { params: filter }
      )
      
      if (resp) {
        dispatch(action(Actions.SET_MOTIVATION_STATISTICS, {
          motivationStatistics: resp,
          hasMore: Boolean(resp.length),
        }))

        dispatch(changeRequestState('getMotivationStatistics', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getMotivationStatistics', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}