// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--CJC7\\+{height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.styles-module--infiniteScroll--G1Lgs{-webkit-box-flex:0;-ms-flex:0 1 auto;flex:0 1 auto}", "",{"version":3,"sources":["webpack://./src/components/InfiniteTable/styles.module.sass"],"names":[],"mappings":"AAAA,kCACI,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEJ,sCACI,kBAAA,CAAA,iBAAA,CAAA,aAAA","sourcesContent":[".container\n    height: 100%\n    display: flex\n    flex-direction: column\n    \n.infiniteScroll\n    flex: 0 1 auto"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--CJC7+",
	"infiniteScroll": "styles-module--infiniteScroll--G1Lgs"
};
export default ___CSS_LOADER_EXPORT___;
