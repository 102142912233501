// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{margin:0;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box}html,body{height:100%;width:100%;overflow:hidden}#root{height:100%;width:100%;overflow:auto}a{text-decoration:none;cursor:pointer;color:inherit}a:hover{text-decoration:none}a:focus{text-decoration:none}a:active{text-decoration:none}*,*:before,*:after{-webkit-box-sizing:border-box;box-sizing:border-box}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.global--ant-form-item-label--KEJXx{overflow:visible !important}", "",{"version":3,"sources":["webpack://./src/styles/global.sass"],"names":[],"mappings":"AAEA,EACE,QAAA,CACA,SAAA,CACA,6BAAA,CAAA,qBAAA,CAEF,UACE,WAAA,CACA,UAAA,CACA,eAAA,CAEF,MACE,WAAA,CACA,UAAA,CACA,aAAA,CAEF,EACE,oBAAA,CACA,cAAA,CACA,aAAA,CAEA,QACE,oBAAA,CAEF,QACE,oBAAA,CAEF,SACE,oBAAA,CAEJ,mBACE,6BAAA,CAAA,qBAAA,CAEF,kEAEI,uBAAA,CACA,QAAA,CAEJ,oCACE,2BAAA","sourcesContent":["@import vars\n\n*\n  margin: 0\n  padding: 0\n  box-sizing: border-box\n  \nhtml, body\n  height: 100%\n  width: 100%\n  overflow: hidden\n\n:global(#root)\n  height: 100%\n  width: 100%\n  overflow: auto\n\na\n  text-decoration: none\n  cursor: pointer\n  color: inherit\n\n  &:hover\n    text-decoration: none\n\n  &:focus\n    text-decoration: none\n\n  &:active\n    text-decoration: none\n\n*, *:before, *:after\n  box-sizing: border-box\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button\n    -webkit-appearance: none\n    margin: 0\n\n.ant-form-item-label\n  overflow: visible !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-form-item-label": "global--ant-form-item-label--KEJXx"
};
export default ___CSS_LOADER_EXPORT___;
