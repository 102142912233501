import React, { useState } from 'react'
import { Dropdown, Menu, Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { APITYPES } from 'types/apitypes'
import { getAllowedDoerStatusArray, getPenaltyStatusColor, getPenaltyStatusText}
  from 'App/PrivateRoutes/Partners/Carwashes/Carwash/Penalties/Status/utils'
import { ChangePenaltyStatusModal } from 'App/PrivateRoutes/Partners/Carwashes/Carwash/Penalties/ChangeStatusModal'

import styles from './styles.module.sass'


export type Props = {
  motivation: APITYPES.DoerMotivation | APITYPES.DoerPenalty | APITYPES.DoerCompensation
  onCallback?: (comment: string, status: string) => void
  modalTitle?: string
}

export const Status = (props: Props) => {
  const { motivation, modalTitle, onCallback, motivation: { state } } = props

  const [changeStatusModalState, setChangeStatusModalState] = useState<{
    isOpen: boolean
    newStatus: string | null
  }>({
    isOpen: false,
    newStatus: null,
  })

  const onTagClick = (newStatus: string) => {
    setChangeStatusModalState({
      isOpen: true,
      newStatus,
    })
  }

  const getMenu = (status: string) => {
    const allowedItems = getAllowedDoerStatusArray(status)
    
    return (
      <Menu className={styles.menu}>
        {
          allowedItems.map((item) => (
            <Menu.Item
              key={item}
              onClick={() => onTagClick(item)}
            >
              <Tag
                color={getPenaltyStatusColor(item)}
                className={styles.tag}
              >
                { getPenaltyStatusText(item) }
              </Tag>
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  return (
    <>
      <div
        className={styles.container}
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown
          overlay={getMenu(state.name)}
          trigger={['click']}
          disabled={state.name === 'canceled' || state.name === 'paid'}
        >
          <a
            className='ant-dropdown-link'
            onClick={(e) => e.preventDefault()}
          >
            <Tag
              color={getPenaltyStatusColor(state.name)}
            >
              { getPenaltyStatusText(state.name) }
            </Tag>
            { '  ' }
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
      <ChangePenaltyStatusModal
        modalTitle={modalTitle || 'Вы точно хотите изменить статус?'}
        newStatus={changeStatusModalState.newStatus}
        carwashPenalty={motivation}
        isVisible={changeStatusModalState.isOpen}
        onCallback={onCallback}
        onCancel={
          () => setChangeStatusModalState({
            isOpen: false,
            newStatus: null,
          })
        }
      />
    </>
  )
}
