import React from 'react'

import { APITYPES } from 'types/apitypes'


const getTariffDates = (tariff: APITYPES.DoerTariffGroups['doer_tariffs'][0]) => {
  const { begin, end } = tariff

  return (
    <b>
      { begin ? ' с ' + begin : '' }
      { end ? ' по ' + end : '' }
    </b>
  )
}

export function combinedTariffGroups(groups: APITYPES.DoerTariffGroups[] | null) {
  if (!groups) {
    return null
  }
  
  return groups?.map((group) => {
    const { id, name, doer_tariffs } = group

    const childrenNode = doer_tariffs?.map((tariff) => {
      return {
        checkable: false,
        title: <p>{ tariff.title } { getTariffDates(tariff) }</p>,
        key: tariff.id,
      }
    })

    return {
      title: name,
      key: id,
      children: childrenNode,
    }
  })
}
