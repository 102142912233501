import { APITYPES } from '../../../../../types/apitypes'

import MOTIVATION_TYPE = APITYPES.MOTIVATION_TYPE


export const states = [
  {
    text: 'Новый',
    value: 'new',
  },
  {
    text: 'Подтвержден',
    value: 'applied',
  },
  {
    text: 'Отменен',
    value: 'canceled',
  },
]

interface Type {
  text: string
  value: MOTIVATION_TYPE
}

export const types: Type[] = [
  {
    text: 'Компенсации',
    value: MOTIVATION_TYPE.COMPENSATION,
  },
  {
    text: 'Штрафы',
    value: MOTIVATION_TYPE.PENALTY,
  },
]

export interface Role {
  role_name: string
  role_id: string
}

export const getRolesWithoutDuplicates = (motivationRoles: Role[]) => {
  return motivationRoles.reduce((acc, current) => {
    if (!acc.find((role) => role?.role_id === current.role_id)) {
      acc.push(current)
    }

    return acc
  }, [] as Role[])
}