import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getFleets } from 'AC/fleets/fleets'
import { fetchAllCarBrands } from 'AC/directory/directory'
import { getTableYScroll } from 'utils/scrollUtils'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'

import { EditFleetCarModal } from './EditFleetCarModal'
import { CreateFleetCarModal } from './CreateFleetCarModal'
import { Filters } from './Filters'
import { EditModalType, getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  fleetsCars: state.fleetsReducer.fleetsCars,
  isLoading: state.requestsReducer.getFleetsCars === StatusRequest.LOADING,
  fleets: state.fleetsReducer.fleets,
  allBrands: state.directoryReducer.carBrands,
  fleetsCarsFilter: state.fleetsReducer.fleetsCarsFilter,
})

export const FleetCars = () => {
  const dispatch = useDispatch()
  const { isLoading, fleetsCars, fleets, allBrands, fleetsCarsFilter } = useSelector(selector)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  /** init all additional data */
  useEffect(() => {
    if (!fleets.length) {dispatch(getFleets())}
    if (!allBrands) {dispatch(fetchAllCarBrands())}
  }, [])


  /** modals states */
  const [filter, setFilter] = useState(false)
  const toggleFilter = () => setFilter((prev) => !prev)
  const [createModal, setCreateModal] = useState(false)
  const toggleCreateModal = () => setCreateModal((prev) => !prev)
  const [editModal, setEditModal] = useState<EditModalType>({
    isOpen: false,
    fleetCar: null,
  })
  const openEditModal = (fleetCar: APITYPES.FleetCar) => setEditModal({
    isOpen: true,
    fleetCar,
  })
  const closeEditModal = () => setEditModal({
    isOpen: false,
    fleetCar: null,
  })

  return (
    <div>
      <div className={styles.toolbar}>
        <Button
          type='primary'
          onClick={toggleCreateModal}
        >
          Добавить автомобиль
        </Button>
        <Button
          size='small'
          onClick={toggleFilter}
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(fleetsCarsFilter)} />}
        />
      </div>

      <Table
        sticky
        size='small'
        loading={isLoading}
        columns={getColumns() as any}
        dataSource={getRows(fleetsCars)}
        pagination={false}
        bordered
        scroll={{ y: getTableYScroll(162) }}
        onRow={
          (record: any, index) => ({
            ref: index === fleetsCars?.length - 1 ? lastRowRef : undefined,
            onClick: () => openEditModal(record.fleetCar),
          })
        }
        style={{ cursor: 'pointer' }}
      />

      <CreateFleetCarModal
        onClose={toggleCreateModal}
        isOpen={createModal}
      />
      <EditFleetCarModal
        onClose={closeEditModal}
        isOpen={editModal.isOpen}
        fleetCar={editModal.fleetCar}
        setEditModal = {setEditModal}
      />
      <Filters
        onClose={toggleFilter}
        isOpen={filter}
        isIntersecting={entry?.isIntersecting}
      />
    </div>
  )
}
