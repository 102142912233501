import React from 'react'
import { Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { getTableYScroll } from 'utils/scrollUtils'

import { getColumns, getRolesColumns, getRolesRows, getRows } from './utils'


type Props = {
  shiftTypes: APITYPES.ShiftType[]
  isLoading: boolean
}

export const ShiftTypesList = (props: Props) => {
  const { shiftTypes, isLoading } = props

  return (
    <Table
      sticky
      loading={isLoading}
      columns={getColumns() as any}
      dataSource={getRows(shiftTypes)}
      pagination={false}
      size='small'
      expandable={{
        expandedRowRender: record => (
          <Table
            columns={getRolesColumns() as any}
            dataSource={getRolesRows(record.roles || [])}
            pagination={false}
            size='small'
          />
        ),
      }}
      scroll={{ y: getTableYScroll(250.5) }}
    />
  )
}
