import React from 'react'
import { Tag } from 'antd'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'


export enum OperationStateColors {
  inprogress = 'green',
  done = 'blue',
  canceled = 'gray',
  failed = 'red'
}

export const getColumns = () => {
  return [
    {
      title: 'Название',
      dataIndex: 'task_name',
      key: 'task_name',
    },
    {
      title: 'Исполнитель',
      dataIndex: 'doer_name',
      key: 'doer_name',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'grz',
      key: 'grz',
      align: 'center',
      render: (record: string | null) => record ? <Tag>{ record }</Tag> : '-',
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      filterMultiple: false,
      render: (record: APITYPES.OperationStateOptions) =>
        //@ts-ignore
        <Tag color={OperationStateColors[record]}>{ APITYPES.OperationStateOptions[record] }</Tag>,
    },
    {
      title: 'Время',
      dataIndex: 'created',
      key: 'created',
      align: 'center',
    },
  ]
}


export const getRows = (operations: APITYPES.TOperations) => {
  const timezone = useGetTimezoneFromLocalstorage()

  return operations.map((operation) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state') {
        row[column.dataIndex] = operation.state.name
      } else if (column.dataIndex === 'created') {
        const date = moment(operation?.time).format('DD.MM.YYYY')
        const time = moment(operation?.time).format('HH:mm:ss Z')
        
        const shift = formatDateTime({
          start: time,
          targetTimezone: (operation?.timezone ? operation.timezone : timezone)
        })
        
        if (operation.state.created === null) {
          row[column.dataIndex] = null
        } else {
          row[column.dataIndex] = `${date} ${shift}`
        }
      } else if (column.dataIndex === 'task_name') {
        row[column.dataIndex] = operation.title
      } else if (column.dataIndex === 'doer_name') {
        row[column.dataIndex] = operation.name
      } else {
        //@ts-ignore
        row[column.dataIndex] = operation[column.dataIndex]
      }
    })
    row.operation = operation
    row.key = operation.id

    return row
  })
}