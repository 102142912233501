import React, { useEffect, useState } from 'react'
import { Card, Select, Table } from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import cn from 'classnames'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { changeOperationState, fetchOperationFiles, fetchOperationTasks } from 'AC/shifts/operations'
import { Loader } from 'components/Loader'
import { APITYPES } from 'types/apitypes'
import { getChangeAction } from '../OperationCard/CardContent/utils'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'

import { getTreeDataFromParent } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  parent: state.operationsReducer.parentOperation,
  current: state.operationsReducer.currentOperation,
  isLoading: state.requestsReducer.fetchOperation === StatusRequest.LOADING,
})

export const OperationsTree = () => {
  const dispatch = useDispatch()
  const { parent, current, isLoading } = useSelector(selector)
  const [treeData, setTreeData] = useState(getTreeDataFromParent(parent))
  const [currentRow, setCurrentRow] = useState('')
  const timezone = useGetTimezoneFromLocalstorage()


  const onStateChange = (id: string, value: APITYPES.OperationStateNoTrans) => {
    const action = getChangeAction(value)

    if (!action) {return}
    dispatch(changeOperationState(id, {
      type: action,
      value,
    }))
  }

  const onSelectTreeRow = (node: any) => {
    if (currentRow === node.operation.id) {return}

    dispatch(action(Actions.SET_OPERATION_FILES, []))
    batch(() => {
      dispatch(action(Actions.SET_CHILD_OPERATION, node.operation))
      dispatch(fetchOperationTasks(node.operation.id))
      dispatch(fetchOperationFiles(node.operation.id))
    })
  }

  const columns: any = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, el: {
        key: string
        title: string
      }) => {
        return (
          <div
            className={cn(styles.treeItem, { [styles.selectedTreeItem]: current?.id === el.key })}
            onClick={
              () => {
                setCurrentRow(el.key)
                onSelectTreeRow(el)
              }
            }
          >
            { el.title }
          </div>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'grz',
      key: 'grz',
      width: '25%',
      render: (_: any, el: any) => {
        return (
          <Select
            style={{ width: '100%' }}
            className={styles.statusSelect}
            value={el.operation.state.name}
            onClick={
              () => {
                dispatch(action(Actions.SET_CHILD_OPERATION, el.operation))
              }
            }
            onSelect={(e: any) => onStateChange(el.operation.id, e)}
          >
            {
              Object.entries(APITYPES.OperationStateOptions).map((state) => (
                <Select.Option
                  key={state[0]}
                  value={state[0]}
                >
                  { state[1] as string }
                </Select.Option>
              ))
            }
          </Select>

        )
      },
    },
    {
      title: 'Время',
      dataIndex: 'time',
      width: '30%',
      key: 'time',
      render: (_: any, el: {
        operation: {
          state: {
            created: any
          }
        }
      }) => {
        const date = moment(el.operation.state.created).format('DD.MM.YYYY')
        const time = moment(el.operation.state.created).format('HH:mm:ss Z')
        
        const shift = formatDateTime({
          start: time,
          targetTimezone: timezone,
        })

        return <>{ `${date}, ${shift}` }</>
      },
    },
  ]

  useEffect(() => {
    if (!parent) {return}
    setTreeData(getTreeDataFromParent(parent))
  }, [parent])

  return (
    <Card
      className={styles.tree}
      bodyStyle={{ height: '100%' }}
    >
      {
        isLoading
          ? <Loader />
          : (
            <Table
              size='small'
              defaultExpandedRowKeys={[parent?.id ?? '']}
              columns={columns}
              dataSource={treeData}
              pagination={false}
            />
          )
      }
    </Card>
  )
}
