import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import {
  deleteCustomerTariffProducts,
  editCustomerTariffProducts,
  fetchCustomerTariffProducts,
} from 'AC/products/tariffs'
import { getTableYScroll } from 'utils/scrollUtils'
import { getColumns, getRows } from './utils'

type Props = {
  tariffId: string
}

const selector = (state: State) => ({
  customerTariffProducts: state.customerTariffsReducer.customerTariffProducts,
  isLoading: state.requestsReducer.fetchCustomerTariffProducts === StatusRequest.LOADING
})

export const TariffProducts = ({tariffId}: Props) => {
  const dispatch = useDispatch()
  const {customerTariffProducts, isLoading} = useSelector(selector)

  useEffect(() => {
    dispatch(fetchCustomerTariffProducts(tariffId))
    return () => {
      dispatch(action(Actions.SET_CUSTOMER_TARIFFS_PRODUCTS, []))
    }
  }, [])

  const onDelete = (productId: string) => {
    dispatch(deleteCustomerTariffProducts(tariffId, productId))
  }

  const onPriceChange = (productId: string, body: APITYPES.Tariffs.Product.PUT.Req) => {
    dispatch(editCustomerTariffProducts(tariffId, productId, body))
  }

  return (
    <Table
      sticky
      bordered
      size='small'
      pagination={false}
      loading={isLoading}
      style={{cursor: 'pointer'}}
      columns={getColumns(onDelete, onPriceChange) as any}
      dataSource={getRows(customerTariffProducts)}
      scroll={{y: getTableYScroll(364)}}
    />
  );
};