import React, { useState } from 'react'
import { Button, Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { getTableYScroll } from 'utils/scrollUtils'

import { AddCarClassForm } from './AddCarClassForm'
import { EditCarClassForm } from './EditCarClassForm'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


type Props = {
  clientTypeCarClasses: APITYPES.CarClasses.Get.Resp
  carClasses: APITYPES.AllCarClasses.Get.Resp
  isLoading?: boolean
}

export const CarClasses = (props: Props) => {
  const { carClasses, clientTypeCarClasses, isLoading = false } = props

  const [isAddCarClassModal, setAddCarClassModal] = useState(false)
  const toggleAddCarClassModal = () => setAddCarClassModal(!isAddCarClassModal)

  const [isEditCarClassModal, setEditCarClassModal] = useState<{
    isOpen: boolean
    carClass: APITYPES.CarClasses.Get.Resp[number] | null
  }>({
    isOpen: false,
    carClass: null,
  })
  const openEditCarClassModal = (carClass: APITYPES.CarClasses.Get.Resp[number]) => setEditCarClassModal({
    isOpen: true,
    carClass: carClass,
  })

  const closeEditCarClassModal = () => setEditCarClassModal({
    isOpen: false,
    carClass: null,
  })

  return (
    <>
      <AddCarClassForm
        isVisible={isAddCarClassModal}
        onCancel={toggleAddCarClassModal}
        allCarClasses={carClasses}
        carClasses={clientTypeCarClasses}
      />
      <Button
        type='primary'
        className={styles.addButton}
        onClick={toggleAddCarClassModal}
      >
        Привязать класс
      </Button>

      <Table
        sticky
        columns={getColumns() as any}
        dataSource={getRows(clientTypeCarClasses)}
        className={styles.table}
        bordered
        size='middle'
        pagination={false}
        loading={isLoading}
        scroll={{ y: getTableYScroll(321.5) }}
        onRow={(record: any) => ({ onClick: () => openEditCarClassModal(record.carClass) })}
      />

      <EditCarClassForm
        isVisible={isEditCarClassModal.isOpen}
        onCancel={closeEditCarClassModal}
        carClass={isEditCarClassModal.carClass}
      />
    </>
  )
}