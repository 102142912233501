import React from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { addShiftTypeByRole } from 'AC/shifts/shifts'
import { required } from 'utils/formUtils'
import { getRolesOptions } from 'utils/select-options'
import { filterSelectionOptions } from 'utils/filterUtils'


const { useForm } = Form

export type Props = {
  onClose: () => void
}

const formItemLayout = {
  labelCol: { sm: { span: 4 } },
  wrapperCol: { sm: { span: 24 } },
}

type FormValues = {
  role_id: string
  shift_type_id: string
}

const selector = (state: State) => ({
  roles: state.doersReducer.roles,
  shiftsTypes: state.shiftsReducer.shiftTypes,
})

export const ShiftTypeByRoleForm = (props: Props) => {
  const { onClose } = props
  const [form] = useForm()
  const {
    roles,
    shiftsTypes,
  } = useSelector(selector)
  const dispatch = useDispatch()

  const onFinish = (values: FormValues) => {
    const {
      role_id,
      shift_type_id,
    } = values

    onClose()
    dispatch(addShiftTypeByRole(shift_type_id, { role_id }))
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='role_id'
        label='Роль'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          placeholder='Выберите роль'
          options={getRolesOptions(roles) || []}
          filterOption={filterSelectionOptions}
        />
      </Form.Item>

      <Form.Item
        name='shift_type_id'
        label='Тип смены'
        rules={required}

      >
        <Select
          placeholder='Выберите тип смены'
          options={
            shiftsTypes.map((t) => ({
              label: t.name,
              value: t.id,
            }))
          }
        />
      </Form.Item>

      <FormFooter onCancel={onClose} />
    </Form>
  )
}
