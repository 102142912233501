import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { editOperation, fetchOperationLocations } from 'AC/shifts/operations'
import { useDebounce } from 'utils/debounce'
import { action, Actions } from 'actions'

const selector = (state: State) => ({
  selectedOperation: state.operationsReducer.currentOperation,
  locationOptions: state.operationsReducer.locationSearch,
  parent: state.operationsReducer.parentOperation,
  isOptionsLoading: state.requestsReducer.fetchOperationLocations === StatusRequest.LOADING,
})

export const useLocationForm = () => {
  const dispatch = useDispatch()
  const {selectedOperation, isOptionsLoading, locationOptions, parent} = useSelector(selector)

  const [editLocationMode, setEditLocationMode] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 300)

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.length > 2) {
      dispatch(fetchOperationLocations(debouncedSearch))
    }
  }, [debouncedSearch])

  const [form] = Form.useForm()

  const onLocationSelect = async (value: string, option: any) => {
    if (!parent || (value === parent.location.id)) {
      setEditLocationMode(false)
      return
    }

    await dispatch(editOperation(
      parent.id,
      {location_id: value},
      'location',
      option.newLocation,
    ))

    form.resetFields()
    onLocationSelectBlur()
  }

  const onLocationEditClick = () => setEditLocationMode(true)

  const onLocationSelectBlur = () => {
    setEditLocationMode(false)
    dispatch(action(Actions.SET_OPERATION_LOCATIONS_SEARCH, []))
  }

  const onLocationSearch = (value: string) => setSearch(value)

  return {
    debouncedSearch,
    editLocationMode,
    onLocationSearch,
    isOptionsLoading,
    onLocationSelect,
    locationForm: form,
    onLocationEditClick,
    onLocationSelectBlur,
    options: locationOptions,
  }
};
