// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addButton--wwYus{margin-bottom:16px}.styles-module--tableBtn--z-Rmr{margin:0;padding:0}.styles-module--table--FN06I{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/ClientTypes/ClientType/Services/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,kBAAA,CAEJ,gCACI,QAAA,CACA,SAAA,CAEJ,6BACI,cAAA","sourcesContent":[".addButton\n    margin-bottom: 16px\n\n.tableBtn\n    margin: 0\n    padding: 0\n\n.table\n    cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "styles-module--addButton--wwYus",
	"tableBtn": "styles-module--tableBtn--z-Rmr",
	"table": "styles-module--table--FN06I"
};
export default ___CSS_LOADER_EXPORT___;
