import moment from 'moment/moment'

import { dateFormat, dateTimeFormat } from 'utils/dateUtils'
import { APITYPES } from 'types/apitypes'


export const reportTableCols = [
  {
    title: 'Дата отчета',
    dataIndex: 'report_date',
    key: 'report_date',
    render: (date: string) => moment(date).format(dateFormat),
  },
  {
    title: 'Загружен',
    dataIndex: 'created',
    key: 'created',
    render: (date: string) => moment(date).format(dateTimeFormat),
  },
  {
    title: 'Обработан',
    dataIndex: 'deployed',
    key: 'deployed',
    render: (date: string) => date ?  moment(date).format(dateTimeFormat) : '',
  },
]


export type MappedReport = APITYPES.Urent.Report & {
  key: string
}

export const mapReportsToTable = (reports: APITYPES.Urent.Report[]): MappedReport[] => {
  return reports.map((report) => {
    return {
      key: report.id,
      ...report,
    }
  })
}