// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--KvtLc{height:calc(100vh - 133px);padding:1rem 0;display:-webkit-box;display:-ms-flexbox;display:flex;gap:1rem}.styles-module--content--Bpo9I{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:1rem}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftsPage/components/ShiftTab/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,0BAAA,CACA,cAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA","sourcesContent":[".container\n  height: calc(100vh - 133px)\n  padding: 1rem 0\n  display: flex\n  gap: 1rem\n\n.content\n  display: flex\n  flex-direction: column\n  gap: 1rem"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--KvtLc",
	"content": "styles-module--content--Bpo9I"
};
export default ___CSS_LOADER_EXPORT___;
