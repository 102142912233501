import React, {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useHistory } from 'react-router'
import {
  Button,
  Input,
  Table,
} from 'antd'
import qs from 'qs'

import { ROUTES } from 'config/routes'
import {
  action,
  Actions,
} from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import {
  fetchCarwashes,
  fetchRentalObjectTypes,
} from 'AC/carwashes/carwashes'
import { fetchPartners } from 'AC/partners/partners'
import { InfiniteTable } from 'components/InfiniteTable'
import { Loader } from 'components/Loader'
import { isObjectEmpty } from 'utils/filterUtils'
import { useDebounce } from 'utils/debounce'
import { navigate } from 'utils/tabelUtils'
import { CreateCard } from '../Carwashes/Card/CreateCard'
import { FilterIcon } from 'components/FilterIcon'
import { exportCarwashesToDocument } from 'AC/partners/exportPartnersToDocument'
import { APITYPES } from '../../../../types'

import { Filters } from './Filters'
import {
  getColumns,
  getRows,
} from './utils'
import styles from './styles.module.sass'


const domain: APITYPES.RentalObjectTypeDomain = 'space'

const { Search } = Input

const selector = (state: State) => {
  const {
    carwashes,
    hasMore,
    isCreating,
    premisesFilter,
  } = state.carwashesReducer
  const {
    fetchCarwashes,
    exportCarwashes,
    fetchRentalObjectTypes,
  } = state.requestsReducer
  const { allPartners } = state.partnersReducer
  const { clientTypes } = state.directoryReducer

  return {
    isLoading: fetchCarwashes === StatusRequest.LOADING,
    isExportLoading: exportCarwashes === StatusRequest.LOADING,
    isTypesLoading: fetchRentalObjectTypes === StatusRequest.LOADING,
    hasMore,
    carwashes,
    filter: premisesFilter,
    clientTypes,
    isStoreCreating: isCreating,
    partners: allPartners,
  }
}

export const Premises = () => {
  const dispatch = useDispatch()
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const history = useHistory()
  const {
    isLoading,
    isExportLoading,
    isTypesLoading,
    isStoreCreating,
    hasMore,
    carwashes,
    filter,
    partners,
  } = useSelector(selector)

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [isCreate, setIsCreate] = useState(false)
  const debouncedSearchTerm = useDebounce(filter.search, 1000)
  const baseFilter: APITYPES.CarwashFilter = { domain }

  const toggleFilter = () => setFilterOpen((prev) => !prev)

  const loadMore = () => {
    dispatch(fetchCarwashes({
      ...baseFilter,
      search: params.search,
      partner_id: params.partner_id?.length ? params.partner_id?.split(',') : undefined,
      state: params.state?.length ? params.state?.split(',') : undefined,
      region: params.region?.length ? params.region?.split(',') : undefined,
      object_type_id: params.object_type_id?.length ? params.object_type_id?.split(',') : undefined,
    }))
  }

  const onSearch = (value: string) => {
    const state = value.length ? value : undefined

    dispatch(action(Actions.CLEAN_CARWASHES, {}))
    dispatch(fetchCarwashes({
      ...baseFilter,
      search: state,
    }))
  }

  const onSearchChange = (event: any) => {
    if (event.target.value === '') {
      dispatch(
        action(Actions.SET_PREMISES_FILTER, {
          ...filter,
          search: undefined,
        }),
      )

      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes(baseFilter))

      history.push(ROUTES.PREMISES.PARAMS.createPath({
        ...filter,
        search: '',
      }))
    }

    dispatch(
      action(Actions.SET_PREMISES_FILTER, {
        ...filter,
        search: event.target.value,
      }),
    )
  }

  const exportToTable = () => {
    dispatch(exportCarwashesToDocument(filter))
  }

  useEffect(() => {
    dispatch(fetchRentalObjectTypes(domain))

    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)

      dispatch(action(Actions.SET_PREMISES_FILTER, {
        ...parsed,
        state: params.state?.length ? params.state?.split(',') : undefined,
      }))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.PREMISES.PARAMS.createPath(filter))
    }

    return () => {
      dispatch(action(Actions.SET_RENTAL_OBJECT_TYPES, []))
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
    }
  }, [dispatch])

  useEffect(() => {
    if (debouncedSearchTerm && filter.search?.trim().length) {
      history.push(ROUTES.PREMISES.PARAMS.createPath(filter))
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({
        ...baseFilter,
        ...filter,
      }, 20, 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    dispatch(action(Actions.SET_CARWASH, undefined))
    if (!partners.length) {
      dispatch(fetchPartners({
        limit: 1000,
        all: true,
      }))
    }
  }, [dispatch])

  if (!carwashes || isTypesLoading) {
    return <Loader />
  }

  return (
    <>
      <div className={styles.main}>
        <Search
          value={filter.search}
          onChange={onSearchChange}
          placeholder='Поиск'
          onSearch={onSearch}
          enterButton
        />
        <div className={styles.toolbar}>
          <div className={styles.toolbarActions}>
            <Button
              type='primary'
              onClick={() => setIsCreate(true)}
            >
              Добавить объект
            </Button>
            <Button
              type='primary'
              disabled={isExportLoading}
              onClick={exportToTable}
            >
              Экспорт в таблицу
            </Button>
          </div>


          <Button
            size='small'
            onClick={toggleFilter}
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          />
        </div>
        <CreateCard
          isVisible={isCreate || isStoreCreating}
          onCancel={() => setIsCreate(false)}
        />
        <InfiniteTable
          isLoading={isLoading}
          hasMore={hasMore}
          columns={
            getColumns()
          }
          loadMore={loadMore}
          className={styles.tableContainer}
        >
          <Table
            showHeader={false}
            columns={getColumns() as any}
            dataSource={getRows(carwashes)}
            className={styles.table}
            bordered
            size='large'
            pagination={false}
            scroll={{ x: '100%' }}
            onRow={(record: any) => ({ onClick: navigate(ROUTES.PREMISES.ID.createPath(record.key), history) })}
          />
        </InfiniteTable>
      </div>

      <Filters
        isOpen={filterOpen}
        onClose={toggleFilter}
      />
    </>
  )
}
