import React from 'react'
import { Tag, Tooltip } from 'antd'
import moment from 'moment-timezone'

import { APITYPES } from 'types/apitypes'


export enum DoerStateTranslation {
  new = 'Новый',
  confirmed = 'Подтвержден',
  blocked = 'Заблокирован',
  registration = 'Регистрация',
  onboarding = 'Онбординг',
}

export const getColorTagByState = (value: string) => {
  if (value === 'new') {
    return 'blue'
  }

  if (value === 'confirmed') {
    return 'green'
  }

  if (value === 'blocked') {
    return 'red'
  }

  if (value === 'registration') {
    return 'yellow'
  }

  if (value === 'onboarding') {
    return 'orange'
  }

  return 'red'
}

export const getColumns = (filter?: APITYPES.DoersFilterType) => {
  return [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      defaultFilteredValue: filter?.name,
      filteredValue: filter?.name ?? '',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone_number',
      key: 'phone_number',
      defaultFilteredValue: filter?.phone_number,
      filteredValue: filter?.phone_number ?? '',
      align: 'center',
    },
    {
      title: <Tooltip overlay='Период отсрочки показа доступных смен'>Период отсрочки</Tooltip>,
      dataIndex: 'delay',
      key: 'delay',
      align: 'center',
    },
    {
      title: 'Состояние',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      render: (value: APITYPES.Doer) => {
        return (
          <Tag
            color={getColorTagByState(value.state.name)}
          >
            { /*@ts-ignore*/ }
            { DoerStateTranslation[value.state.name] }
          </Tag>
        )
      },
      filteredValue: filter?.state ?? [],
      filterMultiple: false,
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      key: 'roles',
      align: 'center',
      width: '20%',
      render: (doerRoles?: APITYPES.DoerRole[]) => {
        if (!doerRoles) {return}
        
        return (
          doerRoles.map((role, index) => (
            <Tag
              key={index}
              style={{ marginBottom: '4px' }}
            >
              { role.name }
            </Tag>
          ))
        )
      },
    },
    {
      title: 'Последняя смена',
      dataIndex: 'last_shift_date',
      key: 'last_shift_date',
      align: 'center',
      render: (d: Date) => d ? moment(d).format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Следующая смена',
      dataIndex: 'next_shift_date',
      key: 'next_shift_date',
      align: 'center',
      render: (d: Date) => d ? moment(d).format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Telegram URL',
      dataIndex: 'telegram_url',
      key: 'telegram_url',
      align: 'center',
      render: (_: any, doer: APITYPES.Doer) => {
        if (doer?.telegram_url === undefined || doer?.telegram_url === null) {
          return null
        }

        const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
          event.stopPropagation()

          return true
        }
        
        return (
          <a
            href={doer.telegram_url}
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleClick}
          >
            { doer?.telegram_url }
          </a>
        )
      },
    },
  ]
}

export const getRows = (doers: APITYPES.Doer[]) => {
  return doers.map((doer) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state') {
        return
      }
      //@ts-ignore
      row[column.dataIndex] = doer[column.dataIndex]

      return
    })
    row.state = doer
    row.key = doer.id

    return row
  })
}