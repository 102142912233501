// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--title--hlj-t{font-size:24px}.styles-module--statisticsBlock--veUZm{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%}.styles-module--card--odL42{-webkit-box-shadow:3px 2px 5px rgba(0,0,0,.15);box-shadow:3px 2px 5px rgba(0,0,0,.15);cursor:pointer;width:30%;min-width:300px;margin-right:20px}.styles-module--fields--agQ2X{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%}.styles-module--tagBlock--Ldhwl{padding:10px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Home/styles.module.sass"],"names":[],"mappings":"AAEA,6BACI,cAAA,CAEJ,uCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CAEJ,4BACI,8CAAA,CAAA,sCAAA,CACA,cAAA,CACA,SAAA,CACA,eAAA,CACA,iBAAA,CAEJ,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CAEJ,gCACI,YAAA","sourcesContent":["@import 'vars'\n\n.title\n    font-size: 24px\n    \n.statisticsBlock\n    display: flex\n    width: 100%\n\n.card\n    box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.15)\n    cursor: pointer\n    width: 30%\n    min-width: 300px\n    margin-right: 20px\n\n.fields\n    display: flex\n    flex-direction: column\n    width: 100%\n\n.tagBlock\n    padding: 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles-module--title--hlj-t",
	"statisticsBlock": "styles-module--statisticsBlock--veUZm",
	"card": "styles-module--card--odL42",
	"fields": "styles-module--fields--agQ2X",
	"tagBlock": "styles-module--tagBlock--Ldhwl"
};
export default ___CSS_LOADER_EXPORT___;
