import React from 'react'
import { Modal } from 'antd'

import { useFilterDrawerContext } from '../../context'

import { Wizard } from './Wizard'


export const CommitModal = () => {
  const { commitModal: isOpen, toggleCommitModal: onClose } =
    useFilterDrawerContext()

  return (
    <Modal
      title='Фиксировать смены'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={1200}
      footer={false}
    >
      <Wizard onClose={onClose} />
    </Modal>
  )
}
