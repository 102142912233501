import React from 'react'
import { Button, Input } from 'antd'
import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'


export const getColumns = (
  onDelete?: (productId: string) => void,
  onPriceChange?: (productId: string, body: APITYPES.Tariffs.Product.PUT.Req) => void,
) => {
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: 100,
      render: ({productId, price}: { productId: string, price: number }) => (
        <Input
          size="small"
          type="number"
          defaultValue={price}
          onBlur={onPriceChange
            ? (e) => {
              const newPrice = +e.target.value
              if(price !== newPrice) onPriceChange(productId, {price: newPrice})
            }
            : undefined
          }
        />
      ),
    },
    {
      title: 'Создан',
      dataIndex: 'created',
      key: 'created',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 120,
      render: (productId: string) => (
        <Button type="link" onClick={onDelete ? () => onDelete(productId) : undefined}>Удалить</Button>
      ),
    },
  ]
}

export const getRows = (products: APITYPES.CustomerTariffProduct[]) => {
  return products.map((product) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'created') {
        row[column.dataIndex] = getFormattedDate(product.created)
      } else if (column.dataIndex === 'price') {
        row[column.dataIndex] = {productId: product.product_id, price: product.price}
      } else if (column.dataIndex === 'actions') {
        row[column.dataIndex] = product.product_id
      } else {
        //@ts-ignore
        row[column.dataIndex] = product[column.dataIndex]
      }

      return
    })
    row.key = product.product_id

    return row
  })
}