import { APITYPES } from '../../types/apitypes'
import { action, Actions, ThAction } from '../../actions'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'
import { showError } from '../../components/modals/ErrorModal'
import { openNotification, successNotification } from '../../components/modals/OperationNotification'


export const getDoerVacancies = (filter?: APITYPES.DoerVacancies.Get.Query): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerVacancies', StatusRequest.LOADING))
    
    try {
      const resp = await api.get(APITYPES.DoerVacancies.Get.URL, {
        params: {
          ...filter,
          offset: getState().doerVacanciesReducer.doerVacancies?.length || 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_VACANCIES, {
          doerVacancies: resp,
          hasMore: !(resp.length < 20),
        }))

        dispatch(changeRequestState('getDoerVacancies', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('getDoerVacancies', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addVacancy = (body: APITYPES.DoerVacancies.Post.Req, onFinish?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addVacancy', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.DoerVacancies.Post.URL, body)

      if (resp) {
        onFinish?.()
        dispatch(getDoerVacancies({ offset: 0 }))
        dispatch(changeRequestState('addVacancy', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addVacancy', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getVacancy = (vacancy_id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getVacancy', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerVacancies.GetVacancy.URL, { replaceUrl: { vacancy_id } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_VACANCY, resp))
        dispatch(changeRequestState('getVacancy', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getVacancy', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateVacancy = (data: {
  vacancy_id: string
  value: number
}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('updateVacancy', StatusRequest.LOADING))

    const { value, vacancy_id } = data

    try {
      const resp = await api.put(APITYPES.DoerVacancies.PutVacancy.URL, { value }, { replaceUrl: { vacancy_id } })

      if (resp) {
        dispatch(getVacancy(vacancy_id))
        successNotification('Потребность успешно изменена!')
        dispatch(changeRequestState('updateVacancy', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateVacancy', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const openVacancyById = (data: {
  vacancy_id: string
  comment?: string
}, onFinish?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('openVacancyById', StatusRequest.LOADING))

    const { comment, vacancy_id } = data

    try {
      const resp = await api.post(APITYPES.DoerVacancies.OpenVacancyById.URL, { comment }, { replaceUrl: { vacancy_id } })

      if (resp) {
        onFinish?.()
        dispatch(getVacancy(vacancy_id))
        openNotification('Статус успешно изменен')
        dispatch(changeRequestState('openVacancyById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('openVacancyById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const cancelVacancyById = (data: {
  vacancy_id: string
  comment?: string
}, onFinish?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('cancelVacancyById', StatusRequest.LOADING))

    const { comment, vacancy_id } = data

    try {
      const resp = await api.post(APITYPES.DoerVacancies.CancelVacancyById.URL, { comment }, { replaceUrl: { vacancy_id } })

      if (resp) {
        onFinish?.()
        dispatch(getVacancy(vacancy_id))
        openNotification('Статус успешно изменен')
        dispatch(changeRequestState('cancelVacancyById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('cancelVacancyById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}