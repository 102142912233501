// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--interval--9Gxhp{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module--dropdown--dibnv{margin-left:10px}.styles-module--item--7mcbM{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--endText--naG\\+q{margin-left:10px;margin-bottom:20px}.styles-module--startText--V\\+Isl{margin-right:10px;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/WorkingTime/Day/AddModal/styles.module.sass"],"names":[],"mappings":"AAAA,gCACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEJ,gCACI,gBAAA,CAEJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,gCACI,gBAAA,CACA,kBAAA,CAEJ,kCACI,iBAAA,CACA,kBAAA","sourcesContent":[".interval\n    width: 100%\n    display: flex\n\n.dropdown\n    margin-left: 10px\n\n.item\n    display: flex\n    align-items: center\n\n.endText\n    margin-left: 10px\n    margin-bottom: 20px\n\n.startText\n    margin-right: 10px\n    margin-bottom: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"interval": "styles-module--interval--9Gxhp",
	"dropdown": "styles-module--dropdown--dibnv",
	"item": "styles-module--item--7mcbM",
	"endText": "styles-module--endText--naG+q",
	"startText": "styles-module--startText--V+Isl"
};
export default ___CSS_LOADER_EXPORT___;
