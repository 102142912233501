import React, { useEffect, useRef, useState } from 'react'
import { Button, Row, Space, Table } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchStaticLocations } from 'AC/shifts/locations'
import { fetchBillSyncState, fetchOperations, fetchTasks } from 'AC/shifts/operations'
import { isObjectEmpty } from 'utils/filterUtils'
import { getTableYScroll } from 'utils/scrollUtils'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { navigate } from 'utils/tabelUtils'
import { FilterIcon } from 'components/FilterIcon'

import { Filters } from './Filters'
import { OperationOrdersModal } from './OperationOrdersModal'
import { OperationNewModal } from './NewOperationModal'
import { Period } from './Period'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  isLoading: state.requestsReducer.fetchOperations === StatusRequest.LOADING,
  isOperOrdersLoading:
    state.requestsReducer.createOperationOrders === StatusRequest.LOADING,
  operations: state.operationsReducer.operations,
  hasMoreOperations: state.operationsReducer.hasMoreOperations,
  operationsFilter: state.operationsReducer.operationsFilter,
  tasks: state.operationsReducer.tasks,
  locations: state.shiftsReducer.allLocations,
  billSyncState: state.operationsReducer.billSyncState,
})

const billSyncSelector = (state: State) => ({ isBillSyncLoading: state.requestsReducer.fetchBillSyncState })

export const Operations = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = Object.fromEntries(
    new URL(window.location.href).searchParams.entries(),
  )
  const lastRowRef = useRef(null)
  const isFirstRef = useRef<boolean>(true)
  const entry = useIntersectionObserver(lastRowRef, {})
  const {
    isLoading,
    operations,
    hasMoreOperations,
    operationsFilter,
    isOperOrdersLoading,
    tasks,
    locations,
    billSyncState,
  } = useSelector(selector)
  const { isBillSyncLoading } = useSelector(billSyncSelector)

  useEffect(() => {
    if (!tasks.length) {dispatch(fetchTasks())}
    if (!locations.length) {dispatch(fetchStaticLocations())}

    return () => {
      dispatch(action(Actions.CLEAR_OPERATIONS, []))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(fetchBillSyncState())
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)

      dispatch(action(Actions.SET_OPERATIONS_FILTER, { ...parsed }))
    } else if (!isObjectEmpty(operationsFilter)) {
      history.push(ROUTES.DOERS.OPERATIONS.PARAMS.createPath(operationsFilter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false
      
      return
    }
    if (isLoading) {
      return
    }

    dispatch(fetchOperations(operationsFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationsFilter])

  // load more when intersecting last table row
  useEffect(() => {
    if (!isLoading && hasMoreOperations && entry?.isIntersecting) {
      dispatch(fetchOperations(operationsFilter))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting])


  const [isFilterOpen, setFilterOpen] = useState(false)
  const [isPeriodOpen, setPeriodOpen] = useState(false)
  const toggleFilters = () => setFilterOpen(!isFilterOpen)
  const togglePeriod = () => setPeriodOpen(!isPeriodOpen)

  const [operModal, setOperModal] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const toggleOperModal = () => setOperModal(!operModal)
  const toggleOpenCreateModal = () => setOpenCreateModal(!openCreateModal)

  return (
    <>
      <div className={styles.header}>
        <Row>
          <Button
            type='primary'
            onClick={toggleOpenCreateModal}
          >
            Создать операцию
          </Button>
        </Row>
        <Row>
          <Space size={8}>
            <Button
              type='primary'
              onClick={togglePeriod}
              loading={isOperOrdersLoading}
            >
              Закрыть период
            </Button>
            <Button
              icon={<FilterIcon isFilterEmpty={isObjectEmpty(operationsFilter)} />}
              onClick={toggleFilters}
            />
          </Space>

        </Row>

      </div>

      <Table
        sticky
        loading={isLoading}
        className={styles.table}
        columns={getColumns() as any}
        dataSource={getRows(operations)}
        pagination={false}
        bordered
        scroll={{ y: getTableYScroll(186) }}
        onRow={
          (record: any, index) => {
            return {
              ref: index === operations?.length - 1 ? lastRowRef : undefined,
              onClick: navigate(ROUTES.DOERS.OPERATIONS.createPath(record.operation.id), history),
            }
          }
        }
      />
      {
        isPeriodOpen && (
          <Period
            isOpen={isPeriodOpen}
            onClose={togglePeriod}
            isBillSyncLoading={isBillSyncLoading}
            billSyncState={billSyncState}
          />
        )
      }
      <Filters
        isOpen={isFilterOpen}
        onClose={toggleFilters}
      />
      <OperationOrdersModal
        isOpen={operModal}
        onClose={toggleOperModal}
      />
      <OperationNewModal
        isOpen={openCreateModal}
        onClose={toggleOpenCreateModal}
      />
    </>
  )
}
