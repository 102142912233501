import React, { useEffect, useState, useMemo } from 'react'
import { Col, DatePicker, Drawer, Form, Row, Space, Spin } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'

import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'
import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { useDebounce } from 'utils/debounce'
import { FormFooter } from 'components/FormFooter'
import { closeOperationOrders, fetchBillSyncState } from 'AC/shifts/operations'


const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'

type Props = {
  isOpen: boolean
  onClose: () => void
  billSyncState: any
  isBillSyncLoading: any
}

const selector = (state: State) => ({
  operationsFilter: state.operationsReducer.operationsFilter,
  tasks: state.operationsReducer.tasks,
  locations: state.shiftsReducer.allLocations,
})

export const Period = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { operationsFilter: filter } = useSelector(selector)
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const timezone = useGetTimezoneFromLocalstorage()

  /** states */
  const [inputs] = useState<{
    doer: string | undefined
    grz: string | undefined
  }>({
    doer: params?.doer_name ? params?.doer_name : filter?.doer_name,
    grz: params?.grz ? params?.grz : filter?.grz,
  })
  const debouncedInputs = useDebounce(inputs, 500)

  /** effects */
  useEffect(() => {
    const parsed = qs.parse(params)

    onFilterChange({
      ...parsed,
      //@ts-ignore
      doer_name: debouncedInputs.doer,
      grz: debouncedInputs.grz,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputs])


  /** handlers */
  const onFilterChange = (partial: Partial<APITYPES.OperationsFilter>) => {
    history.push(ROUTES.DOERS.OPERATIONS.PARAMS.createPath({
      ...filter,
      ...partial,
    }))
    dispatch(action(Actions.CLEAR_OPERATIONS, []))
    dispatch(action(Actions.SET_OPERATIONS_FILTER, {
      ...filter,
      ...partial,
    }))
  }

  const { isOpen, onClose, isBillSyncLoading, billSyncState } = props
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const [begin, end] = values.dates

    dispatch(
      closeOperationOrders({
        begin: begin.format(backFormat),
        end: end.format(backFormat),
      })
    )
  }

  useEffect(() => {
    dispatch(fetchBillSyncState())
  }, [dispatch])
  
  const start = useMemo(() => {
    if (!billSyncState?.start) {
      return '___'
    }
    
    const date = moment(billSyncState?.start).format('YYYY-MM-DD')
    const time = moment(billSyncState?.start).format('HH:mm:ss Z')
    
    const shift = formatDateTime({
      start: time,
      targetTimezone: timezone,
    })

    return `${date} ${shift}`
  }, [billSyncState?.start, timezone])

  const end = useMemo(() => {
    if (!billSyncState?.stop) {
      return '___'
    }
    
    const date = moment(billSyncState?.stop).format('YYYY-MM-DD')
    const time = moment(billSyncState?.stop).format('HH:mm:ss Z')
    
    const shift = formatDateTime({
      start: time,
      targetTimezone: timezone,
    })

    return `${date} ${shift}`
  }, [billSyncState?.stop, timezone])
  
  return (
    <Drawer
      title='Период'
      placement='right'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name='dates'
          label='Период'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <DatePicker.RangePicker format={dateFormat} />
        </Form.Item>
        <Space
          direction='vertical'
          size={12}
        >
          {
            isBillSyncLoading === 'LOADING' ? (
              <Spin size='small' />
            ) : (
              <>
                <Space
                  direction='vertical'
                  size={10}
                >
                  <Row justify='space-between'>
                    <Col>
                      <b>Закрытие периода:</b>
                      { ' ' }

                    </Col>
                    <Col>
                      { billSyncState.period ? billSyncState.period : '___' }
                    </Col>
                  </Row>
                  <Row justify='space-between'>
                    <Col>
                      <b>Начато:</b>
                      { ' ' }
                    </Col>
                    <Col>
                      { start }
                    </Col>
                  </Row>
                  <Row justify='space-between'>
                    <Col>
                      <b>Завершено:</b>
                      { ' ' }

                    </Col>
                    <Col>
                      { end }
                    </Col>
                  </Row>

                  <Row justify='space-between'>
                    <Col>
                      <b>Статус:</b>


                    </Col>
                    <Col>
                      {
                        billSyncState.state === 'success' ? (
                          <CheckCircleFilled
                            style={
                              {
                                color: '#52c41a',
                                verticalAlign: 'middle',
                                fontSize: '20px',
                              }
                            }
                          />
                        ) : billSyncState.state === 'failed' ? (
                          <CloseCircleFilled
                            style={
                              {
                                color: '#ff4d4f',
                                fontSize: '20px',
                                verticalAlign: 'middle',
                              }
                            }
                          />
                        )
                          : (
                            '___'

                          )
                      }
                    </Col>
                  </Row>
                </Space>

              </>

            )
          }
          <FormFooter
            onCancel={onClose}
            okText='Закрыть'
          />
        </Space>

      </Form>

    </Drawer >
  )
}