import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { addShift } from 'AC/shifts/shifts'
import { getEnumKeys } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'
import { MomentType } from '../index'
import { useFilterDrawerContext } from '../../../context'
import { required } from 'utils/formUtils'
import { getRolesOptions } from 'utils/select-options'

import { getEmployerOptions, getLocationsOptions, getTypesOptions } from './utils'
import styles from './styles.module.sass'


const cities = getEnumKeys(APITYPES.Cities)

type FormValues = {
  date: [MomentType, MomentType]
  shift_type_id: string
  location_id: string
  roles: { id: string, places: number }[]
  method: 'create' | 'import'
  employer_id: string
  time: MomentType
};

const dateFormat = 'YYYY-MM-DD'
const dateTimezoneFormat = 'YYYY-MM-DD HH:mm:ssZ'

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 19 },
}

const formItemLayout = { wrapperCol: { span: 24 } }

const selector = (state: State) => ({
  shiftTypes: state.shiftsReducer.shiftTypes,
  locations: state.shiftsReducer.locations,
  roles: state.shiftsReducer.shiftTypes,
  employers: state.shiftsReducer.employers,
})

export const CreateForm = () => {
  const { toggleAddShiftModal: onClose } = useFilterDrawerContext()
  const { shiftTypes, locations, employers } = useSelector(selector)

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isSelected, setIsSelected] = useState<boolean>(true)
  const [cityId, setCityId] = useState<number | null>(null)
  const [roles, setRoles] = useState<any>(null)

  const [addLoading, setLoading] = useState<boolean>(false)

  const timezone = localStorage.getItem('timezone')

  const handleSelectLocationChange = (id: string) => {
    if (id) {
      const locationId = locations.filter((location) => location.id === id)[0]
        .city_id

      setCityId(locationId)
      setIsSelected(false)
    } else {
      setIsSelected(true)
    }
  }

  const handleSelectShiftTypeChange = (id: string) => {
    if (id) {
      const filteredRoles = shiftTypes.filter(
        (type) => type.id === id && type.city_id === cityId
      )[0].roles

      setRoles(filteredRoles)
    }
  }

  const onFinish = (values: FormValues) => {
    setLoading(true)

    let [startDate, endDate] = values.date as [MomentType, MomentType];

    [startDate, endDate] = [startDate.startOf('day'), endDate.startOf('day')]

    const body: any = {
      date_from: startDate.format(dateFormat),
      date_to: endDate.format(dateFormat),
      shift_type_id: values.shift_type_id,
      location_id: values.location_id,
      roles: values.roles,
      method: 'create' as const,
      employer_id: values.employer_id,
    }

    if (values.time) {
      if (timezone) {
        body.time = momentTimezone(values.time)
          .tz(timezone, true)
          .format(dateTimezoneFormat)
      } else {
        body.time = values.time?.format(dateTimezoneFormat)
      }
    }

    dispatch(addShift(body))

    setLoading(false)
    onClose()
  }

  useEffect(() => {
    if (timezone) {
      momentTimezone.tz.setDefault(timezone)
    }
  }, [timezone])


  return (
    <Form
      {...formLayout}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='date'
        label='Период'
        rules={required}
        initialValue={null}
      >
        <DatePicker.RangePicker
          format='LL'
          className={styles.rangePicker}
        />
      </Form.Item>

      <Form.Item
        label='Отложенная публикация'
        name='time'
        initialValue={null}
      >
        <DatePicker
          format='LLL'
          showTime={
            {
              showHour: true,
              showMinute: true,
              showSecond: false,
            }
          }
          minuteStep={30}
          style={{ width: '100%' }}
          disabledDate={(date) =>  date.isBefore(moment(), 'minute')}
        />
      </Form.Item>

      <Form.Item
        name='employer_id'
        label='Заказчик'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          options={getEmployerOptions(employers)}
          filterOption={
            (input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        name='location_id'
        label='Локация'
        rules={required}
      >
        <Select
          className={styles.locationSelect}
          allowClear
          showSearch
          options={getLocationsOptions(locations)}
          filterOption={
            (input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
          }
          onChange={(e: string) => handleSelectLocationChange(e)}
        >
          {
            locations.map((location) => (
              <Select.Option
                value={location.id}
                key={location.id}
              >
                { `${cities[location.city_id - 1]}, ${location.name}` }
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>

      <Form.Item
        name='shift_type_id'
        label='Тип смены'
        rules={required}
      >
        <Select
          disabled={isSelected}
          allowClear
          showSearch
          options={getTypesOptions([cityId], shiftTypes)}
          filterOption={
            (input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
          }
          onChange={(e: string) => handleSelectShiftTypeChange(e)}
        />
      </Form.Item>

      <Form.List
        name='roles'
        initialValue={
          [
            {
              id: undefined,
              places: undefined,
            },
          ]
        }
      >
        {
          (fields, { add, remove }) => (
            <>
              <div className={styles.roleFieldsContainer}>
                {
                  fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      className={styles.roleFieldContainer}
                    >
                      <Form.Item
                        {...restField}
                        {...formItemLayout}
                        className={styles.roleSelect}
                        name={[name, 'id']}
                        rules={required}
                      >
                        <Select
                          disabled={isSelected}
                          allowClear
                          showSearch
                          options={getRolesOptions(roles)}
                          filterOption={
                            (input, option) =>
                              (option!.label as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        {...formItemLayout}
                        name={[name, 'places']}
                        rules={required}
                        className={styles.placesInput}
                      >
                        <Input
                          type='number'
                          placeholder='Кол-во мест'
                        />
                      </Form.Item>
                      <Button
                        className={styles.minusIcon}
                        disabled={key === 0}
                        type='ghost'
                        icon={<CloseOutlined />}
                        onClick={
                          () => {
                            remove(name)
                          }
                        }
                      />
                    </div>
                  ))
                }
              </div>

              <Form.Item {...formItemLayout}>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить роль
                </Button>
              </Form.Item>
            </>
          )
        }
      </Form.List>

      <FormFooter
        onCancel={onClose}
        isLoading={addLoading}
      />
    </Form>
  )
}
