// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--ywRdN{border:1px solid #ebedf0}.styles-module--tabs--9bQxg{height:110px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/Product/ProductHeader/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CAMF,4BACE,YAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)\n  //& > :nth-child(1)\n  //  padding-bottom: 6px\n  //  border-bottom: 1px solid rgb(235, 237, 240)\n\n\n.tabs\n  height: 110px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--ywRdN",
	"tabs": "styles-module--tabs--9bQxg"
};
export default ___CSS_LOADER_EXPORT___;
