// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--JCtF0{width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px}.styles-module--noPadding--EagY2{padding:0}", "",{"version":3,"sources":["webpack://./src/components/Loader/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CAEJ,iCACI,SAAA","sourcesContent":[".container\n    width: 100%\n    height: 100%\n    display: flex\n    justify-content: center\n    align-items: center\n    padding: 20px\n\n.noPadding\n    padding: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--JCtF0",
	"noPadding": "styles-module--noPadding--EagY2"
};
export default ___CSS_LOADER_EXPORT___;
