import React, {
  useEffect,
  useState,
} from 'react'
import {
  Button,
  DatePicker,
  Select,
} from 'antd'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'

import { ROUTES } from 'config/routes'
import {
  fetchRentByPeriod,
  sendToBilling,
} from 'AC/rent/rent'
import {
  action,
  Actions,
} from '../../../../../actions'
import { APITYPES } from '../../../../../types'

import {
  getFormattedDates,
  getObjectTypeStateFromSearch,
  getPickerStateFromSearch,
  M,
} from './utils'
import styles from './styles.module.sass'

import RentalObjectTypeItem = APITYPES.RentalObjectTypeItem


type Props = {
  hasBillingData: boolean
  objectTypes: RentalObjectTypeItem[]
}

export const RentHeader = ({ hasBillingData, objectTypes }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [picker, setPicker] = useState<[M, M] | undefined>(getPickerStateFromSearch(location.search) as [M, M] | undefined)
  const [objectTypeId, setObjectTypeId] = useState<string | undefined>(getObjectTypeStateFromSearch(location.search))
  const onPickerChange = (dates: [M, M] | null) => {
    if (!dates) {
      history.push(ROUTES.PARTNERS.RENT.path)
      dispatch(action(Actions.SET_RENT_DATA, null))
      return
    }
    const dateParams = getFormattedDates(dates);

    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath({
      ...dateParams,
      object_type_id: objectTypeId,
    }))
  }

  const onObjectTypeChange = (id: string) => {
    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath({
      object_type_id: id,
      ...picker ? getFormattedDates(picker) : {},
    }))
  }

  useEffect(() => {
    setPicker(getPickerStateFromSearch(location.search) as [M, M] | undefined)
    setObjectTypeId(getObjectTypeStateFromSearch(location.search))
  }, [location.search])


  const onUpdate = () => {
    if (!picker) return
    const dates = getFormattedDates(picker)
    dispatch(fetchRentByPeriod({
      ...dates,
      object_type_id: objectTypeId,
    }))
  }

  const onSendToBilling = () => {
    if (!picker) return
    const dates = getFormattedDates(picker)
    dispatch(sendToBilling({
      ...dates,
      object_type_id: objectTypeId,
    }))
  }

  return (
    <div className={styles.container}>
      <DatePicker.RangePicker
        value={picker}
        onChange={
          (values) =>
            // @ts-ignore
            onPickerChange(values)
        }
        format='L'
      />
      <Select
        allowClear
        showSearch
        placeholder='Тип объекта...'
        value={objectTypeId}
        onChange={onObjectTypeChange}
        filterOption={
          (input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          objectTypes.map((type) => (
            <Select.Option
              key={type.id}
              value={type.id}
            >
              { type.name }
            </Select.Option>
          ))
        }
      </Select>
      <Button
        disabled={!picker}
        onClick={onUpdate}
      >
        Расчитать аренду
      </Button>
      <Button
        disabled={!hasBillingData}
        onClick={onSendToBilling}
      >
        Закрыть период
      </Button>
    </div>
  )
}
