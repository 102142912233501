import React from 'react'
import { Form, Modal, Checkbox } from 'antd'
import { useSelector } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { downloadFile, MIME_TYPE } from 'utils/fileUtils'


export type Props = {
  isOpen: boolean
  onClose: () => void
}

const formLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  bill: state.billingReducer.bill,
  allDocuments: state.billingReducer.allDocuments,
})

const downloadMap = [
  {
    key: 'details',
    type: MIME_TYPE.EXCEL,
  },
  {
    key: 'act',
    type: MIME_TYPE.PDF,
  },
  {
    key: 'bill',
    type: MIME_TYPE.PDF,
  },
]

export const DownloadModal = (props: Props) => {
  const { isOpen, onClose } = props
  const { bill, allDocuments } = useSelector(selector)

  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    if (!bill) {
      return
    }

    const actName = bill.name.replace('Счет', 'Акт')

    downloadMap.forEach((item) => {
      const allDocumentsKey = allDocuments[item.key as keyof typeof allDocuments]

      if (values[item.key] && allDocumentsKey) {
        downloadFile({
          fileName: item.key === 'act' ? actName : bill.name,
          url: allDocumentsKey,
          fileType: item.type,
          shouldUseContentDisposition: true,
        })
      }
    })

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }


  return (
    <Modal
      title='Скачиваемые документы'
      visible={isOpen}
      onCancel={onClose}
      width={440}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={
          {
            'act': false,
            'bill': false,
            'details': true,
          }
        }
        {...formLayout}
      >
        {
          allDocuments.details && (
            <Form.Item
              name='details'
              valuePropName='checked'
              style={{ marginBottom: '0' }}
            >
              <Checkbox>Детализация</Checkbox>
            </Form.Item>
          )
        }
        {
          allDocuments.act && (
            <Form.Item
              name='act'
              valuePropName='checked'
              style={{ marginBottom: '0' }}
            >
              <Checkbox>Акт</Checkbox>
            </Form.Item>
          )
        }
        {
          allDocuments.bill && (
            <Form.Item
              name='bill'
              valuePropName='checked'
            >
              <Checkbox>Счет</Checkbox>
            </Form.Item>
          )
        }
        <FormFooter
          okText='Скачать'
          cancelText='Отмена'
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
