import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { dateFormat, getFormattedDate } from 'utils/dateUtils'


export const getColumnsForDoers = () => ([
  {
    title: 'Исполнитель',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Время',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    map: (doer:  APITYPES.DoerVacancyWithDoers) => getFormattedDate(doer.created, dateFormat),
  },
])

export const getRowsForDoers = (doers:  APITYPES.DoerVacancyWithDoers[]) => {
  return doers.map((doer) => {
    const row: any = {}

    getColumnsForDoers().forEach((column) => {
      row[column.key] = column?.map
        ? column.map(doer)
        : doer[column.key as keyof APITYPES.DoerVacancyWithDoers]
    })

    return row
  })
}


export const getColumns = () => ([
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    map: (doers_count: APITYPES.DoerVacancies.GetVacancy.Resp['doers_count'][0]) => doers_count.date,
  },
  {
    title: 'Нанято',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    width: '30%',
    map: (doers_count: APITYPES.DoerVacancies.GetVacancy.Resp['doers_count'][0]) => doers_count.count,
  },
])

export const getRows = (doerCounts: APITYPES.DoerVacancies.GetVacancy.Resp['doers_count']) => {
  return doerCounts.map((counts) => {
    const row: any = {}

    getColumns().forEach((column) => {
      row[column.key] = column?.map
        ? column.map(counts)
        : counts[column.key as keyof APITYPES.DoerVacancies.GetVacancy.Resp['doers_count'][0]]
    })
    row.key = counts.date + counts.count

    return row
  })
}
