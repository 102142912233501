import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { changeRequestState } from '../request'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'


export const fetchDoerTariffs = (filter?: APITYPES.DoerTariffFilter): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchDoerTariffs', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerTariff.GET.URL, { params: { ...filter } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFFS, resp))
        dispatch(changeRequestState('fetchDoerTariffs', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerTariffs', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const fetchDoerTariffsGroups = (beginDate?: string, endDate?: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchDoerTariffsGroups', StatusRequest.LOADING))
    const params = beginDate && endDate ? {
      begin: beginDate,
      end: endDate,
    } : {}

    try {
      const resp = await api.get(APITYPES.DoerTariff.Groups.GET.URL, { params: params as APITYPES.DoerTariff.Groups.GET.Req } )

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFFS_GROUPS, resp))
        dispatch(changeRequestState('fetchDoerTariffsGroups', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerTariffsGroups', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}


export const fetchDoerTariffById = (doerTariffId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchDoerTariffById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerTariff.GetById.URL, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF, resp))
        dispatch(changeRequestState('fetchDoerTariffById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerTariffById', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const addDoerTariff = (body: APITYPES.DoerTariff.POST.Req, onSuccess?: (id: string) => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerTariff', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.DoerTariff.POST.URL, body)

      if (resp) {
        if (onSuccess) {
          dispatch(action(Actions.SET_DOER_TARIFF, resp))
          onSuccess(resp.id)
        }
        dispatch(changeRequestState('addDoerTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerTariff', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const editDoerTariff = (doerTariffId: string, body: APITYPES.DoerTariff.PUT.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editDoerTariff', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.DoerTariff.PUT.URL, body, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF, resp))
        dispatch(changeRequestState('editDoerTariff', StatusRequest.LOADED))
        openNotification('Тариф изменен')
      }
    } catch (e: any) {
      dispatch(changeRequestState('editDoerTariff', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const deleteDoerTariff = (doerTariffId: string, onSuccess?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteDoerTariff', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.DoerTariff.DELETE.URL, { replaceUrl: { doerTariffId } })

      if (resp) {
        if (onSuccess) {onSuccess()}

        dispatch(changeRequestState('deleteDoerTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDoerTariff', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

// tasks
export const addDoerTariffTask = (doerTariffId: string, body: APITYPES.DoerTariff.Tasks.POST.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerTariffTask', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.DoerTariff.Tasks.POST.URL, body, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_TASKS, resp))
        dispatch(fetchDoerTariffById(doerTariffId))
        dispatch(changeRequestState('addDoerTariffTask', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerTariffTask', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const editDoerTariffTask = (
  taskId: string,
  body: APITYPES.DoerTariff.Tasks.PUT.Req,
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editDoerTariffTask', StatusRequest.LOADING))
    
    try {
      const resp = await api.put(APITYPES.DoerTariff.Tasks.PUT.URL, body, { replaceUrl: { taskId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_TASKS, resp))
        dispatch(changeRequestState('editDoerTariffTask', StatusRequest.LOADED))
        openNotification('Цена задачи изменена')
      }
    } catch (e: any) {
      dispatch(changeRequestState('editDoerTariffTask', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const deleteDoerTariffTask = (doerTariffId: string, taskId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteDoerTariffTask', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.DoerTariff.Tasks.DELETE.URL, { replaceUrl: { taskId } })

      if (resp) {
        dispatch(fetchDoerTariffById(doerTariffId))
        dispatch(changeRequestState('deleteDoerTariffTask', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDoerTariffTask', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

// shiftTypes
export const fetchDoerTariffShiftTypes = (doerTariffId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchDoerTariffShiftTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerTariff.ShiftTypes.GET.URL, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_SHIFT_TYPES, resp))
        dispatch(changeRequestState('fetchDoerTariffShiftTypes', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerTariffShiftTypes', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const addDoerTariffShiftTypes = (doerTariffId: string, body: APITYPES.DoerTariff.ShiftTypes.POST.Req):
ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerTariffShiftTypes', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.DoerTariff.ShiftTypes.POST.URL, body, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_SHIFT_TYPES, resp))
        dispatch(changeRequestState('addDoerTariffShiftTypes', StatusRequest.LOADED))
        dispatch(fetchDoerTariffById(doerTariffId))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerTariffShiftTypes', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const editDoerTariffShiftTypes = (
  shiftTypeId: string,
  body: APITYPES.DoerTariff.ShiftTypes.PUT.Req,
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editDoerTariffShiftTypes', StatusRequest.LOADING))
    
    try {
      const resp = await api.put(APITYPES.DoerTariff.ShiftTypes.PUT.URL, body, { replaceUrl: { shiftTypeId } })

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_SHIFT_TYPES, resp))
        dispatch(changeRequestState('editDoerTariffShiftTypes', StatusRequest.LOADED))
        openNotification('Цена задачи изменена')
      }
    } catch (e: any) {
      dispatch(changeRequestState('editDoerTariffShiftTypes', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const deleteDoerTariffShiftTypes = (doerTariffId: string, shiftTypeId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteDoerTariffShiftTypes', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.DoerTariff.ShiftTypes.DELETE.URL, { replaceUrl: { shiftTypeId } })

      if (resp) {
        dispatch(fetchDoerTariffById(doerTariffId))
        dispatch(changeRequestState('deleteDoerTariffShiftTypes', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDoerTariffShiftTypes', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

// tariff functions
export const fetchDoerTariffFunctions = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchDoerTariffFunctions', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.DoerTariff.Functions.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_DOER_TARIFF_FUNCTIONS, resp))
        dispatch(changeRequestState('fetchDoerTariffFunctions', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerTariffFunctions', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const fetchBillSyncStateV2 = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchBillSyncStateV2', StatusRequest.LOADING))
    try {
      const resp = await api.get(
        APITYPES.DoerTariff.GetTariffSyncStateV2.URL
      )

      if (resp) {
        dispatch(
          changeRequestState('fetchBillSyncStateV2', StatusRequest.LOADED)
        )
        dispatch(action(Actions.SET_TARIFFS_SYNC, resp))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchBillSyncStateV2', StatusRequest.ERROR))
      throw e
    }
  }
}

export const addDoerTariffShiftPrices = (
  doerTariffId: string,
  body: APITYPES.DoerTariff.ShiftPrices.POST.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addDoerTariffShiftPrices', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.DoerTariff.ShiftPrices.POST.URL, body, { replaceUrl: { doerTariffId } })

      if (resp) {
        dispatch(fetchDoerTariffById(doerTariffId))
        dispatch(changeRequestState('addDoerTariffShiftPrices', StatusRequest.LOADED))
        dispatch(fetchDoerTariffById(doerTariffId))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerTariffShiftPrices', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const editDoerTariffShiftPrices = (
  shiftCostId: string,
  body: APITYPES.DoerTariff.ShiftPrices.PUT.Req,
  tariffId: string
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editDoerTariffShiftPrices', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.DoerTariff.ShiftPrices.PUT.URL, body, { replaceUrl: { shiftCostId } })

      if (resp) {
        dispatch(fetchDoerTariffById(tariffId))
        dispatch(changeRequestState('editDoerTariffShiftPrices', StatusRequest.LOADED))
        openNotification('Цена задачи изменена')
      }
    } catch (e: any) {
      dispatch(changeRequestState('editDoerTariffShiftPrices', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}

export const deleteDoerTariffShiftPrices = (doerTariffId: string, shiftCostId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteDoerTariffShiftPrices', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.DoerTariff.ShiftPrices.DELETE.URL, { replaceUrl: { shiftCostId } })

      if (resp) {
        dispatch(fetchDoerTariffById(doerTariffId))
        dispatch(changeRequestState('deleteDoerTariffShiftPrices', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDoerTariffShiftPrices', StatusRequest.ERROR))
      showError(JSON.stringify(e.response.data))
      throw(e)
    }
  }
}
