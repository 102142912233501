import React from 'react'

import { NestedSwitchRoutes } from 'components/NestedSwitch'
import { ROUTES } from 'config/routes'

import { ClientType } from './ClientType'


export const routes: NestedSwitchRoutes = [{
  path: ROUTES.DIRECTORY.CLIENT_TYPES.ID.createPath(),
  component: <ClientType />,
}]