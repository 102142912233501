import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { updateSingleNews } from 'AC/news/news'
import { FormFooter } from 'components/FormFooter'


type Props = {
  onClose: () => void
}

const selector = (state: State) => ({ news: state.newsReducer.currentNews })

const formLayout = { labelCol: { span: 6 } }

export const EditForm = (props: Props) => {
  const { onClose } = props
  const { news } = useSelector(selector)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  if (!news) {return null}

  const onFinish = (values: any) => {
    dispatch(updateSingleNews(news.id,
      {
        image: values.image,
        body: values.body,
        title: values.title,
        publication_date: new Date().toDateString(),
      }))

    onClose()
    form.resetFields()
  }

  
  return (
    <Form
      form={form}
      {...formLayout}
      onFinish={onFinish}
    >
      <Form.Item
        label='Заголовок'
        name='title'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
        initialValue={news.title}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Контент'
        name='body'
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
        initialValue={news.body}
      >
        <TextArea autoSize={
          {
            minRows: 4,
            maxRows: 8,
          }
        }
        />
      </Form.Item>

      <Form.Item
        label='Дата'
        name='publication_date'
        initialValue={moment(news.publication_date)}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <DatePicker
          format='LLL'
          showTime={
            {
              showHour: true,
              showMinute: true,
            }
          }
          style={{ width: '50%' }}
        />
      </Form.Item>

      <Form.Item
        label='Изображение'
        name='image'
        initialValue={news.image}
      >
        <Input />
      </Form.Item>
      <FormFooter
        onCancel={onClose}
        okText='Изменить'
      />
    </Form>
  )
}
