import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { openNotification } from 'components/modals/OperationNotification'


export const fetchNews = (limit?: number, offset?: number): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchNews', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.News.GET.URL, {
        params: {
          limit: limit || 10,
          offset: offset ?? getState().newsReducer.news.length,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_NEWS, {
          news: resp,
          offset,
        }))

        dispatch(changeRequestState('fetchNews', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchNews', StatusRequest.ERROR))
    }
  }
}

export const addNews = (body: APITYPES.News.POST.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addNews', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.News.POST.URL, body)

      if (resp) {
        // dispatch(action(Actions.SET_NEWS, ))

        dispatch(changeRequestState('addNews', StatusRequest.LOADED))
        openNotification('Новость добавлена')
      }
    } catch (e) {
      dispatch(changeRequestState('addNews', StatusRequest.ERROR))
    }
  }
}

export const getSingleNews = (news_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getSingleNews', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.News.SingleNews.GET.URL, { replaceUrl: { news_id } })

      if (resp) {
        dispatch(action(Actions.SET_CURRENT_NEWS, resp))

        dispatch(changeRequestState('getSingleNews', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('getSingleNews', StatusRequest.ERROR))
    }
  }
}

export const deleteSingleNews = (news_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteSingleNews', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.News.SingleNews.DELETE.URL, { replaceUrl: { news_id } })

      if (resp) {
        // dispatch(action(Actions.SET_NEWS, ))

        dispatch(changeRequestState('deleteSingleNews', StatusRequest.LOADED))
        openNotification('Новость удалена')
      }
    } catch (e) {
      dispatch(changeRequestState('deleteSingleNews', StatusRequest.ERROR))
    }
  }
}

export const updateSingleNews = (news_id: string, body: APITYPES.News.SingleNews.PUT.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateSingleNews', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.News.SingleNews.PUT.URL, body, { replaceUrl: { news_id } })

      if (resp) {
        // dispatch(action(Actions.SET_NEWS, ))

        dispatch(changeRequestState('updateSingleNews', StatusRequest.LOADED))
        openNotification('Новость обновлена')
      }
    } catch (e) {
      dispatch(changeRequestState('updateSingleNews', StatusRequest.ERROR))
    }
  }
}

export const deleteNewsRole = (news_role_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteNewsRole', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.News.DeleteNewsRole.URL, { replaceUrl: { news_role_id } })

      if (resp) {
        // dispatch(action(Actions.SET_NEWS, ))

        dispatch(changeRequestState('deleteNewsRole', StatusRequest.LOADED))
        openNotification('Роль удалена')
      }
    } catch (e) {
      dispatch(changeRequestState('deleteNewsRole', StatusRequest.ERROR))
    }
  }
}