import React, { FC } from 'react'
import { Modal } from 'antd'

import { MatchingSteps } from './MatchingSteps'


export type Props = {
  isOpen: boolean
  onClose: () => void
  reportId?: string
};

export const MatchingModal: FC<Props>   = ({ isOpen, onClose, reportId }) => {
  return (
    <Modal
      title='Загрузка отчета Яндекс.ПРО'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={1200}
      footer={false}
    >
      <MatchingSteps
        reportId={reportId}
        onFinish={onClose}
      />
    </Modal>
  )
}
