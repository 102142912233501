import React, { FC, useState } from 'react'
import { Button, Input } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

import cls from './editable-span.module.sass'


export type EditableSpanPropsType = {
  value: string
  inputClasName?: string
  onEdit: (newValue: string) => void
}

export const EditableSpan: FC<EditableSpanPropsType> = ({ value, inputClasName, onEdit }) => {
  const [text, setText] = useState(value)
  const [editMode, setEditMode] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const trimmedText = text.trim()

  const onCancel = () => {
    setText(value)
    setEditMode(false)
  }


  const onSave = () => {
    if (!trimmedText.length || trimmedText === value) {
      return
    }

    onEdit(text)
    setEditMode(false)
  }

  return (
    <div
      className={cls.wrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {
        editMode ? (
          <div className={cls.actions}>
            <Input
              autoFocus
              value={text}
              className={inputClasName}
              onChange={(e) => setText(e.target.value)}
            />
            <Button
              disabled={!trimmedText || trimmedText === value}
              shape='circle'
              onClick={onSave}
              icon={<CheckOutlined />}
            />
            <Button
              shape='circle'
              onClick={onCancel}
              icon={<CloseOutlined />}
            />
          </div>
        )
          : (
            <span>
              { text }

              {
                isHovered && (
                  <Button
                    type='text'
                    onClick={() => setEditMode(true)}
                    icon={<EditOutlined />}
                  />
                )
              }
            </span>
          )
      }
    </div>
  )
}