// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--ImAWP{margin-bottom:20px;width:calc(100% - 10px)}.styles-module--buttons--xOmCC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.styles-module--button--t2Bb5{margin-bottom:10px}.styles-module--cars--CV6ev{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}.styles-module--car--vgg3B{margin:5px}.styles-module--search--Qbea\\+{margin-bottom:20px}.styles-module--main--v3zXY{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:calc(100vh - 90px)}.styles-module--table--hV2py{cursor:pointer}.styles-module--tableContainer---VLq9{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Users/styles.module.sass"],"names":[],"mappings":"AAAA,4BACI,kBAAA,CACA,uBAAA,CAEJ,+BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEJ,8BACI,kBAAA,CAEJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CAAA,cAAA,CAEJ,2BACI,UAAA,CAEJ,+BACI,kBAAA,CAEJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,yBAAA,CAEJ,6BACI,cAAA,CAEJ,sCACI,kBAAA,CAAA,iBAAA,CAAA,aAAA,CACA,eAAA","sourcesContent":[".card\n    margin-bottom: 20px\n    width: calc(100% - 10px)\n\n.buttons\n    display: flex\n    flex-direction: column\n\n.button\n    margin-bottom: 10px\n\n.cars\n    display: flex\n    flex-wrap: wrap\n\n.car\n    margin: 5px\n\n.search\n    margin-bottom: 20px\n\n.main\n    display: flex\n    flex-direction: column\n    height: calc(100vh - 90px)\n\n.table\n    cursor: pointer\n\n.tableContainer\n    flex: 1 1 auto\n    overflow: hidden"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--ImAWP",
	"buttons": "styles-module--buttons--xOmCC",
	"button": "styles-module--button--t2Bb5",
	"cars": "styles-module--cars--CV6ev",
	"car": "styles-module--car--vgg3B",
	"search": "styles-module--search--Qbea+",
	"main": "styles-module--main--v3zXY",
	"table": "styles-module--table--hV2py",
	"tableContainer": "styles-module--tableContainer---VLq9"
};
export default ___CSS_LOADER_EXPORT___;
