import { APITYPES } from 'types/apitypes'


export const getColumns = () => {
  return [
    {
      title: 'Марка',
      dataIndex: 'car_brand_name',
      key: 'car_brand_name',
      fixed: 'left',
    },
    {
      title: 'Модель',
      dataIndex: 'car_model_name',
      key: 'car_model_name',
    },
  ]
}

export const getRows = (cars: APITYPES.AllCarClasses.GetCars.Resp) => {
  const data = cars.map((car) => {
    const row: any = {}

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = car[column.dataIndex]

      return
    })
    row.key = car.car_model_id
    row.carClass = car

    return row
  })

  return data
}