import React, { useEffect, useRef, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { FilterIcon } from 'components/FilterIcon'
import { getTableYScroll } from 'utils/scrollUtils'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getFleets } from 'AC/fleets/fleets'
import { fetchTasks } from 'AC/shifts/operations'
import { getFleetsServices } from 'AC/fleets/fleetServices'
import { APITYPES } from 'types/apitypes'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { fetchAllCarClasses } from 'AC/directory/directory'

import styles from './styles.module.sass'
import { FleetPeriodModal } from './PeriodModal'
import { EditModalType, getColumns, getRows } from './utils'
import { CreateFleetServiceModal } from './CreateFleetServiceModal'
import { EditFleetServiceModal } from './EditFleetServiceModal'
import { Filters } from './Filters'


const selector = (state: State) => ({
  fleetsServices: state.fleetsReducer.fleetsServices,
  isLoading: state.requestsReducer.getFleetsServices === StatusRequest.LOADING,
  fleets: state.fleetsReducer.fleets,
  tasks: state.operationsReducer.tasks,
})

export const FleetServices = () => {
  const [createModal, setCreateModal] = useState(false)
  const [periodModal, setPeriodModal] = useState(false)
  const [filter, setFilter] = useState(false)
  const { isLoading, fleetsServices, fleets, tasks  } = useSelector(selector)
  const [editModal, setEditModal] = useState<EditModalType>({
    isOpen: false,
    fleetService: null,
  })
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!fleets.length) {dispatch(getFleets())}
    if (!tasks.length) {dispatch(fetchTasks())}
    if (!fleetsServices.length) {dispatch(getFleetsServices())}
    dispatch(fetchAllCarClasses())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])


  const toggleCreateModal = () => setCreateModal((prev) => !prev)
  const togglePeriodModal = () => setPeriodModal((prev) => !prev)
  const toggleFilter = () => setFilter((prev) => !prev)
  const openEditModal = (fleetService: APITYPES.FleetService) => setEditModal({
    isOpen: true,
    fleetService,
  })
  const closeEditModal = () => setEditModal({
    isOpen: false,
    fleetService: null,
  })


  return (
    <>
      <div className={styles.toolbar}>
        <div>
          <Button
            type='primary'
            onClick={toggleCreateModal}
          >
            Добавить тариф
          </Button>
          <Button
            type='primary'
            onClick={togglePeriodModal}
            style={{ marginLeft:'10px' }}
          >
            Закрыть период
          </Button>
        </div>
        <Button
          size='small'
          onClick={toggleFilter}
          icon={<FilterIcon isFilterEmpty />}
        />
      </div>

      <Table
        sticky
        size='small'
        loading={isLoading}
        columns={getColumns() as any}
        dataSource={getRows(fleetsServices, fleets)}
        pagination={false}
        bordered
        scroll={{ y: getTableYScroll(162) }}
        style={{ cursor: 'pointer' }}
        onRow={
          (record: any, index) => ({
            ref: index === fleetsServices?.length - 1 ? lastRowRef : undefined,
            onClick: () => openEditModal(record.service),
          })
          
        }
      />

      <CreateFleetServiceModal
        onClose={toggleCreateModal}
        isOpen={createModal}
      />

      <EditFleetServiceModal
        onClose={closeEditModal}
        isOpen={editModal.isOpen}
        fleetService={editModal.fleetService}
      />

      <FleetPeriodModal
        onClose={togglePeriodModal}
        isOpen={periodModal}
      />

      <Filters
        onClose={toggleFilter}
        isOpen={filter}
        isIntersecting={entry?.isIntersecting}
      />
    </>
  )
}
