import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchAllBrandModels } from 'AC/directory/directory'
import { createFleetCar } from 'AC/fleets/fleetsCars'
import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'


const { Option } = Select

type Props = {
  onClose: () => void
  isOpen: boolean
}

const selector = (state: State) => ({
  fleets: state.fleetsReducer.fleets,
  allBrands: state.directoryReducer.carBrands,
  brandModels: state.directoryReducer.carBrandModels,
  isModelsLoading: state.requestsReducer.fetchAllBrandModels === StatusRequest.LOADING,
})

const formLayout = { labelCol: { span: 5 } }

export const CreateFleetCarModal = ({ onClose, isOpen }: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { allBrands, brandModels, isModelsLoading, fleets } = useSelector(selector)
  const [selectedBrandId, setBrandId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!selectedBrandId) {return}
    dispatch(fetchAllBrandModels(selectedBrandId))
  }, [selectedBrandId])

  const onFinish = (values: any) => {
    dispatch(createFleetCar(values))
    onClose()
  }
  const afterClose = () => {
    form.resetFields()
    setBrandId(undefined)
    dispatch(action(Actions.SET_ALL_BRAND_MODELS, []))
  }

  return (
    <Modal
      title='Добавление автомобиля'
      visible={isOpen}
      onCancel={onClose}
      afterClose={afterClose}
      footer={false}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          label='Оператор'
          name='fleet_id'
          rules={required}
        >
          <Select>
            {
              fleets?.map((fleet) => (
                <Option
                  key={fleet.id}
                  value={fleet.id}
                >
                  { fleet.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Гос. номер'
          name='grz'
          rules={required}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Марка'>
          <Select
            showSearch
            value={selectedBrandId}
            onChange={(value) => setBrandId(value)}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              allBrands?.map((brand) => (
                <Option
                  key={brand.id}
                  value={brand.id}
                >
                  { brand.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Модель'
          name='car_model_id'
          rules={required}
        >
          <Select
            showSearch
            disabled={isModelsLoading || !selectedBrandId}
            loading={isModelsLoading}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              brandModels?.map((model) => (
                <Option
                  key={model.id}
                  value={model.id}
                >
                  { model.name }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <FormFooter onCancel={onClose} />
      </Form>
    </Modal>
  )
}
