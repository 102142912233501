import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDoerContractors, getPartnerContractors } from '../../../../../AC/billing/getContractors'
import { State } from '../../../../../stores/rootReducer'
import { DebounceSelect, SelectValue } from '../../../../../components/DebounceSelectDoer'
import { APITYPES } from '../../../../../types/apitypes'
import { api } from '../../../../../index'

import ContractorType = APITYPES.ContractorType
import Billing = APITYPES.Billing

// eslint-disable-next-line import/order
import type { SelectProps } from 'antd/es/select'


export type ResetContractor = {
  resetContractor: () => void
};

interface Props extends SelectProps {
  fetchContractorFromUrlId?: boolean
  labelInValue?: boolean
  extraOption?: SelectValue
  contractorType: ContractorType
  onFilterChange?: (partial: Partial<APITYPES.BillsFilter>) => void
}

export const ContractorSelector = forwardRef<ResetContractor, Props>(
  ({
    contractorType,
    onFilterChange,
    extraOption,
    fetchContractorFromUrlId = false,
    ...rest
  }, ref) => {
    const dispatch = useDispatch()
    const { contractors, filter } = useSelector((state: State) => {
      const {
        partnerContractors,
        doerContractors,
        billsFilter,
        billsDoersFilter,
      } = state.billingReducer
    
      return {
        contractors: contractorType === ContractorType.partner ? partnerContractors : doerContractors,
        filter: contractorType === ContractorType.partner ? billsFilter : billsDoersFilter,
      }
    })

    const contractorValue = useMemo(() => contractors?.map(({ id, legal_name }) => ({
      label: legal_name,
      value: id,
    })) ?? [], [contractors])

    const [contractor, setContractor] = useState<SelectValue | undefined | null>(
      contractorValue?.find((option) => option.value === filter?.contractor_id?.[0])
    )

    const onContractorSearch = async (value: string) => {
      const resp: Billing.GetContractors.Resp = await api.get(Billing.GetContractors.URL, {
        params: {
          type: contractorType,
          search: value,
          offset: 0,
          limit: 20,
        },
      })

      return resp?.map(({ id, legal_name }: {id: string, legal_name: string}) => ({
        label: legal_name,
        value: id,
      })) || []
    }
  
    useEffect(() => {
      if (extraOption) {
        contractorValue.unshift(extraOption)
      }
    }, [extraOption, contractorValue])

    useEffect(() => {
      if (!contractors.length) {
        if (contractorType === ContractorType.partner) {
          dispatch(getPartnerContractors())

          return
        }
        if (contractorType === ContractorType.doer) {
          dispatch(getDoerContractors())
        }
      }
    }, [dispatch, contractors, contractorType])

    useEffect(() => {
      if (fetchContractorFromUrlId && filter && 'contractor_id' in filter && filter.contractor_id?.[0]) {
        const id: string = Array.isArray(filter.contractor_id) ? filter.contractor_id?.[0] : filter.contractor_id;

        (async () => {
          const res = await api.get(Billing.GetSingleContractor.URL, { replaceUrl: { id } })

          if (res) {
            setContractor({
              label: res?.legal_name,
              value: res?.id,
            })
          }
        })()
      }
    }, [fetchContractorFromUrlId])
  
    useImperativeHandle(ref, () => {
      return { resetContractor: () => setContractor(null) }
    }, [])

    return (
      <DebounceSelect
        allowClear
        value={contractor}
        placeholder='Выберите контрагента...'
        fetchOptions={onContractorSearch}
        defaultOptions={contractorValue}
        onChange={
          (newValue) => {
            setContractor(newValue as SelectValue)
            onFilterChange?.({ contractor_id: newValue ? [(newValue as SelectValue)?.value] : undefined })
          }
        }
        style={{ width: '100%' }}
        {...rest}
      />
    )
  })