import { isSameDay, format } from 'date-fns'

import { APITYPES } from 'types/apitypes'


export const getNameByNum = (num: number | undefined) => {
  if (num === 1) {return 'Понедельник'}

  if (num === 2) {return 'Вторник'}

  if (num === 3) {return 'Среда'}

  if (num === 4) {return 'Четверг'}

  if (num === 5) {return 'Пятница'}

  if (num === 6) {return 'Суббота'}

  if (num === 7) {return 'Воскресенье'}

  return 'Регулярное'
}

export const isDayBelongsToInterval = (value: Date, interval: {
  start: Date
  end: Date
}) => {
  return (value >= interval.start && value <= interval.end) ||
  isSameDay(value, interval.end)
}

export const isEdited = (day: Date, schedules: APITYPES.Schedule[]) => {
  const period = schedules?.filter((item)=>{
    if (!item.active_from || !item.active_to) {return false}

    return isDayBelongsToInterval(day, {
      start: item.active_from,
      end: item.active_to,
    })
  })

  return !!period.length
}

const isScheduleWeekend = (day: Date, schedule: APITYPES.Schedule) => {
  const interval = schedule.intervals?.find((interval)=>
    (!interval.day_num || interval.day_num === parseFloat(format(day, 'i'))))
  
  return !interval
}

export const isWeekend = (day: Date, schedules: APITYPES.Schedule[]) => {
  //find custom schedules by day
  const period = schedules?.filter((item)=>{
    if (!item.active_from || !item.active_to) {
      return false
    }

    return isDayBelongsToInterval(day, {
      start: item.active_from,
      end: item.active_to,
    })
  })

  if (period.length) {
    return !!(period.find((item) => isScheduleWeekend(day, item)))
  }

  const defaultSchedule: APITYPES.Schedule | null = schedules ? schedules[0] : null

  if (!defaultSchedule) { return true }

  return isScheduleWeekend(day, defaultSchedule)
}

export const getInnerCustomSchedule = (schedules: APITYPES.Schedule[], selectedPeriod: {
  start: Date
  end: Date
}) => {
  const innerCustomSchedule = schedules?.find((item)=>{
    if (!selectedPeriod.start) {return}

    if (!item.active_from || !item.active_to) {return false}

    return item.active_from > selectedPeriod.start && item.active_to < selectedPeriod.end
  })

  return innerCustomSchedule
}