import * as H from 'history'

import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { ROUTES } from 'config/routes'


export const fetchClientTypes = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchClientTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.ClientTypes.Get.URL)

      if (resp) {
        dispatch(action(Actions.SET_DIRECTORY_CLIENT_TYPES, resp))

        dispatch(changeRequestState('fetchClientTypes', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchClientTypes', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createClientType = (clientType: APITYPES.ClientTypes.Post.Req, history: H.History): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createClientType', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.ClientTypes.Post.URL, clientType)

      if (resp) {
        dispatch(action(Actions.CREATE_DIRECTORY_CLIENT_TYPES, resp))
        dispatch(changeRequestState('createClientType', StatusRequest.LOADED))
        history.push(ROUTES.DIRECTORY.CLIENT_TYPES.ID.createPath(resp.id))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createClientType', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateAllServices = (service: APITYPES.AllServices.Get.Resp[number]): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateAllServices', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.AllServices.Put.URL, {
        name: service.name,
        description: service.description,
        provider: service.provider,
      }, { replaceUrl: { service_id: service.id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_DIRECTORY_SERVICES, service))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateAllServices', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateAllServices', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteService = (service_id: string, history: H.History): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteService', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.AllServices.Delete.URL, { replaceUrl: { service_id } })

      if (resp) {
        dispatch(action(Actions.DELETE_DIRECTORY_SERVICE, { id: service_id }))
        dispatch(changeRequestState('deleteService', StatusRequest.LOADED))
        openNotification('Удалено')
        history.push(ROUTES.DIRECTORY.SERVICES.path)
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteService', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const fetchAllServices = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchAllServices', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.AllServices.Get.URL)

      if (resp) {
        dispatch(action(Actions.SET_DIRECTORY_SERVICES, resp))

        dispatch(changeRequestState('fetchAllServices', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchAllServices', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const fetchAllService = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchAllService', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.AllServices.GetService.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_DIRECTORY_SERVICE, resp))

        dispatch(changeRequestState('fetchAllService', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchAllService', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchAllCarClasses = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchAllCarClasses', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.AllCarClasses.Get.URL)

      if (resp) {
        dispatch(action(Actions.SET_DIRECTORY_CAR_CLASSES, resp))

        dispatch(changeRequestState('fetchAllCarClasses', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchAllCarClasses', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createService = (service: APITYPES.AllServices.Post.Req, history: H.History): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createService', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.AllServices.Post.URL, service)

      if (resp) {
        dispatch(action(Actions.CREATE_DIRECTORY_SERVICE, resp))
        dispatch(changeRequestState('createService', StatusRequest.LOADED))
        openNotification('Услуга добавлена')
        history.push(ROUTES.DIRECTORY.SERVICES.ID.createPath(resp.id))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createService', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const fetchServices = (clientTypeId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchServices', StatusRequest.LOADING))

    try {
      const resp =
      await api.get(APITYPES.Services.Get.URL, { replaceUrl: { client_type_id: clientTypeId } })

      if (resp) {
        dispatch(action(Actions.SET_CLIENT_TYPE_SERVICES, resp))

        dispatch(changeRequestState('fetchServices', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchServices', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addService = (service: APITYPES.AllServices.Get.Resp[number], clientTypeId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addService', StatusRequest.LOADING))

    try {
      const resp =
      await api.post(APITYPES.Services.Post.URL, {
        service_id: service.id,
        position: getState().directoryReducer.clientTypeServices.length + 1,
      }, { replaceUrl: { id: clientTypeId } })

      if (resp) {
        dispatch(action(Actions.ADD_SERVICE, service))

        dispatch(fetchServices(clientTypeId))

        dispatch(changeRequestState('addService', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addService', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchCarClasses = (clientTypeId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarClasses', StatusRequest.LOADING))

    try {
      const resp =
      await api.get(APITYPES.CarClasses.Get.URL, { replaceUrl: { client_type_id: clientTypeId } })

      if (resp) {
        dispatch(action(Actions.SET_CLIENT_TYPE_CAR_CLASSES, resp))

        dispatch(changeRequestState('fetchCarClasses', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCarClasses', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addCarClass = (carClass: APITYPES.CarClasses.Get.Resp[number], clientTypeId: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addCarClass', StatusRequest.LOADING))

    try {
      const resp =
      await api.post(APITYPES.CarClasses.Post.URL, {
        car_class_id: carClass.id,
        car_class_name: carClass.name,
        position: getState().directoryReducer.clientTypeCarClasses.length + 1,
      }, { replaceUrl: { client_type_id: clientTypeId } })

      if (resp) {
        dispatch(action(Actions.ADD_CAR_CLASS, {
          ...carClass,
          position: getState().directoryReducer.clientTypeCarClasses.length + 1,
        }))

        dispatch(changeRequestState('addCarClass', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addCarClass', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createCarClass = (body: APITYPES.AllCarClasses.Post.Req, history: H.History): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createCarClass', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.AllCarClasses.Post.URL, body)

      if (resp) {
        dispatch(action(Actions.CREATE_DIRECTORY_CAR_CLASSES, resp))
        dispatch(changeRequestState('createCarClass', StatusRequest.LOADED))
        history.push(ROUTES.DIRECTORY.CAR_CLASSES.ID.createPath(resp.id))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createCarClass', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const updateCarClass =
(client_type_id: string, car_class_id: string, carClass: APITYPES.CarClasses.Put.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateCarClass', StatusRequest.LOADING))

    try {
      const resp =
      await api.put(APITYPES.CarClasses.Put.URL, carClass, {
        replaceUrl: {
          client_type_id,
          car_class_id,
        },
      })

      if (resp) {
        dispatch(action(Actions.UPDATE_CLIENT_TYPE_CAR_CLASSES, {
          position: carClass.position,
          id: car_class_id,
        }))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateCarClass', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateCarClass', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchCarClassCars = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchCarClassCars', StatusRequest.LOADING))

    try {
      const id = getState().directoryReducer.carClass?.id

      if (!id) {return}

      const resp =
      await api.get(APITYPES.AllCarClasses.GetCars.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_CAR_CLASS_CARS, resp))

        dispatch(changeRequestState('fetchCarClassCars', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCarClassCars', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateService =
(client_type_id: string, service_id: string, service: APITYPES.Services.Put.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateService', StatusRequest.LOADING))

    try {
      const resp =
      await api.put(APITYPES.Services.Put.URL, service, {
        replaceUrl: {
          client_type_id,
          service_id,
        },
      })

      if (resp) {
        dispatch(action(Actions.UPDATE_CLIENT_TYPE_SERVICES, {
          position: service.position,
          id: service_id,
        }))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateService', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateService', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchClientType = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchClientType', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.ClientTypes.GetClientType.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_DIRECTORY_CLIENT_TYPE, resp))

        dispatch(changeRequestState('fetchClientType', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchClientType', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addCarClassCar = (car_model_id: string, car_class_id?:string,): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addCarClassCar', StatusRequest.LOADING))

    try {
      const id = car_class_id ? car_class_id : getState().directoryReducer.carClass?.id
      const car_brand_id = getState().directoryReducer?.currentCarModelInfo?.brand_id

      if (!id) {return}

      const resp =
      await api.put(APITYPES.AllCarClasses.AddCar.URL, {}, {
        replaceUrl: {
          car_class_id: id,
          car_model_id,
        },
      })

      if (resp) {
        if (car_class_id && car_brand_id) {
          dispatch(fetchCarModelCarClasses(car_brand_id, car_model_id))
        } else {
          dispatch(fetchCarClassCars())
        }

        openNotification('Добавленно')
        
        dispatch(changeRequestState('addCarClassCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addCarClassCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteClientTypeService =
(client_type_id: string, service_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteClientTypeService', StatusRequest.LOADING))

    try {
      const resp =
      await api.delete(APITYPES.Services.Delete.URL, {
        replaceUrl: {
          client_type_id,
          service_id,
        },
      })

      if (resp) {
        dispatch(fetchServices(client_type_id))

        openNotification('Удалено')

        dispatch(changeRequestState('deleteClientTypeService', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteClientTypeService', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateClientTypes = (clientType: APITYPES.ClientTypes.Get.Resp[number]): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateClientTypes', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.ClientTypes.Put.URL, {
        name: clientType.name,
        position: clientType.position,
        code: clientType.code,
      }, { replaceUrl: { id: clientType.id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_DIRECTORY_CLIENT_TYPES, clientType))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateClientTypes', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateClientTypes', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteCarClassCar = (car_model_id: string, car_class_id?: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteCarClassCar', StatusRequest.LOADING))

    try {
      const id = car_class_id ? car_class_id : getState().directoryReducer.carClass?.id
      const car_brand_id = getState().directoryReducer?.currentCarModelInfo?.brand_id

      if (!id) {return}

      const resp =
      await api.delete(APITYPES.AllCarClasses.AddCar.URL, {
        replaceUrl: {
          car_class_id: id,
          car_model_id,
        },
      })

      if (resp) {
        if (car_class_id && car_brand_id) {
          dispatch(fetchCarModelCarClasses(car_brand_id, car_model_id))
        } else {
          dispatch(fetchCarClassCars())
        }

        dispatch(changeRequestState('deleteCarClassCar', StatusRequest.LOADED))
        openNotification('Удалено')
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteCarClassCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteClientTypeCarClass =
(client_type_id: string, car_class_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteClientTypeCarClass', StatusRequest.LOADING))

    try {
      const resp =
      await api.delete(APITYPES.CarClasses.Delete.URL, {
        replaceUrl: {
          client_type_id,
          car_class_id,
        },
      })

      if (resp) {
        dispatch(fetchCarClasses(client_type_id))

        openNotification('Удалено')

        dispatch(changeRequestState('deleteClientTypeCarClass', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteClientTypeCarClass', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteClientType = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteClientType', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.ClientTypes.Delete.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.DELETE_DIRECTORY_CLIENT_TYPES, { id }))
        dispatch(changeRequestState('deleteClientType', StatusRequest.LOADED))
        openNotification('Удалено')
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteClientType', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const fetchAllCarBrands = (): ThAction => {
  return async(dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchAllCarBrands', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Cars.GetBrands.URL)

      if (resp) {
        dispatch(action(Actions.SET_ALL_CAR_BRANDS, resp))

        dispatch(changeRequestState('fetchAllCarBrands', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchAllCarBrands', StatusRequest.ERROR))
    }
  }
}

export const fetchAllBrandModels = (id: string): ThAction => {
  return async(dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchAllBrandModels', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Cars.GetModels.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_ALL_BRAND_MODELS, resp))

        dispatch(changeRequestState('fetchAllBrandModels', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchAllBrandModels', StatusRequest.ERROR))
    }
  }
}

export const fetchCarModelInfo = (brand_id: string, model_id: string): ThAction => {
  return async(dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarModelInfo', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Cars.GetModelInfo.URL,
        {
          replaceUrl: {
            car_brand_id: brand_id,
            car_model_id: model_id,
          },
        })

      if (resp) {
        dispatch(action(Actions.SET_CAR_MODEL_INFO, resp))

        dispatch(changeRequestState('fetchCarModelInfo', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarModelInfo', StatusRequest.ERROR))
    }
  }
}

export const fetchCarModelCarClasses = (brand_id: string, model_id: string): ThAction => {
  return async(dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarModelCarClasses', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Cars.GetModelInfo.URL,
        {
          replaceUrl: {
            car_brand_id: brand_id,
            car_model_id: model_id,
          },
        })

      if (resp) {
        const { car_classes = [] } = resp

        dispatch(action(Actions.SET_CAR_MODEL_CAR_CLASSES, car_classes))

        dispatch(changeRequestState('fetchCarModelCarClasses', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarModelCarClasses', StatusRequest.ERROR))
    }
  }
}
