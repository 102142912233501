import React, { ReactNode } from 'react'
import { Table } from 'antd'
import cn from 'classnames'

import { InfiniteScroll } from 'components/InfiniteScroll'

import styles from './styles.module.sass'


type Props = {

  columns: any
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
  isReactive?: boolean
  className?: string
  children?: ReactNode
  onChange?: (_: any, filters: any) => void

}

export const InfiniteTable = (props: Props) => {
  const {
    isLoading,
    isReactive,
    children,
    columns,
    hasMore,
    className,
    loadMore,
    onChange,
  } = props

  return (
    <div className={cn(className, styles.container)}>
      <Table
        columns={columns}
        bordered
        size='large'
        pagination={false}
        scroll={
          {
            x: '100%',
            y: 0,
          }
        }
        onChange={onChange}
      />
      <InfiniteScroll
        isLoading={isLoading}
        isReactive={isReactive}
        hasMore={hasMore}
        loadMore={loadMore}
        className={styles.infiniteScroll}
      >
        { children }
      </InfiniteScroll>
    </div>
  )
}