import React from 'react'

import { APITYPES } from 'types/apitypes'

import { DoerRoles } from './DoerRoles'
import { DoerInformation } from './DoerInformation'
import styles from './styles.module.sass'


type Props = {
  doer: APITYPES.Doer
}

export const Information = ({ doer }: Props) => {
  return (
    <div className={styles.container}>
      <DoerInformation doer={doer} />

      <DoerRoles doer={doer} />
    </div>
  )
}
