import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { useShiftTabsContext } from '../../context'
import { setShiftDoerReserve } from '../../../../../../AC/shifts/offers'
import { fetchShiftDoers } from '../../../../../../AC/shifts/shifts'
import { showSureConfirmModified } from '../../../../../../components/modals/SureModal'

import { ShiftTabDoers } from './ShiftTabDoers'
import { ShiftInfo } from './ShiftInfo'
import styles from './styles.module.sass'


type Props = {
  shift: APITYPES.Shift
}

const selector = (state: State) => ({
  doers: state.shiftsReducer.shiftDoers,
  shiftOffersReverse: state.shiftsReducer.shiftOffersReverse,
  filter: state.shiftsReducer.shiftDoersFilter,
  isDoersLoading: state.requestsReducer.fetchShiftDoers === StatusRequest.LOADING,
})

export const ShiftTab = (props: Props) => {
  const { shift } = props
  const { doers, shiftOffersReverse, filter,isDoersLoading } = useSelector(selector)
  const dispatch = useDispatch()
  const { tabActions } = useShiftTabsContext()

  const onSelectReverseShift = ({ key, doer_name }: {key: string, doer_name: string}) => {
    showSureConfirmModified({
      title: 'Вы хотите установить ' + doer_name + ' испонителем на смену?',
      onOk() {
        dispatch(setShiftDoerReserve({
          shift_id: shift.id,
          offer_reserve_id: key,
        }, onSuccessfulReverseShift))
      },
    })
  }

  const onSuccessfulReverseShift = () => {
    tabActions.onTabClick(shift.id)
    fetchDoers()
  }

  const fetchDoers = () => {
    dispatch(fetchShiftDoers(shift.id, { state_name: filter.state_name }))
  }

  return (
    <div className={styles.container}>
      <ShiftInfo shift={shift} />
      <div className={styles.content}>
        <ShiftTabDoers
          shiftRoles={shift.roles}
          doers={doers}
          isLoading={isDoersLoading}
          shift={shift}
        />
        <Table
          sticky
          className={styles.table}
          columns={
            [
              {
                title: 'Исполнители из резерва',
                dataIndex: 'doer_name',
                key: 'doer_name',
                onCell: (cell) => {
                  return { onClick: () => onSelectReverseShift(cell) }
                },
              },
              {
                title: '',
                dataIndex: '',
                key: '',
                render: (value) => {
                  return (
                    <Button
                      type='link'
                      onClick={() => onSelectReverseShift(value)}
                    >
                      Назначить на смену
                    </Button>
                  )
                },
              },
            ]
          }
          dataSource={
            shiftOffersReverse.map((offer) => {
              return {
                key: offer.id,
                doer_name: offer.doer_name,
              }
            })
          }
          pagination={false}
          bordered
        />
      </div>


    </div>
  )
}
