import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'

import styles from '../UploadOperationReportFile/styles.module.sass'
import { UploadFiles } from 'shared/ui/UploadFiles'
import { updateReportFile } from 'AC/yandexpro/reports'
import { State } from 'stores/rootReducer'
import { actionEmpty, Actions } from 'actions'


interface Props {
  onSubmit: () => void
}

export const UploadShiftFile: FC<Props> = ({ onSubmit }) => {
  const dispatch = useDispatch()
  const placements = useSelector((state: State) => state.yandexProReducer.placements)

  useEffect(() => {
    dispatch(actionEmpty(Actions.RESET_YANDEX_PLACEMENTS))
  }, [])

  return (
    <div className={styles.wrapper}>
      <section className={styles.uploading}>
        <UploadFiles
          title='Загрузите файл'
          acceptingFiles='.xlsx'
          onFileUpload={(file) => dispatch(updateReportFile(file))}
        />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!placements}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
