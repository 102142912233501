import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { fetchTasks } from 'AC/shifts/operations'
import { fetchRoles } from 'AC/doers/doerRoles'
import { fetchDoerTariffById, fetchDoerTariffFunctions } from 'AC/doers/doerTariffs'
import { Loader } from 'components/Loader'
import { fetchEmployers, fetchShiftTypes } from 'AC/shifts/shifts'
import { APITYPES } from '../../../../../types/apitypes'

import { TariffHeader } from './TariffHeader'
import { TariffTasksTable } from './TariffTasksTable'
import { TariffShiftTable } from './TariffShiftTable'
import { TariffShiftPricesTable } from './TariffShiftPricesTable'


const selector = (state: State) => ({
  tasks: state.operationsReducer.tasks,
  shiftTypes: state.shiftsReducer.shiftTypes,
  employers: state.shiftsReducer.employers,
  tariff: state.doerTariffsReducer.doerTariff,
  roles: state.doersReducer.roles,
  functions: state.doerTariffsReducer.doerTariffFunctions,
})

export const DoerTariff = () => {
  const dispatch = useDispatch()
  const { tasks, tariff, employers, roles, functions, shiftTypes } = useSelector(selector)
  const { doerTariffId } = useParams<{ doerTariffId: string }>()

  useEffect(() => {
    dispatch(fetchDoerTariffById(doerTariffId))
    if (!roles) {dispatch(fetchRoles())}
    if (!tasks.length) {dispatch(fetchTasks())}
    if (!functions.length) {dispatch(fetchDoerTariffFunctions())}
    if (!shiftTypes.length) {dispatch(fetchShiftTypes())}
    if (!employers.length) {dispatch(fetchEmployers())}

    return () => {
      dispatch(action(Actions.SET_DOER_TARIFF, null))
      dispatch(action(Actions.SET_DOER_TARIFF_TASKS, []))
      dispatch(action(Actions.SET_DOER_TARIFF_SHIFT_TYPES, []))
    }
  }, [])

  if (!tariff) {return <Loader />}

  const renderTariff: Record<APITYPES.DoerTariff['function_type'], JSX.Element> = {
    shift: <TariffShiftPricesTable tariff={tariff} />,
    task: <TariffTasksTable tariff={tariff} />,
    shift_type: <TariffShiftTable tariff={tariff} />,
  }

  return (
    <>
      <TariffHeader tariff={tariff} />
      { renderTariff[tariff.function_type] }
    </>
  )
}
