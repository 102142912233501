import moment from 'moment'

import { APITYPES } from 'types/apitypes'


const dateFormat = 'DD.MM.YYYY'

const doerShiftStateNamesMap: Record<string, string> = {
  'started': 'Начата',
  'ended': 'Завершена',
  'canceled': 'Отменена',
  'failed': 'Провалена',
  'redirected': 'Перенаправлена',
}

export const getColumns = () => {
  return [
    {
      title: 'Дата',
      dataIndex: 'shift_date',
      key: 'shift_date',
      width: '10%',
      map: (item: APITYPES.OperationsStatistics) => {
        return moment(item.shift_date).format(dateFormat)
      },
    },
    {
      title: 'Роль',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Город',
      dataIndex: 'city_name',
      key: 'city_name',
      width: '15%',
    },
    {
      title: 'Локация',
      dataIndex: 'location_name',
      key: 'location_name',
    },
    {
      title: 'Исполнитель',
      dataIndex: 'doer_name',
      key: 'doer_name',
      width: '18%',
      ellipsis: true,
    },
    {
      title: 'Операции',
      dataIndex: 'operations_count',
      key: 'operations_count',
      width: '15%',
    },
    {
      title: 'Статус',
      dataIndex: 'doer_shift_state_name',
      key: 'doer_shift_state_name',
      width: '15%',
      map: (item: APITYPES.OperationsStatistics) => {
        return  doerShiftStateNamesMap[item.doer_shift_state_name] || item.doer_shift_state_name
      },
    },
  ]
}


export const getRows = (operations: APITYPES.Statistics.Operations.POST.Resp) => {
  return operations.map((operation) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.map) {
        row[column.dataIndex] = column.map(operation)
      } else {
        row[column.dataIndex] = operation[column.dataIndex as keyof APITYPES.OperationsStatistics]
      }
    })
    row.key = operation.doer_shift_id
    row.id = operation.doer_shift_id

    return row
  })
}