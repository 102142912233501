// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--main--P92Ty{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;min-height:100vh;width:100%}.styles-module--form--gd5w1{padding:20px;background-color:#f2f2f2;width:100%;max-width:600px}.styles-module--input--dU3DT{margin-top:20px}.styles-module--button--Plyae{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/App/PublicRoutes/SignIn/styles.module.sass","webpack://./src/styles/vars.sass"],"names":[],"mappings":"AAGA,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,gBAAA,CACA,UAAA,CAEJ,4BACE,YAAA,CACA,wBCUQ,CDTR,UAAA,CACA,eAAA,CAEF,6BACE,eAAA,CAEF,8BACE,eAAA","sourcesContent":["@import vars\n@import mixins\n\n.main\n    display: flex\n    align-items: center\n    justify-content: center\n    min-height: 100vh\n    width: 100%\n\n.form\n  padding: 20px\n  background-color: $gray100\n  width: 100%\n  max-width: 600px\n\n.input\n  margin-top: 20px\n\n.button\n  margin-top: 20px","$fontFamilyDefault: 'Roboto', -apple-system, BlinkMacSystemFont, \"Segoe UI\", sans-serif\n\n$white: #FCFCFD\n\n$blue: #0066FF\n$active-tab: #1890FFFF\n$grayish: #FBFBFB\n\n$red: #F6768E\n$green: #52C41A\n\n$yellow: #ffc600\n$orange: #FFA500\n\n$blackMain: #333745\n$black900: #161616\n$black700: #1F2129\n$black500: #333745\n$black400: #5C5F6A\n$black300: #85878F\n$black200: #ADAFB5\n\n$gray100: #F2F2F2\n$gray200: #989898\n$gray300: #828282\n$gray500: #676767\n$gray700: #464646\n$lightGray: #AAA"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles-module--main--P92Ty",
	"form": "styles-module--form--gd5w1",
	"input": "styles-module--input--dU3DT",
	"button": "styles-module--button--Plyae"
};
export default ___CSS_LOADER_EXPORT___;
