import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { showInfo } from 'components/modals/InfoModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const syncPartners = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('syncPartners', StatusRequest.LOADING))
        
    try {
      const resp = await api.post(APITYPES.Partners.PartnersSync.URL, {})
      
      if (resp) {
        showInfo('Реестр успешно обновлен')
        dispatch(changeRequestState('syncPartners', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('syncPartners', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}