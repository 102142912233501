// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--content--6HmLt{margin-top:12px;height:calc(100vh - 190px)}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/Product/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,eAAA,CACA,0BAAA","sourcesContent":[".content\n  margin-top: 12px\n  height: calc(100vh - 190px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "styles-module--content--6HmLt"
};
export default ___CSS_LOADER_EXPORT___;
