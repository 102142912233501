import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'antd'

import { UploadFiles } from 'shared/ui/UploadFiles'
import { addReportFile } from 'AC/yandexLavka/reports'

import styles from './styles.module.sass'


interface Props {
  isOpen: boolean
  onClose: () => void
}

export const UploadReportFileModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='Загрузка отчета Яндекс Лавка'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={1200}
      footer={false}
    >
      <div className={styles.wrapper}>
        <section className={styles.uploading}>
          <UploadFiles
            title='Загрузите файл'
            acceptingFiles='.xlsx'
            fileList={[]}
            onFileUpload={(file) => dispatch(addReportFile(file, onClose))}
          />
        </section>
      </div>
    </Modal>

  )
}
