import React from 'react'
import { DatePicker } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'


type Props = {
  dropdownProps: FilterDropdownProps
}

export const FilterRangePicker = ({ dropdownProps }: Props) => {
  const { visible, setSelectedKeys, confirm } = dropdownProps

  const onChange = (e: any) => {
    setSelectedKeys(e)
    confirm({ closeDropdown: true })
  }

  return (
    <DatePicker.RangePicker
      open={visible}
      onChange={onChange}
      format='L'
    />
  )
}
