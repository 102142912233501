import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from 'actions'


export type FleetsState = {
  fleets: APITYPES.Fleet[]

  fleetsCars: APITYPES.FleetCar[]
  fleetsCarsFilter: APITYPES.FleetCarFilter
  fleetsCarsHasMore: boolean
  currentFleetCar: APITYPES.FleetCar | null
  
  fleetsServices: APITYPES.FleetService[]
  fleetsServicesFilter: APITYPES.FleetServicesFilter
}

export const initialFleetsState = {
  fleets: [],
  fleetsCars: [],
  fleetsCarsFilter: {},
  fleetsCarsHasMore: true,
  currentFleetCar: null,
  fleetsServices: [],
  fleetsServicesFilter: {},
}

export const fleetsReducer: Reducer<FleetsState, Action> = (
  state = initialFleetsState,
  a
): FleetsState => {
  switch (a.type) {
    case Actions.SET_FLEETS: {
      return {
        ...state,
        fleets: a.data,
      }
    }

    case Actions.SET_FLEETS_CARS: {
      return {
        ...state,
        fleetsCars: [...state.fleetsCars, ...a.data],
        fleetsCarsHasMore: Boolean(a.data.length),
      }
    }

    case Actions.CLEAR_FLEETS_CARS: {
      return {
        ...state,
        fleetsCars: [],
        fleetsCarsHasMore: true,
      }
    }

    case Actions.SET_FLEET_CAR: {
      return {
        ...state,
        currentFleetCar: a.data,
      }
    }

    case Actions.SET_FLEET_CAR_FILTER: {
      return {
        ...state,
        fleetsCarsFilter: a.data,
      }
    }
    case Actions.SET_FLEETS_SERVICES: {
      return {
        ...state,
        fleetsServices: a.data,
      }
    }

    case Actions.SET_FLEET_SERVICES_FILTER: {
      return {
        ...state,
        fleetsServicesFilter: a.data,
      }
    }
  }

  return state
}