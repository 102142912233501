import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '35%'
  },
  {
    title: 'Тип клиента',
    dataIndex: 'client_type',
    key: 'client_type',
    align: 'center',
    width: 120
  },
  {
    title: 'Класс автомобиля',
    dataIndex: 'car_class',
    key: 'car_class',
    align: 'center',
    width: 180
  },
  {
    title: 'Примеры',
    dataIndex: 'examples',
    key: 'examples',
    align: 'center',
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
    align: 'center',
  },
]

export const getColumns = () => columns

export const getRows = (tariffs: APITYPES.CustomerTariff[]) => {
  return tariffs.map((tariff) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'client_type') {
        row[column.dataIndex] = tariff.client_type.name
      } else if (column.dataIndex === 'car_class') {
        row[column.dataIndex] = tariff.car_class.name
      } else if (column.dataIndex === 'created') {
        row[column.dataIndex] = getFormattedDate(tariff.created)
      } else {
        //@ts-ignore
        row[column.dataIndex] = tariff[column.dataIndex] ? tariff[column.dataIndex] : '-'
      }

      return
    })
    row.key = tariff.id

    return row
  })
}