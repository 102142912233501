import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'antd'

import { getBillCalculations } from 'AC/doers/doerBilling'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer'
import { action, Actions } from '../../../../../actions'

import { StateType, getStateIcon } from './utils'


const selector = (state: State) => ({
  billCalculations: state.billingReducer.billCalculations,
  isLoading: state.requestsReducer.getBillCalculations === StatusRequest.LOADING,
})


export const BillCalculations = () => {
  const { billCalculations, isLoading } = useSelector(selector)
  const dispatch = useDispatch()
  const [list, setList] = useState<APITYPES.DoerTariff.GetBillCalculations.Resp>([])

  useEffect(() => {
    if (billCalculations?.length === 0) {
      dispatch(getBillCalculations())
      dispatch(
        action(Actions.SET_SAVED_BILL_CALCULATION, { savedBillCalculation: billCalculations[0] })
      )
    }
    if (billCalculations?.length) {
      setList(billCalculations)
    }
  }, [dispatch, billCalculations?.length, billCalculations])

  useEffect(() => {
    const intervalId = setInterval(async ()=> await dispatch(getBillCalculations()), 15000)

    return () => {
      clearInterval(intervalId)
    }
  }, [billCalculations, dispatch])

  return (
    <List
      loading={isLoading}
      itemLayout='horizontal'
      dataSource={list}
      renderItem={
        (item) => (
          <List.Item>
            <List.Item.Meta
              title={<a>{ item.name }</a>}
              // description={index + 1}
            />
            <div>{ getStateIcon(item.state.name as StateType) }</div>
          </List.Item>
        )
      }
    />
  )
}
