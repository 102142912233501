import React from 'react'
import cn from 'classnames'
import { Card } from 'antd'

import styles from './styles.module.sass'


type Props = {
  title: string
  children?: React.ReactNode
  className?: string
}

export const Group = (props: Props) => {
  return (
    <Card
      className={cn(styles.fieldsGroup, props.className)}
      title={props.title}
    >
      {
        props.children
      }
    </Card>
  )
}