import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'


export const fetchProducts = (filter?: APITYPES.Products.GET.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchProducts', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Products.GET.URL, {params: {...filter}})

      if (resp) {
        dispatch(action(Actions.SET_PRODUCTS, resp))

        dispatch(changeRequestState('fetchProducts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchProducts', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchProduct = (productId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchProduct', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Products.GetById.URL, {replaceUrl: {productId}})

      if (resp) {
        dispatch(action(Actions.SET_PRODUCT, resp))

        dispatch(changeRequestState('fetchProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addProduct = (body: APITYPES.Products.POST.Req, onSuccess?: (newProduct: APITYPES.Product) => void): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('addProduct', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Products.POST.URL, body)

      if (resp) {
        if (onSuccess) {
          onSuccess(resp)
        } else {
          const filter = getState().productsReducer.productsFilter
          dispatch(fetchProducts(filter))
        }


        dispatch(changeRequestState('addProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const editProduct = (productId: string, body: APITYPES.Products.PUT.Req, updateProducts: boolean = false): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('editProduct', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Products.PUT.URL, body, {replaceUrl: {productId}})

      if (resp) {
        if (updateProducts) {
          const filter = getState().productsReducer.productsFilter
          dispatch(fetchProducts(filter))
          openNotification(`Продукт "${resp.name}" ${resp.active ? 'активен' : 'неактивен'}`)
        } else {
          dispatch(action(Actions.SET_PRODUCT, resp))
          openNotification('Продукт изменен')
        }

        dispatch(changeRequestState('editProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('editProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const addOptionToProduct = (productId: string, options: string[], onSuccess?: () => void): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('addOptionToProduct', StatusRequest.LOADING))

    try {
      const fetches = options.map(id => api.post(APITYPES.Products.OPTION.POST.URL,
        {option_product_id: id}, {replaceUrl: {productId}}),
      )

      const result = await Promise.all(fetches).then(async () => {
        await dispatch(fetchProduct(productId))
        return true
      })

      if (result) {
        if (onSuccess) onSuccess()
        dispatch(changeRequestState('addOptionToProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addOptionToProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteOptionFromProduct = (productId: string, deleteOptions: string[], onSuccess?: () => void): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('deleteOptionFromProduct', StatusRequest.LOADING))

    try {
      const fetches = deleteOptions.map(id => api.delete(APITYPES.Products.OPTION.DELETE.URL,
        {replaceUrl: {optionProductId: id, productId}}),
      )

      const result = await Promise.all(fetches).then(async () => {
        await dispatch(fetchProduct(productId))
        return true
      })

      if (result) {
        if (onSuccess) onSuccess()
        dispatch(changeRequestState('deleteOptionFromProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteOptionFromProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

// services
export const addServiceToProduct = (productId: string, addServices: string[], onSuccess?: () => void): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('addServiceToProduct', StatusRequest.LOADING))

    try {
      const fetches = addServices.map(id => api.post(APITYPES.Products.SERVICE.POST.URL,
        {service_id: id}, {replaceUrl: {productId}}),
      )

      const result = await Promise.all(fetches).then(async () => {
        await dispatch(fetchProduct(productId))
        return true
      })

      if (result) {
        if (onSuccess) onSuccess()
        dispatch(changeRequestState('addServiceToProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addServiceToProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteServiceFromProduct = (productId: string, deleteServices: string[], onSuccess?: () => void): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('deleteServiceFromProduct', StatusRequest.LOADING))

    try {
      const fetches = deleteServices.map(id => api.delete(APITYPES.Products.SERVICE.DELETE.URL,
        {replaceUrl: {productId, serviceId: id}}),
      )

      const result = await Promise.all(fetches).then(async () => {
        await dispatch(fetchProduct(productId))
        return true
      })

      if (result) {
        if (onSuccess) onSuccess()
        dispatch(changeRequestState('deleteServiceFromProduct', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteServiceFromProduct', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

// groups
export const fetchProductGroups = (): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchProductGroups', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Products.GROUP.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_PRODUCT_GROUPS, resp))

        dispatch(changeRequestState('fetchProductGroups', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchProductGroups', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addProductGroup = (body: APITYPES.Products.GROUP.POST.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('addProductGroup', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Products.GROUP.POST.URL, body)

      if (resp) {
        dispatch(fetchProductGroups())

        dispatch(changeRequestState('addProductGroup', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addProductGroup', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const editProductGroup = (groupId: string, body: APITYPES.Products.GROUP.PUT.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('editProductGroup', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Products.GROUP.PUT.URL, body, {replaceUrl: {groupId}})

      if (resp) {
        dispatch(fetchProductGroups())

        dispatch(changeRequestState('editProductGroup', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('editProductGroup', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteProductGroup = (groupId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('deleteProductGroup', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Products.GROUP.DELETE.URL, {replaceUrl: {groupId}})

      if (resp) {
        dispatch(fetchProductGroups())

        dispatch(changeRequestState('deleteProductGroup', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteProductGroup', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


// helpers fetchAllOptions
export const fetchAllOptions = (): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchAllOptions', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Products.GET.URL, {params: {type: 'option'}})

      if (resp) {
        dispatch(action(Actions.SET_ALL_PRODUCT_OPTIONS, resp))

        dispatch(changeRequestState('fetchAllOptions', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchAllOptions', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}