import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { openNotification } from '../../components/modals/OperationNotification'


export const getExportBillsPnl = (filter?: {
  limit?: number
  offset?: number
  state?: string[]
  contractor_id?: string[]
  start?: string
  end?: string
  nonzero?: string
}, contractor_type?: 'partner' | 'doer'): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getExportBillsPnl', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Billing.GetExportBillsPnl.URL, null, {
        params: {
          ...filter,
          ...getState().billingReducer.billsFilter,
          contractor_type: contractor_type,
        },
      })

      if (resp) {
        // eslint-disable-next-line no-console
        openNotification('Счета успешно экспортированы')
        dispatch(changeRequestState('getExportBillsPnl', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getExportBillsPnl', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getExportBillsConsole = (filter?: {
  limit?: number
  offset?: number
  state?: string[]
  contractor_id?: string[]
  start?: string
  end?: string
  nonzero?: string
}, contractor_type?: 'partner' | 'doer'): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getExportBillsConsole', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Billing.GetExportBillsConsole.URL, null, {
        params: {
          ...filter,
          ...getState().billingReducer.billsFilter,
          contractor_type: contractor_type,
        },
      })

      if (resp) {
        // eslint-disable-next-line no-console
        openNotification('Счета успешно экспортированы')
        dispatch(changeRequestState('getExportBillsConsole', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getExportBillsConsole', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}