import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Button, PageHeader, Tag } from 'antd'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { editProduct } from 'AC/products/products'

import { Loader } from 'components/Loader'
import styles from './styles.module.sass'

type Props = {
  product: APITYPES.Product
}

const selector = (state: State) => ({isLoading: state.requestsReducer.editProduct === StatusRequest.LOADING})

export const ProductHeader = ({product}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {isLoading} = useSelector(selector)

  const changeProduct = (partial: APITYPES.Products.PUT.Req) => {
    const key: string = Object.keys(partial)[0] as string
    if (product[key as keyof APITYPES.Product] === partial[key as keyof APITYPES.Products.PUT.Req]) return
    dispatch(editProduct(product.id, partial))
  }

  return (
    <PageHeader
      title={product.name}
      onBack={history.goBack}
      className={styles.header}
      subTitle={<ActiveTag isActive={product.active}/>}
      extra={<ExtraButtons isActive={product.active} loading={isLoading} onChange={changeProduct}/>}
    />
  );
};


const ActiveTag = ({isActive}: { isActive: boolean }) => {
  return <Tag color={isActive ? 'green' : 'red'}>{isActive ? 'Активен' : 'Неактивен'}</Tag>
}

const ExtraButtons = (
  {
    isActive,
    onChange,
    loading,
  }: { isActive: boolean, onChange: (partial: APITYPES.Products.PUT.Req) => void, loading: boolean }) => {
  return (
    <>
      {
        loading
          ? <Loader circleIcon noPadding/>
          : (
            <Button
              size="small"
              type="primary"
              danger={isActive}
              onClick={() => onChange({active: !isActive})}
              style={{lineHeight: 0}}
            >
              {isActive ? 'Деактивировать' : 'Активировать'}
            </Button>
          )
      }
    </>
  )
}

