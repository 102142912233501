import React from 'react'

import { APITYPES } from 'types/apitypes'

import { TariffInput } from './TariffInput'


export const createColumns = (carClasses: APITYPES.CarClasses.Get.Resp, carwashId: string, clientTypeId: string) => {
  return [{
    title: 'услуги',
    dataIndex: 'service',
    key: 'service',
    editable: true,
    fixed: 'left',
  }, ...carClasses.map((carClass) => {
    return {
      title: carClass.name,
      dataIndex: carClass.name,
      key: carClass.name,
      editable: true,
      render: (value: number, record: any) => (
        <TariffInput
          value={value}
          record={record}
          carClassId={carClass.id}
          carwashId={carwashId}
          clientTypeId={clientTypeId}
          key={carClass.id + record.service}
        />
      ),
    }
  })]
}

export const createTable = (
  tariffs: APITYPES.Office.Tariff.Get.Resp,
  services: APITYPES.Services.Get.Resp,
  carClasses: APITYPES.CarClasses.Get.Resp,
) => {
  return services.map((service) => {
    const data: any = {}

    data.service = service.name

    for (let i = 0; i < carClasses.length; i++) {
      data[carClasses[i].name] = tariffs.find(
        (tariff) => (
          tariff.car_class_id === carClasses[i].id && tariff.service_id === service.id
        ),
      )?.cost
    }

    data.key = service.id

    return data
  })
}