// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--main--42Vg6{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:calc(100vh - 145px)}.styles-module--container--QKenZ{display:-webkit-box;display:-ms-flexbox;display:flex;gap:8px;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.styles-module--tableContainer--BznDQ{margin-top:20px;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/DoerRecords/RecordTable/styles.module.sass"],"names":[],"mappings":"AACA,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,0BAAA,CAEJ,iCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAEJ,sCACI,eAAA,CACA,kBAAA,CAAA,iBAAA,CAAA,aAAA,CACA,eAAA","sourcesContent":["\n.main\n    display: flex\n    flex-direction: column\n    height: calc(100vh - 145px)\n\n.container\n    display: flex\n    gap: 8px\n    align-items: baseline\n    justify-content: space-between\n\n.tableContainer\n    margin-top: 20px\n    flex: 1 1 auto\n    overflow: hidden"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles-module--main--42Vg6",
	"container": "styles-module--container--QKenZ",
	"tableContainer": "styles-module--tableContainer--BznDQ"
};
export default ___CSS_LOADER_EXPORT___;
