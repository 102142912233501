import React, { useEffect, useState } from 'react'
import { Button, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { fetchCustomerTariffById } from 'AC/products/tariffs'
import { Loader } from 'components/Loader'
import { TariffHeader } from './TariffHeader'
import { TariffProducts } from './TariffProducts'
import { TariffLocations } from './TariffLocations'
import { AddModal } from './AddModal'

export type TabsType = 'products' | 'locations'
const btnStyle = {margin: '8px 0'}

const selector = (state: State) => ({tariff: state.customerTariffsReducer.customerTariff})

export const CustomerTariff = () => {
  const dispatch = useDispatch()
  const {tariffId} = useParams<{ tariffId: string }>()
  const {tariff} = useSelector(selector)

  useEffect(() => {
    if (!tariffId) return
    dispatch(fetchCustomerTariffById(tariffId))
    return () => {
      dispatch(action(Actions.SET_CUSTOMER_TARIFF, null))
    }
  }, [])

  const [activeTab, setActiveTab] = useState<TabsType>('products')
  const onTabChange = (tab: string) => setActiveTab(tab as TabsType)

  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(prev => !prev)

  if (!tariff) return <Loader/>

  return (
    <>
      <TariffHeader tariff={tariff}/>

      <Tabs onChange={onTabChange} tabBarStyle={{margin: 0}}>
        <Tabs.TabPane tab="Продукты" key="products">
          <>
            <Button size="small" type="primary" style={btnStyle} onClick={toggleAddModal}>Добавить</Button>
            <TariffProducts tariffId={tariff.id}/>
          </>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Локации" key="locations">
          <>
            <Button size="small" type="primary" style={btnStyle} onClick={toggleAddModal}>Добавить</Button>
            <TariffLocations tariffId={tariff.id}/>
          </>
        </Tabs.TabPane>
      </Tabs>

      <AddModal isOpen={addModal} onClose={toggleAddModal} activeTab={activeTab}/>
    </>
  )
};

type ExtraTabsBtnProps = { activeTab: TabsType, open: () => void }
const ExtraTabsBtn = ({activeTab, open}: ExtraTabsBtnProps) => {
  return (
    <Button
      size="small"
      type="primary"
      onClick={open}
      style={{marginRight: 16, width: 140, lineHeight: 0, marginTop: 4}}
    >
      {activeTab === 'products' && 'Добавить продукт'}
      {activeTab === 'locations' && 'Добавить локацию'}
    </Button>
  )
}
