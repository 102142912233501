import { ThAction } from 'actions'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'


export const addReserveToInternship = (payload: APITYPES.Reserves.POST.Req, onSuccess: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      await api.post(APITYPES.Reserves.POST.URL, payload)
      onSuccess()
    } catch (e) {
      showError(e?.response?.data)
    }
  }
}