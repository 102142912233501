import React, { FC, useEffect, useState } from 'react'
import { Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getTableYScroll } from 'utils/scrollUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { useDebounce } from 'utils/debounce'
import { fetchStaticLocations, fetchStaticLocationsByQuery } from 'AC/shifts/locations'
import { Connect } from '../../../../../../YandexProReports/Components/steps/MatchingLocations/components/Connnect/Connect'
import { deleteUnmatchedPlacement, updateUnmatchedPlacement } from 'AC/yandexLavka/placement'


type Placement = APITYPES.YandexLavka.UnmatchedPlacement;


interface Props {
  unmatchedPlacements: Placement[]
}

export const UnmatchedPlacementsTable: FC<Props> = ({ unmatchedPlacements }) => {
  const [placements, setPlacements] = useState<Placement[]>(unmatchedPlacements)
  const dispatch = useDispatch()
  const locations = useSelector((state: State) => state.shiftsReducer.allLocations)
  const [debState, setDebState] = useState<string>('')
  const debouncedName = useDebounce(debState, 500)

  useEffect(() => {
    if (!locations.length) {
      dispatch(fetchStaticLocations())
    }
  }, [])


  useEffect(() => {
    onSearchChange(debouncedName)
  }, [debouncedName])


  const onSearchChange = (value: string) => {
    if (value && !locations.find((l) => l.name.includes(value))) {
      dispatch(fetchStaticLocationsByQuery(value))
    }
  }


  const handleSelectChange = (id: string, placement: Placement) => {
    const location = locations.find((l) => l.id === id)


    const updatedPlacement: Placement = {
      ...placement,
      location_id: location?.id,
      location_name: location?.name,
    }
    const updatedReport = placements.map((item: Placement) => {
      return item.yandex_placement_id === updatedPlacement.yandex_placement_id
        ? updatedPlacement
        : item
    })

    setPlacements(updatedReport)
  }

  useEffect(() => {
    setPlacements(unmatchedPlacements)
  }, [unmatchedPlacements])


  const expandedRowRender = (placement: Placement) => {
    return (
      <Select
        placeholder='Выберите локацию'
        showSearch
        allowClear
        style={{ width: '100%' }}
        fieldNames={
          {
            value: 'id',
            label: 'name',
          }
        }
        onSearch={
          (value) =>
            setDebState(value)
        }
        onChange={(value: string) => handleSelectChange(value, placement)}
        options={
          locations.filter((l) => {
            if (placement.city_id) {
              return l.city_id === placement.city_id
            }

            return true
          }).map((l) => ({
            id: l.id,
            name: l.name,
          }))
        }
      />
    )
  }

  return (
    <Table
      bordered
      sticky
      pagination={false}
      size='large'
      style={{ cursor: 'pointer' }}
      scroll={{ y: getTableYScroll(500) }}
      columns={
        [
          {
            title: 'Из ЯндексПРО',
            dataIndex: 'label',
            key: 'yandex',
          },
          {
            title: 'Из Базы Данных' ,
            dataIndex: 'location_name',
            key: 'doer',
          },
          {
            title: 'Связать данные',
            dataIndex: 'связать',
            width: '20%',
            key: 'x',
            align: 'center',
            render: (_: any, placement: Placement) => {
              return (
                <Connect
                  placement={placement}
                  onDelete={() => dispatch(deleteUnmatchedPlacement(placement.yandex_placement_id))}
                  onSubmit={
                    () => {
                      if (placement.location_id) {
                        dispatch(updateUnmatchedPlacement(placement.yandex_placement_id, placement.location_id))
                      }
                    }
                  }
                />
              )
            },
          },
        ]
      }
      dataSource={
        placements?.map((p) => ({
          ...p,
          key: p.yandex_placement_id,
          label: `${p.yandex_placement_name} (${p.yandex_city})`,
        }))
      }
      expandable={{ expandedRowRender }}
    />
  )
}
