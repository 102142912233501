import React from 'react'

import { APITYPES } from 'types/apitypes'

import { ConnectButton } from './ConnectButton'


export const getColumns = (newReport?:any, setNewReport?:any) => {
  return [
    {
      title: 'Из ЯндексПРО',
      children: [
        {
          title: 'ФИО',
          dataIndex: 'yandex_executor_name',
          key: 'yandex_executor_name',
          align: 'left',
          width: '20%',
        },
        {
          title: 'Телефон',
          dataIndex: 'yandex_executor_phone',
          key: 'yandex_executor_phone',
          align: 'left',
        },
        {
          title: 'Город',
          dataIndex: 'yandex_city',
          key: 'yandex_city',
          align: 'left',
        },{
          title: 'Вид транспорта',
          dataIndex: 'yandex_vehicle_type',
          key: 'yandex_vehicle_type',
          align: 'left',
          width: '7%',
        },
      ],
    },
    {
      title: 'Из Базы Данных',
      children: [
        {
          title: 'ФИО',
          dataIndex: 'doer_name',
          key: 'doer_user_name',
          align: 'left',
          width: '20%',
        },
        {
          title: 'Телефон',
          dataIndex: 'doer_phone',
          key: 'doer_phone',
          align: 'left',
        },
      ],
    },
    {
      title: 'Связать данные',
      dataIndex: 'связать',
      key: 'x',
      align: 'center',
      render: (_: any, record: APITYPES.YandexPro.Executor) => {
        return (
          <ConnectButton
            record={record}
            report={newReport}
            setNewReport={setNewReport}
          />
        )
      },
    },
  ]
}

export const getRows = (report: APITYPES.YandexPro.Executor[]) => {
  const rows: any[] = []

  if (report) {
    report.map((unmatched_executor: APITYPES.YandexPro.Executor) => {
      const row: any = {}

      getColumns().map((column: any) => {
        column.children?.map((child: { dataIndex: string | number }) => {
          //@ts-ignore
          row[child.dataIndex] = unmatched_executor[child.dataIndex]
        })
      })

      row.yandex_executor_id = unmatched_executor.yandex_executor_id
      row.key = unmatched_executor.yandex_executor_id
      row.doer_user_id = unmatched_executor.doer_user_id

      rows.push(row)
    })

    return rows
  }

  return []
}
