import React, { useMemo, useState } from 'react'
import { Layout as BaseLayout, Menu, Modal, Select } from 'antd'
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons'
import cn from 'classnames'
import moment from 'moment-timezone'


import { NavBlock } from 'components/NavBlock'
import { removeLocalStorageItems, LocalStorageKeys } from 'utils/localStorageUtils'
import { isDev } from 'utils/devUtils'
import { PageTitle } from '../PageTitle'

import styles from './styles.module.sass'


const { Header, Content } = BaseLayout
const { SubMenu } = Menu
const { Option } = Select

type Props = {
  children: React.ReactNode
}

export const Layout = (props: Props) => {
  const { children } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timezone, setTimezone] = useState(() => {
    return localStorage.getItem('timezone') || moment.tz.guess()
  })


  const onExit = () => {
    removeLocalStorageItems([LocalStorageKeys.TOKEN])
    location.reload()
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const timezoneList = useMemo(() => moment.tz.names(), [])

  const modalTitle = `Текущий часовой пояс: ${timezone}`

  const handleChange = (e: string) => {
    setTimezone(e)
    localStorage.setItem('timezone', e)
    window.dispatchEvent(new Event('storage'))
  }

  const timezoneTitle = useMemo(() => localStorage.getItem('timezone') ? timezone : 'Часовой пояс', [timezone])
  
  return (
    <BaseLayout>
      <Header className={cn('header', styles.header)}>
        <PageTitle />
        <span
          className={styles.developmentMode}
          style={{ visibility: isDev() ? 'visible' : 'hidden' }}
        >
          development
        </span>
        <div className={styles.menu}>
          <Menu
            theme='dark'
            mode='horizontal'
            style={
              {
                width: '100%',
                'justifyContent': 'end',
              }
            }
          >
            <SubMenu
              key='sub1'
              title='User'
              icon={<UserOutlined />}
            >
              <Menu.Item
                key='1'
                onClick={onExit}
              >
                Logout
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='sub2'
              title={timezoneTitle}
              icon={<ClockCircleOutlined />}
            >
              <Menu.Item
                key='2'
                onClick={showModal}
              >
                Задать часовой пояс
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Header>
      <BaseLayout>
        <NavBlock />
        <BaseLayout>
          <Content
            className={cn('site-layout-background', styles.content)}
          >
            { children }
          </Content>
        </BaseLayout>
      </BaseLayout>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%' }}
          showSearch
          value={timezone}
          onChange={handleChange}
        >
          {
            timezoneList.map((timezone) => (
              <Option
                key={timezone}
                value={timezone}
              >
                { timezone }
              </Option>
            ))
          }
        </Select>
      </Modal>
    </BaseLayout>
  )
}