// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dot-module--wrapper--Pvrx-{width:6px;height:6px;border-radius:50%}.dot-module--red--uLx9w{background:#f6768e}.dot-module--green--wtg-A{background:#52c41a}.dot-module--blue--iYxqi{background:#06f}.dot-module--yellow--JTlsp{background:#ffc600}.dot-module--orange--G4McQ{background:orange}.dot-module--gray--YxCYU{background:#828282}", "",{"version":3,"sources":["webpack://./src/shared/ui/dot/dot.module.sass","webpack://./src/styles/vars.sass"],"names":[],"mappings":"AAEA,4BACE,SAAA,CACA,UAAA,CACA,iBAAA,CAEF,wBACE,kBAAA,CAEF,0BACE,kBCFM,CDIR,yBACE,eCVK,CDYP,2BACE,kBCNO,CDQT,2BACE,iBCRO,CDUT,yBACE,kBCCQ","sourcesContent":["@import \"../../../styles/vars\"\n\n.wrapper\n  width: 6px\n  height: 6px\n  border-radius: 50%\n\n.red\n  background: $red\n\n.green\n  background: $green\n\n.blue\n  background: $blue\n\n.yellow\n  background: $yellow\n\n.orange\n  background: $orange\n\n.gray\n  background: $gray300","$fontFamilyDefault: 'Roboto', -apple-system, BlinkMacSystemFont, \"Segoe UI\", sans-serif\n\n$white: #FCFCFD\n\n$blue: #0066FF\n$active-tab: #1890FFFF\n$grayish: #FBFBFB\n\n$red: #F6768E\n$green: #52C41A\n\n$yellow: #ffc600\n$orange: #FFA500\n\n$blackMain: #333745\n$black900: #161616\n$black700: #1F2129\n$black500: #333745\n$black400: #5C5F6A\n$black300: #85878F\n$black200: #ADAFB5\n\n$gray100: #F2F2F2\n$gray200: #989898\n$gray300: #828282\n$gray500: #676767\n$gray700: #464646\n$lightGray: #AAA"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "dot-module--wrapper--Pvrx-",
	"red": "dot-module--red--uLx9w",
	"green": "dot-module--green--wtg-A",
	"blue": "dot-module--blue--iYxqi",
	"yellow": "dot-module--yellow--JTlsp",
	"orange": "dot-module--orange--G4McQ",
	"gray": "dot-module--gray--YxCYU"
};
export default ___CSS_LOADER_EXPORT___;
