import React from 'react'
import { Modal } from 'antd'
import { TabsType } from '../index'
import { ProductForm } from './ProcutForm'
import { LocationForm } from './LocationForm'

type Props = {
  isOpen: boolean
  onClose: () => void
  activeTab: TabsType
}

export const AddModal = ({isOpen, onClose, activeTab}: Props) => {
  return (
    <Modal
      title={`Добавление ${activeTab === 'products' ? 'продукта' : 'локации'}`}
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      destroyOnClose
    >
      {activeTab === 'products' && <ProductForm onClose={onClose} />}
      {activeTab === 'locations' && <LocationForm onClose={onClose} />}
    </Modal>
  );
};
