import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getDoerCompensation } from '../../../../../AC/doers/penalties'
import { Loader } from '../../../../../components/Loader'

import styles from './styles.module.sass'
import { CompensationInfo } from './CompensationInfo'


type Props = {
  compensationId: string
}

const selector = (state: State) => ({
  doerCompensations: state.motivationsReducer.doerCompensations,
  isLoading: state.requestsReducer.getDoerCompensation === StatusRequest.LOADING,
})

export const CompensationTab = ({ compensationId }: Props) => {
  const { doerCompensations, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const doerCompensation = doerCompensations[compensationId]

  useEffect(() => {
    if (!doerCompensation) {
      dispatch(getDoerCompensation(compensationId))
    }
  }, [doerCompensation])
  
  if (!doerCompensation || isLoading) {return <Loader />}
  
  return (
    <div className={styles.container}>
      <CompensationInfo compensation={doerCompensation} />
      <div className={styles.content}>

      </div>
    </div>
  )
}
