// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--wrapper--umjTn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:3rem;margin-top:1rem}.styles-module--wrapper--umjTn .styles-module--uploading--XSeyX{height:200px}.styles-module--wrapper--umjTn .styles-module--btnWrapper--MpyQR{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/YandexProReports/Components/steps/UploadOperationReportFile/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,eAAA,CACA,gEACE,YAAA,CACF,iEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".wrapper\n  display: flex\n  flex-direction: column\n  gap: 3rem\n  margin-top: 1rem\n  .uploading\n    height: 200px\n  .btnWrapper\n    display: flex\n    justify-content: flex-end\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles-module--wrapper--umjTn",
	"uploading": "styles-module--uploading--XSeyX",
	"btnWrapper": "styles-module--btnWrapper--MpyQR"
};
export default ___CSS_LOADER_EXPORT___;
