import React, { useEffect, useState } from 'react'
import { Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { useDebounce } from 'utils/debounce'
import { State } from 'stores/rootReducer'
import { fetchStaticLocations, fetchStaticLocationsByQuery } from 'AC/shifts/locations'
import { APITYPES } from 'types/apitypes'
import { Connect } from '../Connnect/Connect'
import { action, Actions } from 'actions'
import { deletePlacement, updatePlacement } from '../../../../../../../../../AC/yandexpro/placements'


export const MatchingLocationsTable = () => {
  const dispatch = useDispatch()
  const placements = useSelector((state: State) => state.yandexProReducer.placements)
  const locations = useSelector((state: State) => state.shiftsReducer.allLocations)
  const [debState, setDebState] = useState<string>('')
  const debouncedName = useDebounce(debState, 500)

  useEffect(() => {
    if (!locations.length) {
      dispatch(fetchStaticLocations())
    }
  }, [])


  useEffect(() => {
    onSearchChange(debouncedName)
  }, [debouncedName])


  const onSearchChange = (value: string) => {
    if (value && !locations.find((l) => l.name.includes(value))) {
      dispatch(fetchStaticLocationsByQuery(value))
    }
  }

  return (
    <Table
      sticky
      size='large'
      columns={
        [
          {
            title: 'Из ЯндексПРО',
            dataIndex: 'label',
            key: 'yandex',
          },
          {
            title: 'Из Базы Данных' ,
            dataIndex: 'location_name',
            key: 'doer',
          },
          {
            title: 'Связать данные',
            dataIndex: 'связать',
            width: '20%',
            key: 'x',
            align: 'center',
            render: (_: any, placement: APITYPES.YandexPro.Placement) => {
              return (
                <Connect
                  placement={placement}
                  onDelete={() =>  dispatch(deletePlacement((placement.yandex_placement_id)))}
                  onSubmit={() => dispatch(updatePlacement(placement.yandex_placement_id, placement.location_id!))}
                />
              )
            },
          },
        ]
      }
      dataSource={
        placements?.map((p) => ({
          ...p,
          key: p.yandex_placement_id,
          label: `${p.yandex_placement_name} (${p.yandex_city})`,
        }))
      }
      pagination={false}
      bordered
      expandable={
        {
          expandedRowRender: (placement) => {
            return (
              <Select
                placeholder='Выберите локацию'
                showSearch
                allowClear
                style={{ width: '100%' }}
                fieldNames={
                  {
                    value: 'id',
                    label: 'name',
                  }
                }
                onSearch={
                  (value) =>
                    setDebState(value)
                }
                onChange={
                  (id: string) => {
                    if (id !== undefined && locations) {
                      const location = locations.find((l) => l.id === id)

                      if (location) {
                        dispatch(action(Actions.UPDATE_YANDEX_PLACEMENTS, {
                          yandex_placement_id: placement.yandex_placement_id,
                          location_name: location.name,
                          location_id: location.id,
                        }))
                      }
                    }
                  }
                }
                options={
                  locations.filter((l) => {
                    if (placement.city_id) {
                      return l.city_id === placement.city_id
                    }

                    return true
                  }).map((l) => ({
                    name: l.name,
                    id: l.id,
                  }))
                }
              />
            )
          },
        }
      }
    />
  )
}
