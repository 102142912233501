// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--3EhUj{margin-bottom:20px;width:calc(100% - 10px)}.styles-module--addButton--7Div3{width:100%;margin:0 0 4px 0}.styles-module--main--bdrMP{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex:1 1 100%;flex:1 1 100%}.styles-module--tabPane--RLoL4{width:200px;white-space:break-spaces;text-align:left}.styles-module--tabs--HA-5q{height:calc(100vh - 93.5px)}.styles-module--tabs--HA-5q>div:first-child>div:first-child{padding:0 8px;width:100%}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/CarClasses/styles.module.sass"],"names":[],"mappings":"AAAA,4BACI,kBAAA,CACA,uBAAA,CAEJ,iCACI,UAAA,CACA,gBAAA,CAEJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,kBAAA,CAAA,iBAAA,CAAA,aAAA,CAEJ,+BACI,WAAA,CACA,wBAAA,CACA,eAAA,CAEJ,4BACI,2BAAA,CACA,4DACI,aAAA,CACA,UAAA","sourcesContent":[".card\n    margin-bottom: 20px\n    width: calc(100% - 10px)\n\n.addButton\n    width: 100%\n    margin: 0 0 4px 0\n\n.main\n    display: flex\n    flex-direction: column\n    flex: 1 1 100%\n\n.tabPane\n    width: 200px\n    white-space: break-spaces\n    text-align: left\n\n.tabs\n    height: calc(100vh - 93.5px)\n    & > div:first-child > div:first-child\n        padding: 0 8px\n        width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--3EhUj",
	"addButton": "styles-module--addButton--7Div3",
	"main": "styles-module--main--bdrMP",
	"tabPane": "styles-module--tabPane--RLoL4",
	"tabs": "styles-module--tabs--HA-5q"
};
export default ___CSS_LOADER_EXPORT___;
