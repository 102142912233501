import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type YandexLavkaState = {
  reports: APITYPES.YandexLavka.Report[] | null
  unmatchedExecutors: APITYPES.YandexLavka.UnmatchedExecutor[] | null
  unmatchedPlacements: APITYPES.YandexLavka.UnmatchedPlacement[] | null
  placements: APITYPES.YandexLavka.Placement[] | null
}

const initState: YandexLavkaState = {
  reports: null,
  unmatchedExecutors: null,
  unmatchedPlacements: null,
  placements: null,
}

export const yandexLavkaReducer: Reducer<YandexLavkaState, Action> = (
  state = initState,
  a
): YandexLavkaState => {
  switch (a.type) {
    case Actions.SET_YANDEX_LAVKA_REPORTS: {
      return {
        ...state,
        reports: a.data,
      }
    }
    case Actions.SET_YANDEX_LAVKA_UNMATCHED_EXECUTORS: {
      return {
        ...state,
        unmatchedExecutors: a.data,
      }
    }

    case Actions.UPDATE_YANDEX_LAVKA_UNMATCHED_EXECUTOR: {
      return {
        ...state,
        unmatchedExecutors: (state.unmatchedExecutors || []).map((item) => {
          return item.yandex_executor_id === a.data.id
            ? {
              ...item,
              isMatched: true,
            }
            : item
        }),
      }
    }

    case Actions.SET_YANDEX_LAVKA_UNMATCHED_PLACEMENTS: {
      return {
        ...state,
        unmatchedPlacements: a.data,
      }
    }

    case Actions.UPDATE_YANDEX_LAVKA_PLACEMENTS_MATCHED: {
      return {
        ...state,
        unmatchedPlacements: (state.unmatchedPlacements || []).map((item) => {
          return item.yandex_placement_id === a.data.id
            ? {
              ...item,
              isMatched: true,
            }
            : item
        }),
      }
    }

    case Actions.DELETE_YANDEX_LAVKA_UNMATCHED_PLACEMENT: {
      return {
        ...state,
        unmatchedPlacements: (state.unmatchedPlacements || []).filter((item) => item.yandex_placement_id !== a.data.id),
      }
    }

    case Actions.SET_YANDEX_LAVKA_PLACEMENTS: {
      return {
        ...state,
        placements: a.data,
      }
    }

    case Actions.ADD_YANDEX_LAVKA_PLACEMENTS: {
      return {
        ...state,
        placements: (state.placements || []).concat(a.data),
      }
    }

    case Actions.DELETE_YANDEX_LAVKA_PLACEMENT: {
      return {
        ...state,
        placements: (state.placements || []).filter((item) => item.id !== a.data.id),
      }
    }
    default:
      return state
  }
}