// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--modal--gPA6x{min-width:700px !important}.styles-module--card--5gX5g{-webkit-box-shadow:5px 3px 10px rgba(0,0,0,.2);box-shadow:5px 3px 10px rgba(0,0,0,.2);cursor:pointer;margin-top:24px}.styles-module--field--VehXK+.styles-module--field--VehXK{margin-top:15px}.styles-module--buttonNone--C07uj{display:none}.styles-module--formFields--KGkMT{display:-webkit-box;display:-ms-flexbox;display:flex;gap:8px}.styles-module--formFields--KGkMT>:first-child{margin-bottom:0;width:100%}", "",{"version":3,"sources":["webpack://./src/components/Inn/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,0BAAA,CAEJ,4BACI,8CAAA,CAAA,sCAAA,CACA,cAAA,CACA,eAAA,CAGA,0DACI,eAAA,CAER,kCACI,YAAA,CAEJ,kCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CACA,+CACI,eAAA,CACA,UAAA","sourcesContent":[".modal\n    min-width: 700px!important\n\n.card\n    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2)\n    cursor: pointer\n    margin-top: 24px\n\n.field\n    & + .field\n        margin-top: 15px\n\n.buttonNone\n    display: none\n\n.formFields\n    display: flex\n    gap: 8px\n    & > :first-child\n        margin-bottom: 0\n        width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles-module--modal--gPA6x",
	"card": "styles-module--card--5gX5g",
	"field": "styles-module--field--VehXK",
	"buttonNone": "styles-module--buttonNone--C07uj",
	"formFields": "styles-module--formFields--KGkMT"
};
export default ___CSS_LOADER_EXPORT___;
