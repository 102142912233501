import React from 'react'
import { Route, Switch } from 'react-router-dom'


export type NestedSwitchRoutes = Array<{
  path: string
  component: JSX.Element
  exact?: boolean
}>

export interface NestedSwitchProps {
  routes: NestedSwitchRoutes
  key?: React.Key
}

export const NestedSwitch = (props: NestedSwitchProps) => {
  const { routes, key } = props

  return (
    <Switch key={key}>
      {
        routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
          >
            { route.component }
          </Route>
        ))
      }
    </Switch>
  )
}
