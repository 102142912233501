import React, { FC, useEffect, useState } from 'react'
import { Modal, Steps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { StepProps } from 'antd/lib/steps'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { MatchingUnmatchedExecutors } from './steps/MatchingExecutors/MatchingExecutors'
import { MatchingPlacements } from './steps/MatchingPlacements/MatchingPlacements'
import { FinalStep } from './steps/FinalStep/FinalStep'
import { UploadOperationReportFile } from './steps/UploadReportFile/UploadOperationReportFile'
import { getUrentReportById } from 'AC/urent/reports'
import { actionEmpty, Actions } from 'actions'

import styles from './styles.module.sass'


enum STEPS {
  UPLOADING_FILE = 0,
  MATCHING_EXECUTORS = 1,
  MATCHING_LOCATIONS = 2,
  PROCESSING = 3,
}


interface Props {
  reportId: string
  onCancel: () => void
}

interface Step {
  title: string
  component: React.ReactNode
}


const selector = (state: State) => ({
  report: state.urentReducer.uploadedReport,
  isLoading: state.requestsReducer.getUrentReportById === StatusRequest.LOADING,
})


export const MatchingModal: FC<Props> = ({
  reportId,
  onCancel,
}) => {
  const dispatch = useDispatch()
  const {
    report,
    isLoading,
  } = useSelector(selector)

  const [current, setCurrent] = useState<STEPS>(STEPS.UPLOADING_FILE)
  const changeStep = (step: STEPS) => {
    setCurrent(step)
  }

  const stepsMap: Record<STEPS, Step> = {
    [STEPS.UPLOADING_FILE]: {
      title: 'Загрузка файла',
      component: <UploadOperationReportFile  onSubmit={() => changeStep(STEPS.MATCHING_EXECUTORS)} />,
    },
    [STEPS.MATCHING_EXECUTORS]: {
      title: 'Связка исполнителей',
      component: <MatchingUnmatchedExecutors onSubmit={() => changeStep(STEPS.MATCHING_LOCATIONS)} />,
    },
    [STEPS.MATCHING_LOCATIONS]: {
      title: 'Связка локаций',
      component: <MatchingPlacements onSubmit={() => changeStep(STEPS.PROCESSING)} />,
    },
    [STEPS.PROCESSING]: {
      title: 'Обработка',
      component: <FinalStep
        onFinish={onCancel}
        reportId={reportId}
        // eslint-disable-next-line
      />,
    },
  }

  const steps: StepProps[] = Object.values(stepsMap).map(({ title }) => ({ title }))

  useEffect(() => {
    if (!reportId) {
      return
    }
    dispatch(getUrentReportById(reportId))
  }, [reportId])

  useEffect(() => {
    if (!report || !report?.unmatched_executors || !report?.unmatched_placements || isLoading) {
      return
    }
    const { unmatched_executors: unmatchedExecutors, unmatched_placements: unmatchedPlacements } = report

    if (unmatchedExecutors.length === 0 && unmatchedPlacements.length === 0) {
      changeStep(STEPS.PROCESSING)
    } else if (unmatchedExecutors.length === 0 && unmatchedPlacements.length) {
      changeStep(STEPS.MATCHING_LOCATIONS)
    } else {
      changeStep(STEPS.MATCHING_EXECUTORS)
    }

    return () => {
      dispatch(actionEmpty(Actions.CLEAR_URENT_REPORT))
    }
  }, [isLoading])

  return (
    <Modal
      open
      title='Связка данных'
      onCancel={onCancel}
      destroyOnClose
      width={1200}
      footer={false}
    >

      {
        isLoading
          ? (
            <div className={styles.loaderWrapper}>
              <Loader
                circleIcon
                noPadding
                size='large'
              />
            </div>
          )
          : (
            <>
              <Steps
                current={current}
                items={steps}
              />
              { stepsMap[current].component }
            </>
          )
      }

    </Modal>
  )
}
