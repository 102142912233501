export const isValidPhoneNumber = (phone: string) => {
  if (!phone) {
    return false
  }

  return /^\+?[0-9\s\-()]{3,15}$/.test(phone)
}

export const isOnlyLetters = (value: string) => {
  return /^[А-Яа-яЁёA-Za-z\s]+$/.test(value)
}