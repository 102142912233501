import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { DatePicker, Select } from 'antd'
import moment from 'moment/moment'

import { APITYPES } from '../../../../types/apitypes'
import { ROUTES } from '../../../../config/routes'
import { action, Actions } from '../../../../actions'
import { State } from '../../../../stores/rootReducer'
import { useGetRoles } from '../Motivations/hooks'
import { backDateFormat } from '../../../../utils/dateUtils'


const selector = (state: State) => ({ filter: state.doerMonitoringReducer.doerMonitoringAllocationsFilter })

export const SetParams = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { filter } = useSelector(selector)
  const roles = useGetRoles({})

  const onFilterChange = (partial: Partial<APITYPES.DoerMonitoring.GetAllocations.Req>) => {
    history.push(ROUTES.DOERS.MONITORING.PARAMS.createPath({
      ...filter,
      ...partial,
    }))
    dispatch(action(Actions.CLEAN_DOER_MONITORING_ALLOCATIONS, {}))
    dispatch(action(Actions.SET_DOER_MONITORING_ALLOCATIONS_FILTER, {
      ...filter,
      ...partial,
    }))
  }
  
  useEffect(() => {
    if (filter?.date || filter?.role_id) {
      history.push(ROUTES.DOERS.MONITORING.PARAMS.createPath({ ...filter }))
    } else {
      dispatch(action(Actions.CLEAN_DOER_MONITORING_ALLOCATIONS, null))
    }
  }, [])

  return (
    <>
      <DatePicker
        placeholder='Выберите дату'
        format={backDateFormat}
        style={{ width: 280 }}
        defaultValue={filter?.date ? moment(filter?.date) : undefined}
        //@ts-ignore
        onChange={
          (value) => {
            onFilterChange({ date: value?.format(backDateFormat) })
          }
        }
      />
      <Select
        placeholder='Выберите роль'
        value={filter?.role_id}
        allowClear
        showSearch
        style={{ width: 280 }}
        dropdownMatchSelectWidth={false}
        onChange={
          (value) => {
            onFilterChange({ role_id: value })
          }
        }
        filterOption={
          (input, option) =>
            //@ts-ignore
            (option!.children.toLowerCase() as unknown as string).includes(input.toLowerCase())
        }
      >
        {
          roles.map((role) => (
            <Select.Option
              value={role.id}
              key={role.id}
            >
              { role.name }
            </Select.Option>
          ))
        }
      </Select>

    </>
  )
}