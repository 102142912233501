import React from 'react'
import { Form, Modal } from 'antd'
import { useDispatch } from 'react-redux'

import { FormFooter } from '../../../../../components/FormFooter'
import { postImportBillsItems } from '../../../../../AC/billing/getExportBills'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

type ALL = 'all'
const ALL: ALL = 'all'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export const BillsImportCard = (props: Props) => {
  const dispatch = useDispatch()
  const { isVisible, onCancel } = props

  const onCancelClick = () => {
    onCancel()
  }

  const onFinish = () => {
    onCancel()
    dispatch(postImportBillsItems())
  }

  return (
    <Modal
      title='Импорт позиций счетов'
      visible={isVisible}
      onCancel={onCancelClick}
      footer={false}
    >
      <Form
        name='bills_form'
        {...formItemLayout}
        onFinish={onFinish}
      >
        <h4 style={
          {
            fontWeight: 400,
            textAlign: 'center',
            marginBottom: '20px',
          }
        }
        >
          Вы добавляете позиции в сформированные счета Исполнителей из spreadsheet. Эта операция необратима.
        </h4>
        <FormFooter
          okText='Импортировать'
          onCancel={onCancelClick}
          position='right'
        />
      </Form>
    </Modal>
  )
}