import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'


export const getFleetsCars = (filter?: APITYPES.Fleets.GetCars.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    const status = getState().requestsReducer.getFleetsCars

    if (status === StatusRequest.LOADING) {
      return
    }
    
    dispatch(changeRequestState('getFleetsCars', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Fleets.GetCars.URL, {
        params: {
          ...filter,
          offset: getState().fleetsReducer.fleetsCars.length ?? 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_FLEETS_CARS, resp))
        dispatch(changeRequestState('getFleetsCars', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getFleetsCars', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getFleetCarById = (fleet_id: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('getFleetCarById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Fleets.GetById.URL, {replaceUrl: {fleet_id}})

      if (resp) {
        dispatch(action(Actions.SET_FLEET_CAR, resp))

        dispatch(changeRequestState('getFleetCarById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getFleetCarById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createFleetCar = (body: APITYPES.Fleets.CreateFleetCar.Req): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('createFleetCar', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Fleets.CreateFleetCar.URL, body)

      if (resp) {
        dispatch(action(Actions.CLEAR_FLEETS_CARS, {}))
        const fleetFilters = {...getState().fleetsReducer.fleetsCarsFilter, offset: 0}
        dispatch(getFleetsCars(fleetFilters))

        dispatch(changeRequestState('createFleetCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createFleetCar', StatusRequest.ERROR))
      showError(e.response.data)

      throw(e)
    }
  }
}

export const changeFleetCar = (id: string, body: APITYPES.Fleets.ChangeFleetCar.Req): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('changeFleetCar', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Fleets.ChangeFleetCar.URL, body, {replaceUrl: {fleet_id: id}})

      if (resp) {
        const fleetCars = getState().fleetsReducer.fleetsCars
        const theCar = fleetCars.find(car => car.id === id)

        if(theCar) {
          theCar.fleet_id = resp.fleet_id
          theCar.fleet_name = resp.fleet_name
          theCar.car_brand_id = resp.car_brand_id
          theCar.car_brand_name = resp.car_brand_name
          theCar.car_model_id = resp.car_model_id
          theCar.car_model_name = resp.car_model_name
        }

        dispatch(changeRequestState('changeFleetCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeFleetCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteFleetCar = (fleet_id: string): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('deleteFleetCar', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Fleets.DeleteFleetCar.URL, {replaceUrl: {fleet_id}})

      if (resp) {
        const filter = getState().fleetsReducer.fleetsCarsFilter
        dispatch(action(Actions.CLEAR_FLEETS_CARS, []))
        dispatch(getFleetsCars(filter))

        dispatch(changeRequestState('deleteFleetCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteFleetCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}