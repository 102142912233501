import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Tabs } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { deleteClientType, fetchCarClasses, fetchServices } from 'AC/directory/directory'
import { DeleteButton } from 'components/DeleteButton'
import { Loader } from 'components/Loader'

import { CarClasses } from './CarClasses'
import { Services } from './Services'
import { EditForm } from './EditForm'
import styles from './styles.module.sass'


const { TabPane } = Tabs

const selector = (state: State) => {
  const {
    clientType, clientTypeCarClasses, carClasses,
    services, clientTypeServices, clientTypes,
  } = state.directoryReducer
  const isClientTypesLoading = state.requestsReducer.fetchClientTypes === StatusRequest.LOADING
  const isClientTypeLoading = state.requestsReducer.fetchClientType === StatusRequest.LOADING
  const isCarClassesLoading = state.requestsReducer.fetchCarClasses === StatusRequest.LOADING
  const isServicesLoading = state.requestsReducer.fetchServices === StatusRequest.LOADING

  const isLoading = isClientTypeLoading || isClientTypesLoading
  const isTableLoading = isCarClassesLoading || isServicesLoading

  return {
    clientType,
    clientTypeCarClasses,
    carClasses,
    services,
    clientTypeServices,
    clientTypes,
    isLoading,
    isTableLoading,
  }
}

export const ClientType = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const {
    clientType, clientTypeCarClasses, carClasses, services,
    clientTypeServices, clientTypes, isLoading, isTableLoading,
  } = useSelector(selector)

  useEffect(() => {
    if (!clientTypes) {return}
    const currentClientType = clientTypes.find((type) => type.id === id)

    batch(() => {
      dispatch(action(Actions.SET_DIRECTORY_CLIENT_TYPE, currentClientType))
      dispatch(fetchCarClasses(id))
      dispatch(fetchServices(id))
    })
  }, [dispatch, clientTypes])

  const onDelete = async () => {
    dispatch(deleteClientType(id))
    history.push(ROUTES.DIRECTORY.CLIENT_TYPES.path)
  }

  const avatar = isLoading ? { src: <Loader size='small' /> } : undefined

  return (
    <>
      {
        clientType && (
          <EditForm
            isVisible={isEdit}
            onCancel={() => setIsEdit(false)}
            clientType={clientType}
            key={clientType.id}
          />
        )
      }
      <PageHeader
        className={styles.title}
        avatar={avatar}
        title={<div>{ !isLoading && clientType?.name }</div>}
        extra={
          <>
            <Button
              type='primary'
              className={styles.btn}
              onClick={() => setIsEdit(true)}
            >
              Редактировать
            </Button>
            <DeleteButton
              className={styles.btn}
              onClick={onDelete}
            />
          </>
        }
      />
      <Tabs
        defaultActiveKey='1'
      >
        <TabPane
          tab='Классы автомобилей'
          key='1'
        >
          {
            carClasses && (
              <CarClasses
                clientTypeCarClasses={clientTypeCarClasses}
                carClasses={carClasses}
                isLoading={isTableLoading}
              />
            )
          }
        </TabPane>
        <TabPane
          tab='Услуги'
          key='2'
        >
          {
            services && (
              <Services
                services={clientTypeServices}
                allServices={services}
                isLoading={isTableLoading}
              />
            )
          }
        </TabPane>
      </Tabs>
    </>
  )
}