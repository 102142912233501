import { APITYPES } from 'types/apitypes'


enum SE {
  new = 'new',
  agreed = 'agreed',
  disagreed = 'disagreed',
  canceled = 'canceled',
  applied = 'applied',
  apply = 'apply',
  cancel = 'cancel',
  paid = 'paid'
}

export const getPenaltyStatusColor = (status: string | null) => {
  if (status === SE['new']) {return 'blue'}
  if (status === SE['agreed'] || status === SE['paid']) {return 'green'}
  if (status === SE['disagreed']) {return 'red'}
  if (status === SE['canceled'] || status === SE['cancel']) {return 'gray'}
  if (status === SE['applied'] || status === SE['apply']) {return 'orange'}

  return 'blue'
}

export const getPenaltyStatusText = (value: string | null) => {
  // @ts-ignore
  if (value) {return APITYPES.PenaltyStateStatus[value]}
  
  return APITYPES.PenaltyStateStatus['new']
}

export const getAllowedStatusArray = (status: string) => {
  switch (status) {
    case SE['new']:
      return [SE['applied'], SE['canceled'] ]
    case SE['agreed']:
      return [SE['applied'], SE['canceled']]
    case SE['disagreed']:
      return [SE['applied'], SE['canceled']]
    case SE['canceled']:
    case SE['applied']:
    default:
      return []
  }
}

export const getAllowedDoerStatusArray = (status: string) => {
  switch (status) {
    case SE['new']:
      return [SE['cancel']]
    case SE['applied']:
      return [SE['cancel']]
    case SE['canceled']:
      return [SE['apply']]
    default:
      return []
  }
}

export const getPenaltyStatusUrl = (status: string | null) => {
  if (status === SE['agreed']) {return 'agree'}
  if (status === SE['disagreed']) {return 'disagree'}
  if (status === SE['canceled']) {return 'cancel'}
  if (status === SE['applied']) {return 'apply'}
  if (status === SE['apply']) {return 'apply'}
  if (status === SE['cancel']) {return 'cancel'}

  return null
}

export const isDropdownDisabled = (status: string) => {
  if (status === SE['canceled'] || status === SE['applied']) {return true}

  return false
}