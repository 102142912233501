// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addBtn--L1OXm{margin-bottom:16px}.styles-module--panel--YWK90{padding:0}.styles-module--collapse--UGfY0>div>div:nth-child(2)>div:first-child{padding:0}[data-theme=compact] .styles-module--collapse--UGfY0 .styles-module--collapsePanel--kiqr9,.styles-module--collapse--UGfY0 .styles-module--collapsePanel--kiqr9{margin-bottom:12px;overflow:hidden;background:#fff}[data-theme=compact] .styles-module--collapse--UGfY0 .styles-module--collapsePanel--kiqr9:last-child,.styles-module--collapse--UGfY0 .styles-module--collapsePanel--kiqr9:last-child{margin-bottom:0}.styles-module--table--KrMsn{cursor:pointer}.styles-module--toolbar--MdkbL{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Roles/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,kBAAA,CAEF,6BACE,SAAA,CAGA,qEACE,SAAA,CAEJ,+JAEE,kBAAA,CACA,eAAA,CACA,eAAA,CACA,qLACE,eAAA,CAEJ,6BACE,cAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".addBtn\n  margin-bottom: 16px\n\n.panel\n  padding: 0\n\n.collapse\n  & > div > div:nth-child(2) > div:first-child\n    padding: 0\n\n[data-theme='compact'] .collapse .collapsePanel,\n.collapse .collapsePanel\n  margin-bottom: 12px\n  overflow: hidden\n  background: #fff\n  &:last-child\n    margin-bottom: 0\n\n.table\n  cursor: pointer\n\n.toolbar\n  display: flex\n  justify-content: space-between\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtn": "styles-module--addBtn--L1OXm",
	"panel": "styles-module--panel--YWK90",
	"collapse": "styles-module--collapse--UGfY0",
	"collapsePanel": "styles-module--collapsePanel--kiqr9",
	"table": "styles-module--table--KrMsn",
	"toolbar": "styles-module--toolbar--MdkbL"
};
export default ___CSS_LOADER_EXPORT___;
