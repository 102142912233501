import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'


export const getUrentExecutors = (doer_user_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.get(APITYPES.Urent.Executors.GET.URL,
        { params: { doer_user_id } }
      )

      if (resp) {
        dispatch(action(Actions.SET_URENT_EXECUTOR, resp))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const connectDoerToUrentExecutor = (executorId: string, doerId: string, onSuccess?: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.put(
        APITYPES.Urent.UnmatchedExecutors.PUT.URL, { doer_user_id: doerId }, { replaceUrl: { id: executorId } }
      )

      if (resp) {
        onSuccess && onSuccess()
        dispatch(action(Actions.ADD_URENT_EXECUTOR,  resp))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteUrentExecutor = (executorId: string, doerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.delete(APITYPES.Urent.Executors.DELETE.URL,
        {
          replaceUrl: { id: executorId },
          body: { doer_user_id: doerId },
        }
      )

      if (resp) {
        dispatch(action(Actions.DELETE_URENT_EXECUTOR, { id: executorId }))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}
