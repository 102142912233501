import React from 'react'
import { Modal, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { cancelBill } from 'AC/billing/cancelBill'
import { negotiationBill } from 'AC/billing/negotiationBill'
import { paidBill } from 'AC/billing/paidBill';
import { payingBill } from 'AC/billing/payingBill';
// import { acceptBill } from 'AC/billing/acceptBill'
// import { declineBill } from 'AC/billing/declineBill'


type Props = {
  isVisible: boolean
  onCancel: () => void
  action: 'negotiation' | 'cancel' | 'accept' | 'decline' | 'paid' | 'paying' | null
}

export const CommentModal = (props: Props) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const { isVisible, onCancel, action } = props

  const dispatch = useDispatch()

  const onFinish = async (values:  {
    comment: string
  }) => {
    if (action === 'cancel') {
      dispatch(cancelBill(values.comment))
    }

    if (action === 'negotiation') {
      dispatch(negotiationBill(values.comment))
    }

    if (action === 'paid') {
      dispatch(paidBill(values.comment))
    }

    if (action === 'paying') {
      dispatch(payingBill(values.comment))
    }
    // if (action === 'accept') {
    //   dispatch(acceptBill(values.comment))
    // }
    //
    // if (action === 'decline') {
    //   dispatch(declineBill(values.comment))
    // }
    
    onCancel()
  }

  return (
    <Modal
      title='Изменения состояния счета'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      destroyOnClose
    >
      <Form
        name='comment_changing_state_bill_form'
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='comment'
          label='Комментарий'
        >
          <Input />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          position='right'
          okText='Ok'
        />
      </Form>
    </Modal>
  )
}