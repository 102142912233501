import { APITYPES } from 'types/apitypes'


export type DownloadFileOptions = {
  url: string
  fileType: MIME_TYPE
  fileName: string
  shouldUseContentDisposition: boolean
}

export enum FILE_FORMAT {
  csv = '.csv',
  pdf = '.pdf',
  mp4 = '.mp4',
}

export enum MIME_TYPE {
  PDF = 'application/pdf',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}


export const exportCSV = (name: string, positions?: APITYPES.BillItem[]) => {
  if (!positions) {
    return
  }

  const csvType = 'data:text/csv;charset=utf-8;'
  let content = ['Название', 'Состояние', 'Описание', 'Значение', 'Комментарий', '\n'].join(';')

  positions.forEach((item) => {
    content += [item.name, item.state, item.description, item.value, item.comment, '\n'].join(';')
  })

  const link = document.createElement('a')
  const blob = new Blob(['\ufeff', content], { type: csvType })

  link.href = URL.createObjectURL(blob)
  link.download = `${name}${FILE_FORMAT.csv}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadFile(options: DownloadFileOptions) {
  const { url, fileName: name, shouldUseContentDisposition = false, fileType } = options
  let fileName = name

  const req = new XMLHttpRequest()

  req.open('GET', url, true)
  req.responseType = 'blob'
  req.onload = function() {
    console.log(req.getAllResponseHeaders())
    const blob = new Blob([req.response], { type: fileType })

    const contentDisposition = req.getResponseHeader('Content-Disposition')

    if (contentDisposition && shouldUseContentDisposition) {
      const utf8Filename = decodeURIComponent(escape(contentDisposition))
      const filenameWithExtensionMatch = utf8Filename.match(/filename="([^"]+)"/)
      const filenameWithExtension = filenameWithExtensionMatch ? filenameWithExtensionMatch[1] : ''

      const regex = /^(.+)\.([^.]+)$/

      const matches = filenameWithExtension.match(regex)

      if (matches) {
        fileName = matches[0]
      }
    }

    const navigator = (window.navigator as any)

    if (navigator?.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      const windowUrl = window.URL || window.webkitURL
      const href = windowUrl.createObjectURL(blob)
      const a = document.createElement('a')

      a.setAttribute('download', fileName)
      a.setAttribute('href', href)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  req.send()
}

const getFileName = (url: string, filename: string) => {
  if (filename.length === 0) {
    filename = url.split('/').pop() as string
  }

  return filename
}

export const downloadExcel = (url: string, filename = '',  shouldUseContentDisposition = false) => {
  const fileName = getFileName(url, filename)

  downloadFile({
    url,
    fileName,
    shouldUseContentDisposition,
    fileType: MIME_TYPE.EXCEL,
  })
}

export const downloadPDF = (url: string, filename = '', shouldUseContentDisposition = false) => {
  const fileName = getFileName(url, filename)

  downloadFile({
    url,
    fileName,
    shouldUseContentDisposition,
    fileType: MIME_TYPE.PDF,
  })
}

export async function getVideoFileFromUrl(url: string, name: string) {
  const response = await fetch(url)
  const contentDisposition = response.headers.get('Content-Disposition')
  const fileNameMatch = contentDisposition && contentDisposition.match(/filename="?([^"]+)"?/)
  const fileName = fileNameMatch ? fileNameMatch[1] : name

  const blob = await response.blob()

  const file = new File([blob], fileName, { type: blob.type })
  const stream = URL.createObjectURL(file)

  return {
    file: file,
    stream: stream,
  }
}
