import { APITYPES } from '../types'


type ShiftTypeRole = APITYPES.ShiftTypeRole


export const getRolesOptions = (roles: ShiftTypeRole[] | null) => {
  if (!roles) {
    return []
  }

  return roles.map((role) => ({
    city_id: role.city_id,
    label: role.name,
    value: role.id,
  }))
}
