import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchUserSubscriptions } from 'AC/users/userSubscriptions'
import { getTableYScroll } from 'utils/scrollUtils'
import { getColumns, getProductColumns, getProductRows, getRows } from './utils'

type Props = {
  user: APITYPES.ExpandedUser | null
}

const selector = (state: State) => ({
  subs: state.usersReducer.subscriptions,
  isLoading: state.requestsReducer.fetchUserSubscriptions === StatusRequest.LOADING,
})

export const Subscriptions = (props: Props) => {
  const {user} = props
  const {subs, isLoading} = useSelector(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && user.cars) {
      dispatch(fetchUserSubscriptions(user.id))
    }

    return () => {
      dispatch(action(Actions.SET_USER_SUBSCRIPTIONS, null))
    }
  }, [])

  return (
    <Table
      columns={getColumns() as any}
      dataSource={getRows(subs || [], user?.cars || [])}
      bordered
      pagination={false}
      loading={isLoading}
      expandable={{
        rowExpandable: (record) => !!record.products,
        expandedRowRender: record => (
          <Table
            columns={getProductColumns() as any}
            dataSource={getProductRows(record.products)}
            pagination={false}
            size='small'
          />
        ),
      }}
      scroll={{y: getTableYScroll(280)}}
    />
  )
}