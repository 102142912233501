import { Reducer } from 'redux'

import {
  Action,
  Actions,
} from 'actions'
import {
  MailingFilterType,
  MailingItem,
} from '../types'


export type MailingState = {
  mailingList: MailingItem[]
  hasMore: boolean
  mailingFilter: MailingFilterType
}

export const initialState: MailingState = {
  mailingList: [],
  hasMore: false,
  mailingFilter: {},
}

export const mailingReducer: Reducer<MailingState, Action> = (
  state = initialState,
  a
): MailingState => {
  switch (a.type) {
    case Actions.CLEAN_MAILS: {
      return {
        ...state,
        mailingList: [],
        hasMore: false,
      }
    }

    case Actions.SET_MAILING: {
      return {
        ...state,
        mailingList: [...state.mailingList, ...a.data.mailing],
        hasMore: a.data.hasMoreMailing,
      }
    }

    case Actions.SET_MAILING_FILTER: {
      return {
        ...state,
        mailingFilter: a.data,
      }
    }
  }

  return state
}
