// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addBtn--WLEpR{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:-8px;margin-bottom:12px}.styles-module--table--TRUkV{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/ProductGroups/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEF,6BACE,cAAA","sourcesContent":[".addBtn\n  width: fit-content\n  margin-top: -8px\n  margin-bottom: 12px\n\n.table\n  cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtn": "styles-module--addBtn--WLEpR",
	"table": "styles-module--table--TRUkV"
};
export default ___CSS_LOADER_EXPORT___;
