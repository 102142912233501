import React, { useMemo } from 'react'
import { Divider } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { APITYPES } from 'types/apitypes'
import { copyToClipboard } from '../../../../../../utils/copyUtils'

import { getGeneralData, getPerformerData } from './utils'
import styles from './styles.module.sass'


type Props = {
  penalty: APITYPES.DoerPenalty
}

export const PenaltyInfo = ({ penalty }: Props) => {
  const dataArr = useMemo(() => getGeneralData(penalty), [penalty])
  const performerArr = useMemo(() => getPerformerData(penalty), [penalty])
  
  const handleClipboardClick = () => {
    copyToClipboard(penalty.id, 'ID штрафа успешно скопирован!')
  }
  
  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.field}
          style={{ cursor: 'pointer' }}
          onClick={handleClipboardClick}
        >
          <div className={styles.title}>ID штрафа</div>
          <div className={styles.value}>{ penalty.id }</div>
          <CopyOutlined />
        </div>
        <Divider className={styles.divider}>Информация</Divider>
        <div>

          {
            dataArr.map((item) => (
              <div
                key={item.label}
                className={styles.field}
              >
                <div className={styles.fieldWrap}>
                  <div className={styles.title}>{ item.label }</div>
                  <div className={styles.value}>
                    { item.value }
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Divider className={styles.divider}>Исполнитель</Divider>
        <div>
          {
            performerArr.map((item) => (
              <div
                className={styles.field}
                key={item.label}
              >
                <div className={styles.fieldWrap}>
                  <div className={styles.title}>{ item.label }</div>
                  <div className={styles.value}>
                    { item.value }
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Divider className={styles.divider}>Дополнительно</Divider>
        <div>
          <div
            className={styles.field}
          >
            <div className={styles.fieldWrap}>
              <div className={styles.title}>Описание:</div>
              <div className={styles.value}>
                { penalty.description }
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}
