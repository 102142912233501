import React, { useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'
import { Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { fetchPartners } from 'AC/partners/partners'
import { fetchBrands } from 'AC/cars'
import { fetchClientTypes } from 'AC/directory/directory'
import { getTableYScroll } from 'utils/scrollUtils'
import { CustomEmpty } from 'components/CustomEmpty'

import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


type Props = {
  user: APITYPES.ExpandedUser | null
}

export const UserInfo = (props: Props) => {
  const dispatch = useDispatch()
  const { user } = props

  useEffect(() => {
    batch(() => {
      dispatch(fetchPartners({ limit: 1000 }))
      dispatch(fetchBrands())
      dispatch(fetchClientTypes())
    })
  }, [dispatch])

  return (
    <div className={styles.carsBlock}>
      {
        user?.cars
          ? (
            <Table
              columns={getColumns() as any}
              dataSource={getRows(user.cars, user.id)}
              bordered
              size='large'
              pagination={false}
              scroll={{ y: getTableYScroll(280) }}
            />
          )
          : <CustomEmpty text='Нет автомобилей' />
      }
    </div>
  )
}