import React, {
  useLayoutEffect,
  useState,
} from 'react'
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { updateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'

import {
  isFormChanged,
  isFormValid,
  normalizeFormTypings,
} from './utils'
import styles from './styles.module.sass'

import RentalObjectType = APITYPES.RentalObjectType


const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD-MM-YYYY'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
}

type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
  rentalObjectType: RentalObjectType | undefined
}

export const CarwashInfo = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = useForm()

  const {
    carwash,
    rentalObjectType,
  } = props

  const [formValues, setFormValues] = useState<APITYPES.Carwashes.GetCarwash.Resp>(normalizeFormTypings(form))

  useLayoutEffect(() => {
    setFormValues(normalizeFormTypings(form))
  }, [])

  const onFinish = async (values: any) => {
    dispatch(updateCarwash({
      bq_id: (parseFloat(values.bq_id) || parseFloat(values.bq_id) === 0) ? parseFloat(values.bq_id) : null,
      name: values.name || null,
      own_type: values.own_type || null,
      rent_cost: (values.rent_cost && values.rent_unit) ? +values.rent_cost : null,
      rent_unit: (values.rent_cost && values.rent_unit) ? values.rent_unit : null,
      cooperation_date: carwash.cooperation_date ? values.cooperation_date.format(dateFormat) : undefined,
    }, carwash.id))
  }


  const onBlur = () => {
    setTimeout(() => {
      const errors = isFormValid(form)
      const values = normalizeFormTypings(form)
      const isChanged = isFormChanged(values, formValues)

      if (!errors && isChanged) {
        setFormValues(values)
        form.submit()
      }
    }, 0)
  }

  return (
    <>
      <Form
        className={styles.form}
        name='time_related_controls'
        {...formItemLayout}
        onFinish={onFinish}
        onBlur={onBlur}
        form={form}
      >
        <Form.Item
          name='name'
          label='Название'
          initialValue={carwash.name ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='bq_id'
          label='id в реестре партнеров'
          initialValue={carwash.bq_id ?? ''}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='own_type'
          label='Способ владения'
          initialValue={carwash.own_type ?? 'Аренда'}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='Аренда'>Аренда</Select.Option>
            <Select.Option value='Собственность'>Собственность</Select.Option>
          </Select>
        </Form.Item>
        {
          (!carwash.own_type || carwash.own_type === 'Аренда') && (
            <Form.Item label='Стоимость аренды'>
              <Input.Group compact>
                <Form.Item
                  name='rent_cost'
                  noStyle
                  initialValue={carwash.rent_cost}
                  rules={
                    [{
                      min: 1,
                      message: 'Значение должно быть больше 0',
                      type: 'number',
                    }]
                  }
                >
                  <InputNumber
                    type='number'
                    style={{ width: '60%' }}
                    controls={false}
                  />
                </Form.Item>

                <Form.Item
                  name='rent_unit'
                  noStyle
                  initialValue={carwash.rent_unit}
                >
                  <Select
                    style={{ width: '40%' }}
                    allowClear
                    onClear={onBlur}
                  >
                    <Select.Option value='day'>Сутки</Select.Option>
                    <Select.Option value='month'>Месяц</Select.Option>
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )
        }
        <Form.Item
          label='Дата начала сотрудничества'
          initialValue={carwash.cooperation_date ? moment(carwash.cooperation_date, dateFormat) : undefined}
          name='cooperation_date'
        >
          <DatePicker format={viewDateFormat} />
        </Form.Item>
        <Form.Item
          name='cooperation_type'
          label='Формат сотрудничества'
          initialValue={carwash.cooperation_type}
        >
          <Select showSearch>
            <Select.Option value='Подряд'>Подряд</Select.Option>
            <Select.Option value='Аренда'>Аренда</Select.Option>
            <Select.Option value='Гибридный'>Гибридный</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Тип объекта'
        >
          <Input
            value={rentalObjectType?.name}
            readOnly
          />
        </Form.Item>
        <Form.Item
          label='Логин ЛК'
        >
          <Input
            value={carwash.credentials?.login}
            readOnly
          />
        </Form.Item>
        <Form.Item
          label='Пароль ЛК'
        >
          <Input
            value={carwash.credentials?.password}
            readOnly
          />
        </Form.Item>
      </Form>
    </>
  )
}