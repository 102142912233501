// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--notification--tI7a\\+{background-color:rgba(64,64,64,.96);height:58px}.styles-module--message--UsJaf{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:18px;color:#fff}.styles-module--update--ma4S8{font-weight:600;margin-top:1px;color:#a1c2fa}.styles-module--close--pyNGJ{margin-top:4px;font-size:16px;color:#fff}", "",{"version":3,"sources":["webpack://./src/App/hooks/SwUpdater/styles.module.sass"],"names":[],"mappings":"AAAA,qCACE,mCAAA,CACA,WAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CACA,UAAA,CAEF,8BACE,eAAA,CACA,cAAA,CACA,aAAA,CAEF,6BACE,cAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".notification\n  background-color: rgba(64, 64, 64, .96)\n  height: 58px\n\n.message\n  display: flex\n  justify-content: space-between\n  align-items: center\n  gap: 18px\n  color: #FFF\n\n.update\n  font-weight: 600\n  margin-top: 1px\n  color: #A1C2FA\n\n.close\n  margin-top: 4px\n  font-size: 16px\n  color: #FFF"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "styles-module--notification--tI7a+",
	"message": "styles-module--message--UsJaf",
	"update": "styles-module--update--ma4S8",
	"close": "styles-module--close--pyNGJ"
};
export default ___CSS_LOADER_EXPORT___;
