import moment from 'moment'

import { getEnumKeys } from 'utils/enumUtils'
import { APITYPES } from '../../../../../types/apitypes'


export const billStateOptions = getEnumKeys(APITYPES.BillState)

export enum BillStateTranslation {
  draft = 'Черновик',
  canceled = 'Отменено',
  negotiation = 'На согласовании',
  accepted = 'Согласовано',
  declined = 'Отклонено',
  paid = 'Оплачено',
  paying = 'В оплате',
}

export const getInitDatesState = (start: string | undefined, end: string | undefined) => {
  if (!start || !end) {return undefined}

  return [moment(start), moment(end)]
}