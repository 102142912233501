import React from 'react'
import { Tag } from 'antd'


export enum BillItemStateTranslation {
  auto = 'авто',
  manual = 'ручная',
  deleted = 'удалена',
  updated = 'обновлена'
}

export const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '25%',
  },
  {
    title: 'Тип',
    dataIndex: 'state',
    key: 'state',
    width: '10%',
    align: 'center',
    //@ts-ignore
    render: (record: string) => <Tag>{ BillItemStateTranslation[record] }</Tag>,
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    width: '45%',
    ellipsis: true,
    render: (text: string) => (
      <div style={
        {
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        }
      }
      >
        { text }
      </div>
    ),
  },
  {
    title: 'Количество',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: '10%',
    ecllipse: true,
    fixed: true,
  },
  {
    title: 'Сумма',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    width: '10%',
  },
]

export const getColumns = () => columns
