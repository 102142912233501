import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, DatePicker, Form, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment-timezone'

import { State } from 'stores/rootReducer'
import { dateFormat } from 'utils/dateUtils'
import { FormFooter } from 'components/FormFooter'
import { getCitiesOptions } from 'utils/enumUtils'
import { RecordStateOptions, RecordStateTranslation } from '../utils'
import { getDoerRecordsPeriod, getDoerRecordsTypes } from 'AC/billing/doerRecords'
import { fetchRoleGroup, fetchRoles } from 'AC/doers/doerRoles'
import { action, Actions } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { DoerRecordsFilter } from 'stores/doerRecordsReducer'
import { ContractorSelector } from '../../Bills/ContractorSelector'
import { APITYPES } from 'types/apitypes'
import { openNotification } from 'components/modals/OperationNotification'
import { fetchEmployers } from 'AC/shifts/shifts'

import styles from './styles.module.sass'

import ContractorType = APITYPES.ContractorType

import { fetchSigners } from 'AC/billing/signer'


const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}
const labelCol = {}

const selector = (state: State) => ({
  filtersMap: state.doerRecordsReducer.filtersMap[state.doerRecordsReducer.activeTabId as string],
  activeTab: state.doerRecordsReducer.activeTabId,
  roleGroups: state.doersReducer.roleGroups,
  roles: state.doersReducer.roles,
  period: state.billingReducer.doerRecordsPeriod,
  types: state.doerRecordsReducer.doerRecordTypes,
  hasMore: state.billingReducer.periodHasMore,
  employers: state.shiftsReducer.employers,
  signers: state.dictionaryReducer.signers,
  isLoading:
    state.requestsReducer.getDoerRecordsPeriod === StatusRequest.LOADING,
})

export const Filter = () => {
  const dispatch = useDispatch()
  const {
    filtersMap,
    activeTab,
    roleGroups,
    roles,
    types,
    period,
    hasMore,
    employers,
    signers,
  } =
    useSelector(selector)
  const cities = getCitiesOptions()

  const [form] = useForm()

  useEffect(() => {
    Object.entries(filtersMap ?? {}).forEach(([key, value]: [key: string, value: any]) => {
      form.setFieldsValue({ [key]: value.value })
    })
  }, [filtersMap, form])

  useEffect(() => {
    if (!roleGroups || !roleGroups.length) {
      dispatch(fetchRoleGroup())
    }

    if (!roles || !roles.length) {
      dispatch(fetchRoles())
    }

    if (!types.length) {
      dispatch(getDoerRecordsTypes())
    }
  }, [dispatch, roleGroups, period, roles, types])

  useEffect(() => {
    if (!period.length) {
      dispatch(getDoerRecordsPeriod())
    }
  }, [period])


  const onFinish = async (values: any) => {
    if (!values.dates && !values.period) {
      openNotification('Необходимо выбрать период расчета или дату записи')

      return
    }


    if (activeTab !== 'table') {
      return
    }

    const title = values.period ?
      `c ${formatDate(values.period.split('/')[0])} по ${formatDate(values.period.split('/')[1])}`
      : `c ${values.dates[0].format(dateFormat)} по ${values.dates[1].format(dateFormat)}`

    dispatch(action(Actions.ADD_DOER_RECORD_FILTER_TAB, {
      title,
      filter: getStoredValues(values, true),
    }))
  }

  const getStoredValues = (formValue: Record<string, any>, withDisabled: boolean) => {
    return Object.entries(formValue).reduce((acc, item) => {
      const [key, value] = item
      let disabled = !!value?.length

      if (!withDisabled) {
        disabled = filtersMap ? filtersMap[key as keyof DoerRecordsFilter]?.disabled : false
      }

      return {
        ...acc,
        [key]: {
          value,
          disabled,
        },
      }
    }, {})
  }

  const formatDate = (date: string) => {
    return moment(date).format(dateFormat)
  }

  const onCancel = () => {
    filtersMap && Object.entries(filtersMap).forEach(([key, value]: [key: string, value: any]) => {
      if (value.disabled) {
        return
      }
      form.setFieldsValue({ [key]: undefined })
    })

    onValuesChange()
  }

  const filterOption = (
    input: string,
    option?: { label: string, value: string | number }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const onValuesChange = () => {
    const values = form.getFieldsValue()

    dispatch(action(Actions.RESET_DOER_RECORDS, {}))

    dispatch(action(Actions.SET_DOER_RECORDS_FILTER, {
      id: activeTab,
      filter: getStoredValues(values, false),
    }))
  }

  const handleScroll = useCallback((event) => {
    const { target } = event
    const atBottom = target.scrollTop + target.offsetHeight === target.scrollHeight

    if (atBottom && hasMore) {
      dispatch(getDoerRecordsPeriod())
    }
  }, [hasMore])

  useEffect(() => {
    if (!employers.length) {
      dispatch(fetchEmployers())
    }


    if (!signers.length) {
      dispatch(fetchSigners())
    }
  }, [])

  return (
    <>
      <Card
        title='Фильтр записей'
        className={styles.card}
      >
        <Form
          name='filter'
          {...formItemLayout}
          onFinish={onFinish}
          form={form}
          layout='vertical'
          size='small'
        >
          <Form.Item
            className={styles.formItem}
            label='Период расчета'
            name='period'
            labelCol={labelCol}
          >
            <Select
              disabled={filtersMap?.period?.disabled}
              placeholder='Выберите период расчета'
              allowClear
              onPopupScroll={handleScroll}
              onChange={onValuesChange}
            >
              {
                period.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={`${item.billing_begin}/${item.billing_end}`}
                  >
                    {
                      `c ${moment(item.billing_begin).format(dateFormat)} по 
                    ${moment(item.billing_end).format(dateFormat)}`
                    }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label='Дата записи'
            name='dates'
            labelCol={labelCol}
          >
            <DatePicker.RangePicker
              onChange={onValuesChange}
              format={dateFormat}
              disabled={filtersMap?.dates?.disabled}
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label='Статус'
            name='state'
            labelCol={labelCol}
          >
            <Select
              onChange={onValuesChange}
              disabled={filtersMap?.state?.disabled}
              allowClear
              placeholder='Выберите статус'
            >
              {
                RecordStateOptions.map((state) => (
                  <Select.Option
                    key={state}
                    value={state}
                  >
                    { RecordStateTranslation[state] }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='department_name'
            label='Направление'
            labelCol={labelCol}
          >
            <Select
              onChange={onValuesChange}
              disabled={filtersMap?.department_name?.disabled}
              placeholder='Выберите направление'
              allowClear
            >
              <Select.Option value='Кикшеринг'>Самокаты 🛴</Select.Option>
              <Select.Option value='Каршеринг'>Каршеринг 🚗</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name='employer_name'
            label='Заказчик'
            labelCol={labelCol}
          >
            <Select
              mode='multiple'
              disabled={filtersMap?.employer_name?.disabled}
              allowClear
              onChange={onValuesChange}
              placeholder='Выберите заказчика'
            >
              {
                employers.map((employer) => (
                  <Select.Option
                    key={employer.id}
                    value={employer.name}
                  >
                    { employer.name }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='signer'
            label='Подписант'
            labelCol={labelCol}
          >
            <Select
              mode='multiple'
              disabled={filtersMap?.signer?.disabled}
              allowClear
              onChange={onValuesChange}
              placeholder='Выберите подсписанта'
            >
              {
                signers.map((signers) => (
                  <Select.Option
                    key={signers.id}
                    value={signers.name}
                  >
                    { signers.name }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='role_group_name'
            label='Группа ролей'
            labelCol={labelCol}
          >
            <Select
              onChange={onValuesChange}
              disabled={filtersMap?.role_group_name?.disabled}
              placeholder='Выберите группу ролей'
              allowClear
              showSearch
              filterOption={filterOption}
              options={
                roleGroups?.map((role) => ({
                  label: role.name,
                  value: role.name,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='role_id'
            label='Роль'
            labelCol={labelCol}
          >
            <Select
              onChange={onValuesChange}
              disabled={filtersMap?.role_id?.disabled}
              placeholder='Выберите роль'
              allowClear
              showSearch
              filterOption={filterOption}
              options={
                roles?.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label='Контрагент'
            name='contractor_id'
            labelCol={labelCol}
          >
            <ContractorSelector
              disabled={filtersMap?.contractor_id?.disabled}
              labelInValue={false}
              onFilterChange={onValuesChange}
              contractorType={ContractorType.doer}
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='city_id'
            label='Город'
            labelCol={labelCol}
          >
            <Select
              mode='multiple'
              onChange={onValuesChange}
              disabled={filtersMap?.city_id?.disabled}
              allowClear
              placeholder='Выберите город'
              showSearch
              filterOption={filterOption}
              options={
                cities.map(({ label, value }) => ({
                  label: label.toString(),
                  value,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            name='type'
            label='Тип'
            labelCol={labelCol}
          >
            <Select
              mode='multiple'
              onChange={onValuesChange}
              disabled={filtersMap?.type?.disabled}
              allowClear
              placeholder='Выберите тип'
              showSearch
              filterOption={filterOption}
              options={
                types.map((value) => ({
                  label: value,
                  value,
                }))
              }
            />
          </Form.Item>

          <FormFooter
            actionClass={styles.actions}
            okText='Показать'
            cancelText='Очистить'
            onCancel={onCancel}
            position='right'
          />
        </Form>
      </Card>
    </>
  )
}
