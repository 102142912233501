import { Button, Modal, Upload } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

import { importMotivations } from '../../../../../../AC/doers/penalties'
import { State } from '../../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../../stores/requestsReducer'
import { getObjectWithMostProperties } from '../../../../../../utils/arrayUtils'

import styles from './styles.module.sass'


type Props = {
  isOpen: boolean
  onCancel: () => void
}

const selector = (state: State) => ({ isImporting: state.requestsReducer.importMotivations === StatusRequest.LOADING })

export const ImportModalMotivation = ({ isOpen, onCancel }: Props) => {
  const dispatch = useDispatch()
  const { isImporting } = useSelector(selector)

  const [table, setTable] = useState<any>(null)
  const [file, setFile] = useState<any>(null)

  
  const beforeUpload = (file: any) => {
    file.arrayBuffer().then((data: any) =>{
      const workbook = XLSX.read(data)
      const workSheet = workbook.Sheets['Итоговый отчет'] ?? workbook.Sheets['Нарушения по сменам']

      const jsonData = XLSX.utils.sheet_to_json(workSheet, {
        rawNumbers: false,
        blankrows: false,
      })

      setTable(jsonData)
      setFile(file)
    })

    return false
  }

  const onUpload = () => {
    dispatch(importMotivations(file, onCancel))
  }

  const afterClose = () => {
    setTable(null)
    setFile(null)
  }

  const modalWidth = table ? (table[0].length > 3 ? 1300 : 1100) : 480

  return (
    <Modal
      title='Импорт штрафов и бонусов'
      open={isOpen}
      onCancel={onCancel}
      afterClose={afterClose}
      destroyOnClose
      width={modalWidth}
      footer
    >
      <div className={styles.wrapper}>
        <div className={styles.uploadFields}>
          <Upload
            accept='.xlsx'
            maxCount={1}
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <Button
              type='primary'
            >
              <UploadOutlined />
              Загрузить
            </Button>
          </Upload>

        </div>


        {
          table && (
            <div  style={
              {
                maxHeight: 500,
                overflow: 'auto',
              }
            }
            >
              <table
                className={styles.previewTable}
              >
                <thead>
                  <tr>
                    <th>№</th>
                    {
                      Object.keys(getObjectWithMostProperties(table)).map((item: string) => (
                        <th key={item}>{ item }</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    table.map((value: string, index: number) => (
                      <tr key={index}>
                        <td>{ index + 1 }</td>
                        {
                          Object.values(value).map((item: string) => (
                            <td key={item}>
                              { item }
                            </td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>
      <div className={styles.uploadBtn}>
        {
          table && (
            <Button
              onClick={onUpload}
              type='primary'
              loading={isImporting}
            >
              Импортировать
            </Button>
          )
        }
      </div>
    </Modal>
  )
}
