import momentTimezone from 'moment-timezone'


type FormatDateTime = {
  start: string
  end?: string
  targetTimezone: string
  format?: string
}

/**
 * It takes a start and end time in the original timezone, and returns the start and end time in the
 * target timezone
 * @param {FormatDateTime}  - `start` - the start time of the event in the original timezone
 * @returns An array of start and end times.
 */
export const formatDateTime = ({
  start,
  end,
  targetTimezone,
  format = 'HH:mm:ssZ',
}: FormatDateTime) => {
  const originalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const startTime = momentTimezone.tz(start, format, originalTimezone)
  const startMoment = startTime.clone().tz(targetTimezone)
  
  const startTimeFormated = startMoment.format(format)
  
  if (end === undefined) {
    return startTimeFormated
  }
  
  const endTime = momentTimezone.tz(end, format, originalTimezone)
  const endMoment = endTime.clone().tz(targetTimezone)
  
  return [startTimeFormated, endMoment.format(format)] as const
}