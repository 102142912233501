import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'

import MotivationStatistics = APITYPES.DoerTariff.MotivationStatistics


export type MotivationsStatisticsState = {
  motivationStatistics: MotivationStatistics.Resp
  hasMore: boolean
  motivationStatisticsFilter: MotivationStatistics.Req
}

export const initialState: MotivationsStatisticsState = {
  motivationStatistics: [],
  hasMore: false,
  motivationStatisticsFilter: {} as MotivationStatistics.Req,
}

export const motivationStatisticsReducer: Reducer<MotivationsStatisticsState, Action> = (
  state = initialState,
  a
): MotivationsStatisticsState => {
  switch (a.type) {
    case Actions.CLEAN_MOTIVATION_STATISTICS: {
      return {
        ...state,
        motivationStatistics: [],
        hasMore: false,
      }
    }

    case Actions.SET_MOTIVATION_STATISTICS: {
      return {
        ...state,
        motivationStatistics: [...state.motivationStatistics, ...a.data.motivationStatistics],
        hasMore: a.data.hasMore,
      }
    }

    case Actions.SET_MOTIVATION_STATISTICS_FILTER: {
      return {
        ...state,
        motivationStatisticsFilter: a.data,
      }
    }
  }

  return state
}