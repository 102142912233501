import React from 'react'
import { Modal } from 'antd'

import { NewsModalType } from '../utils'

import { AddForm } from './AddForm'
import { EditForm } from './EditForm'
import { ManageForm } from './ManageForm'
import { getModalTitle } from './utils'


type Props = {
  isOpen: boolean
  type: NewsModalType | null
  onClose: () => void
}

export const NewsModal = React.memo((props: Props) => {
  const { type, onClose, isOpen } = props

  return (
    <Modal
      title={getModalTitle(type)}
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      destroyOnClose
      width={600}
    >
      { type === NewsModalType.ADD_NEWS && <AddForm onClose={onClose} /> }
      { type === NewsModalType.EDIT_NEWS && <EditForm onClose={onClose} /> }
      { type === NewsModalType.MANAGE_NEWS && <ManageForm onClose={onClose} /> }
    </Modal>
  )
})