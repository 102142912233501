// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--controlsBtn--7Ra31{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--btn--6l6qO{margin-right:8px;height:28px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/Bill/Controls/styles.module.sass"],"names":[],"mappings":"AAAA,mCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,2BACI,gBAAA,CACA,WAAA","sourcesContent":[".controlsBtn\n    display: flex\n    align-items: center\n\n.btn\n    margin-right: 8px\n    height: 28px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsBtn": "styles-module--controlsBtn--7Ra31",
	"btn": "styles-module--btn--6l6qO"
};
export default ___CSS_LOADER_EXPORT___;
