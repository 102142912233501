import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type UsersState = {
  users: APITYPES.User[]
  user: APITYPES.ExpandedUser | null
  isCreating: boolean
  search?: string
  hasMore: boolean
  subscriptions: APITYPES.UserSubscription[] | null
  orders: APITYPES.UserOrder[] | null
}

export const initialUsersState = {
  users: [],
  user: null,
  isCreating: false,
  hasMore: true,
  subscriptions: null,
  orders: null,
}

export const usersReducer: Reducer<UsersState, Action> = (state = initialUsersState, a): UsersState => {
  switch (a.type) {
    case Actions.SET_USER_ORDERS:
      return {
        ...state,
        orders: a.data
      }

    case Actions.SET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: a.data
      }

    case Actions.CLEAN_USERS:
      return {
        ...state,
        users: [],
        hasMore: true,
      }

    case Actions.SET_USERS_SEARCH:
      return {
        ...state,
        search: a.data,
      }

    case Actions.SET_USERS:
      return {
        ...state,
        users: [...state.users, ...a.data],
        hasMore: a.data.length ? true : false,
      }

    case Actions.SET_USER:
      return {
        ...state,
        user: a.data,
      }
  }

  return state
}
