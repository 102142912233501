import { ThAction } from '../../actions'
import { APITYPES } from '../../types/apitypes'
import { errorNotification, successNotification } from '../../components/modals/OperationNotification'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'


export const exportPartnersToDocument = (search: string = '', state: string = ''): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      dispatch(changeRequestState('exportPartners', StatusRequest.LOADING))

      const params: APITYPES.Partners.PartnersExport.Query = {}

      if (search) {
        params.search = search
      }
      if (state) {
        params.state = state
      }
      const resp = await api.post(APITYPES.Partners.PartnersExport.URL, {}, { params }, )

      if (resp) {
        successNotification('Успешный экпорт в таблицу')
        dispatch(changeRequestState('exportPartners', StatusRequest.LOADED))
      }
    } catch (e: any) {
      errorNotification(e?.response?.data || 'Что то пошло не так')
      dispatch(changeRequestState('exportPartners', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const exportCarwashesToDocument = (filter: APITYPES.CarwashFilter): ThAction => async (dispatch, _, { api }) => {
  try {
    dispatch(changeRequestState('exportCarwashes', StatusRequest.LOADING))


    const resp = await api.post(APITYPES.Partners.CarwashesExport.URL, {}, { params: filter }, )

    if (resp) {
      successNotification('Успешный экпорт в таблицу')
      dispatch(changeRequestState('exportCarwashes', StatusRequest.LOADED))
    }
  } catch (e: any) {
    errorNotification(e?.response?.data || 'Что то пошло не так')
    dispatch(changeRequestState('exportCarwashes', StatusRequest.ERROR))
    throw(e)
  }
}
