import { APITYPES } from 'types/apitypes'

import VacancyPriorityTrans = APITYPES.VacancyPriorityTrans
import DoerVacancy = APITYPES.DoerVacancy
import VacancyStateTrans = APITYPES.VacancyStateTrans


export interface CitiesOption {
  label: string | number | symbol
  value: number
}

export const getEnumKeys = <T extends object>(enumArg: T): (keyof T)[] => {
  const keys = Object.keys(enumArg)
    .filter((x) => !(parseInt(x) >= 0)) as (keyof T)[]

  return keys
}

export const getEnumOptions = (enumArg: any) => {
  const keys = getEnumKeys(enumArg)
  const options = keys.map((city, index) => ({
    label: city,
    value: index + 1,
  }))

  return options
}

export const getCitiesOptions = (): CitiesOption[] => getEnumOptions(APITYPES.Cities)

// @ts-ignore
export const cities = Object.keys(APITYPES.Cities).filter(isNaN)

export const getCityName = (city_id: number) => {
  return cities[city_id - 1]
}

export const getEnumKeysObject = (enumArg: any) => {
  const keys = getEnumKeys(enumArg)
  const obj: any = {}

  keys.forEach((key) => obj[key] = key)
  
  return obj
}

export const getEnumObject = (enumArg: any) => {
  const obj: any = {}

  for (const key in enumArg) {
    obj[key] = enumArg[key]
  }
  
  return obj
}

export const getEntries = (enumArg: any) => Object.entries(enumArg)

export const vacancyPriorities: Record<DoerVacancy['priority'], VacancyPriorityTrans> = {
  1: VacancyPriorityTrans['CRITICAL'],
  2: VacancyPriorityTrans['HIGH'],
  3: VacancyPriorityTrans['MEDIUM'],
  4: VacancyPriorityTrans['LOW'],
}

export const getPrioritiesOptions = () => Object.entries(vacancyPriorities)
  .map(([value, label]) => ({
    value: Number(value),
    label,
  }))

export const getVacancyStates = () => Object.entries(VacancyStateTrans)
  .map(([value, label]) => ({
    value: value as DoerVacancy['state'],
    label,
  }))

export const getTimeOptions = () => {
  const arr = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      if (minute === 0 || minute === 30) {
        const hourStr = hour.toString().padStart(2, '0')
        const minuteStr = minute.toString().padStart(2, '0')

        arr.push({
          label: `${ hourStr }:${ minuteStr }`,
          value: `${ hourStr }:${ minuteStr }`,
        })
      }
    }
  }

  return arr
}