import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'
import { openNotification } from 'components/modals/OperationNotification'
import { apiV1 } from 'network/http'
import { mapFilterToQuery } from '../../App/PrivateRoutes/Billing/DoerRecords/utils'


export const getDoerRecords = (activeTab:string ,filter: APITYPES.Billing.GetDoerRecords.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerRecords', StatusRequest.LOADING))
    const length = getState().doerRecordsReducer.doerRecordsMap[activeTab]?.doerRecords?.length ?? 0

    try {
      const resp = await api.get(APITYPES.Billing.GetDoerRecords.URL, {
        params: {
          ...filter,
          offset: length,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_RECORDS, {
          activeTab,
          doerRecords: resp,
          offset: length,
        }))
        dispatch(changeRequestState('getDoerRecords', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerRecords', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const payDoerRecords = (filter:APITYPES.Billing.PayDoerRecords.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.post(APITYPES.Billing.PayDoerRecords.URL, null, { params: { ...filter } },)

      if (resp) {
        openNotification('Оплачено')
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const acceptDoerRecords = (filter:APITYPES.Billing.AcceptDoerRecords.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.post(APITYPES.Billing.AcceptDoerRecords.URL, null, { params: { ...filter } },)

      if (resp) {
        openNotification('Согласовано')
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const exportDoerRecords = (
  filter:APITYPES.Billing.ExportDoerRecords.Req,
  { option, onFinish }: {
    option?: 'aggr' | 'console'
    onFinish?: () => void
  }
): ThAction => {
  return async (dispatch, _, { api }) => {
    const isAggregated = option === 'aggr'

    dispatch(changeRequestState(
      isAggregated
        ? 'exportAggregatedDoerRecords'
        : 'exportConsoleDoerRecords',
      StatusRequest.LOADING
    ))

    try {
      if (option === 'aggr') {
        filter = {
          ...filter,
          aggregated: 'true',
        }
      } else if ( option === 'console') {
        filter = {
          ...filter,
          console: 'true',
        }
      }


      //@ts-ignore
      const resp = await api.get(APITYPES.Billing.ExportDoerRecords.URL, {
        needFullAxiosResp: true,
        params: { ...filter },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN') },
        responseType: 'blob',
      })

      if (resp.data) {
        dispatch(changeRequestState(
          isAggregated
            ? 'exportAggregatedDoerRecords'
            : 'exportConsoleDoerRecords',
          StatusRequest.LOADED
        ))

        onFinish?.()

        let fileName = 'export_doer_records.xlsx'

        const contentDisposition = resp.headers['content-disposition']

        if (contentDisposition) {
          const utf8Filename = decodeURIComponent(escape(contentDisposition))
          const filenameWithExtensionMatch = utf8Filename.match(/filename="([^"]+)"/)
          const filenameWithExtension = filenameWithExtensionMatch ? filenameWithExtensionMatch[1] : ''

          const regex = /^(.+)\.([^.]+)$/

          const matches = filenameWithExtension.match(regex)

          if (matches) {
            fileName = matches[0]
          }
        }

        const url = window.URL.createObjectURL(resp.data)
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        openNotification('Экспортировано')
      }
    } catch (e: any) {
      dispatch(changeRequestState(
        isAggregated
          ? 'exportAggregatedDoerRecords'
          : 'exportConsoleDoerRecords',
        StatusRequest.ERROR
      ))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const importDoerRecords = (file: any ,dates: any, onClose: () => void): ThAction => {
  return async (dispatch, getState) => {
    try {
      const response = await apiV1.post(
        `/v1/billing/procedures/import_doer_records?billing_begin=${dates[0]}&billing_end=${dates[1]}`,
        file,
        {
          headers: {
            'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        openNotification('Импортировано')
        onClose()
        const activeTab = getState().doerRecordsReducer?.activeTabId
        const filters = getState().doerRecordsReducer.filtersMap[activeTab]

        dispatch(action(Actions.RESET_DOER_RECORDS, {}))
        dispatch(getDoerRecords(activeTab, mapFilterToQuery(filters)))
      }
    } catch (e: any) {
      showError(e.response.data)
      throw(e)
    }
  }
}

export const deleteDoerRecord = (doer_record_id: string, onSuccess: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteYandexProExecutor', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Billing.DeleteDoerRecord.URL, { replaceUrl: { doer_record_id } })

      if (resp) {
        openNotification('Удален')
        onSuccess()
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const getDoerRecordsPeriod = (params?:{offset:number}): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerRecordsPeriod', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Billing.GetDoerRecordsPeriod.URL, {
        params: {
          offset: typeof params?.offset === 'number' ? params?.offset : (getState().billingReducer.doerRecordsPeriod.length) || 0,
          limit: 20,
        },
      },)

      if (resp) {
        dispatch(action(Actions.SET_DOER_RECORDS_PERIOD, {
          doerRecordsPeriod: resp,
          offset: getState().billingReducer.doerRecordsPeriod?.length ?? 0,
        }))
        dispatch(changeRequestState('getDoerRecordsPeriod', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerRecordsPeriod', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getDoerRecordsTypes = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    try {
      const resp = await api.get(APITYPES.Billing.GetDoerRecordTypes.URL)

      if (resp) {
        dispatch(action(Actions.SET_DOER_RECORD_TYPES, resp))
      }
    } catch (e: any) {
      showError(e.response.data)
      throw(e)
    }
  }
}
