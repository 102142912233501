export const SUBWAYS = [
  '-',
  'Авиамоторная',
  'Автозаводская',
  'Академическая',
  'Александровский сад',
  'Алексеевская',
  'Алма-Атинская',
  'Алтуфьево',
  'Аминьевское шоссе',
  'Андроновка',
  'Аннино',
  'Арбатская',
  'Арбатская',
  'Аэропорт',
  'Бабушкинская',
  'Багратионовская',
  'Балтийская',
  'Баррикадная',
  'Бауманская',
  'Беговая',
  'Белокаменная',
  'Беломорская',
  'Белорусская',
  'Беляево',
  'Бибирево',
  'Библиотека имени Ленина',
  'Битцевский парк',
  'Борисово',
  'Боровицкая',
  'Боровское шоссе',
  'Ботанический сад',
  'Братиславская',
  'Бульвар адмирала Ушакова',
  'Бульвар Дмитрия Донского',
  'Бульвар Рокоссовского',
  'Бунинская аллея',
  'Бутырская',
  'Варшавская',
  'ВДНХ',
  'Верхние Котлы',
  'Верхние Лихоборы',
  'Владыкино',
  'Водный стадион',
  'Войковская',
  'Волгоградский проспект',
  'Волжская',
  'Волоколамская',
  'Волхонка',
  'Воробьёвы горы',
  'Воронцовская',
  'Выставочная',
  'Выставочный центр',
  'Выхино',
  'Говорово',
  'Деловой центр',
  'Динамо',
  'Дмитровская',
  'Дмитровское шоссе',
  'Добрынинская',
  'Домодедовская',
  'Дорогомиловская',
  'Достоевская',
  'Дубровка',
  'Дубровка',
  'Жулебино',
  'ЗИЛ',
  'Зорге',
  'Зюзино',
  'Зябликово',
  'Измайлово',
  'Измайловская',
  'Калужская',
  'Кантемировская',
  'Каховская',
  'Каширская',
  'Киевская',
  'Китай-город',
  'Кожуховская',
  'Коломенская',
  'Коммунарка',
  'Комсомольская',
  'Коньково',
  'Коптево',
  'Косино',
  'Котельники',
  'Красногвардейская',
  'Краснопресненская',
  'Красносельская',
  'Красные ворота',
  'Крестьянская застава',
  'Кропоткинская',
  'Крылатское',
  'Крымская',
  'Кузнецкий мост',
  'Кузьминки',
  'Кунцевская',
  'Курская',
  'Кутузовская',
  'Ленинский проспект',
  'Лермонтовский проспект',
  'Лесопарковая',
  'Лефортово',
  'Лихоборы',
  'Локомотив',
  'Ломоносовский проспект',
  'Лубянка',
  'Лужники',
  'Лухмановская',
  'Люблино',
  'Марксистская',
  'Марьина роща',
  'Марьино',
  'Маяковская',
  'Медведково',
  'Международная',
  'Менделеевская',
  'Минская',
  'Митино',
  'Мичуринский проспект',
  'Мневники',
  'Мнёвники',
  'Молодёжная',
  'Мякинино',
  'Нагатинская',
  'Нагатинский затон',
  'Нагорная',
  'Народное Ополчение',
  'Нахимовский проспект',
  'Некрасовка',
  'Нижегородская',
  'Нижегородская улица',
  'Новогиреево',
  'Новокосино',
  'Новокузнецкая',
  'Новопеределкино',
  'Новослободская',
  'Новохохловская',
  'Новоясеневская',
  'Новые Черёмушки',
  'Озерная',
  'Окружная',
  'Окская',
  'Октябрьская',
  'Октябрьское поле',
  'Ольховая',
  'Орехово',
  'Отрадное',
  'Охотный ряд',
  'Павелецкая',
  'Панфиловская',
  'Парк культуры',
  'Парк Победы',
  'Партизанская',
  'Первомайская',
  'Перово',
  'Петровский парк',
  'Петровско-Разумовская',
  'Печатники',
  'Пионерская',
  'Планерная',
  'Площадь Гагарина',
  'Площадь Ильича',
  'Площадь Революции',
  'Плющиха',
  'Полежаевская',
  'Полянка',
  'Пражская',
  'Преображенская площадь',
  'Прокшино',
  'Пролетарская',
  'Проспект Вернадского',
  'Проспект Мира',
  'Профсоюзная',
  'Пушкинская',
  'Пятницкое шоссе',
  'Раменки',
  'Рассказовка',
  'Речной вокзал',
  'Ржевская',
  'Рижская',
  'Римская',
  'Ростокино',
  'Румянцево',
  'Рязанский проспект',
  'Савёловская',
  'Саларьево',
  'Свиблово',
  'Севастопольская',
  'Севастопольский проспект',
  'Селигерская',
  'Семёновская',
  'Серпуховская',
  'Славянский бульвар',
  'Смоленская',
  'Сокол',
  'Соколиная Гора',
  'Сокольники',
  'Солнцево',
  'Спартак',
  'Спортивная',
  'Сретенский бульвар',
  'Стахановская',
  'Стрешнево',
  'Строгино',
  'Стромынка',
  'Студенческая',
  'Суворовская',
  'Сухаревская',
  'Сходненская',
  'Таганская',
  'Таганская',
  'Тверская',
  'Театральная',
  'Текстильщики',
  'Телецентр',
  'Тёплый стан',
  'Терехово',
  'Технопарк',
  'Тимирязевская',
  'Третьяковская',
  'Тропарёво',
  'Трубная',
  'Тульская',
  'Тургеневская',
  'Тушинская',
  'Угрешская',
  'Улица 1905 года',
  'Улица академика Королёва',
  'Улица академика Янгеля',
  'Улица Горчакова',
  'улица Дмитриевского',
  'Улица Милашенкова',
  'Улица Народного ополчения',
  'Улица Новаторов',
  'Улица Сергея Эйзенштейна',
  'Улица Скобелевская',
  'Улица Старокачаловская',
  'Университет',
  'Филатов луг',
  'Филёвский парк',
  'Фили',
  'Фонвизинская',
  'Фрунзенская',
  'Ховрино',
  'Хорошёво',
  'Хорошёвская',
  'Царицыно',
  'Цветной бульвар',
  'ЦСКА',
  'Черкизовская',
  'Чертановская',
  'Чеховская',
  'Чистые пруды',
  'Чкаловская',
  'Шаболовская',
  'Шелепиха',
  'Шереметьевская',
  'Шипиловская',
  'Шоссе Энтузиастов',
  'Щёлковская',
  'Щукинская',
  'Электрозаводская',
  'Юго-Восточная',
  'Юго-Западная',
  'Южная',
  'Ясенево',
  '-',
  'Автово',
  'Адмиралтейская',
  'Академическая',
  'Балтийская',
  'Бухарестская',
  'Василеостровская',
  'Владимирская',
  'Волковская',
  'Выборгская',
  'Горьковская',
  'Гостиный двор',
  'Гражданский проспект',
  'Девяткино',
  'Достоевская',
  'Елизаровская',
  'Звёздная',
  'Звенигородская',
  'Кировский завод',
  'Комендантский проспект',
  'Крестовский остров',
  'Купчино',
  'Ладожская',
  'Ленинский проспект',
  'Лесная',
  'Лиговский проспект',
  'Ломоносовская',
  'Маяковская',
  'Международная',
  'Московская',
  'Московские ворота',
  'Нарвская',
  'Невский проспект',
  'Новочеркасская',
  'Обводный канал',
  'Обухово',
  'Озерки',
  'Парк Победы',
  'Парнас',
  'Петроградская',
  'Пионерская',
  'Площадь Александра Невского I',
  'Площадь Восстания',
  'Площадь Ленина',
  'Площадь Мужества',
  'Площадь Невского',
  'Политехническая',
  'Приморская',
  'Пролетарская',
  'Проспект Большевиков',
  'Проспект Ветеранов',
  'Проспект Просвещения',
  'Пушкинская',
  'Рыбацкое',
  'Садовая',
  'Сенная площадь',
  'Спасская',
  'Спортивная',
  'Старая Деревня',
  'Технологический институт I',
  'Технологический институт II',
  'Удельная',
  'Улица Дыбенко',
  'Фрунзенская',
  'Чёрная речка',
  'Чернышевская',
  'Чкаловская',
  'Электросила',
]

export const DISTRICTS = [
  '-',
  'Академический',
  'Алексеевский',
  'Алтуфьевский',
  'Арбат',
  'Аэропорт',
  'Бабушкинский',
  'Балашиха',
  'Басманный',
  'Беговой',
  'Бескудниковский',
  'Бибирево',
  'Богородский',
  'Братеево',
  'Бутырский',
  'Вешняки',
  'Видное',
  'Внуково',
  'Войковский',
  'Восточное Бирюлёво',
  'Восточное Дегунино',
  'Восточное Измайлово',
  'Восточный',
  'Выхино-Жулебино',
  'г. Московский',
  'г. Троицк',
  'г. Щербинка',
  'Гагаринский',
  'Головинский',
  'Гольяново',
  'Даниловский',
  'Дзержинский',
  'Дмитровский',
  'Долгопрудный',
  'Домодедово',
  'Донской',
  'Дорогомилово',
  'Жуковский',
  'Замоскворечье',
  'Западное Бирюлёво',
  'Западное Дегунино',
  'Зеленоград',
  'Зюзино',
  'Зябликово',
  'Ивановский',
  'Ивантеевка',
  'Измайлово',
  'Капотня',
  'Коньково',
  'Коптево',
  'Королев',
  'Косино-Ухтомский',
  'Котельники',
  'Котловка',
  'Красногорск',
  'Красносельский',
  'Крылатское',
  'Кузьминки',
  'Кунцево',
  'Куркино',
  'Левобережный',
  'Лефортово',
  'Лианозово',
  'Лобня',
  'Ломоносовский',
  'Лосиноостровский',
  'Лыткарино',
  'Люберцы',
  'Люблино',
  'Марфино',
  'Марьина Роща',
  'Марьино',
  'Метрогородок',
  'Мещанский',
  'Митино',
  'Можайский',
  'Молжаниновский',
  'Молоково',
  'Москворечье-Сабурово',
  'Мытищи',
  'Нагатино-Садовники',
  'Нагатинский Затон',
  'Нагорный',
  'Некрасовка',
  'Нижегородский',
  'Ново-Переделкино',
  'Новогиреево',
  'Новокосино',
  'Ногинск',
  'Обручевский',
  'Одинцово',
  'Орехово-Борисово Северное',
  'Останкинский',
  'Отрадное',
  'Очаково-Матвеевское',
  'Перово',
  'Печатники',
  'Покровское-Стрешнево',
  'Поселение Внуковское',
  'Поселение Воскресенское',
  'Поселение Десёновское',
  'Поселение Кокошкино',
  'Поселение Мосрентген',
  'Поселение Рязановское',
  'Поселение Сосенское',
  'Преображенское',
  'Пресненский',
  'Проспект Вернадского',
  'Пушкино',
  'Раменки',
  'Реутов',
  'Ростокино',
  'Рязанский',
  'Савёловский',
  'Свиблово',
  'Северное Бутово',
  'Северное Измайлово',
  'Северное Медведково',
  'Северное Тушино',
  'Северное Чертаново',
  'Северный',
  'Сокол',
  'Соколиная Гора',
  'Сокольники',
  'Солнечногорск',
  'Солнцево',
  'Строгино',
  'Таганский',
  'Тверской',
  'Текстильщики',
  'Тёплый Стан',
  'Тимирязевский',
  'Тропарёво-Никулино',
  'Филевский парк',
  'Фили Давыдково',
  'Фрязино',
  'Хамовники',
  'Химки',
  'Ховрино',
  'Хорошёво-Мневники',
  'Хорошёвский',
  'Царицыно',
  'Центральное Чертаново',
  'Черёмушки',
  'Щелково',
  'Щукино',
  'Южное Бутово',
  'Южное Медведково',
  'Южное Орехово-Борисово',
  'Южное Тушино',
  'Южное Чертаново',
  'Южнопортовый',
  'Якиманка',
  'Ярославский',
  'Ясенево',
  'Адмиралтейский',
  'Василеостровский',
  'Выборгский',
  'Калининский',
  'Кировский',
  'Колпинский',
  'Красногвардейский',
  'Красносельский',
  'Кронштадтский',
  'Курортный',
  'Московский',
  'Невский',
  'Петроградский',
  'Петродворцовый',
  'Приморский',
  'Пушкинский',
  'Фрунзенский',
  'Центральный',
  'Адлерский',
  'Лазаревский',
  'Хостинский',
  'Центральный',
]

export const AREAS = [ '-', 'ЦАО', 'САО', 'СВАО', 'ВАО', 'ЮВАО', 'ЮАО', 'ЮЗАО', 'ЗАО', 'СЗАО' ]

const qosDictionaryObj = {
  25: 'Эконом',
  50: 'Среднее',
  75: 'Премиум',
}

export const qosDictionary = new Map(Object.entries(qosDictionaryObj))