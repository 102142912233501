import React, { useEffect } from 'react'
import { Button, Checkbox, DatePicker, Form, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getDoerById } from 'AC/doers/getDoers'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getUrlSearchParams } from 'utils/urlUtils'
import { useDoerSearch } from '../ShiftsPage/components/ShiftTab/ShiftInfo/AddDoerToShiftModal/useDoerSearch'


const { Cities } = APITYPES
const backDateFormat = 'YYYY-MM-DD'

const selector = (state: State) => ({
  offers: state.offersReducer.offers,
  offFilter: state.offersReducer.offersFilter,
  hasMoreOffers: state.offersReducer.hasMoreOffers,
  isLoading: state.requestsReducer.fetchOffers === StatusRequest.LOADING,
  roles: state.doersReducer.roles,
  shiftTypes: state.shiftsReducer.shiftTypes,
  locations: state.shiftsReducer.locations,
  doer: state.doersReducer.doer,
})

const Filters = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { offFilter: filter, roles, shiftTypes, locations, doer } = useSelector(selector)
  const urlParams = getUrlSearchParams()

  useEffect(() => {
    if (urlParams?.doer_id) {
      dispatch(getDoerById(urlParams?.doer_id, true))
    } else {
      dispatch(action(Actions.SET_SEARCHED_DOER, null))
    }
  }, [filter.doer_id])

  const {
    onDoerClear,
    onDoerSearch,
    searchedDoers,
    isSearchedDoersLoading,
  } = useDoerSearch()

  const onChangeFilter = (filter: any) => {
    const reserveFilter = {
      role_id: filter.role_id ? filter.role_id : undefined,
      shift_type_id: filter.shift_type_id ? filter.shift_type_id : undefined,
      location_id: filter.location_id ? filter.location_id : undefined,
      date: filter.date ? filter.date : undefined,
      understaffed: filter.understaffed ? filter.understaffed : undefined,
      date_from: filter.date_from ? filter.date_from : undefined,
      date_to: filter.date_to ? filter.date_to : undefined,
      doer_id: filter.doer_id ? filter.doer_id : undefined,
    }

    dispatch(action(Actions.CLEAR_OFFERS, undefined))
    dispatch(action(Actions.SET_OFFERS_FILTER, reserveFilter))
    history.push(ROUTES.DOERS.OFFERS.PARAMS.createPath(
      reserveFilter,
    ))
  }

  return (
    <>
      <Form layout='vertical'>
        <Form.Item>
          <DatePicker.RangePicker
            format='L'
            value={
              (filter.date_from && filter.date_to)
                ? [moment(filter.date_from), moment(filter.date_to)]
                : undefined
            }
            onChange={
              (dates) => onChangeFilter({
                ...filter,
                date_from: (dates && dates[0]) ? dates[0].format(backDateFormat) : undefined,
                date_to: (dates && dates[1]) ? dates[1].format(backDateFormat) : undefined,
              })
            }
          />
        </Form.Item>
        <Form.Item label='Смена'>
          <Select
            placeholder='Смена'
            showSearch
            filterOption={
              (input, option) =>
                ((option!.children as unknown as string) || '').toLowerCase().includes(input.toLowerCase())
            }
            mode='multiple'
            allowClear
            value={filter?.shift_type_id}
            onChange={
              (value) => {
                onChangeFilter({
                  ...filter,
                  shift_type_id: value.length ? value : undefined,
                })
              }
            }
          >
            {
              shiftTypes?.map((shift) => (
                <Select.Option
                  key={shift.id}
                  //@ts-ignore
                  value={shift.id}
                >
                  { shift.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label='Роль'>
          <Select
            placeholder='Роль'
            showSearch
            filterOption={
              (input, option) =>
                (option!.children as unknown as string || '').toLowerCase().includes(input.toLowerCase())
            }
            mode='multiple'
            allowClear
            value={filter?.role_id}
            onChange={
              (value) => onChangeFilter({
                ...filter,
                role_id: value.length ? value : undefined,
              })
            }
          >
            {
              roles?.map((roles) => (
                <Select.Option
                  key={roles.id}
                  //@ts-ignore
                  value={roles.id}
                >
                  { roles.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label='Место'>
          <Select
            allowClear
            placeholder='Место'
            showSearch
            filterOption={
              (input, option) =>
                ((option!.children!.join('') as unknown as string) ?? '').toLowerCase().includes(input.toLowerCase())
            }
            mode='multiple'
            value={filter?.location_id}
            onChange={
              (value) => onChangeFilter({
                ...filter,
                location_id: value.length ? value : undefined,
              })
            }
          >
            {
              locations?.map((loc) => (
                <Select.Option
                  key={loc.id}
                  //@ts-ignore
                  value={roles.id}
                >
                  { Cities[loc.city_id] }
                  ,
                  { ' ' }
                  { loc.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label='Исполнитель'>
          <Select
            allowClear
            autoFocus
            showSearch
            value={
              doer ? {
                label:  doer.name + ` (${doer.phone_number})`,
                value: doer.id,
              } : undefined
            }
            onBlur={onDoerClear}
            filterOption={false}
            onClear={onDoerClear}
            onSearch={onDoerSearch}
            autoClearSearchValue={false}
            options={
              searchedDoers.map((doer) => ({
                label: doer.name + ` (${doer.phone_number})`,
                value: doer.id,
              }))
            }
            loading={isSearchedDoersLoading}
            placeholder='Начните искать исполнителя...'
            onChange={
              (value) => onChangeFilter({
                ...filter,
                doer_id: value,
              })
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={Boolean(filter?.understaffed)}
            onChange={
              (value) => onChangeFilter({
                ...filter,
                understaffed: value.target.checked ? value.target.checked : undefined,
              })
            }
          >
            Не полные резервы
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button onClick={
            () => onChangeFilter({
              understaffed: undefined,
              date_from: undefined,
              date_to: undefined,
              doer_id: undefined,
              role_id: undefined,
              shift_type_id: undefined,
              location_id: undefined,
              date: undefined,
            })
          }
          >
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default Filters