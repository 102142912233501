import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { addCarwashPhoto, fetchCarwashPhotos } from 'AC/carwashes/carwashes'
import { State } from 'stores/rootReducer'
import { Loader } from 'components/Loader'
import { deleteFile } from 'AC/carwashes/deleteFile'
import { showSureConfirm } from 'components/modals/SureModal'

import styles from './styles.module.sass'


const selector = (state: State) => ({ photos: state.carwashesReducer.carwashPhotos })

export const Photos = () => {
  const dispatch = useDispatch()

  const { photos } = useSelector(selector)

  const [previewImage, setPreviewImage] = useState<{
    uid: string
    name: string
    url: string
  } | null>(null)

  useEffect(()=>{
    dispatch(fetchCarwashPhotos())
  }, [dispatch])

  const onUploadImage = (fileObj: any) => {
    dispatch(addCarwashPhoto(fileObj.file))
  }

  const handleCancel = () => {
    setPreviewImage(null)
  }

  const onPreview = (file: any) => {
    setPreviewImage(file)
  }

  const fileList = photos?.map((photo, i)=>({
    uid: `${i}`,
    name: photo.name,
    url: photo.url,
  }))

  const onRemove = (file: any) => {
    const fileId = file.url.split('/')[file.url.split('/').length - 1]

    if (!fileId) {return}

    showSureConfirm(()=>{
      dispatch(deleteFile(fileId))
    },()=>{})

    return false
  }

  return (
    <div className={styles.photoBlock}>
      {
        !fileList && <Loader />
      }
      {
        fileList && (
          <>
            <Upload
              className={styles.photos}
              listType='picture-card'
              fileList={fileList}
              onPreview={onPreview}
              onRemove={onRemove}
              onDownload={onUploadImage}
              onChange={onUploadImage}
              beforeUpload={() => false}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
            <Modal
              visible={!!previewImage}
              title={previewImage?.name}
              footer={null}
              onCancel={handleCancel}
            >
              {
                previewImage && (
                  <img
                    alt='example'
                    style={{ width: '100%' }}
                    src={previewImage.url}
                  />
                )
              }
            </Modal>
          </>
        )
      }
    </div>
  )
}