import React from 'react'
import { Tag } from 'antd'

import styles from './styles.module.sass'


export enum GroupColors {
  partners = 'blue',
  marketing = 'green',
}

export const getGroupTag = (group?: string): JSX.Element | null => {
  if (group) {
    return (
      <Tag
        // @ts-ignore
        color={GroupColors[group]}
        className={styles.tag}
      >
        { group }
      </Tag>
    )
  }
  
  return (
    <Tag
      color='red'
      className={styles.tag}
    >
      no group
    </Tag>
  )
}