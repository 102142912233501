type MapKey = string

export const toggleArrayItem = <T>(array: T[], item: T): T[] => {
  if (array.indexOf(item) !== -1) {
    return array.filter((element) => element !== item)
  }

  return [...array, item]
}

export const mergeList = <T>(
  oldList: MapKey[],
  items: T[],
  key: keyof T,
  order: 'direct' | 'reverse' = 'direct',
): MapKey[] => {
  // @ts-ignore
  const newList: MapKey[] = items.map((item) => item[key])

  const list = order === 'direct' ? [...oldList, ...newList] : [...newList, ...oldList]

  return [...new Set(list)]
}

export const mergeArrayToMap = <T, M extends { set(k: MapKey, v: T): void }>(map: M, arr: T[], key: keyof T) => {
  arr.forEach((item) => {
    const itemKey = item[key]
    
    if (typeof itemKey === 'string') {
      map.set(itemKey, item)
    }
  })

  return map
}

export const getItemsFromListAndMap = <T>(
  list: MapKey[],
  itemsMap: Map<MapKey, T>,
): T[] => {
  return list.map((id) => itemsMap.get(id))
    .filter((item): item is T => item !== undefined)
}

export const removeDuplicateObjects = <T>(list: T[] | null | undefined, getKey: (item: T) => string) => {
  return [
    ...new Map(
      list?.map((item) => [getKey(item), item])
    ).values(),
  ]
}

export const getObjectWithMostProperties = (arr: any[]) => {
  if (arr.length === 0) {return null} // Return null if the array is empty

  let maxPropertiesObject = arr[0] // Initialize with the first object
  let maxPropertiesCount = Object.keys(arr[0]).length

  for (let i = 1; i < arr.length; i++) {
    const currentPropertiesCount = Object.keys(arr[i]).length

    if (currentPropertiesCount > maxPropertiesCount) {
      maxPropertiesObject = arr[i]
      maxPropertiesCount = currentPropertiesCount
    }
  }

  return maxPropertiesObject
}

export const createArrayIncrementally = (length: number) => Array.from({ length }, (_, i) => i++)