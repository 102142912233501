import { action, Actions, ThAction } from 'actions'
import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer'
import { showError } from 'components/modals/ErrorModal'

import { changeRequestState } from './request'


export const fetchBrands = (): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('fetchBrands', StatusRequest.LOADING))
      
    try {
      const resp = await api.get(APITYPES.Cars.GetBrands.URL)
    
      if (resp) {
        dispatch(action(Actions.SET_BRANDS, resp))
        dispatch(changeRequestState('fetchBrands', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchBrands', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}

export const fetchModels = (brand_id?: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('fetchModels', StatusRequest.LOADING))
        
    try {
      const id = brand_id || getState().carsReducer.brand?.id

      if (!id) {return}

      const resp = await api.get(APITYPES.Cars.GetModels.URL, { replaceUrl: { id } })
      
      if (resp) {
        dispatch(action(Actions.SET_MODELS, resp))
        dispatch(changeRequestState('fetchModels', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchModels', StatusRequest.ERROR))
    
      showError(e.response.data)
      
      throw(e)
    }
  }
}