import React, { useState } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { createEmployee } from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'
import { Contacts } from '../Contacts'


const { useForm } = Form

type Props = {
  carwashId: string
  isVisible: boolean
  onCancel: () => void
}

export const CreateModal = (props: Props) => {
  const { isVisible, onCancel, carwashId } = props
  const dispatch = useDispatch()
  const [form] = useForm()

  const [emails, setEmails] = useState<string[]>([])
  const [phones, setPhones] = useState<string[]>([])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  }

  const onFinish = (values: any) => {
    dispatch(createEmployee({
      ...values,
      phones,
      emails,
    }, { carwash_id: carwashId }))

    form.resetFields()
    setEmails([])
    setPhones([])
    onCancel()
  }

  return (
    <Modal
      title='Создание работника'
      visible={isVisible}
      onCancel={onCancel}
      width={600}
      footer={false}
    >
      <Form
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          label='ФИО'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='role'
          label='Должность'
          initialValue='ЛПР'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='ЛПР'>ЛПР</Select.Option>
            <Select.Option value='Администратор'>Администратор</Select.Option>
            <Select.Option value='Мойщик'>Мойщик</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='contacts'
          label='Контакты'
        >
          <Contacts
            emails={emails}
            phones={phones}
            setPhones={setPhones}
            setEmails={setEmails}
          />
        </Form.Item>
        <Form.Item
          name='comment'
          label='Комментарий'
          initialValue=''
        >
          <Input />
        </Form.Item>
        <FormFooter onCancel={onCancel} />
      </Form>
    </Modal>
  )
}