import React, { FC } from 'react'
import cn from 'classnames'

import cls from './dot.module.sass'


type ColorType = 'red' | 'green' | 'blue' | 'yellow' | 'orange' | 'gray'

export type DotProps = {
  color: ColorType
}
export const Dot: FC<DotProps> = ({ color }) => {
  return (
    <div className={cn(cls.wrapper, cls[color || ''])} />
  )
}