import React from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { createCustomerTariff } from 'AC/products/tariffs'
import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'

const {Option} = Select

const formLayout = {
  labelCol: {span: 6}
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  clientTypes: state.directoryReducer.clientTypes,
  carClasses: state.directoryReducer.carClasses,
})

export const AddTariffModal = ({isOpen, onClose}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {clientTypes, carClasses } = useSelector(selector)

  const onFinish = (values: any) => {
    dispatch(createCustomerTariff(values))
    onClose()
  }

  return (
    <Modal
      title="Создание нового тарифа"
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      afterClose={form.resetFields}
    >
      <Form form={form} onFinish={onFinish} {...formLayout}>
        <Form.Item label='Название' name='name' rules={required}>
          <Input placeholder='Введите название' />
        </Form.Item>

        <Form.Item label="Тип клиента" name='client_type_id' rules={required}>
          <Select
            showSearch
            allowClear
            placeholder='Выберите тип клиента'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {clientTypes?.map(type =>
              <Option key={type.id} value={type.id}>{type.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Класс автомобиля" name='car_class_id' rules={required}>
          <Select
            showSearch
            allowClear
            placeholder='Выберите класс автомобиля'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {carClasses?.map(cls =>
              <Option key={cls.id} value={cls.id}>{cls.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label='Примеры' name='examples'>
          <Input placeholder='Введите пример' />
        </Form.Item>

        <FormFooter onCancel={onClose} />
      </Form>
    </Modal>
  );
};
