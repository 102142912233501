// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--xlkxZ{cursor:pointer}.styles-module--header--0\\+iQw{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px}.styles-module--container--SKQM1{overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-bottom:10px;height:calc(100vh - 90px)}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Doers/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,cAAA,CAEJ,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAEF,iCACE,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,mBAAA,CACA,yBAAA","sourcesContent":[".table\n    cursor: pointer\n\n.header\n  display: flex\n  justify-content: space-between\n  align-items: center\n  margin-bottom: 16px\n\n.container\n  overflow: hidden\n  display: flex\n  flex-direction: column\n  padding-bottom: 10px\n  height: calc(100vh - 90px)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--xlkxZ",
	"header": "styles-module--header--0+iQw",
	"container": "styles-module--container--SKQM1"
};
export default ___CSS_LOADER_EXPORT___;
