import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card as BaseCard, Tag } from 'antd'

import { fetchCarwashesStatistics, fetchPartnersStatistics } from 'AC/statistics'
import { State } from 'stores/rootReducer'

import styles from './styles.module.sass'


const selector = (state: State) => ({
  partners: state.statisticsReducer.partners,
  carwashes: state.statisticsReducer.carwashes,
})

export const Home = () => {
  const dispatch = useDispatch()

  const { partners, carwashes } = useSelector(selector)

  useEffect(()=>{
    dispatch(fetchCarwashesStatistics())
    dispatch(fetchPartnersStatistics())
  }, [dispatch])

  return (
    <div>
      <h1 className={styles.title}>Статистика</h1>
      <div className={styles.statisticsBlock}>
        <BaseCard
          className={styles.card}
          title='Мойки'
        >
          <div className={styles.fields}>
            {
              carwashes && Object.keys(carwashes).map((key, i)=>{
                return (
                  <div
                    className={styles.tagBlock}
                    key={i}
                  >
                    <Tag>
                      { key }
                      :
                      { ' ' }
                    </Tag>
                    {  carwashes[key] }
                  </div>
                )
              })
            }
          </div>
        </BaseCard>
        <BaseCard
          className={styles.card}
          title='Партнеры'
        >
          <div className={styles.fields}>
            {
              partners && Object.keys(partners).map((key, i)=>{
                return (
                  <div
                    className={styles.tagBlock}
                    key={i}
                  >
                    <Tag>
                      { key }
                      :
                      { ' ' }
                    </Tag>
                    {  partners[key] }
                  </div>
                )
              })
            }
          </div>
        </BaseCard>
      </div>
    </div>
  )
}