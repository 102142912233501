// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--filterSearch--81nc0{width:220px;padding:6px 6px 0 6px}.styles-module--filterSearch--81nc0 button{padding:0}.styles-module--divider--PjkBH{margin:8px 0 0 -8px;width:220px}", "",{"version":3,"sources":["webpack://./src/components/FilterSearch/styles.module.sass"],"names":[],"mappings":"AAAA,oCACE,WAAA,CACA,qBAAA,CAEA,2CACE,SAAA,CAEJ,+BACE,mBAAA,CACA,WAAA","sourcesContent":[".filterSearch\n  width: 220px\n  padding: 6px 6px 0 6px\n\n  & button\n    padding: 0\n\n.divider\n  margin: 8px 0 0 -8px\n  width: 220px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterSearch": "styles-module--filterSearch--81nc0",
	"divider": "styles-module--divider--PjkBH"
};
export default ___CSS_LOADER_EXPORT___;
