import React, { useEffect } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { changeDoerShift, fetchShiftsForRedirect } from 'AC/shifts/shifts'
import { FormFooter } from 'components/FormFooter'
import { useQueryParams } from '../../../../../../../../utils/hooks/useQueryParams'


type Props = {
  isOpen: boolean
  doer: APITYPES.ShiftDoer | null
  onClose: () => void
  shift: APITYPES.Shift
}

const formLayout = { labelCol: { span: 6 } }

const selector = (state: State) => ({
  redirectShifts: state.shiftsReducer.shiftsForRedirect,
  isLoading: state.requestsReducer.fetchShiftsForRedirect === StatusRequest.LOADING,
})

export const ChangeDoerShiftModal = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const params = useQueryParams()
  const { redirectShifts, isLoading } = useSelector(selector)
  const { isOpen, onClose, doer, shift } = props

  /** fetch shifts for redirecting */
  useEffect(() => {
    if (!doer) {return}

    if (params?.date_from && params?.date_to) {
      dispatch(fetchShiftsForRedirect(shift, {
        date_from: params.date_from,
        date_to: params.date_to,
        city_id: shift.location.city_id,
        shift_type_id: shift.shift_type_id,
        role_id: doer.role.id,
      }))
    }
  }, [dispatch, doer, params?.date_from, params?.date_to, shift])

  const onFinish = (values: any) => {
    if (!doer) {return}

    dispatch(changeDoerShift(shift.id, doer.doer_shift_id, {
      comment: values.comment,
      shift_id: values.shift_id,
    }))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Изменение смены исполнителя'
      visible={isOpen}
      afterClose={afterClose}
      footer={false}
      onCancel={onClose}
      forceRender={false}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          label='Смена'
          name='shift_id'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select loading={isLoading}>
            {
              redirectShifts.map((shift: APITYPES.Shift) => (
                <Select.Option
                  key={shift.id}
                  value={shift.id}
                >
                  {shift.location.name } { `(${ shift.date })` }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Комментарий'
          name='comment'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input.TextArea autoSize={
            {
              minRows: 3,
              maxRows: 5,
            }
          }
          />
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Изменить'
        />
      </Form>
    </Modal>
  )
}
