export function getAllSubtask(tasks:any) {
  const allSubtasks:any = []

  function traverseSubtasks(subtasks:any) {
    if (!subtasks) {return}

    for (const subtask of subtasks) {
      allSubtasks.push(subtask)
      traverseSubtasks(subtask.subtasks)
    }
  }

  traverseSubtasks(tasks)

  return allSubtasks
}