import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'


export const useQueryParams = () => {
  const location = useLocation()

  const resultParams = useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    const params: Record<string, string> = {}

    queryParams.forEach((value, key) => {
      params[key] = value
    })

    return params
  }, [location])

  return resultParams
}
