import React, { useEffect } from "react";
import { Col, Row, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTES } from "config/routes";
import { State } from "stores/rootReducer";
import { StatusRequest } from "stores/requestsReducer";
import { fetchAllBrandModels } from "AC/directory/directory";
import { getUrlParam } from "utils/urlUtils";


const selector = (state: State) => {
  const { carBrandModels } = state.directoryReducer;
  const isBrandModelsLoading =
    state.requestsReducer.fetchAllBrandModels === StatusRequest.LOADING;
  const isCarModelInfoLoading =
    state.requestsReducer.fetchCarModelInfo === StatusRequest.LOADING;

  const isLoading = isBrandModelsLoading || isCarModelInfoLoading;

  return {
    carBrandModels,
    isLoading,
  };
};

export const CarModels = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { carBrandModels, isLoading } = useSelector(selector);
  const model_id = getUrlParam(pathname, 2);
  const brand_id = getUrlParam(pathname, 1)

  useEffect(() => {
    if(!brand_id) return
    dispatch(fetchAllBrandModels(brand_id));
  }, [brand_id]);

  useEffect(() => {
    if (carBrandModels) {
      setTab(model_id ?? carBrandModels[0].id);
    }
  }, [carBrandModels]);


  const setTab = (model_id: string) => {
    history.push(ROUTES.DIRECTORY.CARS.MODEL.ID.createPath(brand_id, model_id));
  };

  return (
      <Row justify="space-between">
        <Col span={4}>Модель: </Col>
        <Col span={19}>
          {!carBrandModels ? <Spin /> : 
          <Select
            showSearch
            value={+model_id}
            placeholder="Выберите модель"
            loading={isLoading}
            style={{ width: '100%' }}
            optionFilterProp="children"
            fieldNames={{ label: 'name', value: 'id' }}
            filterOption={(input, option) =>
              (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => {
              setTab(e.toString())
              ROUTES.DIRECTORY.CARS.MODEL.ID.createPath(
                brand_id,
                e.toString()
              )
            }}
            options={carBrandModels}
          />}
          
        </Col>

      </Row>
  );
};
