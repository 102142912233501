import React from 'react'
import { Button, Tag } from 'antd'

import { APITYPES } from 'types/apitypes'


export type ModalType = {
  assign: boolean
  change: boolean
  reserve: APITYPES.OfferReserve | null
}

export type OpenFcProps = {
  modal: 'change' | 'assign'
  reserve: APITYPES.OfferReserve
}

const { OfferDoerStatusesTrans, OfferDoerStatusesColors } = APITYPES


export const getColumns = (openAssignModal?: (props: OpenFcProps) => void) => {
  const openModal = openAssignModal ? openAssignModal : () => {
  }

  return [
    {
      title: 'Исполнитель',
      dataIndex: 'doer_name',
      key: 'doer_name',
    },
    {
      title: 'Статус',
      dataIndex: 'state_name',
      key: 'state_name',
      align: 'center',
      width: 145,
      render: (state_name: string) =>
        //@ts-ignore
        <Tag color={OfferDoerStatusesColors[state_name]}>{ OfferDoerStatusesTrans[state_name] }</Tag>,
    },
    {
      title: 'Место',
      dataIndex: 'shift_location_name',
      key: 'shift_location_name',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 290,
      render: (record: APITYPES.OfferReserve) => {
        return (
          <>
            {
              record.state_name !== 'overbooked' && (
                <>
                  <Button
                    type='link'
                    onClick={
                      () => openModal({
                        modal: 'assign',
                        reserve: record,
                      })
                    }
                  >
                    Назначить на смену
                  </Button>
                  <Button
                    type='link'
                    onClick={
                      () => openModal({
                        modal: 'change',
                        reserve: record,
                      })
                    }
                  >
                    Изменить
                  </Button>
                </>
              )
            }
          </>
        )
      },
    },
  ]
}


export const getRows = (reserves: APITYPES.OfferReserve[]) => {
  return reserves.map((res) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'action') {
        row[column.dataIndex] = res
      } else {
        //@ts-ignore
        row[column.dataIndex] = res[column.dataIndex]
      }
    })
    row.key = res.id
    row.reserve = res

    return row
  })
}