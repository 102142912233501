import React, { useEffect } from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { StatusRequest } from 'stores/requestsReducer'
import { addCarClassCar } from 'AC/directory/directory'
import { fetchModels } from 'AC/cars'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
  brands: APITYPES.Cars.GetBrands.Resp
}

const formItemLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  models: state.carsReducer.models,
  isModelsLoading: state.requestsReducer.fetchModels === StatusRequest.LOADING,
})

export const AddForm = (props: Props) => {
  const { brands, isVisible, onCancel } = props
  const { models, isModelsLoading } = useSelector(selector)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {

    if (!values.model) { return }

    dispatch(addCarClassCar(values.model))
    onCancel()
  }


  const onChange = (name: string) => {
    form.resetFields()
    const brand = brands.find((item) => item.name === name)

    if (!brand) { return }
    form.setFieldsValue({ brand: brand.name })
    dispatch(fetchModels(String(brand.id)))
  }

  const afterClose = () => {
    form.resetFields()
    onCancel()
  }

  const isModelFieldDisabled = () => {
    return !form.getFieldValue('brand')
  }



  return (
    <Modal
      title='Добавление автомобиля'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      forceRender
      destroyOnClose
      afterClose={afterClose}
    >
      <Form
        name='car_classes'
        onFinish={onFinish}
        form={form}
        {...formItemLayout}
      >
        <Form.Item
          name='brand'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          label='Марка'
        >
          <Select
            showSearch
            onChange={onChange}
          >
            {
              brands.map((brand) => {
                return (
                  <Select.Option
                    key={brand.id}
                    value={brand.name}
                  >
                    {brand.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='model'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          label='Модель'
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={isModelFieldDisabled()}
            loading={isModelsLoading}
          >
            {
              models.map((model) => {
                return (
                  <Select.Option
                    key={model.id}
                    value={model.id}
                  >
                    {model.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Добавить'
          position='right'
        />
      </Form>
    </Modal>
  )
}