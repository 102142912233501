import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'


export const fetchUserSubscriptions = (user_id: string, filter?: APITYPES.Users.GetSubscriptions.Query): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchUserSubscriptions', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Users.GetSubscriptions.URL, {params: {user_id, ...filter}})

      if (resp) {
        dispatch(action(Actions.SET_USER_SUBSCRIPTIONS, resp))

        dispatch(changeRequestState('fetchUserSubscriptions', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchUserSubscriptions', StatusRequest.ERROR))

      throw(e)
    }
  }
}