export type ScrollTypeProps = {
  extraHeight?: number
  cn: string
}

export function getTableYScroll(extraHeight: number) {
  const height = `calc(100vh - ${extraHeight}px)`

  return height
}

export const vhToPx = (vh: number = 100) => {
  return Math.round(window.innerHeight / (100 / vh))
}