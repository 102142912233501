import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Input, Popconfirm, Space, Tooltip, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'

import { required } from 'utils/formUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { connectDoerToUrentExecutor, deleteUrentExecutor, getUrentExecutors } from 'AC/urent/executors'

import styles from './styles.module.sass'


type Props = {
  doer: APITYPES.Doer
};
const { Search } = Input


const selector = (state: State) => ({ executor: state.urentReducer.executors })

export const Urent = ({ doer }: Props) => {
  const { executor } = useSelector(selector)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [executorID, setExecutorID] = useState<string[]>([''])
  const [query, setQuery] = useState('')

  const filteredItems = executorID.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  )

  useEffect(() => {
    dispatch(getUrentExecutors(doer.id))
  }, [])

  useEffect(() => {
    if (executor) {
      const ids = executor.map((item) => item.urent_executor_id)

      setExecutorID(ids)
    }
  }, [executor])

  const onFinish = (value: any) => {
    const { id } = value

    dispatch(connectDoerToUrentExecutor(id,  doer.id, onSuccessConnection))
  }

  const onSuccessConnection = () => {
    form.resetFields()
  }

  const handleDeleteExecutor = (id: string) => {
    dispatch(deleteUrentExecutor(id, doer.id))
  }

  return (
    <div className={styles.container}>
      <Card
        title='UrentID'
        size='small'
        style={{ width: '48%' }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          style={
            {
              display: 'flex',
              justifyContent: 'space-between',
              gap: '15px',
            }
          }
        >
          <Form.Item
            label='ID'
            name='id'
            rules={required}
            style={{ width: '80%' }}
          >
            <Input placeholder='Введите ID исполнителя' />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
            >
              Связать
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card
        title='Связанные ID'
        size='small'
        style={{ width: '50%' }}
      >

        <Search
          allowClear
          onChange={(e) => setQuery(e.target.value)}
          placeholder='Поиск по ID исполнителя'
          enterButton
          style={{ marginBottom: '16px' }}
        />
        <div className={styles.list}>
          {
            filteredItems?.map((item, index) => {
              return (
                <div className={styles.container}>
                  <Typography key={index}>{ item }</Typography>
                  <Popconfirm
                    title='Вы точно хотите удалить?'
                    onConfirm={() => handleDeleteExecutor(item)}
                    placement='topLeft'
                  >
                    <Tooltip overlay='Удалить'>
                      <Button
                        size='small'
                        danger
                        type='primary'
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </div>
              )
            })
          }

        </div>
      </Card>
    </div>
  )
}
