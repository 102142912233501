import { Reducer } from 'redux'

import { Action, Actions } from '../actions'
import { APITYPES } from '../types/apitypes'


export type DoerRecordsFilterTab = {
  id: string
  name: string
}

export type DoerRecordsFilterField = {
  value: string | null
  disabled: boolean
}

export type DoerRecordsFilter = {
  period: DoerRecordsFilterField
  dates: DoerRecordsFilterField
  state: DoerRecordsFilterField
  department_name: DoerRecordsFilterField
  role_group_name: DoerRecordsFilterField
  role_id: DoerRecordsFilterField
  contractor_id: DoerRecordsFilterField
  city_id: DoerRecordsFilterField
  type: DoerRecordsFilterField
  employer_name: DoerRecordsFilterField
  signer: DoerRecordsFilterField
}
const DEFAULT_TAB_KEY = 'table'

export type DoerRecordsState = {
  activeTabId: string
  doerRecordTypes: string[]
  filterTabs: DoerRecordsFilterTab[]
  filtersMap: Record<string, DoerRecordsFilter>
  doerRecordsMap: Record<string, {doerRecords: APITYPES.DoerRecords[], recordsHasMore: boolean}>
}

export const initialDoerRecordsState: DoerRecordsState = {
  activeTabId: DEFAULT_TAB_KEY,
  doerRecordTypes: [],
  filterTabs: [],
  filtersMap: {},
  doerRecordsMap: {},
}

export const doerRecordsReducer: Reducer<DoerRecordsState, Action> = (state = initialDoerRecordsState, a): DoerRecordsState => {
  switch (a.type) {
    case Actions.ADD_DOER_RECORD_FILTER_TAB: {
      const id = Date.now().toString()
      const newFilterTab: DoerRecordsFilterTab = {
        id: id,
        name: a.data.title,
      }

      return {
        ...state,
        filterTabs: [...state.filterTabs, newFilterTab],
        activeTabId: id,
        filtersMap: {
          ...state.filtersMap,
          [id]: a.data.filter,
        },
      }
    }
    case Actions.DELETE_DOER_RECORD_FILTER_TAB: {
      const newFilterTabs = state.filterTabs.filter((filterTab) => filterTab.id !== a.data.id)

      return {
        ...state,
        filterTabs: newFilterTabs,
      }
    }
    case Actions.SET_ACTIVE_DOER_RECORDS_TAB: {
      return {
        ...state,
        activeTabId: a.data.id,
      }
    }

    case Actions.SET_DOER_RECORDS_FILTER: {
      return {
        ...state,
        filtersMap: {
          ...state.filtersMap,
          [a.data.id]: a.data.filter,
        },
      }
    }

    case Actions.SET_DOER_RECORDS:
      return {
        ...state,
        doerRecordsMap: {
          ...state.doerRecordsMap,
          [a.data.activeTab]: {
            doerRecords: a.data.offset === 0
              ? a.data.doerRecords
              : [...state.doerRecordsMap[a.data.activeTab].doerRecords, ...a.data.doerRecords],
            recordsHasMore: !!a.data.doerRecords?.length,
          },
        },
      }

    case Actions.RESET_DOER_RECORDS:
      return {
        ...state,
        doerRecordsMap: {
          ...state.doerRecordsMap,
          [state.activeTabId]: {
            doerRecords: [],
            recordsHasMore: true,
          },
        },
      }

    case Actions.SET_DOER_RECORD_TYPES: {
      return {
        ...state,
        doerRecordTypes: a.data,
      }
    }
    default:
      return state
  }
}