import { getEntries } from 'utils/enumUtils'


export enum Repeat {
  daily = 'ежедневно',
  weekly = 'еженедельно',
  monthly = 'ежемесячно',
  yearly = 'ежегодно',
  shiftly = 'ежесменно'
}

export const getEnumRepeatOptions = (enumArg: any) => {
  const keys = getEntries(enumArg)
  const options = keys.map((item: any) => ({
    label: item[1],
    value: item[0],
  }))

  return options
}