import { Action, Actions } from '../actions'
import { APITYPES } from '../types/apitypes'


type Internship = APITYPES.Internships.Internship;
export type InternshipsFilters = {
  understaffed?: string
  search?: string
  roleId?: string
  dateFrom?: string
  dateTo?: string
  cityId?: number
  locationId?: number
  page?: number
  limit?: number
}

export type InternshipsState = {
  hasInDoers: boolean
  filters?: InternshipsFilters
  internships: Internship[]
  totalCount?: number
}
const initialState: InternshipsState = {
  hasInDoers: false,
  totalCount: 0,
  internships: [],
  filters: {
    page: 1,
    limit: APITYPES.Internships.defaultLimit,
  },
}

export const internshipsReducer = (state = initialState, action: Action): InternshipsState => {
  switch (action.type) {
    case Actions.SET_INTERNSHIPS: {
      return {
        ...state,
        internships: action.data.internships,
        totalCount: action.data.total,
      }
    }

    case Actions.SET_INTERNSHIPS_FILTER: {
      return {
        ...state,
        filters: action.data,
      }
    }

    case Actions.SET_INTERNSHIPS_DOER_SEARCH: {
      return {
        ...state,
        hasInDoers: action.data,
      }
    }

    case Actions.CHANGE_INTERNSHIP_DOERS_STATE: {
      return {
        ...state,
        internships: state.internships.map((internship) => {
          if (internship.id === action.data.internshipId) {
            return {
              ...internship,
              doers: internship.doers.map((doer) => ({
                ...doer,
                doer_shift_state: action.data.doerShiftId === doer.doer_shift_id ? action.data.status : doer.doer_shift_state,
              })),
            }
          }

          return internship
        }),
      }
    }

    case Actions.CANCEL_INTERNSHIPS: {
      return {
        ...state,
        internships: state.internships.filter((internship) => internship.id !== action.data.id),
      }
    }
    default: return state
  }
}