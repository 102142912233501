// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--64RMx{margin-bottom:16px}.styles-module--toolbar--Ss0yR{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px;gap:12px}.styles-module--table---Shya{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Monitoring/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,kBAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CACA,QAAA,CAEF,6BACE,cAAA","sourcesContent":[".container\n  margin-bottom: 16px\n\n.toolbar\n  display: flex\n  width: 100%\n  align-items: center\n  margin-bottom: 16px\n  gap: 12px\n\n.table\n  cursor: pointer\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--64RMx",
	"toolbar": "styles-module--toolbar--Ss0yR",
	"table": "styles-module--table---Shya"
};
export default ___CSS_LOADER_EXPORT___;
