import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { fetchCompensations, fetchCompensationTypes } from 'AC/carwashes/carwashes'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'

import { getColumns, getRows } from './uitls'
import styles from './styles.module.sass'
import { NewCompensationModal } from './NewCompensationModal'


const selector = (state: State) => {
  const { carwashCompensations, compensationTypes, compensationFilter } = state.carwashesReducer
  const carwash_id = state.carwashesReducer.carwash?.id
  const isLoading = state.requestsReducer.fetchPenalties === StatusRequest.LOADING

  return {
    compensations: carwashCompensations,
    carwash_id,
    compensationFilter,
    compensationTypes,
    isLoading,
  }
}

export const Compensation = () => {
  const dispatch = useDispatch()
  const {  compensations, carwash_id, compensationTypes, isLoading, compensationFilter } = useSelector(selector)
  const [isNewCompensationModalOpen, setNewCompensationModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (carwash_id) {
      dispatch(fetchCompensationTypes())
      dispatch(fetchCompensations({ carwash_id }, compensationFilter))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])


  const onTableChange = (_: any, filters: any) => {
    const newFilter = {
      begin: filters.date ? filters.date[0].format('YYYY-MM-DD') : undefined,
      end: filters.date ? filters.date[1].format('YYYY-MM-DD') : undefined,
    }

    dispatch(action(Actions.CLEAN_COMPENSATIONS, undefined))
    dispatch(action(Actions.SET_COMPENSATIONS_FILTER, newFilter))
    dispatch(fetchCompensations({ carwash_id: carwash_id ?? '' }, newFilter))
  }

  return (
    <div className={styles.main}>
      <Button
        size='middle'
        type='primary'
        className={styles.newPenaltyButton}
        onClick={() => setNewCompensationModalOpen(true)}
      >
        Добавить
      </Button>

      <div className={styles.tableContainer}>
        <Table
          sticky
          columns={getColumns(compensationFilter) as any}
          dataSource={getRows(compensations)}
          bordered
          size='large'
          pagination={false}
          scroll={
            {
              x: '100%',
              y: getTableYScroll(333),
            }
          }
          onChange={onTableChange}
          loading={isLoading}
        />
      </div>

      {
        carwash_id && (
          <NewCompensationModal
            carwash_id={carwash_id}
            isVisible={isNewCompensationModalOpen}
            onCancel={() => setNewCompensationModalOpen(false)}
            compensationTypes={compensationTypes}
          />
        )
      }

    </div>
  )
}