import moment from 'moment'

import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'
import {
  MailingItem,
  MailingStateName,
} from 'types'

import { getColumns } from './getColumns'


export const statusNamesMap: Record<MailingStateName, string> = {
  sent: 'Доставлено',
  awaiting_sending: 'Ожидает отправки',
  canceled: 'Отменено',
  active: 'Активная',
  inactive: 'Не активная',
}

export const getRows = (mails: MailingItem[]) => {
  const timezone = useGetTimezoneFromLocalstorage()

  return mails.map((mail) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state_name') {
        const status = mail.state_name

        row[column.dataIndex] = statusNamesMap[status]
      } else if (column.dataIndex === 'created') {
        const date = moment(mail[column.dataIndex]).format('DD.MM.YYYY')
        const time = moment(mail[column.dataIndex]).format('HH:mm:ss Z')

        const shift = formatDateTime({
          start: time,
          targetTimezone: timezone,
        })

        row[column.dataIndex] = `${date}, ${shift}`
      } else if (column.dataIndex === 'start_time') {
        const date = moment(mail[column.dataIndex]).format('DD.MM.YYYY')
        const time = moment(mail[column.dataIndex]).format('HH:mm:ss Z')

        const shift = formatDateTime({
          start: time,
          targetTimezone: timezone,
        })

        row[column.dataIndex] = `${date}, ${shift}`
      } else {
        //@ts-ignore
        row[column.dataIndex] = mail[column.dataIndex]
      }
    })
    row.key = mail.id
    row.mail = mail

    return row
  })
}