import React from 'react'

import { APITYPES } from 'types/apitypes'

import { Status } from './Status'


export const getColorByStatus = (status: string) => {
  if (status === 'new') {return 'blue'}

  if (status === 'active') {return 'green'}

  if (status === 'disconnected') {return 'gray'}

  return 'blue'
}

const columns = [
  {
    title: 'Название',
    dataIndex: 'legal_name',
    key: 'legal_name',
    fixed: 'left',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 160,
    render: (value: APITYPES.Partner) => (
      <Status
        value={value}
        key={value.id}
      />
    ),
  },
  {
    title: 'Юридический адрес',
    dataIndex: 'legal_address',
    key: 'legal_address',
  },
  {
    title: 'ИНН',
    dataIndex: 'inn',
    key: 'inn',
    width: 200,
  },
]

export const getColumns = () => columns

export const getRows = (partners: APITYPES.Partners.Get.Resp) => {
  const data = partners.map((partner) => {
    const row: any = {}

    columns.map((column) => {
      if (column.dataIndex === 'status') { return }
      //@ts-ignore
      row[column.dataIndex] = partner[column.dataIndex]
      row.key = partner.id

      return
    })

    row.status = partner

    return row
  })

  return data
}