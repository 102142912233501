import React, { FC } from 'react'
import { Button, Dropdown, MenuProps, Popconfirm } from 'antd'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import { PlacementToUpdate } from 'stores/yandexProReducer'


interface ConnectProps {
  placement: PlacementToUpdate
  onDelete: () => void
  onSubmit:  () => void
}
export const Connect:FC<ConnectProps> = ({ placement, onDelete, onSubmit }) => {
  const items: MenuProps['items'] = [
    {
      label: (
        <Popconfirm
          placement='top'
          title='Вы действительно хотите удалить локацию?'
          onConfirm={onDelete}
          okText='Да'
          okType='danger'
          cancelText='Нет'
        >
          Удалить
        </Popconfirm>
      ),
      key: 'Удалить',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ]

  const menuProps = { items }

  return (
    <div
      style={
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent:'center',
        }
      }
    >
      <Button
        type='primary'
        disabled={!placement.location_id || placement.isMatched}
        onClick={onSubmit}
        style={
          {
            width:'fit-content',
            padding: '0 4px',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }
        }
      >
        { placement.isMatched ? 'Связано' : 'Связать' }
      </Button>
      <Dropdown
        menu={menuProps}
        trigger={['click']}
        placement='bottomRight'
      >
        <Button
          type='primary'
          style={
            {
              width:'fit-content',
              padding: '0 4px',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }
          }
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
    </div>
  )
}
