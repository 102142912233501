import { Button, Table } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { getContractorRecords } from 'AC/billing/getContractorRecords'
import { exportCSV } from 'utils/fileUtils'

import { UpdateModal } from './UpdateModal'
import { CreateModal } from './CreateModal'
import { getColumns, getRows, ItemsByGroupsType, parseBillItemsByGroups } from './utils'
import styles from './styles.module.sass'


type Props = {
  bill: APITYPES.Bill
  className?: string
}

export const Positions = memo(({ bill, className }: Props) => {
  const dispatch = useDispatch()
  const [item, setItem] = useState<APITYPES.BillItem | null>(null)
  const [itemsByGroup, groupRowTitles] = parseBillItemsByGroups(bill.items || [])
  
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    dispatch(getContractorRecords({ is_vacant: true }))
  }, [dispatch])

  const onCreate = () => setIsCreating(true)

  return (
    <div className={className}>
      <Table
        bordered
        columns={getColumns() as any}
        dataSource={groupRowTitles as { groupTitle: string, length: number }[]}
        pagination={false}
        expandable={
          {
            expandedRowRender: (group) => {
              return (
                <Table
                  bordered
                  showHeader={false}
                  columns={getColumns() as any}
                  className={styles.table}
                  // @ts-ignore
                  dataSource={getRows((itemsByGroup as ItemsByGroupsType)[group.key])}
                  size='small'
                  pagination={false}
                  onRow={(record) => ({ onClick: () => setItem(record.data) })}
                />
              )
            },
          }
        }
      />

      <div className={styles.actions}>
        <Button
          className={styles.addBtn}
          type='primary'
          onClick={onCreate}
        >
          Добавить позицию
        </Button>

        {
          bill.items && (
            <Button
              className={styles.addBtn}
              type='default'
              icon={<DownloadOutlined />}
              onClick={() => exportCSV(bill.name, bill.items)}
            >
              Экспорт CSV
            </Button>
          )
        }
      </div>

      {
        item && (
          <UpdateModal
            isVisible={!!item}
            billItem={item}
            onCancel={() => setItem(null)}
          />
        )
      }

      <CreateModal
        isVisible={isCreating}
        onCancel={() => setIsCreating(false)}
      />
    </div>
  )
})
