import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from 'actions'


export type OffersState = {
  offers: APITYPES.Offer[]
  offer: APITYPES.Offer | null
  offersFilter: APITYPES.Offers.GET.Query
  hasMoreOffers: boolean
  offerReserves: APITYPES.OfferReserve[] | null
  reserveOffers: APITYPES.Offer[]
}

export const initialOffersState = {
  offers: [],
  offer: null,
  hasMoreOffers: true,
  offerReserves: null,
  offersFilter: {
    understaffed: undefined,
    date_from: undefined,
    date_to: undefined,
    doer_id: undefined,
    role_id: undefined,
    shift_type_id: undefined,
    location_id: undefined,
    date: undefined,
    limit: 20,
    offset: 0,
  },
  reserveOffers: [],
}

export const offersReducer: Reducer<OffersState, Action> = (
  state = initialOffersState,
  a
): OffersState => {
  switch (a.type) {
    case Actions.SET_RESERVE_OFFERS: {
      return {
        ...state,
        reserveOffers: a.data,
      }
    }

    case Actions.SET_OFFER:
      return {
        ...state,
        offer: a.data,
      }

    case Actions.SET_OFFERS_RESERVES:
      return {
        ...state,
        offerReserves: a.data,
      }

    case Actions.SET_OFFERS_FILTER:
      return {
        ...state,
        offersFilter: {
          ...state.offersFilter,
          ...a.data,
        },
      }

    case Actions.CLEAR_OFFERS:
      return {
        ...state,
        offers: [],
      }

    case Actions.SET_OFFERS:
      return {
        ...state,
        offers: [...state.offers, ...a.data.offers],
        hasMoreOffers: a.data.hasMoreOffers,
      }
  }

  return state
}