// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--divider--P69II{font-size:14px !important;margin:0 0 8px 0 !important}.styles-module--addonAfter--x6saQ{width:55px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/AddProductModal/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,yBAAA,CACA,2BAAA,CAEF,kCACE,UAAA","sourcesContent":[".divider\n  font-size: 14px !important\n  margin: 0 0 8px 0 !important\n\n.addonAfter\n  width: 55px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "styles-module--divider--P69II",
	"addonAfter": "styles-module--addonAfter--x6saQ"
};
export default ___CSS_LOADER_EXPORT___;
