// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".internships-module--wrapper--PRnjy{height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.internships-module--group--r1k4p{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.internships-module--content--gvZM3{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.internships-module--title--oJYYY{font-size:24px;margin:0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/internships/internships.module.sass"],"names":[],"mappings":"AAAA,oCACE,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEF,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEF,oCACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CAEF,kCACE,cAAA,CACA,QAAA","sourcesContent":[".wrapper\n  height: 100%\n  display: flex\n  flex-direction: column\n\n.group\n  display: flex\n  justify-content: space-between\n  align-items: center\n\n.content\n  flex-grow: 1\n\n.title\n  font-size: 24px\n  margin: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "internships-module--wrapper--PRnjy",
	"group": "internships-module--group--r1k4p",
	"content": "internships-module--content--gvZM3",
	"title": "internships-module--title--oJYYY"
};
export default ___CSS_LOADER_EXPORT___;
