import { APITYPES } from 'types/apitypes'

import { sumValues } from './sumValues'
import { getGroupRows } from './getGroupRows'


export type ItemsByGroupsType = {
  [key: string]: APITYPES.BillItem[]
}

// TODO: needs refactoring
export const parseBillItemsByGroups = (items: APITYPES.BillItem[]) => {
  const itemsByGroups: ItemsByGroupsType = { others: [] }

  for (let i = 0; i < items.length; i++) {
    const item = items[i]


    if (!item.group) {
      itemsByGroups.others.push(item)
    } else if (itemsByGroups[item.group]) {
      itemsByGroups[item.group].push(item)
    } else {
      itemsByGroups[item.group] = []
      itemsByGroups[item.group].push(item)
    }
  }

  // get keys
  const alphabeticallySortedKeys = Object.keys(itemsByGroups).sort((a: string, b: string) => a < b ? -1 : 1)
  
  // put others to the end
  if (itemsByGroups.others.length) {
    alphabeticallySortedKeys.push(alphabeticallySortedKeys.shift() as string)
  } else {
    alphabeticallySortedKeys.shift()
    delete itemsByGroups.others
  }
  
  const groupTitles = alphabeticallySortedKeys.map((item) => ({
    groupTitle: item,
    length: itemsByGroups[item]?.length,
  }))
  const groupTotal = alphabeticallySortedKeys.map((item) => ({
    title: item,
    total: sumValues(itemsByGroups[item]),
  }))
  
  return [itemsByGroups, getGroupRows(groupTitles, groupTotal)]
}
