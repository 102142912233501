import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from 'actions'


export type ShiftsState = {
  shiftTypes: APITYPES.ShiftType[]
  shifts: APITYPES.Shift[]
  shiftDoers: APITYPES.ShiftDoer[]
  shiftDoersFilter: APITYPES.ShiftDoersFilter
  shiftTypesByRole: APITYPES.ShiftTypesByRoles[]
  shiftTypesByRoleId: APITYPES.ShiftTypesByRolesId[]
  shiftTypesByRoleFilter: [string] | null
  shiftTypesByRoleIdFilter: [string] | null
  shiftsFilter: APITYPES.ShiftFilter
  shiftTabs: APITYPES.Shift[]
  shiftOffers: APITYPES.ShiftOffer[]
  shiftsForRedirect: APITYPES.Shift[]
  shiftOffersReverse: APITYPES.OfferReserve[]

  employers: APITYPES.Shifts.Employer.GET.Resp
  employer: APITYPES.Employer | null

  allLocations: APITYPES.Shifts.ShiftLocations.GET.Resp
  locations: APITYPES.Shifts.ShiftLocations.GET.Resp
  locationsFilter: APITYPES.Shifts.ShiftLocations.GET.Params
  locationChildren: APITYPES.Location[] | null
  locationPhotos: APITYPES.LocationFile[]
}

export const initialShiftsState = {
  shiftTypes: [],
  shifts: [],
  shiftDoers: [],
  shiftDoersFilter: { state_name: undefined },
  shiftTypesByRole: [],
  shiftTypesByRoleId: [],
  shiftTypesByRoleFilter: null,
  shiftTypesByRoleIdFilter: null,
  shiftsFilter: {
    city_id: undefined,
    shift_type_id: undefined,
    location_id: undefined,
    role_id: undefined,
    date_from: undefined,
    date_to: undefined,
    employer_id: undefined,
  },
  shiftTabs: [],
  shiftOffers: [],
  shiftsForRedirect: [],
  shiftOffersReverse: [],

  employers: [],
  employer: null,

  locations: [],
  allLocations: [],
  locationsFilter: {
    search: undefined,
    city_id: undefined,
    type: undefined,
  },
  locationChildren: null,
  locationPhotos: [],
}

export const shiftsReducer: Reducer<ShiftsState, Action> = (
  state = initialShiftsState,
  a
): ShiftsState => {
  switch (a.type) {
    case Actions.SET_STATIC_SHIFT_LOCATIONS: {
      return {
        ...state,
        allLocations: a.data,
      }
    }

    case Actions.ADD_STATIC_SHIFT_LOCATIONS: {
      return {
        ...state,
        allLocations: [...state.allLocations, ...a.data],
      }
    }


    case Actions.SET_LOCATION_CHILDREN: {
      return {
        ...state,
        locationChildren: a.data,
      }
    }

    case Actions.SET_LOCATIONS_FILTER: {
      return {
        ...state,
        locationsFilter: a.data,
      }
    }

    case Actions.SET_SHIFTS_FOR_REDIRECT: {
      return {
        ...state,
        shiftsForRedirect: a.data,
      }
    }

    case Actions.SET_SHIFT_OFFERS: {
      return {
        ...state,
        shiftOffers: a.data,
      }
    }

    case Actions.SET_SHIFT_OFFERS_REVERSE: {
      return {
        ...state,
        shiftOffersReverse: a.data,
      }
    }

    case Actions.SET_SHIFT_DOERS_FILTER: {
      return {
        ...state,
        shiftDoersFilter: a.data,
      }
    }

    case Actions.REMOVE_SHIFT_TAB:
      return {
        ...state,
        shiftTabs: state.shiftTabs.filter((shift) => shift.id !== a.data.shift_id),
      }

    case Actions.ADD_SHIFT_TAB:
      return {
        ...state,
        shiftTabs: [...state.shiftTabs, a.data],
      }

    case Actions.DELETE_SHIFT:
      return {
        ...state,
        shifts: state.shifts.filter((shift) => shift.id !== a.data.shift_id),
      }

    case Actions.CLEAR_SHIFT_DOERS: {
      return {
        ...state,
        shiftDoers: [],
      }
    }

    case Actions.SET_SHIFT_DOERS:
      return {
        ...state,
        shiftDoers: a.data,
      }

    case Actions.SET_SHIFTS_FILTER:
      return {
        ...state,
        shiftsFilter: {
          city_id: a.data.city_id,
          shift_type_id: a.data.shift_type_id,
          location_id: a.data.location_id,
          role_id: a.data.role_id,
          date_from: a.data.date_from,
          date_to: a.data.date_to,
          employer_id: a.data.employer_id,
        },
      }

    case Actions.SET_SHIFT_TYPES_BY_ROLE_FILTER:
      return {
        ...state,
        shiftTypesByRoleFilter: [a.data],
      }

    case Actions.SET_SHIFT_TYPES_BY_ROLEID_FILTER:
      return {
        ...state,
        shiftTypesByRoleFilter: [a.data],
      }

    case Actions.SET_SHIFT_TYPES_BY_ROLEID:
      return {
        ...state,
        shiftTypesByRoleId: a.data,
      }

    case Actions.SET_SHIFT_TYPES_BY_ROLE:
      return {
        ...state,
        shiftTypesByRole: a.data,
      }

    case Actions.SET_SHIFT_TYPES:
      return {
        ...state,
        shiftTypes: a.data,
      }

    case Actions.SET_SHIFTS:
      return {
        ...state,
        shifts: a.data,
      }

    case Actions.SET_SHIFT_LOCATIONS:
      return {
        ...state,
        locations: a.data,
      }

    case Actions.SET_EMPLOYERS:
      return {
        ...state,
        employers: a.data,
      }

    case Actions.SET_EMPLOYER:
      return {
        ...state,
        employer: a.data,
      }

    case Actions.ADD_SHIFT_LOCATION:
      return {
        ...state,
        locations: [a.data, ...state.locations],
      }

    case Actions.SET_LOCATION_PHOTOS:
      return {
        ...state,
        locationPhotos: a.data,
      }
  }

  return state
}