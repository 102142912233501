export const required = [{
  required: true,
  message: 'Поле должно быть заполнено',
}]

export const requiredNumber =  [{
  message: 'Поле должно состоять из чисел',
  pattern: /^\d+$/,
}]

export const requiredNoMsg = [{
  required: true,
  message: '',
}]

export const searchSelect = (input: string, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const strToNumber = (value: any, prevValue: any, prevValues: any) => {
  return value ? +value : null
}

export const fullWidth = { width: '100%' }