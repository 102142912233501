import { ThAction } from '../../actions'
import { changeRequestState } from '../request'
import { StatusRequest } from '../../stores/requestsReducer'
import { APITYPES } from '../../types/apitypes'
import { successNotification } from '../../components/modals/OperationNotification'
import { showError } from '../../components/modals/ErrorModal'

import { getDoerById } from './getDoers'


export const doerClearWallet = (contractor_id: string, onFinish: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('doerClearWallet', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Doers.PutContractorsClearWallet.URL, {}, { replaceUrl: { contractor_id } })

      if (resp) {
        successNotification('Кошелек исполнителя успешно обнулен')
        onFinish()
        dispatch(getDoerById(contractor_id))
        dispatch(changeRequestState('doerClearWallet', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('doerClearWallet', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}