// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--2UfXC{overflow-y:scroll}.styles-module--whileLoading--C35Yc{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto}", "",{"version":3,"sources":["webpack://./src/components/InfiniteScroll/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,iBAAA,CAEF,oCACE,kBAAA,CAAA,iBAAA,CAAA,aAAA","sourcesContent":[".container \n  overflow-y: scroll\n\n.whileLoading\n  flex: 1 1 auto"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--2UfXC",
	"whileLoading": "styles-module--whileLoading--C35Yc"
};
export default ___CSS_LOADER_EXPORT___;
