// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--3j0qS{height:100%;width:60%}.styles-module--modalFooter--6Mh4X{margin-top:16px}.styles-module--hider--DPPhL{position:absolute;left:10px;top:12px;background-color:#fff;width:15px;height:15px}.styles-module--transfer--j7gU8>:nth-child(1)>:first-child>:first-child,.styles-module--transfer--j7gU8>:nth-child(3)>:first-child>:first-child{display:none}.styles-module--listTitle--zL7a2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start}.styles-module--loader--GMaZL{position:absolute;top:0;bottom:0;right:0;left:0;background-color:#fff;opacity:.55}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Doers/Doer/Information/DoerRoles/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,SAAA,CAEF,mCACE,eAAA,CAEF,6BACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CAGA,gJAEE,YAAA,CAEJ,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,qBAAA,CAEF,8BACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,OAAA,CACA,MAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":[".container\n  height: 100%\n  width: 60%\n\n.modalFooter\n  margin-top: 16px\n\n.hider\n  position: absolute\n  left: 10px\n  top: 12px\n  background-color: #FFF\n  width: 15px\n  height: 15px\n\n.transfer\n  & > :nth-child(1) > :first-child > :first-child,\n  & > :nth-child(3) > :first-child > :first-child\n    display: none\n\n.listTitle\n  display: flex\n  justify-content: start\n\n.loader\n  position: absolute\n  top: 0\n  bottom: 0\n  right: 0\n  left: 0\n  background-color: #fff\n  opacity: 0.55"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--3j0qS",
	"modalFooter": "styles-module--modalFooter--6Mh4X",
	"hider": "styles-module--hider--DPPhL",
	"transfer": "styles-module--transfer--j7gU8",
	"listTitle": "styles-module--listTitle--zL7a2",
	"loader": "styles-module--loader--GMaZL"
};
export default ___CSS_LOADER_EXPORT___;
