import { Form, Modal, Input } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { deleteClientTypeCarClass, updateCarClass } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'
import { DeleteButton } from 'components/DeleteButton'
import { showSureConfirm } from 'components/modals/SureModal'


type Props = {
  isVisible: boolean
  onCancel: () => void
  carClass: APITYPES.CarClasses.Get.Resp[number] | null
}

export const EditCarClassForm = (props: Props) => {
  const { carClass, isVisible, onCancel } = props
  const { id } = useParams<{
    id: string
  }>()
  
  const dispatch = useDispatch()

  const onFinish = async (values: { position: number, car_class_name: string}) => {
    dispatch(updateCarClass(id, carClass!.id, values))

    onCancel()
  }

  const onDelete = () => {
    showSureConfirm(() => { dispatch(deleteClientTypeCarClass(id, carClass!.id)) }, () => { })
    onCancel()
  }

  return (
    <Modal
      title='Редактировать'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      destroyOnClose
    >
      <Form
        name='car_class'
        onFinish={onFinish}
      >
        <Form.Item
          name='car_class_name'
          initialValue={carClass?.name}
          label='Название'
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          name='position'
          initialValue={carClass?.position}
          label='Позиция'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Сохранить'
          leftElement={
            <DeleteButton
              onClick={onDelete}
            />
          }
        />
      </Form>
    </Modal>
  )
}