import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { errorNotification, openNotification, successNotification } from 'components/modals/OperationNotification'
import { showError } from 'components/modals/ErrorModal'
import { showInfoAndNavigateToTariff } from '../../App/PrivateRoutes/Doers/DoerTariffs/utils'
import { doerShiftStatusMapToUrl } from '../../App/PrivateRoutes/Doers/ShiftsPage/components/ShiftTab/ShiftTabDoers/utils'


export const fetchShiftTypes = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchShiftTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.ShiftTypes.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_SHIFT_TYPES, resp))

        dispatch(changeRequestState('fetchShiftTypes', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchShiftTypes', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addShiftType = (body: APITYPES.Shifts.ShiftTypes.POST.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addShiftType', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Shifts.ShiftTypes.POST.URL, body)

      if (resp) {
        dispatch(fetchShiftTypes())
        dispatch(changeRequestState('addShiftType', StatusRequest.LOADED))
        showInfoAndNavigateToTariff(body.city_id, body.roles[0].id)
      }
    } catch (e) {
      dispatch(changeRequestState('addShiftType', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchShifts = (filter?: APITYPES.ShiftFilter): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchShifts', StatusRequest.LOADING))

    const shiftsFilter = filter ? filter : getState().shiftsReducer.shiftsFilter

    try {
      const resp = await api.get(APITYPES.Shifts.Shift.GET.URL, { params: { ...shiftsFilter } })

      if (resp) {
        dispatch(action(Actions.SET_SHIFTS, resp))

        dispatch(changeRequestState('fetchShifts', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchShifts', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addShift = (body: APITYPES.Shifts.Shift.POST.ReqCreate): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addShift', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Shifts.Shift.POST.URL, body)

      if (resp) {
        dispatch(fetchShifts())
        dispatch(changeRequestState('addShift', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addShift', StatusRequest.ERROR))
      showError(e?.response?.data)
      throw(e)
    }
  }
}

export const importShift = (
  body: APITYPES.Shifts.Shift.POST.ReqImport,
  onSuccess: () => void
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('importShift', StatusRequest.LOADING))

    try {
      await api.post(APITYPES.Shifts.Shift.POST.URL, body)
      onSuccess()
      dispatch(changeRequestState('importShift', StatusRequest.LOADED))
    } catch (e) {
      dispatch(changeRequestState('importShift', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка при импорте')
    }
  }
}

export const updateShiftById = (shift_id: string, body: APITYPES.Shifts.ShiftById.PUT.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateShiftById', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Shifts.ShiftById.PUT.URL, body, { replaceUrl: { shift_id } })

      if (resp) {
        dispatch(changeRequestState('updateShiftById', StatusRequest.LOADED))
        openNotification('Смена успешно обновлена')
      }
    } catch (e) {
      dispatch(changeRequestState('updateShiftById', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addShiftTypeByRole =
  (shift_type_id: string, body: APITYPES.Shifts.ShiftTypesByRole.POST.Req): ThAction => {
    return async (dispatch, _, { api }) => {
      dispatch(changeRequestState('addShiftTypeByRole', StatusRequest.LOADING))

      try {
        const resp = await api.post(APITYPES.Shifts.ShiftTypesByRole.POST.URL, body, { replaceUrl: { shift_type_id } })

        if (resp) {
          dispatch(action(Actions.SET_SHIFT_TYPES_BY_ROLE_FILTER, shift_type_id))
          dispatch(changeRequestState('addShiftTypeByRole', StatusRequest.LOADED))
          successNotification('Успешно добавлен')
        }
      } catch (e) {
        dispatch(changeRequestState('addShiftTypeByRole', StatusRequest.ERROR))
        errorNotification('Ошибка при создании')
        throw(e)
      }
    }
  }

export const getShiftTypeByRoleId =
  (role_id: string): ThAction => {
    return async (dispatch, _, { api }) => {
      dispatch(changeRequestState('getShiftTypeByRoleId', StatusRequest.LOADING))

      try {
        const resp = await api.get(APITYPES.Shifts.ShiftTypesByRoleId.GET.URL, { replaceUrl: { role_id } })

        if (resp) {
          dispatch(action(Actions.SET_SHIFT_TYPES_BY_ROLEID, resp))
          dispatch(changeRequestState('getShiftTypeByRoleId', StatusRequest.LOADED))
        }
      } catch (e) {
        dispatch(changeRequestState('getShiftTypeByRoleId', StatusRequest.ERROR))
        throw(e)
      }
    }
  }

export const fetchEmployers = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchEmployers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.Employer.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_EMPLOYERS, resp))

        dispatch(changeRequestState('fetchEmployers', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchEmployers', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchEmployerById = (params: APITYPES.Shifts.Employer.GET.Query): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchEmployerById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.Employer.GET.URL, { params })

      if (resp?.length) {
        dispatch(action(Actions.SET_EMPLOYER, resp[0]))

        dispatch(changeRequestState('fetchEmployerById', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchEmployerById', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchLocations = (filters?: APITYPES.Shifts.ShiftLocations.GET.Params): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchLocations', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.GET.URL, { params: { ...filters } })

      if (resp) {
        dispatch(action(Actions.SET_SHIFT_LOCATIONS, resp))

        dispatch(changeRequestState('fetchLocations', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchLocations', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addShiftLocation = (body: APITYPES.Shifts.ShiftLocations.POST.Req, onSuccess?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addShiftLocation', StatusRequest.LOADING))

    const filter = getState().shiftsReducer.locationsFilter

    try {
      const resp = await api.post(APITYPES.Shifts.ShiftLocations.POST.URL, body)

      if (resp) {
        onSuccess?.()
        dispatch(fetchLocations(filter))

        dispatch(changeRequestState('addShiftLocation', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('addShiftLocation', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const editShiftLocation = (
  location_id: string,
  body: APITYPES.Shifts.ShiftLocations.PUT.Req,
  onSuccess?: () => void
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editShiftLocation', StatusRequest.LOADING))

    const filter = getState().shiftsReducer.locationsFilter

    try {
      const resp = await api.put(APITYPES.Shifts.ShiftLocations.PUT.URL, body, { replaceUrl: { location_id } })

      if (resp) {
        onSuccess?.()
        dispatch(fetchLocations(filter))

        dispatch(changeRequestState('editShiftLocation', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('editShiftLocation', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchShiftDoers = (shift_id: string, filter?: APITYPES.ShiftDoersFilter): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchShiftDoers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.Shift.GetDoers.URL, {
        replaceUrl: { shift_id },
        params: { state_name: filter ? filter.state_name : undefined },
      })

      if (resp) {
        dispatch(action(Actions.SET_SHIFT_DOERS, resp))
        dispatch(changeRequestState('fetchShiftDoers', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchShiftDoers', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const deleteShift = (shift_id: string, onSuccess?: any): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteShift', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Shifts.ShiftById.DELETE.URL, { replaceUrl: { shift_id } })

      if (resp) {
        if (onSuccess) {
          onSuccess(shift_id)
        }

        dispatch(action(Actions.DELETE_SHIFT, { shift_id }))
        dispatch(action(Actions.REMOVE_SHIFT_TAB, { shift_id }))
        openNotification('Смена удалена')
        dispatch(changeRequestState('deleteShift', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('deleteShift', StatusRequest.ERROR))
      showError('Удаление смены приведет к потере данных')
      throw(e)
    }
  }
}

export const cancelShift =
  (shift_id: string, body: APITYPES.Shifts.ShiftById.CANCEL.Req, onSuccess?: any): ThAction => {
    return async (dispatch, _, { api }) => {
      dispatch(changeRequestState('cancelShift', StatusRequest.LOADING))

      try {
        const resp = await api.post(APITYPES.Shifts.ShiftById.CANCEL.URL, body, { replaceUrl: { shift_id } })

        if (resp) {
          if (onSuccess) {
            onSuccess(shift_id)
          }

          dispatch(action(Actions.DELETE_SHIFT, { shift_id }))
          dispatch(action(Actions.REMOVE_SHIFT_TAB, { shift_id }))
          openNotification('Смена отменена')
          dispatch(changeRequestState('cancelShift', StatusRequest.LOADED))
        }
      } catch (e: any) {
        dispatch(changeRequestState('cancelShift', StatusRequest.ERROR))
        showError(e?.response?.data ?? 'Ошибка')
        throw(e)
      }
    }
  }


export const changeDoerShiftStatus = (
  doerShiftId: string,
  body: APITYPES.Shifts.ChangeDoerShiftStatus.Req,
  shiftId: string,
  status: APITYPES.ShiftDoerStateOptions,
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('changeDoerShiftStatus', StatusRequest.LOADING))


    try {
      const resp = await api.put(APITYPES.Shifts.ChangeDoerShiftStatus.URL,
        body,
        {
          replaceUrl: {
            doer_shift_id: doerShiftId,
            new_status: doerShiftStatusMapToUrl[status],
          },
        })

      if (resp) {
        dispatch(fetchShiftDoers(shiftId))
        openNotification('Статус смены исполнителя изменен')

        dispatch(changeRequestState('changeDoerShiftStatus', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeDoerShiftStatus', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const changeDoerShift = (
  shiftId: string,
  doerShiftId: string,
  body: APITYPES.Shifts.ChangeDoerShift.Req,
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('changeDoerShift', StatusRequest.LOADING))

    try {
      const resp =
        await api.put(APITYPES.Shifts.ChangeDoerShift.URL, body, { replaceUrl: { doer_shift_id: doerShiftId } })

      if (resp) {
        dispatch(fetchShiftDoers(shiftId))
        openNotification('Смена исполнителя измененна')

        dispatch(changeRequestState('changeDoerShift', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeDoerShift', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchShiftsForRedirect = (shift: APITYPES.Shift, filter?: APITYPES.ShiftFilter): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchShiftsForRedirect', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.Shift.GET.URL, { params: { ...filter } })

      if (resp) {
        const response = resp.filter((s) => s.id !== shift.id)

        dispatch(action(Actions.SET_SHIFTS_FOR_REDIRECT, response))

        dispatch(changeRequestState('fetchShiftsForRedirect', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchShiftsForRedirect', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const сommitShifts = (
  body: APITYPES.Shifts.CommitShifts.POST.Req
  , onClose: () => void , onError: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('commitShifts', StatusRequest.LOADING))

    try {
      const resp =
        await api.post(APITYPES.Shifts.CommitShifts.POST.URL, body)

      if (resp) {
        openNotification('Смены зафиксированы')

        dispatch(changeRequestState('commitShifts', StatusRequest.LOADED))
        onClose()
      }
    } catch (e: any) {
      dispatch(changeRequestState('commitShifts', StatusRequest.ERROR))

      showError(e.response.data)
      onError()
      throw(e)
    }
  }
}