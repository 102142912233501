import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


const formatScheduleDto = (dto: APITYPES.ScheduleDto) => ({
  ...dto,
  active_from: new Date(dto.active_from),
  active_to: new Date(dto.active_to),
})

export const getSchedules = (): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('getSchedules', StatusRequest.LOADING))

    const id = getState().carwashesReducer.carwash?.id

    if (!id) { return }
      
    try {
      const resp = await api.get(APITYPES.Schedules.Get.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_SCHEDULES, resp.map(formatScheduleDto)))
    
        dispatch(changeRequestState('getSchedules', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getSchedules', StatusRequest.ERROR))

      showError(e.response.data)
  
      throw(e)
    }
  }
}

export const getSchedule = (schedule: APITYPES.Schedule | undefined | null): ThAction => {
  return async (dispatch, getState) => {
    dispatch(changeRequestState('getSchedule', StatusRequest.LOADING))

    if (!schedule) {
      dispatch(action(Actions.SET_SCHEDULE, null))

      return
    }
  
    const id = getState().carwashesReducer.carwash?.id
  
    if (!id) { return }
    
    dispatch(action(Actions.SET_SCHEDULE, schedule))

    const intervalsMap = new Map()

    schedule.intervals?.map((item)=>{
      intervalsMap.set(item.day_num, intervalsMap.has(item.day_num) ?
        [ item ,...intervalsMap.get(item.day_num)] : [item])
    })

    dispatch(action(Actions.SET_SCHEDULE_INTERVALS_MAP, intervalsMap))
      
    dispatch(changeRequestState('getSchedule', StatusRequest.LOADED))
  }
}

export const createSchedule = (data: APITYPES.Schedules.Post.Req): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('createSchedule', StatusRequest.LOADING))
    
    const id = getState().carwashesReducer.carwash?.id
    
    if (!id) { return }
          
    try {
      const resp = await api.post(APITYPES.Schedules.Post.URL, data, { replaceUrl: { id } })
    
      if (resp) {
        dispatch(action(Actions.ADD_SCHEDULE, formatScheduleDto(resp)))

        openNotification('Расписание создано')
        
        dispatch(changeRequestState('createSchedule', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createSchedule', StatusRequest.ERROR))
    
      showError(e.response.data)
      
      throw(e)
    }
  }
}

export const updateSchedule = (data: APITYPES.Schedules.Put.Req, schedule_id: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('updateSchedule', StatusRequest.LOADING))
      
    const id = getState().carwashesReducer.carwash?.id
      
    if (!id) { return }
            
    try {
      const resp = await api.put(APITYPES.Schedules.Put.URL, data, {
        replaceUrl: {
          carwash_id: id,
          schedule_id,
        },
      })
      
      if (resp) {
        dispatch(action(Actions.SET_SCHEDULE, formatScheduleDto(resp)))

        openNotification('Расписание изменено')
          
        dispatch(changeRequestState('updateSchedule', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateSchedule', StatusRequest.ERROR))
      
      showError(e.response.data)
        
      throw(e)
    }
  }
}

export const deleteSchedule = (schedule_id: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('deleteSchedule', StatusRequest.LOADING))
        
    const id = getState().carwashesReducer.carwash?.id
        
    if (!id) { return }
              
    try {
      const resp = await api.delete(APITYPES.Schedules.Delete.URL, {
        replaceUrl: {
          carwash_id: id,
          schedule_id,
        },
      })
        
      if (resp) {
        dispatch(action(Actions.DELETE_SCHEDULE, schedule_id))

        openNotification('Расписание удалено')
            
        dispatch(changeRequestState('deleteSchedule', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteSchedule', StatusRequest.ERROR))
        
      showError(e.response.data)
          
      throw(e)
    }
  }
}

export const createInterval = (data: APITYPES.Schedules.CreateInterval.Req, schedule_id: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('createInterval', StatusRequest.LOADING))
          
    const id = getState().carwashesReducer.carwash?.id
          
    if (!id) { return }
                
    try {
      const resp = await api.post(APITYPES.Schedules.CreateInterval.URL, data, {
        replaceUrl: {
          carwash_id: id,
          schedule_id,
        },
      })
          
      if (resp) {
        dispatch(action(Actions.ADD_SCHEDULE_INTERVAL, resp))

        dispatch(getSchedules())

        openNotification('Интервал создан')
              
        dispatch(changeRequestState('createInterval', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createInterval', StatusRequest.ERROR))
          
      showError(e.response.data)
            
      throw(e)
    }
  }
}

export const updateInterval =
(data: APITYPES.Schedules.UpdateInterval.Req, schedule_id: string, interval_id: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('updateInterval', StatusRequest.LOADING))
          
    const id = getState().carwashesReducer.carwash?.id
          
    if (!id) { return }
                
    try {
      const resp = await api.put(APITYPES.Schedules.UpdateInterval.URL, data, {
        replaceUrl: {
          carwash_id: id,
          schedule_id,
          interval_id,
        },
      })
          
      if (resp) {
        dispatch(action(Actions.UPDATE_SCHEDULE_INTERVAL, resp))

        dispatch(getSchedules())

        openNotification('Интервал сохранен')
              
        dispatch(changeRequestState('updateInterval', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateInterval', StatusRequest.ERROR))
          
      showError(e.response.data)
            
      throw(e)
    }
  }
}

export const deleteInterval = (schedule_id: string, interval_id: string): ThAction => {
  return async (dispatch, getState , { api }) => {
    dispatch(changeRequestState('deleteInterval', StatusRequest.LOADING))
          
    const id = getState().carwashesReducer.carwash?.id
          
    if (!id) { return }
                
    try {
      const resp = await api.delete(APITYPES.Schedules.DeleteInterval.URL, {
        replaceUrl: {
          carwash_id: id,
          schedule_id,
          interval_id,
        },
      })
          
      if (resp) {
        dispatch(action(Actions.DELETE_SCHEDULE_INTERVAL, interval_id))

        dispatch(getSchedules())

        openNotification('Интервал удален')
              
        dispatch(changeRequestState('deleteInterval', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteInterval', StatusRequest.ERROR))
          
      showError(e.response.data)
            
      throw(e)
    }
  }
}