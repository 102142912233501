// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--nextIcon--MSsAy{font-size:16px;color:#85878f;margin:0 6px}.styles-module--selectSchedule--lWKsZ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--selectSchedule--lWKsZ>*{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.styles-module--timePopUp--DaeMk>div>div>:nth-child(2){display:none}.styles-module--timePopUp--DaeMk>div>div>:nth-child(1)>div>ul::-webkit-scrollbar{width:4px}.styles-module--timePopUp--DaeMk>div>div>:nth-child(1)>div>ul::-webkit-scrollbar-thumb{background-color:#b7b7b7}.styles-module--timePopUp--DaeMk>div>div>:nth-child(1)>div>ul::-webkit-scrollbar-track{background-color:#e5e5e5}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/shift-settings/components/ShiftTypeForm/styles.module.sass"],"names":[],"mappings":"AAAA,gCACE,cAAA,CACA,aAAA,CACA,YAAA,CAGF,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wCACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CAIA,uDACE,YAAA,CAEA,iFACE,SAAA,CACF,uFACE,wBAAA,CACF,uFACE,wBAAA","sourcesContent":[".nextIcon\n  font-size: 16px\n  color: #85878F\n  margin: 0 6px\n\n\n.selectSchedule\n  display: flex\n  align-items: center\n  > *\n    flex-grow: 1\n\n.timePopUp\n  & > div > div\n    & > :nth-child(2)\n      display: none\n    & > :nth-child(1) > div > ul\n      &::-webkit-scrollbar\n        width: 4px\n      &::-webkit-scrollbar-thumb\n        background-color: #B7B7B7\n      &::-webkit-scrollbar-track\n        background-color: #E5E5E5"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nextIcon": "styles-module--nextIcon--MSsAy",
	"selectSchedule": "styles-module--selectSchedule--lWKsZ",
	"timePopUp": "styles-module--timePopUp--DaeMk"
};
export default ___CSS_LOADER_EXPORT___;
