import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const updateBillItem = (value: APITYPES.Billing.UpdateBillItem.Req, bill_item_id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    const bill_id = getState().billingReducer.bill?.id

    if (!bill_id) {
      return
    }

    dispatch(changeRequestState('updateBillItem', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Billing.UpdateBillItem.URL, value, {
        replaceUrl: {
          bill_id,
          bill_item_id,
        },
      })
      
      if (resp) {
        dispatch(action(Actions.SET_BILL, resp))

        openNotification('Позиция счета изменена')

        dispatch(changeRequestState('updateBillItem', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateBillItem', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}