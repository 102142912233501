// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--tree--3YkQJ{width:50%;height:calc(100vh - 174px);overflow:auto}.styles-module--treeItem--Xqpj6{cursor:pointer;color:rgba(0,0,0,.85);font-weight:400;-webkit-transition:.2s;transition:.2s}.styles-module--treeItem--Xqpj6:hover{color:#1890ff}.styles-module--selectedTreeItem--eyWAl{color:#1890ff;font-weight:600}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Operations/Operation/OperationsTree/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,SAAA,CACA,0BAAA,CACA,aAAA,CAEF,gCACE,cAAA,CACA,qBAAA,CACA,eAAA,CACA,sBAAA,CAAA,cAAA,CACA,sCACE,aAAA,CAEJ,wCACE,aAAA,CACA,eAAA","sourcesContent":[".tree\n  width: 50%\n  height: calc(100vh - 174px)\n  overflow: auto\n\n.treeItem\n  cursor: pointer\n  color: rgba(0, 0, 0, 0.85)\n  font-weight: 400\n  transition: 0.2s\n  &:hover\n    color: #1890FF\n\n.selectedTreeItem\n  color: #1890FF\n  font-weight: 600\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree": "styles-module--tree--3YkQJ",
	"treeItem": "styles-module--treeItem--Xqpj6",
	"selectedTreeItem": "styles-module--selectedTreeItem--eyWAl"
};
export default ___CSS_LOADER_EXPORT___;
