import moment from 'moment'


export const getFormattedDate = (dateStr: string, format: string = dateTimeFormat) => {
  return moment(dateStr).format(format)
}

export const getFormattedDateWithParseZone = (dateStr: string, format: string = dateTimeFormat) => {
  return moment.parseZone(dateStr).format(format)
}

export const hourMinuteFormat = 'HH:mm'
export const timeFormat = 'HH:mm:ss'

export const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss'
export const dateTimeFormatWithTimezone = `${dateTimeFormat} Z` as const
export const shortDateTimeFormat = 'DD.MM.YYYY HH:mm'
export const dateFormat = 'DD.MM.YYYY'
export const backDateFormat = 'YYYY-MM-DD'
export const dateTimezoneFormat = 'YYYY-MM-DD HH:mm:ssZ'


export const getShortTime = (time: string) => {
  const [hours, minutes] = time.split(':')

  return hours + ':' + minutes
}