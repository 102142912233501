import { NewsModalType } from '../utils'


export const getModalTitle = (type: NewsModalType | null) => {
  switch (type) {
    case NewsModalType.ADD_NEWS:
      return 'Добавление новости'
    case NewsModalType.EDIT_NEWS:
      return 'Обновление новости'
    case NewsModalType.MANAGE_NEWS:
      return 'Настройка новости'
  }
  
  return ''
}