import React, { useState } from 'react'
import { Button, Table } from 'antd'

import { APITYPES } from 'types/apitypes'
import { EditDocument } from '../EditDocument'
import { CreateDocument } from '../CreateDocument'

import styles from './styles.module.sass'
import { getColumns, getRows } from './utils'


type Props = {
  documents: APITYPES.PartnerDocument[]
}

export const DocumentsBlock = ({ documents = [] }: Props) => {
  const [documentIdForModal, setDocumentIdForModal] = useState<string | undefined>(undefined)

  const [isCreating, setIsCreating] = useState(false)

  const onAdd = () => {
    setIsCreating(true)
  }

  return (
    <div className={styles.container}>
      <Button
        onClick={onAdd}
        className={styles.button}
        type='primary'
      >
        Добавить документ
      </Button>
      {
        documents && (
          <Table
            columns={getColumns() as any}
            className={styles.table}
            dataSource={getRows(documents)}
            bordered
            size='large'
            pagination={false}
            scroll={
              { x: true }
            }
            onRow={
              (record) => {
                return {
                  onClick: () => {
                    setDocumentIdForModal(record.actions.id)
                  },
                }
              }
            }
          />
        )
      }
      {
        documentIdForModal && (
          <EditDocument
            isVisible={!!documentIdForModal}
            onCancel={()=>setDocumentIdForModal(undefined)}
            documentId={documentIdForModal}
          />
        )
      }
      <CreateDocument
        isVisible={isCreating}
        onCancel={()=>setIsCreating(false)}
      />
    </div>
  )
}