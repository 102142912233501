import { APITYPES } from '../../../../../types/apitypes'

import ChargePriorities = APITYPES.ChargePriorities


export enum OptionsType {
  SELECT = 10,
  INPUT = 20,
}
export const TimeTypeNames: Record<OptionsType, string> = {
  [OptionsType.SELECT]: 'Выбрать смену',
  [OptionsType.INPUT]: 'Указать параметры смены',
}


export const PickPenaltyOptions: Record<OptionsType, string> = {
  [OptionsType.SELECT]: 'Выбрать штраф',
  [OptionsType.INPUT]: 'Указать параметры штрафа вручную',
}


export const chargePriorities: Record<APITYPES.Performers.GetPenalties.Resp['charge_priority'], ChargePriorities> = {
  0: ChargePriorities.LOW,
  1: ChargePriorities.MEDIUM,
  2: ChargePriorities.HIGH,
}

export const getChargePriorities = () => Object.entries(chargePriorities)
  .map(([value, label]) => ({
    value: Number(value),
    label,
  }))
