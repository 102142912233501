import { Button, Modal, Space, Steps } from 'antd'
import React, { useState } from 'react'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'

import { CalcPayment } from '../CalcPayments'
import { CalcDoersMotivationsTable } from '../CalcDoersMotivationsTable'
import { action, Actions } from '../../../../../actions'
import { backDateFormat } from '../../../../../utils/dateUtils'
import { postBillCalculations } from '../../../../../AC/doers/doerBilling'
import { State } from '../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../stores/requestsReducer'
import { APITYPES } from '../../../../../types/apitypes'
import { getMotivationStatistics } from '../../../../../AC/doers/getMotivationStatistics'
import { exportToExcel } from '../../../Doers/ShiftsPage/components/CommitModal/Wizard/utils'

import styles from './styles.module.sass'

import PostBillCalculations = APITYPES.DoerTariff.PostBillCalculations


type M = moment.Moment

interface Props {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => {
  return {
    isCalcLoading: state.requestsReducer.postBillCalculations === StatusRequest.LOADING,
    motivationStatistics: state.motivationStatisticsReducer.motivationStatistics,
  }
}

export const CalcDoersWizard = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const { isCalcLoading, motivationStatistics } = useSelector(selector)
  const [current, setCurrent] = useState<number>(0)
  const [checkedKey, setCheckedKey] = useState<string>('')
  const [motivationIds, setMotivationIds] = useState<string[]>([])
  const [dates, setDates] = useState<M[] | any>(null)

  const steps = [
    {
      title: 'Параметры расчета',
      content: (
        <CalcPayment
          dates={dates}
          onDateChange={(value) => setDates(value)}
          checkedKey={checkedKey}
          onChecked={(value) => setCheckedKey(value)}
        />
      ),
    },
    {
      title: 'Бонусы за период',
      content: (
        <CalcDoersMotivationsTable
          setMotivationIds={setMotivationIds}
          motivationIds={motivationIds}
        />
      ),
    },
  ]

  const onError = () => {
    setCurrent(0)
  }

  const calcHandler = () =>{
    if (dates && checkedKey){
      const body: PostBillCalculations.Req = {
        begin: dates[0].format(backDateFormat),
        end: dates[1].format(backDateFormat),
        doer_tariff_group_id: checkedKey,
      }

      if (motivationIds.length) {
        body.motivation_ids = motivationIds
      }
      dispatch(action(Actions.CLEAR_DOER_RECORDS_PERIOD,[]))
      dispatch(postBillCalculations(body, onClose, onError))
    }
  }

  const next = () => {
    if (!motivationStatistics.length && checkedKey && dates) {
      const [begin, end] = dates

      dispatch(getMotivationStatistics(
        {
          begin: begin.format(backDateFormat),
          end: end.format(backDateFormat),
          doer_tariff_group_id: checkedKey,
        }))
    }
    setCurrent(current + 1)
  }

  const downloadFile = async () => {
    if (dates) {
      exportToExcel(motivationStatistics, dates, 'Мотивации за ')
    }
  }

  const onDestroy = () => {
    setCurrent(0)
    dispatch(action(Actions.CLEAN_MOTIVATION_STATISTICS, []))
    dispatch(action(Actions.CLEAN_DOER_TARIFFS_GROUPS, {}))
    setCheckedKey('')
    setDates(null)
    setMotivationIds([])
  }

  return (
    <Modal
      title='Расчет выплат'
      open={isOpen}
      destroyOnClose
      footer={false}
      width={1340}
      onCancel={
        () => {
          onDestroy()
          onClose()
        }
      }
      afterClose={onDestroy}
    >
      <Steps
        current={current}
        items={steps}
      />
      <div className={styles.steps_content}>{ steps[current].content }</div>
      <div className={styles.steps_action}>
        {
          current < steps.length - 1 && (
            <Button
              type='primary'
              onClick={next}
              disabled={!(checkedKey && dates?.length)}
            >
              Далее
            </Button>
          )
        }
        {
          current === steps.length - 1 && (
            <Space>
              <Button
                type='primary'
                onClick={downloadFile}
              >
                Скачать
              </Button>
              <Button
                type='primary'
                onClick={calcHandler}
                loading={isCalcLoading}
              >
                Рассчитать
              </Button>
            </Space>
          )
        }
      </div>

    </Modal>
  )
}