import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import { getDoerById } from './getDoers'


export const blockDoer = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('blockDoer', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Doers.Block.URL, {}, { replaceUrl: { id } })
      
      if (resp) {
        dispatch(getDoerById(id))

        openNotification('Исполнитель заблокирован')

        dispatch(changeRequestState('blockDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('blockDoer', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}