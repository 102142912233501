// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--RW-2N{width:40%;height:100%}.styles-module--card--wzzdi{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:100%}.styles-module--walletBlock--c6VUD{margin-top:20px}.styles-module--walletInput--Lz\\+Pv{width:100%}.styles-module--clearWalletBtn--m251H{width:100%;margin-bottom:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.styles-module--popConfirmWallet--LSCBH{max-width:400px}.styles-module--popConfirmWallet--LSCBH button{width:80px;height:34px;padding:4px 8px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Doers/Doer/Information/DoerInformation/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,SAAA,CACA,WAAA,CAEF,4BACE,0BAAA,CAAA,uBAAA,CAAA,kBAAA,CACA,UAAA,CAEF,mCACE,eAAA,CAEF,oCACE,UAAA,CAEF,sCACE,UAAA,CACA,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAEF,wCACE,eAAA,CACA,+CACE,UAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".container\n  width: 40%\n  height: 100%\n\n.card\n  height: fit-content\n  width: 100%\n\n.walletBlock\n  margin-top: 20px\n\n.walletInput\n  width: 100%\n\n.clearWalletBtn\n  width: 100%\n  margin-bottom: 0\n  display: flex\n  justify-content: flex-end\n\n.popConfirmWallet\n  max-width: 400px\n  & button\n    width: 80px\n    height: 34px\n    padding: 4px 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--RW-2N",
	"card": "styles-module--card--wzzdi",
	"walletBlock": "styles-module--walletBlock--c6VUD",
	"walletInput": "styles-module--walletInput--Lz+Pv",
	"clearWalletBtn": "styles-module--clearWalletBtn--m251H",
	"popConfirmWallet": "styles-module--popConfirmWallet--LSCBH"
};
export default ___CSS_LOADER_EXPORT___;
