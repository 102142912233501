import { RequestsState, StatusRequest } from 'stores/requestsReducer'
import { action, Actions } from 'actions'


export const changeRequestState = (
  requestName: keyof RequestsState,
  status: StatusRequest,
) => {
  return action(Actions.CHANGE_REQUEST_STATUS, {
    requestName,
    status,
  })
}
