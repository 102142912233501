// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--form--ql8y- label{white-space:pre-wrap}.styles-module--field--g\\+XGG{height:30px}.styles-module--field--g\\+XGG div{overflow:visible}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/Properties/styles.module.sass"],"names":[],"mappings":"AACI,kCACI,oBAAA,CAER,8BACI,WAAA,CAEA,kCACI,gBAAA","sourcesContent":[".form\n    label\n        white-space: pre-wrap\n\n.field\n    height: 30px\n\n    div\n        overflow: visible"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles-module--form--ql8y-",
	"field": "styles-module--field--g+XGG"
};
export default ___CSS_LOADER_EXPORT___;
