import { action, Actions, ThAction } from 'actions'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'


export const fetchSigners = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    try {
      const resp = await api.get(APITYPES.Dictionary.Signers.URL)

      if (resp) {
        dispatch(action(Actions.SET_SIGNER_DICTIONARY, resp))
      }
    } catch (e) {
      showError(e?.response?.data)
    }
  }
}