import { combineReducers } from 'redux'

import { Action } from 'actions'

import { requestsReducer, RequestsState } from './requestsReducer'
import { authReducer, AuthState } from './authReducer'
import { partnersReducer, PartnersState } from './partnersReducer'
import { carwashesReducer, CarwashesState } from './carwashesReducer'
import { directoryReducer, DirectoryState } from './directoryReducer'
import { carsReducer, CarsState } from './carsReducer'
import { statisticsReducer, StatisticsState } from './statisticsReducer'
import { usersReducer, UsersState } from './usersReducer'
import { doersReducer, DoersState } from './doersReducer'
import { billingReducer, BillingState } from './billingReducer'
import { shiftsReducer, ShiftsState } from './shiftsReducer'
import { newsReducer, NewsState } from './newsReducer'
import { offersReducer, OffersState } from './offersReducer'
import { operationsReducer, OperationsState } from './operationsReducer'
import { productsReducer, ProductsState } from './productsReducer'
import { customerTariffsReducer, CustomerTariffsState } from './customerTariffsReducer'
import { doerTariffsReducer, DoerTariffsState } from './doerTariffsReducer'
import { fleetsReducer, FleetsState } from './fleetsReducer'
import { mailingReducer, MailingState } from './mailingReducer'
import { motivationsReducer, MotivationsState } from './motivationsReducer'
import { yandexProReducer, YandexProState } from './yandexProReducer'
import { doerRecordsReducer, DoerRecordsState } from './doerRecordsReducer'
import { doerMonitoringReducer, DoerMonitoringState } from './doerMonitoringReducer'
import { motivationStatisticsReducer, MotivationsStatisticsState } from './motivationStatisticsReducer'
import { doerVacanciesReducer, DoerVacanciesState } from './doerVacanciesReducer'
import { yandexLavkaReducer, YandexLavkaState } from './yandexLavkaReducer'
import { urentReducer, UrentState } from './urentReducer'
import { internshipsReducer, InternshipsState } from './internships-reducer'
import { dictionaryReducer, DictionaryState } from './dictionary-reducer'


export type State = {
  dictionaryReducer: DictionaryState
  requestsReducer: RequestsState
  authReducer: AuthState
  partnersReducer: PartnersState
  carwashesReducer: CarwashesState
  directoryReducer: DirectoryState
  carsReducer: CarsState
  statisticsReducer: StatisticsState
  usersReducer: UsersState
  doersReducer: DoersState
  billingReducer: BillingState
  shiftsReducer: ShiftsState
  newsReducer: NewsState
  offersReducer: OffersState
  operationsReducer: OperationsState
  productsReducer: ProductsState
  customerTariffsReducer: CustomerTariffsState
  doerTariffsReducer: DoerTariffsState
  fleetsReducer: FleetsState
  mailingReducer: MailingState
  motivationsReducer: MotivationsState
  motivationStatisticsReducer: MotivationsStatisticsState
  yandexProReducer: YandexProState
  yandexLavkaReducer: YandexLavkaState
  urentReducer: UrentState
  doerRecordsReducer: DoerRecordsState
  doerMonitoringReducer: DoerMonitoringState
  doerVacanciesReducer: DoerVacanciesState
  internshipsReducer: InternshipsState
}

export const rootReducer = combineReducers<State, Action>({
  dictionaryReducer,
  requestsReducer,
  authReducer,
  partnersReducer,
  carwashesReducer,
  directoryReducer,
  carsReducer,
  statisticsReducer,
  usersReducer,
  doersReducer,
  billingReducer,
  shiftsReducer,
  newsReducer,
  offersReducer,
  operationsReducer,
  productsReducer,
  customerTariffsReducer,
  doerTariffsReducer,
  fleetsReducer,
  mailingReducer,
  motivationsReducer,
  motivationStatisticsReducer,
  yandexProReducer,
  yandexLavkaReducer,
  urentReducer,
  doerRecordsReducer,
  doerMonitoringReducer,
  doerVacanciesReducer,
  internshipsReducer,
})
