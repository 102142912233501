// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addButton--yckb9{margin-bottom:16px}.styles-module--table--6BYsR{cursor:pointer}.styles-module--tableBtn--i-MCz{margin:0;padding:0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/ClientTypes/ClientType/CarClasses/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,kBAAA,CAEJ,6BACI,cAAA,CAEJ,gCACI,QAAA,CACA,SAAA","sourcesContent":[".addButton\n    margin-bottom: 16px\n\n.table\n    cursor: pointer\n\n.tableBtn\n    margin: 0\n    padding: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "styles-module--addButton--yckb9",
	"table": "styles-module--table--6BYsR",
	"tableBtn": "styles-module--tableBtn--i-MCz"
};
export default ___CSS_LOADER_EXPORT___;
