// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--steps_action--jUJWf{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.styles-module--steps_content--QVxW4{padding:24px 0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/DoerRecords/CalcDoersWizard/styles.module.sass"],"names":[],"mappings":"AAAA,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGF,qCACE,cAAA","sourcesContent":[".steps_action\n  display: flex\n  justify-content: flex-end\n\n\n.steps_content\n  padding: 24px 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"steps_action": "styles-module--steps_action--jUJWf",
	"steps_content": "styles-module--steps_content--QVxW4"
};
export default ___CSS_LOADER_EXPORT___;
