import React from 'react'
import { SettingOutlined } from '@ant-design/icons'

import styles from '../styles.module.sass'


type Props = {
  title: string
  url: string
  settingsKey: string
  onSettingsClick: () => void
}

export const ItemSettings = (props: Props) => {
  const { title, onSettingsClick, url, settingsKey } = props

  return (
    <div className={styles.menuItemInnerWrapper}>
      <span>{ title }</span>
      <span
        className={styles.menuSettingsIcon}
        onClick={
          (e: any) => {
            e.stopPropagation()
            if (onSettingsClick) {onSettingsClick()}
          }
        }
      >
        <SettingOutlined className={settingsKey === url ? styles.active : ''} />
      </span>
    </div>
  )
}