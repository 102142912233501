import React, { FC, useEffect } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { UnmatchedExecutorsTable } from './components/UnmatchedExecutorsTable'

import styles from './styles.module.sass'


const selector = (state: State) => ({ report: state.urentReducer.uploadedReport })

interface Props {
  onSubmit: () => void
}
export const MatchingUnmatchedExecutors: FC<Props> = ({ onSubmit }) => {
  const { report } = useSelector(selector)

  useEffect(() => {
    if (report?.unmatched_executors && report?.unmatched_executors.length === 0) {
      onSubmit()
    }
  }, [report])

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedExecutorsTable unmatchedExecutors={report?.unmatched_executors || []} />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!report?.unmatched_executors?.every((item) => item.isMatched)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}