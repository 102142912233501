import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import styles from '../YandexProReports/styles.module.sass'
import { getTableYScroll } from 'utils/scrollUtils'
import { getYandexLavkaReports } from 'AC/yandexLavka/reports'
import { UploadReportFileModal } from './components/UploadReportFileModal/UploadReportFileModal'
import { MatchingModal } from './components/MatchingModal/MatchingModal'

import { MappedReport, mapReportsToTable, reportTableCols } from './table'


const selector = (state: State) => ({
  reports: state.yandexLavkaReducer.reports,
  isLoading:
      state.requestsReducer.getYandexLavkaReports === StatusRequest.LOADING,
})

export const YandexLavka = () => {
  const [isUploadReportFileModalOpen, setIsUploadReportFileModalOpen] = useState(false)
  const [matchingReportId, setMatchingReportId] = useState('')
  const { reports, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const toggleUploadModal = () => setIsUploadReportFileModalOpen((prevState) => !prevState)

  const onCloseUploadModal = () => {
    dispatch(getYandexLavkaReports())
    setIsUploadReportFileModalOpen(false)
  }

  const closeMatchingModal = () => {
    setMatchingReportId('')
  }

  const onClickRow = (record: MappedReport) => {
    setMatchingReportId(record.id)
  }

  useEffect(() => {
    if (!reports) {
      dispatch(getYandexLavkaReports())
    }
  }, [reports])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={toggleUploadModal}
          >
            Загрузить отчет
          </Button>
        </div>
        <Table
          bordered
          sticky
          size='small'
          pagination={false}
          loading={isLoading}
          style={{ cursor: 'pointer' }}
          columns={reportTableCols}
          scroll={{ y: getTableYScroll(162) }}
          dataSource={reports ? mapReportsToTable(reports) : []}
          onRow={
            (record) => {
              return { onClick: onClickRow.bind(null, record) }
            }
          }
        />
      </div>

      <UploadReportFileModal
        isOpen={isUploadReportFileModalOpen}
        onClose={onCloseUploadModal}
      />

      {
        matchingReportId && (
          <MatchingModal
            reportId={matchingReportId}
            onCancel={closeMatchingModal}
          />
        )
      }

    </>
  )
}
