import moment from 'moment'

import { getEnumKeys } from 'utils/enumUtils'
import { SelectValue } from '../../../../../components/DebounceSelectDoer'


export enum BillState {
  draft = 'draft',
  canceled = 'canceled',
  negotiation = 'На negotiation',
  accepted = 'accepted',
  declined = 'declined',
  paid = 'paid',
  paying = 'paying',
}
export const billStateOptions = getEnumKeys(BillState)

export enum BillStateTranslation {
  draft = 'Черновик',
  canceled = 'Отменен',
  negotiation = 'На согласовании',
  accepted = 'Подтвержден',
  declined = 'Отклонен',
  paid = 'Оплачен',
  paying = 'В оплате',
}

export const getInitDatesState = (start: string | undefined, end: string | undefined) => {
  if (!start || !end) {return undefined}

  return [moment(start), moment(end)]
}

export const getInitDoer = (id?: string, selectValue?: SelectValue[]) => {
  if (!id || !selectValue) {return}

  return selectValue.find((option) => option.value === id)
}