import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type DoerMonitoringState = {
  doerMonitoringAllocations: APITYPES.DoerMonitoring.GetAllocations.Resp
  doerMonitoringAllocationsFilter: APITYPES.DoerMonitoring.GetAllocations.Req
}

export const initialState: DoerMonitoringState = {
  doerMonitoringAllocations: [],
  doerMonitoringAllocationsFilter: {} as APITYPES.DoerMonitoring.GetAllocations.Req
}

export const doerMonitoringReducer: Reducer<DoerMonitoringState, Action> = (
  state = initialState,
  a
): DoerMonitoringState => {
  switch (a.type) {
    case Actions.CLEAN_DOER_MONITORING_ALLOCATIONS: {
      return {
        ...state,
        doerMonitoringAllocations: [],
      }
    }

    case Actions.SET_DOER_MONITORING_ALLOCATIONS: {
      return {
        ...state,
        doerMonitoringAllocations: [...state.doerMonitoringAllocations, ...a.data.doerMonitoringAllocations],
      }
    }

    case Actions.SET_DOER_MONITORING_ALLOCATIONS_FILTER: {
      return {
        ...state,
        doerMonitoringAllocationsFilter: a.data,
      }
    }
  }

  return state
}