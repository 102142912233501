import React from 'react'
import { useDispatch } from 'react-redux'
import { Form, Modal } from 'antd'
import qs from 'qs'

import { getExportBills } from '../../../../../AC/billing/getExportBills'
import { FormFooter } from '../../../../../components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

type ALL = 'all'
const ALL: ALL = 'all'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export const ExportBills = ({ isVisible, onCancel }: Props) => {
  const dispatch = useDispatch()
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())

  const onFinish = () => {
    const parsed = qs.parse(params)

    const state = (parsed?.state as string) ?? ''
    const status = state.split(',')
    const urlParams = { ...parsed }

    if (state.length) {
      urlParams.state = status
    }

    dispatch(getExportBills(urlParams, 'doer'))

    onCancel()
  }

  return (
    <Modal
      title='Экспорт счетов'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='bills_form'
        {...formItemLayout}
        onFinish={onFinish}
      >
        <h4 style={
          {
            fontWeight: 400,
            textAlign: 'center',
            marginBottom: '20px',
          }
        }
        >
          Вы действительно хотите экспортировать выбранные счета?
        </h4>
        <FormFooter
          okText='Да'
          cancelText='Нет'
          onCancel={onCancel}
          position='right'
        />
      </Form>
    </Modal>
  )
}