import React from 'react'
import { Tag } from 'antd'
import moment from 'moment'
import { APITYPES } from 'types/apitypes'

const dateFormat = 'DD-MM-YYYY HH:mm'

export const getProductColumns = () => {
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Общее кол-во использований',
      dataIndex: 'limit',
      key: 'limit',
    }
  ]
}

export const getProductRows = (products: APITYPES.SubProducts[]) => {
  return products.map((product) => {
    const row: any = {}
    getProductColumns().map((column) => {
      if(column.dataIndex === 'limit') {
        row[column.dataIndex] = product.limit?.usage ? product.limit.usage : '-'
      } else {
        //@ts-ignore
        row[column.dataIndex] = product[column.dataIndex]
      }

      return
    })

    row.key = product.product_id

    return row
  })
}

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Номер',
    dataIndex: 'numberPlate',
    key: 'numberPlate',
    align: 'center',
    width: '18%',
    render: (car: APITYPES.UserCar) => <Tag>{car?.grz}</Tag>
  },
  {
    title: 'Автомобиль',
    dataIndex: 'car',
    key: 'car',
    align: 'center',
    width: '18%',
    render: (car: APITYPES.UserCar) => <Tag>{`${car?.car_brand_name} ${car?.car_model_name}`}</Tag>
  },
  {
    title: 'Период',
    dataIndex: 'period',
    key: 'period',
    align: 'center',
    width: '12%'
  },
  {
    title: 'Активен до',
    dataIndex: 'active_until',
    key: 'active_until',
    width: '17%',
    align: 'center',
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
    width: '17%',
    align: 'center',
  },
]


export const getColumns = () => columns

export const getRows = (subscriptions: APITYPES.UserSubscription[], cars: APITYPES.UserCar[]) => {
  const data = subscriptions.map((sub, index) => {
    const row: any = {}

    columns.map((column) => {
      if (column.dataIndex === 'numberPlate' || column.dataIndex === 'car') {
        const car = cars.find(car => car.car_id === sub.car_id)
        row[column.dataIndex] = car
      } else if(column.dataIndex === 'active_until' || column.dataIndex === 'created') {
        row[column.dataIndex] = moment(sub[column.dataIndex]).format(dateFormat)
      } else {
        //@ts-ignore
        row[column.dataIndex] = sub[column.dataIndex]
      }


      return
    })

    row.nubmerPlate = sub
    row.products = sub.products
    row.key = `${sub.id}_${index}`

    return row
  })

  return data
}