import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { fetchLocationChildren } from 'AC/shifts/locations'
import { action, Actions } from 'actions'
import { getEnumKeysObject } from 'utils/enumUtils'

import { ChildrenLocations } from './ChildrenLocations'
import { AddChildrenForm } from './AddChildrenForm'
import { OnlyForDistrict } from './OnlyForDistrict'
import { EditType, getEditDescription, TabKeys } from './utils'
import { MatchingLocations } from './MatchingLocations'
import { Photos } from './Photos'
import styles from './styles.module.sass'
import { EditLocation } from './EditLocation'
import { MatchingLavkaLocations } from './MatchingLavkaLocations'
import { MatchingUrentLocations } from './MatchingUrentLocations'


const locationKeys = getEnumKeysObject(APITYPES.ShiftLocationsTypes)

type Props = {
  isOpen: boolean
  location: APITYPES.Location | null
  onClose: () => void
}

export const EditLocationModal = (props: Props) => {
  const { isOpen, location, onClose } = props
  const dispatch = useDispatch()
  const isNotDistrict = location?.type !== locationKeys.district

  const [editType, setEditType] = useState(EditType.TABS)

  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.LOCATIONS)
  const onTabChange = (newKey: string) => setActiveTab(newKey as TabKeys)

  useEffect(() => {
    if (!location) {return}
    dispatch(fetchLocationChildren(location.id))

    return () => {
      onTabChange(TabKeys.LOCATIONS)
      setEditType(EditType.TABS)
      dispatch(action(Actions.SET_LOCATION_CHILDREN, null))
    }
  }, [location])

  const isTabsMode = editType === EditType.TABS

  return (
    <Modal
      title={
        <Col>
          <Row style={{ gap: 12 }}>
            { isTabsMode ? (location?.name || 'Локация') : 'Редактирование локации' }
            <Tooltip
              placement='right'
              overlay={isTabsMode ? 'Редактировать локацию' : 'Отмена'}
            >
              <Button
                onClick={() => setEditType(isTabsMode ? EditType.EDIT_LOCATION : EditType.TABS)}
                className={styles.editBtn}
                icon={isTabsMode ? <EditOutlined /> : <CloseOutlined />}
              />
            </Tooltip>
          </Row>
          { isTabsMode ? getEditDescription(location) : null }
        </Col>
      }
      visible={isOpen}
      onCancel={onClose}
      width={720}
      destroyOnClose
      footer={false}
      bodyStyle={
        {
          padding: 0,
          height: activeTab !== TabKeys.LOCATIONS || !isTabsMode ? 'auto' : 360,
        }
      }
    >
      {
        isTabsMode
          ? (
            <>
              <Tabs
                size='small'
                activeKey={activeTab}
                tabBarStyle={
                  {
                    padding: '0 24px',
                    margin: 0,
                  }
                }
                onChange={onTabChange}
              >
                <Tabs.TabPane
                  tab='Локации'
                  key={TabKeys.LOCATIONS}
                  style={{ height: '100%' }}
                >
                  {
                    isNotDistrict
                      ? <OnlyForDistrict locationType={location?.type as APITYPES.ShiftLocationsTypesOptions || null} />
                      : <ChildrenLocations locationId={location?.id || null} />
                  }
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab='Добавить'
                  key={TabKeys.ADD}
                  disabled={isNotDistrict}
                >
                  <AddChildrenForm
                    onClose={onClose}
                    locationId={location?.id || null}
                    afterAdd={() => onTabChange(TabKeys.LOCATIONS)}
                    disabled={isNotDistrict}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab='Яндекс.Про'
                  key={TabKeys.YANDEX}
                >
                  <MatchingLocations locationId={location?.id || null} />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab='Яндекс Лавка'
                  key={TabKeys.YANDEX_LAVKA}
                >
                  <MatchingLavkaLocations locationId={location?.id || null} />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab='Urent'
                  key={TabKeys.URENT}
                >
                  <MatchingUrentLocations locationId={location?.id || null} />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab='Фотографий'
                  key={TabKeys.PHOTOS}
                >
                  <Photos locationId={location?.id || null} />
                </Tabs.TabPane>
              </Tabs>
            </>
          )
          : (
            <EditLocation
              location={location}
              onClose={onClose}
            />
          )
      }
    </Modal>
  )
}