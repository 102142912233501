import React from 'react'
import { Divider } from 'antd'

import { APITYPES } from 'types/apitypes'
import styles from '../../styles.module.sass'

import { Report } from './Report'


export const ReportsBlock = ({ reports }: { reports: APITYPES.OperationReports }) => {
  if (!reports) {return null}

  return (
    <div className={styles.blockContainer}>
      <Divider className={styles.divider}><span className={styles.title}>Текстовый отчет</span></Divider>

      <div className={styles.outerReportsWrapper}>
        {
          reports.map((report) => (
            <Report
              key={report?.report_element_id ?? report.task_element_name}
              report={report}
            />
          ))
        }
      </div>
    </div>
  )
}