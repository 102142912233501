import { APITYPES } from 'types/apitypes'


const { Cities } = APITYPES

const getCity = (cityId: number) => {
  if (!cityId) {return '-'}
  const city_id = cityId.toString()
  //@ts-ignore

  return Cities[city_id]
}

export const getColumns = () => ([
  {
    title: 'Тип смены',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '45%',
  },
  {
    title: 'Город',
    dataIndex: 'city_id',
    key: 'city_id',
    width: '30%',
  },
  {
    title: 'Расписание',
    dataIndex: 'schedule',
    key: 'schedule',
    align: 'center',
    width: '25%',
  },
])

export const getRows = (shiftTypes: APITYPES.ShiftType[]) => {
  return shiftTypes.map((type) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'schedule') {
        row[column.dataIndex] = `${type.begin} - ${type.end}`
      } else if (column.dataIndex === 'city_id') {
        //@ts-ignore
        row[column.dataIndex] = getCity(type[column.dataIndex])
      } else {
        //@ts-ignore
        row[column.dataIndex] = type[column.dataIndex]
      }

      return
    })

    row.key = type.id
    row.roles = type.roles

    return row
  })
}

export const getRolesColumns = () => ([
  {
    title: 'Название роли',
    dataIndex: 'name',
    key: 'name',
  },
])

export const getRolesRows = (roles: APITYPES.ShiftTypeRole[]) => {
  return roles.map((role, index) => {
    const row: any = {}

    getRolesColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = role[column.dataIndex]

      return
    })

    row.key = `${role.id}_${index}`

    return row
  })
}
