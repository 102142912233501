import React from 'react'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { deleteCarClassCar } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'
import { DeleteButton } from 'components/DeleteButton'


type Props = {
  isOpen: boolean
  onCancel: () => void
  car: APITYPES.AllCarClasses.GetCars.Resp[number] | null
}

export const CarClassCarDeleteModal = (props: Props) => {
  const { isOpen, onCancel, car } = props

  const dispatch = useDispatch()

  const onDelete = () => {
    dispatch(deleteCarClassCar(car!.car_model_id))
    onCancel()
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={false}
      title='Удалить связь с классом?'
      bodyStyle={{ padding: '16px' }}
      width={400}
      destroyOnClose
    >
      <FormFooter
        onCancel={onCancel}
        okElement={<DeleteButton onClick={onDelete} />}
      />
    </Modal>
  )
}
