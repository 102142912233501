import React from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { action, Actions } from 'actions'
import { addDoerToShift } from 'AC/doers/doerShifts'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'

import { useDoerSearch } from './useDoerSearch'


const formLayout = { labelCol: { span: 5 } }

type Props = {
  isOpen: boolean
  onClose: () => void
  shiftId: string
  shiftRoles: APITYPES.ShiftRole[]
}

export const AddDoerToShiftModal = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { isOpen, onClose, shiftId, shiftRoles } = props

  const {
    onDoerClear,
    onDoerSearch,
    searchedDoers,
    isSearchedDoersLoading,
  } = useDoerSearch()

  const onFinish = (values: any) => {
    dispatch(addDoerToShift({
      shift_id: shiftId,
      doer_id: values.doer_id,
      role_id: values.role_id,
    }))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
    dispatch(action(Actions.SET_SEARCHED_DOERS, []))
  }

  return (
    <Modal
      title='Добавление исполнителя в смену'
      visible={isOpen}
      afterClose={afterClose}
      footer={false}
      onCancel={onClose}
      destroyOnClose
      width={560}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
      >
        <Form.Item
          name='doer_id'
          label='Исполнитель'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }

        >
          <Select
            autoFocus
            showSearch
            onBlur={onDoerClear}
            filterOption={false}
            onClear={onDoerClear}
            onSearch={onDoerSearch}
            autoClearSearchValue={false}
            loading={isSearchedDoersLoading}
            placeholder='Начните поиск по имени исполнителя...'
          >
            {
              searchedDoers.map((doer) => (
                <Select.Option
                  key={doer.id}
                  value={doer.id}
                >
                  { doer.name + ` (${doer.phone_number})` }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Роль'
          name='role_id'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select>
            {
              shiftRoles.map((role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Добавить'
        />
      </Form>
    </Modal>
  )
}
