// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--wrapper--eIsOe{margin:1.5rem 0;overflow:auto}.styles-module--mainSection--qIgGi{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem;max-width:450px}.styles-module--expandedSection--bAx7e{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem;margin-top:1rem}.styles-module--hidden--0457Q{display:none}.styles-module--formItem--tCdcI{margin:0}.styles-module--select--b2urv{min-width:180px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Mailing/components/Filter/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,eAAA,CACA,aAAA,CAEF,mCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CACA,eAAA,CAGF,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CACA,eAAA,CAEF,8BACE,YAAA,CAEF,gCACE,QAAA,CAEF,8BACE,eAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA","sourcesContent":[".wrapper\n  margin: 1.5rem 0\n  overflow: auto\n\n.mainSection\n  display: flex\n  gap: .5rem\n  max-width: 450px\n\n\n.expandedSection\n  display: flex\n  gap: .5rem\n  margin-top: 1rem\n\n.hidden\n  display: none\n\n.formItem\n  margin: 0\n\n.select\n  min-width: 180px\n  width: fit-content"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles-module--wrapper--eIsOe",
	"mainSection": "styles-module--mainSection--qIgGi",
	"expandedSection": "styles-module--expandedSection--bAx7e",
	"hidden": "styles-module--hidden--0457Q",
	"formItem": "styles-module--formItem--tCdcI",
	"select": "styles-module--select--b2urv"
};
export default ___CSS_LOADER_EXPORT___;
