import { APITYPES } from 'types/apitypes'

export const getCardData = (operation: APITYPES.Operation) => {

  return {
    general: {
      title: operation.title,
      doer_name: operation.doer_name,
      grz: operation.grz,
      location: operation.location,
    },
    state: {
      name: operation.state.name,
      created: operation.state.created,
    },
    shift: {
      begin: operation.shift.begin,
      end: operation.shift.end,
      shift_type_name: operation.shift.shift_type_name,
      timezone: operation.timezone
    },
    reports: operation.reports,
  }


}

export type GeneralType = {
  title: string,
  doer_name: string
  grz: string,
  location: {
    id: string
    name: string
    type: string
    city_id: number
  },
}
export type StateType = {
  name: string,
  created: string,
}
export type ShiftType = {
  begin: string,
  end: string,
  shift_type_name: string,
  timezone?: string
}

export type CardDataType = {
  general: GeneralType
  state: StateType
  shift: ShiftType
  reports: APITYPES.OperationReports
}