import moment from 'moment'

import { APITYPES } from 'types/apitypes'


export const getFunction = (functions: APITYPES.DoerTariffFunction[], function_name: string) => {
  const theFunction = functions.find(f => f.name === function_name)

  return theFunction
}

export const getInitDatesState = (start: string | undefined, end: string | undefined) => {
  if (!start || !end) {return undefined}

  return [moment(start), moment(end)]
}