import React,{ FC } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { UnmatchedExecutorsTable } from './components/UnmatchedExecutorsTable'

import styles from './styles.module.sass'


const selector = (state: State) => ({ unmatchedExecutors: state.yandexLavkaReducer.unmatchedExecutors })

interface Props {
  onSubmit: () => void
}
export const MatchingUnmatchedExecutors: FC<Props> = ({ onSubmit }) => {
  const { unmatchedExecutors } = useSelector(selector)

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <UnmatchedExecutorsTable unmatchedExecutors={unmatchedExecutors || []} />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!unmatchedExecutors?.every((item) => item.isMatched)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}