import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { openNotification, errorNotification } from 'components/modals/OperationNotification'
import { showError } from 'components/modals/ErrorModal'
import { ROUTES } from 'config/routes'
import { backDateFormat } from 'utils/dateUtils'


const backEndDateFormat = 'YYYY-MM-DD HH:mm:ss'

export const fetchTasks = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchTasks', StatusRequest.LOADING))

    try {
      // @ts-ignore
      const resp = await api.get(APITYPES.Tasks.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_TASKS, resp))

        dispatch(changeRequestState('fetchTasks', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchTasks', StatusRequest.ERROR))
      throw e
    }
  }
}

export const fetchOperations = (
  filters?: APITYPES.OperationsFilter
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchOperations', StatusRequest.LOADING))

    try {
      // @ts-ignore
      const resp = await api.get(APITYPES.Operations.GET.URL, {
        params: {
          location_id: filters?.location_id ?? undefined,
          task_id: filters?.task_id ?? undefined,
          doer_name: filters?.doer_name ? filters.doer_name : undefined,
          grz: filters?.grz ? filters.grz : undefined,
          state: filters?.state ? filters.state : undefined,
          start:
            filters?.begin && filters?.end
              ? moment(filters.begin).startOf('day').format(backEndDateFormat)
              : undefined,
          end:
            filters?.begin && filters?.end
              ? moment(filters.end).endOf('day').format(backEndDateFormat)
              : undefined,
          offset: getState().operationsReducer.operations.length ?? 0,
          shift_date_from:
            filters?.shift_date_from && filters?.shift_date_to
              ? moment(filters?.shift_date_from).format(backDateFormat)
              : undefined,
          shift_date_to:
            filters?.shift_date_from && filters.shift_date_to
              ? moment(filters.shift_date_to).format(backDateFormat)
              : undefined,
          limit: 60,
        },
      })

      if (resp) {
        dispatch(
          action(Actions.SET_OPERATIONS, {
            operations: resp,
            hasMoreOperations: Boolean(resp.length),
          })
        )

        dispatch(changeRequestState('fetchOperations', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOperations', StatusRequest.ERROR))
      throw e
    }
  }
}

export const fetchOperation = (
  operationId: string,
  type: 'parent' | 'child',
  changeStatus: boolean = true
): ThAction => {
  return async (dispatch, _, { api }) => {
    if (changeStatus) {
      dispatch(changeRequestState('fetchOperation', StatusRequest.LOADING))
    }

    try {
      const resp = await api.get(APITYPES.Operations.GetOperation.URL, { replaceUrl: { operationId } })

      if (resp) {
        dispatch(fetchOperationTasks(operationId))
        dispatch(fetchOperationFiles(operationId))

        if (type === 'parent') {
          dispatch(action(Actions.SET_PARENT_OPERATION, resp))
          dispatch(action(Actions.SET_CHILD_OPERATION, resp))
        } else {
          dispatch(action(Actions.SET_CHILD_OPERATION, resp))
        }

        dispatch(changeRequestState('fetchOperation', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOperation', StatusRequest.ERROR))
      throw e
    }
  }
}

export const editOperation = (
  operationId: string,
  body: APITYPES.Operations.EditOperation.Req,
  type: 'grz' | 'location',
  newLocation?: APITYPES.Location
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('editOperation', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Operations.EditOperation.URL, body, { replaceUrl: { operationId } })

      if (resp) {
        const current = getState().operationsReducer.parentOperation

        if (current) {
          const loc = {
            id: newLocation ? newLocation.id : current.id,
            city_id: newLocation
              ? newLocation.city_id
              : current.location.city_id,
            name: newLocation ? newLocation.name : current.location.name,
            type: newLocation
              ? (newLocation.type as string)
              : current.location.type,
          }

          current.grz = resp.grz
          current.location = loc
        }

        if (type === 'grz') {
          openNotification('Гос. номер изменен')
        } else if (type === 'location') {
          openNotification('Локация изменена')
        } else {
          openNotification('Операция изменена')
        }
        dispatch(changeRequestState('editOperation', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('editOperation', StatusRequest.ERROR))

      // @ts-ignore
      const errorMessage = e?.response?.data
      
      errorNotification(errorMessage)
      throw e
    }
  }
}

export const deleteOperation = (
  operationId: string,
  history: any
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteOperation', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Operations.DeleteOperation.URL, { replaceUrl: { operationId } })

      if (resp) {
        const { parentOperation } = getState().operationsReducer

        if (parentOperation) {
          if (parentOperation.id === operationId) {
            history.push(ROUTES.DOERS.OPERATIONS.path)
            dispatch(action(Actions.CLEAR_OPERATION_TASKS, undefined))
          } else {
            dispatch(fetchOperation(parentOperation.id, 'parent', false))
          }
        }

        dispatch(changeRequestState('deleteOperation', StatusRequest.LOADED))
        openNotification('Операция удалена')
      }
    } catch (e) {
      dispatch(changeRequestState('deleteOperation', StatusRequest.ERROR))
      throw e
    }
  }
}

export const changeOperationState = (
  operationId: string,
  data: {
    type: 'progress' | 'do' | 'cancel' | 'fail'
    value: APITYPES.OperationStateNoTrans
  }
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('changeOperationState', StatusRequest.LOADING))

    try {
      const resp = await api.post(
        APITYPES.Operations.ChangeState.URL,
        {},
        {
          replaceUrl: {
            operationId,
            action: data.type,
          },
        }
      )

      // change current.state when receive response ({name, created}) | whole operation
      if (resp) {
        const current = getState().operationsReducer.currentOperation

        if (current) {
          current.state.name = resp.state
            .name as unknown as APITYPES.OperationStateOptions
          current.state.created = resp.state.created
        }

        openNotification('Статус изменен')
        dispatch(
          changeRequestState('changeOperationState', StatusRequest.LOADED)
        )
      }
    } catch (e) {
      //@ts-ignore
      showError(e.response.data)
      dispatch(changeRequestState('changeOperationState', StatusRequest.ERROR))
      throw e
    }
  }
}

export const fetchOperationTasks = (operationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchOperationTasks', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Operations.GetOperationTasks.URL, { replaceUrl: { operationId } })

      if (resp) {
        dispatch(action(Actions.SET_OPERATION_TASKS, resp))

        dispatch(
          changeRequestState('fetchOperationTasks', StatusRequest.LOADED)
        )
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOperationTasks', StatusRequest.ERROR))
      throw e
    }
  }
}

export const createSuboperation = (
  operationId: string,
  body: APITYPES.Operations.CreateSuboperation.Req
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('createSuboperation', StatusRequest.LOADING))

    try {
      const resp = await api.post(
        APITYPES.Operations.CreateSuboperation.URL,
        body,
        { replaceUrl: { operationId } }
      )

      if (resp) {
        const parent = getState().operationsReducer.parentOperation

        if (parent) {
          dispatch(fetchOperation(parent.id, 'parent', false))
        }

        openNotification('Операция добавлена')
        dispatch(
          changeRequestState('createSuboperation', StatusRequest.LOADED)
        )
      }
    } catch (e) {
      //@ts-ignore
      showError(e.response.data)

      dispatch(changeRequestState('createSuboperation', StatusRequest.ERROR))
      throw e
    }
  }
}

export const fetchOperationLocations = (
  search: string | undefined
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(
      changeRequestState('fetchOperationLocations', StatusRequest.LOADING)
    )

    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.GET.URL, { params: { search } })

      if (resp) {
        dispatch(action(Actions.SET_OPERATION_LOCATIONS_SEARCH, resp))

        dispatch(
          changeRequestState('fetchOperationLocations', StatusRequest.LOADED)
        )
      }
    } catch (e) {
      dispatch(
        changeRequestState('fetchOperationLocations', StatusRequest.ERROR)
      )
      throw e
    }
  }
}

export const createOperationOrders = (
  body: APITYPES.Operations.CreateOperationOrders.Req
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(
      changeRequestState('createOperationOrders', StatusRequest.LOADING)
    )

    try {
      const resp = await api.post(
        APITYPES.Operations.CreateOperationOrders.URL,
        body
      )

      if (resp) {
        // openNotification('Создание заказов для операций завершилось.')
        dispatch(
          changeRequestState('createOperationOrders', StatusRequest.LOADED)
        )
        dispatch(fetchBillSyncState())
      }
    } catch (e) {
      //@ts-ignore
      showError(e.response.data)

      dispatch(
        changeRequestState('createOperationOrders', StatusRequest.ERROR)
      )
      throw e
    }
  }
}

export const closeOperationOrders = (
  body: APITYPES.Operations.CloseOperationOrders.Req
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(
      changeRequestState('closeOperationOrders', StatusRequest.LOADING)
    )

    try {
      const resp = await api.post(
        APITYPES.Operations.CloseOperationOrders.URL,
        body
      )

      if (resp) {
        // openNotification('Создание заказов для операций завершилось.')
        dispatch(
          changeRequestState('closeOperationOrders', StatusRequest.LOADED)
        )
        dispatch(fetchBillSyncState())
      }
    } catch (e) {
      //@ts-ignore
      showError(e.response.data)

      dispatch(
        changeRequestState('closeOperationOrders', StatusRequest.ERROR)
      )
      throw e
    }
  }
}

export const fetchBillSyncState = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchBillSyncState', StatusRequest.LOADING))
    try {
      const resp = await api.get(
        APITYPES.Operations.GetOperationsSyncState.URL
      )

      if (resp) {
        dispatch(
          changeRequestState('fetchBillSyncState', StatusRequest.LOADED)
        )
        dispatch(action(Actions.SET_OPERATION_SYNC, resp))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchBillSyncState', StatusRequest.ERROR))
      throw e
    }
  }
}

export const fetchOperationFiles = (operationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchOperationFiles', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Operations.GetOperationFiles.URL, { replaceUrl: { operationId } })

      if (resp) {
        dispatch(action(Actions.SET_OPERATION_FILES, resp))

        dispatch(
          changeRequestState('fetchOperationFiles', StatusRequest.LOADED)
        )
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOperationFiles', StatusRequest.ERROR))
      throw e
    }
  }
}
