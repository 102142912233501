import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Input, Button } from 'antd'
import { useHistory } from 'react-router'

import { State } from 'stores/rootReducer'
import { Loader } from 'components/Loader'
import { InfiniteTable } from 'components/InfiniteTable'
import { StatusRequest } from 'stores/requestsReducer'
import { getContractors } from 'AC/billing/getContractors'
import { action, actionEmpty, Actions } from 'actions'
import { useDebounce } from 'utils/debounce'
import { ROUTES } from 'config/routes'
import { isObjectEmpty } from 'utils/filterUtils'
import { FilterIcon } from 'components/FilterIcon'

import { Filters } from './Filters'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const { Search } = Input

const selector = (state: State) => {
  const { contractorsHasMore, contractors, contractorsFilter } = state.billingReducer
  const { getContractors } = state.requestsReducer

  return {
    hasMore: contractorsHasMore,
    contractors,
    isLoading: getContractors === StatusRequest.LOADING,
    filter: contractorsFilter,
  }
}

export const Contractors = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { hasMore, isLoading, contractors, filter } = useSelector(selector)
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const isFirstRun = useRef(true)
  const debouncedSearchTerm = useDebounce(filter.search, 500)

  const [filterDrawer, setFilterDrawer] = useState(false)
  const toggleFilter = () => setFilterDrawer((prev) => !prev)

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      dispatch(action(Actions.SET_CONTRACTORS_FILTER, params))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.BILLING.CONTRACTORS.PARAMS.createPath(filter))
    }
  }, [])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      
      return
    }
    if (isLoading) {return}

    dispatch(actionEmpty(Actions.CLEAN_CONTRACTORS))
    dispatch(getContractors())
    history.push(ROUTES.BILLING.CONTRACTORS.PARAMS.createPath(filter))
  }, [debouncedSearchTerm, filter.type])

  useEffect(() => {
    if (!contractors.length) {dispatch(getContractors())}
  }, [])

  const loadMore = () => {
    if (contractors.length > 0 && !isLoading) {
      dispatch(getContractors())
    }
  }

  if (!contractors) {return <Loader />}

  const onSearch = (value: string) => {
    const state = value.length ? value : undefined

    dispatch(action(Actions.SET_CONTRACTORS_FILTER, {
      ...filter,
      search: state,
    }))
  }

  const onSearchChange = (event: any) => {
    const value = event.target.value ? event.target.value : undefined

    dispatch(action(Actions.SET_CONTRACTORS_FILTER, {
      ...filter,
      search: value,
    }))
  }

  return (
    <div className={styles.main}>
      <div className={styles.toolbar}>
        <Search
          allowClear
          value={filter.search}
          onChange={onSearchChange}
          placeholder='Поиск по имени, адресу, ИНН, телефону'
          onSearch={onSearch}
          enterButton
        />
        <Button
          onClick={toggleFilter}
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
        />
      </div>
      <InfiniteTable
        columns={getColumns()}
        isLoading={isLoading}
        hasMore={hasMore}
        className={styles.tableContainer}
        loadMore={loadMore}
      >
        <Table
          showHeader={false}
          columns={getColumns() as any}
          className={styles.table}
          dataSource={getRows(contractors)}
          bordered
          size='large'
          scroll={{ x: '100%' }}
          pagination={false}
        />
      </InfiniteTable>

      <Filters
        isOpen={filterDrawer}
        onClose={toggleFilter}
      />
    </div>
  )
}