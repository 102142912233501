import { notification } from 'antd'


export const openNotification = (description: string) => {
  notification.info({
    message: description,
    description: '',
    placement: 'bottomRight',
  })
}

export const successNotification = (description: string) => {
  notification.success({
    message: description,
    description: '',
    placement: 'bottomRight',
  })
}

export const errorNotification = (description: string) => {
  notification.error({
    message: description,
    description: '',
    placement: 'bottomRight',
  })
}