import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Input } from 'antd'

import { createTariff, deleteTariff, updateTariff } from 'AC/carwashes/carwashes'
import { State } from 'stores/rootReducer'

import styles from './styles.module.sass'


type Props = {
  value: number | undefined
  record: any
  carClassId: string
  carwashId: string
  clientTypeId: string
}

const selector = (state: State) => {
  const { services, tariffs, error } = state.carwashesReducer
  
  return {
    services,
    tariffs,
    error,
  }
}

export const TariffInput = (props: Props) => {
  const { value, record, carClassId, carwashId, clientTypeId } = props

  const dispatch = useDispatch()

  const { services, tariffs, error } = useSelector(selector)

  const [ isCreated, setIsCreated ] = useState(false)

  useEffect(()=>{
    setIsCreated(value !== undefined)
  }, [value])

  const [ inputValue, setInputValue ] = useState<any>(value === undefined ? '' : value)

  const onChange = (event: any) => {
    if (!isNaN(event.target.value)){
      setInputValue(event.target.value)
    }
  }

  const onBlur = (event: any) => {
    const currentValue = event.target.value

    const serviceId = services.find((service) => service.name === record.service)?.id

    if (!serviceId) {return}

    if (isCreated && !currentValue.length) {
      const tariff = tariffs?.find((tariff) => tariff.car_class_id === carClassId && tariff.service_id === serviceId)
        
      if (!tariff) {return}

      dispatch(deleteTariff(tariff.supplier_tariff_service_id, carwashId, clientTypeId))

      setIsCreated(false)

      return
    }

    if (!Number(currentValue)) {
      setInputValue(undefined)
      setIsCreated(false)

      const tariff = tariffs?.find((tariff) => tariff.car_class_id === carClassId && tariff.service_id === serviceId)
        
      if (!tariff) {return}

      if (isCreated) {
        dispatch(deleteTariff(tariff.supplier_tariff_service_id, carwashId, clientTypeId))
      }

      return
    }

    if (!isCreated && currentValue.length) {
      dispatch(createTariff({
        carwash_id:	carwashId,
        client_type_id: clientTypeId,
        car_class_id:	carClassId,
        service_id: serviceId,
        cost: Number(currentValue),
      }))

      setIsCreated(true)

      return
    }

    if (isCreated && currentValue.length) {
      const tariff = tariffs?.find((tariff) => tariff.car_class_id === carClassId && tariff.service_id === serviceId)
        
      if (!tariff) {return}

      dispatch(updateTariff({ cost: Number(currentValue) }, tariff.supplier_tariff_service_id, carwashId, clientTypeId))
    
      setIsCreated(true)
    }
  }

  const serviceId = services.find((service) => service.name === record.service)?.id

  return (
    <Input
      className={
        classNames(error && error.serviceId === serviceId && error.carClassId === carClassId && styles.error,
          styles.input)
      }
      value={inputValue}
      onBlur={onBlur}
      onChange={onChange}
      key={carClassId + record.name}
    />
  )
}