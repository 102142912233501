import React from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { addCarClassCar } from 'AC/directory/directory'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'
import { getGroupTag } from '../../../../utils'
import styles from '../styles.module.sass'


type Props = {
  isVisible: boolean
  onCancel: () => void
  carClasses: APITYPES.AllCarClasses.Get.Resp | []
  carModel: APITYPES.Cars.GetModelInfo.Resp
}

export const AddForm = (props: Props) => {
  const dispatch = useDispatch()
  const [ form ] = Form.useForm()
  const { carClasses, isVisible, onCancel, carModel } = props
  const { car_classes = [] } = carModel

  const filteredCarClasses = (): APITYPES.AllCarClasses.Get.Resp => {
    const simpleVals = car_classes?.map((el) => el.id)

    
    return carClasses.filter((el) => !simpleVals?.includes(el.id))
  }

  const onFinish = async (values: any) => {
    if (!values.name) {return}
    const carClass = carClasses.find((item)=>item.name === values.name)

    if (!carClass) {return}
    dispatch(addCarClassCar(carModel.id, carClass.id))
    onCancel()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Добавление класса автомобиля'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      width={620}
      afterClose={afterClose}
      destroyOnClose
    >
      <Form
        name='car_classes'
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='name'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          label='Класс автомобиля'
        >
          <Select
            showSearch
            filterOption={
              (input, option) =>
                //@ts-ignore
                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              filteredCarClasses()?.map((carClass)=>{
                return (
                  <Select.Option
                    key={carClass.id}
                    value={carClass.name}
                  >
                    <div className={styles.selectOption}>
                      { carClass.name }
                      { getGroupTag(carClass.group) }
                    </div>
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Добавить'
          position='right'
        />
      </Form>
    </Modal>
  )
}