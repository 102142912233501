import React, { useMemo } from 'react'
import { Select } from 'antd'


export type SelectReportProps = {
  options: string[]
  mode?: 'multiple'
  handleChange: (value: string[]) => void
  defaultValue: string[]
}

export const SelectReport = ({ options, defaultValue, mode, handleChange }: SelectReportProps) => {
  const optionsWithLabel = useMemo(() => options.map((option) => ({
    label: option,
    value: option,
  })), [options])
  
  return (
    <Select
      mode={mode}
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      onChange={handleChange}
      options={optionsWithLabel}
      listItemHeight={10}
      listHeight={250}
    />
  )
}
