import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { api } from 'index'
import { APITYPES } from 'types/apitypes'


export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
  defaultOptions?: ValueType[]
}

export function DebounceSelect<
  ValueType extends { key?: string, label: React.ReactNode, value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, defaultOptions, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  useEffect(() => {
    if (Array.isArray(defaultOptions)) {
      setOptions(defaultOptions)
    }
  }, [defaultOptions])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current

      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      {...props}
      options={options}
    />
  )
}

// Usage of DebounceSelect
export interface SelectValue {
  label: string
  value: string
}

export async function fetchUserList(username: string): Promise<SelectValue[]> {
  const resp = await api.get(APITYPES.Doers.Get.URL,
    { params: { name: username } })

  return resp?.map(({ id, name, phone_number }: {id: string, name: string, phone_number: string}) => ({
    label: `${name} (${phone_number})`,
    value: id,
  }))
}
