import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type MotivationsFilterTab = {
  id: string
  name: string
  motivationType: APITYPES.MOTIVATION_TYPE
}

export type MotivationsState = {
  doerMotivations: APITYPES.Performers.GetDoerMotivations.Resp[]
  penalties: APITYPES.Performers.GetPenalties.Resp[]
  hasMore: boolean
  doerMotivationsFilter: APITYPES.Performers.GetDoerMotivations.Req

  //penalty tab
  doerPenalties: {
    [key: string]: APITYPES.Performers.GetDoerPenalty.Resp | null
  }

  doerCompensations: {
    [key: string]: APITYPES.Performers.GetDoerCompensation.Resp | null
  }

  activeTabId: string
  filterTabs: MotivationsFilterTab[]
}

export const DEFAULT_MOTIVATION_TAB_KEY = 'table'

export const initialState: MotivationsState = {
  doerMotivations: [],
  penalties: [],
  hasMore: false,
  doerMotivationsFilter: {},

  doerPenalties: {},
  doerCompensations: {},

  activeTabId: DEFAULT_MOTIVATION_TAB_KEY,
  filterTabs: [],
}

export const motivationsReducer: Reducer<MotivationsState, Action> = (
  state = initialState,
  a
): MotivationsState => {
  switch (a.type) {
    case Actions.CLEAN_DOER_MOTIVATIONS: {
      return {
        ...state,
        doerMotivations: [],
        hasMore: false,
      }
    }

    case Actions.SET_DOER_MOTIVATIONS: {
      return {
        ...state,
        doerMotivations: [...state.doerMotivations, ...a.data.doerMotivations],
        hasMore: a.data.hasMore,
      }
    }

    case Actions.SET_DOER_MOTIVATIONS_FILTER: {
      return {
        ...state,
        doerMotivationsFilter: a.data,
      }
    }
    
    case Actions.SET_PERFORMACE_PENALTIES: {
      return {
        ...state,
        penalties: a.data,
      }
    }

    case Actions.SET_DOER_PENALTY: {
      return {
        ...state,
        doerPenalties: {
          ...state.doerPenalties,
          [a.data.id]: a.data,
        },
      }
    }

    case Actions.SET_DOER_COMPENSATION: {
      return {
        ...state,
        doerCompensations: {
          ...state.doerCompensations,
          [a.data.id]: a.data,
        },
      }
    }

    case Actions.ADD_MOTIVATIONS_FILTER_TAB: {
      const newFilterTab: MotivationsFilterTab = {
        id: a.data.id,
        name: a.data.title,
        motivationType: a.data.motivationType,
      }

      return {
        ...state,
        filterTabs: [...state.filterTabs, newFilterTab],
        activeTabId: a.data.id,
      }
    }
    case Actions.DELETE_MOTIVATIONS_FILTER_TAB: {
      const newFilterTabs = state.filterTabs.filter((filterTab) => filterTab.id !== a.data.id)

      delete state.doerCompensations[a.data.id]
      delete state.doerPenalties[a.data.id]

      return {
        ...state,
        filterTabs: newFilterTabs,
      }
    }
    case Actions.SET_ACTIVE_MOTIVATIONS_TAB: {
      return {
        ...state,
        activeTabId: a.data.id,
      }
    }
  }

  return state
}
