import React from 'react'

import { APITYPES } from 'types/apitypes'


export enum ContactsType {
  PHONES,
  EMAILS,
}

const columns = [
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '20%',
  },
  {
    title: 'Должность',
    dataIndex: 'role',
    key: 'role',
    width: '10%',
  },
  {
    title: 'Телефон',
    dataIndex: 'phones',
    key: 'phones',
    width: '20%',
    render: (value: APITYPES.CarwashEmployee) => {
      return (
        <>
          {
            value.phones?.map((phone)=>(
              <span key={phone}>
                { phone }
                <br />
              </span>
            ))
          }
        </>
      )
    },
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
]

export const getColumns = () => columns

export const getRows = (employees: APITYPES.CarwashEmployee[]) => {
  const data = employees.map((item) => {
    const row: any = {}

    columns.map((column) => {
      //@ts-ignore
      row[column.dataIndex] = item[column.dataIndex]
      row.key = item.id

      return
    })

    row.phones = item

    return row
  })

  return data
}