import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import { getBills } from './getBills'


export const createBill = (values: APITYPES.Billing.CreateBill.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createBill', StatusRequest.LOADING))
        
    try {
      const resp = await api.post(APITYPES.Billing.CreateBill.URL, values)
      
      if (resp) {
        dispatch(getBills({
          limit: 20,
          offset: 0,
        }, 'partner'))
        openNotification('Счет сформирован')

        dispatch(changeRequestState('createBill', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createBill', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}