import React from 'react'
import { DatePicker } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'

import { Status } from './Status'


type Filter = {
  state?: string[]
  begin?: string
  end?: string
}

export const getColumns = (filter?: Filter) => {
  const filterDates = (Boolean(filter?.begin) && Boolean(filter?.end))
    ? [moment(filter?.begin), moment(filter?.end)]
    : undefined

  
  return (
    [
      {
        title: 'Штраф',
        dataIndex: 'name',
        key: 'name',
        width: 270,
      },
      {
        title: 'Сумма',
        dataIndex: 'value',
        key: 'value',
        width: 130,
        align: 'center',
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        width: 140,
        align: 'center',
        filters: [
          {
            text: 'Новый',
            value: 'new',
          },
          {
            text: 'Согласен',
            value: 'agreed',
          },
          {
            text: 'Не согласен',
            value: 'disagreed',
          },
          {
            text: 'Отменен',
            value: 'canceled',
          },
          {
            text: 'Учтен',
            value: 'applied',
          },
        ],
        defaultFilteredValue: filter?.state ?? undefined,
        render: (penalty: APITYPES.CarwashPenalty) => (
          <Status penalty={penalty} />
        ),
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: 'Выставлен',
        dataIndex: 'created',
        key: 'created',
        width: 250,
        align: 'center',
        defaultFilteredValue: filterDates ?? undefined,
        filterDropdown: ({ clearFilters, confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
          const onDatePick = (dates: [moment.Moment, moment.Moment]) => {
            if (!dates) {
              if (clearFilters) {
                clearFilters()
              }
              confirm({ closeDropdown: true })
              
              return
            }
            //@ts-ignore
            setSelectedKeys([dates[0], dates[1]])
            confirm({ closeDropdown: true })
          }
          
          return (
            //@ts-ignore
            <DatePicker.RangePicker
              onChange={onDatePick}
              format='DD.MM.YYYY'
              value={selectedKeys}
            />
          )
        },
      },
    ]
  )
}

export const getRows = (penalties: APITYPES.Carwashes.GetPenalties.Resp) => {
  const data = penalties.map((penalty) => {
    const row: any = {}

    getColumns().forEach((column) => {
      if (column.dataIndex === 'status') { return } else if (column.dataIndex === 'comment') {
        row[column.dataIndex] = penalty.state.comment
      } else if (column.dataIndex === 'created'){
        // @ts-ignore
        row[column.dataIndex] = getFormattedDate(penalty.state[column.dataIndex])
      } else {
        // @ts-ignore
        row[column.dataIndex] = penalty[column.dataIndex]
      }
    })
    row.status = penalty
    row.key = penalty.id

    return row
  })

    
  return data
}
