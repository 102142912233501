import React, { FC } from 'react'
import moment from 'moment/moment'
import { DatePicker, Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { useFilterDrawerContext } from '../../../context'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { importShift } from 'AC/shifts/shifts'
import { FormFooter } from 'components/FormFooter'
import { backDateFormat, dateTimezoneFormat } from 'utils/dateUtils'
import { APITYPES, MomentType } from 'types'
import { fullWidth, required } from 'utils/formUtils'


const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 19 },
}

const selector = (state: State) => ({ isLoading: state.requestsReducer.importShift === StatusRequest.LOADING })

type FormValues = {
  date: [MomentType, MomentType]
  source: string
  time: MomentType
}
type ImportFormPropsType = {
  isInternship?: boolean
  onSuccess: () => void
}
export const ImportForm: FC<ImportFormPropsType> = ({ isInternship, onSuccess }) => {
  const { toggleAddShiftModal: onClose } = useFilterDrawerContext()
  const { isLoading } = useSelector(selector)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: FormValues) => {
    const [startDate, endDate] = values.date
    const sheet_id = values.source?.split('d/')[1]?.split('/')[0]

    const body: APITYPES.Shifts.Shift.POST.ReqImport = {
      date_from: startDate.format(backDateFormat),
      date_to: endDate.format(backDateFormat),
      is_intership: Boolean(isInternship),
      spreadsheet_id: sheet_id ?? '',
      method: 'import' as const,
    }

    if (values.time) {
      body.time = values.time?.format(dateTimezoneFormat)
    }
    dispatch(importShift(body, onSuccess))
  }


  return (
    <Form
      {...formLayout}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='date'
        label='Период'
        rules={required}
        initialValue={null}
      >
        <DatePicker.RangePicker
          format='LL'
          style={fullWidth}
        />
      </Form.Item>

      <Form.Item
        label='Отложенная публикация'
        name='time'
        initialValue={null}
      >
        <DatePicker
          format='LLL'
          showTime={
            {
              showHour: true,
              showMinute: true,
            }
          }
          style={fullWidth}
          disabledDate={(date) =>  date.isBefore(moment())}
        />
      </Form.Item>

      <Form.Item
        name='source'
        label='Источник'
        rules={required}
      >
        <Input />
      </Form.Item>

      <FormFooter
        onCancel={onClose}
        okText='Импортировать'
        isLoading={isLoading}
      />
    </Form>
  )
}