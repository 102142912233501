// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--tabs--Tc7\\+t{margin-top:-16px}.styles-module--filter--d5r3X{margin-right:12px}.styles-module--filter--d5r3X{cursor:pointer;-webkit-transition:.2s;transition:.2s}.styles-module--filter--d5r3X:hover{color:#1890ff}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftsPage/components/styles.module.sass","webpack://./src/styles/vars.sass"],"names":[],"mappings":"AAEA,6BACE,gBAAA,CAEF,8BACE,iBAAA,CAEF,8BACE,cAAA,CACA,sBAAA,CAAA,cAAA,CACA,oCACE,aCPS","sourcesContent":["@import 'src/styles/vars'\n\n.tabs\n  margin-top: -16px\n\n.filter\n  margin-right: 12px\n\n.filter\n  cursor: pointer\n  transition: 0.2s\n  &:hover\n    color: $active-tab\n","$fontFamilyDefault: 'Roboto', -apple-system, BlinkMacSystemFont, \"Segoe UI\", sans-serif\n\n$white: #FCFCFD\n\n$blue: #0066FF\n$active-tab: #1890FFFF\n$grayish: #FBFBFB\n\n$red: #F6768E\n$green: #52C41A\n\n$yellow: #ffc600\n$orange: #FFA500\n\n$blackMain: #333745\n$black900: #161616\n$black700: #1F2129\n$black500: #333745\n$black400: #5C5F6A\n$black300: #85878F\n$black200: #ADAFB5\n\n$gray100: #F2F2F2\n$gray200: #989898\n$gray300: #828282\n$gray500: #676767\n$gray700: #464646\n$lightGray: #AAA"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "styles-module--tabs--Tc7+t",
	"filter": "styles-module--filter--d5r3X"
};
export default ___CSS_LOADER_EXPORT___;
