import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Modal, Tag } from 'antd'
import { useParams } from 'react-router-dom'

import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'
import { cancelVacancyById } from '../../../../../../AC/doers/doerVacancies'
import { State } from '../../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../../stores/requestsReducer'


type Props = {
  open: boolean
  onCancel: () => void
  initialState: APITYPES.DoerVacancy['state']
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const selector = (state: State) => {
  const isOpening = state.requestsReducer.openVacancyById === StatusRequest.LOADING
  const isCanceling = state.requestsReducer.cancelVacancyById === StatusRequest.LOADING
  
  return { isLoading: isOpening || isCanceling }
}


export const ChangeStatusModal = ({ open, onCancel, initialState }: Props) => {
  const dispatch = useDispatch()
  const { vacancyId } = useParams<{ vacancyId: string }>()
  const { isLoading } = useSelector(selector)

  const onSubmit = (values: { comment: string }) => {
    const { comment } = values

    if (initialState === 'open') {
      dispatch(cancelVacancyById({
        comment,
        vacancy_id: vacancyId,
      }, onCancel))
    }
  }

  return (
    <Modal
      title='Изменение статуса'
      open={open}
      onCancel={onCancel}
      width={530}
      footer={false}
      key={`${open}`}
    >
      <Form
        {...formItemLayout}
        onFinish={onSubmit}
      >
        <Form.Item
          label='Состояние'
        >
          <Tag
            color='gray'
          >
            Отменить
          </Tag>
        </Form.Item>
        <Form.Item
          name='comment'
          label='Коментарий'
          initialValue=''
        >
          <Input.TextArea
            maxLength={250}
            autoSize={
              {
                minRows: 3,
                maxRows: 6,
              }
            }
          />
        </Form.Item>
        <FormFooter
          isLoading={isLoading}
          onCancel={onCancel}
          okText='Изменить'
        />
      </Form>
    </Modal>
  )
}