// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--actions--X4S4v{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;margin-top:10px;gap:1rem}.styles-module--actions--X4S4v Button{margin:0}.styles-module--formItem--UbS58{margin-bottom:.5rem}.styles-module--card--ZU3sK [class=ant-card-body]{overflow-y:auto;max-height:75vh}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/DoerRecords/Filters/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,6BAAA,CAAA,8BAAA,CAAA,0BAAA,CACA,eAAA,CACA,QAAA,CACA,sCACE,QAAA,CAEJ,gCACE,mBAAA,CAGA,kDACE,eAAA,CACA,eAAA","sourcesContent":[".actions\n  display: flex\n  flex-direction: row-reverse\n  margin-top: 10px\n  gap: 1rem\n  Button\n    margin: 0\n\n.formItem\n  margin-bottom: 0.5rem\n\n.card\n  & [class='ant-card-body']\n    overflow-y: auto\n    max-height: 75vh"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "styles-module--actions--X4S4v",
	"formItem": "styles-module--formItem--UbS58",
	"card": "styles-module--card--ZU3sK"
};
export default ___CSS_LOADER_EXPORT___;
