import React from 'react'
import { useDispatch } from 'react-redux'

import { DeleteButton } from 'components/DeleteButton'
import { APITYPES } from 'types/apitypes'
import { deleteDocument } from 'AC/partners/deleteDocument'
import { showSureConfirm } from 'components/modals/SureModal'


const columns = [
  {
    title: 'Тип договора',
    dataIndex: 'type',
    key: 'type',
    fixed: 'left',
  },
  {
    title: '№ договора',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Дата заключения договора',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Ссылка на документ',
    dataIndex: 'link',
    key: 'link',
  },
  {
    title: 'Порядок оплатыа',
    dataIndex: 'payment_type',
    key: 'payment_type',
  },
  {
    title: 'Периодичность актирования',
    dataIndex: 'act_frequency',
    key: 'act_frequency',
  },
  {
    title: 'Период отсрочки платежа',
    dataIndex: 'payment_delay_period',
    key: 'payment_delay_period',
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
    key: 'actions',
    render: (value: APITYPES.PartnerDocument) => {
      const dispatch = useDispatch()

      const onDelete = (e: any) => {
        e.stopPropagation()
        showSureConfirm(()=>{dispatch(deleteDocument(value.id))},()=>{})
      }

      return (
        <DeleteButton
          onClick={onDelete}
        />
      )
    },
  },
]

export const getColumns = () => columns

export const getRows = (documents: APITYPES.PartnerDocument[]) => {
  const data = documents.map((document) => {
    const row: any = {}

    columns.map((column) => {
      if (column.dataIndex === 'actions') { return }
      //@ts-ignore
      row[column.dataIndex] = document[column.dataIndex]

      return
    })
    row.key = document.id
    row.actions = document

    return row
  })

  return data
}