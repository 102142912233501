import React from 'react'

import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from '../../../../../../utils/dateUtils'
import { getCityName } from '../../../../../../utils/enumUtils'
import { Status } from '../../Status'
import {
  getDoerCompensation,
  updateCompensationStatus,
} from '../../../../../../AC/doers/penalties'


const handleChangeStatus = (comment: string, status: string, id: string) => {
  return updateCompensationStatus({
    compensation_id: id,
    new_status: status,
  }, { comment },
  () => getDoerCompensation(id))
}

export const getGeneralData = (compensation: APITYPES.DoerCompensation) => {
  return [
    {
      label: 'Статус:',
      value: (
        <Status
          modalTitle='Вы точно хотите изменить статус бонуса?'
          motivation={compensation}
          onCallback={(comment, status) => handleChangeStatus(comment, status, compensation.id)}
        />
      ),
    },
    {
      label: 'Сумма:',
      value: compensation.value,
    },
    {
      label: 'Инициатор:',
      value: compensation?.initiator_name,
    },
    {
      label: 'Время:',
      value: getFormattedDate(compensation.time),
    },
  ]
}

export const getPerformerData = (compensation: APITYPES.DoerCompensation) => ([
  {
    label: 'Роль:',
    value: compensation?.role_name,
  },
  {
    label: 'Город:',
    value: getCityName(compensation.city_id),
  },
  {
    label: 'Группа ролей:',
    value: compensation?.role_group_name,
  },
  {
    label: 'Департамент:',
    value: compensation?.role_department_name,
  },
])