import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from '../actions'


export type DoerTariffsState = {
  doerTariffs: APITYPES.DoerTariffNoTasks[]
  doerTariff: APITYPES.DoerTariff | null
  doerTariffTasks: APITYPES.DoerTariffTask[]
  doerTariffShiftTypes: APITYPES.DoerTariffShiftType[]
  doerTariffsFilter: APITYPES.DoerTariffFilter

  doerTariffFunctions: APITYPES.DoerTariffFunction[]
  doerTariffsGroups: APITYPES.DoerTariffGroups[] | null
  billSyncStateV2: APITYPES.BillSyncState
}

export const initialDoerTariffsState = {
  doerTariffs: [],
  doerTariff: null,
  doerTariffTasks: [],
  doerTariffShiftTypes: [],
  doerTariffsFilter: {},
  doerTariffFunctions: [],
  doerTariffsGroups: [],
  billSyncStateV2: {
    start: '',
    stop: '',
    period: '',
    state: '',
  },
}

export const doerTariffsReducer: Reducer<DoerTariffsState, Action> =
(state = initialDoerTariffsState, a): DoerTariffsState => {
  switch (a.type) {
    case Actions.SET_DOER_TARIFF_FUNCTIONS: {
      return {
        ...state,
        doerTariffFunctions: a.data,
      }
    }

    case Actions.SET_DOER_TARIFF_TASKS: {
      return {
        ...state,
        doerTariffTasks: a.data,
      }
    }

    case Actions.SET_DOER_TARIFF_SHIFT_TYPES: {
      return {
        ...state,
        doerTariffShiftTypes: a.data,
      }
    }

    case Actions.SET_DOER_TARIFF: {
      return {
        ...state,
        doerTariff: a.data,
      }
    }

    case Actions.SET_DOER_TARIFFS_FILTER: {
      return {
        ...state,
        doerTariffsFilter: a.data,
      }
    }

    case Actions.SET_DOER_TARIFFS: {
      return {
        ...state,
        doerTariffs: a.data,
      }
    }

    case Actions.SET_DOER_TARIFFS_GROUPS: {
      return {
        ...state,
        doerTariffsGroups: a.data,
      }
    }

    case Actions.CLEAN_DOER_TARIFFS_GROUPS: {
      return {
        ...state,
        doerTariffsGroups: [],
      }
    }

    case Actions.SET_TARIFFS_SYNC: {
      return {
        ...state,
        billSyncStateV2: a.data,
      }
    }
  }

  return state
}