import React, { FC, useState } from 'react'
import { Form, InputNumber } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { FormFooter } from 'components/FormFooter'
import { fullWidth, required } from 'utils/formUtils'
import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types'
import { changePlaceInternshipsShift } from 'AC/internships/internships'


type EditCountInternshipFormProps = {
  shifts: APITYPES.Internships.InternshipsShift[] | null
  onClose: () => void
}

type FormValues = Record<string, number>

const selector = (state: State) => {
  return { isLoading: state.requestsReducer.editInternshipCount === StatusRequest.LOADING }
}

export const EditInternshipShiftsForm: FC<EditCountInternshipFormProps> = ({ shifts, onClose }) => {
  const [form] = Form.useForm<FormValues>()
  const { isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const initialValues =  shifts?.reduce((acc: FormValues, shift) => {
    acc[shift.id ] = shift.places

    return acc
  }, {})

  const onFinish = (values: FormValues) => {
    if (!shifts) {
      return
    }
    dispatch(changePlaceInternshipsShift(
      shifts.map((shift) => ({
        ...shift,
        places: values[shift.id],
      })),
      onClose
    ))
  }

  const onValuesChange =  () => {
    const hasEmptyFields = Object.values(form.getFieldsValue()).some((value) => !value)
    const equalToInitialValues = JSON.stringify(form.getFieldsValue()) === JSON.stringify(initialValues)

    setIsSubmitDisabled(hasEmptyFields || equalToInitialValues)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      {
        shifts?.map((shift, index) => (
          <Form.Item
            key={index}
            label={shift.location}
            name={shift.id}
            rules={required}
          >
            <InputNumber
              style={fullWidth}
              min={0}
              size='small'
            />
          </Form.Item>
        ))
      }
      <FormFooter
        isLoading={isLoading}
        onCancel={onClose}
        confirmDisabled={isSubmitDisabled}
        okText='Сохранить'
      />
    </Form>
  )
}
