import React, {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom'
import {
  Button,
  Form,
  PageHeader,
  Select,
  Switch,
  Table,
  Tabs,
} from 'antd'
import { compact } from 'lodash'

import {
  fetchCarwash,
  fetchTableByClientType,
  fetchTables,
  replacePartner,
} from 'AC/carwashes/carwashes'
import { switchClientType } from 'AC/carwashes/switchClientType'
import {
  action,
  Actions,
} from 'actions'
import { Loader } from 'components/Loader'
import { ROUTES } from 'config/routes'
import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { Status } from '../Status'
import { getSchedules } from 'AC/carwashes/schedules'
import { fetchClientTypes } from 'AC/directory/directory'
import { fetchPartners } from 'AC/partners/partners'

import { CarwashInfo } from './CarwashInfo'
import { CopyForm } from './Copy'
import { Location } from './Location'
import { Employees } from './Employees'
import { Photos } from './Photos'
import {
  createColumns,
  createTable,
} from './utils'
import { Properties } from './Properties'
import { Group } from './Group'
import { WorkingTime } from './WorkingTime'
import { Penalties } from './Penalties'
import { History } from './History'
import styles from './styles.module.sass'
import { Compensation } from './Compensation'


const { TabPane } = Tabs

const selector = (state: State) => {
  const { carwash, tariffs, clientTypes, carClasses, services, carwashesFilter } =
    state.carwashesReducer
  const { fetchCarwash, switchClientType } = state.requestsReducer
  const { allPartners } = state.partnersReducer

  return {
    filter: carwashesFilter,
    carwash,
    tariffs,
    isLoading: fetchCarwash === StatusRequest.LOADING,
    isSwitchLoading: switchClientType === StatusRequest.LOADING,
    clientTypes,
    carClasses,
    services,
    partners: allPartners,
  }
}

export const Carwash = () => {
  const params = useParams<{ id: string, search: string | undefined }>()
  const { id } = params
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    carwash,
    tariffs,
    clientTypes,
    carClasses,
    services,
    isSwitchLoading,
    filter,
    partners,
  } = useSelector(selector)

  const { rental_object_type } = carwash ?? {}
  const {
    has_location,
    has_employees,
    has_schedule,
  } = rental_object_type ?? {}

  const [isCopyModal, setIsCopyModal] = useState(false)

  useEffect(() => {
    if (!clientTypes) {
      dispatch(fetchClientTypes())
    }
    if (!partners.length) {
      dispatch(fetchPartners({
        limit: 1000,
        all: true,
      }))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCarwash(id))
    dispatch(fetchTables(id))
  }, [id, dispatch])

  useEffect(() => {
    dispatch(getSchedules())
  }, [dispatch, carwash])

  useEffect(() => {
    return () => {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
    }
  }, [dispatch])

  if (!carwash) {
    return <Loader />
  }

  const onTabsChange = (key: string) => {
    dispatch(action(Actions.CLEAN_TARIFFS, {}))
    dispatch(fetchTableByClientType(key, carwash.id))
  }

  const onChangeSwitch = (
    value: boolean,
    carwashId: string,
    clientTypeId: string
  ) => {
    dispatch(switchClientType(value, carwashId, clientTypeId))
  }

  const onSwitchClick = (e: any) => {
    e.stopPropagation()
  }

  const onReplacePartner = (partnerId: string) => {
    dispatch(replacePartner(carwash.id, partnerId))
  }

  return (
    <div className={styles.main}>
      <PageHeader
        className={styles.titleBlock}
        onBack={
          () => {
            history.push(ROUTES.CARWASHES.PARAMS.createPath(filter))
          }
        }
        title={
          <div className={styles.title}>
            { carwash.location?.address ?? carwash.name }
            <div className={styles.tagBlok}>
              <Status value={carwash} />
            </div>
          </div>
        }
        extra={
          <Form.Item
            className={styles.partner}
            label='Партнер'
          >
            <Select
              className={styles.partnerSelect}
              onChange={onReplacePartner}
              showSearch
              optionFilterProp='children'
              filterOption={
                (input, option: any) => {
                  return option.children.toLocaleLowerCase().includes(input)
                }
              }
              defaultValue={carwash.partner_id ?? ''}
            >
              {
                partners
                  .filter((partner) => partner.state.name !== 'disconnected')
                  .map((item, i) => {
                    return (
                      <Select.Option
                        key={i}
                        value={item.id}
                      >
                        { item.legal_name }
                      </Select.Option>
                    )
                  })
              }
            </Select>
          </Form.Item>
        }
      />
      <Tabs defaultActiveKey='1'>
        <TabPane
          tab='Информация'
          key='1'
        >
          <div className={styles.container}>
            <Group title='Общая информация'>
              <CarwashInfo
                carwash={carwash}
                rentalObjectType={rental_object_type}
              />
            </Group>
            {
              has_employees && (
                <Group
                  title='Работники'
                  className={styles.fieldsEmployeesGroup}
                >
                  <Employees carwash={carwash} />
                </Group>
              )
            }
            {
              rental_object_type && (
                <Group title='Характеристики объекта'>
                  <Properties
                    carwash={carwash}
                    rentalObjectType={rental_object_type}
                  />
                </Group>
              )
            }
            {
              has_location && (
                <Group title='Местоположение'>
                  <Location carwash={carwash} />
                </Group>
              )
            }
          </div>
        </TabPane>
        <TabPane
          tab='Цены'
          key='2'
        >
          <CopyForm
            isVisible={isCopyModal}
            onCancel={() => setIsCopyModal(false)}
          />
          <Button
            type='primary'
            onClick={() => setIsCopyModal(true)}
          >
            Копировать с мойки
          </Button>
          <Tabs
            className={styles.tabs}
            defaultActiveKey='1'
            onChange={onTabsChange}
            tabPosition='left'
          >
            {
              clientTypes.map((clientType) => {
                return (
                  <TabPane
                    tab={
                      <div className={styles.switchBlock}>
                        <div>{ clientType.name }</div>
                        <div onClick={onSwitchClick}>
                          <Switch
                            disabled={isSwitchLoading}
                            checked={clientType.is_connected}
                            onChange={
                              (value) =>
                                onChangeSwitch(value, id, clientType.id)
                            }
                          />
                        </div>
                      </div>
                    }
                    key={clientType.id}
                  >
                    {
                      tariffs && (
                        <Table
                          bordered
                          size='large'
                          scroll={
                            {
                              x:
                                createColumns(carClasses, id, clientType.id).length *
                                150 >
                                1000
                                  ? 1000
                                  : createColumns(carClasses, id, clientType.id)
                                    .length * 100,
                            }
                          }
                          columns={
                            createColumns(carClasses, id, clientType.id) as any
                          }
                          dataSource={createTable(tariffs, services, carClasses)}
                          pagination={false}
                          tableLayout='fixed'
                          style={
                            {
                              maxWidth: `${
                                createColumns(carClasses, id, clientType.id).length *
                                150
                              }px`,
                            }
                          }
                        />
                      )
                    }
                    { !tariffs && <Loader /> }
                  </TabPane>
                )
              })
            }
          </Tabs>
        </TabPane>
        {
          has_schedule && (
            <TabPane
              tab='График работы'
              key='3'
            >
              <WorkingTime />
            </TabPane>
          )
        }
        <TabPane
          tab='Фото'
          key='4'
        >
          <Photos />
        </TabPane>
        <TabPane
          tab='Штрафы'
          key='5'
        >
          <Penalties />
        </TabPane>
        <TabPane
          tab='Компенсации'
          key='6'
        >
          <Compensation />
        </TabPane>
        <TabPane
          tab='История'
          key='7'
        >
          <History />
        </TabPane>
      </Tabs>
    </div>
  )
}
