// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--zxj4S{border:1px solid #ebedf0}.styles-module--table--LoDxJ{margin-top:12px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Offers/Offer/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CAGF,6BACE,eAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)\n\n\n.table\n  margin-top: 12px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--zxj4S",
	"table": "styles-module--table--LoDxJ"
};
export default ___CSS_LOADER_EXPORT___;
