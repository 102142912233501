import React, { RefObject, useEffect, useRef } from 'react'
import { Button, Divider, Input, InputRef } from 'antd'
import styles from './styles.module.sass'

type FilterSearchProps = {
  value: string | undefined
  onChange: (e: any) => void
  onDrop: () => void
  placeholder?: string
  inputRef?: RefObject<InputRef | HTMLInputElement> | null
  visible?: boolean
}

export const FilterSearch = ({value = '', onChange, onDrop, placeholder, inputRef, visible}: FilterSearchProps) => {
  const selfRef = useRef<any>(null)
  useEffect(() => {
    if(selfRef.current && visible) {
      setTimeout(selfRef.current.focus, 200)
    }
  }, [visible])

  return (
    <div className={styles.filterSearch}>
      <Input
        ref={inputRef ? inputRef as RefObject<InputRef> : selfRef}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <Divider className={styles.divider}/>
      <Button type="link" disabled={!Boolean(value)} onClick={onDrop}>Сбросить</Button>
    </div>
  )
}