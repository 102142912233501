import React, { useEffect, useRef, useState } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getDoers } from 'AC/doers/getDoers'
import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { ROUTES } from 'config/routes'
import { useDebounce } from 'utils/debounce'


interface filterProps {
  isOpen: boolean
  onClose: () => void
}

const states = [
  {
    text: 'Новый',
    value: 'new',
  },
  {
    text: 'Подтвержден',
    value: 'confirmed',
  },
  {
    text: 'Заблокирован',
    value: 'blocked',
  },
]

const selector = (state: State) => ({
  filter: state.doersReducer.doersFilter,
  roles: state.doersReducer.roles,
})

const FiltersDoers = ({ isOpen, onClose }: filterProps) => {
  const isFirstRun = useRef(true)
  const { filter, roles } = useSelector(selector)
  const dispatch = useDispatch()
  const history = useHistory()
  const [debState, setDebState] =
    useState<{ name: string | undefined, phone: string | undefined }>({
      name: filter.name,
      phone: filter.phone_number,
    })
  const debouncedPhone = useDebounce(debState.phone, 500)
  const debouncedName = useDebounce(debState.name, 500)

  useEffect(() => {
    onFilterChange({
      name: debouncedName ?? undefined,
      phone_number: debouncedPhone ?? undefined,
    })
  }, [debouncedName, debouncedPhone])

  const onFilterChange = (newFilter: any) => {
    dispatch(action(Actions.CLEAN_DOERS, {}))
    dispatch(action(Actions.SET_DOERS_FILTER, {
      ...filter,
      ...newFilter,
    }))
    history.push(ROUTES.DOERS.DOERS.PARAMS.createPath({
      ...filter,
      ...newFilter,
    }))
  }

  const resetFilters = () => {
    setDebState({
      name: undefined,
      phone: undefined,
    })
    onFilterChange({
      role_id: undefined,
      state: undefined,
      name: undefined,
      phone_number: undefined,
      last_active_days: undefined,
    })
  }

  // on filter change
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }

    dispatch(getDoers(filter))
  }, [dispatch, filter])

  return (
    <Drawer
      title='Фильтры'
      onClose={onClose}
      open={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Имя'>
          <Input
            placeholder='Введите имя'
            allowClear
            onChange={
              (value) =>
                setDebState({
                  ...debState,
                  name: value.target.value ? value.target.value : undefined,
                })
            }
            value={debState.name}
          />
        </Form.Item>

        <Form.Item label='Номер телефона'>
          <Input
            allowClear
            placeholder='Введите номер телефона'
            onChange={
              (value) =>
                setDebState({
                  ...debState,
                  phone: value.target.value ? value.target.value : undefined,
                })
            }
            value={debState.phone}
          />
        </Form.Item>

        <Form.Item label='Статус'>
          <Select
            placeholder='Выберите статус'
            allowClear
            value={filter?.state}
            onChange={(value) => onFilterChange({ state: value })}
          >
            {
              states?.map((status) => (
                <Select.Option
                  key={status.value}
                  value={status.value}
                >
                  { status.text }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Роль'>
          <Select
            allowClear
            showSearch
            mode='multiple'
            placeholder='Выберите роль'
            value={filter?.role_id}
            onChange={(value) => onFilterChange({ role_id: value.length ? value : undefined })}
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              roles?.map((role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Активность за период'>
          <Input
            type='number'
            value={filter?.last_active_days}
            onChange={(e) => onFilterChange({ last_active_days: Number(e.target.value) })}
          />
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersDoers