import React from 'react'
import { format } from 'date-fns'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'

import { Day } from '../Day'
import { APITYPES } from 'types/apitypes'
import { showSureConfirm } from 'components/modals/SureModal'
import { deleteSchedule } from 'AC/carwashes/schedules'

import styles from './styles.module.sass'


type Props = {
  selectedPeriod: {
    start: Date | null
    end: Date | null
  }
  onReset: () => void
  onCreateSchedule: () => void
  schedule: APITYPES.Schedule | null
  schedules: APITYPES.Schedule[]
  scheduleIntervalsMap: Map<any, any> | null
  days: Date[]
};

export const Period = ({
  selectedPeriod,
  onReset,
  schedule,
  scheduleIntervalsMap,
  days,
  onCreateSchedule,
  schedules,
}: Props) => {
  const dispatch = useDispatch()

  const intervalsMap = new Map()

  schedules[0].intervals?.map((item) => {
    intervalsMap.set(
      item.day_num,
      intervalsMap.has(item.day_num)
        ? [item, ...intervalsMap.get(item.day_num)]
        : [item]
    )
  })

  const onDelete = () => {
    if (!schedule) {
      return
    }

    showSureConfirm(
      () => {
        dispatch(deleteSchedule(schedule.id))
      },
      () => {}
    )
  }

  return (
    <div className={styles.days}>
      <div className={styles.header}>
        <div>
          Выбранный период:
          { ' ' }
          {
            selectedPeriod.start &&
            format(selectedPeriod.start, 'yyyy-MM-dd') +
              ' - ' +
              format(selectedPeriod.end ?? selectedPeriod.start, 'yyyy-MM-dd')
          }
          { !selectedPeriod.start && 'Расписание по умолчанию' }
        </div>
        <div className={styles.buttons}>
          {
            selectedPeriod.start && (
              <Button onClick={onReset}>Снять выделение</Button>
            )
          }
          {
            selectedPeriod.start && schedule && (
              <Button
                className={styles.deleteBtn}
                onClick={onDelete}
                danger
              >
                Удалить расписание
              </Button>
            )
          }
        </div>
      </div>
      {
        !schedule &&
        !selectedPeriod.start &&
        [undefined, 1, 2, 3, 4, 5, 6, 7].map((item) => {
          return (
            <Day
              key={item ?? 0}
              className={styles.day}
              dayNum={item}
              scheduleId={schedules[0].id}
              intervals={intervalsMap.get(item) ?? []}
            />
          )
        })
      }
      {
        schedule && scheduleIntervalsMap && (
          <>
            <Day
              className={styles.day}
              dayNum={undefined}
              scheduleId={schedule.id}
              intervals={scheduleIntervalsMap.get(undefined) ?? []}
            />
            {
              days.map((item, i) => {
                if (i > 6) {
                  return
                }

                return (
                  <Day
                    key={i}
                    className={styles.day}
                    dayNum={parseFloat(format(item, 'i'))}
                    scheduleId={schedule.id}
                    intervals={
                      scheduleIntervalsMap.get(parseFloat(format(item, 'i'))) ?? []
                    }
                  />
                )
              })
            }
          </>
        )
      }
      {
        !!days.length && !schedule && (
          <div className={styles.createBlock}>
            Расписание с данным периодом еще не создано, вы можете создать
            расписание.
            <Button
              className={styles.createBtn}
              type='primary'
              onClick={onCreateSchedule}
            >
              Создать расписание
            </Button>
          </div>
        )
      }
    </div>
  )
}
