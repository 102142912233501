// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--q5E8e{width:100%;margin-top:20px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/Card/Partner/DocumentsBlock/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,UAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".table\n    width: 100%\n    margin-top: 20px\n    cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--q5E8e"
};
export default ___CSS_LOADER_EXPORT___;
