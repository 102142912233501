import { mapToQueryString } from '../utils/urlUtils'


export const ROUTES = {
  ROOT: { path: '/' },

  ADMIN: { path: '/admin' },

  DOERS: {
    DOERS: {
      path: '/doers',
      createPath: (id: string = ':doerId') => {
        return `/doers/${id}`
      },
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/doers${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    OPERATIONS: {
      path: '/operations',
      createPath: (id: string = ':operationId') => `/operations/${id}`,
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/operations${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    MONITORING: {
      path: '/monitoring',
      PARAMS: {
        createPath: (params: Record<string, string | number | string[]>) => {
          const query = mapToQueryString(params || {})

          return `/monitoring${query.length ? '?' + query : ''}`
        },
      },
    },
    OFFERS: {
      path: '/offers',
      createPath: (id: string = ':offerId') => {
        return `/offers/${id}`
      },
      PARAMS: {
        createPath: (params: Record<string, string | number | string[] | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') { // params[item][0] === ''
              return
            }

            newParams[item] = params[item]
          })
          const url = new URLSearchParams(newParams)

          return `/offers${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    ROLES: {
      path: '/roles',
      PARAMS: {
        createPath: (params: Record<string, string | number | string[]>) => {
          const query = mapToQueryString(params || {})

          return `/roles${query.length ? '?' + query : ''}`
        },
      },
    },
    SHIFTS: {
      path: '/shifts',
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/shifts${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
      SETTINGS: { path: '/shifts/settings' },
    },
    VACANCIES: {
      path: '/vacancies',
      createPath: (id: string = ':vacancyId') => `/vacancies/${id}`,
      PARAMS: {
        createPath: (params: Record<string, string | number | string[]>) => {
          const query = mapToQueryString(params || {})

          return `/vacancies${query.length ? '?' + query : ''}`
        },
      },
    },
    INTERNSHIPS: {
      path: '/interships',
      createPath: (id: string = ':intershipId') => `/interships/${id}`,
      PARAMS: {
        createPath: (params: Record<string, string | number | string[]>) => {
          const query = mapToQueryString(params || {})

          return `/interships${query.length ? '?' + query : ''}`
        },
      },
    },
    LOCATIONS: {
      path: '/locations',
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/locations${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    TARIFFS: {
      path: '/doer_tariffs',
      createPath: (id: string = ':doerTariffId') => `/doer_tariffs/${id}`,
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })
          const url = new URLSearchParams(newParams)


          return `/doer_tariffs${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    NEWS: {
      path: '/news',
      PARAMS: { createPath: (id: string = ':id') => `/news/${id}` },
    },
    MAILING: {
      path: '/mailing',
      createPath: (status: string = '') => `/mailing/${status}`,
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })
          const url = new URLSearchParams(newParams)


          return `/mailing${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    MOTIVATIONS: {
      path: '/motivations',
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })
          const url = new URLSearchParams(newParams)


          return `/motivations${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
  },

  BILLING: {
    BILLS: {
      path: '/bills',
      ID: { createPath: (id: string = ':id') => `/bills/${id}` },
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/bills${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    CONTRACTORS: {
      path: '/contractors',
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/contractors${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    DOER_RECORDS: {
      path: '/doer_records',
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/doer_records${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    DOERS: {
      path: '/billing_doers',
      ID: { createPath: (id: string = ':id') => `/billing_doers/${id}` },
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/billing_doers${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
  },

  DIRECTORY: {
    path: '/directory',
    CLIENT_TYPES: {
      path: '/client_types',
      ID: { createPath: (id: string = ':id') => `/client_types/${id}` },
    },
    CAR_CLASSES: {
      path: '/car_classes',
      ID: { createPath: (id: string = ':id') => `/car_classes/${id}` },
    },
    SERVICES: {
      path: '/services',
      ID: { createPath: (id: string = ':id') => `/services/${id}` },
    },
    CARS: {
      path: '/cars',

      BRAND: { ID: { createPath: (id: string = ':brand_id') => `/cars/${id}` } },

      MODEL: {
        ID: {
          createPath: (brand_id: string = ':brand_id', model_id: string = ':model_id') =>
            `/cars/${brand_id}/${model_id}`,
        },
      },
    },
  },

  PARTNERS: {
    path: '/partners',
    ID: { createPath: (id: string = ':id') => `/partners/${id}` },
    SEARCH: {
      createPath: (params?: Record<string, string | string[]>) => {
        const query = mapToQueryString(params || {})

        return `/partners${query.length ? '?' + query : ''}`
      },
    },

    RENT: {
      path: '/rent',
      PARAMS: {
        createPath: (params: Record<string, string | number | undefined>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/rent${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
      // createPath: (id: string = ':rent_id') => `/rent/${id}`
    },
  },

  USERS: {
    path: '/users',
    ID: {
      createPath: (id: string = ':id') => {
        return `/users/${id}`
      },
    },
    SEARCH: { createPath: (search: string = ':search') => `/users?search=${search}` },
  },

  CARWASHES: {
    path: '/carwashes',
    ID: {
      createPath: (id: string = ':id') => {
        return `/carwashes/${id}`
      },

      PARAMS: {
        createPath: (id: string = ':id', params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/carwashes/${id}/penalties${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    PARAMS: {
      createPath: (params: Record<string, string | string[]>) => {
        const newParams: any = {}

        Object.keys(params).map((item) => {
          if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
            return
          }

          newParams[item] = params[item]
        })

        const url = new URLSearchParams(newParams)

        return `/carwashes${Object.keys(newParams).length ? '?' + url.toString() : ''}`
      },
    },
    SEARCH: { createPath: (search: string = ':search') => `/carwashes?search=${search}` },
  },

  PREMISES: {
    path: '/premises',
    ID: {
      createPath: (id: string = ':id') => {
        return `/premises/${id}`
      },
    },
    PARAMS: {
      createPath: (params: Record<string, string | string[]>) => {
        const newParams: any = {}

        Object.keys(params).map((item) => {
          if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
            return
          }

          newParams[item] = params[item]
        })

        const url = new URLSearchParams(newParams)

        return `/premises${Object.keys(newParams).length ? '?' + url.toString() : ''}`
      },
    },
    SEARCH: { createPath: (search: string = ':search') => `/premises?search=${search}` },
  },

  PRODUCTS: {
    path: '/products',
    ID: {
      createPath: (id: string = ':productId') => {
        return `/products/${id}`
      },
    },
    PARAMS: {
      createPath: (params: Record<string, string | string[]>) => {
        const newParams: any = {}

        Object.keys(params).map((item) => {
          if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
            return
          }

          newParams[item] = params[item]
        })

        const url = new URLSearchParams(newParams)

        return `/products${Object.keys(newParams).length ? '?' + url.toString() : ''}`
      },
    },

    GROUPS: { path: '/product_groups' },

    TARIFFS: {
      path: '/tariffs',
      ID: {
        createPath: (id: string = ':tariffId') => {
          return `/tariffs/${id}`
        },
      },
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/tariffs${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
  },

  FLEETS: {
    CARS: {
      path: '/fleet_cars',
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/fleet_cars${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
    SERVICES: {
      path: '/fleet_services',
      PARAMS: {
        createPath: (params: Record<string, string | string[]>) => {
          const newParams: any = {}

          Object.keys(params).map((item) => {
            if (params[item] === undefined || params[item] === '' || params[item][0] === '') {
              return
            }

            newParams[item] = params[item]
          })

          const url = new URLSearchParams(newParams)

          return `/fleet_services${Object.keys(newParams).length ? '?' + url.toString() : ''}`
        },
      },
    },
  },

  YANDEXPRO: { REPORTS: { path: '/yandexpro/reports' } },

  YANDEX_LAVKA: { REPORTS: { path: '/yandex_lavka/reports' } },

  URENT: { REPORTS: { path: '/urent/reports' } },

  SIGN_IN: { path: '/sign-in' },
  ERROR: { path: '/error' },
}
