// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--r\\+vvY{cursor:pointer}.styles-module--table--r\\+vvY tr table{background:#fbfbfb}.styles-module--active--Sp-09 td{background:rgba(52,157,255,.07) !important}.styles-module--innerTable--xLX\\+U{cursor:default}.styles-module--tag--A5k0Q{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.styles-module--tag--A5k0Q:hover{border:1px solid #40a9ff;color:#40a9ff}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftsPage/components/ShiftsTable/styles.module.sass"],"names":[],"mappings":"AAEA,8BACE,cAAA,CACA,uCAEE,kBAAA,CAGF,iCACE,0CAAA,CAEJ,mCACE,cAAA,CAEF,2BACE,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,iCACE,wBAAA,CACA,aAAA","sourcesContent":["@import 'src/styles/vars'\n\n.table\n  cursor: pointer\n  tr table\n    //padding-bottom: 16px\n    background: $grayish\n\n.active\n  td\n    background: rgba(52, 157, 255, 0.07) !important\n\n.innerTable\n  cursor: default\n\n.tag\n  user-select: none\n  &:hover\n    border: 1px solid #40A9FF\n    color: #40A9FF\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--r+vvY",
	"active": "styles-module--active--Sp-09",
	"innerTable": "styles-module--innerTable--xLX+U",
	"tag": "styles-module--tag--A5k0Q"
};
export default ___CSS_LOADER_EXPORT___;
