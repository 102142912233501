import React from 'react'
import { Tag } from 'antd'

import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from 'utils/dateUtils'
import { getColorByStatus, getTranslateCarwashStatus } from 'utils/tagUtils'


export const getColumns = () => [
  {
    title: 'Статус',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: '10%',
    align: 'center',
    render: (name: string) =>
      //@ts-ignore
      <Tag color={getColorByStatus(name)}>{ getTranslateCarwashStatus(name) }</Tag>,
  },
  {
    title: 'Имя',
    dataIndex: 'actor',
    key: 'actor',
    width: '30%',
  },
  {
    title: 'Причина',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
    width: '20%',
    align: 'center',
  },
]

export const getRows = (history: APITYPES.CarwashHistory[]) => {
  return history.map((item, index) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'created') {
        row[column.dataIndex] = getFormattedDate(item[column.dataIndex])
      } else {
        //@ts-ignore
        row[column.dataIndex] = item[column.dataIndex]
      }

      return
    })
    row.key = index

    return row
  })
}