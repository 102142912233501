import React, { useState } from 'react'
import { Button, Card, Form, Input, Popconfirm, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { APITYPES } from 'types/apitypes'
import { updateDoer } from 'AC/doers/updateDoer'
import { FormFooter } from 'components/FormFooter'
import { doerClearWallet } from 'AC/doers/doerClearWallet'
import { openNotification } from 'components/modals/OperationNotification'

import { parsePeriodToTime, timeRegEx, removeLeadingZeros } from './utils'
import styles from './styles.module.sass'


type Props = {
  doer: APITYPES.Doer
}

export const DoerInformation = ({ doer }: Props) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: { name: string, delay: string }) => {
    let delay: string = ''
    const delayValue = removeLeadingZeros(values.delay)
    const { length } = values.delay.split(':')

    if (length === 2) {
      delay = delayValue + ':00'
    } else if (length === 3) {
      delay = delayValue
    }

    dispatch(
      updateDoer(
        doer.id,
        {
          name: values.name,
          delay,
        },
        () => {
          openNotification('Исполнитель изменен')
        }
      )
    )
  }

  const clearWallet = () => {
    setConfirmLoading(true)

    dispatch(doerClearWallet(doer.id, () => {
      setOpen(false)
      setConfirmLoading(false)
    }))
  }

  return (
    <Form
      className={styles.container}
      name='doer_updating'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      labelAlign='left'
      onBlur={(e) => console.log(e)}
    >
      <Card
        className={styles.card}
        size='small'
        title='Информация'
      >
        <Form.Item
          name='name'
          label='Имя'
          initialValue={doer.name}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<Tooltip overlay='Период отсрочки показа доступных смен'>Задержка</Tooltip>}
          name='delay'
          initialValue={parsePeriodToTime(doer.delay)}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error(''))
                }
                if (value.match(timeRegEx)) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('Неверный формат'))
              },
            }),
            ]
          }
        >
          <Input placeholder='00:00:00' />
        </Form.Item>

        <FormFooter
          okText='Изменить'
          onCancel={() => {}}
          hideCancelButton
        />
      </Card>
      <Card
        className={cn(styles.card, styles.walletBlock)}
        size='small'
        title='Кошелек'
      >
        <Form.Item
          className={styles.walletInput}
          name='wallet'
          initialValue={doer?.wallet?.amount}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item className={styles.clearWalletBtn}>
          <Popconfirm
            overlayClassName={styles.popConfirmWallet}
            title='Эта операция необратима. Вы действительно хотите обнулить кошелек исполнителя?'
            okText='Да'
            open={open}
            onConfirm={clearWallet}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={() => setOpen(false)}
          >
            <Button
              type='primary'
              onClick={() => setOpen(true)}
            >
              Обнулить
            </Button>
          </Popconfirm>
        </Form.Item>
      </Card>
    </Form>
  )
}
