import React, { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom'
import {
  Form,
  PageHeader,
  Select,
  Tabs,
} from 'antd'
import { compact } from 'lodash'

import {
  fetchCarwash,
  replacePartner,
} from 'AC/carwashes/carwashes'
import {
  action,
  Actions,
} from 'actions'
import { Loader } from 'components/Loader'
import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { Status } from '../../Carwashes/Status'
import { fetchPartners } from 'AC/partners/partners'
import { Location } from '../../Carwashes/Carwash/Location'
import { Properties } from '../../Carwashes/Carwash/Properties'
import { History } from '../../Carwashes/Carwash/History'
import { Group } from '../../Carwashes/Carwash/Group'

import { CarwashInfo } from './Info'
import styles from './styles.module.sass'


const { TabPane } = Tabs

const selector = (state: State) => {
  const { carwash, premisesFilter } =
    state.carwashesReducer
  const { allPartners } = state.partnersReducer

  return {
    filter: premisesFilter,
    premise: carwash,
    partners: allPartners,
  }
}

export const Premise = () => {
  const params = useParams<{ id: string, search: string | undefined }>()
  const { id } = params
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    premise,
    filter,
    partners,
  } = useSelector(selector)

  const { rental_object_type } = premise ?? {}
  const { has_location } = rental_object_type ?? {}

  useEffect(() => {
    if (!partners.length) {
      dispatch(fetchPartners({
        limit: 1000,
        all: true,
      }))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCarwash(id))
  }, [id, dispatch])

  useEffect(() => {
    return () => {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
    }
  }, [dispatch])

  if (!premise) {
    return <Loader />
  }

  const onReplacePartner = (partnerId: string) => {
    dispatch(replacePartner(premise.id, partnerId))
  }

  return (
    <div className={styles.main}>
      <PageHeader
        className={styles.titleBlock}
        onBack={
          () => {
            history.push(ROUTES.PREMISES.PARAMS.createPath(filter))
          }
        }
        title={
          <div className={styles.title}>
            { premise.location?.address ?? premise.name }
            <div className={styles.tagBlok}>
              <Status value={premise} />
            </div>
          </div>
        }
        extra={
          <Form.Item
            className={styles.partner}
            label='Партнер'
          >
            <Select
              className={styles.partnerSelect}
              onChange={onReplacePartner}
              showSearch
              optionFilterProp='children'
              filterOption={
                (input, option: any) => {
                  return option.children.toLocaleLowerCase().includes(input)
                }
              }
              defaultValue={premise.partner_id ?? ''}
            >
              {
                partners
                  .filter((partner) => partner.state.name !== 'disconnected')
                  .map((item, i) => {
                    return (
                      <Select.Option
                        key={i}
                        value={item.id}
                      >
                        { item.legal_name }
                      </Select.Option>
                    )
                  })
              }
            </Select>
          </Form.Item>
        }
      />
      <Tabs defaultActiveKey='1'>
        <TabPane
          tab='Информация'
          key='1'
        >
          <div className={styles.container}>
            <Group title='Общая информация'>
              <CarwashInfo
                carwash={premise}
                rentalObjectType={rental_object_type}
              />
            </Group>
            {
              rental_object_type && (
                <Group title='Характеристики объекта'>
                  <Properties
                    carwash={premise}
                    rentalObjectType={rental_object_type}
                  />
                </Group>
              )
            }
            {
              has_location && (
                <Group title='Местоположение'>
                  <Location carwash={premise} />
                </Group>
              )
            }
          </div>
        </TabPane>
        <TabPane
          tab='История'
          key='7'
        >
          <History />
        </TabPane>
      </Tabs>
    </div>
  )
}
