export const formItemLayout = {
  labelCol: {span: 3},
  wrapperCol: {span: 24},
}

export const timeRegEx = /^\d+:([0-5][0-9])(:[0-5][0-9])?$/;
const leadingZeroesRegEx = /^0+(?!$)/g;

export const removeLeadingZeros = (period: string) => {
  const length = period.split(':').length
  const [hours, minutes, seconds] = period.split(':')

  const newHours = hours.replaceAll(leadingZeroesRegEx, '')

  if(length === 2) {
    return `${newHours}:${minutes}`
  } else {
    return `${newHours}:${minutes}:${seconds}`
  }
}

export const parsePeriodToTime = (period: string | undefined) => {
  if(!period) return ''
  
  if(period.includes('days') || period.includes('day')) {
    const [days, time] = period.split(',').map(item => item.trim())
    const [oldHours, minutes, seconds] = time.split(':')

    const re = / days| day/gi

    const newHours = (+days.replace(re, '') * 24)

    return `${newHours + +oldHours}:${minutes}:${seconds}`
  } else {
    return period
  }
}

export const parseTimeToPeriodWithDays = (period: string) => {
  const [hours, minutes, seconds] = period.split(':')
  const hoursInt = +hours

  if(hoursInt > 23) {
    const newHours = Math.floor(hoursInt / 24)
    const leftHours = hoursInt % 24
    let dayStr = 'days'

    if(newHours < 2) {
      dayStr = 'day'
    }

    return `${newHours} ${dayStr}, ${leftHours}:${minutes}:${seconds}`
  }

  return period
}
