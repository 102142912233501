import React, { useState } from 'react'
import { Button, Table } from 'antd'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'

import { Filters } from './Filters'
import { AddTariffModal } from './AddTariffModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  tariffs: state.customerTariffsReducer.customerTariffs,
  isLoading: state.requestsReducer.fetchCustomerTariffs === StatusRequest.LOADING,
  customerFilter: state.customerTariffsReducer.customerTariffsFilter,
})

export const CustomerTariffs = () => {
  const history = useHistory()
  const { tariffs, isLoading, customerFilter } = useSelector(selector)

  const [filter, setFilter] = useState(false)
  const toggleFilters = () => setFilter((prev) => !prev)

  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal((prev) => !prev)

  return (
    <>
      <div className={styles.header}>
        <Button
          type='primary'
          onClick={toggleAddModal}
        >
          Добавить тариф
        </Button>
        <Button
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(customerFilter)} />}
          size='small'
          onClick={toggleFilters}
        />
      </div>

      <Table
        sticky
        bordered
        pagination={false}
        loading={isLoading}
        className={styles.table}
        style={{ cursor: 'pointer' }}
        columns={getColumns() as any}
        dataSource={getRows(tariffs)}
        scroll={{ y: getTableYScroll(180) }}
        onRow={
          (record) => ({ onClick: () => history.push(ROUTES.PRODUCTS.TARIFFS.ID.createPath(record.key)) })
        }
      />

      <Filters
        isOpen={filter}
        onClose={toggleFilters}
      />
      <AddTariffModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />
    </>
  )
}
