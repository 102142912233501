import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import styles from '../YandexProReports/styles.module.sass'
import { getTableYScroll } from 'utils/scrollUtils'
import { MatchingModal } from './components/MatchingModal/MatchingModal'
import { getUrentReports } from 'AC/urent/reports'

import { MappedReport, mapReportsToTable, reportTableCols } from './table'


const selector = (state: State) => ({
  reports: state.urentReducer.reports,
  isLoading:
      state.requestsReducer.getUrentReports === StatusRequest.LOADING,
})

export const Urent = () => {
  const [isMatchingModalOpen, setIsMatchingModalOpen] = useState(false)
  const [matchingReportId, setMatchingReportId] = useState('')
  const { reports, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const toggleMatchingModal = () => setIsMatchingModalOpen((prevState) => !prevState)

  const closeMatchingModal = () => {
    setIsMatchingModalOpen(false)
    dispatch(getUrentReports())
    if (matchingReportId) {
      setMatchingReportId('')
    }
  }

  const onClickRow = (record: MappedReport) => {
    setMatchingReportId(record.id)
  }

  useEffect(() => {
    if (!reports) {
      dispatch(getUrentReports())
    }
  }, [reports])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={toggleMatchingModal}
          >
            Загрузить отчет
          </Button>
        </div>
        <Table
          bordered
          sticky
          size='small'
          pagination={false}
          loading={isLoading}
          style={{ cursor: 'pointer' }}
          columns={reportTableCols}
          scroll={{ y: getTableYScroll(162) }}
          dataSource={reports ? mapReportsToTable(reports) : []}
          onRow={
            (record) => {
              return { onClick: onClickRow.bind(null, record) }
            }
          }
        />
      </div>

      {
        (matchingReportId || isMatchingModalOpen) && (
          <MatchingModal
            reportId={matchingReportId}
            onCancel={closeMatchingModal}
          />
        )
      }

    </>
  )
}
