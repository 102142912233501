import React from 'react'
import { History } from 'history'


export const navigate = (url: string, history: History) => (event: React.MouseEvent) => {
  if (event.ctrlKey) {
    window.open(url,'_blank')
  } else {
    history.push(url)
  }
}