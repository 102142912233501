import React, { useEffect, useState } from 'react'
import { Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'


import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { Loader } from 'components/Loader'
import { getDoers } from 'AC/doers/getDoers'
import { APITYPES } from 'types/apitypes'
import { useDebounce } from 'utils/debounce'
import { Actions, action } from 'actions'
import { DoerStateTranslation } from 'App/PrivateRoutes/Doers/Doers/utils'

import { getColumns, getRows } from './utils'


const selector = (state: State) => ({
  report: state.yandexProReducer.report,
  doers: state.doersReducer.doers,
  filter: state.doersReducer.doersFilter,
  isLoading: state.requestsReducer.getYandexProReport === StatusRequest.LOADING,
})

export const ReportTable = () => {
  const { report, isLoading, doers, filter } = useSelector(selector)
  const [newReport, setNewReport] = useState<any>([])
  const [debState, setDebState] = useState<{ name: string | undefined }>({ name: filter.name })
  const debouncedName = useDebounce(debState.name, 500)

  const dispatch = useDispatch()

  useEffect(() => {
    onSearchChange({ name: debouncedName ?? undefined })
  }, [debouncedName])

  const onSearchChange = (newFilter: any) => {
    dispatch(action(Actions.CLEAN_DOERS, {}))
    dispatch(
      action(Actions.SET_DOERS_FILTER, {
        ...filter,
        ...newFilter,
      })
    )
    dispatch(getDoers())
  }

  useEffect(() => {
    setNewReport(report?.unmatched_executors)
  }, [report])


  const handleSelectChange = (name: string, record: any) => {
    if (!name) {
      const updatedRecord = {
        ...record,
        doer_name: '',
        doer_phone: '',
        doer_user_id: '',
      }

      const updatedReport = newReport.map((item: any) => {
        return item.yandex_executor_name === updatedRecord.yandex_executor_name
          ? updatedRecord
          : item
      })

      setNewReport(updatedReport)
    }
    const doer = doers?.find((doer: APITYPES.Doer) => doer.name === name)

    if (doer) {
      const updatedRecord = {
        ...record,
        doer_name: doer.name,
        doer_phone: doer.phone_number,
        doer_user_id: doer.id,
      }

      const updatedReport = newReport.map((item: any) => {
        return item.yandex_executor_name === updatedRecord.yandex_executor_name
          ? updatedRecord
          : item
      })

      setNewReport(updatedReport)
    }
  }

  if (!newReport) {
    return <Loader />
  }

  return (
    <>
      <Table
        sticky
        size='large'
        loading={isLoading}
        columns={getColumns(newReport, setNewReport) as any}
        //@ts-ignore
        dataSource={getRows(newReport)}
        pagination={false}
        scroll={{ y: getTableYScroll(500) }}
        bordered
        style={{ cursor: 'pointer' }}
        expandable={
          {
            expandedRowRender: (record) => {
              return (
                <Select
                  showSearch
                  allowClear
                  defaultValue={record.doer_name ? record.doer_name : ''}
                  style={{ width: '100%' }}
                  onChange={
                    (name) => {
                      handleSelectChange(name, record)
                    }
                  }
                  onSearch={
                    (value) =>
                      setDebState({
                        ...debState,
                        name: value ? value : undefined,
                      })
                  }
                  onClear={() => handleSelectChange('', record)}
                >
                  {
                    doers?.map((doer: APITYPES.Doer) => (
                      <Select.Option
                        key={doer.id}
                        value={doer.name}
                      >
                        { /*@ts-ignore*/ }
                        { `${doer.name}, ${doer.phone_number}, ${DoerStateTranslation[doer.state.name]}` }
                        { `${doer.roles ? ', ' + doer.roles.map((r) => r.name).join(' ') : ''}` }
                      </Select.Option>
                    ))
                  }
                </Select>
              )
            },
          }
        }
      />
    </>
  )
}
