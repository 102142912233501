import api from "!../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ref--5-2!../../../../../../../node_modules/sass-loader/dist/cjs.js??ref--5-3!./create-internship-form.module.sass";

var options = {"injectType":"singletonStyleTag"};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};