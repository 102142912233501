import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  TimePicker,
} from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import moment from 'moment'
import pickBy from 'lodash/pickBy'

import { APITYPES } from 'types/apitypes'
import { getEnumOptions } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'
import { State } from 'stores/rootReducer'
import { getDoers } from 'AC/doers/getDoers'
import { fetchRoles } from 'AC/doers/doerRoles'
import { addMailing } from 'AC/doers/mailing'
import {
  DebounceSelect,
  fetchUserList,
  SelectValue,
} from 'components/DebounceSelectDoer'
import { getTypesOptions } from 'App/PrivateRoutes/Doers/ShiftsPage/components/AddShiftModal/CreateForm/utils'
import { fetchShiftTypes } from 'AC/shifts/shifts'
import { StatusRequest } from '../../../../../../stores/requestsReducer'
import {
  backDateFormat,
  dateFormat,
} from '../../../../../../utils/dateUtils'

import {
  getEnumRepeatOptions,
  Repeat,
} from './utils'


const { Option } = Select
const { TextArea } = Input

export type Props = {
  isOpen: boolean
  onClose: () => void
}

const formLayout = { labelCol: { span: 4 } }

const selector = (state: State) => ({
  doers: state.doersReducer.doers,
  shiftTypes: state.shiftsReducer.shiftTypes,
  roles: state.doersReducer.roles,
  adding: state.requestsReducer.addMailing === StatusRequest.LOADING,
})

export const AddModal = (props: Props) => {
  const { isOpen, onClose } = props
  const [isChecked, setIsChecked] = useState(false)
  const [cityIds, setCityIds] = useState<number[] | null>(null)
  const [roleCityId, setRoleCityId] = useState<number | undefined>(undefined)
  const [beforeShiftChecked, setBeforeShiftChecked] = useState(false)
  const [afterShiftChecked, setAfterShiftChecked] = useState(false)
  const [users, setUsers] = useState<SelectValue[]>([])


  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { doers, roles, shiftTypes, adding } = useSelector(selector)
  const userValue = useMemo(() => doers?.map(({ id, name, phone_number }) => ({
    label: `${name} (${phone_number})`,
    value: id,
  })) ?? [], [doers])


  useEffect(() => {
    if (!shiftTypes.length) {dispatch(fetchShiftTypes())}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBeforeShiftChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setBeforeShiftChecked(e.target.checked)
    setAfterShiftChecked(false)
  }

  const onAfterShiftChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setAfterShiftChecked(e.target.checked)
    setBeforeShiftChecked(false)
  }

  const cityOptions = getEnumOptions(APITYPES.Cities)
  const repeatOptions = getEnumRepeatOptions(Repeat)

  const onFinish = (values: any) => {
    const {
      name,
      text,
      city_ids,
      role_ids,
      repeat,
      start_time,
    } = values
    const shift_begin_delay = values.shift_begin_delay?.format('LTS')
    const date_from = values.last_shift_date_range?.[0]?.format(backDateFormat)
    const date_to = values.last_shift_date_range?.[1]?.format(backDateFormat)

    const user_ids = users.length > 0 ? users?.map((user) => user.value) : undefined

    const result = {
      name,
      text,
      start_time,
      city_ids,
      role_ids,
      user_ids,
      repeat,
      date_from,
      date_to,
      shift_begin_delay: beforeShiftChecked ? `-${shift_begin_delay}` : shift_begin_delay,
    }

    dispatch(addMailing(pickBy(result), onClose))
    setIsChecked(false)
  }

  const afterClose = () => {
    form.resetFields()
    setIsChecked(false)
    setCityIds(null)
    setRoleCityId(undefined)
  }

  useEffect(() => {
    dispatch(getDoers())
    dispatch(fetchRoles())
  }, [dispatch])

  const getRoleCityId = (id: string) => {
    const cityId = roles?.find((role) => role.id === id)?.city_id

    setRoleCityId(cityId)
  }


  return (
    <Modal
      title='Создание новой рассылки'
      open={isOpen}
      onCancel={onClose}
      width={1080}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >
        <Form.Item
          name='name'
          label='Название'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено.',
            }]
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='text'
          label='Текст сообщения'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено.',
            }]
          }
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label='Время отправки'
          name='start_time'
          initialValue={moment()}
        >
          <DatePicker
            format='LLL'
            showTime={
              {
                showHour: true,
                showMinute: true,
              }
            }
            style={{ width: '50%' }}
          />
        </Form.Item>

        <Form.Item
          name='city_ids'
          label='Город'
        >

          <Select
            placeholder='Выберите города'
            options={cityOptions}
            allowClear
            mode='multiple'
            filterOption={
              (input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
            }
            onChange={(e: number[]) => setCityIds(e)}
          />
        </Form.Item>

        <Form.Item
          label='Роль'
          name='role_ids'
        >
          <Select
            allowClear
            showSearch
            placeholder='Выберите роли'
            mode='multiple'
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e)=> getRoleCityId(e)}
          >
            {
              roles?.map((role) =>
                (
                  <Option
                    key={role.id}
                    value={role.id}
                  >
                    { role.name }
                  </Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Регулярная'
        >
          <Switch
            onChange={() => setIsChecked(!isChecked)}
          />
        </Form.Item>

        {
          !isChecked ? (
            <>
              <Form.Item
                label='Исполнитель'
                name='user_ids'
              >
                <DebounceSelect
                  mode='multiple'
                  value={users}
                  placeholder='Выберите исполнителей'
                  fetchOptions={fetchUserList}
                  defaultOptions={userValue}
                  onChange={
                    (newValue) => {
                      setUsers(newValue as SelectValue[])
                    }
                  }
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                label='Последняя смена'
                name='last_shift_date_range'
              >
                <DatePicker.RangePicker
                  format={dateFormat}
                />
              </Form.Item>
            </>
          )
            : (
              <>
                <Form.Item
                  label='Тип смены'
                  name='shift_type_id'
                >
                  <Select
                    placeholder='Выберите смену'
                    allowClear
                    showSearch
                    options={getTypesOptions(cityIds, shiftTypes, roleCityId)}
                    filterOption={
                      (input, option) =>
                        (option!.label as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item
                  label='Повторять'
                  name='repeat'
                >
                  <Select
                    placeholder='Выберите периодичность'
                    allowClear
                    showSearch
                    options={repeatOptions}
                    filterOption={
                      (input, option) =>
                        (option!.label as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item
                  label='Смещение'
                >
                  <Space
                    size='small'
                    style={{ alignItems:'baseline' }}
                  >
                    <Form.Item
                      name='shift_begin_delay'
                    >
                      <TimePicker />
                    </Form.Item>
                    <Checkbox
                      checked={beforeShiftChecked}
                      onChange={onBeforeShiftChange}
                    >
                      До начала смены

                    </Checkbox>
                    <Checkbox
                      checked={afterShiftChecked}
                      onChange={onAfterShiftChange}
                    >
                      После начала смены

                    </Checkbox>
                  </Space>
                </Form.Item>
              </>
            )
        }
        <FormFooter
          isLoading={adding}
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
