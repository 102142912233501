import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const getBill = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getBill', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetBillById.URL, { replaceUrl: { id } })
      
      if (resp) {
        dispatch(action(Actions.SET_BILL, resp))
        dispatch(changeRequestState('getBill', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getBill', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}