import React from 'react'
import { useDispatch } from 'react-redux'
import { Button,Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { deleteDoerRecord } from 'AC/billing/doerRecords'


export const DeleteRecordButton = ({ doerId, records, setRecords }: any) => {
  const dispatch = useDispatch()
  const onSuccess = () => {
    const filteredRecords = records.filter((item:any) => item.id !== doerId )

    setRecords(filteredRecords)
  }
  const handleDelete = () => {
    dispatch(deleteDoerRecord(doerId, onSuccess))
  }


  return (
    <Popconfirm
      placement='top'
      title='Вы действительно хотите удалить запись?'
      onConfirm={handleDelete}
      okText='Да'
      okType='danger'
      cancelText='Нет'
    >
      <Button
        danger
        type='link'
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  )
}
