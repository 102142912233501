import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { APITYPES } from '../../types/apitypes'
import { apiV1 } from '../../network/http'
import { successNotification } from '../../components/modals/OperationNotification'


export const getInitialPlacements = (locationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const response = await api.get(
        APITYPES.YandexPro.PlacementsInitial.GET.URL,
        { params: { location_id: locationId } }
      )

      dispatch(action(Actions.SET_YANDEX_INITIAL_PLACEMENTS, response))
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const createPlacement = (locationId: string, name: string, onSuccess: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const response = await api.post(
        APITYPES.YandexPro.PlacementsInitial.POST.URL,
        {
          location_id: locationId,
          name: name,
        }
      )

      dispatch(action(Actions.ADD_YANDEX_INITIAL_PLACEMENTS, response))
      onSuccess()
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}


export const updatePlacement = (placementId: string, locationId:  string): ThAction => {
  return async (dispatch) => {
    try {
      const response = await apiV1.put(
        `/v1/yandexpro/placements/${placementId}`,
        { location_id: locationId },
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN') } }
      )

      if (response) {
        dispatch(action(Actions.UPDATE_YANDEX_PLACEMENTS_MATCHED, {
          id: placementId,
          isMatched: true,
        }))
        successNotification('Успешно связано!')
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}


export const deletePlacement = (placementId: string,): ThAction => {
  return async (dispatch) => {
    try {
      const response = await apiV1.delete(
        `/v1/yandexpro/placements/${placementId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN') } }
      )

      if (response) {
        dispatch(action(Actions.DELETE_YANDEX_INITIAL_PLACEMENTS, { id: placementId }))
        successNotification('Удалено')
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}
