import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Popconfirm } from 'antd'

import { State } from 'stores/rootReducer'
import { createPlacement, deletePlacement, getInitialPlacements } from 'AC/yandexpro/placements'
import { actionEmpty, Actions } from 'actions'
import { DeleteButton } from 'components/DeleteButton'

import styles from './styles.module.sass'


interface MatchingLocationsProps {
  locationId: string | null
}

export const MatchingLocations: FC<MatchingLocationsProps> = ({ locationId }) => {
  const dispatch = useDispatch()
  const placements = useSelector((state: State) => state.yandexProReducer.initialPlacements)
  const [state, setState] = useState('')

  useEffect(() => {
    if (locationId) {
      dispatch(getInitialPlacements(locationId))
    }

    return () => {
      dispatch(actionEmpty(Actions.RESET_YANDEX_INITIAL_PLACEMENTS))
    }
  }, [])

  return (
    <div>
      {
        placements && placements.map((placement, index) => (
          <div
            key={index}
            className={styles.wrapper}
          >
            <span>{ placement.name }</span>
            <Popconfirm
              placement='top'
              title='Вы действительно хотите удалить?'
              okText='Да'
              okType='danger'
              cancelText='Нет'
              onConfirm={() => dispatch(deletePlacement(placement.id))}
            >
              <DeleteButton
                onClick={() => {}}
                icon
              />
            </Popconfirm>

          </div>
        ))
      }

      <div className={styles.wrapper}>
        <Input
          placeholder='Введите наименование'
          value={state}
          onChange={
            (e) => {
              setState(e.target.value)
            }
          }
        />
        <Button
          type='primary'
          onClick={
            () => {
              if (locationId && state) {
                dispatch(createPlacement(locationId, state, () => setState('')))
              }
            }
          }
        >
          Добавить
        </Button>
      </div>


    </div>
  )
}
