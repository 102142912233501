import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, Row, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { State } from 'stores/rootReducer'
import { addLocationPhoto, deleteLocationPhoto, getLocationPhotos } from '../../../../../../AC/shifts/locations'
import { showSureConfirm } from '../../../../../../components/modals/SureModal'
import { Loader } from '../../../../../../components/Loader'
import { action, Actions } from '../../../../../../actions'
import { StatusRequest } from '../../../../../../stores/requestsReducer'

import styles from './styles.module.sass'


interface PhotosProps {
  locationId: string | null
}

const selector = (state: State) => {
  return {
    locationPhotos: state.shiftsReducer.locationPhotos,
    isCreating: state.requestsReducer.putLocationPhoto === StatusRequest.LOADING,
    isRemoving: state.requestsReducer.deleteLocationPhoto === StatusRequest.LOADING,
    isLoading: state.requestsReducer.getLocationPhotos === StatusRequest.LOADING,
  }
}

export const Photos: FC<PhotosProps> = ({ locationId }) => {
  const dispatch = useDispatch()
  const {
    locationPhotos,
    isRemoving,
    isCreating,
    isLoading,
  } = useSelector(selector)

  const [previewImage, setPreviewImage] = useState<{
    uid: string
    name: string
    url: string
  } | null>(null)

  const onUploadImage = (fileObj: any) => {

    dispatch(addLocationPhoto(locationId as string, fileObj.file))
  }
  const handleCancel = () => {
    setPreviewImage(null)
  }
  
  const onPreview = (file: any) => {
    setPreviewImage(file)
  }
  
  const fileList = locationPhotos?.map((photo, i)=>({
    uid: `${i}`,
    name: photo.name,
    url: photo.url,
  }))

  const onRemove = (file: any) => {
    const fileId = file.url.split('/')[file.url.split('/').length - 1]
    
    if (!fileId) {return}

    showSureConfirm(()=>{
      dispatch(deleteLocationPhoto(locationId as string, fileId))
    },()=>{})

    return false
  }
  
  useEffect(() => {
    if (locationId) {
      dispatch(getLocationPhotos(locationId))
    }

    return () => {
      dispatch(action(Actions.SET_LOCATION_PHOTOS, []))
    }
  }, [locationId])

  if (isRemoving || isCreating || isLoading) {
    return <Row style={{height: 144}}><Loader /></Row>
  }

  return (
    <div className={styles.photoBlock}>
      {
        fileList && (
          <>
            <Upload
              className={styles.photos}
              listType='picture-card'
              fileList={fileList}
              onPreview={onPreview}
              onRemove={onRemove}
              onDownload={onUploadImage}
              onChange={onUploadImage}
              beforeUpload={() => false}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
            <Modal
              visible={!!previewImage}
              title={
                <Row>
                  <Col>{ previewImage?.name }</Col>
                  <Col> llk </Col>
                </Row>
              }
              footer={null}
              onCancel={handleCancel}
            >
              {
                previewImage && (
                  <img
                    alt='example'
                    style={{ width: '100%' }}
                    src={previewImage.url}
                  />
                )
              }
            </Modal>
          </>
        )
      }
    </div>
  )
}
