import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { APITYPES } from 'types/apitypes'
// import { State } from 'stores/rootReducer'
// import { fetchNews } from 'AC/news/news'
import { action, Actions } from 'actions'
import { fetchRoles } from 'AC/doers/doerRoles'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { Loader } from 'components/Loader'

import { NewsCard } from './NewsCard'
import { NewsModal } from './NewsModal'
import { NewsModalType } from './utils'
import styles from './styles.module.sass'
import { mock_news } from './mock_data'

// const selector = (state: State) => {
//   const {news} = state.newsReducer
//
//   return {
//     news,
//   }
// }

type NewsModalState = {
  isOpen: boolean
  type: NewsModalType | null
}

export const News = () => {
  const [newsFeed, setNewsFeed] = useState(mock_news)
  const intersectionRef = useRef<any>(null)
  const entry = useIntersectionObserver(intersectionRef, { rootMargin: '88px' })
  const [isLoading, setIsLoading] = useState(false)
  const [newsModal, setNewsModal] = useState<NewsModalState>({
    isOpen: false,
    type: null,
  })
  const dispatch = useDispatch()
  // const { news } = useSelector(selector)

  
  useEffect(() => {
    // dispatch(fetchNews())
    dispatch(fetchRoles())
  }, [])

  useEffect(() => {
    if (!isLoading) {
      loadMore()
      setIsLoading(false)
    }
  }, [entry?.isIntersecting])

  const loadMore = () => {
    setIsLoading(true)
    setNewsFeed([...newsFeed, ...mock_news])
  }

  const openNewsModal = useCallback((type: NewsModalType, modalNews?: APITYPES.News) => {
    if (type !== NewsModalType.ADD_NEWS && modalNews) {
      dispatch(action(Actions.SET_CURRENT_NEWS, modalNews))
    }
    setNewsModal({
      isOpen: true,
      type: type,
    })
  }, [newsModal])

  const closeNewsModal = useCallback(() => setNewsModal({
    isOpen: false,
    type: null,
  }),[newsModal])

  return (
    <>
      <div className={styles.main}>
        <Button
          type='primary'
          shape='circle'
          size='large'
          className={styles.addBtn}
          onClick={() => openNewsModal(NewsModalType.ADD_NEWS)}
        >
          <PlusOutlined />
        </Button>


        <div className={styles.newsFeed}>
          {
            newsFeed.map((news, index) => (
              <NewsCard
                key={news.id + index}
                news={news}
                newsHandler={openNewsModal}
              />
            ))
          }
        </div>

        { isLoading && <div className={styles.loaderWrapper}><Loader /></div> }

        <div
          className={styles.intersectionRef}
          ref={intersectionRef}
        />
      </div>

      <NewsModal
        isOpen={newsModal.isOpen}
        type={newsModal.type}
        onClose={closeNewsModal}
      />
    </>
  )
}
