import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { openNotification } from 'components/modals/OperationNotification'
import { showError } from 'components/modals/ErrorModal'


export const fetchRoles = (filter?: APITYPES.Doers.Roles.GET.Params): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchRoles', StatusRequest.LOADING))

    dispatch(action(Actions.CLEAN_ROLES, {}))

    try {
      const resp = await api.get(APITYPES.Doers.Roles.GET.URL, { params: { ...filter } })

      if (resp) {
        dispatch(action(Actions.SET_ROLES, resp))
        dispatch(changeRequestState('fetchRoles', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchRoles', StatusRequest.ERROR))


      throw(e)
    }
  }
}

export const updateRole = (role_id: string, body: APITYPES.Doers.Roles.PUT.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateRole', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Doers.Roles.PUT.URL, body, { replaceUrl: { role_id } })

      if (resp) {
        dispatch(fetchRoles())
        dispatch(changeRequestState('updateRole', StatusRequest.LOADED))

        openNotification('Роль обновлена')
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateRole', StatusRequest.ERROR))


      throw(e)
    }
  }
}

export const addRole = (body: APITYPES.Doers.Roles.POST.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addRole', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Doers.Roles.POST.URL, body)

      if (resp) {
        dispatch(fetchRoles())
        dispatch(changeRequestState('addRole', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addRole', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchDoerRoles = (doer_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchDoerRoles', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Doers.DoerRole.GET.URL, { replaceUrl: { doer_id } })


      if (resp) {
        dispatch(action(Actions.SET_CURRENT_DOER_ROLES, resp))
        dispatch(changeRequestState('fetchDoerRoles', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchDoerRoles', StatusRequest.ERROR))
      throw(e)
    }
  }
}


export const addDoerRole = (doer_id: string, role_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addDoerRole', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Doers.DoerRole.POST.URL, { id: role_id }, { replaceUrl: { doer_id } })

      if (resp) {
        dispatch(fetchDoerRoles(doer_id))
        dispatch(changeRequestState('addDoerRole', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addDoerRole', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const deleteDoerRole = (doer_id:string, doer_role_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteDoerRole', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Doers.DoerRole.DELETE.URL, { replaceUrl: { doer_role_id } })

      if (resp) {
        dispatch(fetchDoerRoles(doer_id))
        dispatch(changeRequestState('deleteDoerRole', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDoerRole', StatusRequest.ERROR))
      throw(e)
    }
  }
}


export const fetchRoleGroup = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchRoleGroups', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Doers.RoleGroups.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_ROLE_GROUPS, resp))
        dispatch(changeRequestState('fetchRoleGroups', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchRoleGroups', StatusRequest.ERROR))


      throw(e)
    }
  }
}