// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--deleteBtn--3qkP2{margin-left:20px}.styles-module--buttons--v3qdZ{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/Bill/Positions/UpdateModal/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,gBAAA,CAEJ,+BACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA","sourcesContent":[".deleteBtn\n    margin-left: 20px\n\n.buttons\n    width: 100%\n    display: flex\n    justify-content: space-between"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteBtn": "styles-module--deleteBtn--3qkP2",
	"buttons": "styles-module--buttons--v3qdZ"
};
export default ___CSS_LOADER_EXPORT___;
