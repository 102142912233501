import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { changeRequestState } from '../request'
import { openNotification } from 'components/modals/OperationNotification'
import { showError } from 'components/modals/ErrorModal'
import { apiV1 } from '../../network/http'


export const fetchStaticLocationsByQuery = (searchValue: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchStaticLocations', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.GET.URL, { params: { search: searchValue } })

      if (resp && resp?.length) {
        dispatch(action(Actions.SET_STATIC_SHIFT_LOCATIONS, resp))
        dispatch(changeRequestState('fetchStaticLocations', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchStaticLocations', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchStaticLocations = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchStaticLocations', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.GET.URL, { params: {} })

      if (resp) {
        dispatch(action(Actions.SET_STATIC_SHIFT_LOCATIONS, resp))
        dispatch(changeRequestState('fetchStaticLocations', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchStaticLocations', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchLocationChildren = (locationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchLocationChildren', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.PutChildren.URL, { replaceUrl: { location_id: locationId } })

      if (resp) {
        dispatch(action(Actions.SET_LOCATION_CHILDREN, resp))

        dispatch(changeRequestState('fetchLocationChildren', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchLocationChildren', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const addLocationChildren = (locationId: string, body: APITYPES.Shifts.ShiftLocations.PutChildren.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addLocationChildren', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Shifts.ShiftLocations.PutChildren.URL, body, { replaceUrl: { location_id: locationId } })

      if (resp) {
        openNotification('Локация добавлена')
        dispatch(fetchLocationChildren(locationId))

        dispatch(changeRequestState('addLocationChildren', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addLocationChildren', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const deleteLocationChildren = (locationId: string, body: APITYPES.Shifts.ShiftLocations.DeleteChildren.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteLocationChildren', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Shifts.ShiftLocations.DeleteChildren.URL,{
        replaceUrl: { location_id: locationId },
        params: body,
      })

      if (resp) {
        openNotification('Локация удалена')
        dispatch(fetchLocationChildren(locationId))

        dispatch(changeRequestState('deleteLocationChildren', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteLocationChildren', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const getLocationPhotos = (locationId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getLocationPhotos', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Shifts.ShiftLocations.GetFiles.URL, {
        replaceUrl: { location_id: locationId },
        params: { file_type: 'photo' },
      })

      if (resp) {
        dispatch(action(Actions.SET_LOCATION_PHOTOS, resp))

        dispatch(changeRequestState('getLocationPhotos', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getLocationPhotos', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const addLocationPhoto = (locationId: string, file: File): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addLocationPhoto', StatusRequest.LOADING))

    try {
      const resp = await api.post(
        APITYPES.Shifts.ShiftLocations.PostFile.URL,
        {
          mime_type: file.type,
          name: file.name,
          size: file.size,
          file_type: 'photo',
        },
        { replaceUrl: { location_id: locationId } }
      )

      if (resp) {
        const fileId = resp.url.split('/')[resp.url.split('/').length - 1]

        dispatch(putLocationPhoto(locationId, fileId, file))

        dispatch(changeRequestState('addLocationPhoto', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addLocationPhoto', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const putLocationPhoto = (locationId: string, fileId: string, file: File): ThAction => {
  return async (dispatch) => {
    dispatch(changeRequestState('putLocationPhoto', StatusRequest.LOADING))

    try {
      const resp = await apiV1.put(
        `/v1/files/${fileId}`,
        file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (resp) {
        openNotification('Фтография добавлена')
        dispatch(getLocationPhotos(locationId))

        dispatch(changeRequestState('putLocationPhoto', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('putLocationPhoto', StatusRequest.ERROR))
      showError(e?.response?.data ?? 'Ошибка')
      throw(e)
    }
  }
}

export const deleteLocationPhoto = (locationId: string, id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('deleteLocationPhoto', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.File.Delete.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(getLocationPhotos(locationId))
        dispatch(changeRequestState('deleteLocationPhoto', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteLocationPhoto', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}
