import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Checkbox, DatePicker, Drawer, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { State } from 'stores/rootReducer'
import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { action, actionEmpty, Actions } from 'actions'
import { ContractorSelector, ResetContractor } from '../../Bills/ContractorSelector'

import { billStateOptions, BillStateTranslation, getInitDatesState } from './utils'

import ContractorType = APITYPES.ContractorType


const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'

type M = moment.Moment

const { Option } = Select

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({ filter: state.billingReducer.billsDoersFilter })

export const Filters = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const contractorRef = useRef<ResetContractor>(null)
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const { filter } = useSelector(selector)
  const [dates, setDates] =
    useState<M[] | undefined>(getInitDatesState(params?.start ?? filter?.start, params?.end ?? filter?.end))

  const onFilterChange = (partial: Partial<APITYPES.BillsFilter>) => {
    dispatch(actionEmpty(Actions.CLEAN_BILLS_DOERS))
    dispatch(action(Actions.SET_BILLS_FILTER_DOERS, {
      ...filter,
      ...partial,
    }))
    history.push(ROUTES.BILLING.DOERS.PARAMS.createPath({
      ...filter,
      ...partial,
    }))
  }

  const onDateChange = (dates: any) => {
    if (!dates) {
      setDates(undefined)
      onFilterChange({
        start: undefined,
        end: undefined,
      })
    } else {
      setDates(dates)
      const [dates_begin, dates_end] = dates

      onFilterChange({
        start: dates_begin.format(backFormat),
        end: dates_end.format(backFormat),
      })
    }
  }

  const resetFilters = () => {
    setDates(undefined)
    dispatch(action(Actions.SET_BILLS_FILTER_DOERS, {}))
    history.push(ROUTES.BILLING.DOERS.path)
    dispatch(actionEmpty(Actions.CLEAN_BILLS_DOERS))
    contractorRef.current?.resetContractor()
  }
  
  return (
    <Drawer
      title='Фильтры'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Период'>
          <DatePicker.RangePicker
            format={dateFormat}
            //@ts-ignore
            value={dates}
            onChange={onDateChange}
          />
        </Form.Item>

        <Form.Item label='Статус'>
          <Select
            allowClear
            mode='multiple'
            placeholder='Выберите статус...'
            value={filter?.state}
            onChange={(value) => onFilterChange({ state: value.length ? value : undefined })}
          >
            {
              billStateOptions.map((state) => (
                <Option
                  key={state}
                  value={state}
                >
                  { /*@ts-ignore*/ }
                  { BillStateTranslation[state] }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item label='Контрагент'>
          <ContractorSelector
            fetchContractorFromUrlId
            ref={contractorRef}
            contractorType={ContractorType.doer}
            onFilterChange={onFilterChange}
          />
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={Boolean(filter?.nonzero)}
            onChange={
              (e) =>
                onFilterChange({ nonzero: e.target.checked ? `${e.target.checked}` : undefined })
            }
          >
            Ненулевые
          </Checkbox>
        </Form.Item>


        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
