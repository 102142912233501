import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'

// tariffs
export const fetchCustomerTariffs = (filter?: APITYPES.Tariffs.GET.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchCustomerTariffs', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Tariffs.GET.URL, {params: {...filter}})

      if (resp) {
        dispatch(action(Actions.SET_CUSTOMER_TARIFFS, resp))

        dispatch(changeRequestState('fetchCustomerTariffs', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCustomerTariffs', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchCustomerTariffById = (tariffId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchCustomerTariffById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Tariffs.GetById.URL, {replaceUrl: {tariffId}})

      if (resp) {
        dispatch(action(Actions.SET_CUSTOMER_TARIFF, resp))
        dispatch(changeRequestState('fetchCustomerTariffById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCustomerTariffById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createCustomerTariff = (body: APITYPES.Tariffs.POST.Req): ThAction => {
  return async (dispatch, getState, {api}) => {
    dispatch(changeRequestState('createCustomerTariff', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Tariffs.POST.URL, body)

      if (resp) {
        const filter = getState().productsReducer.customerTariffsFilter
        dispatch(fetchCustomerTariffs(filter))

        dispatch(changeRequestState('createCustomerTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createCustomerTariff', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const editCustomerTariff = (customerTariffId: string, body: APITYPES.Tariffs.PUT.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('editCustomerTariff', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Tariffs.PUT.URL, body, {replaceUrl: {customerTariffId}})

      if (resp) {
        dispatch(action(Actions.SET_CUSTOMER_TARIFF, resp))
        dispatch(changeRequestState('editCustomerTariff', StatusRequest.LOADED))
        openNotification('Тариф изменен')
      }
    } catch (e: any) {
      dispatch(changeRequestState('editCustomerTariff', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


// tariffs products
export const fetchCustomerTariffProducts = (customerTariffId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchCustomerTariffProducts', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Tariffs.Product.GET.URL, {replaceUrl: {customerTariffId}})

      if (resp) {
        dispatch(action(Actions.SET_CUSTOMER_TARIFFS_PRODUCTS, resp))

        dispatch(changeRequestState('fetchCustomerTariffProducts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCustomerTariffProducts', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addCustomerTariffProducts = (customerTariffId: string, body: APITYPES.Tariffs.Product.POST.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('addCustomerTariffProducts', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Tariffs.Product.POST.URL, body, {replaceUrl: {customerTariffId}})

      if (resp) {
        dispatch(fetchCustomerTariffProducts(customerTariffId))
        dispatch(changeRequestState('addCustomerTariffProducts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addCustomerTariffProducts', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const editCustomerTariffProducts = (customerTariffId: string, productId: string, body: APITYPES.Tariffs.Product.PUT.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('editCustomerTariffProducts', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Tariffs.Product.PUT.URL, body, {replaceUrl: {customerTariffId, productId}})

      if (resp) {
        openNotification(`Продукт "${resp.name}" изменен`)
        dispatch(fetchCustomerTariffProducts(customerTariffId))
        dispatch(changeRequestState('editCustomerTariffProducts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('editCustomerTariffProducts', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteCustomerTariffProducts = (customerTariffId: string, productId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('deleteCustomerTariffProducts', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Tariffs.Product.DELETE.URL, {replaceUrl: {customerTariffId, productId}})

      if (resp) {
        dispatch(fetchCustomerTariffProducts(customerTariffId))
        dispatch(changeRequestState('deleteCustomerTariffProducts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteCustomerTariffProducts', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


// tariffs locations
export const fetchCustomerTariffLocations = (customerTariffId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('fetchCustomerTariffLocations', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Tariffs.Location.GET.URL, {replaceUrl: {customerTariffId}})

      if (resp) {
        dispatch(action(Actions.SET_CUSTOMER_TARIFFS_LOCATIONS, resp))

        dispatch(changeRequestState('fetchCustomerTariffLocations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchCustomerTariffLocations', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addCustomerTariffLocations = (customerTariffId: string, body: APITYPES.Tariffs.Location.POST.Req): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('addCustomerTariffLocations', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Tariffs.Location.POST.URL, body, {replaceUrl: {customerTariffId}})

      if (resp) {
        dispatch(fetchCustomerTariffLocations(customerTariffId))
        dispatch(changeRequestState('addCustomerTariffLocations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addCustomerTariffLocations', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteCustomerTariffLocations = (customerTariffId: string, locationId: string): ThAction => {
  return async (dispatch, _, {api}) => {
    dispatch(changeRequestState('deleteCustomerTariffLocations', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Tariffs.Location.DELETE.URL, {replaceUrl: {customerTariffId, locationId}})

      if (resp) {
        dispatch(fetchCustomerTariffLocations(customerTariffId))
        dispatch(changeRequestState('deleteCustomerTariffLocations', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteCustomerTariffLocations', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}