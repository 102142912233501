import React from 'react'
import { useLocation } from 'react-router-dom'

import { getTitle } from './utils'
import styles from './styles.module.sass'


export const PageTitle = () => {
  const { pathname } = useLocation()
  const title = getTitle(pathname)

  return (
    <div className={styles.title}>
      <h2>{ title }</h2>
    </div>
  )
}
