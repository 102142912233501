import React from 'react'

import { APITYPES } from 'types/apitypes'
import { getFormattedDate } from '../../../../../../utils/dateUtils'
import { getCityName } from '../../../../../../utils/enumUtils'
import { Status } from '../../Status'
import { getDoerPenalty, updatePenaltyStatus } from '../../../../../../AC/doers/penalties'


const handleChangeStatus = (comment: string, status: string, id: string) => {
  return updatePenaltyStatus(
    {
      penalty_id: id,
      new_status: status,
    },
    { comment },
    () => getDoerPenalty(id))
}

export const getGeneralData = (penalty: APITYPES.DoerPenalty) => {
  return [
    {
      label: 'Статус:',
      value: (
        <Status
          modalTitle='Вы точно хотите изменить статус штрафа?'
          motivation={penalty}
          onCallback={(comment, status) => handleChangeStatus(comment, status, penalty.id)}
        />
      ),
    },
    {
      label: 'Сумма:',
      value: penalty.value,
    },
    {
      label: 'Инициатор:',
      value: penalty.initiator_name,
    },
    {
      label: 'Время:',
      value: getFormattedDate(penalty.time),
    },
    {
      label: 'Процент удержания:',
      value: `${penalty.charge_bill_percent}%` ,
    },
    {
      label: 'Приоритет удержания:',
      value: penalty.charge_priority,
    },
  ]
}

export const getPerformerData = (penalty: APITYPES.DoerPenalty) => ([
  {
    label: 'Роль:',
    value: penalty.role_name,
  },
  {
    label: 'Город:',
    value: getCityName(penalty.city_id),
  },
  {
    label: 'Группа ролей:',
    value: penalty.role_group_name,
  },
  {
    label: 'Департамент:',
    value: penalty.role_department_name,
  },
])