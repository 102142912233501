import React from 'react'
import { Form, Modal, Select } from 'antd'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'
import { useDispatch } from 'react-redux'
import { setShiftDoer } from 'AC/shifts/offers'


type Props = {
  isOpen: boolean
  onClose: () => void
  reserve: APITYPES.OfferReserve | null
  shifts: {id: string, location_name: string}[]
  offerId: string
}

export const AssignShiftModal = (props: Props) => {
  const {isOpen, onClose, reserve, shifts, offerId} = props
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    if(!reserve) return
    const body = {
      offer_reserve_id: reserve.id,
      ...values
    }

    dispatch(setShiftDoer(body, offerId))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }
  return (
    <Modal
      title={'Назначение на смену'}
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      afterClose={afterClose}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          label='Смены'
          name='shift_id'
          rules={[{required: true, message: 'Поле должно быть заполнено'}]}
        >
          <Select>
            {
              shifts.map(shift => (
                <Select.Option key={shift.id} value={shift.id}>{shift.location_name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <FormFooter onCancel={onClose} okText='Назначить' />
      </Form>
    </Modal>
  );
};
