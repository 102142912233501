import { FormInstance } from 'antd'

import { APITYPES } from 'types/apitypes'


const Locations = APITYPES.ShiftLocationsTypes;

export enum LocsWoDistrict {
  carwash = 'Мойка',
  city = 'Город',
  district = 'Район',
  darkstore = 'Даркстор',
  box = 'Бокс',
  selfwash = 'Самомой',
  'tire-service' = 'Шиномонтаж',
}

export type optionsType = {
  label: string
  value: string
}[]

export const getLocationOptions = () => {
  const options: optionsType = []

  for (const key in LocsWoDistrict) {
    options.push({
      //@ts-ignore
      label: Locations[key],
      value: key,
    })
  }

  return options
}

export const validateGeoLocation = (form: FormInstance) => {
  const values = form.getFieldsValue(['latitude', 'longitude'])

  const latitude = !values.latitude
  const longitude = !values.longitude

  if ((latitude && !longitude) || (!latitude && longitude)) {
    form.setFields([
      {
        name: 'latitude',
        errors: latitude ? ['Пожалуйста, заполните это поле!'] : [],
      },
      {
        name: 'longitude',
        errors: longitude ? ['Пожалуйста, заполните это поле!'] : [],
      },
    ])

    return false
  }

  return true
}