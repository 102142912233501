import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { showError } from 'components/modals/ErrorModal'


export const createBillFileUrl = (bill_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createBillFileUrl', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Billing.CreateBillDownloadUrl.URL, {}, { replaceUrl: { bill_id } })

      if (resp) {
        dispatch(action(Actions.SET_ALL_DOCUMENTS, resp))
        dispatch(changeRequestState('createBillFileUrl', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createBillFileUrl', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getBillFileUrl =
  (bill_id: string): ThAction => {
    return async (dispatch, _, { api }) => {
      dispatch(changeRequestState('getBillFileUrl', StatusRequest.LOADING))
      dispatch(action(Actions.CLEAN_ALL_DOCUMENTS, {}))

      try {
        const resp = await api.get(APITYPES.Billing.GetBillDownloadUrl.URL, { replaceUrl: { bill_id } })

        if (resp) {
          dispatch(action(Actions.SET_ALL_DOCUMENTS, resp))

          dispatch(changeRequestState('getBillFileUrl', StatusRequest.LOADED))
        }
      } catch (e: unknown) {
        dispatch(changeRequestState('getBillFileUrl', StatusRequest.ERROR))

        await dispatch(createBillFileUrl(bill_id))

        throw(e)
      }
    }
  }
