import { ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'

import { fetchCarwashPhotos } from './carwashes'


export const deleteFile = (id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('deleteFile', StatusRequest.LOADING))
      
    try {
      const resp = await api.delete(APITYPES.File.Delete.URL, { replaceUrl: { id } })
    
      if (resp) {
        dispatch(fetchCarwashPhotos())
        dispatch(changeRequestState('deleteFile', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteFile', StatusRequest.ERROR))

      showError(e.response.data)
  
      throw(e)
    }
  }
}