import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Input } from 'antd'
import { useHistory } from 'react-router'

import { State } from 'stores/rootReducer'
import { Loader } from 'components/Loader'
import { ROUTES } from 'config/routes'
import { fetchUsers } from 'AC/users/getUsers'
import { action, Actions } from 'actions'
import { useDebounce } from 'utils/debounce'
import { InfiniteTable } from 'components/InfiniteTable'
import { StatusRequest } from 'stores/requestsReducer'
import { navigate } from 'utils/tabelUtils'

import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const { Search } = Input

const selector = (state: State) => ({
  users: state.usersReducer.users,
  isStoreCreating: state.usersReducer.isCreating,
  search: state.usersReducer.search,
  isLoading: state.requestsReducer.fetchUsers === StatusRequest.LOADING,
  hasMore: state.usersReducer.hasMore,
})

export const Users = () => {
  const dispatch = useDispatch()
  const searchParam = new URL(window.location.href).searchParams.get('search')
  const history = useHistory()

  useEffect(() => {
    if (searchParam) {
      dispatch(action(Actions.SET_USERS_SEARCH, searchParam))
    }
  }, [dispatch, searchParam])

  const { users, search, isLoading, hasMore } = useSelector(selector)

  const debouncedSearchTerm = useDebounce(search, 1000)

  useEffect(() => {
    if (debouncedSearchTerm && search?.trim()) {
      history.push(ROUTES.USERS.SEARCH.createPath(search))
      dispatch(action(Actions.CLEAN_USERS, {}))
      dispatch(fetchUsers({ search }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    //dispatch(fetchUsers())
    dispatch(action(Actions.SET_USER, null))
  }, [dispatch])

  const loadMore = () => {
    dispatch(fetchUsers({ search }))
  }

  if (!users) {
    return <Loader />
  }

  const onSearch = (value: string) => {
    const state = value.length ? value : undefined

    dispatch(action(Actions.CLEAN_USERS, {}))
    dispatch(fetchUsers({ search: state }))
  }

  const onSearchChange = (event: any) => {
    if (event.target.value === '') {
      dispatch(action(Actions.SET_USERS_SEARCH, undefined))

      dispatch(action(Actions.CLEAN_USERS, {}))
      dispatch(fetchUsers())

      history.push(ROUTES.USERS.path)
    }

    dispatch(action(Actions.SET_USERS_SEARCH, event.target.value))
  }

  return (
    <div className={styles.main}>
      <Search
        value={search}
        onChange={onSearchChange}
        placeholder='Поиск клиентов'
        onSearch={onSearch}
        className={styles.search}
        enterButton
      />
      <InfiniteTable
        isLoading={isLoading}
        hasMore={hasMore}
        className={styles.tableContainer}
        columns={getColumns()}
        loadMore={loadMore}
      >
        <Table
          showHeader={false}
          columns={getColumns() as any}
          className={styles.table}
          dataSource={getRows(users)}
          bordered
          size='large'
          pagination={false}
          scroll={{ x: '100%' }}
          onRow={
            (record) => {
              return { onClick: navigate(ROUTES.USERS.ID.createPath(record.cars.id), history) }
            }
          }
        />
      </InfiniteTable>
    </div>
  )
}
