import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'

import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { fetchPenalties, fetchPenaltyTypes } from 'AC/carwashes/carwashes'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'

import { NewPenaltyModal } from './NewPenaltyModal'
import { getColumns, getRows } from './uitls'
import styles from './styles.module.sass'


const selector = (state: State) => {
  const { carwashPenalties, penaltyTypes, penaltyFilter } = state.carwashesReducer
  const carwash_id = state.carwashesReducer.carwash?.id
  const isLoading = state.requestsReducer.fetchPenalties === StatusRequest.LOADING

  return {
    penalties: carwashPenalties,
    penaltyTypes,
    carwash_id,
    penaltyFilter,
    isLoading,
  }
}

export const Penalties = () => {
  const dispatch = useDispatch()
  const { penalties, carwash_id, penaltyTypes, isLoading, penaltyFilter } = useSelector(selector)
  const [isNewPenaltyModalOpen, setNewPenaltyModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (carwash_id) {
      dispatch(fetchPenaltyTypes())
      dispatch(fetchPenalties({ carwash_id }, penaltyFilter))
    }
  }, [dispatch])


  const onTableChange = (_: any, filters: any) => {
    const newFilter = {
      state: filters.status ?? undefined,
      begin: filters.created ? filters.created[0].format('YYYY-MM-DD') : undefined,
      end: filters.created ? filters.created[1].format('YYYY-MM-DD') : undefined,
    }
    dispatch(action(Actions.CLEAN_PENALTIES, undefined))
    dispatch(action(Actions.SET_PENALTIES_FILTER, newFilter))
    dispatch(fetchPenalties({ carwash_id: carwash_id ?? '' }, newFilter))
  }

  return (
    <div className={styles.main}>
      <Button
        size='middle'
        type='primary'
        className={styles.newPenaltyButton}
        onClick={() => setNewPenaltyModalOpen(true)}
      >
        Выставить штраф
      </Button>

      <div className={styles.tableContainer}>
        <Table
          sticky
          columns={getColumns(penaltyFilter) as any}
          dataSource={getRows(penalties)}
          bordered
          size='large'
          pagination={false}
          scroll={
            {
              x: '100%',
              y: getTableYScroll(333),
            }
          }
          onChange={onTableChange}
          loading={isLoading}
        />
      </div>

      {
        carwash_id && (
          <NewPenaltyModal
            carwash_id={carwash_id}
            isVisible={isNewPenaltyModalOpen}
            onCancel={() => setNewPenaltyModalOpen(false)}
            penaltyTypes={penaltyTypes}
          />
        )
      }

    </div>
  )
}