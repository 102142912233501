import React, { useEffect, useMemo, useRef } from 'react'
import { Table, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'

import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { Loader } from 'components/Loader'
import { filterEmptyValues, getUrlSearchParams } from 'utils/urlUtils'
import { getDoerMotivations } from 'AC/doers/penalties'
import { action, Actions } from 'actions'
import useIntersectionObserver from '../../../../utils/hooks/useIntersectionObserver'
import { DEFAULT_MOTIVATION_TAB_KEY, MotivationsFilterTab } from '../../../../stores/motivationsReducer'
import { APITYPES } from '../../../../types/apitypes'

import styles from './styles.module.sass'
import { CompensationTab } from './CompensationTab'
import { MotivationToolbar } from './Toolbar'
import { getColumns, getRows } from './utils'
import { PenaltyTab } from './PenaltyTab'

import MOTIVATION_TYPE = APITYPES.MOTIVATION_TYPE
import TabPane = Tabs.TabPane


const selector = (state: State) => {
  const isDoerMotivationsLoading = state.requestsReducer.getDoerMotivations === StatusRequest.LOADING
  
  return {
    isDoerMotivationsLoading,
    doerMotivations: state.motivationsReducer.doerMotivations,
    hasMore: state.motivationsReducer.hasMore,
    filter: state.motivationsReducer.doerMotivationsFilter,
    filterTabs: state.motivationsReducer.filterTabs,
    activeTabId: state.motivationsReducer.activeTabId,
  }
}

export const Motivations = () => {
  const dispatch = useDispatch()
  const {
    isDoerMotivationsLoading,
    doerMotivations,
    hasMore,
    filter,
    filterTabs,
    activeTabId,
  } = useSelector(selector)
  const params = useMemo(() => getUrlSearchParams(), [])
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  useEffect(() => {
    if (activeTabId !== DEFAULT_MOTIVATION_TAB_KEY) {return}

    const parsed = qs.parse(params)

    dispatch(action(Actions.CLEAN_DOER_MOTIVATIONS, {}))
    dispatch(getDoerMotivations(parsed))

    dispatch(action(Actions.SET_DOER_MOTIVATIONS_FILTER, parsed))
  }, [dispatch, params])

  useEffect(() => {
    if (!isDoerMotivationsLoading && hasMore && entry?.isIntersecting) {
      const parsedParams = filterEmptyValues(qs.parse(params))
      const parsedFilter = filterEmptyValues(qs.parse(filter as {}))

      dispatch(getDoerMotivations({
        ...parsedParams,
        ...parsedFilter,
      }))
    }
  }, [entry?.isIntersecting])

  const selectActiveTab = (id: string) => {
    dispatch(action(Actions.SET_ACTIVE_MOTIVATIONS_TAB, { id }))
  }

  const handleCloseTab = (id: any) => {
    const tabIndex = filterTabs.findIndex((tab: MotivationsFilterTab) => tab.id === id)

    if (tabIndex === -1) {
      return
    }

    dispatch(action(Actions.DELETE_MOTIVATIONS_FILTER_TAB, { id }))
    selectActiveTab(filterTabs[tabIndex - 1]?.id || 'table')
  }

  const onRowClick = (record: any) => {
    const tabToAdd = filterTabs.find((tab) => tab.id === record.id)

    if (!tabToAdd) {
      dispatch(action(Actions.ADD_MOTIVATIONS_FILTER_TAB, {
        title: `${record.type} ${record.doer_name}`,
        id: record.id,
        motivationType: record.motivationType,
      }))
    } else {
      selectActiveTab(tabToAdd.id)
    }
  }

  const isActiveTab = (id: string) => {
    let isActive = false

    for (let i = 0; i < filterTabs.length; i++) {
      if (filterTabs[i].id === id) {
        isActive = true
        break
      }
    }

    return isActive
  }

  if (!doerMotivations) {
    return <Loader />
  }
  
  return (
    <>
      <MotivationToolbar />
      <Tabs
        className={styles.tabs}
        type='editable-card'
        hideAdd
        onEdit={handleCloseTab}
        activeKey={activeTabId}
        onTabClick={selectActiveTab}
        destroyInactiveTabPane
      >
        <TabPane
          tab='Штрафы и Бонусы'
          key='table'
          closeIcon={<></>}
          forceRender
        >
          <Table
            loading={isDoerMotivationsLoading}
            sticky
            size='small'
            columns={getColumns() as any}
            dataSource={getRows(doerMotivations || [])}
            className={styles.table}
            bordered
            pagination={false}
            scroll={{ x: '100%' }}
            rowClassName={(record) => isActiveTab(record.id) && styles.active}
            onRow={
              (record, index ) => ({
                onClick: () => onRowClick(record),
                ref: index === doerMotivations?.length - 1 ? lastRowRef : undefined,
              })
            }
          />
        </TabPane>

        {
          filterTabs.map((tab: MotivationsFilterTab) => (
            <TabPane
              key={tab.id}
              tab={tab.name}
            >
              {
                tab.motivationType === MOTIVATION_TYPE.PENALTY
                  ? <PenaltyTab penaltyId={tab.id} />
                  : <CompensationTab compensationId={tab.id} />
              }
            </TabPane>
          ))
        }
      </Tabs>
    </>
  )
}
