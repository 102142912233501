import React from 'react'
import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'

import { getCitiesOptions, getPrioritiesOptions, getTimeOptions } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'
import { APITYPES } from '../../../../../types/apitypes'
import { useGetLocations } from '../hooks/useGetLocations'
import { filterEmptyValues } from '../../../../../utils/urlUtils'
import { useGetRoles } from '../../Motivations/hooks'
import { backDateFormat, dateFormat } from '../../../../../utils/dateUtils'
import { required } from '../../../../../utils/formUtils'
import { addVacancy } from '../../../../../AC/doers/doerVacancies'
import { action, Actions } from '../../../../../actions'
import { State } from '../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../stores/requestsReducer'

import Cities = APITYPES.Cities


export type Props = {
  isOpen: boolean
  onClose: () => void
}


const cityOptions = getCitiesOptions()
const priorityOptions = getPrioritiesOptions()

const formLayout = { labelCol: { span: 6 } }

const selector = (state: State) => {
  return { isLoading: state.requestsReducer.addVacancy === StatusRequest.LOADING }
}

export const AddModal = (props: Props) => {
  if (!props.isOpen) {return <></>}

  const { isOpen, onClose } = props

  const [form] = Form.useForm()
  const cityId = Form.useWatch('city_id', form)

  const dispatch = useDispatch()
  const locations = useGetLocations({})
  const roles = useGetRoles({ cityId })

  const { isLoading } = useSelector(selector)

  const onFinish = (values: any) => {
    const filteredValues = filterEmptyValues(values)

    filteredValues.target_date = filteredValues?.target_date?.format(backDateFormat)

    dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
    dispatch(addVacancy(filteredValues as APITYPES.DoerVacancies.Post.Req, onClose))
  }

  const afterClose = () => {
    form.resetFields()
  }

  const anyCity = {
    label: 'Любой',
    value: 0,
  }

  return (
    <Modal
      title='Создание новой вакансии'
      open={isOpen}
      onCancel={onClose}
      width={650}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >
        <Form.Item
          name='priority'
          label='Приоритет'
          rules={required}
        >
          <Select
            allowClear
            placeholder='Выберите приоритет'
            options={priorityOptions}
          />
        </Form.Item>

        <Form.Item
          name='city_id'
          label='Город'
          rules={required}
        >
          <Select
            showSearch
            placeholder='Выберите город'
            filterOption={
              (input: string, option?: { label: any, value: number }) => (
                option?.label ?? '').toLowerCase().includes(input.toLowerCase()
              )
            }
            options={[anyCity, ...cityOptions]}
          />
        </Form.Item>

        <Form.Item
          name='role_id'
          label='Роль'
          rules={required}
        >
          <Select
            placeholder='Выберите роль'
            allowClear
            showSearch
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              roles && roles.map((role) => (
                <Select.Option
                  key={role.id}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='location_id'
          label='Локация'
        >
          <Select
            allowClear
            showSearch
            placeholder='Выберите локацию'
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              locations.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  { `${Cities[item.city_id]}, ${item.name}` }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Комментарий'
          name='comment'
        >
          <Input placeholder='Введите комментарий' />
        </Form.Item>

        <Form.Item
          label='График'
          required
          style={{ marginBottom: 0 }}
        >
          <Row
            justify='space-between'
            style={{ width: 330 }}
          >
            <Col span={11}>
              <Form.Item
                name='begin'
                rules={required}
              >
                <Select
                  allowClear
                  placeholder='00:00'
                  options={getTimeOptions()}
                />
              </Form.Item>
            </Col>
            <Space style={{ height: 32 }}>−</Space>
            <Col span={11}>
              <Form.Item
                name='end'
                rules={required}
              >
                <Select
                  allowClear
                  placeholder='00:00'
                  options={getTimeOptions()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name='value'
          label='Потребность'
          rules={required}
        >
          <InputNumber
            placeholder='Укажите потребность'
            type='number'
            min={0}
            max={100}
            style={{ width: 150 }}
          />
        </Form.Item>

        <Form.Item
          label='Срок выполнения'
          name='target_date'
          rules={required}
        >
          <DatePicker
            disabledDate={
              (current) => {
                // is before
                return current.diff(moment(), 'days') < 0
              }
            }
            format={dateFormat}
          />
        </Form.Item>

        <FormFooter
          isLoading={isLoading}
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
