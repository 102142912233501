import React, { useState } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { deleteEmployee, updateEmployee } from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'
import { showSureConfirm } from 'components/modals/SureModal'
import { DeleteButton } from 'components/DeleteButton'
import { Contacts } from '../Contacts'


type Props = {
  employee: APITYPES.CarwashEmployee
  carwashId: string
  isVisible: boolean
  onCancel: () => void
}

export const EditModal = (props: Props) => {
  const { isVisible, onCancel, employee, carwashId } = props

  const dispatch = useDispatch()

  const [emails, setEmails] = useState(employee.emails ?? [])

  const [phones, setPhones] = useState(employee.phones ?? [])

  const onDelete = () => {
    showSureConfirm(()=>{
      dispatch(deleteEmployee(
        {
          carwash_id: carwashId,
          employee_id: employee.id,
        }))

      onCancel()
    },()=>{})
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  }

  const onFinish = (values: any) => {
    dispatch(updateEmployee({
      ...values,
      phones,
      emails,
    }, {
      carwash_id: carwashId,
      employee_id: employee.id,
    }))

    onCancel()
  }

  return (
    <Modal
      title='Редактирование работника'
      visible={isVisible}
      onCancel={onCancel}
      width={600}
      footer={false}
    >
      <Form
        name={employee.id}
        key={employee.id + employee.name}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          label='ФИО'
          initialValue={employee.name}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='role'
          label='Должность'
          initialValue={employee.role}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='ЛПР'>ЛПР</Select.Option>
            <Select.Option value='Администратор'>Администратор</Select.Option>
            <Select.Option value='Мойщик'>Мойщик</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='phones'
          label='Контакты'
        >
          <Contacts
            emails={emails}
            phones={phones}
            setPhones={setPhones}
            setEmails={setEmails}
          />
        </Form.Item>
        <Form.Item
          name='comment'
          label='Комментарий'
          initialValue={employee.comment}
        >
          <Input />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Сохранить'
          leftElement={<DeleteButton onClick={onDelete} />}
        />
      </Form>
    </Modal>
  )
}