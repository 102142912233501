// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editable-select-module--wrapper--0Dc53{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:2rem}.editable-select-module--actions--\\+RyVv{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/shared/ui/editable-select/editable-select.module.sass"],"names":[],"mappings":"AAAA,wCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CAGF,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA","sourcesContent":[".wrapper\n  display: flex\n  align-items: center\n  min-height: 2rem\n\n\n.actions\n  display: flex\n  gap: .5rem"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "editable-select-module--wrapper--0Dc53",
	"actions": "editable-select-module--actions--+RyVv"
};
export default ___CSS_LOADER_EXPORT___;
