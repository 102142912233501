import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Pagination, Table, Tooltip } from 'antd'
import { useHistory } from 'react-router'

import { State } from 'stores/rootReducer'
import { changeDoerInternshipStatus, fetchInternships } from 'AC/internships/internships'
import { InternshipsItemHeader } from '../internships-item-header/internships-item-header'
import { action, Actions } from 'actions'
import { Loader } from 'components/Loader'
import { StatusRequest } from 'stores/requestsReducer'
import { NotFoundResult } from '../not-found-result/not-found-result'
import { AddReserveForm } from '../add-reserve-form/add-reserve-form'
import { addReserveToInternship } from 'AC/internships/reserves'
import { EditableSpan } from 'shared/ui/editable-span'
import { Dot } from 'shared/ui/dot'
import { APITYPES } from 'types/apitypes'
import { updateDoer } from 'AC/doers/updateDoer'
import { openNotification } from 'components/modals/OperationNotification'
import { EditableSelect } from 'shared/ui/editable-select'
import { CancelInternshipModal } from '../cancel-internship-modal/cancel-internship-modal'
import { EditInternshipShiftsModal } from '../edit-count-internship-modal/edit-internship-shifts-modal'

import cls from './internships-table.module.sass'
import { mapReserveStateOptions, mapReserveStateToColor, mapStateToStatus, stateOptions } from './table-utils'

import RESERVE_STATE = APITYPES.Reserves.RESERVE_STATE
import STATE_TYPE = APITYPES.Internships.STATE_TYPE


type InternshipsShift = APITYPES.Internships.InternshipsShift


const { Panel } = Collapse

const selector = (state: State) => {
  return {
    internships: state.internshipsReducer.internships,
    totalCount: state.internshipsReducer.totalCount,
    filters: state.internshipsReducer.filters,
    isLoading: state.requestsReducer.fetchInternships === StatusRequest.LOADING,
  }
}

export const InternshipsTable = () => {
  const first = useRef(true)
  const {
    internships,
    filters,
    isLoading,
    totalCount,
  } = useSelector(selector)
  const dispatch = useDispatch()
  const history = useHistory()

  const [activePanel, setActivePanel] = useState<string | string[]>()
  const [cancelInternshipId, setCancelInternshipsId] = useState('')
  const [editShiftsPlaces, setEditShiftsPlaces] = useState<InternshipsShift[] | null>(null)
  const [formModeId, setFormModeId] = useState<string>('')

  const onChange = (key: string | string[]) => {
    setActivePanel(key)
    if (formModeId) {
      setFormModeId('')
    }
  }

  const updateQueryParams = (filterValues: Record<string, string>) => {
    const params = new URLSearchParams(filterValues)

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const paginationChange = (page: number, limit: number) => {
    dispatch(action(Actions.SET_INTERNSHIPS_FILTER, {
      ...filters,
      page,
      limit,
    }))
  }

  const onAddClick = (id: string) => {
    setActivePanel(id)
    setFormModeId(id)
  }

  const onCancelFormMode = () => {
    setFormModeId('')
  }

  const onAddReserveToInternship = (doerId: string) => {
    const body = {
      offer_id: formModeId,
      doer_user_id: doerId,
    }

    dispatch(addReserveToInternship(body, () => {
      setFormModeId('')
      dispatch(fetchInternships())
    }))
  }

  const onEditName = (name: string, id: string) => {
    dispatch(
      updateDoer(
        id,
        { name: name },
        () => {
          openNotification('Имя исполнителя изменено')
          dispatch(fetchInternships())
        }
      )
    )
  }

  const onEditStatus = (internshipId: string , doerShiftId: string, status: STATE_TYPE) => {
    dispatch(changeDoerInternshipStatus(internshipId, doerShiftId, status))
  }

  useEffect(() => {
    if (first.current) { //fetch data only by updating filters
      first.current = false

      return
    }
    updateQueryParams(filters as any) //todo fix type
    dispatch(fetchInternships())
  }, [filters])

  if (isLoading) {
    return <Loader />
  }

  if (!internships.length) {
    return <NotFoundResult />
  }

  return (
    <div className={cls.wrapper}>
      <Collapse
        accordion
        defaultActiveKey={activePanel}
        activeKey={activePanel}
        className={cls.tableWrapper}
        onChange={onChange}
      >
        {
          internships.map((internship) => (
            <Panel
              header={
                <InternshipsItemHeader
                  internship={internship}
                  onAddClick={() => onAddClick(internship.id)}
                  onCancelClick={() => setCancelInternshipsId(internship.id)}
                  onEditClick={() => setEditShiftsPlaces(internship.shifts)}
                />
              }
              key={internship.id}
            >

              {
                formModeId === internship.id && (
                  <AddReserveForm
                    onSubmit={onAddReserveToInternship}
                    onCancel={onCancelFormMode}
                  />
                )
              }
              <Table
                sticky
                loading={false}
                columns={
                  [
                    {
                      title: 'ФИО',
                      dataIndex: 'doer_name',
                      key: 'doer_name',
                      width: 350,
                      render: (name: string = '', item: APITYPES.Internships.InternshipsShiftDoer) => {
                        return (
                          <EditableSpan
                            inputClasName={cls.editInput}
                            value={name}
                            onEdit={(editedName) => onEditName(editedName, item.doer_user_id)}
                          />
                        )
                      },
                    },
                    {
                      title: 'Телефон',
                      dataIndex: 'phone_number',
                      key: 'phone_number',
                    },
                    {
                      title: 'ТГ',
                      dataIndex: 'telegram_tag',
                      key: 'telegram_tag',
                      render: (tag: string = '') => {
                        return (
                          <a
                            href={`https://t.me/${tag?.replace('@', '')}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            { tag }
                          </a>
                        )
                      },
                    },
                    {
                      title: 'Статус',
                      dataIndex: 'state',
                      width: 350,
                      key: 'state',
                      render: (state: RESERVE_STATE, item: APITYPES.Internships.InternshipsShiftDoer) => {
                        return (
                          <div className={cls.status}>

                            {
                              item.doer_shift_state ? (
                                <>
                                  <Dot color={mapStateToStatus[item.doer_shift_state].color} />
                                  <EditableSelect
                                    selectClassName={cls.statusSelect}
                                    showEdit
                                    selectedOption={
                                      {
                                        label: mapStateToStatus[item.doer_shift_state].label,
                                        value: item.doer_shift_state,
                                      }
                                    }
                                    options={stateOptions}
                                    onEdit={
                                      (status: string) => {
                                        if (item.doer_shift_id) {
                                          onEditStatus(internship.id, item.doer_shift_id, status as STATE_TYPE)
                                        }
                                      }
                                    }
                                  />
                                </>
                              ) : (

                                <Tooltip
                                  placement='top'
                                  title='Редактирование статуса станет доступно за 18 часов до начала смены'
                                >
                                  <span className={cls.status}>
                                    <Dot color={mapReserveStateToColor[state]} />
                                    { mapReserveStateOptions[state] }
                                  </span>
                                </Tooltip>
                              )
                            }
                          </div>
                        )
                      },
                    },
                  ]
                }
                dataSource={internship.doers}
                pagination={false}
              />
            </Panel>
          ))
        }

      </Collapse>
      <Pagination
        onChange={paginationChange}
        className={cls.pagination}
        current={filters?.page || 1}
        pageSize={filters?.limit || 15}
        total={totalCount}
      />
      <CancelInternshipModal
        onClose={() => setCancelInternshipsId('')}
        internshipId={cancelInternshipId}
      />

      <EditInternshipShiftsModal
        onClose={() => setEditShiftsPlaces(null)}
        shifts={editShiftsPlaces}
      />
    </div>
  )
}