// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--T2RcI{width:100%;height:calc(100vh - 254px)}.styles-module--cardContent--Xhv-3{width:90%;margin:auto}.styles-module--divider--y66i3{font-size:14px !important;margin:0 0 8px 0 !important}.styles-module--addonAfter--NAhu8{width:55px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Products/Products/Product/ProductCard/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,UAAA,CACA,0BAAA,CAEF,mCACE,SAAA,CACA,WAAA,CAEF,+BACE,yBAAA,CACA,2BAAA,CAEF,kCACE,UAAA","sourcesContent":[".card\n  width: 100%\n  height: calc(100vh - 254px)\n\n.cardContent\n  width: 90%\n  margin: auto\n\n.divider\n  font-size: 14px !important\n  margin: 0 0 8px 0 !important\n\n.addonAfter\n  width: 55px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--T2RcI",
	"cardContent": "styles-module--cardContent--Xhv-3",
	"divider": "styles-module--divider--y66i3",
	"addonAfter": "styles-module--addonAfter--NAhu8"
};
export default ___CSS_LOADER_EXPORT___;
