import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type DoerVacanciesState = {
  doerVacancies: APITYPES.DoerVacancies.Get.Resp
  doerVacancy: APITYPES.DoerVacancies.GetVacancy.Resp
  hasMore: boolean
  vacanciesFilter: APITYPES.DoerVacancies.Get.Query
}

export const initialState: DoerVacanciesState = {
  doerVacancies: [],
  doerVacancy: {} as APITYPES.DoerVacancies.GetVacancy.Resp,
  hasMore: false,
  vacanciesFilter: {},
}

export const doerVacanciesReducer: Reducer<DoerVacanciesState, Action> = (
  state = initialState,
  a
): DoerVacanciesState => {
  switch (a.type) {
    case Actions.CLEAN_DOER_VACANCIES: {
      return {
        ...state,
        doerVacancies: [],
        hasMore: false,
      }
    }

    case Actions.SET_DOER_VACANCIES: {
      return {
        ...state,
        doerVacancies: [...state.doerVacancies, ...a.data.doerVacancies],
        hasMore: a.data.hasMore,
      }
    }

    case Actions.SET_DOER_VACANCIES_FILTER: {
      return {
        ...state,
        vacanciesFilter: a.data,
      }
    }

    case Actions.SET_DOER_VACANCY: {
      return {
        ...state,
        doerVacancy: a.data,
      }
    }
  }

  return state
}
