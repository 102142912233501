import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Select } from 'antd'

import { State } from 'stores/rootReducer'
import { deleteNewsRole, deleteSingleNews } from 'AC/news/news'
import { FormFooter } from 'components/FormFooter'


type Props = {
  onClose: () => void
}
const selector = (state: State) => ({ news: state.newsReducer.currentNews })

const formLayout = { labelCol: { span: 3 } }

export const ManageForm = (props: Props) => {
  const { onClose } = props
  const { news } = useSelector(selector)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  if (!news) {return null}

  const onFinish = async (values: any) => {
    const news_role_ids = values.news_role_id

    if (news_role_ids) {
      news_role_ids.map(async (role: string) => {
        await dispatch(deleteNewsRole(role))
      })
    }

    onClose()
  }

  const onDelete = () => {
    dispatch(deleteSingleNews(news.id))
    onClose()
  }

  return (
    <Form
      form={form}
      {...formLayout}
      onFinish={onFinish}
    >
      <Form.Item
        label='Роли'
        name='news_role_id'
        initialValue={news.roles.map((role) => role.id)}
      >
        <Select mode='multiple'>
          {
            news.roles.map((role) => (
              <Select.Option
                value={role.id}
                key={role.id}
              >
                { role.name }
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>

      <FormFooter
        onCancel={onClose}
        okText='Изменить'
        leftElement={
          <Button
            danger
            onClick={onDelete}
            type='primary'
          >
            Удалить новость
          </Button>
        }
      />
    </Form>
  )
}
