import { FormInstance } from 'antd'


export const isFormChanged = (values: any, formValues: any) => {
  let isChanged = false
  const keys = Object.keys(values)

  keys.forEach((key) => {
    if (formValues[key] !== values[key]) {isChanged = true}
  })

  return isChanged
}

export const normalizeFormTypings = (form: FormInstance) => {
  const values = form.getFieldsValue()

  return values
}

export const isFormValid = (form: FormInstance) => {
  const errors = form.getFieldsError().filter((field) => field.errors.length).length


  return !!errors
}