import React, { useState } from 'react'
import { Col, Form, Modal, Row, Select, InputNumber } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { addDoerTariffTask } from 'AC/doers/doerTariffs'
import {
  required,
  requiredNoMsg,
  searchSelect,
  strToNumber,
} from 'utils/formUtils'
import { FormFooter } from 'components/FormFooter'
import { APITYPES } from 'types/apitypes'
import { apiV1 } from 'network/http'

import { getAllSubtask } from './utils'


type Props = {
  isOpen: boolean
  onClose: () => void
  filteredTasks: APITYPES.Task[]
};

type SubTasks = {
  id: string
  title: string
  description: string
  required: boolean
  exclusive: null
  question: null
  grz_required: boolean
  serial: number
  action: string
  subtasks_min: number
  min_optional_elements: number
  elements: []
}


const formLayout = { labelCol: { span: 5 } }

const selector = (state: State) => ({ carClasses: state.directoryReducer.carClasses })

export const AddTariffTaskModal = ({
  isOpen,
  onClose,
  filteredTasks,
}: Props) => {
  const { doerTariffId } = useParams<{ doerTariffId: string }>()
  const dispatch = useDispatch()
  const [subTasks, setSubTasks] = useState<SubTasks[] | null>(null)
  const { carClasses } = useSelector(selector)

  const [form] = Form.useForm()

  const filteredCarClasses = carClasses?.filter(
    (carClass) => carClass.group === 'partners carsh'
  )

  const watchedCost = Form.useWatch('cost', form)
  const watchedCost1 = Form.useWatch('cost_1', form)
  const orValidation = watchedCost || watchedCost1 ? 'success' : 'error'

  const getSubTasks = async (id: string) => {
    try {
      const res =
        await apiV1.get(`/v1/tasks/${id}`, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })

      const allSubTasks = getAllSubtask(res.data.subtasks)
      const task = {
        id: res.data.id,
        title: res.data.title,
      }
      const subTasksWithTask = [...allSubTasks, task]


      setSubTasks(subTasksWithTask)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const onFinish = (values: any) => {
    dispatch(addDoerTariffTask(doerTariffId, values))
    onClose()
  }

  return (
    <Modal
      title='Добавление задачи'
      open={isOpen}
      onCancel={onClose}
      afterClose={form.resetFields}
      footer={false}
      destroyOnClose
      width={600}
    >
      <Form
        form={form}
        onFinish={onFinish}
        {...formLayout}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 22 }}
      >
        <Form.Item
          label='Корневая'
          name='filtered_task'
          rules={required}
        >
          <Select
            showSearch
            allowClear
            placeholder='Выберите задачу'
            filterOption={searchSelect}
            onChange={(e:string) => getSubTasks(e)}
          >
            {
              filteredTasks.map((t) => (
                <Select.Option
                  key={t.id}
                  value={t.id}
                >
                  { t.title }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Задача'
          name='task_id'
          rules={required}
        >
          <Select
            showSearch
            allowClear
            placeholder='Выберите задачу'
            filterOption={searchSelect}
          >
            {
              subTasks?.map((t) => (
                <Select.Option
                  key={t.id}
                  value={t.id}
                >
                  { t.title }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='Автомобиль'
          name='car_class_id'
        >
          <Select
            style={{ width: '100%' }}
            allowClear
            placeholder='Выберите класс'
          >
            {
              filteredCarClasses?.map((car) => (
                <Select.Option key={car.id}>{ car.name }</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label='По умолчанию'
          name='cost'
          initialValue={null}
          normalize={strToNumber}
          rules={watchedCost1 ? undefined : requiredNoMsg}
          validateStatus={orValidation}
        >
          <InputNumber
            controls={false}
            min={1}
            style={{ width: 65 }}
          />
        </Form.Item>

        <Form.Item
          label='Условия'
          style={{ display: 'flex' }}
        >
          <Row justify='space-between'>
            <Col span={4}>
              <Form.Item
                name='cost_1'
                initialValue={null}
                normalize={strToNumber}
                rules={watchedCost ? undefined : requiredNoMsg}
                validateStatus={orValidation}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='1'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_2'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='2'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_3'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='3'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_4'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='4'
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='cost_5'
                initialValue={null}
                normalize={strToNumber}
              >
                <InputNumber
                  controls={false}
                  min={1}
                  addonBefore='5'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <FormFooter
          onCancel={onClose}
          okText='Добавить'
        />
      </Form>
    </Modal>
  )
}
