import React from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { changeDoerShiftStatus } from 'AC/shifts/shifts'
import { FormFooter } from 'components/FormFooter'

const {ShiftDoerStateTranslations} = APITYPES

type Props = {
  isOpen: boolean
  doer: APITYPES.ShiftDoer | null
  onClose: () => void
  shiftId: string
}

const formLayout = {
  labelCol: {span: 5},
}

const statuses = Object.keys(APITYPES.ShiftDoerStateEnum)

export const ChangeDoerStatusModal = (props: Props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {isOpen, onClose, doer, shiftId} = props

  const onFinish = (values: any) => {
    if (!doer) return

    dispatch(changeDoerShiftStatus(
      doer.doer_shift_id,
      {comment: values.comment},
      shiftId,
      values.new_status))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title="Изменение статуса смены исполнителя"
      visible={isOpen}
      afterClose={afterClose}
      footer={false}
      onCancel={onClose}
    >
      <Form form={form} onFinish={onFinish} {...formLayout}>
        <Form.Item
          label="Статус"
          name="new_status"
          rules={[{required: true, message: 'Поле должно быть заполнено'}]}
        >
          <Select>
            {
              statuses.filter(s => (s !== doer?.doer_shift_state?.name) && s !== APITYPES.ShiftDoerStateEnum.booked)
                .map(status => (
                  <Select.Option key={status} value={status}>
                    {ShiftDoerStateTranslations[status as APITYPES.ShiftDoerStateOptions]}
                  </Select.Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Комментарий"
          name="comment"
        >
          <Input.TextArea autoSize={{minRows: 3, maxRows: 5}}/>
        </Form.Item>

        <FormFooter onCancel={onClose} okText="Сохранить"/>
      </Form>
    </Modal>
  );
};

