import { APITYPES } from 'types/apitypes'

export type EditProductGroupModalType = { open: boolean, group: APITYPES.ProductGroup | null }

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
]

export const getColumns = () => columns

export const getRows = (productGroups: APITYPES.ProductGroup[]) => {
  return productGroups.map((group) => {
    const row: any = {}

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = group[column.dataIndex]

      return
    })
    row.key = group.id
    row.group = group

    return row
  })
}