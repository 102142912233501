import React, { useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import pickBy from 'lodash/pickBy'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import {
  deleteDoerTariffShiftPrices,
  editDoerTariff, editDoerTariffShiftPrices,
} from 'AC/doers/doerTariffs'
import { getTableYScroll } from 'utils/scrollUtils'
import { errorNotification } from 'components/modals/OperationNotification'

import { getColumns, getRows } from './utils'
import { AddTariffShiftPriceModal } from './AddTariffShiftPriceModal'
import styles from './styles.module.sass'


type Props = {
  tariff: APITYPES.DoerTariff.GetById.Resp
};

const selector = (state: State) => ({
  loading: state.requestsReducer.deleteDoerTariffShiftPrices === StatusRequest.LOADING ||
    state.requestsReducer.addDoerTariffShiftPrices === StatusRequest.LOADING,
})

export const TariffShiftPricesTable = ({ tariff }: Props) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(selector)

  const [addTariff, setAddTariff] = useState(false)
  const onShiftDelete = (shift_cost_id: string) => {
    dispatch(deleteDoerTariffShiftPrices(tariff.id, shift_cost_id))
  }

  const onTariffChange = (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => {
    const key = Object.keys(partial)[0] as keyof APITYPES.DoerTariff.PUT.Req

    if (partial[key] === 0) {
      errorNotification('Число должно быть больше 0')

      return
    }

    //@ts-ignore
    if (partial[key] === +tariff?.[key]) {
      return
    }
    //@ts-ignore
    if (partial[key] === null && isNaN(+tariff?.[key])) {
      return
    }

    const newBody = {
      ...tariff,
      ...partial,
      id: undefined,
      tasks: undefined,
      shift_types: undefined,
    }

    dispatch(editDoerTariff(tariff.id, newBody))
  }

  const onShiftTypeChange = (
    shiftCostId: string,
    shift_price: Partial<APITYPES.DoerTariffCost>,
    partial: Partial<APITYPES.DoerTariff.ShiftPrices.PUT.Req>
  ) => {
    const newBody = {
      ...shift_price,
      ...partial,
    }

    if ('id' in newBody) {
      delete newBody['id']
    }

    if (!newBody.cost && !newBody.cost_1) {
      errorNotification(
        'Общая цена или цена по первому условию должны быть заполнены'
      )

      return
    }

    dispatch(editDoerTariffShiftPrices(shiftCostId ?? '', pickBy(newBody), tariff.id))
  }

  return (
    <>
      <Button
        size='small'
        type='primary'
        className={styles.addBtn}
        onClick={() => setAddTariff(!addTariff)}
      >
        Добавить стоимость смены
      </Button>

      <Table
        sticky
        bordered
        loading={loading}
        columns={
          getColumns(tariff, onShiftDelete, onTariffChange, onShiftTypeChange) as any
        }
        dataSource={getRows(tariff.shift_prices || [])}
        pagination={false}
        scroll={
          {
            y: getTableYScroll(296),
            scrollToFirstRowOnChange: false,
          }
        }
      />

      <AddTariffShiftPriceModal
        isOpen={addTariff}
        onClose={() => setAddTariff(!addTariff)}
      />
    </>
  )
}
