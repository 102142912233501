import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getDoerByName } from 'AC/doers/getDoers'
import { useDebounce } from 'utils/debounce'

const selector = (state: State) => ({
  searchedDoers: state.doersReducer.searchedDoers,
  isSearchedDoersLoading: state.requestsReducer.getDoerByName === StatusRequest.LOADING,
})

export const useDoerSearch = () => {
  const dispatch = useDispatch()
  const {searchedDoers, isSearchedDoersLoading} = useSelector(selector)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 200)

  useEffect(() => {
    if(debouncedSearch && debouncedSearch.length > 1) {
      dispatch(getDoerByName(debouncedSearch))
    }
  }, [debouncedSearch])

  const onDoerSearch = (values: string) => {
    setSearch(values)
  }

  const onDoerClear = () => {
    dispatch(action(Actions.SET_SEARCHED_DOERS, []))
  }

  return {
    onDoerClear,
    onDoerSearch,
    searchedDoers,
    doerSearch: search,
    isSearchedDoersLoading,
  }
};

