import moment from 'moment'

import { APITYPES } from 'types/apitypes'


const { Cities } = APITYPES

const dateFormat = 'DD.MM.YYYY'


export const getColumns = (filter: APITYPES.Offers.GET.Query) => {
  return [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      padding: '0',
      fixed: 'left',
      filteredValue: filter?.date ?? null,
    },
    {
      title: 'Тип смены',
      dataIndex: 'shift_type',
      key: 'shift_type_name',
      fixed: 'left',
      width: '26%',
      filterMultiple: true,
      filteredValue: filter?.shift_type_id ?? [],
      filterSearch: true,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      width: '25%',
      filterMultiple: true,
      filteredValue: filter?.role_id ?? null,
      filterSearch: true,
    },
    {
      title: 'Место',
      dataIndex: 'location',
      key: 'location',
      width: '25%',
      filterMultiple: true,
      filteredValue: filter?.location_id ?? null,
      filterSearch: true,
    },
    {
      title: 'Резерв',
      dataIndex: 'places',
      key: 'places',
      width: '15%',
    },
  ]
}

export const getRows = (offers: APITYPES.Offers.GET.Resp, filter: APITYPES.Offers.GET.Query,) => {
  return offers.map((offer, index) => {
    const row: any = {}

    getColumns(filter).map((column) => {
      if (column.dataIndex === 'location') {
        row[column.dataIndex] = `${Cities[offer.location.city_id]}, ${offer.location.name}`
      } else if (column.dataIndex === 'date') {
        row[column.dataIndex] = moment(offer.date).format(dateFormat)
      } else if (column.dataIndex === 'places') {
        row[column.dataIndex] = `${offer.doers_counter} / ${offer.places} `
      } else if (column.dataIndex === 'role') {
        row[column.dataIndex] = offer.role.name
      } else if (column.dataIndex === 'shift_type') {
        row[column.dataIndex] = offer.shift_type.type
      } else {
        //@ts-ignore
        row[column.dataIndex] = offer[column.dataIndex]
      }
    })
    row.key = `${offer.id}_${offer.shift_type_name}_${offer.date}_${index}`
    row.id = offer.id

    return row
  })
}
