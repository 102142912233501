// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--toolbar--Tsvj-{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--main--N3cJH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-bottom:16px;height:calc(100vh - 90px)}.styles-module--addBtns--T9qbK{height:100%;gap:10px;display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module--tableContainer--dO8Yy{margin-top:20px;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;overflow:hidden}.styles-module--table--cAj33{cursor:pointer;margin-top:16px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Vacancies/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,mBAAA,CACA,yBAAA,CAEF,+BACE,WAAA,CACA,QAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEF,sCACE,eAAA,CACA,kBAAA,CAAA,iBAAA,CAAA,aAAA,CACA,eAAA,CAEF,6BACE,cAAA,CACA,eAAA","sourcesContent":[".toolbar\n  display: flex\n  width: 100%\n  justify-content: space-between\n  align-items: center\n\n.main\n  display: flex\n  flex-direction: column\n  padding-bottom: 16px\n  height: calc(100vh - 90px)\n\n.addBtns\n  height: 100%\n  gap: 10px\n  display: flex\n\n.tableContainer\n  margin-top: 20px\n  flex: 1 1 auto\n  overflow: hidden\n\n.table\n  cursor: pointer\n  margin-top: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "styles-module--toolbar--Tsvj-",
	"main": "styles-module--main--N3cJH",
	"addBtns": "styles-module--addBtns--T9qbK",
	"tableContainer": "styles-module--tableContainer--dO8Yy",
	"table": "styles-module--table--cAj33"
};
export default ___CSS_LOADER_EXPORT___;
