import React from 'react'
import { FilterFilled, FilterTwoTone } from '@ant-design/icons'


type Props = {
  isFilterEmpty: boolean
}

export const FilterIcon = ({ isFilterEmpty }: Props) => {
  const color = { color: '#1990ff' }
  
  return isFilterEmpty ? <FilterTwoTone /> : <FilterFilled style={color} />
}