import React, { useEffect } from 'react'
import { Card, Transfer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { addDoerRole, deleteDoerRole, fetchDoerRoles } from 'AC/doers/doerRoles'

import { Loader } from 'components/Loader'
import { getSourceColumn, getTargetKeys, listStyle, transferTitles } from './utils'
import styles from './styles.module.sass'

type Props = {
  doer: APITYPES.Doer
}

const selector = (state: State) => {
  const {roles, currentDoerRoles} = state.doersReducer
  const isAddRoleLoading = state.requestsReducer.addDoerRole === StatusRequest.LOADING
  const isDeleteRoleLoading = state.requestsReducer.deleteDoerRole === StatusRequest.LOADING
  const isFetchRolesLoading = state.requestsReducer.fetchDoerRoles === StatusRequest.LOADING

  const isLoading = isAddRoleLoading || isDeleteRoleLoading || isFetchRolesLoading

  return {
    doerRoles: currentDoerRoles,
    roles,
    isLoading,
  }
}

export const DoerRoles = ({doer}: Props) => {
  const dispatch = useDispatch()
  const {doerRoles = [], roles = [], isLoading} = useSelector(selector)

  useEffect(() => {
    dispatch(fetchDoerRoles(doer.id))
  }, [])

  const onChange = async (_: string[], direction: string, selectedRoles: string[]) => {
    switch (direction) {
      case 'right':
        await addRole(selectedRoles)
        break
      case 'left':
        await deleteRole(selectedRoles)
        break
    }
  }

  const addRole = async (roles: string[]) => {
    roles.forEach((role) => {
      dispatch(addDoerRole(doer.id, role))
    })
  }

  const deleteRole = async (roles: string[]) => {
    const doerRoleIds = doerRoles.map((doerRole) => doerRole.role_id)

    roles.forEach((role) => {
      if (doerRoleIds.includes(role)) {
        const roleToDelete = doerRoles.find((doerRole) => doerRole.role_id === role)

        if (roleToDelete) {
          dispatch(deleteDoerRole(doer.id, roleToDelete.id))
        }
      }
    })
  }

  return (
    <Card className={styles.container} size='small' title='Роли'>
      <Transfer
        showSearch
        titles={transferTitles}
        operations={['Добавить', 'Удалить']}
        showSelectAll={false}
        selectAllLabels={[<></>, <></>]}
        dataSource={getSourceColumn(roles || [])}
        targetKeys={getTargetKeys(doerRoles)}
        listStyle={listStyle}
        onChange={onChange}
        className={styles.transfer}
        render={(item) => item.title}
        filterOption={(inputValue, item) => item?.title?.toLowerCase().includes(inputValue.toLowerCase())}
      >
        {() => isLoading ? <div className={styles.loader}><Loader/></div> : null}
      </Transfer>
    </Card>
  );
};
