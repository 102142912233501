import React, { FC } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

import { ReportTable } from '../../ReportTable'
import { State } from 'stores/rootReducer'

import styles from './styles.module.sass'


const select = (state: State) => ({ executors: state.yandexProReducer.executors })

interface Props {
  onSubmit: () => void
}
export const MatchingExecutors: FC<Props> = ({ onSubmit }) => {
  const { executors } = useSelector(select)

  return (
    <div className={styles.wrapper}>
      <section className={styles.mainSection}>
        <ReportTable />
      </section>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onSubmit}
          disabled={!executors?.every((item) => item.doer_user_id)}
          type='primary'
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
