import React from 'react'
import { Modal, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createClientType } from 'AC/directory/directory'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'


const { useForm } = Form
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

type Props = {
  isVisible: boolean
  onCancel: () => void
}

export const CreateCard = (props: Props) => {
  const { isVisible, onCancel } = props
  const [form] = useForm()
  const history = useHistory()
  const dispatch = useDispatch()

  const onFinish = async (values: APITYPES.ClientTypes.Post.Req) => {
    dispatch(createClientType({
      ...values,
      position: Number(values.position),
    }, history))

    onCancelClick()
  }

  const onCancelClick = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title='Создание'
      visible={isVisible}
      onCancel={onCancelClick}
      footer={false}
    >
      <Form
        name='client_types'
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='name'
          label='Имя клиента'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='position'
          label='Позиция'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='code'
          label='Code'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <FormFooter onCancel={onCancelClick} />
      </Form>
    </Modal>
  )
}