// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--5wzyn{cursor:pointer}.styles-module--actions--\\+\\+LtM{margin-top:20px;display:-webkit-box;display:-ms-flexbox;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/Bill/Positions/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,cAAA,CAEJ,iCACI,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA","sourcesContent":[".table\n    cursor: pointer\n\n.actions\n    margin-top: 20px\n    display: flex\n    gap: 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--5wzyn",
	"actions": "styles-module--actions--++LtM"
};
export default ___CSS_LOADER_EXPORT___;
