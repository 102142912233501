import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from 'actions'


export type NewsState = {
  news: APITYPES.News[]
  currentNews: APITYPES.News | null
}

export const initialNewsState = {
  news: [],
  currentNews: null,
}

export const newsReducer: Reducer<NewsState, Action> = (
  state = initialNewsState,
  a
): NewsState => {
  switch (a.type) {
    case Actions.SET_NEWS:
      return {
        ...state,
        news: a.data.offset ? a.data.news : [...state.news, ...a.data.news],
      }

    case Actions.SET_CURRENT_NEWS:
      return {
        ...state,
        currentNews: a.data,
      }
  }

  return state
}