// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editable-span-module--wrapper--MBXot{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:2rem}.editable-span-module--actions--yY5E8{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/shared/ui/editable-span/editable-span.module.sass"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CAGF,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA","sourcesContent":[".wrapper\n  display: flex\n  align-items: center\n  min-height: 2rem\n\n\n.actions\n  display: flex\n  gap: .5rem\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "editable-span-module--wrapper--MBXot",
	"actions": "editable-span-module--actions--yY5E8"
};
export default ___CSS_LOADER_EXPORT___;
