import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Table,
} from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { fetchUsers } from 'AC/users/getUsers'
import { Loader } from 'components/Loader'
import {
  exportRecipients,
  getMailingList,
} from 'AC/doers/mailing'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import {
  action,
  Actions,
} from 'actions'
import { getColumns } from './utils/getColumns'
import { getRows } from './utils/getRows'
import { MailingDetails } from './components/Details'
import { fetchRoles } from '../../../../AC/doers/doerRoles'
import { useQueryParams } from '../../../../utils/hooks/useQueryParams'

import styles from './styles.module.sass'
import {
  AddModal,
  Filters,
} from './components'
import { useMutation } from '@tanstack/react-query'


const selector = (state: State) => {
  const { mailingList } = state.mailingReducer

  const isMailsLoading = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isFetchMailsLoading = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isMailsUpdating = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isMailsTableLoading = isMailsLoading || isMailsUpdating || isFetchMailsLoading
  const { hasMore } = state.mailingReducer

  return {
    mailingList,
    isMailsTableLoading,
    hasMore,
  }
}

export const Mailing = () => {
  const dispatch = useDispatch()
  const { isMailsTableLoading, mailingList, hasMore } = useSelector(selector)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})
  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(!addModal)
  const queryParams = useQueryParams()
  const {
    isLoading: isExportLoading,
    mutate: startExport,
  } = useMutation({ mutationFn: exportRecipients })

  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(fetchRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(action(Actions.CLEAN_MAILS, {}))
    dispatch(getMailingList(queryParams))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (entry?.isIntersecting && !isMailsTableLoading && hasMore) {
      dispatch(getMailingList(queryParams))
    }
  }, [dispatch, entry?.isIntersecting])

  if (!mailingList) {
    return <Loader />
  }
  
  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={toggleAddModal}
          >
            Добавить рассылку
          </Button>
        </div>
        <Filters />
        <Table
          sticky
          size='small'
          loading={isMailsTableLoading}
          columns={getColumns(isExportLoading, startExport) as any}
          dataSource={getRows(mailingList || [])}
          className={styles.table}
          bordered
          pagination={false}
          onRow={
            (record, index ) => ({
              onClick: () => void 0,
              ref: index === mailingList?.length - 1 ? lastRowRef : undefined,
            })
          }
          expandable={
            {
              expandedRowRender: ({ key }) => (
                <MailingDetails id={key} />
              ),
            }
          }
        />
      </div>

      <AddModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />
    </>
  )
}
