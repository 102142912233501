import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { openNotification } from 'components/modals/OperationNotification'
import { changeRequestState } from '../request'
import { showError } from '../../components/modals/ErrorModal'


export const fetchOffers = (filters?: APITYPES.Offers.GET.Query): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchOffers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Offers.GET.URL, {
        params: {
          ...filters,
          offset: getState().offersReducer.offers.length ?? 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_OFFERS, {
          offers: resp,
          hasMoreOffers: Boolean(resp.length),
        }))

        dispatch(changeRequestState('fetchOffers', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOffers', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchOfferById = (offerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchOfferById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Offers.GetOfferById.URL, { replaceUrl: { offerId } })

      if (resp) {
        dispatch(action(Actions.SET_OFFER, resp))

        dispatch(changeRequestState('fetchOfferById', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOfferById', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchOfferReserves = (offerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchOfferReserves', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Offers.GetReserves.URL, {
        params: {},
        replaceUrl: { offer_id: offerId },
      })

      if (resp) {
        dispatch(action(Actions.SET_OFFERS_RESERVES, resp))

        dispatch(changeRequestState('fetchOfferReserves', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchOfferReserves', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const setShiftDoer = (body: APITYPES.Offers.SetShiftDoer.Req, offerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('setShiftDoer', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Offers.SetShiftDoer.URL, body)

      if (resp) {
        dispatch(fetchOfferReserves(offerId))

        openNotification('Резерв назначен')
        dispatch(changeRequestState('setShiftDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('setShiftDoer', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const setShiftDoerReserve = (body: APITYPES.Offers.SetShiftDoer.Req, onSucces: () => void ): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('setShiftDoer', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Offers.SetShiftDoer.URL, body)

      if (resp) {
        openNotification('Испонитель назначен')
        dispatch(changeRequestState('setShiftDoer', StatusRequest.LOADED))
        onSucces()
      }
    } catch (e: any) {
      dispatch(changeRequestState('setShiftDoer', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const changeReserveOffer = (body: APITYPES.Offers.ChangeReserveOffer.Req, reserveId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('changeReserveOffer', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Offers.ChangeReserveOffer.URL, body, { replaceUrl: { reserveId } })

      if (resp) {
        dispatch(fetchOfferReserves(body.offer_id))

        openNotification('Заявка резерва изменена')
        dispatch(changeRequestState('changeReserveOffer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeReserveOffer', StatusRequest.ERROR))
      showError(e.response.data)
      throw(e)
    }
  }
}

export const fetchReserveOffers = (roleId: string, date: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchReserveOffers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Offers.GET.URL, {
        params: {
          role_id: roleId,
          date_from: date,
          date_to: date,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_RESERVE_OFFERS, resp))

        dispatch(changeRequestState('fetchReserveOffers', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchReserveOffers', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchShiftOffers = (shift_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchShiftOffers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Offers.GetShiftOffers.URL, { replaceUrl: { shift_id } })

      if (resp) {
        dispatch(action(Actions.SET_SHIFT_OFFERS, resp))

        const requests = resp?.map(({ offer_id }) => {
          const url = APITYPES.Offers.GetReserves.URL.replace(':offer_id', offer_id) + '?state=booked&state=confirmed'

          //@ts-ignore
          return api.get(url)
        })

        Promise.all(requests).then((res) => {
          dispatch(action(Actions.SET_SHIFT_OFFERS_REVERSE, res.flat().filter((r) => !r.doer_shift_id)))
        })


        dispatch(changeRequestState('fetchShiftOffers', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchShiftOffers', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const cancelReserve = (reserveId: string, offerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('cancelReserve', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Offers.CancelReserve.URL, {}, { replaceUrl: { reserveId } })

      if (resp) {
        dispatch(fetchOfferReserves(offerId))

        openNotification('Резерв отменен')
        dispatch(changeRequestState('cancelReserve', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('cancelReserve', StatusRequest.ERROR))
      throw(e)
    }
  }
}
