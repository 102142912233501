// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-reserve-form-module--flexWrapper--8V6Y\\+{display:-webkit-box;display:-ms-flexbox;display:flex;gap:1rem;padding:1rem}.add-reserve-form-module--select--LlfQP{width:400px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/internships/components/add-reserve-form/add-reserve-form.module.sass"],"names":[],"mappings":"AAAA,8CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CACA,YAAA,CAEF,wCACE,WAAA","sourcesContent":[".flexWrapper\n  display: flex\n  gap: 1rem\n  padding: 1rem\n\n.select\n  width: 400px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexWrapper": "add-reserve-form-module--flexWrapper--8V6Y+",
	"select": "add-reserve-form-module--select--LlfQP"
};
export default ___CSS_LOADER_EXPORT___;
