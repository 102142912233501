import React, { useState, useEffect } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import pickBy from 'lodash/pickBy'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import {
  deleteDoerTariffTask,
  editDoerTariff,
  editDoerTariffTask,
} from 'AC/doers/doerTariffs'
import { getTableYScroll } from 'utils/scrollUtils'
import { errorNotification } from 'components/modals/OperationNotification'
import { fetchAllCarClasses } from 'AC/directory/directory'

import { AddTariffTaskModal } from './AddTariffTaskModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


type Props = {
  tariff: APITYPES.DoerTariff.GetById.Resp
};

const selector = (state: State) => ({
  tasks: state.operationsReducer.tasks,
  loading:
    state.requestsReducer.fetchDoerTariffById === StatusRequest.LOADING ||
    state.requestsReducer.deleteDoerTariffTask === StatusRequest.LOADING ||
    state.requestsReducer.addDoerTariffTask === StatusRequest.LOADING,
})

export const TariffTasksTable = ({ tariff }: Props) => {
  const dispatch = useDispatch()
  const { loading, tasks } = useSelector(selector)

  const [addTariff, setAddTariff] = useState(false)

  const filteredTasks = tasks.filter((task) => task.role_ids?.includes(tariff.role_id))

  const onTaskDelete = (task_id: string) => {
    dispatch(deleteDoerTariffTask(tariff.id, task_id))
  }

  const onTariffChange = (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => {
    const key = Object.keys(partial)[0] as keyof APITYPES.DoerTariff.PUT.Req

    if (partial[key] === 0) {
      errorNotification('Число должно быть больше 0')
      
      return
    }

    //@ts-ignore
    if (partial[key] === +tariff?.[key]) {return}
    //@ts-ignore
    if (partial[key] === null && isNaN(+tariff?.[key])) {return}

    const newBody = {
      ...tariff,
      ...partial,
      id: undefined,
      tasks: undefined,
      shift_types:undefined,
    }

    if ('employer' in tariff) {
      newBody.employer_id = tariff.employer?.id
    }
    newBody.employer = undefined

    dispatch(editDoerTariff(tariff.id, newBody))
  }

  const onTaskChange = (
    task_id: string,
    task: Partial<APITYPES.DoerTariffTask>,
    partial: Partial<APITYPES.DoerTariff.Tasks.PUT.Req>
  ) => {
    const key = Object.keys(
      partial
    )[0] as keyof APITYPES.DoerTariff.Tasks.PUT.Req
    
    if ((task?.[key] as string) === (partial?.[key] as string)) {return}
    
    const newBody = {
      ...task,
      ...partial,
      task_id: undefined,
      doer_tariff_id: undefined,
    }

    // TODO: need refactoring
    if ('task_name' in newBody) {
      delete newBody['task_name']
    }
    
    if ('car_class_name' in newBody) {
      // @ts-ignore
      delete newBody['car_class_name']
    }

    if ('id' in newBody) {
      // @ts-ignore
      delete newBody['id']
    }
    
    if (!newBody.cost && !newBody.cost_1) {
      errorNotification(
        'Общая цена или цена по первому условию должны быть заполнены'
      )
      
      return
    }

    dispatch(editDoerTariffTask(task.id ?? '', pickBy(newBody)))
  }

  useEffect(() => {
    dispatch(fetchAllCarClasses())
  }, [dispatch])
  
  return (
    <>
      <Button
        size='small'
        type='primary'
        className={styles.addBtn}
        onClick={() => setAddTariff(!addTariff)}
      >
        Добавить задачу
      </Button>

      <Table
        sticky
        bordered
        loading={loading}
        columns={
          getColumns(tariff, onTaskDelete, onTariffChange, onTaskChange) as any
        }
        dataSource={getRows(tariff?.tasks ? tariff.tasks : [])}
        pagination={false}
        scroll={
          {
            y: getTableYScroll(296),
            scrollToFirstRowOnChange: false,
          }
        }
      />

      <AddTariffTaskModal
        isOpen={addTariff}
        onClose={() => setAddTariff(!addTariff)}
        filteredTasks={filteredTasks}
      />
    </>
  )
}
