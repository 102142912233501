import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'


const { confirm } = Modal

export const showSureConfirm = (
  onDelete: () => void,
  onCancel: () => void,
  title: string = 'Вы точно хотите совершить данное действие?'
) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: '',
    okText: 'Да',
    cancelText: 'Нет',
    onOk() {
      onDelete()
    },
    onCancel() {
      onCancel()
    },
  })
}

export type SureConfirmOptions = {
  onCancel?: () => void
  onOk?: () => void
  title?: string
}

export const showSureConfirmModified = ({
  title = 'Вы точно хотите совершить данное действие?',
  onCancel,
  onOk,
}: SureConfirmOptions) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: '',
    okText: 'Да',
    cancelText: 'Нет',
    onOk,
    onCancel,
  })
}
