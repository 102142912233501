import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Table } from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { action, Actions } from 'actions'
import { fetchCarClassCars } from 'AC/directory/directory'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { APITYPES } from 'types/apitypes'
import { getTableYScroll } from 'utils/scrollUtils'
import { getGroupTag } from '../../utils'

import { AddForm } from './AddForm'
import { getColumns, getRows } from './utils'
import { CarClassCarDeleteModal } from './CarClassCarDeleteModal'
import styles from './styles.module.sass'


const selector = (state: State) => {
  const { carClassCars, carClass } = state.directoryReducer
  const { brands } = state.carsReducer
  const isCarClassLoading = state.requestsReducer.fetchAllCarClasses === StatusRequest.LOADING
  const isBrandsLoading = state.requestsReducer.fetchBrands === StatusRequest.LOADING
  const isCarClassCarsLoading = state.requestsReducer.fetchCarClassCars === StatusRequest.LOADING

  const isLoading = isCarClassLoading || isBrandsLoading
  const isTableLoading = isCarClassCarsLoading

  return {
    brands,
    carClassCars,
    carClass,
    isLoading,
    isTableLoading,
  }
}

export const CarClass = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const [isModal, setIsModal] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<{
    isOpen: boolean
    currentCarBrand: APITYPES.AllCarClasses.GetCars.Resp[number] | null
  }>({
    isOpen: false,
    currentCarBrand: null,
  })
  const { brands, carClassCars, carClass, isLoading, isTableLoading } = useSelector(selector)

  useEffect(() => {
    batch(() => {
      dispatch(action(Actions.SET_DIRECTORY_CAR_CLASS, id))
      dispatch(fetchCarClassCars())
    })
  }, [id])

  const onCarBrandClick = (car: APITYPES.AllCarClasses.GetCars.Resp[number]) => {
    setDeleteModalOpen({
      isOpen: true,
      currentCarBrand: car,
    })
  }

  const onCarBrandCancel = () => {
    setDeleteModalOpen({
      isOpen: false,
      currentCarBrand: null,
    })
  }

  const avatar = isLoading ? { src: <Loader size='small' /> } : undefined

  return (
    <>
      <PageHeader
        className={styles.title}
        title={<div>{ !isLoading && carClass?.name }</div>}
        subTitle={!isLoading && getGroupTag(carClass?.group!)}
        avatar={avatar}
      />

      <Button
        type='primary'
        className={styles.addButton}
        onClick={() => setIsModal(true)}
      >
        Привязать модель автомобиля
      </Button>

      {
        carClassCars && (
          <Table
            sticky
            loading={isTableLoading}
            columns={getColumns() as any}
            className={styles.table}
            dataSource={getRows(carClassCars)}
            bordered
            size='middle'
            pagination={false}
            scroll={{ y: getTableYScroll(270.5) }}
            onRow={(record: any) => ({ onClick: () => onCarBrandClick(record.carClass) })}
          />
        )
      }

      <AddForm
        isVisible={isModal}
        onCancel={() => setIsModal(false)}
        brands={brands}
      />
      <CarClassCarDeleteModal
        isOpen={isDeleteModalOpen.isOpen}
        onCancel={onCarBrandCancel}
        car={isDeleteModalOpen.currentCarBrand}
      />
    </>
  )
}