import { PageHeader, Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { State } from 'stores/rootReducer'
import { ROUTES } from 'config/routes'
import { fetchUser } from 'AC/users/getUser'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'

import { UserInfo } from './UserInfo'
import { Subscriptions } from './Subscriptions'
import { Orders } from './Orders'
import styles from './styles.module.sass'


const { TabPane } = Tabs


const selector = (state: State) => ({
  user: state.usersReducer.user,
  isLoading: state.requestsReducer.fetchUser === StatusRequest.LOADING
})

export const User = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, isLoading } = useSelector(selector)

  useEffect(()=>{
    dispatch(fetchUser(id))
  }, [id, dispatch])

  if(isLoading) return <Loader />

  return (
    <div className={styles.main}>
      <PageHeader
        className={styles.titleBlock}
        onBack={
          () => {
            history.push(ROUTES.USERS.path)
          }
        }
        title={
          <div className={styles.title}>
            { user?.phone }
          </div>
        }
      />
      <Tabs
        defaultActiveKey='1'
        className={styles.tabContainer}
        destroyInactiveTabPane
      >
        <TabPane
          tab='Автомобили'
          key='1'
          className={styles.userInfoContainer}
        >
          <UserInfo user={user} />
        </TabPane>
        <TabPane
          tab='Абонементы'
          key='2'
          className={styles.userInfoContainer}
        >
          <Subscriptions user={user} />
        </TabPane>
        <TabPane
          tab='Заказы'
          key='3'
          className={styles.userInfoContainer}
        >
          <Orders user={user} />
        </TabPane>
      </Tabs>
    </div>
  )
}