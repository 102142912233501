import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { fetchShiftDoers } from 'AC/shifts/shifts'
import { APITYPES } from 'types/apitypes'

import { ChangeDoerShiftModal } from './ChangeDoerShiftModal'
import { ChangeDoerStatusModal } from './ChangeDoerStatusModal'
import { ColFilters, getColumns, getRows } from './utils'


type Props = {
  shiftRoles: APITYPES.ShiftRole[]
  doers: APITYPES.ShiftDoer[]
  isLoading: boolean
  shift: APITYPES.Shift
}

type CloseModalType = {
  isOpen: boolean
  doer: APITYPES.ShiftDoer | null
}

const selector = (state: State) => ({ filter: state.shiftsReducer.shiftDoersFilter })

export const ShiftTabDoers = (props: Props) => {
  const dispatch = useDispatch()
  const { filter } = useSelector(selector)
  const { doers, isLoading, shiftRoles, shift } = props
  const nameRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)

  const columnsFilters: ColFilters = {
    shiftRoles,
    nameRef,
    phoneRef,
    defaultFilteredStatus: filter.state_name ? filter.state_name : undefined,
  }

  const [statusModal, setStatusModal] = useState<CloseModalType>({
    isOpen: false,
    doer: null,
  })
  const toggleStatusModal = (doer?: APITYPES.ShiftDoer) => setStatusModal({
    isOpen: !statusModal.isOpen,
    doer: doer?.doer_shift_id ? doer : null,
  })

  const [changeShiftModal, setShiftModal] = useState<CloseModalType>({
    isOpen: false,
    doer: null,
  })
  const toggleShiftModal = (doer?: APITYPES.ShiftDoer) => setShiftModal({
    isOpen: !changeShiftModal.isOpen,
    doer: doer?.doer_shift_id ? doer : null,
  })

  const handleFilter = (_: any, filters: any) => {
    const newState = filters.doer_shift_state ? filters.doer_shift_state[0] : undefined

    if (newState === filter.state_name) {return}

    dispatch(action(Actions.SET_SHIFT_DOERS_FILTER, { state_name: newState }))
  }

  useEffect(() => {
    dispatch(fetchShiftDoers(shift.id, { state_name: filter.state_name }))
  }, [filter])

  return (
    <>
      <Table
        sticky
        columns={getColumns(columnsFilters, toggleStatusModal, toggleShiftModal) as any}
        dataSource={getRows(doers || [])}
        bordered
        pagination={false}
        loading={isLoading}
        onChange={handleFilter}
        scroll={{ y: doers.length ? 516 : undefined }}
      />

      <ChangeDoerStatusModal
        shiftId={shift.id}
        isOpen={statusModal.isOpen}
        doer={statusModal.doer}
        onClose={toggleStatusModal}
      />

      <ChangeDoerShiftModal
        shift={shift}
        isOpen={changeShiftModal.isOpen}
        doer={changeShiftModal.doer}
        onClose={toggleShiftModal}
      />
    </>
  )
}
