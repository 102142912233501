import React from 'react'
import { Tag } from 'antd'
import { APITYPES } from 'types/apitypes'
import { dateTimeFormat, getFormattedDate } from 'utils/dateUtils'

const { OrderState } = APITYPES

enum StateTagColors {
  done = 'orange',
  inprogress = 'green',
  created = 'blue',
  canceled = 'gray'
}

enum StateTagText {
  done = 'Истек',
  inprogress = 'В процессе',
  created = 'Создан',
  canceled = 'Отменен',
}

const dateFormat = 'DD-MM-YYYY HH:mm'

export const getServiceColumns = () => {
  return [
    {
      title: 'Название',
      dataIndex: 'service_name',
      key: 'service_name',
    },
    {
      title: 'Цена по промокоду',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Цена',
      dataIndex: 'price_full',
      key: 'price_full',
    },
  ]
}

export const getServiceRows = (services: APITYPES.OrderService[]) => {
  return services.map((service) => {
    const row: any = {}
    getServiceColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = service[column.dataIndex]

      return
    })

    row.key = service.service_id

    return row
  })
}


const columns = [
  {
    title: 'Название',
    dataIndex: 'carwash_id',
    key: 'carwash_id',
  },
  {
    title: 'Промокод',
    dataIndex: 'promocode',
    key: 'promocode',
    width: '20%',
    align: 'center',
  },
  {
    title: 'Статус',
    dataIndex: 'state_name',
    key: 'state_name',
    width: '20%',
    align: 'center',
    filters: Object.keys(OrderState).map(status => ({
      //@ts-ignore
      text: StateTagText[status],
      value: status,
    })),
    filterMultiple: false,
    //@ts-ignore
    render: (record: string) => <Tag color={StateTagColors[record]}>{StateTagText[record]}</Tag>
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
    width: '20%',
    align: 'center',
  },
]

export const getColumns = () => columns


export const getRows = (orders: APITYPES.UserOrder[], cars: APITYPES.UserCar[]) => {
  if(!cars) return

  return orders.map((order) => {
    const row: any = {}
    columns.map((column) => {
      if(column.dataIndex === 'created') {
        row[column.dataIndex] = getFormattedDate(order[column.dataIndex], dateTimeFormat)
      } else if (column.dataIndex === 'carwash_id') {
        row[column.dataIndex] = order.carwash_id
      } else if(column.dataIndex === 'promocode') {
        row[column.dataIndex] = order[column.dataIndex] ? order[column.dataIndex] : '-'
      } else {
        //@ts-ignore
        row[column.dataIndex] = order[column.dataIndex]
      }

      return
    })

    row.key = order.id

    row.services = order.services

    return row
  })
}