import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ROUTES } from 'config/routes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { FilterIcon } from 'components/FilterIcon'
import { isObjectEmpty } from 'utils/filterUtils'
import { fetchDoerTariffsGroups } from 'AC/doers/doerTariffs'

import { Filters } from './Filters'
import { AddDoerTariffModal } from './AddDoerTariffModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  tariffs: state.doerTariffsReducer.doerTariffs,
  roles: state.doersReducer.roles,
  functions: state.doerTariffsReducer.doerTariffFunctions,
  isLoading: state.requestsReducer.fetchDoerTariffs === StatusRequest.LOADING,
  filter: state.doerTariffsReducer.doerTariffsFilter,
  billSyncState: state.doerTariffsReducer.billSyncStateV2,
  tariffGroups: state.doerTariffsReducer.doerTariffsGroups,
})

export const DoerTariffs = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    tariffs,
    isLoading,
    roles,
    functions,
    filter,
    tariffGroups,
  } =
    useSelector(selector)

  const [isFilterOpen, setFilterOpen] = useState(false)
  const toggleFilters = () => setFilterOpen((prev) => !prev)

  const [addDoerModal, setAddDoerModal] = useState(false)
  const toggleAddModal = () => setAddDoerModal((prev) => !prev)

  const toTariff = (id: string) => {
    history.push(ROUTES.DOERS.TARIFFS.createPath(id))
  }


  useEffect(() => {
    if (tariffGroups?.length === 0) {
      dispatch(fetchDoerTariffsGroups())
    }
  }, [dispatch, tariffGroups])

  return (
    <>
      <div className={styles.toolbar}>
        <Button
          type='primary'
          onClick={toggleAddModal}
        >
          Создать
        </Button>
        <Button
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          size='small'
          onClick={toggleFilters}
        />
      </div>

      <Table
        sticky
        bordered
        loading={isLoading}
        className={styles.table}
        columns={getColumns(roles || [], functions) as any}
        dataSource={getRows(tariffs || [], tariffGroups || [])}
        pagination={false}
        scroll={{ y: getTableYScroll(184) }}
        onRow={(record: any) => ({ onClick: () => toTariff(record.key) })}
      />

      <Filters
        isOpen={isFilterOpen}
        onClose={toggleFilters}
      />

      <AddDoerTariffModal
        isOpen={addDoerModal}
        onClose={toggleAddModal}
      />
    </>
  )
}
