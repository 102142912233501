import { Tag } from 'antd'
import React from 'react'

import { APITYPES } from 'types/apitypes'

import styles from './styles.module.sass'


const columns = [
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    fixed: 'left',
    width: 300,
  },
  {
    title: 'Автомобили',
    dataIndex: 'cars',
    key: 'cars',
    render: (value: APITYPES.User) => {
      return (
        <div
          className={styles.cars}
          key={value.id}
        >
          {
            value.cars?.map((item, i)=>(
              <Tag
                className={styles.car}
                color='orange'
                key={`${i}_${item?.grz}`}
              >
                { item?.grz }
              </Tag>
            ))
          }
        </div>
      )
    },
  },
]

export const getColumns = () => columns

export const getRows = (users: APITYPES.User[]) => {
  const data = users.map((user) => {
    const row: any = {}

    columns.map((column) => {
      if (column.dataIndex === 'cars') { return }
      //@ts-ignore
      row[column.dataIndex] = user[column.dataIndex]

      return
    })

    row.cars = user

    row.key = user.id
    
    return row
  })

  return data
}