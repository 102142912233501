import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Table } from 'antd'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { fetchCarModelInfo } from 'AC/directory/directory'
import { Loader } from 'components/Loader'
import { getTableYScroll } from 'utils/scrollUtils'

import { CarClassCarDeleteModal } from './CarClassCarDeleteModal'
import { AddForm } from './AddForm'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'
import { getUrlParam } from 'utils/urlUtils'


const selector = (state: State) => {
  const currentModel = state.directoryReducer.currentCarModelInfo
  const car_classes = currentModel?.car_classes || []
  const allCarClasses = state.directoryReducer.carClasses || []

  const isCarModelInfoLoading = state.requestsReducer.fetchCarModelInfo === StatusRequest.LOADING
  const isAddCarClassCarLoading = state.requestsReducer.addCarClassCar === StatusRequest.LOADING
  const isDeleteCarClassCarLoading = state.requestsReducer.deleteCarClassCar === StatusRequest.LOADING
  const isUpdateCarClassCarLoading = state.requestsReducer.fetchCarModelCarClasses === StatusRequest.LOADING

  const isLoading = isCarModelInfoLoading
  const isTableLoading = isDeleteCarClassCarLoading || isAddCarClassCarLoading || isUpdateCarClassCarLoading


  return {
    currentModel,
    car_classes,
    allCarClasses,
    isLoading,
    isTableLoading,
  }
}

export const CarModel = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation();

  const { currentModel, car_classes, allCarClasses, isLoading, isTableLoading } = useSelector(selector)
  const model_id = getUrlParam(pathname, 2);
  const brand_id = getUrlParam(pathname, 1)

  useEffect(() => {
    if(!model_id || !brand_id) return
    dispatch(fetchCarModelInfo(brand_id, model_id))
  }, [model_id, brand_id])

  const [isAddModal, setAddModal] = useState(false)
  const toggleAddModel = () => setAddModal(!isAddModal)

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<{
    isOpen: boolean
    currentClass: APITYPES.CarClass | null
  }>({
    isOpen: false,
    currentClass: null,
  })

  const onCarClassClick = (carClass: APITYPES.CarClass) => {
    setDeleteModalOpen({
      isOpen: true,
      currentClass: carClass,
    })
  }

  const onCarClassCancel = () => {
    setDeleteModalOpen({
      isOpen: false,
      currentClass: null,
    })
  }

  if (!currentModel) { return <Loader /> }

  const avatar = isLoading ? { src: <Loader size='small' /> } : undefined

  return (
    <>
      <PageHeader
        className={styles.title}
        title={<div>{ !isLoading && `${currentModel.brand_name} : ${currentModel.name}` }</div>}
        avatar={avatar}
      />

      <Button
        type='primary'
        className={styles.addButton}
        onClick={toggleAddModel}
        disabled={isLoading}
      >
        Привязать к классу
      </Button>
      {
        car_classes && (
          <Table
            sticky
            loading={isTableLoading || isLoading}
            columns={getColumns() as any}
            className={styles.table}
            dataSource={getRows(car_classes)}
            bordered
            size='middle'
            pagination={false}
            scroll={{ y: getTableYScroll(270.5) }}
            onRow={(record: any) => ({ onClick: () => onCarClassClick(record.car) })}
          />
        )
      }

      <AddForm
        isVisible={isAddModal}
        onCancel={toggleAddModel}
        carClasses={allCarClasses}
        carModel={currentModel}
      />
      <CarClassCarDeleteModal
        isOpen={isDeleteModalOpen.isOpen}
        onCancel={onCarClassCancel}
        carClass={isDeleteModalOpen.currentClass}
        carModelId={currentModel.id}
      />
    </>
  )
}