// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--Ej21l{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--menu--BxLD6{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;text-align:center}.styles-module--tag--dcqgB{margin:0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/Penalties/Status/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,iBAAA,CAEF,2BACE,QAAA","sourcesContent":[".container\n  display: flex\n  justify-content: center\n  align-items: center\n\n.menu\n  display: flex\n  flex-direction: column\n  text-align: center\n\n.tag\n  margin: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--Ej21l",
	"menu": "styles-module--menu--BxLD6",
	"tag": "styles-module--tag--dcqgB"
};
export default ___CSS_LOADER_EXPORT___;
