import { Table } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getRows } from './utils/getRows'
import { getColumns } from './utils/getColumns'
import { getTableYScroll } from '../../../../../utils/scrollUtils'
import { State } from '../../../../../stores/rootReducer'
import { StatusRequest } from '../../../../../stores/requestsReducer'


interface Props {
  setMotivationIds: (ids: string[]) => void
  motivationIds: string[]
}

const selector = (state: State) => {
  return {
    motivationStatistics: state.motivationStatisticsReducer.motivationStatistics,
    isLoading: state.requestsReducer.getMotivationStatistics === StatusRequest.LOADING,
    isLoaded: state.requestsReducer.getMotivationStatistics === StatusRequest.LOADED,
  }
}

export const CalcDoersMotivationsTable = ({ setMotivationIds, motivationIds }: Props) => {

  const { motivationStatistics, isLoading, isLoaded } = useSelector(selector)
  const defaultIds = motivationStatistics?.map((el) => el.id)

  useEffect(() => {
    if (!motivationIds.length && isLoaded) {
      setMotivationIds(defaultIds)
    }
  }, [isLoaded])

  return (
    <Table
      sticky
      size='large'
      loading={isLoading}
      columns={getColumns() as any}
      //@ts-ignore
      dataSource={getRows(motivationStatistics)}
      pagination={false}
      scroll={{ y: getTableYScroll(500) }}
      bordered
      style={{ cursor: 'pointer' }}
      rowSelection={
        {
          type: 'checkbox',
          onChange: (selectedRowKeys ) => {
            setMotivationIds(selectedRowKeys as string[])
          },
          selectedRowKeys: motivationIds,
        }
      }
    />
  )
}