import React, {
  useEffect,
  useState,
} from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
} from 'antd'
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import {
  isEmpty,
  omitBy,
} from 'lodash'

import { useDebounce } from 'utils/debounce'
import { getCitiesOptions } from 'utils/enumUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import {
  action,
  Actions,
} from 'actions'
import {
  backDateFormat,
  dateFormat,
} from 'utils/dateUtils'
import { filterEmptyValuesV2 } from 'utils/urlUtils'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import {
  filterSelectionOptions,
  isObjectEmpty,
} from 'utils/filterUtils'
import { statusNamesMap } from '../../utils/getRows'

import cls from './styles.module.sass'


const states = Object.entries(statusNamesMap).map(([key, value]) => ({
  label: value,
  value: key,
}))

const cities = getCitiesOptions()

const selector = (state: State) => ({
  roles: state.doersReducer.roles,
  storedFilters: state.mailingReducer.mailingFilter,
})

export const Filters = () => {
  const [form] = Form.useForm()
  const cityId = form.getFieldValue('cityId')
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const history = useHistory()

  const { roles, storedFilters } = useSelector(selector)

  const [toggle, setToggle] = useState(true)
  const [searchValue, setSearchValue] = useState<string | undefined>(queryParams.search || undefined)
  const debouncedSearch = useDebounce(searchValue, 500)
  const isAdditionalFiltersEmpty = isObjectEmpty(filterEmptyValuesV2(form.getFieldsValue(['date', 'cityId', 'roleId'])))

  const onClickToggle = () => {
    setToggle((prev) => !prev)
  }

  const updateQueryParams = (filterValues: Record<string, string>) => {
    const params = new URLSearchParams(filterValues)

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  useEffect(() => {
    if (!isEmpty(queryParams)) {
      dispatch(action(Actions.SET_MAILING_FILTER, queryParams))
    } else if (!isEmpty(storedFilters)) {
      updateQueryParams(storedFilters as any)
      setSearchValue(storedFilters.name)
      setTimeout(() => {
        form.resetFields()
      })
    }
  }, [dispatch])

  const onFormChange = () => {
    const formValues = form.getFieldsValue()

    const date = formValues.date?.length === 2 ? formValues.date : []
    const date_from = date[0] ? moment(date[0]).format(backDateFormat) : ''
    const date_to = date[1] ? moment(date[1]).format(backDateFormat) : ''

    const filterValues = omitBy(filterEmptyValuesV2({
      ...formValues,
      date_from,
      date_to,
      name: debouncedSearch,
    }), isEmpty)

    delete filterValues.date
    dispatch(action(Actions.SET_MAILING_FILTER, filterValues))
    updateQueryParams(filterValues)
  }

  useEffect(() => {
    // TODO: KZ: useDebounce return undefined values
    if (debouncedSearch !== undefined) {
      onFormChange()
    }
  }, [debouncedSearch])

  const onClickReset = () => {
    form.setFieldValue('state_name', undefined)
    form.setFieldValue('city_id', [])
    form.setFieldValue('role_id', [])
    form.setFieldValue('date', [])
    setSearchValue('')
    onFormChange()
  }

  const getFormValuesFromQueryParams = () => ({
    ...queryParams,
    city_id: queryParams.city_id ? queryParams.city_id.split(',')
      .map((value: string) => Number.parseInt(value)) : undefined,
    role_id: queryParams.role_id ? queryParams.role_id.split(',') : undefined,
    date: queryParams.date_from ? [moment(queryParams.date_from), moment(queryParams.date_to)] : undefined,
  })

  const filterRoles = (role: APITYPES.Role) => {
    if (cityId && role.city_id) {
      return role.city_id === cityId
    }

    return role
  }
  const filteredRoles = roles?.filter(filterRoles)

  return (
    <div className={cls.wrapper}>
      <div className={cls.mainSection}>
        <Input
          allowClear
          placeholder='Введите название'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          defaultValue={queryParams.name}
        />

        <Button
          onClick={onClickToggle}
          type={isAdditionalFiltersEmpty ? 'default' : 'primary'}
        >
          { toggle ? <UpOutlined /> : <DownOutlined /> }
        </Button>
        <Button onClick={onClickReset}>Сбросить</Button>

      </div>
      <Form
        form={form}
        className={classNames(cls.expandedSection, { [cls.hidden]: !toggle })}
        onFieldsChange={onFormChange}
        initialValues={getFormValuesFromQueryParams()}
      >
        <Form.Item
          name='state_name'
          label='Статус'
        >
          <Select
            allowClear
            className={cls.select}
          >
            {
              states.map((state) => (
                <Select.Option
                  key={state.value}
                  value={state.value}
                >
                  { state.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='date'
          className={cls.formItem}
        >
          <DatePicker.RangePicker
            className={cls.select}
            format={dateFormat}
          />
        </Form.Item>
        <Form.Item
          name='city_id'
          className={cls.formItem}
        >
          <Select
            className={cls.select}
            mode='multiple'
            allowClear
            placeholder='Выберите город'
          >
            {
              cities.map((city) => (
                <Select.Option
                  className={cls.select}
                  key={city.value}
                  value={city.value}
                >
                  { city.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='role_id'
          className={cls.formItem}
        >
          <Select
            className={cls.select}
            placeholder='Роли'
            allowClear
            mode='multiple'
            showSearch
            filterOption={filterSelectionOptions}
          >
            {
              filteredRoles?.map((role: APITYPES.Role) => (
                <Select.Option
                  className={cls.select}
                  key={role.id}
                  label={role.name}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Form>

    </div>
  )
}