import React from 'react'
import { Button, Modal, Row, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { deleteOperation } from 'AC/shifts/operations'


type Props = {
  isOpen: boolean
  onClose: () => void
  selectedOperation: any
}

export const DeleteOperationModal = (props: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { onClose, isOpen, selectedOperation } = props

  const onDelete = () => {
    if (!selectedOperation) return
    dispatch(deleteOperation(selectedOperation.id, history))
    onClose()
  }

  return (
    <Modal
      title={
        <Row>
          <Space size={10}>
            <ExclamationCircleOutlined style={{
              color: "#faad14", 
              fontSize: "24px",
              verticalAlign: "middle" 
            }}/>
            Вы точно хотите совершить данное действие?
          </Space>

        </Row>
      }
      visible={isOpen}
      footer={false}
      onCancel={onClose}
    >
      <Row justify="end">
        <Space size={12}>
          <Button onClick={() => onClose()}>Нет</Button>
          <Button
            type="primary"
            onClick={() => onDelete()}
          >
            Да
          </Button>
        </Space>


      </Row>
    </Modal>
  );
};