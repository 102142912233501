import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getDoerPenalty } from '../../../../../AC/doers/penalties'
import { Loader } from '../../../../../components/Loader'

import styles from './styles.module.sass'
import { PenaltyInfo } from './PenaltyInfo'
import { PenaltyRecords } from './PenaltyRecords'


type Props = {
  penaltyId: string
}

const selector = (state: State) => ({
  doerPenalties: state.motivationsReducer.doerPenalties,
  isLoading: state.requestsReducer.getDoerPenalty === StatusRequest.LOADING,
})

export const PenaltyTab = ({ penaltyId }: Props) => {
  const { doerPenalties, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const doerPenalty = doerPenalties[penaltyId]

  useEffect(() => {
    if (!doerPenalty) {
      dispatch(getDoerPenalty(penaltyId))
    }
  }, [doerPenalty])
  
  if (!doerPenalty) {return <Loader />}

  return (
    <div className={styles.container}>
      <PenaltyInfo penalty={doerPenalty} />
      <div className={styles.content}>
        <PenaltyRecords
          records={doerPenalty.records}
          penaltyValue={doerPenalty.value}
          loading={isLoading}
        />
      </div>
    </div>
  )
}
