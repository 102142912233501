import React, { RefObject } from 'react'
import { Button, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FilterDropdownProps } from 'antd/es/table/interface'

import { APITYPES } from 'types/apitypes'
import { FilterSearch } from 'components/FilterSearch'
import { getEnumKeys } from 'utils/enumUtils'

import styles from './styles.module.sass'


const { ShiftDoerStateTranslations } = APITYPES
const statusKeys = getEnumKeys(ShiftDoerStateTranslations)

const { ShiftDoerStateEnum, ShiftDoerStateColors } = APITYPES

export const doerShiftStatusMapToUrl: Record<string, string> = {
  [ShiftDoerStateEnum.started]: 'start',
  [ShiftDoerStateEnum.ended]: 'end',
  [ShiftDoerStateEnum.canceled]: 'cancel',
  [ShiftDoerStateEnum.confirmed]: 'confirm',
  [ShiftDoerStateEnum.failed]: 'fail',
}

export type ColFilters = {
  shiftRoles: APITYPES.ShiftRole[]
  phoneRef: RefObject<HTMLInputElement> | null
  nameRef: RefObject<HTMLInputElement> | null
  defaultFilteredStatus: APITYPES.ShiftDoerStateOptions | undefined
}

export const getColumns = (
  filters: ColFilters = {
    shiftRoles: [],
    phoneRef: null,
    nameRef: null,
    defaultFilteredStatus: undefined,
  }, openModal?: (doer: APITYPES.ShiftDoer) => void,
  openChangeShiftModal?: (doer: APITYPES.ShiftDoer) => void) => {
  const { shiftRoles, nameRef, phoneRef, defaultFilteredStatus } = filters
  const toggleModal = openModal ? openModal : () => {
  }
  const toggleChangeShiftModal = openChangeShiftModal ? openChangeShiftModal : () => {
  }

  return [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {setTimeout(() => nameRef?.current?.focus(), 200)}
      },
      onFilter: (value: string, record: any) =>
        record['name']
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      filterDropdown: ({ confirm, setSelectedKeys }: FilterDropdownProps) => {
        const doFiltering = (e: React.ChangeEvent<HTMLInputElement>) => {
          setSelectedKeys(e.target.value ? [e.target.value] : [])
          confirm({ closeDropdown: false })
        }
        const reset = () => {
          if (nameRef && nameRef.current) {
            const event = { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>

            doFiltering(event)
            //@ts-ignore
            nameRef.current.input.value = ''
            confirm({ closeDropdown: true })
          }
        }

        return (
          <FilterSearch
            //@ts-ignore
            value={nameRef?.current?.input.value}
            onChange={doFiltering}
            onDrop={reset}
            inputRef={nameRef}
            placeholder='Поиск по имени'
          />
        )
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 120,
      align: 'center',
      filterIcon: (filtered: boolean) =>
        <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {setTimeout(() => phoneRef?.current?.focus(), 200)}
      },
      onFilter: (value: string, record: any) =>
        record['phone_number']
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      filterDropdown: ({ confirm, setSelectedKeys, clearFilters }: FilterDropdownProps) => {
        const doFiltering = (e: React.ChangeEvent<HTMLInputElement>) => {
          setSelectedKeys(e.target.value ? [e.target.value] : [])
          confirm({ closeDropdown: false })
        }
        const reset = () => {
          if (phoneRef && phoneRef.current && clearFilters) {
            const event = { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>

            doFiltering(event)
            //@ts-ignore
            phoneRef.current.input.value = ''
            confirm({ closeDropdown: true })
          }
        }

        return (
          <FilterSearch
            //@ts-ignore
            value={phoneRef?.current?.input.value}
            onChange={doFiltering}
            onDrop={reset}
            inputRef={phoneRef}
            placeholder='Поиск по номеру телефона'
          />
        )
      },
      render: (phone_number: string) => (<>{ phone_number ? phone_number : '-' }</>),
    },
    {
      title: 'Статус',
      dataIndex: 'doer_shift_state',
      key: 'doer_shift_state',
      width: 160,
      align: 'center',
      //@ts-ignore
      filters: statusKeys.map((status) => ({
        text: ShiftDoerStateTranslations[status],
        value: status,
      })),
      filteredValue: defaultFilteredStatus ? [defaultFilteredStatus] : undefined,
      filterMultiple: false,
      render: (doer: APITYPES.ShiftDoer) => (
        <Tag
          className={styles.statusTag}
          color={ShiftDoerStateColors[doer.doer_shift_state.name]}
          onClick={() => toggleModal(doer)}
        >
          { ShiftDoerStateTranslations[doer.doer_shift_state.name] }
        </Tag>
      ),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      filters: shiftRoles && shiftRoles.map((role) => ({
        text: role.name,
        value: role.name,
      })),
      onFilter: (value: string, record: any) => value.toLowerCase().includes(record.role.toLowerCase()),
    },
    {
      title: 'Количество операций',
      dataIndex: 'operations_count',
      key: 'operations_count',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (doer: APITYPES.ShiftDoer) => {
        const isRedirected = doer.doer_shift_state.name === APITYPES.ShiftDoerStateEnum.redirected


        return (
          <Button
            disabled={isRedirected}
            type='link'
            onClick={() => toggleChangeShiftModal(doer)}
          >
            Изменить
          </Button>
        )
      },
    },
  ]
}

export const getRows = (doers: APITYPES.ShiftDoer[]) => {
  return doers.map((doer) => {
    const row: any = {}

    getColumns(undefined).map((column) => {
      if (column.dataIndex === 'location') {
        return
      } else if (column.dataIndex === 'role') {
        row[column.dataIndex] = doer[column.dataIndex].name
      } else if (column.dataIndex === 'doer_shift_state' || column.dataIndex === 'actions') {
        row[column.dataIndex] = doer
      } else {
        //@ts-ignore
        row[column.dataIndex] = doer[column.dataIndex]
      }

      return
    })
    row.key = doer.id

    return row
  })
}