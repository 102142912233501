import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'

import { useDebounce } from 'utils/debounce'
import { getCitiesOptions } from 'utils/enumUtils'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { fetchRoles } from 'AC/doers/doerRoles'
import { action, Actions } from 'actions'
import { backDateFormat, dateFormat } from 'utils/dateUtils'
import { filterEmptyValues, filterEmptyValuesV2 } from 'utils/urlUtils'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { filterSelectionOptions, isObjectEmpty } from 'utils/filterUtils'
import { useGetLocations } from '../../../Vacancies/hooks/useGetLocations'

import cls from './internships-filter.module.sass'


const cities = getCitiesOptions()

const selector = (state: State) => ({
  roles: state.doersReducer.roles,
  filters: state.internshipsReducer.filters,
})

export const InternshipsFilter = () => {
  const [form] = Form.useForm()
  const cityId = form.getFieldValue('cityId')
  const dispatch = useDispatch()
  const queryParams = useQueryParams()

  const first = useRef(true)
  const {
    roles,
    filters,
  } = useSelector(selector)

  const [toggle, setToggle] = useState(true)
  const [searchValue, setSearchValue] = useState<string>(queryParams.search || '')
  const debouncedSearch = useDebounce(searchValue, 500)
  const locations = useGetLocations({ city_id: cityId })
  const isAdditionalFiltersEmpty = isObjectEmpty(filterEmptyValuesV2(form.getFieldsValue(['date', 'cityId', 'roleId'])))

  const onClickToggle = () => {
    setToggle((prev) => !prev)
  }

  const onFormChange = () => {
    const formValues = form.getFieldsValue()

    const date = formValues.date?.length === 2 ? formValues.date : []
    const dateFrom = date[0] ? moment(date[0]).format(backDateFormat) : ''
    const dateTo = date[1] ? moment(date[1]).format(backDateFormat) : ''

    const filterValues = filterEmptyValues({
      ...formValues,
      dateFrom,
      dateTo,
      search: debouncedSearch,
      page: filters?.page,
      limit: filters?.limit,
      understaffed: formValues.understaffed,
    })

    delete filterValues.date
    dispatch(action(Actions.SET_INTERNSHIPS_FILTER, filterValues))
  }

  const onClickReset = () => {
    form.resetFields()
    form.setFieldValue('date', [])
    form.setFieldValue('understaffed', 'false') // default value
    setSearchValue('')
    onFormChange()
  }

  const onCityChange = () => {
    form.setFieldsValue({ locationId: undefined })
    onFormChange()
  }

  const filterRoles = (role: APITYPES.Role) => {
    if (cityId && role.city_id) {
      return role.city_id === cityId
    }

    return role
  }
  const filteredRoles = roles?.filter(filterRoles)

  useEffect(() => {
    if (first.current) {
      return
    }
    onFormChange()
  }, [debouncedSearch])

  useEffect(() => {
    first.current = false
    if (!roles?.length) {
      dispatch(fetchRoles())
    }
    const formValues = {
      ...queryParams,
      dateFrom: queryParams.dateFrom ? moment(queryParams.dateFrom) : moment(),
      dateTo: queryParams.dateTo ? moment(queryParams.dateTo) : moment(),
      cityId: queryParams.cityId ? Number(queryParams.cityId) : undefined,
      understaffed: queryParams.understaffed || 'false',
    }

    form.setFieldsValue(formValues)

    dispatch(action(Actions.SET_INTERNSHIPS_FILTER, {
      ...queryParams,
      page: queryParams.page ? Number(queryParams.page) : filters?.page,
      limit: queryParams.limit ? Number(queryParams.limit) : filters?.limit,
      dateFrom: moment(queryParams.dateFrom).format(backDateFormat),
      dateTo: moment(queryParams.dateTo).format(backDateFormat),
    }))
  }, [])

  const dateInitValue = [
    queryParams.dateFrom ? moment(queryParams.dateFrom) : moment(),
    queryParams.dateTo ? moment(queryParams.dateTo) : moment(),
  ]

  return (
    <div className={cls.wrapper}>
      <div className={cls.mainSection}>
        <Input
          allowClear
          placeholder='Введите ФИО или телефон'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Button
          onClick={onClickToggle}
          type={isAdditionalFiltersEmpty ? 'default' : 'primary'}
        >
          { toggle ? <UpOutlined /> : <DownOutlined /> }
        </Button>
        <Button onClick={onClickReset}>Сбросить</Button>

      </div>
      <Form
        form={form}
        className={classNames(cls.expandedSection, { [cls.hidden]: !toggle })}
        onFieldsChange={onFormChange}
        initialValues={
          { date: dateInitValue }
        }
      >
        <Form.Item
          name='date'
          className={cls.formItem}
        >
          <DatePicker.RangePicker
            className={cls.select}
            format={dateFormat}
          />
        </Form.Item>
        <Form.Item
          name='cityId'
          className={cls.formItem}
        >
          <Select
            className={cls.select}
            allowClear
            placeholder='Выберите город'
            onChange={onCityChange}
          >
            {
              cities.map((city) => (
                <Select.Option
                  className={cls.select}
                  key={city.value}
                  value={city.value}
                >
                  { city.label }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='locationId'
          className={cls.formItem}
        >
          <Select
            allowClear
            showSearch
            placeholder='Выберите локацию'
            filterOption={filterSelectionOptions}
            className={cls.select}
          >
            {
              locations?.map((location) => (
                <Select.Option
                  key={location.id}
                  label={location.name}
                  value={location.id}
                >
                  { location.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='roleId'
          className={cls.formItem}
        >
          <Select
            className={cls.select}
            placeholder='Роли'
            allowClear
            showSearch
            filterOption={filterSelectionOptions}
          >
            {
              filteredRoles?.map((role: APITYPES.Role) => (
                <Select.Option
                  className={cls.select}
                  key={role.id}
                  label={role.name}
                  value={role.id}
                >
                  { role.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='understaffed'
          className={cls.formItem}
        >
          <Select
            className={cls.select}
            placeholder='Тип слота'
            allowClear
            showSearch
            filterOption={filterSelectionOptions}
          >
            <Select.Option
              className={cls.select}
              default
              value='false'
            >
              Все
            </Select.Option>
            <Select.Option
              className={cls.select}
              value='true'
            >
              Только свободные слоты
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>

    </div>
  )
}