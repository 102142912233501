import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { fetchShifts } from 'AC/shifts/shifts'


const FilterContext = React.createContext<any>({})


/** context value */
type MomentRange = [moment.Moment, moment.Moment]
const startOfDay = 'YYYY-MM-DD 00:00:00'
const endOfDay = 'YYYY-MM-DD 23:59:59'
const dateFormat = 'YYYY-MM-DD'

const selector = (state: State) => ({ shiftsFilter: state.shiftsReducer.shiftsFilter })


export const ShiftsFilterProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { shiftsFilter } = useSelector(selector)
  const filterUrlParams = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const isFirstRef = useRef<boolean>(true)


  const [dates, setDates] = useState<MomentRange>(getMomentRange(undefined,
    {
      date_from: filterUrlParams.date_from ?? shiftsFilter.date_from,
      date_to: filterUrlParams.date_to ?? shiftsFilter.date_to,
    }) as MomentRange)

  useEffect(() => {
    const nonEmptyParams = JSON.parse(JSON.stringify(shiftsFilter))

    if (Object.keys(nonEmptyParams).length) {
      dispatchToFilter(nonEmptyParams)
    } else {
      dispatchToFilter(filterUrlParams)
    }
  }, [])

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false

      return
    }
    dispatch(fetchShifts({
      ...shiftsFilter,
      date_from: dates[0].format(startOfDay),
      date_to: dates[1].format(endOfDay),
    }))
  }, [shiftsFilter])


  /** filter helpers */
  const dispatchToFilter = (keyValPair: Partial<APITYPES.ShiftFilter>) => {
    const newParams = createHistoryWithParams(keyValPair)

    dispatch(action(Actions.SET_SHIFTS_FILTER, { ...newParams }))
  }

  const createHistoryWithParams = (keyValPair: Partial<APITYPES.ShiftFilter>) => {
    const keyOfPair = Object.keys(keyValPair)
    const newParams: any = {}

    for (const key in shiftsFilter) {
      if (keyOfPair.includes(key)) {
        newParams[key] = keyValPair[key as keyof typeof shiftsFilter]
      } else {
        const newKey = shiftsFilter[key as keyof typeof shiftsFilter]

        newParams[key] = newKey ? newKey : undefined
      }
    }

    history.push(ROUTES.DOERS.SHIFTS.PARAMS.createPath(newParams))

    return newParams
  }

  useEffect(() => {
    const partialFilter = {
      ...filterUrlParams,
      date_from: dates[0].format(dateFormat),
      date_to: dates[1].format(dateFormat),
    }

    dispatchToFilter(partialFilter)
  }, [dates])

  const onDateChange = (dates: MomentRange) => {
    setDates(dates)
  }
  const onPredefinedDate = (type?: 'today' | 'endOfWeek' | 'endOfMonth') => {
    setDates(getMomentRange(type) as MomentRange)
  }

  const onCityFilter = (value: number | undefined) => {
    const partialFilter = { city_id: value ? value : undefined }

    dispatchToFilter(partialFilter)
  }

  const onShiftTypeFilter = (value: string | undefined) => {
    const partialFilter = { shift_type_id: value }

    dispatchToFilter(partialFilter)
  }

  const onLocationChange = (value: string | undefined) => {
    const partialFilter = { location_id: value }

    dispatchToFilter(partialFilter)
  }

  const onRolesChange = (value: string | undefined) => {
    const partialFilter = { role_id: value }

    dispatchToFilter(partialFilter)
  }

  const onEmployerChange = (value: string | undefined) => {
    const partialFilter = { employer_id: value }

    dispatchToFilter(partialFilter)
  }

  const dropFilters = () => {
    const emptyFilter = {
      city_id: undefined,
      shift_type_id: undefined,
      location_id: undefined,
      role_id: undefined,
      employer_id: undefined,
    }

    dispatchToFilter(emptyFilter)
  }


  return (
    <FilterContext.Provider value={
      {
        dates,
        shiftsFilter,
        onCityFilter,
        onShiftTypeFilter,
        onLocationChange,
        onRolesChange,
        dropFilters,
        onDateChange,
        onPredefinedDate,
        onEmployerChange,
      }
    }
    >
      { children }
    </FilterContext.Provider>
  )
}


/** context helpers */
const getMomentRange = (type?: 'today' | 'endOfWeek' | 'endOfMonth', fromUrl?: any) => {
  if (fromUrl?.date_from && fromUrl?.date_to) {
    return [moment(fromUrl.date_from).startOf('day'), moment(fromUrl.date_to).endOf('day')]
  }

  switch (type) {
    case 'today':
      return [moment().startOf('day'), moment().endOf('day')]
    case 'endOfWeek':
      return [moment().startOf('day'), moment().endOf('week')]
    case 'endOfMonth':
      return [moment().startOf('day'), moment().endOf('month')]
    default:
      return [moment().startOf('day'), moment().endOf('day').add(2, 'weeks')]
  }
}

/** getContext hook */
export const useShiftsFilterContext = () => {
  const context = useContext(FilterContext)

  if (!context) {
    throw new Error('ShiftsFilterContext is not reachable.')
  }

  return context
}