import React from 'react'
import { Modal, Tabs } from 'antd'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { useFilterDrawerContext } from '../../context'
import { fetchShifts } from 'AC/shifts/shifts'

import { CreateForm } from './CreateForm'
import { ImportForm } from './ImportForm'


const { TabPane } = Tabs

export type MomentType = moment.Moment

export const AddShiftModal = () => {
  const { addShiftModal: isOpen, toggleAddShiftModal: onClose } = useFilterDrawerContext()

  const dispatch = useDispatch()

  const onImportSuccess = () => {
    dispatch(fetchShifts())
    onClose()
  }
  
  return (
    <Modal
      title='Создание периода смен'
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      destroyOnClose
      width={700}
      bodyStyle={{ padding: '0 24px 24px 24px' }}
    >
      <Tabs size='small'>
        <TabPane
          tab='Создать вручную'
          key='create'
        >
          <CreateForm />
        </TabPane>

        <TabPane
          tab='Импортировать'
          key='import'
        >
          <ImportForm onSuccess={onImportSuccess} />
        </TabPane>
      </Tabs>
    </Modal>
  )
}
