import React, { useEffect, useState } from 'react'
import { Card, Transfer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'

import { addOptionToProduct, deleteOptionFromProduct, fetchAllOptions } from 'AC/products/products'
import { Loader } from 'components/Loader'

import { getSourceColumn, getTargetKeys, listStyle, transferTitles } from './utils'
import styles from './styles.module.sass'

type Props = {
  productId: string
  options: APITYPES.ProductOption[] | null
  disabled: boolean
}

const selector = (state: State) => ({
  allOptions: state.productsReducer.allOptions,
  isLoading: state.requestsReducer.fetchAllOptions === StatusRequest.LOADING
})

export const ProductOptions = ({productId, options, disabled}: Props) => {
  const dispatch = useDispatch()
  const {allOptions, isLoading} = useSelector(selector)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!allOptions.length) dispatch(fetchAllOptions())
  }, [])

  const onChange = (_: string[], direction: string, selectedOptions: string[]) => {
    setLoading(true)
    switch (direction) {
      case 'right':
        addOptions(selectedOptions)
        break
      case 'left':
        deleteOptions(selectedOptions)
        break
    }
  }

  const addOptions = async (addOptions: string[]) => {
    await dispatch(addOptionToProduct(productId, addOptions, () => setLoading(false)))
  }

  const deleteOptions = async (deleteOptions: string[]) => {
    await dispatch(deleteOptionFromProduct(productId, deleteOptions, () => setLoading(false)))
  }

  return (
    <Card className={styles.container} title="Опции" size="small">
      <Transfer
        showSearch
        disabled={disabled}
        onChange={onChange}
        listStyle={listStyle}
        showSelectAll={false}
        titles={transferTitles}
        className={styles.transfer}
        selectAllLabels={[<></>, <></>]}
        render={(item) => item.title}
        targetKeys={getTargetKeys(options || [])}
        dataSource={getSourceColumn(allOptions || [])}
        filterOption={(inputValue, item) => item?.title?.toLowerCase().includes(inputValue)}
      >
        {() => (loading || isLoading) && <div className={styles.loader}><Loader/></div>}
      </Transfer>
    </Card>
  );
};
