import { DatePicker, Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import {  fetchDoerTariffsGroups } from 'AC/doers/doerTariffs'
import { backDateFormat, dateFormat } from 'utils/dateUtils'

import styles from './styles.module.sass'
import { combinedTariffGroups } from './utils'


type M = moment.Moment

const selector = (state: State) => ({ tariffGroups: state.doerTariffsReducer.doerTariffsGroups })

type Props = {
  checkedKey: string
  onChecked: (value: string) => void
  dates: M[] | any
  onDateChange: (dates: M[]) => void
}

export const CalcPayment = ({ onChecked, checkedKey, dates, onDateChange }: Props) => {
  const { tariffGroups } = useSelector(selector)
  const dispatch = useDispatch()

  const [treeData, setTreeData] = useState<any>()


  useEffect(() => {
    if (Array.isArray(dates)) {
      const [begin, end] = dates

      dispatch(fetchDoerTariffsGroups(
        begin.format(backDateFormat),
        end.format(backDateFormat)
      ))
    }
  }, [dispatch, dates])

  useEffect(() => {
    if (tariffGroups) {
      setTreeData(combinedTariffGroups(tariffGroups))
    }
  }, [dispatch, tariffGroups])

  const onCheck = (checkedKeysValue: any) => {
    if (checkedKeysValue.length > 0) {
      onChecked(checkedKeysValue.pop())
    } else {
      onChecked('')
    }
  }

  return (
    <div className={styles.container}>
      <p>Выберите период:</p>
      <div className={styles.datePicker}>
        <DatePicker.RangePicker
          format={dateFormat}
          value={dates}
          onChange={(value) => onDateChange(value as any[])}
        />
      </div>
      <div className={styles.checkBoxContainer}>
        {
          treeData && (
            <Tree
              checkable
              onCheck={onCheck}
              checkedKeys={[checkedKey]}
              treeData={treeData}
            />
          )
        }
      </div>
    </div>
  )
}
