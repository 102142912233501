import React, { useState } from 'react'
import { Button, Menu, Tag , Dropdown } from 'antd'
import { useDispatch } from 'react-redux'
import { DownOutlined } from '@ant-design/icons'

import { showSureConfirm } from 'components/modals/SureModal'
import { APITYPES } from 'types/apitypes'
import { activatePartner } from 'AC/partners/partners'
import { getTranslatePartnerStatus } from 'utils/tagUtils'
import { getColorByStatus } from '../utils'
import { Off } from '../Card/Partner/Off'
import styles from '../styles.module.sass'


type Props = {
  value: APITYPES.Partner
}

export const Status = ({ value }: Props) => {
  const onClick = (e: any) => {
    e.stopPropagation()
  }
  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <Buttons card={value} />
      </Menu.Item>
    </Menu>
  )

  
  return (
    <div onClick={onClick}>
      <Dropdown
        overlay={menu}
        trigger={['click']}
      >
        <a
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
        >
          <Tag
            color={getColorByStatus(value.state.name)}
          >
            { getTranslatePartnerStatus(value.state.name) }
          </Tag>
          { ' ' }
          <DownOutlined />
        </a>
      </Dropdown>
    </div>
  )
}

const Buttons = ({ card }: { card: APITYPES.Partner }) => {
  const dispatch = useDispatch()
  
  const [isOffModal, setIsOffModal] = useState(false)
  
  const onActivate = async (e: any) => {
    e.stopPropagation()
    showSureConfirm(()=>{ dispatch(activatePartner(card.id))}, ()=>{})
  }
  
  const value = card.state.name
  
  const modals = (
    <span onClick={(e: any)=>e.stopPropagation()}>
      <Off
        onCancel={() => setIsOffModal(false)}
        isVisible={isOffModal}
        id={card.id}
        key={card.id}
      />
    </span>
  )
  
  if (value === 'active') {
    return (
      <div className={styles.buttons}>
        { modals }
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsOffModal(true)
            }
          }
          key='2'
        >
          <Tag
            color={getColorByStatus('disconnected')}
          >
            { getTranslatePartnerStatus('disconnected') }
          </Tag>
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.buttons}>
      { modals }
      <Button
        className={styles.button}
        type='text'
        onClick={onActivate}
        key='1'
      >
        <Tag
          color={getColorByStatus('active')}
        >
          { getTranslatePartnerStatus('active') }
        </Tag>
      </Button>
    </div>
  )
}