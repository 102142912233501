import React from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { getEnumOptions } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'
import { addRole } from 'AC/doers/doerRoles'
import { State } from 'stores/rootReducer'


export type Props = {
  isOpen: boolean
  onClose: () => void
}

const formLayout = { labelCol: { span: 4 } }
const selector = (state: State) => ({ roleGroups: state.doersReducer.roleGroups })

export const AddModal = (props: Props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const {  roleGroups } = useSelector(selector)

  const [form] = Form.useForm()

  const cityOptions = getEnumOptions(APITYPES.Cities)

  const onFinish = (values: any) => {
    const params: APITYPES.Doers.Roles.POST.Req = {
      name: values.name,
      group_id: values.group_id,
    }

    if (values.city_id) {
      params.city_id = values.city_id
    }

    dispatch(addRole(params))
    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  const anyCinty = {
    label: 'Любой',
    value: 0,
  }

  return (
    <Modal
      title='Создание новой роли'
      visible={isOpen}
      onCancel={onClose}
      width={620}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >
        <Form.Item
          name='name'
          label='Название'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено.',
            }]
          }
        >
          <Input />
        </Form.Item>


        <Form.Item
          name='group_id'
          label='Группа'
        >
          <Select
            allowClear
            placeholder='Выберите группу'
          >
            {
              roleGroups?.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  { item.name }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>


        <Form.Item
          name='city_id'
          label='Город'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено.',
            }]
          }
        >
          <Select  options={[anyCinty, ...cityOptions]} />
        </Form.Item>

        <FormFooter
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
