// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--title--DGOHI{border:1px solid #ebedf0}.styles-module--addButton--49ZQG{max-width:250px;margin-bottom:16px;margin-top:12px}.styles-module--tableBtn--jFCAW{margin:0;padding:0}.styles-module--table--1HTd8{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/CarClasses/CarClass/styles.module.sass"],"names":[],"mappings":"AAAA,6BACI,wBAAA,CAEJ,iCACI,eAAA,CACA,kBAAA,CACA,eAAA,CAEJ,gCACI,QAAA,CACA,SAAA,CAEJ,6BACI,cAAA","sourcesContent":[".title\n    border: 1px solid rgb(235, 237, 240)\n\n.addButton\n    max-width: 250px\n    margin-bottom: 16px\n    margin-top: 12px\n\n.tableBtn\n    margin: 0\n    padding: 0\n\n.table\n    cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles-module--title--DGOHI",
	"addButton": "styles-module--addButton--49ZQG",
	"tableBtn": "styles-module--tableBtn--jFCAW",
	"table": "styles-module--table--1HTd8"
};
export default ___CSS_LOADER_EXPORT___;
