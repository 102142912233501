// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--LxnEq{display:-webkit-box;display:-ms-flexbox;display:flex;width:50%}.styles-module--form--GHuAH{width:100%}.styles-module--form--GHuAH label{white-space:pre-wrap}.styles-module--field--5iV2m{height:40px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Billing/Bills/Bill/BillInfo/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CAEJ,4BACI,UAAA,CACA,kCACI,oBAAA,CAER,6BACI,WAAA","sourcesContent":[".container \n    display: flex\n    width: 50%\n\n.form\n    width: 100%\n    label\n        white-space: pre-wrap\n\n.field\n    height: 40px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--LxnEq",
	"form": "styles-module--form--GHuAH",
	"field": "styles-module--field--5iV2m"
};
export default ___CSS_LOADER_EXPORT___;
