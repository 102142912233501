import { APITYPES } from 'types/apitypes'
import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { changeRequestState } from '../request'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { getDoerRecordsPeriod } from '../billing/doerRecords'


export const getBillCalculations = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getBillCalculations', StatusRequest.LOADING))
    if (getState().billingReducer.billCalculations.length !== 0) {
      dispatch(
        action(Actions.SET_SAVED_BILL_CALCULATION, { savedBillCalculation: getState().billingReducer.billCalculations[0] })
      )
    }
    try {
      const resp = await api.get(APITYPES.DoerTariff.GetBillCalculations.URL, {
        params: {
          offset: 0,
          limit: 50,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_BILL_CALCULATIONS, {
          billCalculations: resp,
          offset: 0,
        }))
        dispatch(
          changeRequestState('getBillCalculations', StatusRequest.LOADED)
        )


        const savedBill = getState().billingReducer.savedBillCalculation
        const lastBill = resp[0]


        if (savedBill) {
          const isDifferentFinishedState = lastBill.id !== savedBill.id && lastBill.state.name === 'finished'
          const isStateChangedSameElement = lastBill.id === savedBill.id && savedBill.state.name !== 'finished'
                        && lastBill.state.name === 'finished'

          if (isDifferentFinishedState || isStateChangedSameElement) {
            dispatch(action(Actions.SET_SAVED_BILL_CALCULATION, { savedBillCalculation: lastBill }))
            dispatch(action(Actions.CLEAR_DOER_RECORDS_PERIOD,[]))
          }
        } else {
          dispatch(action(Actions.SET_SAVED_BILL_CALCULATION, { savedBillCalculation: lastBill }))
        }
      }
    } catch (e) {
      //@ts-ignore
      showError(e.response.data)

      dispatch(changeRequestState('getBillCalculations', StatusRequest.ERROR))
      throw e
    }
  }
}

export const postBillCalculations = (
  body: APITYPES.DoerTariff.PostBillCalculations.Req,
  onClose: () => void,
  onError: () => void,
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('postBillCalculations', StatusRequest.LOADING))

    try {
      const resp = await api.post(
        APITYPES.DoerTariff.PostBillCalculations.URL,
        body
      )

      if (resp) {
        openNotification('Отправлено')
        dispatch(action(Actions.CLEAN_BILL_CALCULATIONS, {}))
        dispatch(getBillCalculations())
        dispatch(
          changeRequestState('postBillCalculations', StatusRequest.LOADED)
        )
        onClose()
      }
    } catch (e) {
      showError(e?.response?.data || 'Произошла ошибка')
      onError()
      dispatch(changeRequestState('postBillCalculations', StatusRequest.ERROR))
      throw e
    }
  }
}
