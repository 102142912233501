// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--addBtn--9Fvnl{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin:8px 0}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/DoerTariffs/DoerTariff/TariffShiftPricesTable/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,YAAA","sourcesContent":[".addBtn\n  width: fit-content\n  margin: 8px 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtn": "styles-module--addBtn--9Fvnl"
};
export default ___CSS_LOADER_EXPORT___;
