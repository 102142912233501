// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--interval--aIdYT{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module--deleteButton--MBDfb{margin:0 10px 0 20px}.styles-module--item--\\+sw-J{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module--endText--mWmur{margin-left:10px;margin-bottom:20px}.styles-module--startText--LT8eV{margin-right:10px;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/WorkingTime/Day/Interval/styles.module.sass"],"names":[],"mappings":"AAAA,gCACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEJ,oCACI,oBAAA,CAEJ,6BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,+BACI,gBAAA,CACA,kBAAA,CAEJ,iCACI,iBAAA,CACA,kBAAA","sourcesContent":[".interval\n    width: 100%\n    display: flex\n\n.deleteButton\n    margin: 0 10px 0 20px\n\n.item\n    display: flex\n    align-items: center\n\n.endText\n    margin-left: 10px\n    margin-bottom: 20px\n\n.startText\n    margin-right: 10px\n    margin-bottom: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"interval": "styles-module--interval--aIdYT",
	"deleteButton": "styles-module--deleteButton--MBDfb",
	"item": "styles-module--item--+sw-J",
	"endText": "styles-module--endText--mWmur",
	"startText": "styles-module--startText--LT8eV"
};
export default ___CSS_LOADER_EXPORT___;
