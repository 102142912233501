import { ThAction } from 'actions'
import { LocalStorageKeys, setLocalStorageItem } from 'utils/localStorageUtils'
import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer'

import { changeRequestState } from './request'


export const login = (values: { login: string, password: string}): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('login', StatusRequest.LOADING))
      
    try {
      const resp = await api.post(APITYPES.Auth.URL, values)
    
      if (resp.token) {
        setLocalStorageItem(LocalStorageKeys.TOKEN, resp.token)

        location.reload()
    
        api.setAuth(`Bearer ${resp.token}`)
    
        dispatch(changeRequestState('login', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('login', StatusRequest.ERROR))
  
      throw(e)
    }
  }
}