import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ROUTES } from 'config/routes'
import { Partner } from './Partners/Registry/Card/Partner'
import { Carwash } from './Partners/Carwashes/Carwash'
import { ClientTypes } from './Directory/ClientTypes'
import { Services } from './Directory/Services'
import { CarClasses } from './Directory/CarClasses'
import { Cars } from './Directory/Cars'
import { User } from './Users/User'
import { Bill } from './Billing/Bills/Bill'
import { Doers } from './Doers/Doers'
import { Doer } from './Doers/Doers/Doer'
import { Offers } from './Doers/Offers'
import { Offer } from './Doers/Offers/Offer'
import { Operations } from './Doers/Operations'
import { Operation } from './Doers/Operations/Operation'
import { Roles } from './Doers/Roles'
import { ShiftSettings } from './Doers/shift-settings/shift-settings'
import { Locations } from './Doers/Locations'
import { News } from './Doers/News'
import { SingleNews } from './Doers/News/SingleNews'
import { Carwashes } from './Partners/Carwashes'
import { Registry } from './Partners/Registry'
import { Bills } from './Billing/Bills'
import { Contractors } from './Billing/Contractors'
import { BillingDoers } from './Billing/BillingDoers'
import { Error } from 'components/Error'
import { ShiftsPage } from './Doers/ShiftsPage'
import { Rent } from './Partners/Rent'
import { Products } from './Products/Products'
import { Product } from './Products/Products/Product'
import { ProductGroups } from './Products/ProductGroups'
import { CustomerTariffs } from './Products/CustomerTariffs'
import { CustomerTariff } from './Products/CustomerTariffs/CustomerTariff'
import { DoerTariffs } from './Doers/DoerTariffs'
import { DoerTariff } from './Doers/DoerTariffs/DoerTariff'
import { FleetCars } from './Fleets/Cars'
import { Mailing } from './Doers/Mailing'
import { Motivations } from './Doers/Motivations'
import { FleetServices } from './Fleets/Services'
import { YandexProReports } from './Doers/YandexProReports'
import { Monitoring } from './Doers/Monitoring'
import { DoerRecordsPage } from './Billing/DoerRecords'
import { Vacancies } from './Doers/Vacancies'
import { Vacancy } from './Doers/Vacancies/Vacancy'
import { YandexLavka } from './Doers/YandexLavka'
import { Urent } from './Doers/Urent'
import { Internships } from './Doers/internships/internships'
import { Premises } from './Partners/Premises'
import { Premise } from './Partners/Premises/Premise'

import { Users } from './Users'
import { Home } from './Home'


export const PrivateRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.ROOT.path}
      >
        <Home />
      </Route>

      <Route
        exact
        path={ROUTES.FLEETS.CARS.path}
      >
        <FleetCars />
      </Route>

      <Route
        exact
        path={ROUTES.FLEETS.SERVICES.path}
      >
        <FleetServices />
      </Route>

      <Route
        exact
        path={ROUTES.PRODUCTS.path}
      >
        <Products />
      </Route>

      <Route
        exact
        path={ROUTES.PRODUCTS.ID.createPath()}
      >
        <Product />
      </Route>

      <Route
        exact
        path={ROUTES.PRODUCTS.GROUPS.path}
      >
        <ProductGroups />
      </Route>

      <Route
        exact
        path={ROUTES.PRODUCTS.TARIFFS.path}
      >
        <CustomerTariffs />
      </Route>

      <Route
        exact
        path={ROUTES.PRODUCTS.TARIFFS.ID.createPath()}
      >
        <CustomerTariff />
      </Route>

      <Route
        exact
        path={ROUTES.BILLING.BILLS.ID.createPath()}
      >
        <Bill />
      </Route>
      <Route
        exact
        path={ROUTES.BILLING.DOERS.ID.createPath()}
      >
        <Bill />
      </Route>

      <Route
        exact
        path={ROUTES.BILLING.BILLS.path}
      >
        <Bills />
      </Route>

      <Route
        exact
        path={ROUTES.BILLING.CONTRACTORS.path}
      >
        <Contractors />
      </Route>

      <Route
        exact
        path={ROUTES.BILLING.DOERS.path}
      >
        <BillingDoers />
      </Route>

      <Route
        exact
        path={ROUTES.BILLING.DOER_RECORDS.path}
      >
        <DoerRecordsPage />
      </Route>

      <Route
        exact
        path={ROUTES.USERS.path}
      >
        <Users />
      </Route>

      <Route
        exact
        path={ROUTES.USERS.ID.createPath()}
      >
        <User />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.DOERS.path}
      >
        <Doers />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.DOERS.createPath()}
      >
        <Doer />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.SHIFTS.path}
      >
        <ShiftsPage />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.SHIFTS.SETTINGS.path}
      >
        <ShiftSettings />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.INTERNSHIPS.path}
      >
        <Internships />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.VACANCIES.path}
      >
        <Vacancies />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.VACANCIES.createPath()}
      >
        <Vacancy />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.OFFERS.path}
      >
        <Offers />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.OFFERS.createPath()}
      >
        <Offer />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.OPERATIONS.path}
      >
        <Operations />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.OPERATIONS.createPath()}
      >
        <Operation />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.MONITORING.path}
      >
        <Monitoring />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.ROLES.path}
      >
        <Roles />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.LOCATIONS.path}
      >
        <Locations />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.TARIFFS.path}
      >
        <DoerTariffs />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.TARIFFS.createPath()}
      >
        <DoerTariff />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.NEWS.path}
      >
        <News />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.NEWS.PARAMS.createPath()}
      >
        <SingleNews />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.MAILING.path}
      >
        <Mailing />
      </Route>

      <Route
        exact
        path={ROUTES.DOERS.MOTIVATIONS.path}
      >
        <Motivations />
      </Route>

      <Route path={ROUTES.DIRECTORY.CLIENT_TYPES.path}>
        <ClientTypes />
      </Route>

      <Route path={ROUTES.DIRECTORY.CAR_CLASSES.path}>
        <CarClasses />
      </Route>

      <Route path={ROUTES.DIRECTORY.CARS.path}>
        <Cars />
      </Route>

      <Route path={ROUTES.DIRECTORY.SERVICES.path}>
        <Services />
      </Route>

      <Route
        exact
        path={ROUTES.PARTNERS.path}
      >
        <Registry />
      </Route>

      <Route
        exact
        path={ROUTES.PARTNERS.SEARCH.createPath()}
      >
        <Registry />
      </Route>

      <Route
        exact
        path={ROUTES.PARTNERS.RENT.path}
      >
        <Rent />
      </Route>

      <Route
        exact
        path={ROUTES.PARTNERS.ID.createPath()}
      >
        <Partner />
      </Route>

      <Route
        exact
        path={ROUTES.CARWASHES.path}
      >
        <Carwashes />
      </Route>

      <Route
        exact
        path={ROUTES.CARWASHES.SEARCH.createPath()}
      >
        <Carwashes />
      </Route>

      <Route
        exact
        path={ROUTES.CARWASHES.ID.createPath()}
      >
        <Carwash />
      </Route>

      <Route
        exact
        path={ROUTES.PREMISES.path}
      >
        <Premises />
      </Route>

      <Route
        exact
        path={ROUTES.PREMISES.SEARCH.createPath()}
      >
        <Premises />
      </Route>

      <Route
        exact
        path={ROUTES.PREMISES.ID.createPath()}
      >
        <Premise />
      </Route>

      <Route
        exact
        path={ROUTES.YANDEXPRO.REPORTS.path}
      >
        <YandexProReports />
      </Route>

      <Route
        exact
        path={ROUTES.YANDEX_LAVKA.REPORTS.path}
      >
        <YandexLavka />
      </Route>

      <Route
        exact
        path={ROUTES.URENT.REPORTS.path}
      >
        <Urent />
      </Route>
      <Route
        exact
        path={ROUTES.ERROR.path}
      >
        <Error />
      </Route>

      <Redirect to={ROUTES.ROOT.path} />
    </Switch>
  )
}
