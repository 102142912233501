import React from 'react'
import { useHistory } from 'react-router'
import { Checkbox, Col, Divider, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from 'config/routes'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { addProduct } from 'AC/products/products'
import { required } from 'utils/formUtils'
import { getEntries } from 'utils/enumUtils'
import { FormFooter } from 'components/FormFooter'

import styles from './styles.module.sass'

const productTypeOptions = getEntries(APITYPES.ProductTypeTrans)

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({productGroups: state.productsReducer.productGroups})

export const AddProductModal = ({isOpen, onClose}: Props) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {productGroups} = useSelector(selector)

  const watchedColor = Form.useWatch(['style', 'color'], form)
  const watchedBgc = Form.useWatch(['style', 'background_color'], form)

  const onFinish = (values: any) => {
    dispatch(addProduct(values, (newProduct) => {
      history.push(ROUTES.PRODUCTS.ID.createPath(newProduct.id))
    }))
    onClose()
  }

  return (
    <Modal
      width={800}
      footer={false}
      destroyOnClose
      visible={isOpen}
      onCancel={onClose}
      title="Создание продукта"
      afterClose={form.resetFields}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 4]} align="middle">
          <Col span={7}>
            <Form.Item label="Тип" name="type" rules={required}>
              <Select allowClear placeholder="Выберите тип продукта">
                {productTypeOptions.map(type => (
                  <Select.Option key={type[0]} value={type[0]}>{type[1] as string}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Название" name="name" rules={required}>
              <Input placeholder="Введите название"/>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label='Последовательность' name="sequence">
              <InputNumber style={{width: '100%'}} />
            </Form.Item>
          </Col>

          <Col span={12} style={{alignItems: 'center'}}>
            <Form.Item label="Группа продуктов" name="group_id">
              <Select placeholder='Выберите группу'>
                {
                  productGroups.map((grp => <Select.Option key={grp.id} value={grp.id}>{grp.name}</Select.Option>))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="active" initialValue={false} valuePropName="checked" noStyle>
              <Checkbox>Активна</Checkbox>
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={[12, 4]} align="middle">
          <Col span={12}>
            <Form.Item label="Краткое описание" name="summary" rules={required}>
              <Input.TextArea autoSize={{minRows: 3, maxRows: 3}}/>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Описание" name="description" rules={required}>
              <Input.TextArea autoSize={{minRows: 3, maxRows: 3}}/>
            </Form.Item>
          </Col>
        </Row>

        <Divider className={styles.divider}>Дополнительные стили</Divider>

        <Row align="middle" gutter={[12, 4]}>
          <Col flex='384.44px'>
            <Form.Item name={['style', 'background_color']}>
              <Input
                type="color"
                addonAfter={<div className={styles.addonAfter}>{watchedBgc ? watchedBgc : '-'}</div>}
                addonBefore='Цвет фона (background_color)'
              />
            </Form.Item>
          </Col>

          <Col flex='267.29px'>
            <Form.Item name={['style', 'color']}>
              <Input
                type="color"
                addonBefore='Цвет (color)'
                addonAfter={<div className={styles.addonAfter}>{watchedColor ? watchedColor : '-'}</div>}
              />
            </Form.Item>
          </Col>
        </Row>

        <FormFooter onCancel={onClose}/>
      </Form>
    </Modal>
  );
};
