import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchProductGroups } from 'AC/products/products'
import { getTableYScroll } from 'utils/scrollUtils'

import { AddModal } from './AddModal'
import { EditModal } from './EditModal'
import { EditProductGroupModalType, getColumns, getRows } from './utils'
import styles from './styles.module.sass'

const selector = (state: State) => ({
  productGroups: state.productsReducer.productGroups,
  isLoading: state.requestsReducer.fetchProductGroups === StatusRequest.LOADING,
})

export const ProductGroups = () => {
  const dispatch = useDispatch()
  const {productGroups, isLoading} = useSelector(selector)

  useEffect(() => {
    if (!productGroups.length) dispatch(fetchProductGroups())
  }, [])

  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(prev => !prev)

  const [editModal, setEditModal] = useState<EditProductGroupModalType>({open: false, group: null})
  const openEditModal = (group: APITYPES.ProductGroup) => setEditModal({open: true, group: group})
  const closeEditModal = () => setEditModal({open: false, group: null})

  return (
    <>
      <Button type="primary" className={styles.addBtn} onClick={toggleAddModal}>
        Добавить группу
      </Button>

      <Table
        sticky
        bordered
        pagination={false}
        loading={isLoading}
        className={styles.table}
        style={{cursor: 'pointer'}}
        columns={getColumns() as any}
        dataSource={getRows(productGroups)}
        scroll={{y: getTableYScroll(180)}}
        onRow={(record) => ({
          onClick: () => openEditModal(record.group),
        })}
      />

      <AddModal isOpen={addModal} onClose={toggleAddModal}/>
      <EditModal isOpen={editModal.open} onClose={closeEditModal} group={editModal.group}/>
    </>
  );
};
