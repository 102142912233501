import React from 'react'
import { Form, Modal, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { APITYPES } from 'types/apitypes'
import { addCarClass } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
  carClasses: APITYPES.CarClasses.Get.Resp
  allCarClasses: APITYPES.AllCarClasses.Get.Resp
}

const { useForm } = Form

export const AddCarClassForm = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const { carClasses, allCarClasses, isVisible, onCancel } = props
  const [form] = useForm()

  const dispatch = useDispatch()

  const onFinish = async (values: { carClass: string }) => {
    const carClass = allCarClasses.find((item)=>item.id === values.carClass)

    if (!carClass) {return}

    dispatch(addCarClass(carClass, id))

    onCancel()
  }

  const afterClose = () => {form.resetFields()}

  return (
    <Modal
      title='Привязать'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      destroyOnClose
      afterClose={afterClose}
    >
      <Form
        name='car_classes'
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='carClass'
          label='Класс автомобиля'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select
            showSearch
            filterOption={
              (input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              allCarClasses.map((carClass)=>{
                if (!carClasses.map((item)=>item.id).find((id)=> id === carClass.id)) {
                  return (
                    <Select.Option
                      key={carClass.id}
                      value={carClass.id}
                    >
                      { carClass.name }
                    </Select.Option>
                  )
                }

                return
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Привязать класс автомобиля'
        />
      </Form>
    </Modal>
  )
}