import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const deleteDocument = (document_id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteDocument', StatusRequest.LOADING))
    
    const partner_id = getState().partnersReducer.partner?.id

    if (!partner_id) {return}

    try {
      const resp = await api.delete(APITYPES.Documents.Partners.DeleteDocument.URL,
        {
          replaceUrl: {
            partner_id,
            document_id,
          },
        })
      
      if (resp) {
        dispatch(action(Actions.DELETE_PARTNER_DOCUMENT, document_id))

        openNotification('Удалено')

        dispatch(changeRequestState('deleteDocument', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteDocument', StatusRequest.ERROR))
  
      showError(e.response.data)
    
      throw(e)
    }
  }
}