import { APITYPES } from 'types/apitypes'


export type RoleGroupsTree = {
  title: string
  key: string
  children: RoleGroupsTreeChildren[]
}

export type RoleGroupsTreeChildren = {
  title: string
  key: string
  children?: RoleGroupsTreeChildren[]
}

export function combinedRoleGroups(roleGroups: APITYPES.RoleGroups[] | null): RoleGroupsTree[] | null {
  const departmentMap = new Map<string, RoleGroupsTreeChildren[]>()

  if (!roleGroups || roleGroups?.length === 0) {
    return null
  }
  roleGroups.forEach((roleGroup) => {
    const { department, name, roles } = roleGroup

    if (!departmentMap.has(department)) {
      departmentMap.set(department, [])
    }

    const newRoles = roles?.map((role) => {
      return {
        title: role.name,
        key: role.id,
      }
    }) ?? []

    const item = departmentMap.get(department)

    if (item) {
      item.push({
        title: name,
        key: newRoles.map((role) => role.key).join(',') + 'value',
        children: newRoles,
      })
    }
  })

  const nestedArray: RoleGroupsTree[] = []

  departmentMap.forEach((value: RoleGroupsTreeChildren[], key) => {
    nestedArray.push({
      title: key,
      key: value.map((role) => role.key).join(','),
      children: value,
    })
  })

  return nestedArray
}

export function removeValueEndingFromArray(arr: string[]): string[] {
  return arr.map((str) => str.replace(/value$/, ''))
}