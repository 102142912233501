import React from 'react'
import { CheckCircleOutlined, CloseCircleOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { blue, green, red } from '@ant-design/colors'


export type StateType =  'started' | 'finished' | 'failed';

export const getStateIcon = (state: StateType ) => {
  switch (state) {
    case 'started':
      return (
        <FieldTimeOutlined style={
          {
            fontSize: '30px',
            color: `${blue.primary}`,
          }
        }
        />
      )
    case 'finished':
      return (
        <CheckCircleOutlined style={
          {
            fontSize: '30px',
            color: `${green.primary}`,
          }
        }
        />
      )
    case 'failed':
      return (
        <CloseCircleOutlined style={
          {
            fontSize: '30px',
            color: `${red.primary}`,

          }
        }
        />
      )
    default:
      return null
  }
}
