import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchStaticLocations } from 'AC/shifts/locations'
import { getTableYScroll } from 'utils/scrollUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { isObjectEmpty } from 'utils/filterUtils'
import { FilterIcon } from 'components/FilterIcon'

import { Filters } from './Filters'
import { AddLocationModal } from './AddLocationModal'
import { EditLocationModal } from './EditLocationModal'
import { defaultEditValue, EditModalType, getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => {
  const { locations, allLocations } = state.shiftsReducer
  const isLocationsLoading = state.requestsReducer.fetchLocations === StatusRequest.LOADING
  const isAddLocationLoading = state.requestsReducer.addShiftLocation === StatusRequest.LOADING
  const isLocationTableLoading = isLocationsLoading || isAddLocationLoading
  const filter = state.shiftsReducer.locationsFilter

  return {
    locations,
    allLocations,
    isLoading: isLocationTableLoading,
    filter,
  }
}

export const Locations = () => {
  const dispatch = useDispatch()
  const { locations, isLoading, allLocations, filter } = useSelector(selector)

  const [isAddModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(!isAddModal)

  const [editModal, setEditModal] = useState<EditModalType>(defaultEditValue)
  const openEditModal = (location: APITYPES.Location | null) => setEditModal({
    open: true,
    location,
  })
  const closeEditModal = () => setEditModal({
    open: false,
    location: null,
  })

  const urlParams = getUrlSearchParams()

  useEffect(() => {
    if (!allLocations.length) {
      dispatch(fetchStaticLocations())
    }
    if (!isObjectEmpty(urlParams)) {
      dispatch(action(Actions.SET_LOCATIONS_FILTER, {
        ...urlParams,
        city_id: urlParams.city_id ? urlParams.city_id : undefined,
      }))
    }
  }, [])

  const [visible, setVisible] = useState(false)
  const toggleFilter = () => setVisible((prev) => !prev)

  return (
    <>
      <div className={styles.main}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            className={styles.addBtn}
            onClick={toggleAddModal}
          >
            Добавить локацию
          </Button>

          <Button
            size='small'
            onClick={toggleFilter}
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          />
        </div>

        <Table
          sticky
          loading={isLoading}
          columns={getColumns() as any}
          dataSource={getRows(locations)}
          className={styles.table}
          pagination={false}
          bordered
          onRow={(record) => ({ onClick: () => openEditModal(record.location) })}
          scroll={{ y: getTableYScroll(194) }}
        />
      </div>

      <Filters
        isOpen={visible}
        onClose={toggleFilter}
      />

      <AddLocationModal
        isOpen={isAddModal}
        onClose={toggleAddModal}
      />
      <EditLocationModal
        isOpen={editModal.open}
        location={editModal.location}
        onClose={closeEditModal}
      />
    </>
  )
}
