import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Drawer, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchOffers } from 'AC/shifts/offers'
import { fetchRoles } from 'AC/doers/doerRoles'
import { fetchLocations, fetchShiftTypes } from 'AC/shifts/shifts'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { isObjectEmpty } from 'utils/filterUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { getTableYScroll } from 'utils/scrollUtils'
import { FilterIcon } from 'components/FilterIcon'

import { getColumns, getRows } from './utils'
import Filters from './Filters'


const selector = (state: State) => ({
  offers: state.offersReducer.offers,
  offFilter: state.offersReducer.offersFilter,
  hasMoreOffers: state.offersReducer.hasMoreOffers,
  isLoading: state.requestsReducer.fetchOffers === StatusRequest.LOADING,
  roles: state.doersReducer.roles,
  shiftTypes: state.shiftsReducer.shiftTypes,
  locations: state.shiftsReducer.locations,
})

export const Offers = () => {
  const { offers, isLoading, offFilter, roles, shiftTypes, locations, hasMoreOffers } = useSelector(selector)
  const dispatch = useDispatch()
  const history = useHistory()
  const params = getUrlSearchParams()
  const isFirstRun = useRef(true)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(!visible)

  useEffect(() => {
    if (!shiftTypes.length) {dispatch(fetchShiftTypes())}
    if (!locations.length) {dispatch(fetchLocations())}
    if (!roles || !roles.length) {dispatch(fetchRoles())}

    dispatch(action(Actions.CLEAR_OFFERS, {}))

    if (!isObjectEmpty(params)) {
      const urlParams = Object.fromEntries(Object.entries(params).map(([key, value]) => ([key, value.split(',') ])))

      dispatch(action(Actions.SET_OFFERS_FILTER, {
        ...urlParams,
        date_from: urlParams.date_from ? urlParams.date_from[0] : undefined,
        date_to: urlParams.date_to ? urlParams.date_to[0] : undefined,
      }))
    } else {
      history.push(ROUTES.DOERS.OFFERS.PARAMS.createPath({
        ...offFilter,
        offset: undefined,
        limit: undefined,
      }))
      dispatch(fetchOffers(offFilter))
    }
  }, [])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      
      return
    }
    dispatch(fetchOffers(offFilter))
  }, [offFilter])

  // load more when intersecting last table row
  useEffect(() => {
    if (!isLoading && hasMoreOffers && entry?.isIntersecting && !isFirstRun.current) {
      dispatch(fetchOffers(offFilter))
    }
  }, [entry?.isIntersecting])

  const toOffer = (offerId: string) => history.push(ROUTES.DOERS.OFFERS.createPath(offerId))

  const filter = useMemo(() => omit(offFilter, ['limit', 'offset']), [offFilter])
  
  return (
    <>
      <div style={
        {
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '10px',
        }
      }
      >
        <Button
          size='small'
          onClick={toggleVisible}
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
        />
      </div>
      <Table
        sticky
        size='small'
        loading={isLoading}
        columns={getColumns(offFilter) as any}
        dataSource={getRows(offers, offFilter)}
        pagination={false}
        bordered
        scroll={{ y: getTableYScroll(162) }}
        onRow={
          (record: any, index) => {
            return ({
              ref: index === offers?.length - 1 ? lastRowRef : undefined,
              onClick: () => toOffer(record.id),
            })
          }
        }
        style={{ cursor: 'pointer' }}
      />
      <Drawer
        title='Фильтры'
        placement='right'
        onClose={toggleVisible}
        visible={visible}
      >
        <Filters />
      </Drawer>
    </>
  )
}
