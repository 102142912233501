import React from 'react'
import { Menu } from 'antd'


type Props = {
  handleMenuClick: (e: any) => void
}

export const IntervalMenu = ({ handleMenuClick }: Props) => {
  return (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key='4'
      >
        Круглосуточно
      </Menu.Item>
      <Menu.Item
        key='2'
      >
        Пред. день
      </Menu.Item>
      <Menu.Item
        key='3'
      >
        След. день
      </Menu.Item>
      <Menu.Item
        key='1'
      >
        Установить время
      </Menu.Item>
    </Menu>
  )
}