import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


type GetDoersProps = {
  offset?: number
  limit?: number
} & APITYPES.DoersFilterType

export const getDoers = (filter?: GetDoersProps): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoers', StatusRequest.LOADING))

    const state = getState().doersReducer.doersFilter.state ?? undefined
    const name = getState().doersReducer.doersFilter.name ?? undefined
    const phoneNumber = getState().doersReducer.doersFilter.phone_number ?? undefined
    const roles = getState().doersReducer.doersFilter.role_id ?? undefined
    const lastActiveDays = getState().doersReducer.doersFilter.last_active_days ?? undefined

    try {
      const resp = await api.get(APITYPES.Doers.Get.URL,
        {
          params: {
            state: filter?.state ?? state,
            name: filter?.name ?? name,
            phone_number: filter?.phone_number ?? phoneNumber,
            role_id: roles ? roles : undefined,
            last_active_days: lastActiveDays,
            offset: getState().doersReducer.doers?.length ?? 0,
            limit: 40,
          },
        })

      if (resp) {
        dispatch(action(Actions.SET_DOERS, {
          doers: resp,
          hasMore: Boolean(resp?.length),
        }))

        dispatch(changeRequestState('getDoers', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoers', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const getDoerByName = (search: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getDoerByName', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Doers.Get.URL, { params: { name: search } })

      if (resp) {
        dispatch(action(Actions.SET_SEARCHED_DOERS, resp))

        dispatch(changeRequestState('getDoerByName', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerByName', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const getDoerById = (doerId: string, search: boolean = false): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getDoerById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Doers.GetById.URL, { replaceUrl: { doerId } })

      if (resp) {
        if (search) {
          dispatch(action(Actions.SET_SEARCHED_DOER, resp))
        } else {
          dispatch(action(Actions.SET_DOER, resp))
        }

        dispatch(changeRequestState('getDoerById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerById', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const getDoerShifts = (doerId: string, filter?: APITYPES.Doers.GetShifts.Params): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerShifts', StatusRequest.LOADING))

    const { doerShifts } = getState().doersReducer

    try {
      const resp = await api.get(APITYPES.Doers.GetShifts.URL, {
        replaceUrl: { doerId },
        params: {
          ...filter,
          offset: doerShifts.length || 0,
          limit: filter?.limit ?? 30,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_SHIFTS, {
          doerShifts: resp,
          hasMoreDoerShifts: Boolean(resp.length),
        }))

        dispatch(changeRequestState('getDoerShifts', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerShifts', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const getDoerReserves = (doerId: string, filter?: APITYPES.Doers.GetReserves.Params): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getDoerReserves', StatusRequest.LOADING))

    const { doerReserves } = getState().doersReducer

    try {
      const resp = await api.get(APITYPES.Doers.GetReserves.URL, {
        replaceUrl: { doerId },
        params: {
          ...filter,
          offset: doerReserves.length || 0,
          limit: 30,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_DOER_RESERVES, {
          doerReserves: resp,
          hasMoreDoerReserves: Boolean(resp.length),
        }))

        dispatch(changeRequestState('getDoerReserves', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getDoerReserves', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}