import React from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Form, Input, Tag } from 'antd'

import { APITYPES } from 'types/apitypes'
import { updatePenaltyStatus } from 'AC/carwashes/carwashes'
import { FormFooter } from 'components/FormFooter'
import { getPenaltyStatusColor, getPenaltyStatusText, getPenaltyStatusUrl } from '../Status/utils'


type Props = {
  modalTitle: string
  newStatus: string | null
  isVisible: boolean
  onCancel: () => void
  carwashPenalty: APITYPES.CarwashPenalty | APITYPES.DoerMotivation | APITYPES.DoerPenalty | APITYPES.DoerCompensation
  onCallback?: (comment: string, newStatus: string) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

export const ChangePenaltyStatusModal = (props: Props) => {
  const { isVisible, onCancel, carwashPenalty, newStatus, onCallback } = props
  const dispatch = useDispatch()

  const onSubmit = (values: any) => {
    const { comment, new_status } = values
    const statusReqDirectory = getPenaltyStatusUrl(new_status)

    if (!statusReqDirectory) {return}

    if (onCallback) {
      dispatch(onCallback(comment, new_status))
    } else {
      dispatch(updatePenaltyStatus({
        carwash_id: 'carwash_id' in carwashPenalty ? carwashPenalty.carwash_id : '',
        carwash_penalty_id: carwashPenalty.id,
        new_status: statusReqDirectory,
      }, { comment }))
    }
    
    onCancel()
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title={props.modalTitle}
        open={isVisible}
        onCancel={
          (e) => {
            e.stopPropagation()
            onCancel()
          }
        }
        width={530}
        footer={false}
        key={`${isVisible}`}

      >
        <Form
          {...formItemLayout}
          onFinish={onSubmit}
        >
          <Form.Item
            name='new_status'
            label='Новый статус'
            initialValue={newStatus}
          >
            <Tag color={getPenaltyStatusColor(newStatus)}>
              { getPenaltyStatusText(newStatus) }
            </Tag>
          </Form.Item>
          <Form.Item
            name='comment'
            label='Коментарий'
            initialValue=''
          >
            <Input.TextArea
              maxLength={250}
              autoSize={
                {
                  minRows: 3,
                  maxRows: 6,
                }
              }
            />
          </Form.Item>
          <FormFooter
            onCancel={onCancel}
            okText='Изменить'
          />
        </Form>
      </Modal>
    </div>
  )
}