import React from 'react'
import { Button } from 'antd'
import { APITYPES } from 'types/apitypes'

const cities = APITYPES.Cities

export const getColumns = (onDelete?: (locationId: string) => void) => {
  return [
    {
      title: 'Город',
      dataIndex: 'city_id',
      key: 'city_id',
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'Регион',
      dataIndex: 'carwash_region',
      key: 'carwash_region',
      align: 'center',
    },
    {
      title: 'Адрес',
      dataIndex: 'carwash_address',
      key: 'carwash_address',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 120,
      render: (locationId: string) => (
        <Button
          onClick={onDelete ? () => onDelete(locationId) : undefined}
          type='link'
        >
          Удалить
        </Button>
      )
    },
  ]
}

export const getRows = (locations: APITYPES.CustomerTariffLocation[]) => {
  return locations.map((location) => {
    const row: any = {}

    getColumns().map((column) => {
      if(column.dataIndex === 'city_id') {
        row[column.dataIndex] = cities[location.city_id]
      } else if (column.dataIndex === 'carwash_region') {
        row[column.dataIndex] = location?.carwash_region ? location?.carwash_region : '-'
      } else if (column.dataIndex === 'carwash_address') {
        row[column.dataIndex] = location?.carwash_address ? location?.carwash_address : '-'
      } else if (column.dataIndex === 'actions') {
        row[column.dataIndex] = location.id
      } else {
        //@ts-ignore
        row[column.dataIndex] = location[column.dataIndex]
      }
      
      return
    })
    row.key = location.id

    return row
  })
}