import React from 'react'
import { Form, Input, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { cancelShift } from 'AC/shifts/shifts'
import { FormFooter } from 'components/FormFooter'
import { useShiftTabsContext } from '../../../../context'

type Props = {
  isOpen: boolean
  onClose: () => void
  shiftId: string
}

export const CancelShiftModal = (props: Props) => {
  const {isOpen, onClose, shiftId} = props
  const { tabActions } = useShiftTabsContext();
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values: { comment: string }) => {
    dispatch(cancelShift(shiftId, values, tabActions.handleCloseTab))

    onClose()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title="Отмена смены"
      visible={isOpen}
      afterClose={afterClose}
      footer={false}
      onCancel={onClose}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish}>

        <div style={{height: 'fit-content'}}>
          <Form.Item
            label="Комментарий"
            name="comment"
            rules={[{required: true, message: 'Поле должно быть заполнено'}]}
          >
            <Input.TextArea autoSize={{minRows: 4, maxRows: 6}}/>
          </Form.Item>
        </div>

          <FormFooter onCancel={onClose} okText="Отменить смену"/>
      </Form>
    </Modal>
  );
};
