import { Reducer } from "redux";

import { APITYPES } from "types/apitypes";
import { Action, Actions } from "actions";

export type OperationsState = {
  operations: APITYPES.TOperations;
  hasMoreOperations: boolean;
  parentOperation: APITYPES.Operation | null;
  currentOperation: APITYPES.Operation | null;
  operationsFilter: APITYPES.OperationsFilter;
  operationTasks: APITYPES.OperationTask[];
  operationFiles: APITYPES.OperationFile[];
  operationsSyncState: any;
  tasks: APITYPES.Task[];
  allTasks: APITYPES.Task[];
  billSyncState: APITYPES.BillSyncState;
  locationSearch: APITYPES.Shifts.ShiftLocations.GET.Resp | null;
};

export const initialOperationsState = {
  operations: [],
  hasMoreOperations: true,
  parentOperation: null,
  currentOperation: null,
  operationTasks: [],
  operationFiles: [],
  operationsFilter: {},
  operationsSyncState: {},
  tasks: [],
  allTasks: [],
  billSyncState: {
    start: "",
    stop: "",
    period: "",
    state: "",
  },
  
  locationSearch: null,
};

export const operationsReducer: Reducer<OperationsState, Action> = (
  state = initialOperationsState,
  a
): OperationsState => {
  switch (a.type) {
    // case Actions.SET_ALL_TASKS: {
    //   return {
    //     ...state,
    //     allTasks: a.data
    //   }
    // }

    case Actions.SET_TASKS: {
      return {
        ...state,
        tasks: a.data,
      };
    }

    case Actions.SET_OPERATION_FILES: {
      return {
        ...state,
        operationFiles: a.data,
      };
    }

    case Actions.SET_OPERATION_LOCATIONS_SEARCH: {
      return {
        ...state,
        locationSearch: a.data,
      };
    }

    case Actions.CLEAR_OPERATION_TASKS: {
      return {
        ...state,
        operationTasks: [],
      };
    }

    case Actions.SET_OPERATION_TASKS: {
      return {
        ...state,
        operationTasks: a.data,
      };
    }

    case Actions.SET_OPERATIONS_FILTER: {
      return {
        ...state,
        operationsFilter: a.data,
      };
    }

    case Actions.CLEAR_OPERATION:
      return {
        ...state,
        parentOperation: null,
        currentOperation: null,
      };

    case Actions.SET_PARENT_OPERATION:
      return {
        ...state,
        parentOperation: a.data,
      };

    case Actions.SET_CHILD_OPERATION:
      return {
        ...state,
        currentOperation: a.data,
      };

    case Actions.CLEAR_OPERATIONS:
      return {
        ...state,
        operations: [],
        hasMoreOperations: true,
      };
    case Actions.SET_OPERATION_SYNC: {
      return {
        ...state,
        billSyncState: a.data,
      };
    }
    case Actions.SET_OPERATIONS: {
      return {
        ...state,
        operations: [...state.operations, ...a.data.operations],
        hasMoreOperations: a.data.hasMoreOperations,
      };
    }
  }

  return state;
};
