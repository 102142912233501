import { Reducer } from 'redux'

import { APITYPES } from 'types/apitypes'
import { Action, Actions } from 'actions'


export interface PlacementToUpdate extends APITYPES.YandexPro.Placement {
  isMatched?: boolean
  name?: string
}

export type YandexProState = {
  report: APITYPES.YandexPro.Report | null
  reports: APITYPES.YandexPro.Report[]
  executors: APITYPES.YandexPro.Executor[] | null
  placements: PlacementToUpdate[] | null
  initialPlacements:  APITYPES.YandexPro.PlacementInitial[] | null
}

const initState: YandexProState = {
  report: null,
  reports: [],
  executors: null,
  placements: null,
  initialPlacements: null,
}

export const yandexProReducer: Reducer<YandexProState, Action> = (
  state = initState,
  a
): YandexProState => {
  switch (a.type) {
    case Actions.SET_YANDEXPRO_REPORTS: {
      return {
        ...state,
        reports: a.data,
      }
    }
    case Actions.SET_YANDEXPRO_REPORT: {
      return {
        ...state,
        report: a.data,
      }
    }
    case Actions.CLEAR_YANDEXPRO_REPORT: {
      return {
        ...state,
        report: null,
      }
    }
    case Actions.SET_YANDEXPRO_EXECUTOR: {
      return {
        ...state,
        executors: a.data,
      }
    }

    case Actions.CONNECT_YANDEXPRO_EXECUTOR_AND_DOER: {
      return {
        ...state,
        executors: state.executors?.map((item) => {
          return  item.yandex_executor_id === a.data.yandex_executor_id ? a.data : item
        }) ?? null,
      }
    }

    case Actions.RESET_YANDEX_PLACEMENTS: {
      return {
        ...state,
        placements: null,
      }
    }

    case Actions.RESET_YANDEX_INITIAL_PLACEMENTS: {
      return {
        ...state,
        initialPlacements: null,
      }
    }


    case Actions.SET_YANDEX_PLACEMENTS: {
      return {
        ...state,
        placements: a.data,
      }
    }

    case Actions.DELETE_YANDEX_PLACEMENTS: {
      return {
        ...state,
        placements: state.placements?.filter((p) => p.yandex_placement_id !== a.data.id) || null,
      }
    }

    case Actions.DELETE_YANDEX_INITIAL_PLACEMENTS: {
      return {
        ...state,
        initialPlacements: state.initialPlacements?.filter((p) => p.id !== a.data.id) || null,
      }
    }

    case Actions.UPDATE_YANDEX_PLACEMENTS: {
      return {
        ...state,
        placements: state.placements?.map((p) => p.yandex_placement_id === a.data.yandex_placement_id ? {
          ...p,
          location_name: a.data.location_name,
          location_id: a.data.location_id,
          isMatched: false,
        } : p) || null,
      }
    }

    case Actions.UPDATE_YANDEX_PLACEMENTS_MATCHED: {
      return {
        ...state,
        placements: state.placements?.map((p) => p.yandex_placement_id === a.data.id ? {
          ...p,
          isMatched: a.data.isMatched,
        } : p) || null,
      }
    }

    case Actions.SET_YANDEX_INITIAL_PLACEMENTS: {
      return {
        ...state,
        initialPlacements: a.data,
      }
    }

    case Actions.ADD_YANDEX_INITIAL_PLACEMENTS: {
      return {
        ...state,
        initialPlacements: [...state.initialPlacements ?? [], a.data],
      }
    }

    case Actions.CLEAR_YANDEXPRO_EXECUTOR: {
      return {
        ...state,
        executors: null,
      }
    }

    case Actions.DELETE_YANDEX_EXECUTOR: {
      return {
        ...state,
        executors: state.executors?.filter((item) => item.yandex_executor_id !== a.data.id) ?? null,
      }
    }
  }

  return state
}