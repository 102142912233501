import React from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { APITYPES } from 'types/apitypes'
import { createCarClass } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'


const { CarClassesGroups } = APITYPES

type Props = {
  isVisible: boolean
  onCancel: () => void
}

const formItemLayout = { labelCol: { span: 5 } }

export const CreateCard = (props: Props) => {
  const { isVisible, onCancel } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const groups = Object.entries(CarClassesGroups)

  const onFinish = (values: APITYPES.AllCarClasses.Post.Req) => {
    dispatch(createCarClass({ ...values }, history))

    onCancel()
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Создание'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      afterClose={afterClose}
    >
      <Form
        name='car_classes'
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='name'
          label='Имя класса'
          initialValue=''
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='group'
          label='Группа'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select>
            {
              groups.map((group) => (
                <Select.Option
                  value={group[0]}
                  key={group[0]}
                >
                  { group[1] }
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <FormFooter onCancel={onCancel} />
      </Form>
    </Modal>
  )
}