import { PageHeader, Tabs, Tag } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { State } from 'stores/rootReducer'
import { getBill } from 'AC/billing/getBill'
import { Loader } from 'components/Loader'
import { BillStateTranslation } from '../Filters/utils'
import { action as reduxAction, Actions } from 'actions'

import { BillInfo } from './BillInfo'
import { Positions } from './Positions'
import { Controls } from './Controls'
import { CommentModal } from './CommentModal'
import { History } from './History'
import styles from './styles.module.sass'


const { TabPane } = Tabs


const selector = (state: State) => ({
  bill: state.billingReducer.bill,
  billsDoers: state.billingReducer.billsDoers,
})

export const Bill = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { bill, billsDoers } = useSelector(selector)

  useEffect(() => {
    dispatch(getBill(id))
  }, [id, dispatch])

  const [action, setAction] = useState<'cancel' | 'negotiation' | 'accept' | 'decline' | 'paid' | 'paying' | null>(null)
  const onCancel = () => {
    setAction('cancel')
  }

  const onNegotiation = () => setAction('negotiation')
  const onPaid = () => setAction('paid')
  const onPaying = () => setAction('paying')

  const isDoer = useMemo(() => Boolean(billsDoers?.find((b) => b.id === bill?.id)),[bill?.id, billsDoers])


  useEffect(() => {
    return () => {
      dispatch(reduxAction(Actions.SET_BILL,  null))
    }
  }, [])

  if (!bill) {return <Loader />}

  return (
    <div className={styles.main}>
      <PageHeader
        className={styles.titleBlock}
        onBack={history.goBack}
        title={
          <div className={styles.title}>
            { bill?.name }
            <Tag className={styles.tag}>{ BillStateTranslation[bill?.state.name] }</Tag>
          </div>
        }
        extra={
          <Controls
            state={bill?.state.name}
            onPaid={onPaid}
            onCancel={onCancel}
            onNegotiation={onNegotiation}
            onPaying={onPaying}
            isDoer={isDoer}
          />
        }
      />

      <Tabs defaultActiveKey='1'>
        <TabPane
          tab='Информация'
          key='1'
        >
          <>
            <BillInfo bill={bill} />
            <Positions
              className={styles.positions}
              bill={bill}
            />
          </>
        </TabPane>

        <TabPane
          tab='История'
          key='2'
        >
          <History />
        </TabPane>
      </Tabs>

      <CommentModal
        isVisible={!!action}
        onCancel={() => setAction(null)}
        action={action}
      />
    </div>
  )
}