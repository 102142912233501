import React, { useState } from 'react'
import { Modal, Form, TimePicker, Dropdown, Button } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'
import { EditOutlined } from '@ant-design/icons'

import { FormFooter } from 'components/FormFooter'
import { createInterval } from 'AC/carwashes/schedules'
import { IntervalMenu } from '../Interval/Menu'

import styles from './styles.module.sass'


type Props = {
  isVisible: boolean
  scheduleId: string
  dayNum: number | undefined
  onCancel : () => void
}

const format = 'HH:mm'

export const AddModal = (props: Props) => {
  const { isVisible, onCancel, dayNum, scheduleId } = props

  const dispatch = useDispatch()

  const [mode, setMode] = useState<number>(1)

  const [form] = useForm()

  const handleMenuClick = (e: any) => {
    setMode(parseFloat(e.key))
  }

  const onFinish = (values: any) => {
    dispatch(createInterval({
      day_num: dayNum,
      work_from: values.interval && mode === 1 ? values.interval[0].format(format)
        : values.from && mode === 3 ? values.from.format(format) : null,
      work_to: values.interval && mode === 1 ? values.interval[1].format(format)
        : values.to && mode === 2 ? values.to.format(format) : null,
    }, scheduleId))
    onCancel()
  }

  return (
    <Modal
      title='Добавление интервала'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <div className={styles.interval}>
          {
            mode === 1 && (
              <Form.Item
                name='interval'
              >
                <TimePicker.RangePicker
                  minuteStep={5}
                  format={format}
                />
              </Form.Item>
            )
          }
          {
            mode === 2 && (
              <div className={styles.item}>
                <div className={styles.startText}>Пред. день -</div>
                <Form.Item
                  name='to'
                >
                  <TimePicker
                    format={format}
                    minuteStep={5}
                  />
                </Form.Item>
              </div>
            )
          }
          {
            mode === 3 && (
              <div className={styles.item}>
                <Form.Item
                  name='from'
                >
                  <TimePicker
                    format={format}
                    minuteStep={5}
                  />
                </Form.Item>
                <div className={styles.endText}>- След. день</div>
              </div>
            )
          }
          {
            mode === 4 && (
              <Form.Item
                name='interval'
              >
                <div>Круглосуточно</div>
              </Form.Item>
            )
          }
          <Dropdown
            className={styles.dropdown}
            overlay={<IntervalMenu handleMenuClick={handleMenuClick} />}
          >
            <Button
              icon={<EditOutlined />}
            />
          </Dropdown>
        </div>
        <FormFooter
          onCancel={onCancel}
          okText='Добавить'
        />
      </Form>
    </Modal>
  )
}