import React from 'react'
import { Modal, Form, Input, Button, Card as BaseCard, Tag } from 'antd'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { APITYPES } from 'types/apitypes'
import { fetchPartnerByInn } from 'AC/partners/partners'
import { action, Actions } from 'actions'

import styles from './styles.module.sass'


type Props = {
  isVisible: boolean
  isFill?: boolean
  onCancel: () => void
  partner: APITYPES.PartnerInfoByInn | null
}

export const Inn = (props: Props) => {
  const { isVisible, onCancel, partner, isFill = false } = props

  const dispatch = useDispatch()

  const onFinish = async (values: { inn: number }) => {
    dispatch(fetchPartnerByInn(values.inn))
  }

  const onFill = () => {
    if (partner) {
      dispatch(action(Actions.SET_PARTNER_BY_INN_FOR_CREATING, partner))

      onCancel()
    }
  }

  return (
    <Modal
      className={styles.modal}
      title='Поиск партнера по ИНН'
      visible={isVisible}
      onCancel={onCancel}
      footer={
        [
          <Button
            className={classNames(!isFill && styles.buttonNone)}
            type='primary'
            onClick={onFill}
            key={1}
            disabled={!partner}
          >
            Заполнить
          </Button>,
          <Button
            onClick={onCancel}
            key={2}
          >
            Отмена
          </Button>,
        ]
      }
    >
      <Form
        name='time_related_controls'
        onFinish={onFinish}
        labelAlign='left'
      >
        <div className={styles.formFields}>
          <Form.Item
            name='inn'
            label='ИНН'
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              type='primary'
              htmlType='submit'
            >
              Найти
            </Button>
          </Form.Item>
        </div>
      </Form>
      {
        partner && (
          <BaseCard
            title={partner.legal_name}
            className={styles.card}
          >
            <div className={styles.field}>
              <Tag>Юридический адрес:</Tag>
              { partner.legal_address }
            </div>
            <div className={styles.field}>
              <Tag>ИНН:</Tag>
              { partner.inn }
            </div>
            <div className={styles.field}>
              <Tag>ОГРН / ОГРНИП:</Tag>
              { partner.ogrn }
            </div>
            <div className={styles.field}>
              <Tag>Генеральный директор:</Tag>
              { partner.general_manager }
            </div>
          </BaseCard>
        )
      }
    </Modal>
  )
}