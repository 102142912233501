import React from 'react'
import { Tag, Tooltip } from 'antd'

import { APITYPES } from 'types/apitypes'

import { getBookedTagsData, getDate, getShiftLocation } from './helpers'
import styles from './styles.module.sass'


export const getColumns = (onExpand?: any, employers?: APITYPES.Employer[]) => {
  return [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: 120,
    },
    {
      title: 'Тип смены',
      dataIndex: 'shift_type',
      key: 'shift_type',
    },
    {
      title: 'Заказчик',
      dataIndex: 'employer_id',
      key: 'employer_id',
      render: (employer_id: string) => {
        const found = employers?.find((item) => item.id === employer_id)

        if (found) {
          return found.name
        }

        return '-'
      },
    },
    {
      title: 'Локация',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Загруженность',
      dataIndex: 'bookedData',
      key: 'bookedData',
      render: (record: { name: string, data: string, key: string }[]) => (
        <>
          {
            record.map((tag, index) => (
              <Tooltip
                overlay={tag.name}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                className={styles.tag}
                key={index}
              >
                <Tag onClick={
                  (e) => {
                    e.stopPropagation()
                    onExpand(false, { key: tag.key })
                  }
                }
                >
                  { tag.data }

                </Tag>
              </Tooltip>
            ))
          }
        </>
      ),
    },
    {
      title: 'Начатые смены',
      dataIndex: 'startedShifts',
      key: 'startedShifts',
      render: (_: any, record: any,) => {
        const { shift, roles } = record
        const { started_doer_shift_count, state_name } = shift

        const placesCount = roles.reduce((acc: any, curr: { places: any }) => curr.places + acc, 0)

        if (state_name !== 'started') {
          return <Tag>{ `${started_doer_shift_count} / ${placesCount}` }</Tag>
        }

        const color = started_doer_shift_count === placesCount ? '#90EE90' : '#FA8072'

        return <Tag color={color}>{ `${started_doer_shift_count} / ${placesCount}` }</Tag>
      },
    },
  ]
}

export const getRows = (shifts: APITYPES.Shift[]) => {
  return shifts.map((shift) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'date') {
        row[column.dataIndex] = getDate(shift)
      } else if (column.dataIndex === 'location') {
        row[column.dataIndex] = getShiftLocation(shift)
      } else if (column.dataIndex === 'bookedData') {
        row[column.dataIndex] = getBookedTagsData(shift)
      } else {
        row[column.dataIndex] = shift[column.dataIndex as keyof APITYPES.Shift]
      }

      return
    })
    row.key = shift.id
    row.shift = shift
    row.roles = shift.roles

    return row
  })
}

export const getRolesColumns = () => [
  {
    title: 'Название роли',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: '25%',
  },
  {
    title: 'Кол-во записавшихся',
    dataIndex: 'booked',
    key: 'booked',
  },
]

export const getRolesRows = (roles: APITYPES.ShiftRole[]) => {
  return roles.map((role) => {
    const row: any = {}

    getRolesColumns().map((column) => {
      if (column.dataIndex === 'booked') {
        row[column.dataIndex] = `${role.booked_places} / ${role.places}`
      } else {
        row[column.dataIndex] = role[column.dataIndex as keyof APITYPES.ShiftRole]
      }

      return
    })
    row.key = role.id

    return row
  })
}
