// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--marginLeft--FRN\\+n{margin-left:0px}.styles-module--modal--wmvvl{min-width:900px !important}.styles-module--footer--NIBXp{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Card/CreateCard/styles.module.sass"],"names":[],"mappings":"AAAA,mCACI,eAAA,CAEJ,6BACI,0BAAA,CAEJ,8BACI,eAAA","sourcesContent":[".marginLeft\n    margin-left: 0px\n\n.modal\n    min-width: 900px!important\n\n.footer\n    margin-top: 24px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginLeft": "styles-module--marginLeft--FRN+n",
	"modal": "styles-module--modal--wmvvl",
	"footer": "styles-module--footer--NIBXp"
};
export default ___CSS_LOADER_EXPORT___;
