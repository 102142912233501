import React from 'react'
import { Modal, Form, Input, Button, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { FormFooter } from 'components/FormFooter'
import { APITYPES } from 'types/apitypes'
import { updateBillItem } from 'AC/billing/updateBillItem'
import { showSureConfirm } from 'components/modals/SureModal'
import { deleteBillItem } from 'AC/billing/deleteBillItem'
import { BILL_ITEM_GROUPS, DOERS_BILL_ITEM_GROUPS } from '../../../../config'


import styles from './styles.module.sass'


type Props = {
  billItem: APITYPES.BillItem
  isVisible: boolean
  onCancel: () => void
}

export const UpdateModal = (props: Props) => {
  const { pathname } = useLocation()
  
  const billItemGroup = pathname.includes('/bills/') ? BILL_ITEM_GROUPS : DOERS_BILL_ITEM_GROUPS

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const { isVisible, onCancel, billItem } = props

  const dispatch = useDispatch()

  const onFinish = async (values: {
    name: string
    description: string
    value: string
    group: string
  }) => {
    dispatch(updateBillItem({
      name: values.name.length ? values.name : undefined,
      description: values.description.length ? values.description : undefined,
      value: values.value.length ? parseFloat(values.value) : undefined,
      group: ! values.group.includes('Прочее') ? values.group : undefined,
    }, billItem.id))
    onCancel()
  }

  const onDelete = () => {
    showSureConfirm(()=>{
      dispatch(deleteBillItem(billItem.id))

      onCancel()
    }, ()=>{})
  }

  return (
    <Modal
      title='Редактирование позиции счета'
      open={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='updating_bill_item_form'
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          label='Название'
          initialValue={billItem.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='description'
          label='Описание'
          initialValue={billItem.description}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='value'
          label='Сумма'
          initialValue={billItem.value}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='group'
          label='Тип'
          initialValue={billItem.group}
        >
          <Select showSearch>
            {
              billItemGroup.map((item, i)=>{
                return (
                  <Select.Option
                    key={i}
                    value={item}
                  >
                    { item }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            danger
            type='primary'
            className={styles.deleteBtn}
            onClick={onDelete}
          >
            Удалить
          </Button>
          <FormFooter
            onCancel={onCancel}
            position='right'
            okText='Сохранить'
          />
        </div>
      </Form>
    </Modal>
  )
}