import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { editProduct, fetchProductGroups } from 'AC/products/products'

import { getEntries } from 'utils/enumUtils'
import styles from './styles.module.sass'

const productTypeOptions = getEntries(APITYPES.ProductTypeTrans)

type Props = {
  product: APITYPES.Product
}

const selector = (state: State) => ({productGroups: state.productsReducer.productGroups})


export const ProductCard = ({product}: Props) => {
  const dispatch = useDispatch()
  const {productGroups} = useSelector(selector)

  useEffect(() => {
    if (!productGroups.length) dispatch(fetchProductGroups())
  }, [])

  const changeProduct = (partial: APITYPES.Products.PUT.Req, nonEmpty: boolean = false) => {
    const key: string = Object.keys(partial)[0] as string

    if (nonEmpty && !partial[key as keyof APITYPES.Products.PUT.Req]) return;
    if (product[key as keyof APITYPES.Product] === partial[key as keyof APITYPES.Products.PUT.Req]) return
    dispatch(editProduct(product.id, partial))
  }

  return (
    <Card
      key={JSON.stringify(product)}
      size="small"
      className={styles.card}
      title="Информация о продукте"
      bodyStyle={{overflowY: 'auto', height: 'calc(100vh - 293px)'}}
    >
      <Form layout="vertical" className={styles.cardContent}>
        <Row gutter={[12, 4]} align="middle">
          <Col span={7}>
            <Form.Item label="Тип">
              <Select disabled defaultValue={product.type}>
                {productTypeOptions.map(type => (
                  <Select.Option key={type[0]} value={type[0]}>{type[1] as string}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Название">
              <Input
                placeholder="Введите название"
                defaultValue={product.name}
                onBlur={(e) => changeProduct({name: e.target.value}, true)}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Последовательность">
              <InputNumber
                style={{width: '100%'}}
                defaultValue={product.sequence ? product.sequence : 0}
                onBlur={(e) => changeProduct({sequence: +e.target.value})}/>
            </Form.Item>
          </Col>

          <Col span={12} style={{alignItems: 'center'}}>
            <Form.Item label="Группа продуктов">
              <Select
                placeholder="Выберите группу"
                allowClear
                defaultValue={product.group.id}
                onChange={(value) => changeProduct({group_id: value ? value : null})}
              >
                {productGroups.map((grp => <Select.Option key={grp.id} value={grp.id}>{grp.name}</Select.Option>))}
              </Select>
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={[12, 4]} align="middle">
          <Col span={12}>
            <Form.Item label="Краткое описание">
              <Input.TextArea
                defaultValue={product.summary}
                autoSize={{minRows: 3, maxRows: 3}}
                onBlur={(e) => changeProduct({summary: e.target.value}, true)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Описание">
              <Input.TextArea
                defaultValue={product.description}
                autoSize={{minRows: 3, maxRows: 3}}
                onBlur={(e) => changeProduct({description: e.target.value}, true)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider className={styles.divider}>Дополнительные стили</Divider>

        <Row align="middle" gutter={[12, 4]}>
          <Col flex="384.44px">
            <Form.Item>
              <Input
                type="color"
                defaultValue={product.style?.background_color}
                addonAfter={<div className={styles.addonAfter}>
                  {product.style?.background_color ? product.style?.background_color : '-'}
                </div>}
                addonBefore="Цвет фона (background_color)"
                onBlur={(e) =>
                  changeProduct({style: {...product.style, background_color: e.target.value}})}
              />
            </Form.Item>
          </Col>

          <Col flex="267.29px">
            <Form.Item>
              <Input
                type="color"
                defaultValue={product.style?.color}
                addonBefore="Цвет (color)"
                addonAfter={<div className={styles.addonAfter}>
                  {product.style?.color ? product.style?.color : '-'}
                </div>}
                onBlur={(e) =>
                  changeProduct({style: {...product.style, color: e.target.value}})}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
