import React, { useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { fetchShiftOffers } from 'AC/shifts/offers'


const ShiftTabsContext = React.createContext<any>({})

const selector = (state: State) => ({ shiftTabs: state.shiftsReducer.shiftTabs })

const DEFAULT_TAB_KEY = 'table'

export const ShiftTabsProvider = ({ children }: { children: React.ReactNode }) => {
  const { shiftTabs } = useSelector(selector)
  const dispatch = useDispatch()
  const DEFAULT_TAB_KEY_REF = useRef(DEFAULT_TAB_KEY)
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB_KEY_REF.current)

  const tabActions = {
    onTabClick: (key: string) => {
      dispatch(action(Actions.CLEAR_SHIFT_DOERS, {}))
      dispatch(action(Actions.SET_SHIFT_OFFERS, []))

      // load shift doers if not calendar tab
      if (key !== DEFAULT_TAB_KEY) {
        dispatch(fetchShiftOffers(key))
      }

      setActiveTab(key)
    },
    handleCloseTab: (shift_id: string) => {
      const tabIndex = shiftTabs.findIndex((tab) => tab.id === shift_id)

      if (tabIndex === -1) {return}

      dispatch(action(Actions.REMOVE_SHIFT_TAB, { shift_id }))

      const prevTab = shiftTabs[tabIndex - 1]

      if (!prevTab?.id) {
        setActiveTab(DEFAULT_TAB_KEY)
      } else {
        setActiveTab(prevTab.id)
      }
    },
    handleAddShiftTab: (shift: APITYPES.Shift) => {
      const tabToAdd = shiftTabs.find((tab) => tab.id === shift.id)

      if (!tabToAdd) {
        dispatch(action(Actions.ADD_SHIFT_TAB, shift))
        tabActions.onTabClick(shift.id)
      } else {
        tabActions.onTabClick(tabToAdd.id)
      }
    },
    isActiveTab: (id: string) => {
      let isActive = false

      for (let i = 0; i < shiftTabs.length; i++) {
        if (shiftTabs[i].id === id) {
          isActive = true
          break
        }
      }
      
      return isActive
    },
  }

  return (
    <ShiftTabsContext.Provider value={
      {
        activeTab,
        shiftTabs,
        tabActions: tabActions,
        DEFAULT_TAB_KEY: DEFAULT_TAB_KEY_REF.current,
      }
    }
    >
      { children }
    </ShiftTabsContext.Provider>
  )
}

export const useShiftTabsContext = () => {
  const context = useContext(ShiftTabsContext)

  if (!context) {
    throw new Error('ShiftTabsContext is not reachable.')
  }

  return context
}