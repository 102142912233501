import { DatePicker, Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { fetchRoleGroup } from 'AC/doers/doerRoles'

import styles from './styles.module.sass'
import { combinedRoleGroups, removeValueEndingFromArray } from './utils'


type M = moment.Moment;
const dateFormat = 'DD.MM.YYYY'

type Props = {
  dates: M[] | undefined
  setDates: any
  setRolesId: any
};


const selector = (state: State) => ({ roleGroups: state.doersReducer.roleGroups })

export const GetStatistics = ({ dates, setDates, setRolesId }: Props) => {
  const { roleGroups } = useSelector(selector)
  const dispatch = useDispatch()

  // const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['0-0-0','0-1','0-0-1'])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([''])
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)
  const [treeData, setTreeData] = useState<any>()

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([''])

  useEffect(() => {
    if (roleGroups?.length === 0) {
      dispatch(fetchRoleGroup())
    }
    if (roleGroups) {
      const tree = combinedRoleGroups(roleGroups)

      if (tree) {
        setTreeData(tree)
      }
    }
  }, [dispatch, roleGroups])

  const onDateChange = async (dates: any) => {
    setDates(dates)
  }


  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue)
    checkedKeysValue = removeValueEndingFromArray(checkedKeysValue.join(',').split(',')).filter(Boolean)
    setRolesId(checkedKeysValue)
  }

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }

  const onSelect = (selectedKeysValue: React.Key[]) => {
    setSelectedKeys(selectedKeysValue)
  }

  return (
    <div className={styles.container}>
      <div className={styles.datePicker}>
        <DatePicker.RangePicker
          format={dateFormat}
          //@ts-ignore
          value={dates}
          onChange={(value) => onDateChange(value)}
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
        />
      </div>
    </div>
  )
}
