import { RcFile } from 'antd/es/upload'

import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'
import { apiV1 } from 'network/http'
import { successNotification } from '../../components/modals/OperationNotification'
import { changeRequestState } from '../request'
import { showInfo } from '../../components/modals/InfoModal'
import { getYandexProReports } from '../yandexpro/reports'


export const getYandexLavkaReports = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getYandexLavkaReports', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.YandexLavka.Reports.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_YANDEX_LAVKA_REPORTS, resp))

        dispatch(changeRequestState('getYandexLavkaReports', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getYandexLavkaReports', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getYandexLavkaReportById = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getYandexLavkaReportById', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.YandexLavka.Reports.GetById.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_YANDEX_LAVKA_UNMATCHED_EXECUTORS, resp.unmatched_executors || []))
        dispatch(action(Actions.SET_YANDEX_LAVKA_UNMATCHED_PLACEMENTS, resp.unmatched_placements || []))


        dispatch(changeRequestState('getYandexLavkaReportById', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getYandexLavkaReportById', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const connectLavkaUnmatchedExecutors = (executorId: string, doerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('connectLavkaUnmatchedExecutors', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.YandexLavka.UnmatchedExecutors.PUT.URL, { doer_user_id: doerId }, { replaceUrl: { id: executorId } })

      if (resp) {
        dispatch(action(Actions.UPDATE_YANDEX_LAVKA_UNMATCHED_EXECUTOR, { id: executorId }))

        dispatch(changeRequestState('connectLavkaUnmatchedExecutors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('connectLavkaUnmatchedExecutors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const addReportFile = (file: RcFile, onFinish: () => void): ThAction => {
  return async () => {
    try {
      const response = await apiV1.post(
        '/v1/yandex_lavka/reports',
        file,
        {
          headers: {
            'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        successNotification('Файл успешно загружен')
        onFinish()
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const deployYandexLavkaReportById = (id: string, onSuccess?: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deployYandexLavkaReport', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.YandexLavka.DeployReport.URL,{}, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.DEPLOY_YANDEXPRO_REPORT, resp))

        onSuccess && onSuccess()
        showInfo('Отчет обработан')
        dispatch(changeRequestState('deployYandexLavkaReport', StatusRequest.LOADED))

        dispatch(getYandexProReports())
      }
    } catch (e: any) {
      dispatch(changeRequestState('deployYandexLavkaReport', StatusRequest.ERROR))

      showError(e?.response?.data || e?.message || 'Ошибка')

      throw(e)
    }
  }
}