// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--footer--rcrah{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.styles-module--left--wg5AY{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}.styles-module--right--Z0oO\\+{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}.styles-module--cancelBtn--WF7iW{margin-left:16px}", "",{"version":3,"sources":["webpack://./src/components/FormFooter/styles.module.sass"],"names":[],"mappings":"AAAA,8BACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAEJ,4BACI,6BAAA,CAAA,6BAAA,CAAA,8BAAA,CAAA,0BAAA,CAEJ,8BACI,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CAEJ,iCACI,gBAAA","sourcesContent":[".footer\n    width: 100%\n    display: flex\n    justify-content: space-between\n\n.left\n    flex-direction: row-reverse\n\n.right\n    flex-direction: row\n\n.cancelBtn\n    margin-left: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "styles-module--footer--rcrah",
	"left": "styles-module--left--wg5AY",
	"right": "styles-module--right--Z0oO+",
	"cancelBtn": "styles-module--cancelBtn--WF7iW"
};
export default ___CSS_LOADER_EXPORT___;
