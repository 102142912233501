export const isFilterUpdated = (newFilters: any, reducerFilter: any) => {
  const keys = Object.keys(newFilters)
  let isChanged = false

  keys.forEach((key) => {
    if (newFilters[key] instanceof Array && reducerFilter[key] instanceof Array) {
      isChanged = !newFilters[key].every((_:any, i:number) => reducerFilter[key][i] === newFilters[key][i])
    } else if (newFilters[key] instanceof Array) {
      isChanged = !!newFilters[key].length !== !!reducerFilter[key]
    } else {
      isChanged = !!newFilters[key] !== !!reducerFilter[key]
    }
  })

  return isChanged
}

export const isObjectEmpty = (obj: any) => {
  const clearObj = JSON.parse(JSON.stringify(obj))


  return Object.keys(clearObj).length === 0
}

export const filterSelectionOptions = (input: string, option?: Record<string, any>): boolean => {
  return Boolean(option?.label?.toLowerCase().includes(input.toLowerCase()))
}