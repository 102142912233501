import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type DirectoryState = {
  services: APITYPES.AllServices.Get.Resp | null
  service: APITYPES.AllServices.Get.Resp[number] | null
  clientTypes: APITYPES.ClientTypes.Get.Resp | null
  carClasses: APITYPES.AllCarClasses.Get.Resp | null
  clientType: APITYPES.ClientTypes.Get.Resp[number] | null
  clientTypeServices: APITYPES.Services.Get.Resp | []
  clientTypeCarClasses: APITYPES.CarClasses.Get.Resp | []
  carClassCars?: APITYPES.AllCarClasses.GetCars.Resp
  carClass?: APITYPES.AllCarClasses.Get.Resp[number]
  carBrands?: APITYPES.Cars.GetBrands.Resp
  carBrandModels?: APITYPES.Cars.GetModels.Resp
  currentCarModelInfo?: APITYPES.Cars.GetModelInfo.Resp
}

export const initialDirectoryState = {
  services: null,
  service: null,
  clientTypes: null,
  carClasses: null,
  clientType: null,
  clientTypeServices: [],
  clientTypeCarClasses: [],
}

export const directoryReducer: Reducer<DirectoryState, Action> = (state = initialDirectoryState, a): DirectoryState => {
  switch (a.type) {
    case Actions.SET_CAR_CLASS_CARS:
      return {
        ...state,
        carClassCars: a.data,
      }
      
    case Actions.UPDATE_CLIENT_TYPE_CAR_CLASSES:
      return {
        ...state,
        clientTypeCarClasses: state.clientTypeCarClasses.map((item)=>{
          if (a.data.id === item.id) {
            return {
              ...item,
              position: a.data.position,
            }
          }

          return item
        }),
      }

    case Actions.UPDATE_CLIENT_TYPE_SERVICES:
      return {
        ...state,
        clientTypeServices: state.clientTypeServices.map((item)=>{
          if (a.data.id === item.id) {
            return {
              ...item,
              position: a.data.position,
            }
          }
  
          return item
        }),
      }

    case Actions.SET_CLIENT_TYPE_CAR_CLASSES:
      return {
        ...state,
        clientTypeCarClasses: a.data,
      }

    case Actions.SET_CLIENT_TYPE_SERVICES:
      return {
        ...state,
        clientTypeServices: a.data,
      }

    case Actions.ADD_CAR_CLASS:
      return {
        ...state,
        clientTypeCarClasses: [...state.clientTypeCarClasses, a.data],
      }

      // case Actions.ADD_SERVICE:
      //   return {
      //     ...state,
      //     clientTypeServices: [...state.clientTypeServices, a.data],
      //   }

    case Actions.SET_DIRECTORY_CLIENT_TYPE:
      return {
        ...state,
        clientType: a.data,
      }

    case Actions.SET_DIRECTORY_SERVICE:
      return {
        ...state,
        service: a.data,
      }

    case Actions.SET_DIRECTORY_SERVICES:
      return {
        ...state,
        services: a.data,
      }

    case Actions.UPDATE_DIRECTORY_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: state.clientTypes?.map((item)=>{
          if (item.id === a.data.id) {return a.data}
  
          return item
        }) || null,
        clientType: a.data,
      }
  
    case Actions.DELETE_DIRECTORY_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: state.clientTypes?.filter((item)=>{
          return item.id !== a.data.id
        }) || null,
      }

    case Actions.UPDATE_DIRECTORY_SERVICES:
      return {
        ...state,
        services: state.services?.map((item)=>{
          if (item.id === a.data.id) {return a.data}

          return item
        }) || null,
      }

    case Actions.DELETE_DIRECTORY_SERVICE:
      return {
        ...state,
        services: state.services?.filter((item)=>{
          return item.id !== a.data.id
        }) || null,
      }


    case Actions.SET_DIRECTORY_CAR_CLASSES:
      return {
        ...state,
        carClasses: a.data,
      }

    case Actions.SET_DIRECTORY_CAR_CLASS:
      return {
        ...state,
        carClass: state.carClasses ? state.carClasses.find((car) => car.id === a.data) : undefined,
      }

    case Actions.CREATE_DIRECTORY_CAR_CLASSES:
      return {
        ...state,
        carClasses: state.carClasses ? [...state.carClasses, {
          ...a.data,
          position: state.carClasses.length + 1,
        }] : [{
          ...a.data,
          position: 1,
        }],
      }
  

    case Actions.SET_DIRECTORY_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: a.data,
      }

    case Actions.CREATE_DIRECTORY_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: state.clientTypes ? [...state.clientTypes, a.data] : [a.data],
      }

    case Actions.CREATE_DIRECTORY_SERVICE:
      return {
        ...state,
        services: state.services ? [...state.services, a.data] : [a.data],
      }

    case Actions.SET_ALL_CAR_BRANDS:
      return {
        ...state,
        carBrands: a.data,
      }

    case Actions.SET_ALL_BRAND_MODELS:
      return {
        ...state,
        carBrandModels: a.data,
      }

    case Actions.CLEAR_BRAND_MODELS:
      return {
        ...state,
        carBrandModels: undefined,
      }

    case Actions.SET_CAR_MODEL_INFO:
      return {
        ...state,
        currentCarModelInfo: a.data,
      }

    case Actions.SET_CAR_MODEL_CAR_CLASSES:
      return {
        ...state,
        currentCarModelInfo: state.currentCarModelInfo ? {
          ...state.currentCarModelInfo,
          car_classes: a.data,
        } : undefined,
      }
  }
  
  return state
}
