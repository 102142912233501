import { action, Actions, ThAction } from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'


export const getFleetsServices = (filter?: APITYPES.Fleets.GetServices.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    const status = getState().requestsReducer.getFleetsServices

    if (status === StatusRequest.LOADING) {
      return
    }
    
    dispatch(changeRequestState('getFleetsServices', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Fleets.GetServices.URL, { params: { ...filter } })

      if (resp) {
        dispatch(action(Actions.SET_FLEETS_SERVICES, resp))
        dispatch(changeRequestState('getFleetsServices', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getFleetsServices', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createFleetService = (body: APITYPES.Fleets.CreateFleetServices.Req, onClose: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('createFleetService', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Fleets.CreateFleetServices.URL, body)

      if (resp) {
        dispatch(action(Actions.CLEAR_FLEETS_SERVICES, {}))
        const fleetFilters = { ...getState().fleetsReducer.fleetsServicesFilter }

        dispatch(getFleetsServices(fleetFilters))

        dispatch(changeRequestState('createFleetCar', StatusRequest.LOADED))
        onClose()
      }
    } catch (e: any) {
      dispatch(changeRequestState('createFleetCar', StatusRequest.ERROR))
      showError(e.response.data)

      throw(e)
    }
  }
}

export const changeFleetService = ( fleet_service_id: string,
  body: APITYPES.Fleets.ChangeFleetService.Req, onClose: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('changeFleetService', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Fleets.ChangeFleetService.URL, body, { replaceUrl: { fleet_service_id } })

      if (resp) {
        const fleetServices = getState().fleetsReducer.fleetsServices
        const theService = fleetServices.find((service) => service.id === fleet_service_id)

        if (theService) {
          theService.fleet_id = resp.fleet_id
          theService.title = resp.title
          theService.city_id = resp.city_id
          theService.task_id = resp.task_id
          theService.task_title = resp.task_title
          theService.car_class_id = resp.car_class_id
          theService.price = resp.price
          theService.begin = resp.begin
          theService.end = resp.end
        }

        dispatch(changeRequestState('changeFleetCar', StatusRequest.LOADED))
        onClose()
      }
    } catch (e: any) {
      dispatch(changeRequestState('changeFleetCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteFleetService = (fleet_service_id : string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteFleetCar', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Fleets.DeleteFleetService.URL, { replaceUrl: { fleet_service_id } })

      if (resp) {
        const filter = getState().fleetsReducer.fleetsServicesFilter

        dispatch(action(Actions.CLEAR_FLEETS_SERVICES, []))
        dispatch(getFleetsServices(filter))

        dispatch(changeRequestState('deleteFleetCar', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteFleetCar', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}