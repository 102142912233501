import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const getBills = (filter?: {
  limit?: number
  offset?: number
  state?: string[]
  contractor_id?: string[]
  start?: string
  end?: string
}, contractor_type?: 'partner' | 'doer'): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getBills', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetBills.URL, {
        params: {
          ...filter,
          offset: filter?.offset ?? getState().billingReducer.bills.length,
          limit: filter?.limit ?? 20,
          ...getState().billingReducer.billsFilter,
          contractor_type: contractor_type,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_BILLS, {
          bills: resp,
          offset: filter?.offset,
        }))
        dispatch(changeRequestState('getBills', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getBills', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getBillsDoers = (filter?: {
  limit?: number
  offset?: number
  state?: string[]
  contractor_id?: string[]
  start?: string
  end?: string
}, contractor_type?: 'partner' | 'doer'): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getBillsDoers', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Billing.GetBills.URL, {
        params: {
          ...filter,
          offset: filter?.offset ?? getState().billingReducer.billsDoers.length,
          limit: filter?.limit ?? 20,
          ...getState().billingReducer.billsDoersFilter,
          contractor_type: contractor_type,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_BILLS_DOERS, {
          billsDoers: resp,
          offset: getState().billingReducer.billsDoers.length,
        }))
        dispatch(changeRequestState('getBillsDoers', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getBillsDoers', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const reloadBillsDoers = (filter?: {
  limit?: number
  offset?: number
  state?: string[]
  contractor_id?: string[]
  start?: string
  end?: string
}, contractor_type?: 'partner' | 'doer'): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getBillsDoers', StatusRequest.LOADING))
    dispatch(action(Actions.CLEAN_BILLS_DOERS, {}))
    
    try {
      const resp = await api.get(APITYPES.Billing.GetBills.URL, {
        params: {
          ...filter,
          limit: filter?.limit ?? 20,
          ...getState().billingReducer.billsDoersFilter,
          contractor_type: contractor_type,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_BILLS_DOERS, {
          billsDoers: resp,
          offset: getState().billingReducer.billsDoers.length,
        }))
        dispatch(changeRequestState('getBillsDoers', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getBillsDoers', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}