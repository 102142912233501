import React, { useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { APITYPES } from 'types/apitypes'
import { updatePartner } from 'AC/partners/partners'
import { DocumentsBlock } from '../DocumentsBlock'

import styles from './styles.module.sass'
import { isFormChanged, isFormValid, normalizeFormTypings } from './utils'


type Props = {
  partner: APITYPES.Partners.GetPartner.Resp
}

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

export const PartnerInfo = (props: Props) => {
  const { partner } = props
  const [form] = useForm()
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState<APITYPES.Partners.GetPartner.Resp>(normalizeFormTypings(form))

  const onFinish = async (values: any) => {
    dispatch(updatePartner({
      legal_address: values.legal_address || null,
      legal_name: values.legal_name || null,
      inn: values.inn || null,
      ogrn: values.ogrn || null,
      checking_account: values.checking_account || null,
      bank: values.bank || null,
      bik: values.bik || null,
      corr_account: values.corr_account || null,
      general_manager: values.general_manager || null,
      diadoc_state: values.diadoc_state || null,
      signer_tag: values.signer_tag || null,
      documents: partner.documents,
    }, partner.id))
  }


  const onBlur = () => {
    const errors = isFormValid(form)
    const values = normalizeFormTypings(form)
    const isChanged = isFormChanged(values, formValues)

    if (!errors && isChanged) {
      setFormValues(values)
      form.submit()
    }
  }

  const onChange = () => {}

  return (
    <div className={styles.container}>
      <div className={styles.formColumn}>
        <Form
          className={styles.form}
          name='time_related_controls'
          {...formItemLayout}
          onFinish={onFinish}
          onBlur={onBlur}
          onChange={onChange}
          form={form}
        >
          <Form.Item
            name='legal_name'
            label='Юридическое лицо'
            initialValue={partner.legal_name || null}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='legal_address'
            label='Юридический адрес'
            initialValue={partner.legal_address}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='inn'
            label='ИНН'
            initialValue={partner.inn}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='ogrn'
            label='ОГРН / ОГРНИП'
            initialValue={partner.ogrn}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='checking_account'
            label='Расчетный счет'
            initialValue={partner.checking_account}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='corr_account'
            label='Корр. счет'
            initialValue={partner.corr_account}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='bank'
            label='Банк'
            initialValue={partner.bank}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='bik'
            label='БИК'
            initialValue={partner.bik}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='general_manager'
            label='Генеральный директор'
            initialValue={partner.general_manager}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='diadoc_state'
            label='Подключен к Диадок'
            initialValue={partner.diadoc_state ?? 'Подключен'}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Select showSearch>
              <Select.Option value='Подключен'>Подключен</Select.Option>
              <Select.Option value='В процессе подключения'>
                В процессе подключения
              </Select.Option>
              <Select.Option value='Отказ'>Отказ</Select.Option>
              <Select.Option value='Меняет ИП'>Меняет ИП</Select.Option>
              <Select.Option value='В работе'>В работе</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='signer_tag'
            label='Договор с'
            initialValue={partner.signer_tag}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Select>
              <Select.Option value='servicepro'>ООО "СервисПро"</Select.Option>
              <Select.Option value='topdoer'>
                ООО "ТОПДУЕР"
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.tableColumn}>
        <DocumentsBlock documents={partner.documents} />
      </div>
    </div>
  )
}