// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container---Nj7z{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.styles-module--form--tSqHI label{white-space:pre-wrap}.styles-module--formColumn--WPUKU{width:35%}.styles-module--tableColumn--DNe-j{width:60%}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Registry/Card/Partner/PartnerInfo/styles.module.sass"],"names":[],"mappings":"AAAA,iCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGA,kCACI,oBAAA,CAER,kCACI,SAAA,CAEJ,mCACI,SAAA","sourcesContent":[".container \n    display: flex\n    width: 100%\n    justify-content: space-between\n\n.form\n    label\n        white-space: pre-wrap\n\n.formColumn\n    width: 35%\n\n.tableColumn\n    width: 60%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container---Nj7z",
	"form": "styles-module--form--tSqHI",
	"formColumn": "styles-module--formColumn--WPUKU",
	"tableColumn": "styles-module--tableColumn--DNe-j"
};
export default ___CSS_LOADER_EXPORT___;
