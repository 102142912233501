import qs from 'qs'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { reloadBillsDoers } from 'AC/billing/getBills'


export const useGetBillsDoers = () => {
  const dispatch = useDispatch()
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  
  const getBills = useCallback(() => {
    const parsed = qs.parse(params)

    const result = {
      ...parsed,
      state: parsed?.state ? (parsed.state as string).split(',') : undefined,
    }

    dispatch(reloadBillsDoers(result, 'doer'))
  }, [dispatch, params])

  return getBills
}