// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--form--5L-Y1 label{white-space:pre-wrap;overflow:visible}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Premises/Premise/Info/styles.module.sass"],"names":[],"mappings":"AACI,kCACI,oBAAA,CACA,gBAAA","sourcesContent":[".form\n    label\n        white-space: pre-wrap\n        overflow: visible\n        "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles-module--form--5L-Y1"
};
export default ___CSS_LOADER_EXPORT___;
