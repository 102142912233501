import React from 'react'
import cn from 'classnames'
import { Input, Button, Form } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import { login } from 'AC/auth'

import styles from './styles.module.sass'


export const SignIn = () => {
  const dispatch = useDispatch()

  const onLogin = (values: {login: string, password: string}) => {
    dispatch(login(values))
  }

  return (
    <div className={styles.main}>
      <Form
        className={styles.form}
        onFinish={onLogin}
      >
        <h1 className={styles.title}>Admin login</h1>
        <Form.Item name='login'>
          <Input
            className={styles.input}
            prefix={<UserOutlined className='site-form-item-icon' />}
            type='email'
            placeholder='Username'
            size='large'
          />
        </Form.Item>
        <Form.Item name='password'>
          <Input
            className={styles.input}
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder='Password'
            size='large'
          />
        </Form.Item>
        <Button
          type='primary'
          size='large'
          htmlType='submit'
          className={cn('login-form-button', styles.button)}
        >
          Log in
        </Button>
      </Form>
    </div>
  )
}