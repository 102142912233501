import React, { useState } from 'react'
import { TimePicker, Form, Dropdown, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useForm } from 'antd/lib/form/Form'

import { DeleteButton } from 'components/DeleteButton'
import { APITYPES } from 'types/apitypes'
import { showSureConfirm } from 'components/modals/SureModal'
import { deleteInterval, updateInterval } from 'AC/carwashes/schedules'

import { IntervalMenu } from './Menu'
import styles from './styles.module.sass'


type Props = {
  interval: APITYPES.ScheduleInterval
  scheduleId: string
}

const format = 'HH:mm'

const getModeByInterval = (interval: APITYPES.ScheduleInterval) => {
  if (interval.work_from && interval.work_to) {return 1}

  if (!interval.work_from && interval.work_to) {return 2}

  if (interval.work_from && !interval.work_to) {return 3}

  return 4
}

export const Interval = (props: Props) => {
  const  { interval, scheduleId } = props

  const dispatch = useDispatch()

  const [mode, setMode] = useState<number>(getModeByInterval(interval))

  const [form] = useForm()

  const onDelete = () => {
    showSureConfirm(()=>{
      dispatch(deleteInterval(scheduleId, interval.id))
    },()=>{})
  }

  const onChange = () => {
    const values = form.getFieldsValue()
      
    dispatch(updateInterval(
      {
        day_num: interval.day_num,
        work_from: values.interval ? values.interval[0].format(format) : null,
        work_to: values.interval ? values.interval[1].format(format) : null,
      }, scheduleId, interval.id,
    ))
  }

  const onFromChange = () => {
    const values = form.getFieldsValue()
      
    dispatch(updateInterval(
      {
        day_num: interval.day_num,
        work_from: values.from.format(format),
        work_to: null,
      }, scheduleId, interval.id,
    ))
  }

  const onToChange = () => {
    const values = form.getFieldsValue()
      
    dispatch(updateInterval(
      {
        day_num: interval.day_num,
        work_from: null,
        work_to: values.to.format(format),
      }, scheduleId, interval.id,
    ))
  }

  const handleMenuClick = (e: any) => {
    setMode(parseFloat(e.key))

    if (parseFloat(e.key) === 4) {
      dispatch(updateInterval(
        {
          day_num: interval.day_num,
          work_from: null,
          work_to: null,
        }, scheduleId, interval.id,
      ))
    }

    if (parseFloat(e.key) === 2 && interval.work_to) {
      dispatch(updateInterval(
        {
          day_num: interval.day_num,
          work_from: null,
          work_to: interval.work_to,
        }, scheduleId, interval.id,
      ))
    }

    if (parseFloat(e.key) === 3 && interval.work_from) {
      dispatch(updateInterval(
        {
          day_num: interval.day_num,
          work_from: interval.work_from,
          work_to: null,
        }, scheduleId, interval.id,
      ))
    }
  }

  return (
    <Form
      form={form}
      className={styles.interval}
    >
      {
        mode === 1 && (
          <Form.Item
            name='interval'
            initialValue={
              [
                moment(interval.work_from, format).isValid() ?
                  moment(interval.work_from, format) : undefined as any,
                moment(interval.work_to, format).isValid() ?
                  moment(interval.work_to, format) : undefined as any,
              ]
            }
          >
            <TimePicker.RangePicker
              onChange={onChange}
              minuteStep={5}
              format={format}
            />
          </Form.Item>
        )
      }
      {
        mode === 2 && (
          <div className={styles.item}>
            <div className={styles.startText}>Пред. день -</div>
            <Form.Item
              name='to'
              initialValue={
                moment(interval.work_to, format).isValid() ?
                  moment(interval.work_to, format) : undefined
              }
            >
              <TimePicker
                onChange={onToChange}
                minuteStep={5}
                format={format}
              />
            </Form.Item>
          </div>
        )
      }
      {
        mode === 3 && (
          <div className={styles.item}>
            <Form.Item
              name='from'
              initialValue={
                moment(interval.work_from, format).isValid()
                  ? moment(interval.work_from, format)
                  : undefined
              }
            >
              <TimePicker
                onChange={onFromChange}
                minuteStep={5}
                format={format}
              />
            </Form.Item>
            <div className={styles.endText}>- След. день</div>
          </div>
        )
      }
      {
        mode === 4 && (
          <Form.Item
            name='interval'
          >
            <div>Круглосуточно</div>
          </Form.Item>
        )
      }
      <DeleteButton
        className={styles.deleteButton}
        onClick={() => onDelete()}
      />
      <Dropdown
        overlay={<IntervalMenu handleMenuClick={handleMenuClick} />}
      >
        <Button
          icon={<EditOutlined />}
        />
      </Dropdown>
    </Form>
  )
}