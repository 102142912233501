import React from 'react'

import { AddShiftModal } from './components/AddShiftModal'
import { ShiftsFilter } from './components/ShiftsFilter'
import { Shifts } from './components/Shifts'
import { CommitModal } from './components/CommitModal'

import {
  ShiftTabsProvider,
  ShiftsContextProvider,
  FilterDrawerProvider,
  ShiftsFilterProvider,
} from './context'


export const ShiftsPage = () => {
  return (
    <ShiftTabsProvider>
      <ShiftsContextProvider>
        <ShiftsFilterProvider>
          <FilterDrawerProvider>
            <Shifts />
            <ShiftsFilter />
            <AddShiftModal />
            <CommitModal />
          </FilterDrawerProvider>
        </ShiftsFilterProvider>
      </ShiftsContextProvider>
    </ShiftTabsProvider>
  )
}
