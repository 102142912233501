import React, { useEffect } from 'react'
import { Form, InputNumber, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'stores/rootReducer'
import { fetchProducts } from 'AC/products/products'
import { addCustomerTariffProducts } from 'AC/products/tariffs'
import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'

const formLayout = {
  labelCol: {span: 4},
}

type Props = {
  onClose: () => void
}

const selector = (state: State) => ({
  tariff: state.customerTariffsReducer.customerTariff,
  products: state.productsReducer.products,
})

export const ProductForm = ({onClose}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {tariff, products} = useSelector(selector)

  useEffect(() => {
    if (!products.length) dispatch(fetchProducts())
    return () => {
      form.resetFields()
    }
  }, [])

  const onFinish = (values: any) => {
    if (!tariff) return
    dispatch(addCustomerTariffProducts(tariff.id, values))
    onClose()
  }

  return (
    <Form form={form} onFinish={onFinish} {...formLayout}>
      <Form.Item label="Продукт" name="product_id" rules={required}>
        <Select
          showSearch
          allowClear
          placeholder="Выберите продукт"
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {products.map(product => (<Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>))}
        </Select>
      </Form.Item>

      <Form.Item label="Цена" name="price" rules={required}>
        <InputNumber placeholder="300"/>
      </Form.Item>

      <FormFooter onCancel={onClose} okText="Добавить"/>
    </Form>
  );
};
