import { Form, Input, Modal, Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { changeCar as changeCarRequest } from 'AC/users/changeCar'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchModels } from 'AC/cars'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
  car: APITYPES.UserCar
  userId: string
}

const selector = (state: State) => ({
  models: state.carsReducer.models,
  brands: state.carsReducer.brands,
  isLoading: state.requestsReducer.fetchModels === StatusRequest.LOADING
})

const formLayout = {
  labelCol: {span: 4}
}

export const ChangeCar = (props: Props) => {
  const { isVisible, car, onCancel, userId } = props

  const { models, brands, isLoading } = useSelector(selector)

  const [ form ] = useForm()

  const dispatch = useDispatch()

  useEffect(() => {
    const brand = brands.find((brand)=> brand.name === car.car_brand_name)

    if (!brand) {return}

    dispatch(fetchModels(brand.id))
  }, [dispatch, brands, car])

  useEffect(() => {
    const model = models.find((item)=>parseFloat(item.id) === car.car_model_id)

    const brand = brands.find((item)=>item.name == car.car_brand_name)

    if (!model || !brand) {return}

    form.setFieldsValue({
      car_model: model.name,
      car_brand: brand.name,
    })
  }, [models, brands, car, form])

  const onFinish = async (values: any) => {
    const model = models.find((item)=>item.name === values.car_model)

    if (!model) {return}

    dispatch(changeCarRequest(userId, car.car_id,
      {
        car_model_id: parseFloat(model.id),
        grz: values.grz,
      }))

    onCancel()
  }

  const onChange = (name: string) => {
    form.setFieldsValue({ car_model: undefined })

    const brand = brands.find((item)=>item.name === name)

    if (!brand) {return}

    form.setFieldsValue({ car_brand: brand.name })
    dispatch(fetchModels(String(brand.id)))
  }

  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title='Редактирование'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
      afterClose={afterClose}
    >
      <Form
        name='car'
        onFinish={onFinish}
        form={form}
        {...formLayout}
      >
        <Form.Item
          name='grz'
          label='Гос. номер'
          initialValue={car.grz}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='car_brand'
          initialValue=''
          label='Марка'
        >
          <Select
            showSearch
            onChange={onChange}
            loading={isLoading}
            disabled={isLoading}
          >
            {
              brands.map((brand)=>{
                return (
                  <Select.Option
                    key={brand.id}
                    value={brand.name}
                  >
                    { brand.name }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='car_model'
          label='Модель'
          initialValue={car.car_model_id}
        >
          <Select
            showSearch
            loading={isLoading}
            disabled={isLoading}
          >
            {
              models.map((model)=>{
                return (
                  <Select.Option
                    key={model.id}
                    value={model.name}
                  >
                    { model.name }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Сохранить'
        />
      </Form>
    </Modal>
  )
}