import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import qs from 'qs'

import { FilterIcon } from '../../../../components/FilterIcon'
import { State } from '../../../../stores/rootReducer'
import { getDoerVacancies } from '../../../../AC/doers/doerVacancies'
import { isObjectEmpty } from '../../../../utils/filterUtils'
import { action, Actions } from '../../../../actions'
import { ROUTES } from '../../../../config/routes'
import { StatusRequest } from '../../../../stores/requestsReducer'
import { filterEmptyValues, getUrlSearchParams } from '../../../../utils/urlUtils'
import useIntersectionObserver from '../../../../utils/hooks/useIntersectionObserver'
import { InfiniteTable } from '../../../../components/InfiniteTable'
import { getColumns, getRows } from './utils/utils'

import styles from './styles.module.sass'
import { AddModal } from './AddModal'
import { Filter } from './Filter'


const selector = (state: State) => {
  const { vacanciesFilter, doerVacancies, hasMore } = state.doerVacanciesReducer
  const isLoading = state.requestsReducer.getDoerVacancies === StatusRequest.LOADING
  
  return {
    filter: vacanciesFilter,
    doerVacancies,
    hasMore,
    isLoading,
  }
}

export const Vacancies = () => {
  const dispatch = useDispatch()
  const { doerVacancies, filter, hasMore, isLoading } = useSelector(selector)
  const queryParams = useMemo(() => getUrlSearchParams(), [])
  const history = useHistory()

  const isFirstRun = useRef(true)
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  const [addModal, setAddModal] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const toggleAddModal = () => setAddModal(!addModal)
  const toggleFilter = () => setIsFilterOpen((prev) => !prev)

  useEffect(() => {
    const parsed = qs.parse(queryParams)

    dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
    dispatch(getDoerVacancies(parsed))

    dispatch(action(Actions.SET_DOER_VACANCIES_FILTER, parsed))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }
    const parsed = qs.parse(queryParams)

    dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
    dispatch(getDoerVacancies(parsed))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (entry?.isIntersecting && !isFirstRun.current && hasMore) {
      const parsed = qs.parse(queryParams)

      dispatch(action(Actions.CLEAN_DOER_VACANCIES, {}))
      dispatch(getDoerVacancies(parsed))
    }
  }, [dispatch, entry?.isIntersecting, hasMore, queryParams])

  const loadMore = () => {
    if (isLoading) {return}
    const parsedParams = filterEmptyValues(qs.parse(queryParams))
    const parsedFilter = filterEmptyValues(qs.parse(filter as {}))

    dispatch(getDoerVacancies({
      ...parsedParams,
      ...parsedFilter,
    }))
  }

  return (
    <div className={styles.main}>
      <div className={styles.toolbar}>
        <div className={styles.addBtns}>
          <Button
            type='primary'
            onClick={toggleAddModal}
          >
            Добавить вакансию
          </Button>
        </div>

        <Button
          icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          onClick={toggleFilter}
        />
      </div>

      <InfiniteTable
        columns={getColumns()}
        isLoading={isLoading}
        hasMore={hasMore}
        className={styles.tableContainer}
        loadMore={loadMore}
      >
        <Table
          showHeader={false}
          sticky
          size='small'
          columns={getColumns() as any}
          dataSource={getRows(doerVacancies || [])}
          className={styles.table}
          bordered
          pagination={false}
          scroll={{ x: '100%' }}
          onRow={(record) => ({ onClick: () => history.push(ROUTES.DOERS.VACANCIES.createPath(record.key)) })}
        />
      </InfiniteTable>

      <AddModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />

      <Filter
        isOpen={isFilterOpen}
        onClose={toggleFilter}
      />
    </div>
  )
}