import { APITYPES } from 'types/apitypes';

const {Cities} = APITYPES
//@ts-ignore
const cities = Object.keys(Cities).filter(isNaN)


export const getCityName = (city_id: number) => {
  return cities[city_id-1]
}

export const getShiftTypeName = (shiftTypeId: string , shiftTypes: APITYPES.ShiftType[]) => {
  const type = shiftTypes?.find(s => s.id === shiftTypeId)
  if(type) return type.name
  return ''
}

export const getLocationName = (locationId: string, locations: APITYPES.Location[]) => {
  const type = locations?.find(s => s.id === locationId)
  if(type) return type.name
  return ''
}

export const getRoleName = (roleId: string, roles: APITYPES.Role[]) => {
  const type = roles?.find(s => s.id === roleId)
  if(type) return type.name
  return ''
}