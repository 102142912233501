// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--container--hJxzd{height:calc(100vh - 88px)}.styles-module--addBtn--E98Zz{height:20px;font-size:12px}.styles-module--panel--wH5fX{padding:0}.styles-module--collapse--ybfGc>div>div:nth-child(2)>div:first-child{padding:0}[data-theme=compact] .styles-module--collapse--ybfGc .styles-module--collapsePanel--RPeaG,.styles-module--collapse--ybfGc .styles-module--collapsePanel--RPeaG{margin-bottom:12px;overflow:hidden;background:#fff}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/shift-settings/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,yBAAA,CAEF,8BACE,WAAA,CACA,cAAA,CAEF,6BACE,SAAA,CAGA,qEACE,SAAA,CAEJ,+JAEE,kBAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".container\n  height: calc(100vh - 88px)\n\n.addBtn\n  height: 20px\n  font-size: 12px\n\n.panel\n  padding: 0\n\n.collapse\n  & > div > div:nth-child(2) > div:first-child\n    padding: 0\n\n[data-theme='compact'] .collapse .collapsePanel,\n  .collapse .collapsePanel\n  margin-bottom: 12px\n  overflow: hidden\n  background: #fff\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module--container--hJxzd",
	"addBtn": "styles-module--addBtn--E98Zz",
	"panel": "styles-module--panel--wH5fX",
	"collapse": "styles-module--collapse--ybfGc",
	"collapsePanel": "styles-module--collapsePanel--RPeaG"
};
export default ___CSS_LOADER_EXPORT___;
