/* eslint-disable no-unused-vars */
import React from 'react'
import { Button, Input, Popconfirm } from 'antd'
// import { useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
// import { StatusRequest } from 'stores/requestsReducer'
// import { State } from 'stores/rootReducer'


// const { Option } = Select

export const getColumns = (
  tariff?: APITYPES.DoerTariffNoTasks,
  onShiftTypeDelete?: (shift_type_id: string) => void,
  onConditionsChange?: (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => void,
  onShiftTypeChange?: (
    shift_type_id: string,
    shiftType: APITYPES.DoerTariffShiftType,
    partial: Partial<APITYPES.DoerTariff.ShiftTypes.PUT.Req>
  ) => void
) => {
  return [
    {
      title: '',
      dataIndex: 'shift_type_name',
      key: 'shift_type_name',
      align: 'center',
      width: 280,
    },
    {
      title: 'По умолчанию',
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={1}
          cond={tariff?.condition_1}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_1',
      key: 'cost_1',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost_1'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={2}
          cond={tariff?.condition_2}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_2',
      key: 'cost_2',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost_2'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={3}
          cond={tariff?.condition_3}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_3',
      key: 'cost_3',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost_3'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={4}
          cond={tariff?.condition_4}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_4',
      key: 'cost_4',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost_4'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: (
        <HeaderInput
          num={5}
          cond={tariff?.condition_5}
          onChange={onConditionsChange}
        />
      ),
      dataIndex: 'cost_5',
      key: 'cost_5',
      align: 'center',
      render: (task: APITYPES.DoerTariffShiftType) => (
        <RowInput
          task={task}
          keyValue='cost_5'
          onChange={onShiftTypeChange}
        />
      ),
    },
    {
      title: 'Действие',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 110,
      render: (shift_type_id: string) => (
        <Popconfirm
          title='Вы уверены, что хотите удалить задачу?'
          onConfirm={onShiftTypeDelete ? () => onShiftTypeDelete(shift_type_id) : undefined}
          okText='Да'
          cancelText='Нет'
          placement='bottomRight'
        >
          <Button type='link'>Удалить</Button>
        </Popconfirm>
      ),
    },
  ]
}

export const getRows = (shiftTypes: APITYPES.DoerTariffShiftType[]) => {
  return shiftTypes.map((shiftType) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'actions') {
        row[column.dataIndex] = shiftType.id
      } else if (column.dataIndex.includes('cost')) {
        //@ts-ignore
        row[column.dataIndex] = shiftType
      } else {
        //@ts-ignore
        row[column.dataIndex] = shiftType[column.dataIndex]
      }
    })
    row.key = shiftType.shift_type_id

    return row
  })
}

type RowInputProps = {
  task: APITYPES.DoerTariffShiftType
  keyValue: keyof APITYPES.DoerTariff.ShiftTypes.PUT.Req
  onChange?: (
    task_id: string,
    task: APITYPES.DoerTariffShiftType,
    partial: Partial<APITYPES.DoerTariff.ShiftTypes.PUT.Req>
  ) => void
};
const RowInput = ({ task, keyValue, onChange }: RowInputProps) => {
  return (
    <Input
      type='number'
      size='small'
      defaultValue={task[keyValue]}
      onBlur={
        onChange
          ? (e) => {
            const val =
                e.target?.value && e.target?.value !== '0'
                  ? e.target.value
                  : undefined

            onChange(task.shift_type_id, task, { [keyValue]: val })
          }
          : undefined
      }
    />
  )
}

type HeaderInputProps = {
  num: number
  cond?: number | undefined
  onChange?: (partial: Partial<APITYPES.DoerTariff.PUT.Req>) => void
};
const stl = { width: '70%' }
const HeaderInput = ({ cond, num, onChange }: HeaderInputProps) => {
  return (
    <>
      { 'Усл. ' }
      { num }
      { ' ' }
      <Input
        size='small'
        type='number'
        defaultValue={cond}
        style={stl}
        onBlur={
          onChange
            ? (e) => {
              const val = e.target?.value ? +e.target.value : null

              onChange({ [`condition_${num}`]: val })
            }
            : undefined
        }
      />
    </>
  )
}

export const getFilteredShiftTypes = (shiftTypes: APITYPES.ShiftType[], tariff: any) => {
  return shiftTypes
    .filter((shiftType: { city_id: any, roles: any[] }) => {
      return (
        shiftType.city_id === tariff.city_id &&
        shiftType.roles &&
        shiftType.roles.some((role: { id: any }) => role.id === tariff.role_id)
      )
    })
}