import { Form, Modal, Input } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { updateClientTypes } from 'AC/directory/directory'
import { FormFooter } from 'components/FormFooter'


type Props = {
  isVisible: boolean
  onCancel: () => void
  clientType: APITYPES.ClientTypes.Get.Resp[number]
}

export const EditForm = (props: Props) => {
  const { clientType, isVisible, onCancel } = props

  const dispatch = useDispatch()

  const onFinish = async (values: APITYPES.ClientTypes.Put.Req) => {
    dispatch(updateClientTypes({
      ...values,
      id: clientType.id,
    }))

    onCancel()
  }

  return (
    <Modal
      title='Редактировать'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='service'
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          initialValue={clientType.name}
          label='Название'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='position'
          initialValue={clientType.position}
          label='Позиция'
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='code'
          initialValue={clientType.code}
          label='Code'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Сохранить'
        />
      </Form>
    </Modal>
  )
}