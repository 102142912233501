import { APITYPES } from 'types/apitypes';

export const getColumns = () => {
  return [
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      fixed: 'left',
    },
    {
      title: 'Регион',
      dataIndex: 'region',
      key: 'region',
      fixed: 'left',
      width: '20%',
    },
    {
      title: 'Аренда',
      dataIndex: 'rent_count',
      key: 'rent_count',
      fixed: 'left',
      width: '15%',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a: APITYPES.Rent, b: APITYPES.Rent) => b.rent_count - a.rent_count,
      // sortDirections: ['ascend'],
    },
    {
      title: 'Стоимость',
      dataIndex: 'rent_amount',
      key: 'rent_amount',
      fixed: 'left',
      width: '15%',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a: APITYPES.Rent, b: APITYPES.Rent) => b.rent_amount - a.rent_amount,
      // sortDirections: ['ascend'],
    },
  ]
}

export const getRows = (rents: APITYPES.Rent[]) => {
  return rents.map((rent) => {
    const row: any = {}

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = rent[column.dataIndex]

      return
    })
    row.key = rent.carwash_id

    return row
  })
}
