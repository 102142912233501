import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type PartnersState = {
  search?: string
  allPartners: APITYPES.Partner[]
  partners: APITYPES.Partner[]
  partner: APITYPES.Partner | null
  partnerByInn: APITYPES.PartnerInfoByInn | null
  partnerByInnForCreating: APITYPES.PartnerInfoByInn | null
  hasMore: boolean
}

export const initialPartnersState = {
  allPartners: [],
  partners: [],
  partner: null,
  partnerUpdatingStatus: 'Сохранено',
  partnerByInn: null,
  partnerByInnForCreating: null,
  hasMore: true,
}

export const partnersReducer: Reducer<PartnersState, Action> = (state = initialPartnersState, a): PartnersState => {
  switch (a.type) {
    case Actions.SET_ALL_PARTNERS: {
      return {
        ...state,
        allPartners: a.data
      }
    }

    case Actions.SET_PARTNER_DOCUMENTS:
      return {
        ...state,
        partner: state.partner ? {
          ...state.partner,
          documents: a.data,
        } : null,
      }

    case Actions.ADD_PARTNER_DOCUMENT:
      return {
        ...state,
        partner: state.partner ? {
          ...state.partner,
          documents: [a.data, ...state.partner.documents],
        } : null,
      }

    case Actions.UPDATE_PARTNER_DOCUMENT:
      return {
        ...state,
        partner: state.partner ? {
          ...state.partner,
          documents: state.partner.documents.map((item)=>item.id === a.data.id ? a.data : item),
        } : null,
      }

    case Actions.DELETE_PARTNER_DOCUMENT:
      return {
        ...state,
        partner: state.partner ? {
          ...state.partner,
          documents: state.partner.documents.filter((item)=>item.id !== a.data),
        } : null,
      }

    case Actions.SET_PARTNERS_SEARCH:
      return {
        ...state,
        search: a.data,
      }

    case Actions.SET_PARTNER_BY_INN_FOR_CREATING:
      return {
        ...state,
        partnerByInnForCreating: a.data,
      }

    case Actions.SET_PARTNER:
      return {
        ...state,
        partner: a.data ? {
          ...a.data,
          documents: a.data.documents ?? [],
        } : null,
      }

    case Actions.CLEAN_PARTNERS:
      return {
        ...state,
        partners: [],
        hasMore: true,
      }

    case Actions.SET_PARTNERS:
      const newState = {
        ...state,
        partners: [...state.partners, ...a.data],
        hasMore: a.data.length ? true : false,
      }

      return { ...newState }
    
    case Actions.UPDATE_PARTNER:
    
      return {
        ...state,
        partner: a.data.partner,
        partners: state.partners.map((partner) => {
          if (partner.id === a.data.id) {return a.data.partner}

          return partner
        }),
      }

    case Actions.ADD_PARTNER:
      return {
        ...state,
        partners: [...state.partners, a.data],
      }

    case Actions.SET_PARTNER_BY_INN_INFO:
      return {
        ...state,
        partnerByInn: a.data,
      }
  }
  
  return state
}
