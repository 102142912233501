import React, { FC } from 'react'
import { Modal, Tabs } from 'antd'
import { useDispatch } from 'react-redux'

import { CreateInternshipForm } from '../create-internship-form/create-internship-form'
import { ImportForm } from '../../../ShiftsPage/components/AddShiftModal/ImportForm'
import { fetchInternships } from 'AC/internships/internships'

import TabPane = Tabs.TabPane


export type AddInternshipModalPropsType = {
  isOpen: boolean
  onClose: () => void
}


export const AddInternshipModal: FC<AddInternshipModalPropsType> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const onSuccessImport = () => {
    dispatch(fetchInternships())
    onClose()
  }
  
  return (
    <Modal
      title='Создание стажировки'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={700}
      footer={false}
      bodyStyle={{ padding: '0 24px 24px 24px' }}
    >
      <Tabs size='small'>
        <TabPane
          tab='Создать вручную'
          key='create'
        >
          <CreateInternshipForm onClose={onClose} />
        </TabPane>

        <TabPane
          tab='Импортировать'
          key='import'
        >
          <ImportForm
            onSuccess={onSuccessImport}
            isInternship
          />
        </TabPane>
      </Tabs>
    </Modal>
  )
}
