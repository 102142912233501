import { Card, Table } from 'antd'
import React, { useMemo } from 'react'

import { APITYPES } from '../../../../../../types/apitypes'

import styles from './styles.module.sass'
import { getColumns, getRows, getTotalPenaltyValue } from './utils'


type Props = {
  records: APITYPES.DoerPenaltyRecord[]
  penaltyValue: number
  loading: boolean
}

export const PenaltyRecords = ({ records, penaltyValue, loading }: Props) => {
  const totalPenaltyValue = useMemo(() => getTotalPenaltyValue(records), [records])

  const getPenaltyRemains = () => {
    return Math.abs(penaltyValue || 0) - totalPenaltyValue
  }

  return (
    <>
      <Card
        size='small'
        title={<h3>История погашений</h3>}
        className={styles.card}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          sticky
          columns={getColumns() as any}
          dataSource={getRows(records || [])}
          bordered
          pagination={false}
          loading={loading}
          scroll={{ y: records.length ? 516 : undefined }}
        />
      </Card>
      <div
        className={styles.field}
      >
        <div className={styles.title}>
          Итого удержано:
        </div>
        <div className={styles.value}>
          { totalPenaltyValue }
        </div>
      </div>

      <div
        className={styles.field}
      >
        <div className={styles.title}>
          Осталось удержать:
        </div>
        <div className={styles.value}>
          { getPenaltyRemains() }
        </div>
      </div>
    </>
  )
}