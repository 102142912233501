import React from 'react'
import { Switch } from 'antd'
import { APITYPES } from 'types/apitypes'

const {ProductTypeTrans} = APITYPES

export const getColumns = (activeChange?: (e: any, id: string, value: boolean) => void) => {
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '30%'
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 120
    },
    {
      title: 'Краткое описание',
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: 'Активен',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 100,
      render: ({id, active}: {id: string, active: boolean}) =>
        <Switch checked={active} onChange={
          activeChange
            ? (nextValue, e) => activeChange(e, id, nextValue)
            : undefined
        } />
    },
  ]
}

export const getRows = (products: APITYPES.Product[]) => {
  return products.map((product) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'type') {
        row[column.dataIndex] = ProductTypeTrans[product.type]
      } else if (column.dataIndex === 'active') {
        row[column.dataIndex] = {id: product.id, active: product.active}
      } else {
        //@ts-ignore
        row[column.dataIndex] = product[column.dataIndex]
      }

      return
    })
    row.key = product.id

    return row
  })
}