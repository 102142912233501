// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--dW4hb{border:1px solid #ebedf0;padding:8px 24px}.styles-module--loadWrapper--b\\+C4r{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:6px}.styles-module--content--A3UmU{height:calc(100vh - 174px);margin-top:12px;display:-webkit-box;display:-ms-flexbox;display:flex;gap:12px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Operations/Operation/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CACA,gBAAA,CAEF,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,cAAA,CAEF,+BACE,0BAAA,CACA,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)\n  padding: 8px 24px\n\n.loadWrapper\n  display: flex\n  align-items: center\n  margin-top: 6px\n\n.content\n  height: calc(100vh - 174px)\n  margin-top: 12px\n  display: flex\n  gap: 12px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--dW4hb",
	"loadWrapper": "styles-module--loadWrapper--b+C4r",
	"content": "styles-module--content--A3UmU"
};
export default ___CSS_LOADER_EXPORT___;
