// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--RubC\\+{border:1px solid #ebedf0}.styles-module--select--VCOB0{min-width:50%;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.styles-module--edit--CqrLN{cursor:pointer;-webkit-transition:.2s;transition:.2s}.styles-module--edit--CqrLN:hover{color:#06f}.styles-module--groupSelect--ss0XK{min-width:200px}.styles-module--filters--5pLi1{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:12px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/DoerTariffs/DoerTariff/TariffHeader/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,wBAAA,CAEF,8BACE,aAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAEF,4BACE,cAAA,CACA,sBAAA,CAAA,cAAA,CACA,kCACE,UAAA,CAEJ,mCACE,eAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)\n\n.select\n  min-width: 50%\n  width: fit-content\n\n.edit\n  cursor: pointer\n  transition: 0.2s\n  &:hover\n    color: #0066FF\n\n.groupSelect\n  min-width: 200px\n\n.filters\n  display: flex\n  flex-direction: column\n  gap: 12px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--RubC+",
	"select": "styles-module--select--VCOB0",
	"edit": "styles-module--edit--CqrLN",
	"groupSelect": "styles-module--groupSelect--ss0XK",
	"filters": "styles-module--filters--5pLi1"
};
export default ___CSS_LOADER_EXPORT___;
