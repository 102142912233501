import React, { useState } from 'react'
import { DatePicker, Form, Modal } from 'antd'
import moment from 'moment'

import { FormFooter } from 'components/FormFooter'
import { required } from 'utils/formUtils'
import { Loader } from 'components/Loader'
import { showError } from 'components/modals/ErrorModal'
import { apiV1 } from 'network/http'


type Props = {
  onClose: () => void
  isOpen: boolean
};
type M = moment.Moment;

const dateFormat = 'DD.MM.YYYY'
const backFormat = 'YYYY-MM-DD'


const formLayout = { labelCol: { span: 5 } }

export const FleetPeriodModal = ({ onClose, isOpen }: Props) => {
  const [form] = Form.useForm()
  const [dates, setDates] = useState<M[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const onDateChange = (dates: any) => {
    setDates(dates)
  }

  const downloadExcelFile = async () => {
    if (!dates) {return}

    try {
      setIsLoading(true)
      const [begin, end] = dates

      const body = {
        begin: moment(begin).format(backFormat),
        end: moment(end).format(backFormat),
      }

      const response = await apiV1.post('/v1/fleets/procedures/bill_calculation',
        body,
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN') },
          responseType: 'blob',
        }
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', `clients_billing_${body.begin}_${body.end}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      showError(error.response.data)
    } finally {
      setDates(undefined)
      setIsLoading(false)
    }
  }

  const onFinish = async () => {
    setIsLoading(true)
    await downloadExcelFile()
  }


  return (
    <Modal
      title='Скачать рассчет'
      open={isOpen}
      onCancel={onClose}
      footer={false}
      destroyOnClose
    >
      {
        isLoading ? (
          <Loader />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            {...formLayout}
          >
            <Form.Item
              label='Период'
              name='dates'
              rules={required}
            >
              <DatePicker.RangePicker
                format={dateFormat}
                //@ts-ignore
                value={dates}
                onChange={onDateChange}
              />
            </Form.Item>
            <FormFooter
              onCancel={onClose}
              okText='Скачать рассчет'
              
            />
          </Form>
        )
      }
    </Modal>
  )
}
