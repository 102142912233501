import { Button } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { FilterIcon } from '../../../../../components/FilterIcon'
import { isObjectEmpty } from '../../../../../utils/filterUtils'
import { AddPenaltyModal } from '../AddPenaltyModal'
import { AddCompensationModal } from '../AddCompensationModal'
import { DEFAULT_MOTIVATION_TAB_KEY } from '../../../../../stores/motivationsReducer'
import { State } from '../../../../../stores/rootReducer'
import { Filters } from '../Filter'

import styles from './styles.module.sass'
import { ImportModalMotivation } from './importModal'


const selector = (state: State) => {
  return {
    filter: state.motivationsReducer.doerMotivationsFilter,
    activeTabId: state.motivationsReducer.activeTabId,
  }
}

export const MotivationToolbar = () => {
  const { filter, activeTabId } = useSelector(selector)

  const [importModal, setImportModal] = useState(false)
  const [addPenaltyModal, setAddPenaltyModal] = useState(false)
  const [addCompensationModal, setAddCompensationModal] = useState(false)
  const toggleImportModal = () => setImportModal(!importModal)
  const toggleAddPenaltyModal = () => setAddPenaltyModal(!addPenaltyModal)
  const toggleAddCompensationModal = () => setAddCompensationModal(!addCompensationModal)

  const [isFilterOpen, setFilterOpen] = useState(false)
  const toggleFilters = () => setFilterOpen((prev) => !prev)

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.col}>
          <Button
            type='primary'
            onClick={toggleAddPenaltyModal}
          >
            Новый штраф
          </Button>

          <Button
            type='primary'
            onClick={toggleAddCompensationModal}
          >
            Новая компенсация
          </Button>

          <Button
            onClick={toggleImportModal}
            type='primary'
            disabled={true}
          >
            Импорт
          </Button>
        </div>

        <div
          className={styles.col}
          style={{ display: activeTabId === DEFAULT_MOTIVATION_TAB_KEY ? 'flex' : 'none' }}
        >
          <Button
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
            onClick={toggleFilters}
          />
        </div>
      </div>

      <Filters
        isOpen={isFilterOpen}
        onClose={toggleFilters}
      />

      {
        addPenaltyModal && (
          <AddPenaltyModal
            isOpen={addPenaltyModal}
            onClose={toggleAddPenaltyModal}
          />
        )
      }
      {
        addCompensationModal && (
          <AddCompensationModal
            isOpen={addCompensationModal}
            onClose={toggleAddCompensationModal}
          />
        )
      }

      <ImportModalMotivation
        isOpen={importModal}
        onCancel={toggleImportModal}
      />
    </>
  )
}