import React, { useState } from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import { deleteShift } from 'AC/shifts/shifts'
import { APITYPES } from 'types/apitypes'
import { useShiftTabsContext } from '../../../context'

import { Info } from './Info'
import { AddDoerToShiftModal } from './AddDoerToShiftModal'
import { CancelShiftModal } from './CancelShiftModal'
import styles from './styles.module.sass'


type Props = {
  shift: APITYPES.Shift
}

export const ShiftInfo = (props: Props) => {
  const dispatch = useDispatch()
  const { tabActions } = useShiftTabsContext()
  const { shift } = props

  const handleDeleteShift = () => {
    dispatch(deleteShift(shift.id, tabActions.handleCloseTab))
  }

  const [addDoerModal, setAddDoerModal] = useState<boolean>(false)
  const toggleAddDoerModal = () => setAddDoerModal(!addDoerModal)

  const [cancelModal, setCancelModal] = useState<boolean>(false)
  const toggleCancelModal = () => setCancelModal(!cancelModal)

  return (
    <>
      <div className={styles.container}>
        <Info shift={shift} />

        <div className={styles.btnsWrapper}>

          <div className={styles.rightIcons}>
            <Popconfirm
              title='Вы точно хотите удалить смену?'
              onConfirm={handleDeleteShift}
              placement='topLeft'
            >
              <Tooltip overlay='Удалить смену'>
                <Button
                  size='small'
                  danger
                  type='primary'
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>

            <Tooltip overlay='Отменить смену'>
              <Button
                size='small'
                type='default'
                onClick={toggleCancelModal}
              >
                <CloseCircleOutlined />
              </Button>
            </Tooltip>
          </div>


          <div>
            <Tooltip overlay='Добавить исполнителя в смену'>
              <Button
                size='small'
                type='primary'
                onClick={toggleAddDoerModal}
              >
                <UserAddOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
        
      </div>

      <AddDoerToShiftModal
        isOpen={addDoerModal}
        onClose={toggleAddDoerModal}
        shiftId={shift.id}
        shiftRoles={shift.roles}
      />

      <CancelShiftModal
        isOpen={cancelModal}
        onClose={toggleCancelModal}
        shiftId={shift.id}
      />
    </>
  )
}
