import { Button, Table } from 'antd'
import React, { useState } from 'react'

import { APITYPES } from 'types/apitypes'

import { CreateModal } from './CreateModal'
import { EditModal } from './EditModal'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
}

export const Employees = (props: Props) => {
  const { carwash } = props

  const [ isCreate, setIsCreate ] = useState(false)
  const [ employeeForModal, setEmployeeForModal ] = useState<APITYPES.CarwashEmployee | undefined>(undefined)
    
  return (
    <div>
      {
        carwash.employees && (
          <Table
            columns={getColumns() as any}
            className={styles.table}
            dataSource={getRows(carwash.employees)}
            bordered
            size='large'
            pagination={false}
            onRow={
              (record) => {
                return {
                  onClick: () => {
                    setEmployeeForModal(record.phones)
                  },
                }
              }
            }
          />
        )
      }
      <Button
        type='primary'
        className={styles.addButton}
        onClick={()=>setIsCreate(true)}
      >
        Добавить
      </Button>
      <CreateModal
        isVisible={isCreate}
        onCancel={()=>setIsCreate(false)}
        carwashId={carwash.id}
      />
      {
        employeeForModal && (
          <EditModal
            isVisible={!!employeeForModal}
            onCancel={()=>setEmployeeForModal(undefined)}
            employee={employeeForModal}
            carwashId={carwash.id}
          />
        )
      }
    </div>
  )
}