// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--fieldsGroup--u6ZeN{-webkit-box-flex:0;-ms-flex:0 1 45%;flex:0 1 45%;margin-right:10px;margin-top:10px}.styles-module--fieldsEmployeesGroup--TAh-J{-webkit-box-flex:0;-ms-flex:0 1 50%;flex:0 1 50%;margin-top:10px}.styles-module--groupTitle--uzRSp{font-weight:bold;font-size:18px;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Partners/Carwashes/Carwash/Group/styles.module.sass"],"names":[],"mappings":"AAAA,mCACI,kBAAA,CAAA,gBAAA,CAAA,YAAA,CACA,iBAAA,CACA,eAAA,CAEJ,4CACI,kBAAA,CAAA,gBAAA,CAAA,YAAA,CACA,eAAA,CAEJ,kCACI,gBAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":[".fieldsGroup\n    flex: 0 1 45%\n    margin-right: 10px\n    margin-top: 10px\n\n.fieldsEmployeesGroup\n    flex: 0 1 50%\n    margin-top: 10px\n\n.groupTitle\n    font-weight: bold\n    font-size: 18px\n    margin-bottom: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsGroup": "styles-module--fieldsGroup--u6ZeN",
	"fieldsEmployeesGroup": "styles-module--fieldsEmployeesGroup--TAh-J",
	"groupTitle": "styles-module--groupTitle--uzRSp"
};
export default ___CSS_LOADER_EXPORT___;
