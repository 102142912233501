import { action, Actions, ThAction } from 'actions'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'

import { changeRequestState } from './request'


export const fetchCarwashesStatistics = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarwashesStatistics', StatusRequest.LOADING))
      
    try {
      const resp = await api.get(APITYPES.Statistics.Carwashes.URL)
    
      if (resp) {
        dispatch(action(Actions.SET_CARWASHES_STATISTICS, resp))
    
        dispatch(changeRequestState('fetchCarwashesStatistics', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarwashesStatistics', StatusRequest.ERROR))
  
      throw(e)
    }
  }
}

export const fetchPartnersStatistics = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchPartnersStatistics', StatusRequest.LOADING))
        
    try {
      const resp = await api.get(APITYPES.Statistics.Partners.URL)
      
      if (resp) {
        dispatch(action(Actions.SET_PARTNERS_STATISTICS, resp))
      
        dispatch(changeRequestState('fetchPartnersStatistics', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchPartnersStatistics', StatusRequest.ERROR))
    
      throw(e)
    }
  }
}


export const addOperationsStatistics =
  (body:APITYPES.Statistics.Operations.POST.Req ): ThAction => {
    return async (dispatch, _, { api }) => {
      dispatch(changeRequestState('addOperationsStatistics', StatusRequest.LOADING))

      try {
        const resp = await api.post(APITYPES.Statistics.Operations.POST.URL, body)

        if (resp) {
          dispatch(action(Actions.SET_OPERATIONS_STATISTICS, resp))
          dispatch(changeRequestState('addOperationsStatistics', StatusRequest.LOADED))
        }
      } catch (e:any) {
        dispatch(changeRequestState('addOperationsStatistics', StatusRequest.ERROR))
        showError(e.response.data)
        throw(e)
      }
    }
  }