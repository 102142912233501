import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button, Drawer, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { fetchCustomerTariffs } from 'AC/products/tariffs'
import { fetchAllCarClasses, fetchClientTypes } from 'AC/directory/directory'
import { getUrlSearchParams } from 'utils/urlUtils'
import { isObjectEmpty } from 'utils/filterUtils'

const {Option} = Select

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  clientTypes: state.directoryReducer.clientTypes,
  carClasses: state.directoryReducer.carClasses,
  filter: state.customerTariffsReducer.customerTariffsFilter,
})

export const Filters = ({isOpen, onClose}: Props) => {
  const params = getUrlSearchParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const {clientTypes, carClasses, filter} = useSelector(selector)


  useEffect(() => {
    if(!clientTypes) dispatch(fetchClientTypes())
    if(!carClasses) dispatch(fetchAllCarClasses())
  }, [])

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)
      dispatch(action(Actions.SET_CUSTOMER_TARIFFS_FILTER, {...parsed}))
      dispatch(fetchCustomerTariffs(parsed))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.PRODUCTS.TARIFFS.PARAMS.createPath(filter))
      dispatch(fetchCustomerTariffs(filter))
    } else {
      dispatch(fetchCustomerTariffs())
    }
  }, [])

  const onFilterChange = (partial: Partial<APITYPES.CustomerTariffFilter>) => {
    const newFilter = {...filter, ...partial}
    dispatch(action(Actions.SET_CUSTOMER_TARIFFS_FILTER, newFilter))
    history.push(ROUTES.PRODUCTS.TARIFFS.PARAMS.createPath(newFilter))
    dispatch(fetchCustomerTariffs(newFilter))
  }


  const resetFilters = () => {
    dispatch(action(Actions.SET_CUSTOMER_TARIFFS_FILTER, {}))
    history.push(ROUTES.PRODUCTS.TARIFFS.path)
    dispatch(fetchCustomerTariffs())
  }


  return (
    <Drawer
      title="Фильтры"
      placement="right"
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout="vertical">
        <Form.Item label="Тип клиента">
          <Select
            showSearch
            allowClear
            value={filter?.client_type_id}
            placeholder='Выберите тип клиента'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => onFilterChange({client_type_id: value})}
          >
            {clientTypes?.map(type =>
              <Option key={type.id} value={type.id}>{type.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Класс автомобиля">
          <Select
            showSearch
            allowClear
            value={filter?.car_class_id}
            placeholder='Выберите класс автомобиля'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => onFilterChange({car_class_id: value})}
          >
            {carClasses?.map(cls =>
              <Option key={cls.id} value={cls.id}>{cls.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
