import React, { useState } from 'react'
import { Button } from 'antd'

import { InternshipsFilter } from './components/internships-filter/internships-filter'
import { InternshipsTable } from './components/internships-table/internships-table'
import { AddInternshipModal } from './components/add-internship-modal/add-internship-modal'

import cls from './internships.module.sass'


export const Internships = () => {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const closeAddModal = () => setAddModalOpen(false)

  return (
    <div className={cls.wrapper}>
      <div className={cls.group}>
        <h1 className={cls.title}>Стажировки</h1>
        <Button
          type='primary'
          onClick={() => setAddModalOpen(true)}
        >
          Создать
        </Button>
      </div>
      <InternshipsFilter />
      <div className={cls.content}>
        <InternshipsTable />
      </div>
      <AddInternshipModal
        isOpen={addModalOpen}
        onClose={closeAddModal}
      />
    </div>
  )
}