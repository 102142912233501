import { Moment } from 'moment'
import * as XLSX from 'xlsx'

import { backDateFormat } from 'utils/dateUtils'


export const formatDates = (dates: Moment[]): [string, string] | undefined => {
  if (dates) {
    const [begin, end] = dates
    const formattedBegin = begin.format(backDateFormat)
    const formattedEnd = end.format(backDateFormat)

    return [formattedBegin, formattedEnd]
  }
  
  return undefined
}

export const exportToExcel = (statisticsData: any, dates: Moment[], title: string) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(statisticsData)

  const fileName = formatDates(dates)?.join('_')

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  XLSX.writeFile(wb, title + `${fileName}.xlsx`)
}
