// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--title--ZLroI{border:1px solid #ebedf0}.styles-module--addButton--gD7O4{max-width:250px;margin-bottom:16px;margin-top:12px}.styles-module--tableBtn--yCB1W{margin:0;padding:0}.styles-module--table--KRmmI{cursor:pointer}.styles-module--selectOption--kIgtQ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/Cars/CarModels/CarModel/styles.module.sass"],"names":[],"mappings":"AAAA,6BACE,wBAAA,CAEF,iCACE,eAAA,CACA,kBAAA,CACA,eAAA,CAEF,gCACE,QAAA,CACA,SAAA,CAEF,6BACE,cAAA,CAEF,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA","sourcesContent":[".title\n  border: 1px solid rgb(235, 237, 240)\n\n.addButton\n  max-width: 250px\n  margin-bottom: 16px\n  margin-top: 12px\n\n.tableBtn\n  margin: 0\n  padding: 0\n\n.table\n  cursor: pointer\n\n.selectOption\n  display: flex\n  justify-content: space-between\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles-module--title--ZLroI",
	"addButton": "styles-module--addButton--gD7O4",
	"tableBtn": "styles-module--tableBtn--yCB1W",
	"table": "styles-module--table--KRmmI",
	"selectOption": "styles-module--selectOption--kIgtQ"
};
export default ___CSS_LOADER_EXPORT___;
