import { APITYPES } from 'types/apitypes'

import { getColumns } from './columns'


// TODO: needs refactoring
export const getRows = (billItems: APITYPES.BillItem[]) => {
  const data = billItems.map((item) => {
    const row: any = {}

    getColumns().map((column) => {
      //@ts-ignore
      row[column.dataIndex] = item[column.dataIndex]

      return
    })

    row.data = item
    row.key = item.id

    return row
  })

  return data
}
