import React, { useState , useEffect } from 'react'
import { Modal, Form, Input, Button, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { createPartner } from 'AC/partners/partners'
import { Inn } from 'components/Inn'
import { State } from 'stores/rootReducer'
import { action, Actions } from 'actions'
import { FormFooter } from 'components/FormFooter'

import styles from './styles.module.sass'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

const { useForm } = Form

const dateFormat = 'YYYY-MM-DD'

const selector = (state: State) => {
  const { partnerByInn, partnerByInnForCreating } = state.partnersReducer

  return {
    partnerByInn,
    partnerByInnForCreating,
  }
}

export const CreateCard = (props: Props) => {
  const { partnerByInn, partnerByInnForCreating } = useSelector(selector)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const [ form ] = useForm()

  const { isVisible, onCancel } = props

  const dispatch = useDispatch()

  useEffect(()=>{
    if (partnerByInnForCreating) {
      form.setFieldsValue(partnerByInnForCreating)
    }
  }, [partnerByInnForCreating, form])

  const onFinish = async (values: any) => {
    dispatch(createPartner({
      ...values,
      contract_date: values.contract_date ? values.contract_date.format(dateFormat) : undefined,
    }))
    onCancel()
  }

  const onReset = async () => {
    dispatch(action(Actions.SET_PARTNER_BY_INN_FOR_CREATING, undefined))
    form.resetFields()
  }

  const [isInn, setIsInn] = useState(false)

  return (
    <Modal
      className={styles.modal}
      title='Создание'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Inn
        isVisible={isInn}
        onCancel={() => setIsInn(false)}
        partner={partnerByInn}
        isFill
      />
      <Form
        name='time_related_controls'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='legal_name'
          label='Юридическое лицо'
          initialValue={partnerByInnForCreating?.legal_name ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='legal_address'
          label='Юридический адрес'
          initialValue={partnerByInnForCreating?.legal_address ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='inn'
          label='ИНН'
          initialValue={partnerByInnForCreating?.inn ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='ogrn'
          label='ОГРН / ОГРНИП'
          initialValue={partnerByInnForCreating?.ogrn ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='checking_account'
          label='Расчетный счет'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='corr_account'
          label='Корр. счет'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='bank'
          label='Банк'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='bik'
          label='БИК'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='general_manager'
          label='Генеральный директор'
          initialValue={partnerByInnForCreating?.general_manager ?? ''}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='diadoc_state'
          label='Подключен к Диадок'
          initialValue='Подключен'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='Подключен'>Подключен</Select.Option>
            <Select.Option value='В процессе подключения'>
              В процессе подключения
            </Select.Option>
            <Select.Option value='Отказ'>Отказ</Select.Option>
            <Select.Option value='Меняет ИП'>Меняет ИП</Select.Option>
            <Select.Option value='В работе'>В работе</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='signer_tag'
          label='Договор с'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select>
            <Select.Option value='servicepro'>ООО "СервисПро"</Select.Option>
            <Select.Option value='topdoer'>
              ООО "ТОПДУЕР"
            </Select.Option>
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          position='right'
          leftElement={
            <>
              <Button
                onClick={onReset}
                className={styles.resetButton}
              >
                Очистить
              </Button>
              <Button
                type='primary'
                onClick={() => setIsInn(true)}
                className={styles.innButton}
              >
                Найти по ИНН
              </Button>
            </>
          }
        />
      </Form>
    </Modal>
  )
}