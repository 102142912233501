// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--tag--4Qwac{margin-top:4px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Directory/styles.module.sass"],"names":[],"mappings":"AAAA,2BACE,cAAA","sourcesContent":[".tag\n  margin-top: 4px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "styles-module--tag--4Qwac"
};
export default ___CSS_LOADER_EXPORT___;
