// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--table--ttv8m{cursor:pointer}.styles-module--toolbar--GvHTJ{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:-8px;margin-bottom:16px}.styles-module--container--OdyB8{margin-bottom:16px}.styles-module--steps_content--7hzBH{margin-top:16px;border:1px dashed #e9e9e9;border-radius:6px;background-color:#fafafa;min-height:200px;text-align:center}.styles-module--steps_action--lJUc4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin-top:16px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/ShiftsPage/components/CommitModal/Wizard/styles.module.sass"],"names":[],"mappings":"AAAA,6BACE,cAAA,CAEF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CACA,kBAAA,CAEF,iCACE,kBAAA,CAEF,qCACE,eAAA,CACA,yBAAA,CACA,iBAAA,CACA,wBAAA,CACA,gBAAA,CACA,iBAAA,CAEF,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,eAAA","sourcesContent":[".table\n  cursor: pointer\n\n.toolbar\n  display: flex\n  width: 100%\n  justify-content: space-between\n  align-items: center\n  margin-top: -8px\n  margin-bottom: 16px\n\n.container\n  margin-bottom: 16px\n\n.steps_content \n  margin-top: 16px\n  border: 1px dashed #e9e9e9\n  border-radius: 6px\n  background-color: #fafafa\n  min-height: 200px\n  text-align: center\n    \n.steps_action \n  display: flex\n  justify-content: flex-end\n  margin-top: 16px\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles-module--table--ttv8m",
	"toolbar": "styles-module--toolbar--GvHTJ",
	"container": "styles-module--container--OdyB8",
	"steps_content": "styles-module--steps_content--7hzBH",
	"steps_action": "styles-module--steps_action--lJUc4"
};
export default ___CSS_LOADER_EXPORT___;
