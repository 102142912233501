import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'


import { getTableYScroll } from '../../../../utils/scrollUtils'
import { State } from '../../../../stores/rootReducer'
import { StatusRequest } from '../../../../stores/requestsReducer'
import { getColumns } from './utils/getColumns'
import { getRows } from './utils/getRows'
import { getMonitoringAllocations, updateMonitoringAllocations } from '../../../../AC/doers/doerMonitoring'
import { useQueryParams } from '../../../../utils/hooks/useQueryParams'

import styles from './styles.module.sass'
import { SetParams } from './SetParams'


const selector = (state: State) => {
  const { doerMonitoringAllocations, doerMonitoringAllocationsFilter } = state.doerMonitoringReducer
  const { getMonitoringAllocations, updateMonitoringAllocations, updatedMonitoringAllocationsData } = state.requestsReducer
  
  return {
    monitoringAllocations: doerMonitoringAllocations,
    filter: doerMonitoringAllocationsFilter,
    isDataUpdating: updatedMonitoringAllocationsData === StatusRequest.LOADING,
    isGetLoading: getMonitoringAllocations === StatusRequest.LOADING,
    isUpdateLoading: updateMonitoringAllocations === StatusRequest.LOADING
  }
}


export const Monitoring = () => {
  const dispatch = useDispatch()
  const params = useQueryParams()
  const {
    monitoringAllocations,
    filter,
    isDataUpdating,
    isGetLoading,
    isUpdateLoading,
  } = useSelector(selector)
  const isFirstRun = useRef(true)

  const { role_id, date } = params

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }
    if (isGetLoading) {
      return
    }

    if (role_id && date) {
      dispatch(getMonitoringAllocations(
        {
          date,
          role_id,
        }
      ))
    }
  }, [role_id, date])

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (filter?.role_id && filter?.date && !isUpdateLoading) {
        dispatch(updateMonitoringAllocations({
          date: filter.date,
          role_id: filter.role_id,
        }))
      }
    }, 100)

    return () => clearInterval(intervalID)
  }, [filter?.role_id, filter?.date, isUpdateLoading])

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <SetParams />
      </div>

      <Table
        sticky
        size='small'
        loading={isGetLoading || isDataUpdating}
        columns={getColumns() as any}
        dataSource={getRows(monitoringAllocations || [])}
        className={styles.table}
        bordered
        pagination={false}
        scroll={{ y: getTableYScroll(10) }}
      />
    </div>
  )
}