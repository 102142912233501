import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Table } from 'antd'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { acceptDoerRecords, getDoerRecords, payDoerRecords } from 'AC/billing/doerRecords'
import { getColumns, getRows, mapFilterToQuery } from '../utils'
import { Loader } from '../../../../../components/Loader'
import { showSureConfirmModified } from '../../../../../components/modals/SureModal'
import { InfiniteTable } from '../../../../../components/InfiniteTable'

import styles from './styles.module.sass'
import { ExportButtons } from './exportButtons'
import { ImportModal } from './importModal'


const selector = (state: State) => ({
  isLoading: state.requestsReducer.getDoerRecords === StatusRequest.LOADING,
  activeTab: state.doerRecordsReducer.activeTabId,
  doerRecords: state.doerRecordsReducer.doerRecordsMap[state.doerRecordsReducer.activeTabId],
  filtersMap: state.doerRecordsReducer.filtersMap[state.doerRecordsReducer.activeTabId],
})

export const RecordTable = () => {
  const {
    doerRecords,
    isLoading,
    activeTab,
    filtersMap,
  } = useSelector(selector)
  const dispatch = useDispatch()
  const [openImportModal, setOpenImportModal] = useState(false)
  const [records, setRecords] = useState<APITYPES.DoerRecords[]>([])
  const [filter, setFilter] = useState<any>({})

  const toggleModal = () => setOpenImportModal(!openImportModal)

  const onClickConfirm = () => {
    showSureConfirmModified({
      title: 'Вы действительно хотите перевести записи в статус "Согласовано"?',
      onOk: () => dispatch(acceptDoerRecords(filter)),
    })
  }

  const onClickPaid = () => {
    showSureConfirmModified({
      title: 'Вы действительно хотите перевести записи в статус "Оплачено"?',
      onOk: () => dispatch(payDoerRecords(filter)),
    })
  }

  useEffect(() => {
    if (!filtersMap) {return}
    const fitters = mapFilterToQuery(filtersMap)

    dispatch(getDoerRecords(activeTab, fitters))
    setFilter(fitters)
  }, [dispatch, filtersMap])

  useEffect(() => {
    if (!doerRecords) {return}
    setRecords(doerRecords.doerRecords)
  }, [dispatch, doerRecords])

  if (!records.length && isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Space>
          <Button
            type='primary'
            size='small'
            onClick={toggleModal}
          >
            Импорт
          </Button>
          <ExportButtons filter={filter} />
          <Button
            type='primary'
            size='small'
            onClick={onClickConfirm}
          >
            Согласовано
          </Button>
          <Button
            type='primary'
            size='small'
            onClick={onClickPaid}
          >
            Оплачено
          </Button>
        </Space>
      </div>
      <InfiniteTable
        hasMore={doerRecords?.recordsHasMore}
        isLoading={isLoading}
        columns={getColumns(records, setRecords)}
        className={styles.tableContainer}
        loadMore={
          ()=> {
            if (!filtersMap) {return}
            const fitters = mapFilterToQuery(filtersMap)

            dispatch(getDoerRecords(activeTab, fitters))
          }
        }
      >
        <Table
          sticky
          size='large'
          showHeader={false}
          pagination={false}
          loading={isLoading}
          columns={getColumns(records, setRecords)}
          dataSource={getRows(records)}
          bordered
          style={
            { cursor: 'pointer' }
          }
        />
      </InfiniteTable>

      {
        openImportModal && (
          <ImportModal
            isOpen={openImportModal}
            onCancel={toggleModal}
          />
        )
      }

    </div>
  )
}
