// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--header--oJN0X{border:1px solid #ebedf0;height:75.2px}.styles-module--extra--tl0vr{display:-webkit-box;display:-ms-flexbox;display:flex;gap:8px}.styles-module--loaderWrapper--4EHfP{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Doers/Doer/Header/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CACA,aAAA,CAEF,6BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CAEF,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".header\n  border: 1px solid rgb(235, 237, 240)\n  height: 75.2px\n\n.extra\n  display: flex\n  gap: 8px\n\n.loaderWrapper\n  display: flex\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles-module--header--oJN0X",
	"extra": "styles-module--extra--tl0vr",
	"loaderWrapper": "styles-module--loaderWrapper--4EHfP"
};
export default ___CSS_LOADER_EXPORT___;
