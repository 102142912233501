import React, { useEffect, useState } from 'react'
import { Table, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'
import { getYandexProReports } from 'AC/yandexpro/reports'
import { MatchingModal } from './Components/MatchingModal/MatchingModal'

import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  reports: state.yandexProReducer.reports,
  isLoading:
    state.requestsReducer.getYandexProReports === StatusRequest.LOADING,
})

export const YandexProReports = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [connectReportId, setConnectReportId] = useState('')
  const { reports, isLoading } = useSelector(selector)
  const dispatch = useDispatch()

  const toggleUploadModal = () => setIsModalOpen((prevState) => !prevState)

  useEffect(() => {
    if (!reports.length) {
      dispatch(getYandexProReports())
    }
  }, [reports])

  const openConnectModal = (id: string) => {
    setConnectReportId(id)
  }

  const closeConnectModal = () => {
    setConnectReportId('')
    dispatch(getYandexProReports())
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={
              () => {
                toggleUploadModal()
              }
            }
          >
            Загрузить отчет
          </Button>
        </div>
        <Table
          sticky
          size='small'
          loading={isLoading}
          columns={getColumns() as any}
          dataSource={getRows(reports)}
          pagination={false}
          bordered
          scroll={{ y: getTableYScroll(162) }}
          style={{ cursor: 'pointer' }}
          onRow={
            (record: any) => ({ onClick: () => openConnectModal(record.id) })
          }
        />
      </div>

      <MatchingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <MatchingModal
        isOpen={!!connectReportId}
        reportId={connectReportId}
        onClose={closeConnectModal}
      />
    </>
  )
}
