// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--card--wmOuC{width:50%}.styles-module--cardBody--DmrB2{height:calc(100vh - 214px);overflow:auto}.styles-module--cardBodyContent--nuMYn{padding:16px}.styles-module--extra--wTZjw{display:-webkit-box;display:-ms-flexbox;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/Operations/Operation/OperationCard/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,SAAA,CAEF,gCACE,0BAAA,CACA,aAAA,CAEF,uCACE,YAAA,CAEF,6BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA","sourcesContent":[".card\n  width: 50%\n\n.cardBody\n  height: calc(100vh - 214px)\n  overflow: auto\n\n.cardBodyContent\n  padding: 16px\n\n.extra\n  display: flex\n  gap: 8px\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles-module--card--wmOuC",
	"cardBody": "styles-module--cardBody--DmrB2",
	"cardBodyContent": "styles-module--cardBodyContent--nuMYn",
	"extra": "styles-module--extra--wTZjw"
};
export default ___CSS_LOADER_EXPORT___;
