import React from 'react'

import { dateFormat, getFormattedDate } from '../../../../../utils/dateUtils'
import { Status } from '../Status'
import { APITYPES } from 'types/apitypes'
import { updateCompensationStatus, updatePenaltyStatus } from 'AC/doers/penalties'

import MOTIVATION_TYPE = APITYPES.MOTIVATION_TYPE


export const columns = ([
  {
    title: 'Исполнитель',
    dataIndex: 'doer_name',
    key: 'doer_name',
    align: 'center',
    width: 220,
  },
  {
    title: 'Роль',
    dataIndex: 'role_name',
    key: 'role_name',
    align: 'center',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 110,
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: 'Сумма',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    width: 100,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 110,
    align: 'center',
    render: (motivation: APITYPES.Performers.GetDoerMotivations.Resp) => {
      const handleChangeStatus = (comment: string, status: 'applied' | 'canceled') => {
        const { id } = motivation

        if (motivation.type === 'penalty') {
          return updatePenaltyStatus({
            penalty_id: id,
            new_status: status,
          }, { comment })
        }
        
        return updateCompensationStatus({
          compensation_id: id,
          new_status: status,
        }, { comment })
      }
      
      return (
        <Status
          modalTitle={
            `Вы точно хотите изменить статус ${motivation.type === MOTIVATION_TYPE.PENALTY ? 'штрафа' : 'бонуса'}?`
          }
          motivation={motivation}
          // @ts-ignore
          onCallback={handleChangeStatus}
        />
      )
    },
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: 240,
  },
  {
    title: 'Дата',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
    width: 120,
    render: (time: string) => getFormattedDate(time, dateFormat),
  },
])

export const getColumns = () => columns
