import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const fetchCarwashes = (
  filter: APITYPES.CarwashFilter,
  limit?: number,
  offset?: number,
  selectSearch?: boolean
): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchCarwashes', StatusRequest.LOADING))
    try {
      const resp = await api.get(APITYPES.Carwashes.Get.URL, {
        params: {
          ...filter,
          partner_id: filter.partner_id ?? getState().carwashesReducer.carwashesFilter.partner_id,
          state: filter.state ?? getState().carwashesReducer.carwashesFilter.state,
          client_type: filter.client_type ?? getState().carwashesReducer.carwashesFilter.client_type,
          region: filter.region ?? getState().carwashesReducer.carwashesFilter.region,
          object_type_id: filter.object_type_id ?? getState().carwashesReducer.carwashesFilter.object_type_id,
          search: filter.search ?? getState().carwashesReducer.carwashesFilter.search,
          limit: limit || 20,
          offset: offset ?? getState().carwashesReducer.carwashes.length,
        },
      })

      if (resp) {
        if (selectSearch) {
          dispatch(action(Actions.SET_SEARCHED_CARWHASHES, resp))
        } else {
          dispatch(action(Actions.SET_CARWASHES, {
            carwashes: resp,
            offset,
          }))
        }

        dispatch(changeRequestState('fetchCarwashes', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarwashes', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const updateCarwash = (values: APITYPES.Carwashes.Put.Req, id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('updateCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Carwashes.Put.URL, values, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id,
        }))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateCarwash', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateCarwash', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const offCarwash = (id: string, reason: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('offCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Carwashes.Off.URL, { reason } ,{ replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id,
        }))

        dispatch(changeRequestState('offCarwash', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('offCarwash', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deactivateCarwash = (id: string, reason: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('deactivateCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Carwashes.Deactivate.URL, { reason } ,{ replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id,
        }))

        dispatch(changeRequestState('deactivateCarwash', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deactivateCarwash', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const activateCarwash = (id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('activateCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Carwashes.Activate.URL, {}, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id,
        }))

        dispatch(changeRequestState('activateCarwash', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('activateCarwash', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createCarwash = (values: APITYPES.Carwashes.Post.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Carwashes.Post.URL, values)

      if (resp) {
        dispatch(fetchCarwashes({}, 10, 0))

        dispatch(changeRequestState('createCarwash', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createCarwash', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchCarwash = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarwash', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Carwashes.GetCarwash.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_CARWASH, resp))

        dispatch(changeRequestState('fetchCarwash', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarwash', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const fetchRentalObjectTypes = (domain?: APITYPES.RentalObjectTypeDomain): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchRentalObjectTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.RentalObjectTypes.Get.URL, { params: { domain } })

      if (resp) {
        dispatch(action(Actions.SET_RENTAL_OBJECT_TYPES, resp))
        dispatch(changeRequestState('fetchRentalObjectTypes', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchRentalObjectTypes', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const fetchRentalObjectType = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchRentalObjectType', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.RentalObjectTypes.GetRentalObjectType.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_CURRENT_RENTAL_OBJECT_TYPE, resp))

        dispatch(changeRequestState('fetchRentalObjectType', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchRentalObjectType', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const fetchTariffs = (params: APITYPES.Office.Tariff.Get.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchTariffs', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Office.Tariff.Get.URL, { params })

      if (resp) {
        dispatch(action(Actions.SET_TARIFFS, resp))

        dispatch(changeRequestState('fetchTariffs', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchTariffs', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const createTariff = (tariff: APITYPES.Office.Tariff.Post.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createTariff', StatusRequest.LOADING))

    dispatch(action(Actions.SET_TARIFF_ERROR, null))

    try {
      const resp = await api.post(APITYPES.Office.Tariff.Post.URL, tariff)

      if (resp) {
        dispatch(action(Actions.CREATE_TARIFF, resp))

        dispatch(changeRequestState('createTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createTariff', StatusRequest.ERROR))

      if (e.response.status === 409) {
        dispatch(action(Actions.CLEAN_TARIFFS, {}))
        dispatch(fetchTariffs({
          carwash_id: tariff.carwash_id,
          client_type_id: tariff.client_type_id,
        }))
      } else if (e.response.status !== 200){
        dispatch(action(Actions.SET_TARIFF_ERROR, {
          carClassId: tariff.car_class_id,
          serviceId: tariff.service_id,
        }))
      }

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateTariff =
  (tariff: APITYPES.Office.Tariff.Put.Req, id: string, carwash_id: string, client_type_id: string): ThAction => {
    return async (dispatch, getState, { api }) => {
      dispatch(changeRequestState('updateTariff', StatusRequest.LOADING))

      dispatch(action(Actions.SET_TARIFF_ERROR, null))

      try {
        const resp = await api.put(APITYPES.Office.Tariff.Put.URL, tariff, { replaceUrl: { id } })

        if (resp) {
          dispatch(action(Actions.UPDATE_TARIFF, resp))

          dispatch(changeRequestState('updateTariff', StatusRequest.LOADED))
        }
      } catch (e: any) {
        dispatch(changeRequestState('updateTariff', StatusRequest.ERROR))

        if (e.response.status === 409) {
          dispatch(action(Actions.CLEAN_TARIFFS, {}))
          dispatch(fetchTariffs({
            carwash_id,
            client_type_id,
          }))
        } else if (e.response.status !== 200){
          const currentTariff =
          getState().carwashesReducer.tariffs?.find((tariff) => tariff.supplier_tariff_service_id === id)

          if (!currentTariff) {return}

          dispatch(action(Actions.SET_TARIFF_ERROR, {
            carClassId: currentTariff.car_class_id,
            serviceId: currentTariff.service_id,
          }))
        }

        throw(e)
      }
    }
  }

export const deleteTariff = (id: string, carwash_id: string, client_type_id: string): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('deleteTariff', StatusRequest.LOADING))

    dispatch(action(Actions.SET_TARIFF_ERROR, null))

    try {
      const resp = await api.delete(APITYPES.Office.Tariff.Put.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.DELETE_TARIFF, id))

        dispatch(changeRequestState('deleteTariff', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteTariff', StatusRequest.ERROR))

      if (e.response.status === 409) {
        dispatch(action(Actions.CLEAN_TARIFFS, {}))
        dispatch(fetchTariffs({
          carwash_id,
          client_type_id,
        }))
      } else if (e.response.status !== 200){
        const currentTariff =
          getState().carwashesReducer.tariffs?.find((tariff) => tariff.supplier_tariff_service_id === id)

        if (!currentTariff) {return}

        dispatch(action(Actions.SET_TARIFF_ERROR, {
          carClassId: currentTariff.car_class_id,
          serviceId: currentTariff.service_id,
        }))
      }

      throw(e)
    }
  }
}

export const fetchClientTypes = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchClientTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.CarwashClientTypes.Get.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_CLIENT_TYPES, resp))

        dispatch(changeRequestState('fetchClientTypes', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchClientTypes', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const fetchCarClasses = (client_type_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCarClasses', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.CarClasses.Get.URL, { replaceUrl: { client_type_id } })

      if (resp) {
        dispatch(action(Actions.SET_CAR_CLASSES, resp))

        dispatch(changeRequestState('fetchCarClasses', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarClasses', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const fetchServices = (client_type_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchServices', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Services.Get.URL, { replaceUrl: { client_type_id } })

      if (resp) {
        dispatch(action(Actions.SET_SERVICES, resp))

        dispatch(changeRequestState('fetchServices', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchServices', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const fetchTables = (id: string): ThAction => {
  return async (dispatch, getState) => {
    await dispatch(fetchClientTypes(id))
    await dispatch(fetchCarClasses(getState().carwashesReducer.clientTypes[0].id))
    await dispatch(fetchServices(getState().carwashesReducer.clientTypes[0].id))
    dispatch(fetchTariffs({
      carwash_id: id,
      client_type_id: getState().carwashesReducer.clientTypes[0].id,
    }))
  }
}

export const fetchTableByClientType = (client_type_id: string, carwash_id: string): ThAction => {
  return async (dispatch) => {
    await dispatch(fetchCarClasses(client_type_id))
    await dispatch(fetchServices(client_type_id))
    dispatch(fetchTariffs({
      carwash_id,
      client_type_id,
    }))
  }
}

export const updateEmployee =
(employee: APITYPES.Employees.Put.Req, params: { carwash_id: string, employee_id: string}): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('updateEmployee', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Employees.Put.URL, employee, { replaceUrl: params })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id: params.carwash_id,
        }))

        openNotification('Сохранено')

        dispatch(changeRequestState('updateEmployee', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateEmployee', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createEmployee =
(employee: APITYPES.Employees.Put.Req, params: { carwash_id: string }): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('createEmployee', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Employees.Post.URL, employee, { replaceUrl: params })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id: params.carwash_id,
        }))

        dispatch(changeRequestState('createEmployee', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createEmployee', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteEmployee =
(params: { carwash_id: string, employee_id: string }): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('deleteEmployee', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.Employees.Delete.URL, { replaceUrl: params })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id: params.carwash_id,
        }))

        openNotification('Удалено')

        dispatch(changeRequestState('deleteEmployee', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteEmployee', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchCarwashPhotos = (): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchCarwashPhotos', StatusRequest.LOADING))

    try {
      const carwash_id = getState().carwashesReducer.carwash?.id

      if (!carwash_id) {return}

      const resp = await api.get(APITYPES.Carwashes.GetPhotos.URL, { replaceUrl: { carwash_id } })

      if (resp) {
        dispatch(action(Actions.SET_CARWASH_PHOTOS, resp))

        dispatch(changeRequestState('fetchCarwashPhotos', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCarwashPhotos', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const addCarwashPhoto = (file: File): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addCarwashPhoto', StatusRequest.LOADING))

    try {
      const carwash_id = getState().carwashesReducer.carwash?.id

      if (!carwash_id) {return}

      const resp = await api.post(APITYPES.Carwashes.AddPhoto.URL, {
        name: file.name,
        size: file.size,
        mime_type: file.type,
      }, { replaceUrl: { carwash_id } })

      if (resp) {
        const resp2 = await api.put(resp.url, file)

        if (resp2){
          dispatch(fetchCarwashPhotos())
          dispatch(changeRequestState('addCarwashPhoto', StatusRequest.LOADED))
        }
      }
    } catch (e) {
      dispatch(changeRequestState('addCarwashPhoto', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const replacePartner = (carwash_id: string, partner_id: string): ThAction => {
  return async (dispatch, _ , { api }) => {
    dispatch(changeRequestState('replacePartner', StatusRequest.LOADING))

    try {
      const resp =
      await api.put(APITYPES.Carwashes.ReplacePartner.URL, { partner_id }, { replaceUrl: { id: carwash_id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_CARWASH, {
          carwash: resp,
          id: carwash_id,
        }))

        openNotification('Партнер изменен')

        dispatch(changeRequestState('replacePartner', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('replacePartner', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

// penalties

export const fetchPenaltyTypes = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchPenaltyTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Penalty.Get.URL)

      if (resp) {
        dispatch(action(Actions.SET_PENALTY_TYPES, resp))

        dispatch(changeRequestState('fetchPenaltyTypes', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchPenaltyTypes', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addNewPenalty = (params: APITYPES.Penalty.Post.Params, data: APITYPES.Penalty.Post.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addNewPenalty', StatusRequest.LOADING))
    const { carwash_id } = params

    try {
      const resp = await api.post(APITYPES.Penalty.Post.URL, data, { replaceUrl: { carwash_id } })

      if (resp) {
        dispatch(action(Actions.ADD_NEW_PENALTY, resp))

        dispatch(changeRequestState('addNewPenalty', StatusRequest.LOADED))
        openNotification('Штраф успешно добавлен.')
      }
    } catch (e) {
      dispatch(changeRequestState('addNewPenalty', StatusRequest.ERROR))
      showError(e.response.data)
    }
  }
}

export const updatePenaltyStatus = (
  params: APITYPES.Penalty.UpdateStatus.Params,
  data: APITYPES.Penalty.UpdateStatus.Req
): ThAction => {
  return async(dispatch, _, { api }) => {
    dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.LOADING))
    const { carwash_id, carwash_penalty_id, new_status } = params

    try {
      const resp = await api.post(APITYPES.Penalty.UpdateStatus.URL, data,
        {
          replaceUrl: {
            carwash_id,
            carwash_penalty_id,
            new_status,
          },
        })

      if (resp) {
        dispatch(action(Actions.UPDATE_PENALTY_STATUS, resp))

        dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.LOADED))
        openNotification('Статус штрафа изменен.')
      }
    } catch (e) {
      dispatch(changeRequestState('updatePenaltyStatus', StatusRequest.ERROR))
      showError(e.response.data)
    }
  }
}

export const fetchPenalties = (
  params: APITYPES.Carwashes.GetPenalties.Params,
  filter: APITYPES.Carwashes.GetPenalties.Query
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchPenalties', StatusRequest.LOADING))
    const { carwash_id } = params

    try {
      const resp = await api.get(APITYPES.Carwashes.GetPenalties.URL,
        {
          replaceUrl: { carwash_id },
          params: {
            state: filter.state ?? undefined,
            begin: filter.begin ?? undefined,
            end: filter.end ?? undefined,
          },
        })

      if (resp) {
        dispatch(action(Actions.SET_PENALTIES, { carwashPenalties: resp }))
        dispatch(changeRequestState('fetchPenalties', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchPenalties', StatusRequest.ERROR))
      throw(e)
    }
  }
}

//compensations
export const fetchCompensationTypes = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCompensationTypes', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Compensation.Get.URL)

      if (resp) {
        dispatch(action(Actions.SET_COMPENSATION_TYPES, resp))

        dispatch(changeRequestState('fetchCompensationTypes', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCompensationTypes', StatusRequest.ERROR))
      throw(e)
    }
  }
}

export const addNewCompensation = (params: APITYPES.Compensation.Post.Params,
  data: APITYPES.Compensation.Post.Req): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addNewCompensation', StatusRequest.LOADING))
    const { carwash_id } = params

    try {
      const resp = await api.post(APITYPES.Compensation.Post.URL, data, { replaceUrl: { carwash_id } })

      if (resp) {
        dispatch(action(Actions.ADD_NEW_COMPENSATION, resp))

        dispatch(changeRequestState('addNewCompensation', StatusRequest.LOADED))
        openNotification('Компенсация успешно добавлена.')
      }
    } catch (e) {
      dispatch(changeRequestState('addNewCompensation', StatusRequest.ERROR))
      showError(e.response.data)
    }
  }
}

export const fetchCompensations = (
  params: APITYPES.Carwashes.GetCompensations.Params,
  filter: APITYPES.Carwashes.GetCompensations.Query
): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchCompensations', StatusRequest.LOADING))
    const { carwash_id } = params

    try {
      const resp = await api.get(APITYPES.Carwashes.GetCompensations.URL,
        {
          replaceUrl: { carwash_id },
          params: {
            begin: filter.begin ?? undefined,
            end: filter.end ?? undefined,
          },
        })

      if (resp) {
        dispatch(action(Actions.SET_COMPENSATIONS, { carwashCompensations: resp }))
        dispatch(changeRequestState('fetchCompensations', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchCompensations', StatusRequest.ERROR))
      throw(e)
    }
  }
}