import React, { useState } from 'react'
import { Button, Card } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { Loader } from 'components/Loader'
import { CreateOperationModal } from '../CreateOperationModal'
import { DeleteOperationModal } from '../DeleteOperationModal'

import { CardContent } from './CardContent'
import { getCardData } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  selectedOperation: state.operationsReducer.currentOperation,
  tasks: state.operationsReducer.operationTasks,
  isLoading: state.requestsReducer.fetchOperation === StatusRequest.LOADING,
  isTasksLoading: state.requestsReducer.fetchOperationTasks === StatusRequest.LOADING,
})

export const OperationCard = () => {
  const { selectedOperation, isLoading, isTasksLoading, tasks } = useSelector(selector)
  const [create, setCreate] = useState<boolean>(false)
  const [remove, setRemove] = useState<boolean>(false)
  const toggleCreateModal = () => setCreate(!create)



  return (
    <>
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        className={styles.card}
        extra={
          <div className={styles.extra}>
            <Button
              size="small"
              type="primary"
              loading={isTasksLoading}
              disabled={Boolean(!tasks.length || isTasksLoading)}
              onClick={toggleCreateModal}
            >
              Добавить операцию
            </Button>
            <Button danger type="primary" size="small" onClick={() => setRemove(!remove)}><DeleteOutlined /></Button>
          </div>
        }
      >
        <div className={styles.cardBody}>
          {
            !isLoading && selectedOperation
              ? <CardContent cardData={getCardData(selectedOperation)} />
              : <Loader />
          }
        </div>
      </Card>
      <DeleteOperationModal
        isOpen={remove}
        onClose={() => setRemove(!remove)}
        selectedOperation={selectedOperation}
      />
      <CreateOperationModal
        isOpen={create}
        onClose={toggleCreateModal}
      />
    </>
  )
}
